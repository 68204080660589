import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  formControl: {
    //margin: theme.spacing(1),
    width: "100%"
  },
  noLabel: {
    marginTop: theme.spacing(3)
  },
  label: {
    "&:focus": {
      color: theme.palette.common.black
    },
    color:"#4d4d4d"
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
  getContentAnchorEl: null
};

export default function EMSelect(
  { label, name, data = [], objKey, prevData, onChange, error, helperText,isDisabled },
  props
) {
  const classes = useStyles();
  const [selectedData, setSelectedData] = React.useState();
  
  React.useEffect(() => { 
    setSelectedData(prevData);
  }, [prevData]);

  const handleChange = event => {   
    setSelectedData(event.target.value);
    onChange(event.target.value);
  };
  return (
    <div>
      <FormControl className={classes.formControl} disabled = {isDisabled}>
        <InputLabel id="org_network_select" className={classes.label}>
          {label}
        </InputLabel>
        <Select
          name={name}
          placeholder={name}
          labelId="org_network_select"
          value={selectedData}
          defaultValue={prevData}
          onChange={handleChange}
          input={<Input value={selectedData}/>}
          MenuProps={MenuProps}
          fullWidth
          helperText={helperText}
        >
          
          {data && data.map(d => {
            
            let dataToShow = d[objKey];
            return (
              <MenuItem key={dataToShow} value={dataToShow}>
                <Tooltip title={<h2 style={{ color: "white" }}>{dataToShow}</h2>}  placement="top">
                <ListItemText primary={dataToShow && dataToShow.length > 48 ? dataToShow.slice(0,48) + ".." : dataToShow} />
                </Tooltip>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormHelperText style={{color:"red"}}>
        {error && helperText}
      </FormHelperText>
    </div>
  );
}
