import { useState, useEffect, useCallback } from "react";
import ReactDOM from "react-dom";
import MaterialTable from "@material-table/core";
import EMIcons from "./EMIcons";
import { useSelector } from "react-redux";
import { findIndex } from "lodash";
import { CheckBoxOutlineBlankTwoTone } from "@material-ui/icons";
import { countBy } from "lodash";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
const useStyles = makeStyles((theme) => ({
  colHeader: {
    color: "white",
    textAlign: "left",
    "&:hover": {
      color: "white",
    },
  },
  colHeaderForPartnership: {
    color: "white",
    textAlign: "center",
    "&:hover": {
      color: "white",
    },
  },
  //  tableWidth: { [theme.breakpoints.up("xs")]: {
  //     width: "16rem",
  //   },
  //   [theme.breakpoints.up("sm")]: {
  //     width: "40rem",
  //   },
  //   [theme.breakpoints.up("md")]: {
  //     width: "50rem",
  //   },
  //   [theme.breakpoints.up("lg")]: {
  //     width: "100%",
  //   },}
}));

export default function EMSimpleDatatable({
  columns,
  data,
  options,
  count,
  updatePageAndPageSizeAndOrder,
  enableCustomPagination,
  filter,
  headerStyleColor,
  forPartnershipCreation,
}) {
  const uiData = useSelector((state) => state.ui);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [defaultpageSize, setdefaultpageSize] = useState(15);
  const [order, setOrder] = useState({ id: -1, direction: "asc" });
  const classes = useStyles();

  //execute the query whenever the page or page size changes
  useEffect(() => {
    updatePageAndPageSizeAndOrder(page, pageSize, order);
  }, [page, pageSize, order]);

  useEffect(() => {
    setPage(0);
  }, [filter]);

  return (
    <div style={{ maxHeight: window.screen.height / 1.5, overflow: "auto" }}>
      <MaterialTable
        icons={EMIcons}
        totalCount={count}
        onOrderChange={(id, direction) => {
          if (id !== -1) {
            setOrder({ id, direction });
          }
        }}
        options={{
          pageSize: pageSize,
          pageSizeOptions: [
            defaultpageSize * 1,
            defaultpageSize * 2,
            defaultpageSize * 3,
          ],
          ...options,
          search: false,
          showTitle: false,
          toolbar: null,
          emptyRowsWhenPaging: false,
          showEmptyDataSourceMessage: !uiData.loading,
          draggable: false,
          sorting: true,
          headerStyle: {
            backgroundColor: headerStyleColor ? "#2F92D6" : "#43455A",
            padding: "7px 0px 7px 0px",
            // width: 100,
            textAlign: "left",
            whiteSpace: "nowrap",
          },
        }}
        localization={{
          pagination: {
            labelDisplayedRows: `${page + 1}-${Math.ceil(count / pageSize)}`,

            labelRowsSelect: "",
          },
        }}
        page={page}
        components={{
          Container: "div",
        }}
        onPageChange={(page, pageSize) => {
          setPage(page);
          setPageSize(pageSize);
        }}
        columns={[
          {
            title: (
              <Typography
                className={classes.colHeader}
                style={{ paddingLeft: "7px" }}
              >
                Sr. No.
              </Typography>
            ),
            align: "left",
            sorting: false,
            width: "5%",
            render: (rowData, index) => {
              return (
                <Typography
                  style={{
                    fontSize: "small",
                    paddingLeft: "7px",
                  }}
                >
                  {rowData.tableData.id + 1 + pageSize * page}
                </Typography>
              );
            },
          },
          ...columns.map((col) => ({
            ...col,
            customSort: (a, b) => {},
            title: (
              <Typography
                className={
                  forPartnershipCreation
                    ? classes.colHeaderForPartnership
                    : classes.colHeader
                }
              >
                {col.title}
              </Typography>
            ),
          })),
        ]}
        data={data}
      />
    </div>
  );
}
