
export default {
    common: {
      black: "#000000",
      white: "#ffffff",
      grey : "#D2D8DB"
    },
    primary: {
      light: "#2F92D6",
      main: "#2F92D6",
      dark: "#0D5879",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#43455A",
      main: "#43455A",
      dark: "#43455A",
      contrastText: "#ffffff",
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    warning: {
      light: "#ffb74d",
      main: "#ff9800",
      dark: "#f57c00",
      contrastText: "#ffffff",
    },
    info: {
      light: "#64b5f6",
      main: "#2196f3",
      dark: "#1976d2",
      contrastText: "#fff",
    },
    success: {
      light: "#81c784",
      main: "#4caf50",
      dark: "#388e3c",
      contrastText: "#ffffff",
    },
    // text: {
    //   primary: rgba(0, 0, 0, 0.87),
    //   secondary: rgba(0, 0, 0, 0.54),
    //   disabled: rgba(0, 0, 0, 0.38),
    //   hint: rgba(0, 0, 0, 0.38),
    // },
    // divider: rgba(0, 0, 0, 0.12),
    // active: {
    //   active: rgba(0, 0, 0, 0.54),
    //   hover: rgba(0, 0, 0, 0.04),
    //   hoverOpacity: 0.04,
    //   selected: rgba(0, 0, 0, 0.08),
    //   selectedOpacity: 0.08,
    //   disabled: rgba(0, 0, 0, 0.26),
    //   disabledBackground: rgba(0, 0, 0, 0.12),
    //   disabledOpacity: 0.38,
    //   focus: rgba(0, 0, 0, 0.12),
    //   focusOpacity: 0.12,
    //   activatedOpacity: 0.12,
    // },
  };
  