import React, { useState, useEffect, useRef } from "react";
import EMPage from "../../../components/commans/EMPage";
import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Box,
  Button,
  makeStyles,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  InputLabel,
  Hidden,
} from "@material-ui/core";
import EMDatePicker from "../../../components/commans/EMDatePicker";
import EMUserEngagementReport from "./EMUserEngagementReport";
import { useFormik, Field, Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { EMDoGetUserEngagementReportForGroupAction,EMDoGetEntityListForGroupAction, EMDoGetGroupBasedOnOrgOrPartnershipAction,EMDoExportUserEngagementReportForGroupAction} from "../../../redux/actions/EMGroupAdminActions"

const dataForNetworkReportType = [
  
  {
    id: 1,
    name: "User Engagement Report",
  },
  
];

const browseReportType = [
  { id: 2, type: "Channel-Wise" },
  { id: 1, type: "Group-Wise" },
];

const useStyles = makeStyles((theme) => ({
  Title: {
    textAlign: "center",
    fontWeight: "bolder",
    fontSize: "large",
  },
  circle: {
    width: "100px",
    height: "100px",
    lineHeight: "100px",
    borderRadius: "50%",
    fontSize: "20px",
    color: "#fff",
    textAlign: "center",
    background: "#000080",
  },
}));

export default function EMPlatformReportsManagement(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [networkList, setNetworkList] = useState([]);
  const [listOfOrganizations, setListOfOrganizations] = useState([]);
  const networkadminData = useSelector((state) => state.groupAdmin || {});
  const [reportType, setReportType] = useState(0);
  const [isGroupWise, setIsGroupWise] = useState()
  const [entityId, setEntityId] = useState();
  const [entity, setEntity] = useState();
  const [groupList, setGroupList] = useState();
  const [widthDropdown, setWidth] = useState("80%");
  const [dataForTableData, setDataForTableData] = useState([])

  // useEffect(() => {
  //   dispatch(EMDoGetNetworkListForDashboardAndReportsAction());
  // }, []);
  useEffect(() => {
    dispatch(EMDoGetEntityListForGroupAction());
  }, []);

  useEffect(() => {
    if (entityId) {
      let result = networkList.find((obj) => {
        return obj.newId === entityId;
      });
      setEntity(result);
    }
  }, [entityId]);

  useEffect(() => {
    if (entity) {
      dispatch(
        EMDoGetGroupBasedOnOrgOrPartnershipAction({
          requiredData: entity,
        })
      );
    }
  }, [entity]);

  useEffect(() => {
    if (networkadminData && networkadminData.groupListForDashboard) {
      setGroupList(networkadminData.groupListForDashboard);
    }
  }, [networkadminData]);
  useEffect(() => {
    let organizationsArray = [];
    let partnershipsArray = [];
    if (networkadminData && networkadminData.dashboardEntityList) {
      if (
        networkadminData.dashboardEntityList &&
        networkadminData.dashboardEntityList[0] &&
        networkadminData.dashboardEntityList[0].partnerships.length > 0
      ) {
        partnershipsArray = networkadminData.dashboardEntityList[0].partnerships.map(
          (part, index) => ({
            ...part,
            isPartnership: true,
            newId: index + 1,
            networkName: part.partnership_name
          })
        );
      }
      let newIndex = partnershipsArray.length;
      organizationsArray =
        networkadminData.dashboardEntityList &&
        networkadminData.dashboardEntityList[0] &&
        networkadminData.dashboardEntityList[0].organizations.length > 0 &&
        networkadminData.dashboardEntityList[0].organizations.map((org, index) => ({
          ...org,
          isPartnership: false,
          newId: newIndex + index + 1,
          networkName: org.organization_name
        }));
        var combinedArray = [
          ...partnershipsArray, ...organizationsArray
        ];
        function SortArray(x, y){
            if (x.networkName < y.networkName) {return -1;}
            if (x.networkName > y.networkName) {return 1;}
            return 0;
        }
        var sortedArray = combinedArray.sort(SortArray);
        setNetworkList([...sortedArray]);
      setListOfOrganizations([...organizationsArray])
    }
  }, [networkadminData]);


  const validationSchema = yup.object({
    startDate: yup.date("Enter valid date").required("start date is required"),
    endDate: yup
      .date("Enter valid date")
      .required("end date is required")
      .min(yup.ref("startDate"), "End Date Must be After Start Date")
      .test(
        "",
        "end date should not be greater than 1 year",
        (val, props) => {
          const expiryDate = moment(val);
          const enteredDate = moment(props.parent.startDate);
          const tmpExpiryDate = moment(enteredDate).add(365, "days");
  
          if (!tmpExpiryDate.isBefore(expiryDate)) {
            return true;
          }
        }
      ),
    selectedNetwork: yup
      .string("Please select the network")
      .required("Please select the network"),
    reportType: yup
      .string("Please select the report type")
      .required("Please select the report type"),
    browseReportType: yup
      .string("Please select the report type")
      .when("reportType", (showReport) => {
        
        if (showReport == 1)
          return yup
            .string("Please select the report type")
            .required("Please select the report type");
      }),
  });

  let date = new Date();
  date.setMonth(date.getMonth() + 1);

  const formik = useFormik({
    initialValues: {
      startDate: new Date(),
      endDate: date,
      selectedNetwork: "",
      reportType: "",
      browseReportType: "",
      selectedGroup:"",
    },
    validationSchema: validationSchema,
    onSubmit: (e) => {
      function formatDate(date) {
        var d = new Date(date),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
      }
      let result = networkList.find((obj) => {
        return obj.newId === formik.values.selectedNetwork;
      });

      if(formik.values.browseReportType == 1){
        setIsGroupWise(true)
      }else{
        setIsGroupWise(false)
      }

      let dataForUserEngagementReport = {
        start_date: formatDate(formik.values.startDate),
        end_date: formatDate(formik.values.endDate),
        organizationOrPartnershipId: result && result.id,
        isPartnership: result && result.isPartnership,
        groupOrChannel: formik.values.browseReportType,
        groupId: formik.values.selectedGroup,
      };
      
      setDataForTableData(dataForUserEngagementReport)   
      setReportType(1);  
    },
  });
  window.addEventListener("resize", () => {
    let width = document.body.clientWidth;

    if (width <= 600) { //Close Drawer when screen size medium
      setWidth("100%")
    } else {
      setWidth("80%")
    }
  });

  return (
    <EMPage title="Reports" style={{ padding: "2%" }}>
      <Paper elevation={2} style={{ marginBottom: "1%" }}>
        <form
          onSubmit={formik.handleSubmit}
          style={{ width: "100%", padding: "1%", display: "inline-block" }}
        >
          <Grid
            container
            spacing={3}
            alignItems="center" 
            justifyContent="flex-start"
          >
            <Grid item xs={6} sm={6} md={4} lg={2}>
              {/* <Typography
                style={{
                  fontWeight: "bolder",
                  fontSize: "large",
                  marginBottom: "3%",
                }}
              >
                Choose time period
              </Typography> */}

              <EMDatePicker
                label={"Start Date"}
                id={"Start Date"}
                value={formik.values.startDate}
                onSelectDate={(date) => {
                  formik.setFieldValue("startDate", date);
                }}
                minDate={new Date("01/01/1960")}
                error={
                  formik.touched.startDate && Boolean(formik.errors.startDate)
                }
                helperText={formik.touched.startDate && formik.errors.startDate}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={2}>
              <div ></div>
              <EMDatePicker
                label={"End Date"}
                id={"End Date"}
                value={formik.values.endDate}
                onSelectDate={(date) => {
                  formik.setFieldValue("endDate", date);
                }}
                minDate={formik.values.startDate}
                error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                helperText={formik.touched.endDate && formik.errors.endDate}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={2} style={{}}>
              {/* <Typography
                style={{
                  fontWeight: "bolder",
                  fontSize: "large",
                  marginBottom: "-5%",
                }}
              >
                Select Report
              </Typography> */}
              <FormControl style={{width:widthDropdown}}>
                <InputLabel id="select-report" style={{color:"#4d4d4d"}}>Report</InputLabel>
                <Select
                  labelId="select-report"
                  id="select-report"
                  value={formik.values.reportType}
                  label="Select Report"
                  // error={
                  //   formik.errors.reportType && formik.touched.reportType
                  //     ? true
                  //     : false
                  // }
                  helperText={formik.touched.reportType && formik.errors.reportType}
                  InputLabelProps={{style:{color:"#4d4d4d"}}}
                  FormHelperTextProps={{style:{color:"red"}}}
                  onChange={(selected) => {
                    formik.setFieldValue("reportType", selected.target.value);
                  }}
                >
                  {dataForNetworkReportType &&
                    dataForNetworkReportType.map((report) => (
                      <MenuItem value={report.id}>{report.name}</MenuItem>
                    ))}
                </Select>
                <FormHelperText style={{ color: "red" }}>
                  {formik.errors.reportType && formik.touched.reportType
                    ? formik.errors.reportType
                    : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
           {formik.values.reportType == 1 ? (
            <Grid item xs={12} sm={4} md={4} lg={2} style={{}}>
                {/* <Typography
                  style={{
                    fontWeight: "bolder",
                    fontSize: "large",
                    marginBottom: "-5%",
                  }}
                >
                  Select Network
                </Typography> */}
                <FormControl style={{width:widthDropdown}}>
                  <InputLabel id="select-network" style={{color:"#4d4d4d"}}>Network</InputLabel>
                  <Select
                    labelId="select-network"
                    id="select-network"
                    value={formik.values.selectedNetwork || entityId}
                    label="Select Network"
                    // error={
                    //   formik.errors.selectedNetwork &&
                    //   formik.touched.selectedNetwork
                    //     ? true
                    //     : false
                    // }
                    helperText={formik.touched.selectedNetwork && formik.errors.selectedNetwork}
                    InputLabelProps={{style:{color:"#4d4d4d"}}}
                    FormHelperTextProps={{style:{color:"red"}}}
                    onChange={(selected) => {
                      formik.setFieldValue(
                        "selectedNetwork",
                        selected.target.value
                      );
                      setEntityId(selected.target.value);
                    }}
                  >
                    
                    {networkList &&
                      networkList.length > 0 &&
                      networkList.map((network) => (
                        <MenuItem value={network.newId}>
                          {network.networkName}
                        </MenuItem>
                      
                      ))}
                       
                     
                  </Select>
                      
                  <FormHelperText style={{ color: "red" }}>
                    {formik.errors.selectedNetwork &&
                    formik.touched.selectedNetwork
                      ? formik.errors.selectedNetwork
                      : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
               ) : null} 
              {entityId ? (
                 <Grid item xs={12} sm={6} md={4} lg={2} style={{}}>
                 <FormControl fullWidth style={{ marginTop: "1%" ,width:widthDropdown}}>
                   <InputLabel style={{fontSize:"75%",color:"#4d4d4d"}} id="select-report" >Select Group/Channel wise</InputLabel>
                   <Select
                     labelId="select-report"
                     id="select-report"
                     value={formik.values.browseReportType}
                     label="Select Report" 
                    //  error={
                    //    formik.errors.browseReportType &&
                    //    formik.touched.browseReportType
                    //      ? true
                    //      : false
                    //  }
                     helperText={formik.touched.browseReportType && formik.errors.browseReportType}
                     InputLabelProps={{style:{color:"#4d4d4d"}}}
                     FormHelperTextProps={{style:{color:"red"}}}
                     onChange={(selected) => {
                       formik.setFieldValue(
                         "browseReportType",
                         selected.target.value
                       );
                     }}
                   >
                     {browseReportType &&
                       browseReportType.map((reports) => (
                         <MenuItem value={reports.id}>{reports.type}</MenuItem>
                       ))}
                   </Select>
                   <FormHelperText style={{ color: "red" }}>
                     {formik.errors.browseReportType &&
                     formik.touched.browseReportType
                       ? formik.errors.browseReportType
                       : ""}
                   </FormHelperText>
                 </FormControl>
               </Grid>
              ) : null}
<Hidden smDown>
            <Grid item xs={2} sm={2} md={2} lg={1} >
              <Button type="submit" variant="contained" color="primary">
                Apply
              </Button>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={1}>
              <Button
              style={{width:"100%"}}
                onClick={() => {
                  
                  function formatDate(date) {
                    var d = new Date(date),
                      month = "" + (d.getMonth() + 1),
                      day = "" + d.getDate(),
                      year = d.getFullYear();

                    if (month.length < 2) month = "0" + month;
                    if (day.length < 2) day = "0" + day;

                    return [year, month, day].join("-");
                  }
                

                  let result = networkList.find((obj) => {
                    return obj.newId === formik.values.selectedNetwork;
                  });
              

                  let requiredData;

                  if(result && result.isPartnership){
                    requiredData = {
                      start_date: formatDate(formik.values.startDate),
                      end_date: formatDate(formik.values.endDate),
                      partnership:result.id
                    
                    }
                  }else{
                    requiredData = {
                      start_date: formatDate(formik.values.startDate),
                      end_date: formatDate(formik.values.endDate),
                      organization:result.id
                    
                    }
                  }

                     let groupOrChannel = formik.values.browseReportType;
                   
                      dispatch(
                        EMDoExportUserEngagementReportForGroupAction({
                          requiredData: requiredData,
                          groupOrChannel: groupOrChannel
                        })
                      );
                   

                }}
                disabled={reportType === 0}
                variant="contained"
                color="primary"
              >
                Download
              </Button>
            </Grid>
            </Hidden>
          </Grid>
          <Hidden lgUp mdUp>
          <Grid container style={{marginTop:"2rem" ,alignItems:"center",justifyContent:"center"}} >
          <Grid item xs={2} sm={2} md={2} lg={1} >
              <Button type="submit" variant="contained" color="primary">
                Apply
              </Button>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={1} style={{ textAlign: "center", marginLeft:"2rem" }}>
              <Button
                onClick={() => {
                  function formatDate(date) {
                    var d = new Date(date),
                      month = "" + (d.getMonth() + 1),
                      day = "" + d.getDate(),
                      year = d.getFullYear();

                    if (month.length < 2) month = "0" + month;
                    if (day.length < 2) day = "0" + day;

                    return [year, month, day].join("-");
                  }
                

                  let result = networkList.find((obj) => {
                    return obj.newId === formik.values.selectedNetwork;
                  });
              
                  
                  let requiredData;

                  if(result && result.isPartnership){
                    requiredData = {
                      start_date: formatDate(formik.values.startDate),
                      end_date: formatDate(formik.values.endDate),
                      partnership:result.id
                    
                    }
                  }else{
                    requiredData = {
                      start_date: formatDate(formik.values.startDate),
                      end_date: formatDate(formik.values.endDate),
                      organization:result.id
                    
                    }
                  }

                     let groupOrChannel = formik.values.browseReportType;
                   
                      dispatch(
                        EMDoExportUserEngagementReportForGroupAction({
                          requiredData: requiredData,
                          groupOrChannel: groupOrChannel
                        })
                      );
                   

                }}
                disabled={reportType === 0}
                variant="contained"
                color="primary"
              >
                Download
              </Button>
            </Grid>
            </Grid>
            </Hidden>
        </form>
      </Paper>
     
      {reportType === 1 ? <EMUserEngagementReport groupOrChannel={isGroupWise} initialData={dataForTableData} /> : null}
     
    </EMPage>
  );
}
