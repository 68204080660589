import React, { useState, useEffect } from "react";
import {
  Paper,
  Box,
  Button,
  Grid,
  makeStyles,
  CircularProgress,
  Modal,
  Typography,
} from "@material-ui/core";
import EMPage from "../../../../../components/commans/EMPage";
import EMUsersTable from "./EMUsersTable";
import EMDropDown from "../../../../../components/commans/EMDropdown";
import EMDatatableSearchView from "../../../../../components/datatables/EMDatatableSearchView";
import EMImportUsers from "./ImportUsers/index";
import EMResendInvites from "./ResendInvites/index";
import EMAddNewUser from "./AddNewUser/index";
import EMBulkUpload from "./BulkUpload/index";
import EMEntityDropdown from "../../commons/EMEntityDropdown";
import {
  EMDoExportNetworkAdminUsersAction,
  EMDoGetRolesListAction,
  EMDoGetEmailTemplateAction,
  EMDoGetFilterByGroupDropdownValuesAction,
  EMDoGetListOfNetworksForDropdownAction,
  EMDoGetPermissionSpecificToNetworkAction,
} from "../../../../../redux/actions/EMNetworkAdminActions";
import { EMDoGetEntityInformationBasedOnPermissionAction } from "../../../../../redux/actions/EMAuthActions";
import { useSelector, useDispatch } from "react-redux";
import { find, findLast, isEmpty, findIndex } from "lodash";
import { NETWORK_ADMIN_ADD_REMOVE_IMPORT_USERS_FROM_SECONDARY_ARRAY, NETWORK_ADMIN_ADD_REMOVE_RESEND_INVITES_USERS_FROM_SECONDARY_ARRAY, NETWORK_ADMIN_IMPORT_USERS_SELECT_ALL_FLAG, NETWORK_ADMIN_RESEND_INVITES_USERS_SELECT_ALL_FLAG, NETWORK_ADMIN_UPDATE_USERS_LIST_FOR_IMPORT_USERS, NETWORK_ADMIN_UPDATE_USERS_LIST_FOR_RESEND_INVITES, RESET } from "../../../../../redux/constants";
import {
  EMDoGetOrganizationDepartmentsAction,
  EMDoGetOrganizationLevelsAction,
  EMDoGetOrganizationTypeAction,
} from "../../../../../redux/actions/EMCommonActions";
const useStyles = makeStyles((theme) => ({
  button: {
    margin: 10,
    width: 170,
    height: 40,
  },
  buttonWidth: {
    [theme.breakpoints.up("xs")]: {
      //width: "12ch",
      width: 120,
    },
    [theme.breakpoints.up("sm")]: {
      //width: "12ch",
      width: 170,
    },
    [theme.breakpoints.up("md")]: {
      //width: "12ch",
      width: 170,
    },
    [theme.breakpoints.up("lg")]: {
      //width: "12ch",
      width: 170,
    },
  },
  width: {
    [theme.breakpoints.up("xs")]: {
      //width: "12ch",
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      //width: "12ch",
      width: "40%",
    },
    [theme.breakpoints.up("md")]: {
      //width: "12ch",
      width: "33.3%",
    },
    [theme.breakpoints.up("lg")]: {
      //width: "12ch",
      width: "33.3%",
    },
  },
  widthForSearchBar: {
    [theme.breakpoints.up("xs")]: {
      //width: "12ch",
      width: "100%",
      height:"fit-content"
    },
    [theme.breakpoints.up("sm")]: {
      //width: "12ch",
      width: "100%",
      height:"fit-content"
    },
    [theme.breakpoints.up("md")]: {
      //width: "12ch",
      width: "100%",
      height:"fit-content"
    },
    [theme.breakpoints.up("lg")]: {
      //width: "12ch",
      width: "100%",
      height:"fit-content"
    },
  },
  justifyEnd: {
    [theme.breakpoints.up("lg")]: {
      justifyContent: "flex-end",
    },
  },
}));

export default function NetworkAdminManagement(props) {
  const classes = useStyles();
  const [filter, setFilter] = useState("");
  const [open, setOpen] = React.useState(false);
  const [editData, setEditData] = React.useState({});
  const [importUserActive, setImportUserActive] = React.useState(false);
  const [resendInviteActive, setResendInviteActive] = React.useState(false);
  const [bulkUploadActive, setBulkUploadActive] = React.useState(false);
  const [exportUserActive, setExportUserActive] = React.useState(false);
  const [addNewUserActive, setAddNewUserActive] = React.useState(false);
  const [showLabels, setShowLabels] = React.useState({
    label: "",
    buttonLabel: "",
  });
  const [showNetworkError, setShowNetworkError] = React.useState();
  const [isError, setIsError] = React.useState(true);
  const [isEdit, setIsEdit] = React.useState(false);
  const tableData = useSelector(
    (state) => state.networkAdmin.networkUsers || {}
  );
  const roleData = useSelector((state) => state.networkAdmin.roles);
  const dispatch = useDispatch();
  const [bulkUploadData, setBulkUploadData] = React.useState({});
  const userData = useSelector((state) => state.auth);
  const data = useSelector((state) => state.auth["entityDetails"]);
  const [permissionData, setPermissionData] = React.useState();
  const networkSpecificPermissions = useSelector(state => state.networkAdmin.networkSpecificRolesAndPermissions)
  const uiData = useSelector((state) => state.ui);
  const [entity, setEntity] = useState();

  //states for onSubmit calls
  const [addNewUserButtonActive, setAddNewUserButtonActive] =
    React.useState(true);

  useEffect(()=>{
    if(networkSpecificPermissions && entity){
      let permission = find(networkSpecificPermissions,{context:entity.context})
      let specificPermission = find(permission && permission.rolePermissions,{permission_name:"manage_users"})
      setPermissionData(specificPermission)
    }
  },[networkSpecificPermissions])

  let disabledValue = (permissionData && permissionData["add_flag"]) || true;

  //call dropdown Api
  useEffect(() => {
    dispatch(EMDoGetListOfNetworksForDropdownAction());
  }, []);

  useEffect(() => {
    if(entity){
      dispatch(EMDoGetPermissionSpecificToNetworkAction({id:entity.context, networkType:entity.entity_type}))
    }
  },[entity])

  useEffect(() => {
    if (uiData["messages"]) {
      let refObj = findLast(uiData["messages"], { key: "user_invite" });
      if (refObj && refObj.type === "success") {
        setAddNewUserActive(false);
        setAddNewUserButtonActive(true);
        refObj.key = "user_invited";
      } else if (refObj && refObj.type === "error") {
        setAddNewUserButtonActive(true);
      }
    }
  }, [uiData]);

  useEffect(() => {
    if (uiData["messages"]) {
      let refObj = findLast(uiData["messages"], { key: "user_update" });
      if (refObj && refObj.type === "success") {
        setAddNewUserActive(false);
        setAddNewUserButtonActive(true);
        refObj.key = "user_update_1";
      } else if (refObj && refObj.type === "error") {
        setAddNewUserButtonActive(true);
      }
    }
  }, [uiData]);

  useEffect(() => {
    if (uiData["messages"]) {
      
      let refObj = findLast(uiData["messages"], { key: "user_import" });
      if (refObj && refObj.type === "success") {
        
        setImportUserActive(false);
        refObj.key = "user_imported";
        dispatch({
          type: NETWORK_ADMIN_IMPORT_USERS_SELECT_ALL_FLAG,
          payload: { state: false },
        });
        dispatch({type:NETWORK_ADMIN_UPDATE_USERS_LIST_FOR_IMPORT_USERS, payload:true})
      } else if (refObj && refObj.type === "error") {
        setImportUserActive(true);
      }
    }
  }, [uiData]);

  useEffect(() => {
    if (uiData["messages"]) {
      
      let refObj = findLast(uiData["messages"], { key: "resend_list_success" });
      if (refObj && refObj.type === "success") {
        setResendInviteActive(false);
        refObj.key = "resend_list_succeed";
        dispatch({
          type: NETWORK_ADMIN_RESEND_INVITES_USERS_SELECT_ALL_FLAG,
          payload: { state: false },
        });
        dispatch({type:NETWORK_ADMIN_UPDATE_USERS_LIST_FOR_RESEND_INVITES, payload:true})
      } else if (refObj && refObj.type === "error") {
        setResendInviteActive(true);
      }
    }
  }, [uiData]);

  let isEntityPartnership = false;
  let isEntityOrganisation = false;

  if (!isEmpty(entity) && entity.entity_type == "organization") {
    isEntityOrganisation = true;
  } else if (!isEmpty(entity) && entity.entity_type == "partnership") {
    isEntityPartnership = true;
  }

  useEffect(() => {
    if (!isEmpty(entity)) {
      setShowNetworkError("");
      let contextData = {
        contextType: entity.context_type,
        context: entity.context,
      };
      if (
        (userData &&
          userData.boolean_organization_manage_users) ||
        (userData &&
          userData.boolean_partnership_manage_users)
      ) {
        dispatch(EMDoGetRolesListAction({ contextData }));
        dispatch(EMDoGetEmailTemplateAction({ contextData }));
        dispatch(EMDoGetFilterByGroupDropdownValuesAction({ contextData }));
        dispatch(EMDoGetOrganizationLevelsAction({ orgId: entity.context}));
        dispatch(EMDoGetOrganizationDepartmentsAction({ orgId: entity.context}));
      }
    }
  }, [entity]);

  const networkSelectionHandler = () => {
    if (isEmpty(entity)) {
      setShowNetworkError("Please Select Network Here");
      setIsError(false);
    } else {
      setShowNetworkError("");
      setIsError(true);
    }
  };

  return (
    <EMPage title="Manage Users">
      <Box>
        <Paper elevation={2}>
          {/* <Box height="100%" display="flex" flexDirection="column"> */}
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            p={3}
            justifyItems="center"
            alignItems="center"
          >
            <Grid container>
              <Grid container spacing={40} style={{ maxWidth: "100%" }}>
                <Grid className={classes.width}>
                  <EMEntityDropdown
                    // data = {networkInfo}
                    errorText={showNetworkError}
                    onSelect={(data) => {
                      setEntity(data);
                    }}
                  />
                </Grid>
                <Grid
                  container
                  className={classes.justifyEnd}
                  item
                  xs={6}
                  sm={8}
                  md={8}
                  lg={8}
                >
                  <Button
                    disabled={isEntityOrganisation || !disabledValue}
                    className={[classes.button, classes.buttonWidth]}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      networkSelectionHandler();
                      if (!isEmpty(entity)) {
                        dispatch({type:NETWORK_ADMIN_UPDATE_USERS_LIST_FOR_IMPORT_USERS, payload:false})
                        setImportUserActive(true);
                      }
                    }}
                  >
                    {"Import Users"}
                  </Button>
                  <Button
                    disabled={isEntityPartnership || !disabledValue}
                    className={[classes.button, classes.buttonWidth]}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      networkSelectionHandler();
                      if (!isEmpty(entity)) {
                        dispatch({type:NETWORK_ADMIN_UPDATE_USERS_LIST_FOR_RESEND_INVITES, payload:false})
                        setResendInviteActive(true);
                      }
                    }}
                  >
                    {"Resend Invites"}
                  </Button>
                  <Button
                    disabled={isEntityPartnership || !disabledValue}
                    className={[classes.button, classes.buttonWidth]}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      networkSelectionHandler();
                      if (!isEmpty(entity)) {
                        setBulkUploadActive(true);
                      }
                    }}
                  >
                    {"Bulk Upload"}
                  </Button>
                </Grid>
              </Grid>
              <Box>
                <EMImportUsers
                  open={importUserActive}
                  close={() => {
                    setImportUserActive(false);
                  }}
                  closeHandler={setImportUserActive}
                  userData={editData}
                  entity={entity}
                />
              </Box>
              <Box>
                <EMResendInvites
                  open={resendInviteActive}
                  close={() => {
                    setResendInviteActive(false);
                  }}
                  closeHandler={setResendInviteActive}
                  entity={entity}
                  userData={editData}
                />
              </Box>
              <Box>
                <EMBulkUpload
                  open={bulkUploadActive}
                  close={() => {
                    setBulkUploadActive(false);
                    setBulkUploadData({});
                  }}
                  entity={entity}
                  userData={editData}
                />
              </Box>
              <Box>
                <EMAddNewUser
                  isEntityOrganisation={isEntityOrganisation}
                  data={roleData}
                  open={addNewUserActive}
                  entity={entity}
                  pagelabel={showLabels.label}
                  buttonLabel={showLabels.buttonLabel}
                  doweHaveTemplate={isEdit}
                  permissionData={permissionData}
                  close={() => {
                    setAddNewUserActive(false);
                    setEditData({});
                  }}
                  userData={editData}
                  //onSubmit state
                  shouldButtonActive={addNewUserButtonActive}
                  setShouldButtonActive={setAddNewUserButtonActive}
                />
              </Box>
              <Grid
                container
                style={{ maxWidth: "100%" }}
                justify="flex-start"
                alignItems="center"
                item
                xs={12}
                sm={6}
                md={6}
                lg={8}
              >
                <Box
                  width="100%"
                  justify="flex-start"
                  className={classes.widthForSearchBar}
                >
                  <EMDatatableSearchView
                    hint={"Search by First Name, Last Name, E-mail Address"}
                    onChangeListener={(data) => {
                      setFilter(data);
                    }}
                  />
                </Box>
              </Grid>
              <Grid
                container
                className={classes.justifyEnd}
                item
                xs={12}
                sm={8}
                md={6}
                lg={4}
              >
                <Button
                  disabled={!disabledValue}
                  className={[classes.button, classes.buttonWidth]}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    networkSelectionHandler();
                    if (!isEmpty(entity)) {
                      if (tableData) {
                        let contextData = {
                          contextType: entity.context_type,
                          context: entity.context,
                        };
                        let data = {
                          ...tableData,
                          key: "role_permission_add_edit",
                        };
                        dispatch(
                          EMDoExportNetworkAdminUsersAction({
                            data,
                            contextData,
                            key: "user_export",
                          })
                        );
                      }
                    }
                  }}
                >
                  {"Export Users"}
                </Button>
                <Button
                  disabled={isEntityPartnership || !disabledValue}
                  className={[classes.button, classes.buttonWidth]}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    networkSelectionHandler();
                    if (!isEmpty(entity)) {
                      setAddNewUserActive(true);
                      setShowLabels({
                        label: "Add A New User",
                        buttonLabel: "Invite",
                      });
                      setIsEdit(true);
                      setEditData({});
                      setAddNewUserButtonActive(true);
                    }
                  }}
                >
                  {"Add New User"}
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Modal
            style={{ marginTop: window.screen.height / 2 }}
            open={uiData.loading.state}
          >
            <Box
              style={{ display: "flex" }}
              alignItems="center"
              justifyContent="center"
            >
              <Box textAlign="center">
                <CircularProgress />
                <Typography style={{ fontWeight: "medium", fontSize: "large" }}>
                  {uiData.loading.message}
                </Typography>
              </Box>
            </Box>
          </Modal>

          <EMUsersTable
            filter={filter}
            entity={entity}
            permissionData={permissionData}
            disableCondition={entity && entity.entity_type === "organization" ? false : true}
            onEdit={(userData) => {
              setEditData(userData);
              setAddNewUserActive(true);
              networkSelectionHandler();
              setShowLabels({
                label: "Edit User Details",
                buttonLabel: "Update",
              });
              setIsEdit(false);
            }}
          />
          {/* </Box> */}
        </Paper>
      </Box>
    </EMPage>
  );
}
