import React, { useCallback, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {
  EMDoGetListOfAllGroupsAction,
  EMDoGetFilteredGroupsWithPartenershipsAndOrganizationsAction,
  EMDoGetFilteredGroupMembersWithOrganizationAndPartnershipsAction,
  EMDoGetMembersOfGroupAction,
} from "../../redux/actions/UserPlatform/Groups/EMGroupsAction";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Paper, Typography } from "@material-ui/core";
import { ReactComponent as CategoryIcon } from "../../assets/Icon-awesome-tag.svg";
import {
  EMDoGetFilteredMembersWithPartenershipsAndOrganizationsAction,
  EMDoGetListOfMembersOfOtherOrganizationAction,
} from "../../redux/actions/UserPlatform/People/EMPeopleActions";
import {
  DISABLE_ENABLE_FILTER,
  SHOW_NEXT_MEMBERS,
} from "../../redux/constants/UserPlatform/EMGroupConstant";
import { SHOW_NEXT_MEMBERS_SEARCHOTHERS } from "../../redux/constants/UserPlatform/EMPeopleConstant";
import { LocalOffer } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    paddingTop: theme.spacing(1.5),
    fontWeight: "bolder",
    fontSize: "large",
    width: "100%",
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 2,
    wordBreak: "break-word",
    overflow: "hidden",
  },
  typo: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 2,
    wordBreak: "break-word",
    overflow: "hidden",
  },
}));

export default function CheckboxesGroup({
  filter_name,
  network,
  network_filter,
  route,
  search,
  disabled,
  ...props
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.groups || {});
  const peopleData = useSelector((state) => state.myPeople);
  const [selectedNetworks, setSelectedNetworks] = React.useState([]);
  const [limit, setLimit] = React.useState(0);
  const [limitWhileFiltering, setLimitWhileFiltering] = React.useState(0);

  return (
    <Paper style={{ padding: "5%" }}>
      <Grid container direction="row">
        <Grid item xs={2}>
          <LocalOffer fontSize="large" color="primary" />
        </Grid>
        <Grid item xs={10}>
          <Typography
            style={{
              color: "#2F92D6",
              fontWeight: "bolder",
              fontSize: "medium",
            }}
          >
            {filter_name}
          </Typography>
        </Grid>
      </Grid>
      <FormControl className={classes.formControl}>
        {network.map((singleNetwork, key) => (
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={disabled}
                  checked={singleNetwork.value}
                  onChange={(event) => {
                    dispatch({
                      type: DISABLE_ENABLE_FILTER,
                      payload: true,
                    });
                    let arr;
                    if (event.target.checked) {
                      let checkedValue = event.target.name;
                      arr =
                        selectedNetworks.length > 0
                          ? [...selectedNetworks, singleNetwork]
                          : [singleNetwork];
                      setSelectedNetworks(arr);
                      dispatch({
                        type: network_filter,
                        payload: {
                          checked_value: !singleNetwork.value,
                          postIndex: key,
                        },
                      });
                    } else {
                      //find this value in array and delete it
                      let checkedValue = event.target.name;
                      let index = selectedNetworks.findIndex(
                        (x) => x.context_name == checkedValue
                      );
                      selectedNetworks.splice(index, 1);
                      arr = selectedNetworks;
                      dispatch({
                        type: network_filter,
                        payload: {
                          checked_value: !singleNetwork.value,
                          postIndex: key,
                        },
                      });
                    }
                    if (arr.length > 0 && route === "browse_groups") {
                      if (arr.length == 1) {
                        let selectedNetwork = arr[0];
                        let action =
                          selectedNetwork.context_type_name +
                          "=" +
                          selectedNetwork.context;
                        if (
                          selectedNetwork.context_type_name === "partnership"
                        ) {
                          action = `${selectedNetwork.context_type_name}s=${selectedNetwork.context}`;
                        }
                        props.handleLimitWhileFilteringInGroups(limitWhileFiltering);
                        if (search.length > 0) {
                          action = action + "&" + "search=" + search;
                        }
                        dispatch(
                          EMDoGetFilteredGroupsWithPartenershipsAndOrganizationsAction(
                            {
                              myaction: action,
                              limit: 0,
                            }
                          )
                        );
                      } else if (arr.length > 1) {
                        let action;
                        let partnershipAction;
                        let organizationAction;
                        let partnershipIds = [];
                        let orgIds = [];
                        arr.map((selectedNetwork, key) => {
                          if (
                            selectedNetwork.context_type_name === "partnership"
                          ) {
                            partnershipIds.push(selectedNetwork.context);
                            partnershipAction = `${
                              selectedNetwork.context_type_name
                            }s=${partnershipIds.toString()}`;
                          }
                          if (
                            selectedNetwork.context_type_name === "organization"
                          ) {
                            orgIds.push(selectedNetwork.context);
                            organizationAction = `${
                              selectedNetwork.context_type_name
                            }=${orgIds.toString()}`;
                          }
                          if (organizationAction != undefined) {
                            action = organizationAction;
                          }
                          if (organizationAction != undefined) {
                            action = organizationAction;
                          }
                          if (partnershipAction != undefined) {
                            action = partnershipAction;
                          }
                          if (
                            organizationAction != undefined &&
                            partnershipAction != undefined
                          ) {
                            action =
                              organizationAction + "&" + partnershipAction;
                          }
                          if (search.length > 0) {
                            action = action + "&" + "search=" + search;
                          }
                        });
                        props.handleLimitWhileFilteringInGroups(limitWhileFiltering);
                        dispatch(
                          EMDoGetFilteredGroupsWithPartenershipsAndOrganizationsAction(
                            {
                              myaction: action,
                              limit: 0,
                            }
                          )
                        );
                      }
                    } else if (
                      arr.length == 0 &&
                      route === "browse_groups" &&
                      search.length == 0
                    ) {
                      props.handleLimitInGroups(limit);
                      dispatch(EMDoGetListOfAllGroupsAction({limit:0}));
                    } else if (
                      arr.length == 0 &&
                      route === "browse_groups" &&
                      search.length > 0
                    ) {
                      props.handleLimitWhileSearchingInGroups(0);
                      let action = "search=" + search;
                      dispatch(
                        EMDoGetFilteredGroupsWithPartenershipsAndOrganizationsAction(
                          {
                            myaction: action,
                            limit: 0,
                          }
                        )
                      );
                    }
                    //for group_ Members Network Filter
                    if (arr.length > 0 && route === "group_members") {
                      if (arr.length == 1) {
                        let selectedNetwork = arr[0];
                        let action =
                          selectedNetwork.context_type_name +
                          "=" +
                          selectedNetwork.context;
                        props.handleLimitWhileFiltering(limitWhileFiltering);
                        if (search.length > 0) {
                          action = action + "&" + "search=" + search;
                        }
                        dispatch(
                          EMDoGetFilteredGroupMembersWithOrganizationAndPartnershipsAction(
                            {
                              myaction: action,
                              id: data.singleGroup.id
                                ? data.singleGroup.id
                                : window.sessionStorage.getItem("GroupId"),
                              limit: 0,
                            }
                          )
                        );

                        dispatch({
                          type: SHOW_NEXT_MEMBERS,
                          payload: {
                            nextMembers: 1,
                          },
                        });
                      } else if (arr.length > 1) {
                        let organizationAction;
                        let orgIds = [];
                        let action;
                        arr.map((selectedNetwork, key) => {
                          if (
                            selectedNetwork.context_type_name === "organization"
                          ) {
                            orgIds.push(selectedNetwork.context);
                            organizationAction = `${
                              selectedNetwork.context_type_name
                            }=${orgIds.toString()}`;
                          }
                          if (organizationAction != undefined) {
                            action = organizationAction;
                          }
                        });
                        if (search.length > 0) {
                          action = action + "&" + "search=" + search;
                        }
                        props.handleLimitWhileFiltering(limitWhileFiltering);
                        dispatch(
                          EMDoGetFilteredGroupMembersWithOrganizationAndPartnershipsAction(
                            {
                              myaction: action,
                              id: data.singleGroup.id
                                ? data.singleGroup.id
                                : window.sessionStorage.getItem("GroupId"),
                              limit: 0,
                            }
                          )
                        );
                        dispatch({
                          type: SHOW_NEXT_MEMBERS,
                          payload: {
                            nextMembers: arr.length,
                          },
                        });
                      }
                    } else if (
                      arr.length == 0 &&
                      route === "group_members" &&
                      search.length == 0
                    ) {
                      props.handleLimit(limit);
                      dispatch(
                        EMDoGetMembersOfGroupAction({
                          id: data.singleGroup.id
                            ? data.singleGroup.id
                            : window.sessionStorage.getItem("GroupId"),
                          limit: 0,
                        })
                      );
                      dispatch({
                        type: SHOW_NEXT_MEMBERS,
                        payload: {
                          nextMembers: 0,
                        },
                      });
                    } else if (
                      arr.length == 0 &&
                      route === "group_members" &&
                      search.length > 0
                    ) {
                      props.handleLimitWhileSearching(0);
                      let action = "search=" + search;
                      dispatch({
                        type: SHOW_NEXT_MEMBERS,
                        payload: {
                          nextMembers: 0,
                        },
                      });
                      dispatch(
                        EMDoGetFilteredGroupMembersWithOrganizationAndPartnershipsAction(
                          {
                            myaction: action,
                            id: data.singleGroup.id
                              ? data.singleGroup.id
                              : window.sessionStorage.getItem("GroupId"),
                            limit: 0,
                          }
                        )
                      );
                    }
                    if (arr.length > 0 && route === "search_others") {
                      if (arr.length == 1) {
                        let selectedNetwork = arr[0];
                        let action =
                          selectedNetwork.context_type_name +
                          "=" +
                          selectedNetwork.context;
                        if (
                          selectedNetwork.context_type_name === "partnership"
                        ) {
                          action = `${selectedNetwork.context_type_name}s=${selectedNetwork.context}`;
                        }
                        props.handleLimitWhileFilteringInSearchOthers(
                          limitWhileFiltering
                        );
                        if (search.length > 0) {
                          action = action + "&" + "search=" + search;
                        }
                        dispatch({
                          type: SHOW_NEXT_MEMBERS_SEARCHOTHERS,
                          payload: {
                            nextMembers: 1,
                          },
                        });
                        dispatch(
                          EMDoGetFilteredMembersWithPartenershipsAndOrganizationsAction(
                            { action: action, limit: 0 }
                          )
                        );
                      } else if (arr.length > 1) {
                        let action;
                        let partnershipAction;
                        let organizationAction;
                        let partnershipIds = [];
                        let orgIds = [];
                        arr.map((selectedNetwork, key) => {
                          if (
                            selectedNetwork.context_type_name === "partnership"
                          ) {
                            partnershipIds.push(selectedNetwork.context);
                            partnershipAction = `${
                              selectedNetwork.context_type_name
                            }s=${partnershipIds.toString()}`;
                          }
                          if (
                            selectedNetwork.context_type_name === "organization"
                          ) {
                            if (
                              selectedNetwork.context_type_name ===
                              "organization"
                            ) {
                              orgIds.push(selectedNetwork.context);
                              organizationAction = `${
                                selectedNetwork.context_type_name
                              }=${orgIds.toString()}`;
                            }
                          }
                          if (organizationAction != undefined) {
                            action = organizationAction;
                          }
                          if (organizationAction != undefined) {
                            action = organizationAction;
                          }
                          if (partnershipAction != undefined) {
                            action = partnershipAction;
                          }
                          if (
                            organizationAction != undefined &&
                            partnershipAction != undefined
                          ) {
                            action =
                              organizationAction + "&" + partnershipAction;
                          }
                        });
                        props.handleLimitWhileFilteringInSearchOthers(
                          limitWhileFiltering
                        );
                        if (search.length > 0) {
                          action = action + "&" + "search=" + search;
                        }
                        dispatch({
                          type: SHOW_NEXT_MEMBERS_SEARCHOTHERS,
                          payload: {
                            nextMembers: arr.length,
                          },
                        });
                        dispatch(
                          EMDoGetFilteredMembersWithPartenershipsAndOrganizationsAction(
                            { action: action, limit: 0 }
                          )
                        );
                      }
                    } else if (
                      arr.length == 0 &&
                      route === "search_others" &&
                      search.length == 0
                    ) {
                      props.handleLimitInSearchOthers(limit);
                      dispatch({
                        type: SHOW_NEXT_MEMBERS_SEARCHOTHERS,
                        payload: {
                          nextMembers: 0,
                        },
                      });
                      dispatch(
                        EMDoGetListOfMembersOfOtherOrganizationAction({
                          limit: 0,
                        })
                      );
                    } else if (
                      arr.length == 0 &&
                      route === "search_others" &&
                      search.length > 0
                    ) {
                      props.handleLimitWhileSearchingInSearchOthers(0);
                      let action = "search=" + search;
                      dispatch({
                        type: SHOW_NEXT_MEMBERS_SEARCHOTHERS,
                        payload: {
                          nextMembers: 0,
                        },
                      });
                      dispatch(
                        EMDoGetFilteredMembersWithPartenershipsAndOrganizationsAction(
                          { action: action, limit: 0 }
                        )
                      );
                    }
                  }}
                  name={singleNetwork.context_name}
                />
              }
              label={singleNetwork.context_name}
              key={singleNetwork.id}
            />
          </FormGroup>
        ))}
      </FormControl>
    </Paper>
  );
}
