import React, { useEffect, useRef } from "react";
import EMPage from "../../../../components/commans/EMPage";
import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  makeStyles,
  GridList,
  IconButton,
  Tooltip,
  Modal,
  CircularProgress,
} from "@material-ui/core";
import { useFormik, Field } from "formik";
import EMEntityDropdown from "../../UserManagement/commons/EMEntityDropdown";
import EMOrganisationDetails from "./EMOrganisationDetails";
import EMContactDetails from "./EMContactDetails";
import EMDropdown from "../../../../components/commans/EMDropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  EMDoUpdateNetworkDetailsAction,
  EMNetworkDetailsUpdateFileUploadAction,
  EMDoGetNetworkDetailsListAction,
  EMDoGetFileUploadDetailsAction,
  EMDoGetListOfNetworksForDropdownAction,
  EMDoGetPermissionSpecificToNetworkAction,
} from "../../../../redux/actions/EMNetworkAdminActions";
import { isEmpty, find, findIndex } from "lodash";
import {
  NETWORK_DETAILS_LIST_SUCCESS,
  NETWORK_FILE_UPLOAD_DETAILS,
  RESET,
} from "../../../../redux/constants";
import * as yup from "yup";
import { EMDoGetEntityInformationBasedOnPermissionAction } from "../../../../redux/actions/EMAuthActions";
import { CloseRounded } from "@material-ui/icons";
import EMFileSelect from "../../../../components/commans/EMFileSelect";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

const useStyles = makeStyles((theme) => ({
  root: {
    // height: '90vh',
  },
  paper: {
    marginLeft: "3%",
  },
  orgDetails: {
    marginBottom: "10%",
  },
  rootForGrid: {
    marginTop: "2%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    // padding:"10px",
    width: "100%",
    height: "100%",
    maxHeight: window.screen.height / 2.5,
    "&::-webkit-scrollbar": {
      width: "0.1em",
    },
    // "&::-webkit-scrollbar-track": {
    //   boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    //   webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    // },
    // "&::-webkit-scrollbar-thumb": {
    //   backgroundColor: "#4A4A4A",
    //   outline: "1px solid slategrey",
    // },
  },
  container: {},
  attachmentBtnStyle:{
    [theme.breakpoints.up("xs")]: {
      marginLeft:"3rem"

    },
    [theme.breakpoints.up("sm")]: {
      marginLeft:"2rem"
    },
    [theme.breakpoints.up("md")]: {
      marginLeft:"2rem"
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft:"-0.5rem"
    },
  },
  typo: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 1,
    wordBreak: "break-word",
    overflow: "hidden",
  },
}));
export default function NetworkOrganizationDetails(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const data = useSelector((state) => state.networkAdmin["networkListForUserDropdown"]);
  const entity = useSelector(
    (state) => state.networkAdmin["networkDetailInfo"]
  );
  let fileUploadDetails = useSelector(
    (state) => state.networkAdmin["networkFileUploadDetails"]
  );

  const [entityId, setEntityId] = React.useState();
  const [showNetworkError, setShowNetworkError] = React.useState();
  const [isPartnerOrOrg, setIsPartnerOrOrg] = React.useState("Enter");
  const [entityIsPartnership, setEntityIsPartnership] = React.useState(false);
  //field specific states
  const [vision, setVision] = React.useState();
  const [mission, setMission] = React.useState();
  const [iconFile, setIconFile] = React.useState();
  const [iconFileName, setIconFileName] = React.useState();
  const [faviconFile, setFaviconFile] = React.useState();
  const [faviconFileName, setFaviconFileName] = React.useState();
  const [policyFile, setPolicyFile] = React.useState();
  const [policyFileName, setPolicyFileName] = React.useState();
  const [entityDetails, setEntityDetails] = React.useState();
  const [iconPrevFile, setIconPrevFile] = React.useState({});
  const [faviconPrevFile, setFaviconPrevFile] = React.useState({});
  const [policyPrevFile, setPolicyPrevFile] = React.useState({});
  const [initialEntity, setinitialEntity] = React.useState();
  const [additionalInfo, setAdditionalInfo] = React.useState();
  const [attachments, setAttachments] = React.useState([]);
  const [prevAttachments, setPrevAttachments] = React.useState([]);
  const [deletedDocs, setDeletedDocs] = React.useState([]);
  const uiData = useSelector((state) => state.ui);
  const [permissionData, setPermissionData] = React.useState();
  const networkSpecificPermissions = useSelector(state => state.networkAdmin.networkSpecificRolesAndPermissions)

  useEffect(()=>{
    if(networkSpecificPermissions && entityId){
      let permission = find(networkSpecificPermissions,{context:entityId.context})
      let specificPermission = find(permission && permission.rolePermissions,{permission_name:"manage_profile_page"})
      setPermissionData(specificPermission)
    }
  },[networkSpecificPermissions])

  //call dropdown Api
  useEffect(() => {
    dispatch(EMDoGetListOfNetworksForDropdownAction());
  }, []);

  useEffect(() => {
    if(entityId){
      dispatch(EMDoGetPermissionSpecificToNetworkAction({id:entityId.context, networkType:entityId.entity_type}))
    }
  },[entityId])

  let isDisabled = (permissionData && permissionData["edit_flag"]) || true;

  /* file upload Handlers */
  const iconFileUploadHandler = (file,) => {
    
    setIconFile(file);
    // setIconFileName(fileName);
  };

  const faviconFileUploadHandler = (file, fileName) => {
    setFaviconFile(file);
    setFaviconFileName(fileName);
  };

  const policyFileUploadHandler = (file, fileName) => {
    setPolicyFile(file);
    setPolicyFileName(fileName);
  };

  /* vision and Mission state update function */
  const visionChangeHandler = (value) => {
    setVision(value);
  };

  const missionChangeHandler = (value) => {
    setMission(value);
  };

  const additionalInfoChangeHandler = (value) => {
    setAdditionalInfo(value);
  };

  /* check if Entity is selected or not */
  const networkSelectionHandler = () => {
    if (isEmpty(entityId)) {
      setShowNetworkError("Please Select Network");
    }
  };

  useEffect(() => {
    if (entity) {
      setPrevAttachments(entity.attachments);
    }
  }, [entity]);


  useEffect(() => {
    dispatch({type:RESET})
    setPrevAttachments([])
    setIconPrevFile({
      name: "",
      link: "",
    });
    setPolicyPrevFile({
      name: "",
      link: "",
    });
    setFaviconPrevFile({
      name: "",
      link: "",
    });
  },[])

  const phoneRegExp =
    /^([\+,-,()]?)|\d{6}([- ]*)\d{9}|((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  /* Formik Validation Object */
  const validationSchema = yup.object({
    name: yup.string().trim().required(" Name is required "),
    tagline: yup.string(),
    email: yup.string().email("Email must be a valid email address"),
    phone: yup
      .string("Only Numbers are allowed")
      .trim()
      .matches(phoneRegExp, "Phone number is not valid")
      .max(15),
    address: yup.string().trim().max(255),
    city: yup.string().trim(),
    zipCode: yup.string().trim()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(5, 'Zip code must be 5 digits')
    .max(5, 'Zip code must be 5 digits'),
    website: yup.string().trim(),
  });

  useEffect(() => {
    setIconFile();
    setIconFileName();
    setPolicyFile();
    setPolicyFileName();
      if ((fileUploadDetails !== undefined) && !isEmpty(fileUploadDetails) && (fileUploadDetails["deandi_policy_file"] != null) ) {
        let policyFile = fileUploadDetails["deandi_policy_file"];
        let policyFileName = policyFile.substring(
          policyFile.lastIndexOf("/") + 1,
          policyFile.length
        );
        setPolicyPrevFile({
          name: policyFileName,
          link: policyFile,
        });
      } else {
        setPolicyPrevFile({
          name: "",
          link: "",
        });
      }
      if ((fileUploadDetails !== undefined) && !isEmpty(fileUploadDetails) && (fileUploadDetails["favicon_file"] != null)) {
        let faviconFile = fileUploadDetails["favicon_file"];
        let faviconFileName = faviconFile.substring(
          faviconFile.lastIndexOf("/") + 1,
          faviconFile.length
        );
        setFaviconPrevFile({
          name: faviconFileName,
          link: faviconFile,
        });
      }else {
        setFaviconPrevFile({
          name: "",
          link: "",
        });
      }
      if ((fileUploadDetails !== undefined) && !isEmpty(fileUploadDetails) && (fileUploadDetails["logo_file"] != null)) {
        let logoFile = fileUploadDetails["logo_file"];
        let logoFileName = logoFile.substring(
          logoFile.lastIndexOf("/") + 1,
          logoFile.length
        );
        setIconPrevFile({
          name: logoFileName,
          link: logoFile,
        });
      } else {
        setIconPrevFile({
          name: "",
          link: "",
        });
      }
    // }
  }, [fileUploadDetails]);

  const changeNetworkHandler = (selected) => {
    setShowNetworkError("");
    setEntityId(selected);
    let contextData = {
      contextType: selected.context_type,
      context: selected.context,
      entity: selected.entity_type,
    };
    dispatch(
      EMDoGetNetworkDetailsListAction({
        contextData,
      })
    );
    if (selected.entity_type == "organization") {
      setIsPartnerOrOrg("Organization");
      setEntityIsPartnership(false);
    } else if (selected.entity_type == "partnership") {
      setIsPartnerOrOrg("Partner Network");
      setEntityIsPartnership(true);
    }
    contextData = {
      context: selected.context,
      entity: selected.entity_type,
    };
    fileUploadDetails = {};
    dispatch(
      EMDoGetFileUploadDetailsAction({
        contextData,
      })
    );
    setIconFile();
    setIconFileName();
    setFaviconFile();
    setFaviconFileName();
    setAttachments([]);
    setPrevAttachments([]);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: (entity && entity.name) || "",
      tagline: (entity && entity["tagline"]) || "",
      vision: ((entity && entity["vision"]) || ""),
      mission: ((entity && entity["mission"]) || ""),
      email: (entity && entity["email"]) || "",
      phone: (entity && entity["phone"]) || "",
      website: (entity && entity["website"]) || "",
      additionalInfo: (entity && entity["additionalInfo"]) || "",
      address: (entity && entity["address"]) || "",
      city: (entity && entity["city"]) || "",
      zipCode: (entity && entity["zipCode"]) || "",
      state: (entity && entity["state"]) || "",
      country: (entity && entity["country"]) || "",
    },

    //initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (isEmpty(vision)) {
        setVision(values.vision);
      }
      if (isEmpty(mission)) {
        setMission(values.mission);
      }
      let entiyDetail = {
        name: values.name.trim(),
        tagline: values.tagline.trim(),
        vision: vision || "",
        mission: mission || "",
        email: (values.email && values.email.trim()).toLowerCase() || "",
        phone: values.phone,
        website: values.website.trim(),
        additionalInfo: additionalInfo || "",
        new_attachments: attachments,
        deleted_attachments: deletedDocs.toString(),
      }; 
      let contactDetail = {
        address: values.address,
        city: values.city,
        zipCode: values.zipCode,
        state: values.state || "",
        country: values.country || "",
      };
      
      let files = {};
      if (iconFile) {
        files["icon"] = iconFile 
      }
      if (!isEmpty(faviconFile)) {
        files["favicon"] = faviconFile;
      }
      if (!isEmpty(policyFile)) {
        files["policy"] = policyFile;
      }

      let contextData = {
        contextType: entity.context_type,
        context: entity.id,
        entity: entity.entity,
      };
      let data;
      if (entityIsPartnership) {
        data = {
          ...entiyDetail,
          contextData,
          key: "user_add_edit",
          showmsg: isPartnerOrOrg,
        };
      } else {
        data = {
          ...entiyDetail,
          ...contactDetail,
          contextData,
          key: "user_add_edit",
          showmsg: isPartnerOrOrg,
        };
      }
      // action for string data
      
      dispatch(
        EMDoUpdateNetworkDetailsAction({
          data,
          contextData,
          files,
        })
      );
      //action for file data

      contextData = {
        contextType: entity.context_type,
        context: entity.id,
        entity: entity.entity,
      };

      if (!isEmpty(files)) {
        dispatch(
          EMNetworkDetailsUpdateFileUploadAction({
            contextData,
            files,
          })
        );
      }
      formik.resetForm(formik.initialValues);
      setPolicyPrevFile({
        name: "",
        link: "",
      });
      setFaviconPrevFile({
        name: "",
        link: "",
      });
      setIconPrevFile({
        name: "",
        link: "",
      });
      setIconFile();
      setIconFileName();
      setFaviconFile();
      setFaviconFileName();
      setPolicyFile();
      setPolicyFileName();
      setAttachments([]);
      setPrevAttachments([]);
    },
  });

  return (
    <EMPage title="Network Management" >
      <Box className={classes.root}>
      <Modal style={{display:"flex", alignItems:"center", justifyContent:"center"}} open={uiData.loading.state}>
          <Box style={{display:"flex"}} alignItems="center" justifyContent="center">
            <Box textAlign="center">
          <CircularProgress />
          <Typography style={{fontWeight:"medium", fontSize:"small"}}>{uiData.loading.message}</Typography>
          </Box>
          </Box>
          </Modal>
        <Paper elevation={2}>
          <form onSubmit={formik.handleSubmit}>
            <Box className={classes.paper} style={{ paddingTop: "1%" }}>
              <Grid justify="flex-start" item xs={12} sm={6} md={4}>
                 <EMEntityDropdown
                    // data={networkInfo}
                    errorText={showNetworkError}
                    onSelect={(selected) => {
                      changeNetworkHandler(selected);
                    }}
                  />
              </Grid>
            </Box>
            <Box
              className={classes.paper}
              onClick={() => {
                networkSelectionHandler();
              }}
            >
              <Grid container className={classes.container} spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <Grid justify="flex-start" className={classes.orgDetails}>
                    <EMOrganisationDetails
                    entityId={entityId}
                      labelDetails={isPartnerOrOrg + " Details"}
                      onChange={formik.handleChange}
                      //for name field
                      nameValue={formik.values.name}
                      nameId={"name"}
                      nameFormikError={
                        formik.touched.name && Boolean(formik.errors.name)
                      }
                      nameHelperText={formik.touched.name && formik.errors.name}
                      //for tagline
                      tagValue={formik.values.tagline}
                      taglineId={"tagline"}
                      taglineFormikError={
                        formik.touched.tagline && Boolean(formik.errors.tagline)
                      }
                      taglineHelperText={
                        formik.touched.tagline && formik.errors.tagline
                      }
                      //for vision & mission
                      visionValue={formik.values.vision}
                      visionHandler={visionChangeHandler}
                      missionHandler={missionChangeHandler}
                      missionValue={formik.values.mission}
                      //for file upload
                      //icon
                      iconId={"icon"}
                      iconChangeHandler={iconFileUploadHandler}
                      iconFileName={iconFileName}
                      previconFileName={iconPrevFile.name}
                      previconFileLink={iconPrevFile.link}
                      //favicon
                      faviconId={"favicon"}
                      faviconChangeHandler={faviconFileUploadHandler}
                      faviconFileName={faviconFileName}
                      prevfaviconFileName={faviconPrevFile.name}
                      prevfaviconFileLink={faviconPrevFile.link}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Grid justify="center">
                    <EMContactDetails
                      entityIsPartnership={entityIsPartnership}
                      labelDetails={"Contact Details"}
                      onChange={formik.handleChange}
                      //additionalInfo
                      additionalInfoRichTextValue={formik.values.additionalInfo}
                      additionalInfoRichText={additionalInfo}
                      additionalInfoRichTextHandler={
                        additionalInfoChangeHandler
                      }
                      //country and state field

                      countryId={"country"}
                      stateId={"state"}
                      countryValue={formik.values.country}
                      stateValue={formik.values.state}
                      countryFormikError={
                        formik.touched.country && Boolean(formik.errors.country)
                      }
                      countryHelperText={
                        formik.touched.country && formik.errors.country
                      }
                      stateFormikError={
                        formik.touched.state && Boolean(formik.errors.state)
                      }
                      stateHelperText={
                        formik.touched.state && formik.errors.state
                      }
                      //email
                      emailId={"email"}
                      emailvalue={formik.values.email.trim().toLowerCase()}
                      emailFormikError={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      emailHelperText={
                        formik.touched.email && formik.errors.email
                      }
                      //phone
                      phoneId={"phone"}
                      phoneValue={formik.values.phone}
                      phoneFormikError={
                        formik.touched.phone && Boolean(formik.errors.phone)
                      }
                      phoneHelperText={
                        formik.touched.phone && formik.errors.phone
                      }
                      //address
                      addressId={"address"}
                      addressValue={formik.values.address}
                      addressFormikError={
                        formik.touched.address && Boolean(formik.errors.address)
                      }
                      addressHelperText={
                        formik.touched.address && formik.errors.address
                      }
                      //city
                      cityId={"city"}
                      cityValue={formik.values.city}
                      cityFormikError={
                        formik.touched.city && Boolean(formik.errors.city)
                      }
                      cityHelperText={formik.touched.city && formik.errors.city}
                      //zipCode
                      zipValue={formik.values.zipCode}
                      zipCodeId={"zipCode"}
                      zipCodeFormikError={
                        formik.touched.zipCode && Boolean(formik.errors.zipCode)
                      }
                      zipCodeHelperText={
                        formik.touched.zipCode && formik.errors.zipCode
                      }
                      //website
                      websiteId={"website"}
                      websiteValue={formik.values.website}
                      websiteFormikError={
                        formik.touched.website && Boolean(formik.errors.website)
                      }
                      websiteHelperText={
                        formik.touched.website && formik.errors.website
                      }
                      //additionalInfo
                      additionalInfoId={"additionalInfo"}
                      additionalInfoValue={formik.values.additionalInfo}
                      additionalInfoFormikError={
                        formik.touched.additionalInfo &&
                        Boolean(formik.errors.additionalInfo)
                      }
                      additionalInfoHelperText={
                        formik.touched.additionalInfo &&
                        formik.errors.additionalInfo
                      }
                      //file Upload
                      policyId={"policy"}
                      policyChangeHandler={policyFileUploadHandler}
                      policyFileName={policyFileName}
                      prevPolicyFileName={policyPrevFile.name}
                      prevPolicyFileLink={policyPrevFile.link}
                    />
                  </Grid>
                  <Box p={2} >
                    <Box alignItems="flex-end" justifyContent="center" p={2}>
                      <Typography>Previous Files:</Typography>
                      <div className={classes.rootForGrid}>
                        <GridList
                          cellHeight={20}
                          className={classes.gridList}
                          cols={1}
                        >
                          {prevAttachments &&
                            prevAttachments.map((doc, id) => (
                              <Box display="flex">
                                <Typography className={classes.typo}>
                                  <a
                                    href={
                                      process.env
                                        .REACT_APP_BACKEND_FILE_PREVIEW +
                                      doc.file
                                    }
                                    style={{ color: "#2F92D6" }}
                                    target="_blank"
                                  >
                                    {doc &&
                                      doc.file.substr(
                                        doc && doc.file.lastIndexOf("/") + 1,
                                        doc && doc.length
                                      )}
                                  </a>
                                </Typography>
                                <IconButton
                                  onClick={() => {
                                    
                                    if (deletedDocs.length > 0) {
                                      setDeletedDocs([...deletedDocs, doc.id]);
                                    } else {
                                      setDeletedDocs([doc.id]);
                                    }
                                    let docArray = prevAttachments;
                                    let index = id;
                                    docArray.splice(index, 1);
                                    setPrevAttachments(docArray);
                                  }}
                                >
                                  <CloseRounded color="secondary" />
                                </IconButton>
                              </Box>
                            ))}
                        </GridList>
                      </div>
                    </Box>
                    <Box alignItems="flex-end" justifyContent="center" p={2} >
                      <Typography>Selected Files:</Typography>
                      <div className={classes.rootForGrid}>
                        <GridList
                          cellHeight={20}
                          className={classes.gridList}
                          cols={2}
                        >
                          {attachments &&
                            attachments.map((doc, id) => (
                              <Box key={id}>
                                <Tooltip title={<h2 style={{ color: "white" }}>{doc.name}</h2>} placement="top">
                                  <Typography className={classes.typo}>
                                    <a
                                      href={doc.url}
                                      style={{ color: "#2F92D6" }}
                                      target="_blank"
                                    >
                                      {doc.name}
                                    </a>
                                  </Typography>
                                </Tooltip>
                              </Box>
                            ))}
                        </GridList>
                      </div>
                    </Box>
                    <Box display="flex"  className={classes.attachmentBtnStyle}>
                      <EMFileSelect
                        changeHandler={(file) => {
                          if (file != null) {
                            let data = file;
                            if (data != null) {
                              let newDocuments = [];
                              for (let i = 0; i < data.length; i++) {
                                let file = data[i];
                                let fileObj = file && file;
                                let url = URL.createObjectURL(data[i]);
                                fileObj["url"] = url;
                                newDocuments.push(fileObj);
                              }

                              let documentArray;
                              if (attachments && attachments.length > 0) {
                                let arr = attachments.concat(newDocuments);
                                setAttachments(arr);
                              } else {
                                documentArray = newDocuments;
                                setAttachments(documentArray);
                              }
                            } else {
                              setAttachments([]);
                            } 
                          }
                        }}
                        id={"doc"}
                        accept={[
                          ".pdf",
                          ".txt",
                          ".doc",
                          ".xls",
                          ".pptx",
                          ".ppt",
                          ".docx",
                          ".xlsx",
                          ".png",
                          ".jpg",
                          ".jpeg",
                        ]}
                        fileSize={11534336}
                        supportedFormats={[
                          "text/plain",
                          "application/pdf",
                          "application/doc",
                          "application/ms-doc",
                          "application/msword",
                          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                          "application/mspowerpoint",
                          "application/powerpoint",
                          "application/vnd.ms-powerpoint",
                          "application/x-mspowerpoint",
                          "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                          "application/excel",
                          "application/vnd.ms-excel",
                          "application/x-excel",
                          "application/x-msexcel",
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                          "image/png",
                          "image/jpg",
                          "image/jpeg",
                        ]}
                        maxFileSize={"10MB"}
                        icon={<CloudUploadIcon />}
                        label="ATTACHMENTS"
                        // disabled={
                        //   data.documents && data.documents.length >= 10
                        //     ? true
                        //     : false
                        // }
                        multipleFileSelect={true}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid
                container
                justify="center"
                alignItems="center"
                direction="row"
                className={classes.buttonHolder}
                spacing={2}
              >
                <Grid item>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={networkSelectionHandler}
                  >
                    <Typography variant="h7">Save</Typography>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    className={classes.button}
                    variant="outlined"
                    color="secondary"
                    type="reset"
                    onClick={() => {
                      formik.resetForm(formik.initialValues);
                      setVision(entity && entity["vision"]);
                      setMission(entity && entity["mission"]);
                      setIconFile();
                      setIconFileName();
                      setFaviconFile();
                      setFaviconFileName();
                      setPolicyFile();
                      setPolicyFileName();
                      setAttachments([]);
                      //   setPrevAttachments([])
                    }}
                  >
                    <Typography variant="h7">Discard</Typography>
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Paper>
      </Box>
    </EMPage>
  );
}
