import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import MailIcon from "@material-ui/icons/Mail";
import user_image from "../../assets/user_image.png";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    // marginLeft :"2%",
    // [theme.breakpoints.down("md")]: {
    //   height: "300px",
    // },
    // [theme.breakpoints.up("lg")]: {
    //   height: "300px",
    // },
    backgroundColor: "#d3d3d3",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    // height: "100%",
    // height: "300px",
    width: "100%",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  content: {
    backgroundColor: theme.palette.primary.main,
    // [theme.breakpoints.down("md")]: {
    //   height: "200px",
    // },
    // [theme.breakpoints.up("lg")]: {
    //   height: "300px",
    // },
    // width: "100%",
    paddingTop:"5%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    borderRadius: "none",
  },
  typo1: {
    [theme.breakpoints.down("md")]: {
      height: "25px",
    },
    [theme.breakpoints.up("lg")]: {
      height: "25px",
    },
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 1,
    wordBreak: "break-word",
    overflow: "hidden",
  },
  typo2: {
    [theme.breakpoints.down("md")]: {
      height: "30px",
    },
    [theme.breakpoints.up("lg")]: {
      height: "30px",
    },
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 2,
    wordBreak: "break-word",
    overflow: "hidden",
  },
  button: {
    // [theme.breakpoints.down("md")]: {
    //   height: "15px",
    // },
    // [theme.breakpoints.up("lg")]: {
    //   height: "20px",
    // },
  },
}));

export default function EMUser({
  user,
  profileImage,
  profileClickHandler,
  userFirstName,
  userLastName,
  designation,
  organisationName,
  email,
  linkedinLink,
}) {
  const classes = useStyles();
  let fullName = userFirstName + " " + userLastName;
  let userBrief = designation + ", " + organisationName;

  return (
    <Card className={classes.root}>
      <CardActionArea onClick={profileClickHandler}>
      <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                  // backgroundColor: "#d3d3d3",
                }}
              >

                <img
                  alt=""
                  style={{
                    flexShrink: 0,
                    minWidth: "100%",
                    minHeight: "100%",
                  }}
            src={profileImage.includes("undefined") ? user_image : profileImage}
          />
        </div>
      </CardActionArea>

      <Card className={classes.content}>
        <CardContent style={{ paddingBottom: "0%", paddingTop: "0%" }}>
          <Tooltip
            title={<h2 style={{ color: "white" }}>{fullName}</h2>}
            placement="top"
          >
            <Typography
              className={classes.typo1}
              gutterBottom
              variant="subtitle1"
              style={{ color: "white" }}
            >
              {fullName}
            </Typography>
          </Tooltip>
          <Tooltip
            title={<h2 style={{ color: "white" }}>{userBrief}</h2>}
            placement="top"
          >
            <Typography
              className={classes.typo2}
              variant="body2"
              component="p"
              style={{ color: "white" }}
            >
              {userBrief}
            </Typography>
          </Tooltip>
        </CardContent>

        <CardActions style={{ justifyContent: "space-evenly", height: "30px" }}>
          <Button
            className={classes.button}
            onClick={() => {
              window.location.href = `mailto:${email}`;
            }}
          >
            <MailIcon style={{ color: "white" }} />
          </Button>
          <a
            href={linkedinLink}
            rel="noreferrer"
            target="_blank"
            onClick="javascript.function();"
            style={{ textDecoration: "none" }}
          >
            <Button>
              <LinkedInIcon style={{ color: "white", margin: "auto" }} />
            </Button>
          </a>
        </CardActions>

        {/* </div> */}
      </Card>
    </Card>
  );
}
