import {
  ENTITY_LIST_SUCCESS,
  PRIVACY_LIST_SUCCESS,
  UI_LOADING,
  SHOW_MESSAGE,
  ENTITY_ROLE_CREATE_SUCCESS,
  ENTITY_ROLE_LIST_SUCCESS,
  ENTITY_ROLE_UPDATE_SUCCESS,
  NETWORK_ADMIN_GROUP_LIST_SUCCESS,
  NETWORK_ADMIN_GROUP_UPDATE_SUCCESS,
  NETWORK_ADMIN_GROUP_CREATE_SUCCESS,
  NETWORK_ADMIN_CHANNEL_LIST_SUCCESS,
  NETWORK_ADMIN_CHANNEL_UPDATE_SUCCESS,
  NETWORK_ADMIN_CHANNEL_CREATE_SUCCESS,
  EMAIL_TEMPLATE_LIST_SUCCESS,
  EMAIL_TEMPLATE_UPDATE_SUCCESS,
  EMAIL_TEMPLATE_CREATE_SUCCESS,
  EMAIL_TEMPLATE_TYPE_LIST_SUCCESS,
  NETWOEK_ADMIN_ROLE_LIST_SUCCESS,
  GROUP_LIST_DROPDOWN_SUCCESS,
  NETWORK_ADMIN_USERS_LIST_SUCCESS,
  NETWORK_ADMIN_UPDATE_SUCCESS,
  NETWORK_ADMIN_CREATE_SUCCESS,
  NETWORK_ADMIN_USERS_EXPORT_SUCCESS,
  NETWORK_ADMIN_RESEND_INVITE_SUCCESS,
  NETWORK_ADMIN_IMPORT_USERS_SUCCESS,
  NETWORK_ADMIN_IMPORT_USERS_LIST_SUCCESS,
  NETWORK_ADMIN_BULK_UPLOAD_SUCCESS,
  NETWOEK_ADMIN_EMAIL_TEMPLATE_LIST_SUCCESS,
  NETWORK_LIST_SUCCESS,
  NETWORK_DETAILS_UPDATE_SUCCESS,
  NETWORK_ADMIN_RESEND_USERS_LIST_SUCCESS,
  NETWORK_DETAILS_LIST_SUCCESS,
  NETWORK_FILE_UPLOAD_DETAILS,
  NETWORK_ADMIN_FILTER_BY_GROUP_DROPDOWN_VALUES_SUCCESS,
  NETWORK_ADMIN_FILTER_BY_GROUP_LIST_SUCCESS,
  NETWORK_ADMIN_EMAIL_TEMPLATE,
  ROLES_LIST_SUCCESS,
  ENTITY_ROLE_RETRIEVE_SUCCESS,
  NETWORK_ADMIN_GET_DASHBOARD_DATA,
  NETWORK_ADMIN_GET_ONBOARDING_DATA,
 NETWORK_ADMIN_GET_USAGE_REPORT_DATA ,
 NETWORK_ADMIN_GET_USER_ENGAGEMENT_DATA,
 NETWORK_ADMIN_GET_NETWORK_STATS_DATA,
 NETWORK_ADMIN_ENTITY_LIST,
 NETWORK_ADMIN_GET_ORGANIZATION_DEPARTMENTS,
 NETWORK_ADMIN_GET_ORGANIZATION_LEVELS,
 NETWORK_ADMIN_GET_LEVELS_LIST,
 NETWORK_ADMIN_GET_BADGES_LIST,
 NETWORK_ADMIN_GET_DATA_FOR_DROPDOWN_IN_GAMIFICATION,
 NETWORK_ADMIN_GET_BADGE_REPORT,
 NETWORK_ADMIN_GET_LEVEL_REPORT,
 NETWORK_ADMIN_GET_LIST_OF_NETWORKS_FOR_DROPDOWN,
 NETWORK_ADMIN_GET_PERMISSION_BASED_ON_NETWORK,
 NETWORK_ADMIN_UPDATE_USERS_LIST_FOR_IMPORT_USERS,
 NETWORK_ADMIN_GET_EMAIL_TEMPLATE_BODY
} from "../constants";
import API from "../../api";
import {EMDoGetEntityInformationBasedOnPermissionAction, EMDoGetMultipleProfileDetailsAction} from "./EMAuthActions"
import { handleError } from "../../utils/EMCommonFunctions";
export const EMGetEntityList = action => {
  return dispatch => {
    API.EMDoGetEntityForRoleAndPermission()
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: ENTITY_LIST_SUCCESS,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching entity list",
              key: action.key
            }
          });
        }
      })
      .catch(err => {
        // if (err.response.data.detail) {
        //   dispatch({
        //     type: SHOW_MESSAGE,
        //     payload: {
        //       type: "error",
        //       message: err.response.data.detail
        //     }
        //   })
        // }
        handleError(dispatch, err)
      });
  };
};

//GET network departments
export const EMDoGetListOfOrganizationDepartmentsAction = action => {
  return dispatch => {
    API.EMDoGetListOfOrganizationDepartments(action.organizationId, action.queryData)
      .then(data => data.data)
      .then(data => {
        
        if (data) {
          dispatch({
            type: NETWORK_ADMIN_GET_ORGANIZATION_DEPARTMENTS,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching department list",
              key: action.key
            }
          });
        }
      })
      .catch(err => {
        // if (err.response.data.detail) {
        //   dispatch({
        //     type: SHOW_MESSAGE,
        //     payload: {
        //       type: "error",
        //       message: err.response.data.detail
        //     }
        //   })
        // }
        handleError(dispatch, err)
      });
  };
};

//GET network levels
export const EMDoGetListOfOrganizationLevelsAction = action => {
  return dispatch => {
    API.EMDoGetListOfOrganizationLevels(action.organizationId, action.queryData)
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: NETWORK_ADMIN_GET_ORGANIZATION_LEVELS,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching level list",
              key: action.key
            }
          });
        }
      })
      .catch(err => {
        // if (err.response.data.detail) {
        //   dispatch({
        //     type: SHOW_MESSAGE,
        //     payload: {
        //       type: "error",
        //       message: err.response.data.detail
        //     }
        //   })
        // }
        handleError(dispatch, err)
      });
  };
};

//Add new network department
export const EMDoCreateNewDepartmentInOrganizationAction = action => {
  return dispatch => {
    API.EMDoCreateNewDepartmentInOrganization(action.data)
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch(EMDoGetListOfOrganizationDepartmentsAction({organizationId:action.data.organizationId, queryData:"?limit=15"}));
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Department Added Successfully",
              key: action.key
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching level list",
              key: action.key
            }
          });
        }
      })
      .catch(err => {
        // if (err.response.data.detail) {
        //   dispatch({
        //     type: SHOW_MESSAGE,
        //     payload: {
        //       type: "error",
        //       message: err.response.data.detail
        //     }
        //   })
        // }
        handleError(dispatch, err)
      });
  };
};

//GET network organization
export const EMDoCreateNewLevelInOrganizationAction = action => {
  return dispatch => {
    API.EMDoCreateNewLevelInOrganization(action.data)
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch(EMDoGetListOfOrganizationLevelsAction({organizationId:action.data.organizationId, queryData:"?limit=15"}));
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Level Added Successfully",
              key: action.key
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching level list",
              key: action.key
            }
          });
        }
      })
      .catch(err => {
        if (err.response.data.detail) {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: err.response.data.detail
            }
          })
        }
      });
  };
};

//Add new network department
export const EMDoUpdateDepartmentInOrganizationAction = action => {
  return dispatch => {
    API.EMDoUpdateDepartmentInOrganization(action.data)
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch(EMDoGetListOfOrganizationDepartmentsAction({organizationId:action.data.organizationId, queryData:"?limit=15"}));
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Department Updated Successfully",
              key: action.key
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching level list",
              key: action.key
            }
          });
        }
      })
      .catch(err => {
        // if (err.response.data.detail) {
        //   dispatch({
        //     type: SHOW_MESSAGE,
        //     payload: {
        //       type: "error",
        //       message: err.response.data.detail
        //     }
        //   })
        // }
        handleError(dispatch, err)
      });
  };
};

//Add new network department
export const EMDoDeleteDepartmentInOrganizationAction = action => {
  return dispatch => {
    API.EMDoDeleteDepartmentInOrganization(action.data)
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch(EMDoGetListOfOrganizationDepartmentsAction({organizationId:action.data.organizationId, queryData:"?limit=15"}));
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Department Deleted Successfully",
              key: "user_add_edit"
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching level list",
              key: action.key
            }
          });
        }
      })
      .catch(err => {
        // if (err.response.data.detail) {
        //   dispatch({
        //     type: SHOW_MESSAGE,
        //     payload: {
        //       type: "error",
        //       message: err.response.data.detail
        //     }
        //   })
        // }
        handleError(dispatch, err)
      });
  };
};

//Add new network department
export const EMDoUpdateLevelInOrganizationAction = action => {
  return dispatch => {
    API.EMDoUpdateLevelInOrganization(action.data)
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch(EMDoGetListOfOrganizationLevelsAction({organizationId:action.data.organizationId, queryData:"?limit=15"}));
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Level Updated Successfully",
              key: action.key
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching level list",
              key: action.key
            }
          });
        }
      })
      .catch(err => {
        // if (err.response.data.detail) {
        //   dispatch({
        //     type: SHOW_MESSAGE,
        //     payload: {
        //       type: "error",
        //       message: err.response.data.detail
        //     }
        //   })
        // }
        handleError(dispatch, err)
      });
  };
};

//Add new network department
export const EMDoDeleteLevelInOrganizationAction = action => {
  return dispatch => {
    API.EMDoDeleteLevelInOrganization(action.data)
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch(EMDoGetListOfOrganizationLevelsAction({organizationId:action.data.organizationId, queryData:"?limit=15"}));
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Level Deleted Successfully",
              key: "user_add_edit"
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching level list",
              key: action.key
            }
          });
        }
      })
      .catch(err => {
        // if (err.response.data.detail) {
        //   dispatch({
        //     type: SHOW_MESSAGE,
        //     payload: {
        //       type: "error",
        //       message: err.response.data.detail
        //     }
        //   })
        // }
        handleError(dispatch, err)
      });
  };
};

// GET DASHBOARD DATA ACTION
export const EMDoGetNetworkDashboardInfo = action => {
  
  return dispatch => {
    API.EMDoGetNetworkDashboardInfo(action.requiredData)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: NETWORK_ADMIN_GET_DASHBOARD_DATA,
            payload: response
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching dashboard data"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

// DASHBOARD STATIC DATA
export const EMDoGetNetworkOrgGrpChnForNetworkAction = action => {
  return dispatch => {
    API.EMDoGetNetworkOrgGrpChnForNetwork(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: NETWORK_ADMIN_ENTITY_LIST,
            payload: response
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching dashboard data"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};


// REPORTS DATA
export const EMDoGetOnboardingReportForNetworkAction = action => {
  return dispatch => {
    API.EMDoGetOnboardingReportForNetwork(action.requiredData)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: NETWORK_ADMIN_GET_ONBOARDING_DATA,
            payload: response
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching dashboard data"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetUsageReportForNetworkAction = action => {
  return dispatch => {
    API.EMDoGetUsageReportForNetwork(action.requiredData)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: NETWORK_ADMIN_GET_USAGE_REPORT_DATA,
            payload: response
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching dashboard data"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
    });
  };
};

export const EMDoGetUserEngagementReportForNetworkAction = action => {
  return dispatch => {
    API.EMDoGetUserEngagementReportForNetwork(action.requiredData, action.paginationData)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: NETWORK_ADMIN_GET_USER_ENGAGEMENT_DATA,
            payload: response
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching dashboard data"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
        let data = {
          count: 0,
          next: null,
          previous: null,
          results:[]
        }
        dispatch({
          type: NETWORK_ADMIN_GET_USER_ENGAGEMENT_DATA,
          payload: data
        });
      });
  };
};

export const EMDoGetNetworkStatsReportForNetworkAction = action => {
  return dispatch => {
    API.EMDoGetNetworkStatsReportForNetwork(action.requiredData, action.paginationData)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: NETWORK_ADMIN_GET_NETWORK_STATS_DATA,
            payload: response
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching dashboard data"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};


export const EMDoGetRolesListAction = action => {
  return dispatch => {

    API.EMDoGetRolesList(action)
      .then(data => data.data)
      .then(data => {

        if (data) {
          dispatch({
            type: NETWOEK_ADMIN_ROLE_LIST_SUCCESS,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching entity list",
              key: action.key
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetPrivacyList = action => {
  return dispatch => {
    API.EMDoGetPrivacy()
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: PRIVACY_LIST_SUCCESS,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching privacy list"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetEntityRoles = action => {
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
    API.EMDoGetEntityRoleList(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: ENTITY_ROLE_LIST_SUCCESS,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching role list"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        handleError(dispatch, err)
      });
  };
};

export const EMDoUpdateEntityRoleAction = action => {
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
    API.EMDoUpdateEntityRole(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: ENTITY_ROLE_UPDATE_SUCCESS,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Role updated successfully",
              key: action.key
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to update the role"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        handleError(dispatch, err)
      });
  };
};

export const EMDoCreateEntityRoleAction = action => {
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
    let userData = { ...action };
    delete userData.key;
    API.EMDoCreateEntityRole(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: ENTITY_ROLE_CREATE_SUCCESS,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Role added successfully",
              key: action.key
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to create the role"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        handleError(dispatch, err)
      });
  };
};

//Actions for Network Admin User management

export const EMDOGetNetworkAdminUsersListAction = action => {
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
    let userData = { ...action };
    delete userData.key;
    API.EMDoGetNetworkAdminUsersList(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: NETWORK_ADMIN_USERS_LIST_SUCCESS,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to show network admin users"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        handleError(dispatch, err)
      });
  };
};

export const EMDoUpdateNetworkAdminAction = action => {
  
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
    API.EMDoUpdateNetworkAdmin(action)
      .then(data => data.data)
      .then(response => {
        
        if (response) {
          dispatch({
            type: NETWORK_ADMIN_UPDATE_SUCCESS,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "User updated successfully",
              key: action.key
            }
          });
          
          let limit = action.contextData.limit || 15
          dispatch(EMDOGetNetworkAdminUsersListAction(`?limit=${limit}&context_type=${action.contextData.contextType}&context=${action.contextData.context}`));
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to update the user"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        // if (err.response.data.detail) {
        //  dispatch({
        //     type: SHOW_MESSAGE,
        //     payload: {
        //       type: "error",
        //       message: err.response.data.detail
        //     }
        //   })
        // }
        handleError(dispatch, err)
      });
  };
};

export const EMDoCreateNetworkAdminAction = action => {
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
    let userData = { ...action };
    API.EMDoCreateNetworkAdmin(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          //dispatch({
          //   type: NETWORK_ADMIN_CREATE_SUCCESS,
          //   payload: response
          // });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "User invited successfully",
              key: action.key
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to invite the user"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        handleError(dispatch, err)
      });
  };
};


export const EMDoExportNetworkAdminUsersAction = action => {
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
    let userData = { ...action };
    delete userData.key;
    API.EMDoExportNetworkAdminUsers(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'ExportedUserDetails.csv');
          document.body.appendChild(link);
          link.click();
          link.remove();
          dispatch({
            type: NETWORK_ADMIN_USERS_EXPORT_SUCCESS,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Users exported successfully",
              key: action.key
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to export users"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        handleError(dispatch, err)
      });
  };
};

//resend invite actions

export const EMDoGetResendInviteUsersListAction = action => {
  return dispatch => {
    API.EMDoGetResendInviteUsersList(action)
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: NETWORK_ADMIN_RESEND_USERS_LIST_SUCCESS,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching users list"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoResendInviteToUsersAction = action => {
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
    let userData = { ...action };
    delete userData.key;
    API.EMDoResendInviteToUsers(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: NETWORK_ADMIN_RESEND_INVITE_SUCCESS,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Invitations sent successfully",
              key: action.key
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to resend invite"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        handleError(dispatch, err)
      });
  };
};

export const EMDoImportAllSelectedUsersList = action => {
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
    
    let userData = { ...action };
    delete userData.key;
    API.EMDoImportAllSelectedUsersList(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: NETWORK_ADMIN_IMPORT_USERS_SUCCESS,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Invitations sent successfully",
              key: action.key
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to send invite"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        handleError(dispatch, err)
      });
  };
};


//import users action
export const EMDoGetNetworkAdminImportUsersListAction = action => {
  return dispatch => {
    API.EMDoGetAllImportUsersList(action)
      .then(data => data.data)
      .then(data => {
        if (data) {
          
          dispatch({
            type: NETWORK_ADMIN_IMPORT_USERS_LIST_SUCCESS,
            payload: data
          });
          
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching import list"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetFilterByGroupDropdownValuesAction = action => {
  return dispatch => {
    API.EMDoGetFilterByGroupDropdownValues(action)
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: NETWORK_ADMIN_FILTER_BY_GROUP_DROPDOWN_VALUES_SUCCESS,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching dropdown values"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetFilterByGroupListAction = action => {
  return dispatch => {
    API.EMDoGetFilterByGroupList(action)
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: NETWORK_ADMIN_IMPORT_USERS_LIST_SUCCESS,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching import list"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};



export const EMDoGetFilterByGroupListByIdAction = action => {
  return dispatch => {
    API.EMDoGetFilterByGroupListById(action)
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: NETWORK_ADMIN_IMPORT_USERS_LIST_SUCCESS,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching import list"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};
export const EMDoBulkUploadUsersListAction = action => {
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
    let userData = { ...action };
    delete userData.key;
    API.EMDoBulkUploadUsersList(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: NETWORK_ADMIN_BULK_UPLOAD_SUCCESS,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Users Uploaded Successfully",
              key: action.key
            }
          });

        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to upload the users"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        handleError(dispatch, err)
      });
  };
};

//Actions for Network Admin Group management

export const EMDOGetNetworkAdminGroupListAction = action => {
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
    API.EMDoGetNetworkAdminGroupList(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: NETWORK_ADMIN_GROUP_LIST_SUCCESS,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching group list"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        handleError(dispatch, err)
      });
  };
};

export const EMDoUpdateNetworkAdminGroupAction = action => {
  return dispatch => {

    dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
    API.EMDoUpdateNetworkAdminGroup(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: NETWORK_ADMIN_GROUP_UPDATE_SUCCESS,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Group updated successfully",
              key: action.key
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to update the group",
              key: action.key
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        handleError(dispatch, err, action.key)
      });
  };
};
export const EMDoUpdateNetworkAdminGroupStatusAction = action => {
  return dispatch => {

    dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
    API.EMDoUpdateNetworkAdminGroupStatus(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: NETWORK_ADMIN_GROUP_UPDATE_SUCCESS,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Group updated successfully",
              key: action.key
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to update the group",
              key: action.key
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        handleError(dispatch, err, action.key)
      });
  };
};

export const EMDoCreateNetworkAdminGroupAction = action => {
  return dispatch => {

    dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
    let groupData = { ...action };
    delete groupData.key;
    
    API.EMDoCreateNetworkAdminGroup(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: NETWORK_ADMIN_GROUP_CREATE_SUCCESS,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Group added successfully",
              key: action.key
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to create the group",
              key: action.key
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {

        dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        handleError(dispatch, err, action.key)
      });
  };
};

// Network Admin Channel Actions

export const EMDOGetNetworkAdminChannelListAction = action => {
  return dispatch => {
    
    dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
    API.EMDoGetNetworkAdminChannelList(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: NETWORK_ADMIN_CHANNEL_LIST_SUCCESS,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching channel list"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        handleError(dispatch, err)
      });
  };
};

export const EMDoUpdateNetworkAdminChannelAction = action => {
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
    API.EMDoUpdateNetworkAdminChannel(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: NETWORK_ADMIN_CHANNEL_UPDATE_SUCCESS,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Channel updated successfully",
              key: action.key
            }
          });
          // dispatch(EMDOGetNetworkAdminChannelListAction(action.data))
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to update the channel",
              key: action.key
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        handleError(dispatch, err, action.key)
      });
  };
};

export const EMDoCreateNetworkAdminChannelAction = action => {
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
    let channelData = { ...action };
    delete channelData.key;
    API.EMDoCreateNetworkAdminChannel(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: NETWORK_ADMIN_CHANNEL_CREATE_SUCCESS,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "channel added successfully",
              key: action.key
            }
          });
       //   dispatch(EMDOGetNetworkAdminChannelListAction(action.data))
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to create the channel",
              key: action.key
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        handleError(dispatch, err, action.key)
      });
  };
};


//Network Management EMail Templates Actions 

export const EMDoGetEmailTemplateListAction = action => {
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
    API.EMDOGetEmailTemplateList(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: EMAIL_TEMPLATE_LIST_SUCCESS,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching email template list"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        handleError(dispatch, err)
      });
  };
};

export const EMDoUpdateEmailTemplateAction = action => {
  
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
    API.EMDoUpdatEmailTemplate(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: EMAIL_TEMPLATE_UPDATE_SUCCESS,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Email Template Updated Successfully",
              key: action.key
            }
          });
          let query = `?limit=15&context_type=${action.contextType}&context=${action.context}`
          dispatch(EMDoGetEmailTemplateListAction(query))
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to update the Email Template",
              key: action.key
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        handleError(dispatch, err, action.key)
      });
  };
};

export const EMDoUpdateEmailTemplateStatusAction = action => {
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
    API.EMDoUpdateEmailTemplateStatus(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          // dispatch({
          //   type: EMAIL_TEMPLATE_UPDATE_SUCCESS,
          //   payload: response
          // });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Email Template Updated Successfully",
              key: action.key
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to update the Email Template"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        handleError(dispatch, err)
      });
  };
};

export const EMDoCreateEmailTemplateAction = action => {
  
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
    let channelData = { ...action };
    delete channelData.key;
    API.EMDoCreateEmailTemplate(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: EMAIL_TEMPLATE_CREATE_SUCCESS,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Email Template Added Successfully",
              key: action.key
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to Create The Email Template",
              key: action.key
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        handleError(dispatch, err, action.key)
      });
  };
};

export const EMDOGetEmailTemplateTypeListAction = action => {
  return dispatch => {
    API.EMDOGetEmailTemplateTypeList(action)
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: EMAIL_TEMPLATE_TYPE_LIST_SUCCESS,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching template list"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetGroupListDropDownAction = action => {
  return dispatch => {
    API.EMDoGetGroupListDropDown(action)
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: GROUP_LIST_DROPDOWN_SUCCESS,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching privacy list"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

/* Actions for Organisation Management Get and Put */

export const EMDoGetNetworkListDetailsAction = action => {
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
    API.EMDoGetNetworkListDetails()
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: NETWORK_LIST_SUCCESS,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching entity list",
              key: action.key
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetNetworkDetailsListAction = action => {
  
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
    API.EMDoGetNetworkDetailsList(action)
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: NETWORK_DETAILS_LIST_SUCCESS,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching entity list",
              key: action.key
            }
          });
        }
        dispatch({ type: UI_LOADING, payload: {state:false, message:""} })
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};
export const EMDoUpdateNetworkDetailsAction = action => {
  
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
    API.EMDOUpdateNetworkDetails(action)
      .then(data => data.data)
      .then(response => {

        if (response) {
          dispatch({
            type: NETWORK_DETAILS_UPDATE_SUCCESS,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;

          dispatch({
            type: NETWORK_DETAILS_LIST_SUCCESS,
            payload: response
          });
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: action.data.showmsg + " Updated Successfully",
              key: action.key
            }
          });
          /* update Details */
          dispatch(EMDoGetNetworkDetailsListAction({contextData : action.contextData}))
          dispatch(EMDoGetFileUploadDetailsAction({contextData :action.contextData}))
          let data = {
            entity : "network",
            permissionData :"manage_profile_page"
          }
          dispatch(EMDoGetEntityInformationBasedOnPermissionAction(data))
          dispatch(EMDoGetMultipleProfileDetailsAction());
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to update the user"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        if (err.response.data) {
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
          handleError(dispatch, err)
        }
      });
  };
};
/* API for file Upload */
export const EMDoGetFileUploadDetailsAction = action => {
  
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
    API.EMDoGetFileUploadDetails(action)
      .then(data => data.data)
      .then(data => {

        if (data) {
          dispatch({
            type: NETWORK_FILE_UPLOAD_DETAILS,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching entity list",
              key: action.key
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

export const EMNetworkDetailsUpdateFileUploadAction = action => {
  
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
    let userData = { ...action };
    delete userData.key;
    API.EMNetworkDetailsUpdateFileUpload(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: NETWORK_ADMIN_BULK_UPLOAD_SUCCESS,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
          /* update Details */
          dispatch(EMDoGetNetworkDetailsListAction({contextData : action.contextData}))
          dispatch(EMDoGetFileUploadDetailsAction({contextData :action.contextData}))
          let data = {
            entity : "network",
            permissionData :"manage_profile_page"
          }
          dispatch(EMDoGetEntityInformationBasedOnPermissionAction(data))
          dispatch(EMDoGetMultipleProfileDetailsAction());
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to upload the files"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        handleError(dispatch, err)
      });
  };
};
export const EMDoGetEmailTemplateAction = action => {
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
    API.EMDoGetEmailTemplate(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: NETWORK_ADMIN_EMAIL_TEMPLATE,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching role list"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        handleError(dispatch, err)
      });
  };
};

/* Manage Roles And Permissions */

//GET - for listing roles in table

export const EMDoListRolesForSelectedEntityAction = action => {
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
    API.EMDoListRolesForSelectedEntity(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: ENTITY_ROLE_LIST_SUCCESS,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching role list"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        handleError(dispatch, err)
      });
  };
};
//Post - Create new Role
export const EMDoCreateNewRoleForEntityAction = action => {
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
    let userData = { ...action };
    delete userData.key;
    
    API.EMDoCreateNewRoleForEntity(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: ENTITY_ROLE_CREATE_SUCCESS,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Role added successfully",
              key: action.key
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to create the role"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        handleError(dispatch, err)
      });
  };
};
//GET - for Role Specific Permissions
export const EMDoRetrievePermissionsForRoleAction = action => {
  
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
    API.EMDoRetrievePermissionsForRole(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          
          dispatch({
            type: ENTITY_ROLE_RETRIEVE_SUCCESS,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching role details"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        handleError(dispatch, err)
      });
  };
};

// export const EMDoRetrievePermissionsForRoleAction = action => {
//   
//   return dispatch => {
//     dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
//     API.EMDoRetrievePermissionsForRole(action)
//       .then(data => data.data)
//       .then(data => {
//         
//         if (data) {
//           
//           dispatch({
//             type: ROLES_LIST_SUCCESS,
//             payload: data
//           });
//         } else {
//           dispatch({
//             type: SHOW_MESSAGE,
//             payload: {
//               type: "error",
//               message: "Error occured while fetching roles list"
//             }
//           });
//         }
//       })
//       .catch(err => {
//         dispatch({
//           type: SHOW_MESSAGE,
//           payload: {
//             type: "error",
//             message: err.response.data.detail
//           }
//         });
//       });
//   };
// };
//Patch - active inactive 
export const EMDoUpdateStatusOfRoleAction = action => {
  
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
    API.EMDoUpdateStatusOfRole(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Role updated Successfully"
            }
          })
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching role list"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        handleError(dispatch, err)
      });
  };
}
//Put - Update Role
export const EMDoUpdateRoleForEntityAction = action => {
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
    let userData = { ...action };
    delete userData.key;
    
    API.EMDoUpdateRoleForEntity(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          // dispatch({
          //   type: ENTITY_ROLE_CREATE_SUCCESS,
          //   payload: response
          // });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Role updated successfully",
              key: action.key
            }
          });
          dispatch(EMDoListRolesForSelectedEntityAction(action.contextData))
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to create the role"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        handleError(dispatch, err)
      });
  };
};
// ACTION FOR EXPORT REPORTS

export const EMDoExportOnboardingReportForNetworkAction = action => {
  return dispatch => {
    API.EMDoExportOnboardingReportForNetwork(action.requiredData)
      .then(data => data.data)
      .then(response => {
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'OnboardingReport.csv');
          document.body.appendChild(link);
          link.click();
          link.remove();
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Onboarding report exported successfully",
              key: action.key
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to export onboarding report"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoExportNetworkStatsReportForNetworkAction = action => {
  return dispatch => {
    API.EMDoExportNetworkStatsReportForNetwork(action.requiredData)
      .then(data => data.data)
      .then(response => {
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'NetworkStatsReport.csv');
          document.body.appendChild(link);
          link.click();
          link.remove();
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Network stats report exported successfully",
              key: action.key
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to export network stats report"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

export const EMExportUsageReportForNetworkAction = action => {
  return dispatch => {
    API.EMExportUsageReportForNetwork(action.requiredData)
      .then(data => data.data)
      .then(response => {
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'UsageReport.csv');
          document.body.appendChild(link);
          link.click();
          link.remove();
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Usage report exported successfully",
              key: action.key
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to export usage report"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoExportUserEngagementReportForNetworkAction = action => {
  return dispatch => {
    API.EMDoExportUserEngagementReportForNetwork(action.requiredData, action.groupOrChannel)
      .then(data => data.data)
      .then(response => {
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'UserEngagementReport.csv');
          document.body.appendChild(link);
          link.click();
          link.remove();
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "User engagement report exported successfully",
              key: action.key
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to export User engagement report"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

//download attachments for networks
export const EMDoDownloadAttachmentsForNetworkAction = action => {
  return dispatch => {
    API.EMDoDownloadAttachmentsForNetwork(action.data)
      .then(data => data.data)
      .then(response => {
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Network Attachments.zip');
          document.body.appendChild(link);
          link.click();
          link.remove();
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Network attachments downloaded successfully",
              key: action.key
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to download attachments"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetDefaultLevelsInOrganizationAction = action => {
  return dispatch => {
    API.EMDoGetDefaultLevelsInOrganization()
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: NETWORK_ADMIN_GET_LEVELS_LIST,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching level list",
              key: action.key
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetDefaultBadgesInOrganizationAction = action => {
  return dispatch => {
    API.EMDoGetDefaultBadgesInOrganization()
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: NETWORK_ADMIN_GET_BADGES_LIST,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching badge list",
              key: action.key
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetDataForDropdownInGamificationAction = action => {
  return dispatch => {
    API.EMDoGetDataForDropdownInGamification("network")
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: NETWORK_ADMIN_GET_DATA_FOR_DROPDOWN_IN_GAMIFICATION,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching network list",
              key: action.key
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetLevelReportAction = action => {
  return dispatch => {
    API.EMDoGetLevelAndBadgeReport(action.entityType, action.entityId, "level")
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: NETWORK_ADMIN_GET_LEVEL_REPORT,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching network list",
              key: action.key
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetBadgeReportAction = action => {
  return dispatch => {
    API.EMDoGetLevelAndBadgeReport(action.entityType, action.entityId, "badge")
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: NETWORK_ADMIN_GET_BADGE_REPORT,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching network list",
              key: action.key
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetListOfNetworksForDropdownAction = action => {
  return dispatch => {
    API.EMDoGetListOfNetworksForDropdown()
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: NETWORK_ADMIN_GET_LIST_OF_NETWORKS_FOR_DROPDOWN,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching group list",
              key: "get_networks_error"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetPermissionSpecificToNetworkAction = action => {
  return dispatch => {
    API.EMDoGetPermissionSpecificToNetwork(action.id, action.networkType)
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: NETWORK_ADMIN_GET_PERMISSION_BASED_ON_NETWORK,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching user's permission",
              key: "get_permissions_error"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetDefaultEmailTemplateAction = action => {
  return dispatch => {
    API.EMDoGetDefaultEmailTemplate(action)
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: NETWORK_ADMIN_GET_EMAIL_TEMPLATE_BODY,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching email template body",
              key: "get_email_template_error"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};
