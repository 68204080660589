import axios from "axios";

/* User Management Functionality Api */
const EMDoGetChannelAdminChannelListDropDown = () => {
  let url = `/channel/channel_admin/user/channel_list/`;
  return axios.get(url);
};

const EMDOGetChannelAdminChannelUsersList = (queryData) => {
  let url = "";
  if (queryData) {
    url = `/channel/channel_user/${queryData}`;
  } else {
    url = `/channel/channel_user/`;
  }
  return axios.get(url);
};

const EMDoGetChannelUserRolesList = (id) => {
  let url = `/channel/roles/?channel=${id}`;
  return axios.get(url);
};

const EMDoUpdateChannelUserDetails = ({ data, id }) => {
  let url = `channel/channel_user/${id}/`;
  return axios.put(url, { ...data });
};

const EMDoExportChannelAdminUsers = ({ contextData }) => {
  let url = `/channel/channel_user/export/?context=${contextData.context}`;
  return axios.get(url);
};

// Channel Management Functionality API Calls

const EMDoGetChannelAdminChannelList = (query) => {
  let url = "";
  if (query) {
    url = `/channel/list_all_channels/${query}`;
  } else {
    url = `/channel/list_all_channels/`;
  }
  return axios.get(url);
};

const EMDoUpdateChannelAdminChannel = ({ data }) => {
  let url = `/channel/channel_settings_update/${data.id}`;
  return axios.put(url, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const EMDoGetChannelAdminGroupListDropDown = () => {
  let url = `/channel/list_all_groups/`;
  return axios.get(url);
};

/* get Channel detail by id */

const EMDoGetChannelAdminChannelDetailsById = (id) => {
  let url = `/channel/channel_profile_update/${id}`;
  return axios.get(url);
};

//Download attachments for channel
const EMDoDownloadAttachmentsForChannel = (channelId) => {
  let url = `/channel/${channelId}/download-channel-attachments/`;
  return axios.get(url, {
    responseType: "arraybuffer",
  });
};

/* Put - update Channel Details by id */
const EMDoUpdateChannelAdminChannelDetailsById = ({ jsonData }) => {
  let url = `/channel/channel_profile_update/${jsonData.id}`;
  const fileData = new FormData();
  if (jsonData.files.hasOwnProperty("icon")) {
    fileData.append("logo_file", jsonData.files.icon);
  }
  if (jsonData.files.hasOwnProperty("banner")) {
    fileData.append("banner_file", jsonData.files.banner);
  }
  delete jsonData.files;
  fileData.append("id", jsonData.id);
  fileData.append("channel_name", jsonData.channel_name);
  fileData.append("tagline", jsonData.tagline);
  fileData.append("vision_stmt", jsonData.vision_stmt);
  fileData.append("mission_stmt", jsonData.mission_stmt);
  fileData.append("additional_info", jsonData.additional_info);
  fileData.append("privacy_type", jsonData.privacy_type);
  fileData.append("is_active", jsonData.is_active);
  fileData.append("deleted_attachments", jsonData.deleted_attachments);
  jsonData.new_attachments.map((img) => {
    fileData.append("new_attachments", img);
  });

  return axios.put(url, fileData, {
    body: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const EMDoGetChanelAdminImportUsersList = (queryData) => {
  let url = "";
  if (queryData) {
    url = `/channel/channels/import_users/${queryData}`;
  }
  return axios.get(url);
};

const EMDoImportChannelAdminSelectedUsersList = ({ data1, contextData }) => {
  let url = "";
  if (contextData) {
    url = `/channel/channels/import_users/?channel=${contextData.channelId}`;
  } else {
    url = `/channel/channels/import_users/`;
  }

  return axios.post(url, { ...data1 });
};

const EMDoGetResendInviteUsersChannelAdminList = (queryData) => {
  let url = "";
  if (queryData) {
    url = `/invitations/resend-invitation/${queryData}`;
  } else {
    url = `/invitations/resend-invitation/`;
  }
  return axios.get(url);
};

const EMDoChannelAdminResendInviteToUsers = ({ data, contextData }) => {
  let url = "";
  if (contextData) {
    url = `/invitations/resend-invitation/?channel=${contextData.channelId}&email_template=${contextData.emailTemplate}`;
  } else {
    url = `/invitations/resend-invitation/`;
  }

  return axios.post(url, { ...data });
};

const EMDOGetChannelAdminEmailTemplateTypeList = (channelId) => {
  let url = "";
  if (channelId) {
    url = `/commans/listemailtemplates/?channel=${channelId}`;
  } else {
    url = `/commans/listemailtemplates/`;
  }
  return axios.get(url);
};

const EMDoListRolesForSelectedChannel = ({ data, query }) => {
  let url;
  if (data != undefined) {
    if (query) {
      url = `${data.entity}/${data.id}/roles${query}`;
    } else {
      url = `${data.entity}/${data.id}/roles`;
    }
  }
  return axios.get(url);
};
const EMDoCreateNewRoleForChannel = ({ roleData, contextData }) => {
  let url = `${contextData.entity}/${contextData.id}/roles`;
  return axios.post(url, { ...roleData });
};
const EMDoRetrievePermissionsForChannel = ({ contextData }) => {
  let url = `${contextData.entity}/${contextData.id}/roles/${contextData.roleId}`;
  return axios.get(url);
};
const EMDoUpdateRoleForChannel = ({ roleData, contextData }) => {
  let url = `${contextData.entity}/${contextData.id}/roles/${contextData.roleId}`;
  return axios.put(url, { ...roleData });
};
const EMDoUpdateStatusOfRoleforChannel = ({ contextData }) => {
  let url = `${contextData.entity}/${contextData.entityId}/roles/${contextData.roleId}/action:${contextData.action}/`;
  return axios.patch(url);
};

//  remove user from channel
const EMAdminRemoveChannelUser = (data) => {
  let url = `channel/${data.channelId}/remove-user-from-channel/`;
  return axios.delete(url, {
    data: {
      user_id: data.user_id,
    },
  });
};
// dashboard for channel

const EMDoGetChannelDashboardInfo = (requiredData) => {
  let url = `user/channel-admin-channel-dashboard/?start_date=${requiredData.start_date}&end_date=${requiredData.end_date}&channel=${requiredData.channelId}`;
  return axios.get(url);
};
const EMDoGetEntityListForChannel = () => {
  let url = `/user/channel-admin-entity-list/`;
  return axios.get(url);
};

const EMDoGetChannelBasedOnOrgOrPartnership = (requiredData) => {
  let url;
  if (requiredData.isPartnership === true) {
    url = `/user/channel-admin/partnership/${requiredData.id}/list-user-groups/?limit=100`;
  } else {
    url = `/user/channel-admin/organization/${requiredData.id}/list-user-groups/?limit=100`;
  }
  return axios.get(url);
};

//  Channel list
const EMDoGetListForChannel = (requiredData) => {
  let url = `/user/group/${requiredData.groupId}/list-channels/`;
  return axios.get(url);
};

// reports
const EMDoGetUserEngagementReportForChannel = (requiredData, paginationData) => {
  let url = `/user/channel-admin-user-engagement-report/${requiredData.groupId}/?${paginationData}&start_date=${requiredData.start_date}&end_date=${requiredData.end_date}`;
  return axios.get(url);
};

// export reports
const EMDoExportUserEngagementReportForChannel = (requiredData) => {
  let url = `/user/channel-admin-user-engagement-report/${requiredData.groupId}/`;
  return axios.post(url, requiredData);
};

//get list of channels for dropdown
const EMDoGetListOfChannelsForDropdownInUserManagement = () => {
  let url = `/auth/get-entity-for-dropdown/channel/none/none/`;
  return axios.get(url);
};

//get permission for user, specific to group
const EMDoGetPermissionSpecificToChannel = (id) => {
  let url;
  if (id) {
    url = `auth/get-roles/channel/${id}/`;
    return axios.get(url);
  }
};

export default {
  EMDoGetChannelAdminChannelListDropDown,
  EMDOGetChannelAdminChannelUsersList,
  EMDoGetChannelUserRolesList,
  EMDoUpdateChannelUserDetails,
  EMDoExportChannelAdminUsers,
  EMDoGetChannelAdminChannelList,
  EMDoUpdateChannelAdminChannel,
  EMDoGetChannelAdminGroupListDropDown,
  EMDoGetChannelAdminChannelDetailsById,
  EMDoUpdateChannelAdminChannelDetailsById,
  EMDoGetChanelAdminImportUsersList,
  EMDoImportChannelAdminSelectedUsersList,
  EMDoGetResendInviteUsersChannelAdminList,
  EMDOGetChannelAdminEmailTemplateTypeList,
  EMDoChannelAdminResendInviteToUsers,
  //manage Roles and Permissions
  EMDoListRolesForSelectedChannel,
  EMDoCreateNewRoleForChannel,
  EMDoRetrievePermissionsForChannel,
  EMDoUpdateRoleForChannel,
  EMDoUpdateStatusOfRoleforChannel,
  EMAdminRemoveChannelUser,
  EMDoGetChannelDashboardInfo,
  EMDoGetEntityListForChannel,
  EMDoGetChannelBasedOnOrgOrPartnership,
  EMDoGetUserEngagementReportForChannel,
  EMDoExportUserEngagementReportForChannel,
  EMDoGetListForChannel,
  EMDoDownloadAttachmentsForChannel,
  EMDoGetListOfChannelsForDropdownInUserManagement,
  EMDoGetPermissionSpecificToChannel,
};
