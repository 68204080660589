import React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import EMPage from "../commans/EMPage";
import { useDispatch, useSelector } from "react-redux";
import { CHANGE_TAB_INDEX_TO_ZERO, RESET } from "../../redux/constants";
import { CHANGE_USER_TO_ADMIN } from "../../redux/constants/UserPlatform/EMNavigationConstant";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: window.screen.height / 25,
  },
  tabLabel: {
    marginTop: "3.5%",
  },
}));

export default function EMTabs(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const commonData = useSelector((state) => state.common || {});

  const handleChange = (event, newValue) => {
    setValue(newValue);
    
    if (newValue != (commonData && commonData.tabIndex)) {
      dispatch({
        type: RESET,
      });
    }
    dispatch({ type: CHANGE_TAB_INDEX_TO_ZERO, payload: newValue });
    dispatch({
      type: CHANGE_USER_TO_ADMIN,
      payload: true,
    });
  };

  const handleChangeIndex = (index) => {
    setValue(index);

    if (index != (commonData && commonData.tabIndex)) {
      dispatch({
        type: RESET,
      });
    }
    dispatch({ type: CHANGE_TAB_INDEX_TO_ZERO, payload: index });
    dispatch({
      type: CHANGE_USER_TO_ADMIN,
      payload: true,
    });
  };

  let tabs = props.tabs.map((tabName, index) => {
    return (
      <Tab
        label={<span className={classes.tabLabel}>{tabName}</span>}
        {...a11yProps(index)}
      />
    );
  });
  let components = props.components.map((componentName, index) => {
    return (
      <TabPanel
        value={commonData && commonData.tabIndex}
        index={index}
        dir={theme.direction}
      >
        {componentName}
      </TabPanel>
    );
  });
  return (
    <EMPage title={props.title}>
      <AppBar position="static" color="default">
        <Tabs
          value={commonData && commonData.tabIndex}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          className={classes.root}
          aria-label="full width tabs example"
          variant="scrollable"
          scrollButtons={true}
        >
          {tabs}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={commonData && commonData.tabIndex}
        onChangeIndex={handleChangeIndex}
      >
        {components}
      </SwipeableViews>
    </EMPage>
  );
}
