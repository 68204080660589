import React, { useState, useEffect, useCallback } from "react";
import {
  Paper,
  Box,
  Button,
  Modal,
  Typography,
  IconButton,
  Grid,
  FormControlLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import EMTextFieldSearch from "../../../../../components/datatables/EMDatatableSearchView";
import EMImportUserTable from "./EMImportUserTable";
import { CloseRounded, CheckBox } from "@material-ui/icons";
import EMEntityRoleDropdown from "../../../commons/EMGroupUserRoleDropdown";
//import EMFilterByGroupDropdown from "../../../commons/EMFilterByGroupDropdown"
import Checkbox from "@material-ui/core/Checkbox";
import { find } from "lodash";
import { EMDoImportGroupAdminSelectedUsersList } from "../../../../../redux/actions/EMGroupAdminActions";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { uniqBy } from "lodash";
import {
  GROUP_ADMIN_ADD_REMOVE_IMPORT_USERS_FROM_SECONDARY_ARRAY,
  GROUP_ADMIN_IMPORT_USERS_CLEAR_UNCLEAR_SELECT_ALL_WRT_SEARCH,
  GROUP_ADMIN_IMPORT_USERS_SELECT_ALL_FLAG,
  GROUP_ADMIN_SELECT_UNSELECT_USER_IN_IMPORT_USERS,
} from "../../../../../redux/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,

    overflowY: "auto",
    width: "50%",
    marginTop: 10,
    margin: "auto",
    padding: "0px",
    marginLeft: "25%",
    maxHeight: window.screen.height / 1.2,
  },
  paper: {
    margin: "3%",
  },
  title: {
    margin: 10,
    flexGrow: 1,
    fontWeight: "bold",
    color: "white",
    fontSize: "large",
  },
  form: {
    padding: "0px 36px",
    width: "100%",
  },
  button: {
    padding: theme.spacing(1),
    margin: 20,
  },
}));

export default function EMImportUsers(props) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(false);
  const [filter, setFilter] = useState("");
  const [role, setRole] = useState();
  const [filterByGroup, setFilterByGroup] = useState(-1);
  const [tabledata, setTabledata] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [showRoleError, setShowRoleError] = React.useState();
  const [isError, setIsError] = React.useState(true);
  const [checkedUsers, setCheckedUsers] = React.useState([]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const data = useSelector((state) => state.groupAdmin.importUsersList || {});
  const selectAllFlag = useSelector((state) => state.groupAdmin.selectAll);
  const uiData = useSelector((state) => state.ui);

  useEffect(() => {
    if (uiData["messages"]) {
      let refObj = find(uiData["messages"], { key: "user_import" });
      if (refObj && refObj.type === "success") {
        setRole();
        setFilter("");
        setOpen(false);
        dispatch({
          type: GROUP_ADMIN_IMPORT_USERS_SELECT_ALL_FLAG,
          payload: { state: false },
        });
      }
    }
  }, [uiData]);

  useEffect(() => {
    runThisFunctionFirst();
  }, [data]);

  const runThisFunctionFirst = () => {
    return new Promise(function (resolve) {
      if (data.results) {
        setTabledata(data);
      }
      resolve();
    });
  };

  useEffect(() => {
    if (!isEmpty(role)) {
      setShowRoleError("");
      setIsError(true);
    }
  }, [role]);

  const roleSelectionHandler = () => {
    if (isEmpty(role)) {
      setShowRoleError("Please Select Role Here");
      setIsError(false);
    } else {
      setShowRoleError("");
      setIsError(true);
    }
  };

  const dispatch = useDispatch();

  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.closeHandler(false);
        setRole();
        setFilter("");
        setTabledata([]);
        setCheckedUsers([]);
        dispatch({
          type: GROUP_ADMIN_IMPORT_USERS_SELECT_ALL_FLAG,
          payload: { state: false },
        });
      }}
    >
      <Box className={classes.root}>
        <form>
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            style={{ padding: "0px 4px 0px 18px", backgroundColor: "#2F92D6" }}
          >
            <Typography className={classes.title}>
              {"Import Users To Group"}
            </Typography>
            <IconButton
              onClick={() => {
                props.closeHandler(false);
                setRole();
                setTabledata([]);
                setFilter("");
                setCheckedUsers([]);
                dispatch({
                  type: GROUP_ADMIN_IMPORT_USERS_SELECT_ALL_FLAG,
                  payload: { state: false },
                });
              }}
            >
              <CloseRounded style={{ color: "white" }} />
            </IconButton>
          </Box>
          <Box className={classes.paper}>
            <Grid container justify="flex-start" style={{ marginLeft: 16 }}>
              <Grid item xs={12} sm={5} md={5}>
                <EMTextFieldSearch
                  hint={"Search for User Name, Email-Address"}
                  onChangeListener={(data) => {
                    setFilter(data);
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
                md={3}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {data["count"] > 0 ? (
                  <label
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <input
                      type="checkbox"
                      className="input-checkbox"
                      checked={selectAllFlag}
                      onChange={(event) => {
                        let state = event.target.checked;
                        dispatch({
                          type: GROUP_ADMIN_IMPORT_USERS_SELECT_ALL_FLAG,
                          payload: { state: state },
                        });
                      }}
                    />
                    <svg
                      className={`checkbox ${
                        selectAllFlag ? "checkbox--active" : ""
                      }`}
                      aria-hidden="true"
                      viewBox="0 0 15 11"
                      fill="none"
                    >
                      <path
                        d="M1 4.5L5 9L14 1"
                        strokeWidth="2"
                        stroke={selectAllFlag ? "#fff" : "none"}
                      />
                    </svg>
                    <Typography>Select All</Typography>
                  </label>
                ) : (
                  <FormControlLabel
                    value="all"
                    control={<Checkbox />}
                    label="Select All"
                    labelPlacement="end"
                    disabled={true}
                  />
                )}
              </Grid>
            </Grid>
            <Grid>
              <EMImportUserTable
                data={tabledata}
                isChecked={checked}
                filter={filter}
                open={handleOpen}
                close={handleClose}
                groupId={props.groupId}
                onChangePermission={(permissionData, event) => {
                  if (tabledata.results) {
                    dispatch({
                      type: GROUP_ADMIN_SELECT_UNSELECT_USER_IN_IMPORT_USERS,
                      payload: {
                        userId: permissionData.id,
                        state: event.target.checked,
                      },
                    });
                  }
                }}
              />
              <Box style={{ paddingLeft: 16, paddingRight: 16 }}>
                <EMEntityRoleDropdown
                  title={"Choose Role"}
                  error={showRoleError}
                  style={{ width: "30%" }}
                  onSelect={(id) => {
                    setRole(id);
                  }}
                />
              </Box>
            </Grid>
            <Grid container justify={"center"}>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  roleSelectionHandler();

                  if (!isEmpty(role)) {
                    let emails = [];
                    let requiredUsers = tabledata.results.filter(
                      (d) => d.isChecked == true
                    );
                    emails = requiredUsers.map((x) => x.email);
                    let data = {
                      email: emails,
                      role: role.id,
                      select_all: false,
                    };
                    let contextData = {
                      groupId: props.groupId,
                    };

                    dispatch(
                      EMDoImportGroupAdminSelectedUsersList({
                        data,
                        contextData,
                        key: "users_import_ga",
                      })
                    );
                  }
                }}
              >
                <Typography variant="h7">Invite</Typography>
              </Button>

              <Button
                className={classes.button}
                variant="outlined"
                color="secondary"
                onClick={() => {
                  props.closeHandler(false);
                  setRole();
                  setTabledata([]);
                  setFilter("");
                  setCheckedUsers([]);
                  dispatch({
                    type: GROUP_ADMIN_IMPORT_USERS_SELECT_ALL_FLAG,
                    payload: { state: false },
                  });
                }}
              >
                <Typography variant="h7">Discard</Typography>
              </Button>
            </Grid>
          </Box>
        </form>
      </Box>
    </Modal>
  );
}
