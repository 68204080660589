import {
  PLATFORM_ADMIN_LIST_SUCCESS,
  PLATFORM_ADMIN_UPDATE_SUCCESS,
  PLATFORM_ADMIN_CREATE_SUCCESS,
  PLATFORM_ADMIN_ORGANIZATION_CREATE_SUCCESS,
  PLATFORM_ADMIN_ORGANIZATION_LIST_SUCCESS,
  PLATFORM_ADMIN_ORGANIZATION_UPDATE_SUCCESS,
  PLATFORM_ADMIN_PARTNERSHIP_CREATE_SUCCESS,
  PLATFORM_ADMIN_PARTNERSHIP_LIST_SUCCESS,
  PLATFORM_ADMIN_PARTNERSHIP_UPDATE_SUCCESS,
  RESET,
  PLATFORM_ADMIN_NETWORKS_LIST_DASHBOARD,
  PLATFORM_ADMIN_GET_DASHBOARD_DATA,
  PLATFORM_ADMIN_GET_ONBOARDING_DATA,
  PLATFORM_ADMIN_GET_USAGE_REPORT_DATA,
  PLATFORM_ADMIN_GET_USER_ENGAGEMENT_DATA,
  PLATFORM_ADMIN_GET_NETWORK_STATS_DATA,
  PLATFORM_ADMIN_GET_STATIC_DASHBOARD_DATA,
  PLATFORM_ADMIN_RESEND_USERS_LIST_SUCCESS,
  PLATFORM_ADMIN_RESEND_INVITE_SUCCESS,
  PLATFORM_ADMIN_GET_ORGANIZATION_SUB_TYPES,
  AUTH_LOGOUT,
  PLATFORM_ADMIN_RESEND_INVITES_SELECT_ALL_FLAG,
  PLATFORM_ADMIN_SELECT_UNSELECT_USER_IN_RESEND_INVITES,
  PLATFORM_ADMIN_ADD_REMOVE_RESEND_INVITES_FROM_SECONDARY_ARRAY,
  PLATFORM_ADMIN_IMPORT_USERS_CLEAR_UNCLEAR_SELECT_ALL_WRT_SEARCH,
  PLATFORM_ADMIN_UPDATE_ORGANIZATION_LIST,
  PLATFORM_ADMIN_UPDATE_PARTNERSHIP_LIST,
  PLATFORM_ADMIN_GET_ADMIN_LIST_BASED_ON_ORGANIZATIONS,
  PLATFORM_ADMIN_GET_ADMIN_LIST_FOR_PARTNERSHIP,
} from "../constants";
import { cloneDeep, findIndex } from "lodash";

const initialState = {
  resendUsersList: {},
  selectAll: false,
  checkedUsers: [],
  updateOrgnizationList: false,
  updatePartnershipList: false,
  adminsForPartnerships: [],
  adminsOfSelectedPartnership: [],
};

export default function EMAdminReducer(state = initialState, action) {
  let newState = cloneDeep(state);
  switch (action.type) {
    case PLATFORM_ADMIN_LIST_SUCCESS: {
      newState["platformAdminsList"] = action.payload;
      return newState;
    }

    case PLATFORM_ADMIN_UPDATE_SUCCESS: {
      let userId = action.payload.id;
      let userIndex = findIndex(newState["platformAdminsList"]["results"], {
        id: userId,
      });
      if (userIndex > -1) {
        newState["platformAdminsList"]["results"][userIndex] = {
          ...action.payload,
        };
      }
      return newState;
    }

    case PLATFORM_ADMIN_UPDATE_ORGANIZATION_LIST: {
      newState["updateOrgnizationList"] = action.payload;
      return newState;
    }

    case PLATFORM_ADMIN_UPDATE_PARTNERSHIP_LIST: {
      newState["updatePartnershipList"] = action.payload;
      return newState;
    }

    // case PLATFORM_ADMIN_CREATE_SUCCESS: {
    //   newState["platformAdminsList"]["results"].unshift(action.payload);
    //   newState["platformAdminsList"]["count"] = newState["platformAdminsList"]["count"]++
    //   return newState;
    // }

    case PLATFORM_ADMIN_ORGANIZATION_LIST_SUCCESS: {
      newState["organizationList"] = action.payload;
      return newState;
    }

    case PLATFORM_ADMIN_ORGANIZATION_UPDATE_SUCCESS: {
      let userId = action.payload.id;
      let userIndex = findIndex(newState["organizationList"]["results"], {
        id: userId,
      });
      if (userIndex > -1) {
        newState["organizationList"]["results"][userIndex] = {
          ...action.payload,
        };
      }
      return newState;
    }

    case PLATFORM_ADMIN_ORGANIZATION_CREATE_SUCCESS: {
      newState["organizationList"]["results"].unshift(action.payload);
      newState["organizationList"]["count"] = newState["organizationList"][
        "count"
      ]++;
      return newState;
    }

    case PLATFORM_ADMIN_PARTNERSHIP_LIST_SUCCESS: {
      newState["partnershipList"] = action.payload;
      return newState;
    }

    case PLATFORM_ADMIN_PARTNERSHIP_UPDATE_SUCCESS: {
      let userId = action.payload.id;
      let userIndex = findIndex(newState["partnershipList"]["results"], {
        id: userId,
      });
      if (userIndex > -1) {
        newState["partnershipList"]["results"][userIndex] = {
          ...action.payload,
        };
      }
      return newState;
    }

    case PLATFORM_ADMIN_PARTNERSHIP_CREATE_SUCCESS: {
      newState["partnershipList"]["results"].unshift(action.payload);
      newState["partnershipList"]["count"] = newState["partnershipList"][
        "count"
      ]++;
      return newState;
    }

    case PLATFORM_ADMIN_NETWORKS_LIST_DASHBOARD: {
      newState["networkList"] = action.payload;
      return newState;
    }

    case PLATFORM_ADMIN_GET_DASHBOARD_DATA: {
      newState["dashboardData"] = action.payload;
      return newState;
    }

    case PLATFORM_ADMIN_GET_ONBOARDING_DATA: {
      newState["onboardingData"] = action.payload;
      return newState;
    }

    case PLATFORM_ADMIN_GET_USAGE_REPORT_DATA: {
      newState["usageData"] = action.payload;
      return newState;
    }

    case PLATFORM_ADMIN_GET_USER_ENGAGEMENT_DATA: {
      newState["userEngagementData"] = action.payload;
      return newState;
    }

    case PLATFORM_ADMIN_GET_NETWORK_STATS_DATA: {
      newState["networkStatsData"] = action.payload;
      return newState;
    }

    case PLATFORM_ADMIN_GET_STATIC_DASHBOARD_DATA: {
      newState["dashboardStaticData"] = action.payload;
      return newState;
    }

    case PLATFORM_ADMIN_RESEND_USERS_LIST_SUCCESS: {
      newState["nextUsersResend"] = action.payload.next;
      newState["resendUsersList"] = action.payload;
      newState["selectAll"] = false;
      newState["resendUsersList"] = {
        ...newState["resendUsersList"],
        results: newState["resendUsersList"].results.map((user) => ({
          ...user,
          isChecked: false,
        })),
      };

      return newState;
    }

    case PLATFORM_ADMIN_SELECT_UNSELECT_USER_IN_RESEND_INVITES: {
      let userId = action.payload.userId;

      var foundIndex = newState["resendUsersList"].results.findIndex(
        (x) => x.id == userId
      );
      if (foundIndex >= 0) {
        newState["resendUsersList"].results[foundIndex].isChecked =
          action.payload.state;
      }

      let selectAllState = newState["resendUsersList"].results.every(
        (user) => user.isChecked == true
      );
      if (selectAllState) {
        newState["selectAll"] = true;
      } else {
        newState["selectAll"] = false;
      }
      return newState;
    }

    case PLATFORM_ADMIN_RESEND_INVITES_SELECT_ALL_FLAG: {
      newState["selectAll"] = action.payload.state;
      newState["resendUsersList"] = {
        ...newState["resendUsersList"],
        results: newState["resendUsersList"].results.map((user) => ({
          ...user,
          isChecked: action.payload.state,
        })),
      };
      return newState;
    }

    case PLATFORM_ADMIN_RESEND_INVITE_SUCCESS: {
      newState["invites"] = action.payload;
      return newState;
    }

    case PLATFORM_ADMIN_GET_ORGANIZATION_SUB_TYPES: {
      let data = action.payload;

      if (data && data.length > 0) {
        data = data.map((subType, index) => ({
          ...subType,
          id: index,
          sub_type: subType[index + 1],
        }));
        newState["organizationSubTypes"] = data;
      }
      return newState;
    }

    case PLATFORM_ADMIN_GET_ADMIN_LIST_BASED_ON_ORGANIZATIONS: {
      let data = action.payload;
      newState["adminsForPartnerships"] = data;
      return newState;
    }

    case PLATFORM_ADMIN_GET_ADMIN_LIST_FOR_PARTNERSHIP: {
      let data = action.payload;
      newState["adminsOfSelectedPartnership"] = data;
      return newState;
    }

    case AUTH_LOGOUT: {
      return { ...initialState };
    }

    case RESET: {
      return {};
    }

    default: {
      return newState;
    }
  }
}
