import React from "react";
import {
    Paper,
    Box,
    Button,
    Modal,
    Typography,
    IconButton,
    Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import {
    EMSimpleTextFieldView,
    EMChipTextFieldView,
} from "../../../components/EMTextFieldView";
import {
    EMDoUpdateEditedGroupDetailsByIdAction
} from "../../../redux/actions/EMGroupAdminActions";
import { CloseRounded } from "@material-ui/icons";
import { useFormik, Field, Formik } from "formik";
import * as yup from "yup";
import ChipInput from "material-ui-chip-input";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { findIndex, filter, difference, isEmpty } from "lodash";
import PrivacyDropdown from "../../NetworkAdmin/UserManagement/commons/EMPrivacyDropdown"

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.common.white,
        height: "auto",
        minWidth: "50%",
        margin: "auto",
        padding: "0px",
        // maxWidth: '100vw',
        maxHeight: '100%',
        position: 'fixed',
        top: '50%',
        transform: 'translate(0, -50%)',
        width:"50%"
    },
    title: {
        margin: 10,
        flexGrow: 1,
        fontWeight: "bold",
    },
    form: {
        padding: "5px 36px",
        width: "100%",
    },
    buttonHolder: {
        padding: theme.spacing(3),
    },
}));

export default function EMAddEditGroup(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [localEntity, setLocalEntity] = React.useState(-1);

    const validationSchema = yup.object({
        group_name: yup
            .string("Enter Group Name").trim()
            .required("Group name is required"),
        admin_emails: yup
            .array()
            .transform(function (value, originalValue) {
                if (this.isType(value) && value !== null) {
                    return value;
                }
                return originalValue ? originalValue.split(/[\s,]+/) : [];
            })
            .of(yup.string().email(({ value }) => `${value} is not a valid email`))
            .min(1, "Atleast 1 admin email is required")
            .required("Admin email is requreid"),
        privacy_type: yup.string("Select Privacy Type").required("Select Privacy Type")
    });
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            group_name: props.groupData["group_name"] || "",
            admin_emails: props.groupData["users"]
                ? props.groupData["users"].map(x => x["email"]) : [],
            privacy_type: props.groupData["privacy_type"] || "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            props.setDisabled(true)
            let deleted_admin_emails = difference(
                filter(props.groupData["users"], {
                    role: { role_name: "Admin" }
                }).map(x => x["email"]),
                formik.values.admin_emails
            );
            let new_admin_emails = difference(
                formik.values.admin_emails,
                filter(props.groupData["users"], {
                    role: { role_name: "Admin" }
                }).map(x => x["email"])
            );
            let newGroupData = {
                ...props.groupData,
                ...values,
                deleted_admin_emails,
                new_admin_emails
            };
            let data = {
                "id": props.groupData["id"],
                "group_name": values.group_name,
                "admin_emails":  formik.values.admin_emails,
                "is_active": props.groupData["is_active"],
                "privacy_type": values.privacy_type
            }
            dispatch(EMDoUpdateEditedGroupDetailsByIdAction({ data, key: "edit_group_details" }))
        }
    });
    return (
        <Modal open={props.open} onClose={() => { props.closeState(false) }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box className={classes.root} style={{ outline: 'none' }}>
                <form onSubmit={formik.handleSubmit}>
                    <Box
                        width="100%"
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Typography className={classes.title}>
                            {"Edit Group Details"}
                        </Typography>
                        <IconButton onClick={() => {
                            formik.resetForm(formik.initialValues)
                            props.closeState(false);

                        }} >
                            <CloseRounded color="secondary" />
                        </IconButton>
                    </Box>
                    <Grid
                        container
                        justify="center"
                        alignItems="center"
                        direction="row"
                        className={classes.form}
                    >
                        <EMSimpleTextFieldView
                            label={"Group Name"}
                            id="group_name"
                            name="group_name"
                            value={formik.values.group_name}
                            onChange={formik.handleChange}
                            fullwidth
                            error={
                                formik.touched.group_name && Boolean(formik.errors.group_name)
                            }
                            helperText={
                                formik.touched.group_name && formik.errors.group_name
                            }
                        />
                    </Grid>
                    <Grid
                        container
                        justify="center"
                        alignItems="center"
                        direction="row"
                        spacing={2}
                        className={classes.form}
                    >
                        <Grid item xs>
                            <ChipInput
                                name="admin_emails"
                                id="admin_emails"
                                type="email"
                                label="Admin E-mail Address"
                                helperText={
                                    formik.errors.admin_emails && formik.touched.admin_emails
                                        ? formik.errors.admin_emails
                                        : ""
                                }
                                value={formik.values.admin_emails}

                                error={
                                    formik.errors.admin_emails && formik.touched.admin_emails
                                        ? true
                                        : false
                                }
                                margin="none"
                                onAdd={chip => {
                                    if (chip) {
                                        let oldvalues = [...formik.values.admin_emails];
                                        let index = findIndex(oldvalues, chip);
                                        if (index === -1) {
                                            oldvalues.push(chip);
                                            formik.setFieldValue("admin_emails", oldvalues);
                                        }
                                    }
                                }}
                                onDelete={chip => {
                                    let oldValues = [...formik.values.admin_emails];
                                    let index = oldValues.indexOf(chip);
                                    if (index > -1) {
                                        oldValues.splice(index, 1);
                                        formik.setFieldValue("admin_emails", oldValues);
                                    }
                                }}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        className={classes.form}
                    >
                    <Grid xs={4}>
                        <PrivacyDropdown
                            name="privacy_type"
                            initialValue={formik.values.privacy_type}
                            onSelect={id => {
                                formik.setFieldValue("privacy_type", id)
                            }}
                            error={
                                formik.touched.privacy_type && Boolean(formik.errors.privacy_type)
                            }
                            helperText={
                                formik.touched.privacy_type && formik.errors.privacy_type
                            }
                        />
                    </Grid>
                    </Grid>
                    <Grid
                        container
                        justify="center"
                        alignItems="center"
                        direction="row"
                        className={classes.buttonHolder}
                        spacing={2}
                    >
                        <Grid item>
                            <Button
                                className={classes.button}
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled = {props.saveClicked}
                            >
                                <Typography variant="h7">Save</Typography>
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                className={classes.button}
                                variant="outlined"
                                color="secondary"
                                onClick={() => {
                                    props.closeState(false);
                                    formik.resetForm()
                                }}
                            >
                                <Typography variant="h7">Discard</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Modal>
    );
}
