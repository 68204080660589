//master reset
export const RESET = "reset";

//master error
export const ERROR = "error";

export const SHOW_MESSAGE = "message";
export const HIDE_MESSAGE = "hide_message";

//Auth related action constants
export const AUTH_LOGIN = "auth_login";
export const AUTH_LOGIN_SUCCESS = "auth_login_success";
export const AUTH_LOGOUT = "auth_logout";
export const AUTH_LOGGEDIN_USER_ROLE_PERMISSIONS =
  "auth_loggedin_user_role_permissions";
export const AUTH_MULTIPLE_PROFILE = "auth_multiple_profile";
export const LOGIN_USER_PERMISSIONS = "login_user_permissions";
export const AUTH_USER_PROFILE_ID = "auth_user_profile_id";
export const CHANGE_PROFILE_PIC = "change_profile_pic";
export const GET_ROLES_BASED_ON_ENTITY = "get_roles_based_on_entity";
export const GET_COMMON_ADMIN_PANEL_PERMISSIONS =
  "get_common_admin_panel_permissions";
export const GET_COMMON_GLOBAL_PERMISSIONS = "get_common_global_permissions";
//UI related action constants
export const UI_LOADING = "ui_loading";
export const UI_REMOVE_ERROR = "ui_remove_error";
export const UI_SUCCESS = " ui_sucess";

//Platform Admin related action constants
export const PLATFORM_ADMIN_LIST_SUCCESS = "platform_admin_success";
export const PLATFORM_ADMIN_UPDATE_SUCCESS = "platform_admin_update_sucess";
export const PLATFORM_ADMIN_CREATE_SUCCESS = "platform_admin_create_success";

export const PLATFORM_ADMIN_ORGANIZATION_LIST_SUCCESS =
  "platform_admin_organization_list_success";
export const PLATFORM_ADMIN_ORGANIZATION_UPDATE_SUCCESS =
  "platform_admin_organization_update_sucess";
export const PLATFORM_ADMIN_ORGANIZATION_CREATE_SUCCESS =
  "platform_admin_organization_create_success";

export const PLATFORM_ADMIN_PARTNERSHIP_LIST_SUCCESS =
  "platform_admin_partnership_list_success";
export const PLATFORM_ADMIN_PARTNERSHIP_UPDATE_SUCCESS =
  "platform_admin_partnership_update_sucess";
export const PLATFORM_ADMIN_PARTNERSHIP_CREATE_SUCCESS =
  "platform_admin_partnership_create_success";

export const PLATFORM_ADMIN_NETWORKS_LIST_DASHBOARD =
  "platform_admin_networks_list_dashboard";
export const PLATFORM_ADMIN_GET_DASHBOARD_DATA =
  "platform_admin_get_dashboard_data";
export const PLATFORM_ADMIN_GET_ONBOARDING_DATA =
  "platform_admin_get_onboarding_data";
export const PLATFORM_ADMIN_GET_USAGE_REPORT_DATA =
  "platform_admin_get_usage_data";
export const PLATFORM_ADMIN_GET_USER_ENGAGEMENT_DATA =
  "platform_admin_get_user_engagement_data";
export const PLATFORM_ADMIN_GET_NETWORK_STATS_DATA =
  "platform_admin_get_network_stats_data";
export const PLATFORM_ADMIN_GET_STATIC_DASHBOARD_DATA =
  "platform_admin_get_static_dashboard_data";
export const PLATFORM_ADMIN_RESEND_USERS_LIST_SUCCESS =
  "platform_admin_resend_users_list_success";
export const PLATFORM_ADMIN_RESEND_INVITE_SUCCESS =
  "platform_admin_resend_invite_success";
export const PLATFORM_ADMIN_GET_ORGANIZATION_SUB_TYPES =
  "platform_admin_get_organization_types";
export const PLATFORM_ADMIN_RESEND_INVITES_SELECT_ALL_FLAG =
  "group_admin_resend_invites_select_all_flag";
export const PLATFORM_ADMIN_SELECT_UNSELECT_USER_IN_RESEND_INVITES =
  "group_admin_select_uselect_user_in_resend_invites";
export const PLATFORM_ADMIN_ADD_REMOVE_RESEND_INVITES_FROM_SECONDARY_ARRAY =
  "group_admin_add_remove_resend_invites_from_secondary_array";
export const PLATFORM_ADMIN_IMPORT_USERS_CLEAR_UNCLEAR_SELECT_ALL_WRT_SEARCH =
  "platform_admin_import_users_clear_unclear_select_all_wrt_search";
export const PLATFORM_ADMIN_UPDATE_ORGANIZATION_LIST =
  "platform_admin_update_organization_list";
export const PLATFORM_ADMIN_UPDATE_PARTNERSHIP_LIST =
  "platform_admin_update_partnership_list";
export const PLATFORM_ADMIN_GET_ADMIN_LIST_BASED_ON_ORGANIZATIONS =
  "platform_admin_get_admin_list_based_on_organizations";
export const PLATFORM_ADMIN_GET_ADMIN_LIST_FOR_PARTNERSHIP =
  "platform_admin_get_admin_list_for_partnership";

//Network Admin related actions
export const ENTITY_ROLE_LIST_SUCCESS = "entity_role_list_success";
export const ENTITY_ROLE_RETRIEVE_SUCCESS = "entity_role_retrieve_success";
export const ENTITY_ROLE_UPDATE_SUCCESS = "entity_role_update_success";
export const ENTITY_ROLE_CREATE_SUCCESS = "entity_role_create_success";
export const NETWORK_ADMIN_GROUP_LIST_SUCCESS =
  "network_admin_group_list_success";
export const NETWORK_ADMIN_GROUP_UPDATE_SUCCESS =
  "network_admin_group_update_success";
export const NETWORK_ADMIN_GROUP_CREATE_SUCCESS =
  "network_admin_group_create_success";
export const NETWORK_ADMIN_CHANNEL_LIST_SUCCESS =
  "network_admin_channel_list_success";
export const NETWORK_ADMIN_CHANNEL_UPDATE_SUCCESS =
  "network_admin_channel_update_success";
export const NETWORK_ADMIN_CHANNEL_CREATE_SUCCESS =
  "network_admin_channel_create_success";
export const EMAIL_TEMPLATE_LIST_SUCCESS = "email_template_list_success";
export const EMAIL_TEMPLATE_UPDATE_SUCCESS = "email_template_update_success";
export const EMAIL_TEMPLATE_CREATE_SUCCESS = "email_template_create_success";
export const NETWORK_ADMIN_USERS_LIST_SUCCESS = "network_admin_list_success";
export const NETWORK_ADMIN_UPDATE_SUCCESS = "network_admin_update_sucess";
export const NETWORK_ADMIN_CREATE_SUCCESS = "network_admin_create_success";
export const NETWORK_ADMIN_USERS_EXPORT_SUCCESS =
  "network_admin_export_success";
export const NETWORK_ADMIN_RESEND_INVITE_SUCCESS =
  "network_admin_resend_invite_success";
export const NETWORK_ADMIN_IMPORT_USERS_LIST_SUCCESS =
  "network_admin_import_users_list_success";
export const NETWORK_ADMIN_IMPORT_USERS_SUCCESS =
  "network_admin_import_users_success";
export const NETWORK_ADMIN_BULK_UPLOAD_SUCCESS =
  "network_admin_bulk_upload_success";
export const NETWOEK_ADMIN_EMAIL_TEMPLATE_LIST_SUCCESS =
  "network_admin_email_template_list_success";
export const NETWOEK_ADMIN_ROLE_LIST_SUCCESS =
  "network_admin_role_list_success";
export const NETWORK_LIST_SUCCESS = "network_list_sucess";
export const NETWORK_DETAILS_LIST_SUCCESS = "network_details_list_sucess";
export const NETWORK_DETAILS_UPDATE_SUCCESS = "network_details_update_sucess";
export const NETWORK_ADMIN_RESEND_USERS_LIST_SUCCESS =
  "network_admin_resend_users_list_success";
export const NETWORK_FILE_UPLOAD_DETAILS = "network_file_upload_details_sucess";
export const NETWORK_ADMIN_FILTER_BY_GROUP_DROPDOWN_VALUES_SUCCESS =
  "network_admin_filter_by_group_dropdown_values_success";
export const NETWORK_ADMIN_FILTER_BY_GROUP_LIST_SUCCESS =
  "network_admin_filter_by_group_list_success";
export const NETWORK_ADMIN_EMAIL_TEMPLATE = "network_admin_email_template";

export const NETWORK_ADMIN_GET_DASHBOARD_DATA =
  "network_admin_get_dashboard_data";
export const NETWORK_ADMIN_NETWORKS_LIST_DASHBOARD =
  "network_admin_networks_list_dashboard";
export const NETWORK_ADMIN_ENTITY_LIST = "network_admin_entity_list";
export const NETWORK_ADMIN_GET_ONBOARDING_DATA =
  "network_admin_get_onboarding_data";
export const NETWORK_ADMIN_GET_USAGE_REPORT_DATA =
  "network_admin_get_usage_data";
export const NETWORK_ADMIN_GET_USER_ENGAGEMENT_DATA =
  "network_admin_get_user_engagement_data";
export const NETWORK_ADMIN_GET_NETWORK_STATS_DATA =
  "network_admin_get_network_stats_data";

export const NETWORK_ADMIN_GET_ORGANIZATION_DEPARTMENTS =
  "network_admin_get_organization_departments";
export const NETWORK_ADMIN_GET_ORGANIZATION_LEVELS =
  "network_admin_get_organization_levels";

export const NETWORK_ADMIN_GET_LEVELS_LIST = "network_admin_get_levels_list";
export const NETWORK_ADMIN_GET_BADGES_LIST = "network_admin_get_badges_list";
export const NETWORK_ADMIN_GET_DATA_FOR_DROPDOWN_IN_GAMIFICATION =
  "network_admin_get_data_for_dropdown_in_gamification";
export const NETWORK_ADMIN_GET_LEVEL_REPORT = "network_admin_get_level_report";
export const NETWORK_ADMIN_GET_BADGE_REPORT = "network_admin_get_badge_report";
export const NETWORK_ADMIN_GET_LIST_OF_NETWORKS_FOR_DROPDOWN =
  "network_admin_get_list_of_networks_for_dropdown";
export const NETWORK_ADMIN_GET_PERMISSION_BASED_ON_NETWORK =
  "network_admin_get_permission_based_on_network";

export const NETWORK_ADMIN_IMPORT_USERS_SELECT_ALL_FLAG =
  "network_admin_import_users_select_all_flag";
export const NETWORK_ADMIN_SELECT_UNSELECT_USER_IN_IMPORT_USERS =
  "network_admin_select_uselect_user_in_import_users";
export const NETWORK_ADMIN_ADD_REMOVE_IMPORT_USERS_FROM_SECONDARY_ARRAY =
  "network_admin_add_remove_import_users_from_secondary_array";
export const NETWORK_ADMIN_IMPORT_USERS_CLEAR_UNCLEAR_SELECT_ALL_WRT_SEARCH =
  "network_admin_import_users_clear_unclear_select_all_wrt_search";

export const NETWORK_ADMIN_RESEND_INVITES_USERS_SELECT_ALL_FLAG =
  "network_admin_resend_invites_users_select_all_flag";
export const NETWORK_ADMIN_SELECT_UNSELECT_USER_IN_RESEND_INVITES_USERS =
  "network_admin_select_uselect_user_in_resend_invites_users";
export const NETWORK_ADMIN_ADD_REMOVE_RESEND_INVITES_USERS_FROM_SECONDARY_ARRAY =
  "network_admin_add_remove_resend_invites_users_from_secondary_array";
export const NETWORK_ADMIN_RESEND_INVITES_CLEAR_UNCLEAR_SELECT_ALL_WRT_SEARCH =
  "network_admin_resend_clear_unclear_select_all_wrt_search";
export const NETWORK_ADMIN_UPDATE_USERS_LIST_FOR_IMPORT_USERS =
  "channel_admin_update_users_list_for_import_users";
export const NETWORK_ADMIN_UPDATE_USERS_LIST_FOR_RESEND_INVITES =
  "channel_admin_update_users_list_resend_invites";
export const NETWORK_ADMIN_UPDATE_GROUPS_LIST =
  "channel_admin_update_groups_list";
export const NETWORK_ADMIN_UPDATE_CHANNELS_LIST =
  "channel_admin_update_channels_list";
export const NETWORK_ADMIN_GET_EMAIL_TEMPLATE_BODY =
  "network_admin_get_email_template_body";

//Group Admin actions
//Group Management
export const GROUP_ADMIN_DETAILS_BY_ID = "group_admin_details_by_id";
export const GROUP_ADMIN_REMOVE_USER = "group_admin_remove_user";
export const GROUP_ADMIN_MEMBER_LEAVE_GROUP = "group_admin_member_leave_group";
export const GROUP_ADMIN_UPDATE_DETAILS_BY_ID =
  "group_admin_update_details_by_id";
export const GROUP_ADMIN_UPDATE_GROUP_STATUS =
  "group_admin_update_group_status";
export const GROUP_ADMIN_GROUP_LIST_SUCCESS = "group_admin_group_list_success";
export const GROUP_ADMIN_GROUP_UPDATE_SUCCESS =
  "group_admin_group_update_success";
export const GROUP_ADMIN_USERS_EXPORT_SUCCESS =
  "group_admin_users_export_success";
export const GROUP_ADMIN_GROUP_SETTINGS_UPDATE_SUCCESS =
  "group_admin_group_settings_update_success";
export const GROUP_ADMIN_GROUP_SETTINGS_UPDATE_SUCCESS_FOR_USER_PLATFORM =
  "group_admin_group_settings_update_success_for_user_platform";
export const GROUP_ADMIN_SAVE_IMPORT_USER_DATA =
  "group_admin_save_import_user_data";
export const GROUP_ADMIN_GET_GROUP_LIST = "group_admin_get_group_list";
export const GROUP_ADMIN_ENTITY_LIST = "group_admin_entity_list";
export const GROUP_ADMIN_GET_DASHBOARD_DATA = "group_admin_get_dashboard_data";
export const GROUP_ADMIN_GET_USER_ENGAGEMENT_DATA =
  "group_admin_get_user_engagement_data";
export const GROUP_ADMIN_GET_DATA_FOR_DROPDOWN_IN_GAMIFICATION =
  "group_admin_get_data_for_dropdown_in_gamification";
export const GROUP_ADMIN_GET_LEVEL_REPORT = "group_admin_get_level_report";
export const GROUP_ADMIN_GET_BADGE_REPORT = "group_admin_get_badge_report";
export const GROUP_ADMIN_GET_LIST_OF_GROUPS_IN_USER_MANAGEMENT =
  "group_admin_get_list_of_groups_in_user_management";
export const GROUP_ADMIN_GET_PERMISSION_BASED_ON_GROUP =
  "group_admin_get_permission_based_on_group";
export const GROUP_ADMIN_IMPORT_USERS_SELECT_ALL_FLAG =
  "group_admin_import_users_select_all_flag";
export const GROUP_ADMIN_SELECT_UNSELECT_USER_IN_IMPORT_USERS =
  "group_admin_select_uselect_user_in_import_users";
export const GROUP_ADMIN_ADD_REMOVE_IMPORT_USERS_FROM_SECONDARY_ARRAY =
  "group_admin_add_remove_import_users_from_secondary_array";
export const GROUP_ADMIN_IMPORT_USERS_CLEAR_UNCLEAR_SELECT_ALL_WRT_SEARCH =
  "group_admin_import_users_clear_unclear_select_all_wrt_search";

//channel management
export const GROUP_ADMIN_CHANNEL_LIST_SUCCESS =
  "group_admin_channel_list_success";
export const GROUP_ADMIN_CHANNEL_CREATE_SUCCESS =
  "group_admin_channel_create_success";
export const GROUP_ADMIN_CHANNEL_UPDATE_SUCCESS =
  "group_admin_channel_update_success";
export const GROUP_ADMIN_GROUP_LIST_DROPDOWN_FOR_CHANNEL_SUCCESS =
  "group_admin_group_list_dropdown_for_channel_success";
export const GROUP_ADMIN_CHANNEL_DETAILS_BY_ID =
  "group_admin_channel_details_by_id";
export const GROUP_ADMIN_CHANNEL_SETTINGS_UPDATE_SUCCESS =
  "group_admin_channel_settings_update_success";

//common actions
export const ENTITY_LIST_SUCCESS = "entity_list_sucess";
export const ROLES_LIST_SUCCESS = "roles_list_success"; // get all roles for the application
export const PRIVACY_LIST_SUCCESS = "privay_list_success";
export const EMAIL_TEMPLATE_TYPE_LIST_SUCCESS =
  "email_template_type_list_success";
export const GROUP_LIST_DROPDOWN_SUCCESS = "group_list_dropdown_success";
export const ENTITY_ROLES_LIST_SUCCESS = "entity_roles_list_success";
export const USERDATA_FETCH_SUCCESS = "userdata_fetch_success";
export const USER_CREATE_SUCCESS = "user_create_success";
export const PASSWORD_RESET_SUCCESS = "password_reset_success";
export const PASSWORD_RESET_LINK_SUCCESS = "password_reset_link_success";
export const PLATFORM_ADMIN_DATA_FETCH_SUCCESS =
  "platform_admin_data_fetch_success";
export const ORGANIZATIONS_EXPORT_SUCCESS = "organization_export_success";
export const GET_ORGANIZATION_TYPE = "get_organization_type";
export const GET_ORGANIZATION_TYPE_FIELDS = "get_organization_type_fields";
export const GET_ORGANIZATION_LEVELS = "get_organization_levels";
export const GET_ORGANIZATION_DEPARTMENTS = "get_organization_departments";
export const GET_LIST_PRONOUNS = "get_list_pronouns";
export const GET_LIST_GENDER_IDENTITY = "get_gender_idetity";
export const GET_LIST_RACE = "get_list_race";
export const GET_LIST_MINORITY_IDENTIFICATION =
  "get_list_minoirty_identification";
export const GET_LIST_WORK_TYPES = "get_work_type";
export const GET_PHONE_TYPES = "get_work_types";
export const GET_COUNTRY_CODES = "get_country_codes";
export const GET_ETHNICITY_TYPES = "get_ethnicity_types";
export const OPEN_CLOSE_CRASH_REPORT_DIALOG_BOX =
  "open_close_crash_report_dialog_box";
export const GET_CRASH_REPORT_TEXT = "get_crash_report_text";
export const MARK_NOTIFICATION_AS_SEEN = "mark_notification_as_seen";

//user management

export const GROUP_ADMIN_GROUP_LIST_DROPDOWN_SUCCESS =
  "group_admin_group_list_dropdown_success";
export const GROUP_ADMIN_GROUP__USERS_LIST_DROPDOWN =
  "group_admin_group_users_list_success";
export const GROUP_ADMIN_GROUP_USER_ROLE_DROPDOWN_SUCCESS =
  "group_admin_group_user_role_dropdown_success";
export const GROUP_ADMIN_USER_DETAILS_UPDATE_SUCCESS =
  "group_admin_user_details_update_success";
export const GROUP_ADMIN_IMPORT_USERS_LIST_SUCCESS =
  "group_admin_import_users_list_success";
export const GROUP_ADMIN_IMPORT_USERS_SUCCESS =
  "group_admin_import_users_success";
export const GROUP_ADMIN_RESEND_USERS_LIST_SUCCESS =
  "group_admin_resend_users_list_success";
export const GROUP_ADMIN_RESEND_INVITE_SUCCESS =
  "group_admin_resend_invite_success";
export const GROUP_ADMIN_EMAIL_TEMPLATE_TYPE_LIST_SUCCESS =
  "group_admin_email_template_type_list_success";
export const GROUP_ADMIN_LIST_ROLES_SUCCESS = "group_admin_list_roles_success";
export const GROUP_ADMIN_ROLE_CREATE_SUCCESS =
  "group_admin_role_create_success";
export const GROUP_ADMIN_UPDATE_USERS_LIST = "group_admin_update_users_list";
export const GROUP_ADMIN_UPDATE_GROUPS_LIST = "group_admin_update_groups_list";

//channel admin actions
export const CHANNEL_ADMIN_CHANNEL_LIST_DROPDOWN_SUCCESS =
  "channel_admin_channel_list_dropdown_success";
export const CHANNEL_ADMIN_CHANNEL_USERS_LIST_DROPDOWN =
  "channel_admin_channel_users_list_success";
export const CHANNEL_ADMIN_CHANNEL_USER_ROLE_DROPDOWN_SUCCESS =
  "channel_admin_channel_user_role_dropdown_success";
export const CHANNEL_ADMIN_USER_DETAILS_UPDATE_SUCCESS =
  "channel_admin_user_details_update_success";
export const CHANNEL_ADMIN_USERS_EXPORT_SUCCESS =
  "channel_admin_users_export_success";
export const CHANNEL_ADMIN_CHANNEL_LIST_SUCCESS =
  "channel_admin_channel_list_success";
export const CHANNEL_ADMIN_CHANNEL_UPDATE_SUCCESS =
  "channel_admin_channel_update_success";
export const CHANNEL_ADMIN_GROUP_LIST_DROPDOWN_SUCCESS =
  "channel_admin_group_list_dropdown_success";
export const CHANNEL_ADMIN_CHANNEL_DETAILS_BY_ID =
  "channel_admin_channel_details_by_id";
export const CHANNEL_ADMIN_CHANNEL_SETTINGS_UPDATE_SUCCESS =
  "channel_admin_channel_settings_update_success";
export const CHANNEL_ADMIN_CHANNEL_SETTINGS_UPDATE_SUCCESS_FOR_USER_PLATFORM =
  "channel_admin_channel_settings_update_success_for_user_platform";
export const CHANNEL_ADMIN_IMPORT_USERS_LIST_SUCCESS =
  "channel_admin_import_users_list_success";
export const CHANNEL_ADMIN_IMPORT_USERS_SUCCESS =
  "channel_admin_import_users_success";
export const CHANNEL_ADMIN_RESEND_USERS_LIST_SUCCESS =
  "channel_admin_resend_users_list_success";
export const CHANNEL_ADMIN_RESEND_INVITE_SUCCESS =
  "channel_admin_resend_invite_success_success";
export const CHANNEL_ADMIN_EMAIL_TEMPLATE_TYPE_LIST_SUCCESS =
  "channel_admin_email_template_type_list_success";
export const CHANNEL_ADMIN_LIST_ROLE_SUCCESS =
  "channel_admin_list_role_success";
export const CHANNEL_ADMIN_ROLE_CREATE_SUCCESS =
  "channel_admin_role_create_success";
export const CHANNEL_ADMIN_REMOVE_CHANNEL_USER =
  "channel_admin_remove_channel_user";

export const CHANNEL_ADMIN_GET_GROUP_LIST = "channel_admin_get_group_list";
export const CHANNEL_ADMIN_ENTITY_LIST = "channel_admin_entity_list";
export const CHANNEL_ADMIN_GET_DASHBOARD_DATA =
  "channel_admin_get_dashboard_data";
export const CHANNEL_ADMIN_GET_CHANNEL_LIST = "channel_admin_get_channel_list";
export const CHANNEL_ADMIN_GET_USER_ENGAGEMENT_DATA =
  "channel_admin_get_user_engagement_data";

export const CHANNEL_ADMIN_GET_DATA_FOR_DROPDOWN_IN_GAMIFICATION =
  "channel_admin_get_data_for_dropdown_in_gamification";
export const CHANNEL_ADMIN_GET_LEVEL_REPORT = "channel_admin_get_level_report";
export const CHANNEL_ADMIN_GET_BADGE_REPORT = "channel_admin_get_badge_report";

export const CHANNEL_ADMIN_GET_LIST_OF_CHANNELS_IN_USER_MANAGEMENT =
  "channel_admin_get_list_of_channels_in_user_management";
export const CHANNEL_ADMIN_GET_PERMISSION_BASED_ON_CHANNEL =
  "channel_admin_get_permission_based_on_channel";

export const CHANNEL_ADMIN_IMPORT_USERS_SELECT_ALL_FLAG =
  "channel_admin_import_users_select_all_flag";
export const CHANNEL_ADMIN_SELECT_UNSELECT_USER_IN_IMPORT_USERS =
  "channel_admin_select_uselect_user_in_import_users";
export const CHANNEL_ADMIN_ADD_REMOVE_IMPORT_USERS_FROM_SECONDARY_ARRAY =
  "channel_admin_add_remove_import_users_from_secondary_array";
export const CHANNEL_ADMIN_IMPORT_USERS_CLEAR_UNCLEAR_SELECT_ALL_WRT_SEARCH =
  "channel_admin_import_users_clear_unclear_select_all_wrt_search";
export const CHANNEL_ADMIN_UPDATE_USERS_LIST =
  "channel_admin_update_users_list";

//common
export const USER_INFO = "user_info";
export const GET_NOTIFICATIONS = "get_notifications";
export const CHANGE_TAB_INDEX_TO_ZERO = "change_tab_index_to_zero";
