import {
  Paper,
  Box,
  Button,
  Modal,
  CircularProgress,
  Typography,
  Grid,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import EMPage from "../../../components/commans/EMPage";
import EMDatatableSearchView from "../../../components/datatables/EMDatatableSearchView";
import EMGroupTable from "./EMGroupTable.js";
import { useSelector, useDispatch } from "react-redux";
import { find } from "lodash";
import EMDropDown from "../../../components/commans/EMDropdown";
import EMGroupSettings from "./groupSettings/index";
import { EMDoGetGroupDetailsByIdAction } from "../../../redux/actions/EMGroupAdminActions";
import EMAddEditGroup from "./EMAddEditGroup";
import { findLast } from "lodash";
import { EMDoGetLoggedInUserRolesAction } from "../../../redux/actions/EMAuthActions";
export default function EMGroupManagement(props) {
  const [filter, setFilter] = useState("");
  const [editData, setEditData] = React.useState({});
  const [updateGroupData, setUpdateGroupData] = React.useState({});
  const [showSetting, setShowSetting] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [saveClicked, setDisabled] = React.useState(false);
  const dispatch = useDispatch();
  const uiData = useSelector((state) => state.ui);

  /* Close popUp once details are updated */
  useEffect(() => {
    if (uiData["messages"]) {
      let refObj = findLast(uiData["messages"], {
        key: "update_group_settings",
      });
      if (refObj && refObj.type === "success") {
        setShowSetting(false);
        refObj.type = "failed";
      }
    }
  }, [uiData]);
  /* open and close modal functionality */
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditData({});
  };
  // useEffect(()=>{ dispatch(EMDoGetLoggedInUserRolesAction())},[])
  useEffect(() => {
    // if (uiData["messages"]) {
    //     let refObj = find(uiData["messages"], { key: "edit_group_details" });
    //     if (refObj && refObj.type === "success") {
    //         setEdit(false);
    //     }
    // }
    if (uiData["messages"] && uiData.loading !== true) {
      let refObj = findLast(uiData["messages"], { key: "edit_group_details" });
      if (refObj && refObj.type === "success") {
        setEdit(false);
        setDisabled(false);
        refObj.type = "failed";
      } else if (refObj && refObj.type === "error") {
        setDisabled(false);
        refObj.type = "failed";
      }
    }
  }, [uiData]);
  useEffect(() => {
    if (uiData["messages"]) {
      let refObj = findLast(uiData["messages"], { key: "fetch_group_details" });
      if (refObj && refObj.type === "success") {
        setShowSetting(true);
        refObj.type = "failed";
      }
    }
  }, [uiData]);
  return (
    <EMPage title="Group Admin">
      <Box p={2}>
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={uiData.loading.state}
        >
          <Box
            style={{ display: "flex" }}
            alignItems="center"
            justifyContent="center"
          >
            <Box textAlign="center">
              <CircularProgress />
              <Typography style={{ fontWeight: "medium", fontSize: "large" }}>
                {uiData.loading.message}
              </Typography>
            </Box>
          </Box>
        </Modal>
        <Paper elevation={2}>
          <Box height="100%" display="flex" flexDirection="column">
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              p={3}
              justifyItems="center"
              alignItems="center"
            >
              <Grid
                container
                style={{ maxWidth: "100%" }}
                justify="flex-start"
                item
                xs={12}
                sm={8}
                md={10}
                lg={12}
              >
                <Box
                  width="100%"
                  justify="flex-start"
                  xs={12}
                  sm={8}
                  md={10}
                  lg={12}
                >
                  <EMDatatableSearchView
                    hint={"Search by Group Name, Admin Emails"}
                    onChangeListener={(data) => {
                      setFilter(data);
                    }}
                  />
                </Box>
              </Grid>
              <Box>
                <EMGroupSettings
                  open={showSetting}
                  close={() => setShowSetting(false)}
                />
              </Box>
              <Box>
                <EMAddEditGroup
                  open={edit}
                  close={() => setEdit(false)}
                  closeState={setEdit}
                  groupData={editData}
                  saveClicked={saveClicked}
                  setDisabled={setDisabled}
                />
              </Box>
            </Box>
            <EMGroupTable
              filter={filter}
              onSettings={(groupData) => {
                dispatch(
                  EMDoGetGroupDetailsByIdAction({
                    id: groupData && groupData.id,
                    key: "fetch_group_details",
                    usedIn: "Group Management",
                  })
                );
                setUpdateGroupData(groupData);
              }}
              onEdit={(groupData) => {
                setEdit(true);
                setEditData(groupData);
                setDisabled(false);
              }}
            />
          </Box>
        </Paper>
      </Box>
    </EMPage>
  );
}
