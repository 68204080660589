import { KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { FORM_DATE_FORMAT } from "../../utils/EMConstants";
import moment from "moment";
import { Typography, TextField } from "@material-ui/core";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import Icon from "@material-ui/core/Icon";
import { bool } from "yup";
import { useEffect, useState } from "react";

export default function EMDatePicker({
  label,
  id,
  value,
  onSelectDate,
  minDate,
  error,
  helperText,
  maxDate,
}) {
  const [selectedDate, setSelectedDate] = useState("");

  useEffect(() => {
    if(value == ""){
      setSelectedDate(null);
    }else {
      setSelectedDate(value);
    }
   
  }, [value]);

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          maxDate={maxDate}
          id={id}
          label={label}
          InputLabelProps={{shrink:true}}
          shrink={true}
          format={FORM_DATE_FORMAT}
          renderInput={(props) => (
            <TextField
              variant="filled"
              InputLabelProps={{ shrink: true }}
              {...props}
            />
          )}
          value={selectedDate}
          minDate={minDate}
          onChange={(date) => {
            onSelectDate(date);
          }}
          openPickerIcon={<Icon>CalendarTodayIcon</Icon>}
          error={error}
          helperText={helperText}
          //InputProps={{ style: {fontFamily: "Arial" } }}
        />
      </MuiPickersUtilsProvider>
    </>
  );
}
