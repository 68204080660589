import React, {useState}from 'react';
import {Card, Grid, Button, Hidden} from  "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useFormik, Field } from "formik";
import * as yup from "yup";
const useStyles = makeStyles((theme) => ({
    card : {
        card :{
            padding: "0px 36px",
            width: "100%",
            height : "100%",
            boxShadow: "0px 3px 6px #00000029"
        },
    }
}));

export default function EMFileUploader({changeHandler,fileName,id,accept,fileSize,supportedFormats,maxFileSize}) {
    const [error, setError] =React.useState()
    const [fileSelectedName, setFileSelectedName] =React.useState(undefined)

    React.useEffect(() => {
        if(fileName != null || fileName !== undefined){
            setFileSelectedName(fileName)
        }else{
            setFileSelectedName(undefined)
        }
    }, [fileName])

    const fileChangeHandler  = event =>{
        
        if(event.target.files[0] ){
            if(supportedFormats.includes(event.target.files[0] && event.target.files[0].type)){
                if(event.target.files[0] && event.target.files[0].size <= fileSize){
                    let file = event.target.files;
                    let fileName = event.target.files[0].name;
                    changeHandler(file,fileName)
                    setError("") 
                    setFileSelectedName(fileName)   
                }else{
                    setError("Selected File : "+event.target.files[0] && event.target.files[0].name+" should be less than "+maxFileSize)
                    setFileSelectedName(null)
                    changeHandler(null,null)
                }
            }else{
                setError("Selected File : "+event.target.files && event.target.files[0] && event.target.files[0].name+" is not supported")
                setFileSelectedName(null)
                changeHandler(null,null)
            }
        }
    }

    const classes = useStyles();
    
    return(
        <form>
        <Grid  style = {{marginBottom : 20}} container xs={12}  >
            <Grid xs={8} className={classes.card}>
                <Card style={{ width: "100%" }}>
                    {
                       (fileSelectedName != null || fileSelectedName !== undefined)
                            ? (<span>File Selected - {fileSelectedName}</span>)
                            : (<span>Choose a file...</span>)
                    }
                </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
                <input
                    style={{ display: "none" }}
                    id={"contained-button-file "+id}
                    type="file"
                    accept={accept}
                    onChange={fileChangeHandler}
                    />
                <label htmlFor={"contained-button-file "+id}>
                    <Hidden smDown mdDown>
                    <Button variant="contained" color="primary" component="span"
                     style={{marginLeft:"2rem"}}
                     >Browse</Button>
                    </Hidden>
                    <Hidden lgUp mdUP>
                    <Button variant="contained" color="primary" component="span"
                     style={{marginTop:"1rem"}}
                     >Browse</Button>
                    </Hidden>
                </label>
            </Grid>
        </Grid>
        <p style = {{color :"red"}}>
            {error}
            </p>
        </form>
    )
}