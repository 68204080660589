import React, { useEffect } from "react";
import EMTabs from "../../../components/navigation/EMTabs";
import { useSelector, useDispatch } from "react-redux";
import EMChannelManageUsers from "./ManageUsers/index";
import EMChannelManageRolesAndPermissions from "./ManageRolesAndPermissions/index";
import { EMDoGetLoggedInUserRolesAction } from "../../../redux/actions/EMAuthActions";
import { RESET } from "../../../redux/constants";
export default function EMChannelUserManagement() {
  const userData = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  let tabs = [];
  let components = [];
  useEffect(() => {
    // dispatch(EMDoGetLoggedInUserRolesAction());
    dispatch({
        type:RESET
    })
  }, []);
  // let tabs = ["Manage Users","Manage Roles & Permissions"]
  // let components =[ <EMChannelManageUsers /> ,<EMChannelManageRolesAndPermissions />]
  if (userData.boolean_channel_manage_users) {
    tabs.push("Manage Users");
    components.push(<EMChannelManageUsers />);
  }
  if (userData.boolean_channel_manage_roles_and_permissions) {
    tabs.push("Manage Roles & Permissions");
    components.push(<EMChannelManageRolesAndPermissions />);
  }
  return (
    <EMTabs
      title={"Channel - User Management"}
      tabs={tabs}
      components={components}
    />
  );
}
