import React from "react";
import {
    Paper,
    Box,
    Button,
    Modal,
    Typography,
    IconButton,
    Grid,
    Link,
    Card
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import EMDropDown from '../../../commons/EMEntityTemplateDropdown'
import { EMDoBulkUploadUsersListAction } from "../../../../../../redux/actions/EMNetworkAdminActions"
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.common.white,
        height: "auto",
        width: "50%",
        marginTop: "10%",
        margin: "auto",
        padding: "12px",
        marginLeft: "30%",
    },
    title: {
        margin: 10,
        flexGrow: 1,
        fontWeight: "bold",
    },
    form: {
        padding: "0px 36px",
        width: "100%",
    },
    buttonHolder: {
        padding: theme.spacing(3),
    },
    card: {
        padding: "0px 36px",
        width: "100%",
        height: "100%"
    },
    key : {
        padding:"10px 50px",
    },
    value :{
        fontWeight: "bold",
        paddingTop:"10px",
    },
    button: {
        display:"flex",
        flexDirection:"row",
        justifyContent:"center",
        alignItems:"center"
    },
    box: {
        display:"flex",
        flexDirection:"row",
        justifyContent:"left",
        alignItems:"left"
    },
    center: {
        display:"flex",
        justifyContent:"center",
        alignItems:"center"
    },
    details: {
        marginLeft: "50px"
    }
}));

export default function EMBulkUploadResult(props) {
    
    const classes = useStyles();
    const Status = (props) => {
        <div>
          
        </div>
    }
    const BulkUploadResultData = useSelector(
        state => state.networkAdmin.bulkUploadStatus || ""
    )
    return (
        <Modal open={props.open} onClose={props.close} style={{outline: "none"}}>
            <Box className={classes.root}>
                <Box className={classes.box}>
                    <Typography className={classes.title}>
                        {"Bulk Uploading Status"}
                    </Typography>
                    <IconButton onClick={props.close}>
                        <CloseRounded color="secondary" />
                    </IconButton>
                </Box>
                <Box classname={classes.details}>
                <Box className={classes.box}>
                <Typography className={classes.key}>
                   {"Uploaded Contacts"}
                </Typography>
                <Typography className = {classes.value}>
                    {BulkUploadResultData? BulkUploadResultData["data"].uploaded_contacts : ""}
                </Typography>
            </Box>
            <Box className={classes.box}>
                <Typography className={classes.key}>
                   {"Contacts already present"}
                </Typography>
                <Typography  className = {classes.value}>
                    {BulkUploadResultData? BulkUploadResultData["data"].contacts_already_present : ""}
                </Typography>
            </Box>
            <Box className={classes.box}>
                <Typography className={classes.key}>
                   {"Invalid Records"}
                </Typography>
                <Typography className = {classes.value}>
                    {BulkUploadResultData? BulkUploadResultData["data"].invalid_records : ""}
                </Typography>
                <Grid style={{ marginLeft: 50, paddingTop:15 }}>
                <Link 
                    variant="body2"
                    href={ BulkUploadResultData? process.env.REACT_APP_BACKEND_FILE_DOWNLOAD + BulkUploadResultData["data"].file_path : "" }
                >
                    Download Invitations Record
                </Link>
                </Grid>
            </Box>
            <Box className={classes.box}>
                <Typography className={classes.key}>
                   {"Contacts successfully invited"}
                </Typography>
                <Typography className = {classes.value}>
                    {BulkUploadResultData? BulkUploadResultData["data"].contacts_invited : ""}
                </Typography>
            </Box>
            </Box>
          
            <Box className={classes.center}>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={props.close}
              >
                <Typography variant="h7">OK</Typography>
              </Button>
            </Box>
    

            </Box>
        </Modal>
    );
}
