import {
    Grid,
    Paper,
    Typography,
    Button,
    GridList,
    GridListTile,
    Box, CardMedia, ButtonBase, IconButton,
    Modal, Avatar, CardContent
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { EMSimpleTextFieldView } from "../../../../components/EMTextFieldView"
import { EMDoLinkEmailToPrimaryAccountAction, EMDoUnLinkEmailToPrimaryAccountAction,EMDoGetMultipleProfileDetailsAction } from "../../../../redux/actions/EMAuthActions"
import { useDispatch } from "react-redux";
import { useFormik, Field } from "formik";
import { CloseRounded } from "@material-ui/icons";
import { LINK_PROFILE } from "../../../../redux/constants/UserPlatform/EMPeopleConstant"

const useStyles = makeStyles((theme) => ({
        root: {
        backgroundColor: theme.palette.common.white,
        padding: "0px",
        [(theme.breakpoints.up("md"))]:{
            width:"50%"
        },
        [(theme.breakpoints.down("md"))]:{
            width:"80%"
        },
        height:"auto"
    },
    title: {
        margin: 10,
        flexGrow: 1,
        fontWeight: "bold"
    },
    form: {
        padding: "2%"
    },
    buttonHolder:{
        // marginBottom:"5%"
    }
}));

export default function EMLinkProfile(props) {
    const classes = useStyles();
    const location = useLocation();
    const dispatch = useDispatch()
    const navigation = useNavigate();
    const [email, setEmail] = React.useState()
    const [disabled, setDisabled] = useState(false)
    return (
        <Box className={classes.root} style={{ outline: "none" }}>
            <form className={classes.form}>
                <Box
                    width="100%"
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Typography className={classes.title}>
                        {"Link Profile"}
                    </Typography>
                    <IconButton onClick={() => { dispatch({ type: LINK_PROFILE, payload: false }) }}>
                        <CloseRounded color="secondary" />
                    </IconButton>
                </Box>
                <Grid
                    container
                    justify="center"
                    alignItems="center"
                    direction="row"
                    spacing={2}
                    className={classes.form}
                >
                    <Grid item xs={12} sm={12} md={6}>
                        <EMSimpleTextFieldView
                            label="Enter Email Id to link"
                            onChange={(event) => { setEmail(event.target.value) }} ></EMSimpleTextFieldView>
                    </Grid>
                </Grid>
                <Grid
                    container
                    justify="center"
                    alignItems="center"
                    direction="row"
                    className={classes.buttonHolder}
                    // spacing={4}
                >
                    <Grid item>
                        <Button
                            className={classes.button}
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={(e) => {e.preventDefault() 
                                dispatch(EMDoLinkEmailToPrimaryAccountAction({ email: email })) 
                            dispatch({
                                type:LINK_PROFILE,
                                payload: false
                            })}}
                        >
                            <Typography variant="h7">{"Link Profile"}</Typography>
                        </Button>
                    </Grid>
                    <Grid item>
                       
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
}
