import { Box, Checkbox, IconButton, TableCell, Tooltip, Typography } from "@material-ui/core";
import EMAutoPaginationDatatable from "../../../../../components/datatables/EMAutoPaginationDatatable";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filter as lodash_filter } from "lodash";

import EMSwitch from "../../../../../components/commans/EMSwitch";

const useStyles = makeStyles((theme) => ({
  typo: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 1,
    wordBreak: "break-word",
    overflow: "hidden",
  },
}));

export default function EMAddEditPermissionsTable({
  filter,
  onChangePermission,
  entity
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const data = useSelector(state => state.common.roles || []);
  const classes = useStyles()

  const columns = [
    {
      title: "Permission",
      field: "permission_descr",
      align: "left",
      render: (data) => {
        return (
          <>
            <Tooltip
              title={
                <h2
                  style={{ color: "white" }}
                >{data["permission_descr"]}</h2>
              }
              placement="top"
            >
              <Typography
                className={classes.typo}
              >{data["permission_descr"]}</Typography>
            </Tooltip>
          </>
        );
      },
    },

    {
      title: "Add",
      align: "center",
      render: data => {
        return (
          <Checkbox
            checked={data.hasOwnProperty("add_flag") ?data["add_flag"]:false}
            disabled={data["add_flag_applicable"] == false ? true :false}
            onChange={event => {
              
              let state = event.target.checked;
              data["add_flag"] = state;
              let newData = {...data}
              delete newData.tableData
              onChangePermission(newData);
            }}
          />
        );
      }
    },
    {
      title: "Edit/Deactivate/Delete",
      align: "center",
      render: data => {
        return (
          <Checkbox
            checked={data.hasOwnProperty("edit_flag") ?data["edit_flag"]:false}
            disabled={data["edit_flag_applicable"] == false ? true :false}
            onChange={event => {
              
              let state = event.target.checked;
              data["edit_flag"] = state;
              let newData = {...data}
              delete newData.tableData
              onChangePermission(newData);
            }}
          />
        );
      }
    },
    {
      title: "View",
      align: "center",
      render: data => {
        return (
          <Checkbox
            checked={data.hasOwnProperty("view_flag") ?data["view_flag"]:false}
            disabled={data["view_flag_applicable"] ==false ? true :false}
            onChange={event => {
              
              let state = event.target.checked;
              data["view_flag"] = state;
              let newData = {...data}
              delete newData.tableData
              onChangePermission(newData);
            }}
          />
        );
      }
    }
  ];
  const updateData = query => {
    //dispatch(EMDoGetOrganizationList(query));
  };

  // const data = lodash_filter(paginationData, {
  //   context_type: entity ? entity.context_type : -1
  // });

  // const data =lodash_filter(paginationData,{
  //   context_type_name : entity ? entity.entity : "" 
  // })
  return (
    <Box p={2} maxHeight="200" overflow="auto">
      <EMAutoPaginationDatatable
        columns={columns}
        data={data}
        count={data.length}
        options={{
          cellStyle: {
            color: "#707070",
            fontSize: 15
          },
          headerStyle: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.common.white
          }
        }}
      />
    </Box>
  );
}
