import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Tooltip, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  label: {
    "&:focus": {
      color: theme.palette.common.black,
    },
  },
  typo: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 1,
    wordBreak: "break-word",
    overflow: "hidden",
  },
}));

export default function EMGroupedSingleSelect({
  label,
  data,
  objKey,
  objSecondaryKey,
  objTertKey,
  onChange,
  errors,
  required,
  value,
  objQuatKey,
}) {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState("");
  const handleChange = (event) => {
    onChange(event.target.value);
    setSelectedValue(event.target.value);
  };

  const checkPrevValue = useCallback(() => {
    if (value !== "") {
      setSelectedValue(value);
    }else{
      setSelectedValue("");
    }
  }, [value]);

  useEffect(() => {
    checkPrevValue();
  }, [checkPrevValue]);

  const renderSelectGroup = (data) => {
    const items = data[objSecondaryKey].map((p) => {
      return (
        <MenuItem key={p.id} value={p.id}>
          <Tooltip
            title={<h2 style={{ color: "white" }}>{p[objQuatKey]}</h2>}
            placement="top"
          >
            <Typography variant="p" className={classes.typo}>
              {p[objTertKey]}
            </Typography>
          </Tooltip>
        </MenuItem>
      );
    });
    return [
      <Typography
        style={{ fontWeight: "bolder", fontSize: "14px", marginLeft: "2%" }}
        key={data[objKey]}
      >
        {" "}
        {data[objKey]}{" "}
      </Typography>,
      items,
    ];
  };

  return (
    <div>
      <FormControl required={required} className={classes.formControl}>
        <InputLabel htmlFor="grouped-select" className={classes.label}>
          {label}
        </InputLabel>
        <Select
          id="grouped-select"
          value={selectedValue}
          label={label}
          error={errors}
          onChange={handleChange}
        >
          {data?.map((p) => renderSelectGroup(p))}
        </Select>
      </FormControl>
    </div>
  );
}
