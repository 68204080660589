import React, { useState, useEffect, useRef, useCallback } from "react";
import EMPage from "../../../components/commans/EMPage";
import { withStyles } from "@material-ui/core/styles";

import {
  Paper,
  Box,
  Button,
  makeStyles,
  Grid,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Tooltip,
  Hidden,
} from "@material-ui/core";
import {
  Chart,
  BarSeries,
  Title,
  ArgumentAxis,
  ValueAxis,
  Legend,
} from "@devexpress/dx-react-chart-material-ui";
import { Animation, Stack, ValueScale } from "@devexpress/dx-react-chart";
import { Typography } from "@material-ui/core";
import EMSelect from "../../../components/commans/EMDropdown";
import { find, findIndex } from "lodash";
import EMDatePicker from "../../../components/commans/EMDatePicker";
import { useFormik, Field, Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { EMDoGetNetworkListForDashboardAndReportsAction } from "../../../redux/actions/EMAdminActions";
import moment from "moment";
import {
  EMDoGetEntityListForGroupAction,
  EMDoGetGroupBasedOnOrgOrPartnershipAction,
  EMDoGetGroupDashboardInfo,
} from "../../../redux/actions/EMGroupAdminActions";
import { GROUP_ADMIN_GET_DASHBOARD_DATA } from "../../../redux/constants";

const useStyles = makeStyles((theme) => ({
  Title: {
    textAlign: "center",
    fontWeight: "bolder",
    fontSize: "large",
  },
  circle: {
    lineHeight: "100px",
    // borderRadius: "50%",
    // fontSize: "2vw",
    // textAlign: "center",
    // background: "#000080",

    fontWeight: "bolder",
    color: "#fff",
    position: 'relative',
    width: '70%',
    paddingBottom: '70%',
    background: "#000080", borderRadius: '50%'
  },
  circleText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    margin: '0',
    fontSize: "100%",
    // marginTop:"2.5rem"
    [theme.breakpoints.up("xs")]: {
      //width: "12ch",
      fontSize: "15px",
    },
    [theme.breakpoints.up("sm")]: {
      //width: "12ch",
      fontSize: "25px",
    },
    [theme.breakpoints.up("md")]: {
      //width: "12ch",
      fontSize: "25px",
    },
    [theme.breakpoints.up("lg")]: {
      //width: "12ch",
      fontSize: "35px",
    },
  },
  typography: {
    fontWeight: "bold",
    fontSize: "100%",
    // marginTop:"2.5rem"
    [theme.breakpoints.up("xs")]: {
      //width: "12ch",
      fontSize: "7px",
    },
    [theme.breakpoints.up("sm")]: {
      //width: "12ch",
      fontSize: "10px",
    },
    [theme.breakpoints.up("md")]: {
      //width: "12ch",
      fontSize: "13px",
    },
    [theme.breakpoints.up("lg")]: {
      //width: "12ch",
      fontSize: "16px",
    },
  },
  grid: {
    borderBlock: "5px solid #2F92D6",
    borderLeft: "5px solid #2F92D6",
    borderRight: "5px solid #2F92D6",
    borderRadius: "5px",
  },
  flex: {
    [theme.breakpoints.up("sm")]: {
      display: "flex"
    },
    [theme.breakpoints.up("md")]: {
      display: "flex"
    },
    [theme.breakpoints.up("lg")]: {
      display: "flex"
    },
  },
}));

export default function EMPlatformDashboard(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [networkList, setNetworkList] = useState([]);
  const networkadminData = useSelector((state) => state.groupAdmin || {});
  const [invitationData, setInvitationData] = useState([]);
  const [entityId, setEntityId] = useState();
  const [entity, setEntity] = useState();
  const [groupList, setGroupList] = useState();
  const [totalUserData, setTotalUserData] = useState([]);
  const [activeUserData, setActiveUserData] = useState([]);
  const [channelStats, setChannelStats] = useState([]);
  const [widthDropdown, setWidth] = useState("80%");
  const [domainForTotalUsers, setDomainForTotalUsers] = useState(() => () => [0,10])
  const [domainForActiveUsers, setDomainForActiveUsers] = useState(() => () => [0,10])
  const [domainForChannelStats, setDomainForChannelStats] = useState(() => () => [0,10])

  useEffect(() => {
    dispatch(EMDoGetEntityListForGroupAction());
    dispatch({
      type: GROUP_ADMIN_GET_DASHBOARD_DATA,
      payload: undefined,
    });
  }, []);

  useEffect(() => {
    if (entityId) {
      let result = networkList.find((obj) => {
        return obj.newId === entityId;
      });
      setEntity(result);
    }
  }, [entityId]);

  useEffect(() => {
    if (entity) {
      dispatch(
        EMDoGetGroupBasedOnOrgOrPartnershipAction({
          requiredData: entity,
        })
      );
    }
  }, [entity]);

  useEffect(() => {
    setTotalUserData([]);
    setActiveUserData([]);
    setChannelStats([]);
    if (networkadminData.dashboardDataGroup) {
      setTotalUserData(
        networkadminData.dashboardDataGroup[0].total_users_vs_month_data
      );
      setActiveUserData(
        networkadminData.dashboardDataGroup[0].active_users_vs_month_data
      );
      setChannelStats(networkadminData.dashboardDataGroup[0].channel_stats);
      const maxTotalusers = networkadminData.dashboardDataGroup[0].total_users_vs_month_data.reduce((p, c) => p.user_count > c.user_count ? p : c);
      const maxActiveusers = networkadminData.dashboardDataGroup[0].active_users_vs_month_data.reduce((p, c) => p.user_count > c.user_count ? p : c);
      
      if(networkadminData.dashboardDataGroup[0].channel_stats.length > 0){
        const maxChannelStats = networkadminData.dashboardDataGroup[0].channel_stats.reduce((p, c) => p.users > c.users ? p : c);
        if(maxChannelStats){
          let value = maxActiveusers.users
          value < 10 ? setDomainForChannelStats(() => () => [0,10]) : setDomainForChannelStats(() => () => [0,value + 2])
        }
      }

      if(maxTotalusers){
        let value = maxTotalusers.user_count
        value < 10 ? setDomainForTotalUsers(() => () => [0,10]) : setDomainForTotalUsers(() => () => [0,value + 2])
      }
      if(maxActiveusers){
        let value = maxActiveusers.user_count
        value < 10 ? setDomainForActiveUsers(() => () => [0,10]) : setDomainForActiveUsers(() => () => [0,value + 2])
      }
    }
  }, [networkadminData]);

  useEffect(() => {
    let organizationsArray = [];
    let partnershipsArray = [];
    if (networkadminData && networkadminData.dashboardEntityList) {
      if (
        networkadminData.dashboardEntityList &&
        networkadminData.dashboardEntityList[0] &&
        networkadminData.dashboardEntityList[0].partnerships.length > 0
      ) {
        partnershipsArray =
          networkadminData.dashboardEntityList[0].partnerships.map(
            (part, index) => ({
              ...part,
              isPartnership: true,
              newId: index + 1,
              networkName: part.partnership_name,
            })
          );
      }

      let newIndex = partnershipsArray.length;
      organizationsArray =
        networkadminData.dashboardEntityList &&
        networkadminData.dashboardEntityList[0] &&
        networkadminData.dashboardEntityList[0].organizations.length > 0 &&
        networkadminData.dashboardEntityList[0].organizations.map(
          (org, index) => ({
            ...org,
            isPartnership: false,
            newId: newIndex + index + 1,
            networkName: org.organization_name,
          })
        );
      var combinedArray = [...partnershipsArray, ...organizationsArray];
      function SortArray(x, y) {
        if (x.networkName < y.networkName) {
          return -1;
        }
        if (x.networkName > y.networkName) {
          return 1;
        }
        return 0;
      }
      var sortedArray = combinedArray.sort(SortArray);
      setNetworkList([...sortedArray]);
    }
  }, [networkadminData]);

  useEffect(() => {
    if (networkadminData && networkadminData.groupListForDashboard) {
      function SortArray(x, y) {
        if (x.group_name < y.group_name) {
          return -1;
        }
        if (x.group_name > y.group_name) {
          return 1;
        }
        return 0;
      }
      var sortedArray = networkadminData.groupListForDashboard.sort(SortArray);
      setGroupList(sortedArray);
    }
  }, [networkadminData]);

  const validationSchema = yup.object({
    startDate: yup.date("Enter valid date").required("start date is required"),
    endDate: yup
      .date("Enter valid date")
      .required("end date is required")
      .min(yup.ref("startDate"), "End Date Must be After Start Date")

      .test("", "end date should not be greater than 1 year", (val, props) => {
        const expiryDate = moment(val);
        const enteredDate = moment(props.parent.startDate);
        const tmpExpiryDate = moment(enteredDate).add(365, "days");

        if (!tmpExpiryDate.isBefore(expiryDate)) {
          return true;
        }
      }),
    selectedNetwork: yup
      .string("Please select the network")
      .required("Please select the network"),
    selectedGroup: yup
      .string("Please select the group")
      .required("Please select the group"),
  });

  let date = new Date();
  date.setMonth(date.getMonth() + 1);

  const formik = useFormik({
    initialValues: {
      startDate: new Date(),
      endDate: date,
      selectedNetwork: "",
      selectedGroup: "",
    },

    validationSchema: validationSchema,
    onSubmit: (e) => {
      function formatDate(date) {
        var d = new Date(date),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
      }

      let requiredData = {
        start_date: formatDate(formik.values.startDate),
        end_date: formatDate(formik.values.endDate),
        groupId: formik.values.selectedGroup,
      };
      dispatch(EMDoGetGroupDashboardInfo({ requiredData: requiredData }));
    },
  });

  const ArgumentLabel = (props, index) => {
    const { text } = props;

    props = {
      ...props,
      text: text.slice(0, 3)
    }
    return (<>
      <Tooltip title={<h2 style={{ color: "white" }}>{text}</h2>} >
        <ArgumentAxis.Label {...props} />
      </Tooltip>
    </>);
  };
  window.addEventListener("resize", () => {
    let width = document.body.clientWidth;

    if (width <= 600) { //Close Drawer when screen size medium
      setWidth("100%")
    } else {
      setWidth("80%")
    }
  });
  return (
    <EMPage title="Dashboard" style={{ padding: "2%" }}>
      <div >
        <Paper elevation={2} className={classes.flex}>
          <form
            onSubmit={formik.handleSubmit}
            style={{ width: "100%", padding: "1%", display: "inline-block" }}
          >
            <Grid
              container
              spacing={3}
              alignItems="center"
            //  justifyContent="center"
            >
              <Grid item xs={6} sm={6} md={3} style={{}}  >

                {/* &nbsp; */}
                <EMDatePicker
                  label={"Start Date"}
                  id={"Start Date"}
                  value={formik.values.startDate}
                  onSelectDate={(date) => {
                    formik.setFieldValue("startDate", date);
                  }}
                  minDate={new Date("01/01/1960")}
                  error={
                    formik.touched.startDate && Boolean(formik.errors.startDate)
                  }
                  helperText={
                    formik.touched.startDate && formik.errors.startDate
                  }
                />
              </Grid>
              <Grid item xs={6} sm={6} md={3} style={{}} >
                {/* <div ></div>&nbsp; */}
                <EMDatePicker
                  label={"End Date"}
                  id={"End Date"}
                  value={formik.values.endDate}
                  onSelectDate={(date) => {
                    formik.setFieldValue("endDate", date);
                  }}
                  //  maxDate={maxSelectableDate}

                  minDate={formik.values.startDate}
                  error={
                    formik.touched.endDate && Boolean(formik.errors.endDate)
                  }
                  helperText={formik.touched.endDate && formik.errors.endDate}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2} style={{}}>
                <FormControl  style={{width:widthDropdown}}>
                  <InputLabel id="select-network" style={{color:"#4d4d4d"}}>Network</InputLabel>
                  <Select
                    labelId="select-network"
                    id="select-network"
                    value={
                      networkList.length === 1
                        ? networkList[0].newId
                        : formik.values.selectedNetwork || entityId
                    }
                    label="Select Network"
                    // error={
                    //   formik.errors.selectedNetwork &&
                    //     formik.touched.selectedNetwork
                    //     ? true
                    //     : false
                    // }
                    InputLabelProps={{style:{color:"#4d4d4d"}}}
                    FormHelperTextProps={{style:{color:"red"}}}
                    helperText={formik.touched.selectedNetwork && formik.errors.selectedNetwork}
                    // helperText={formik.touched.selectedNetwork && formik.errors.selectedNetwork}
                    onChange={(selected) => {
                      formik.setFieldValue(
                        "selectedNetwork",
                        selected.target.value
                      );
                      setEntityId(selected.target.value);
                      formik.setFieldValue(
                        "selectedGroup",
                        ""
                      );
                    }}
                  >
                    {networkList &&
                      networkList.length > 0 &&
                      networkList.map((network) => (
                        <MenuItem value={network.newId}>
                          {network.networkName}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText style={{ color: "red" }}>
                    {formik.errors.selectedNetwork &&
                      formik.touched.selectedNetwork
                      ? formik.errors.selectedNetwork
                      : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
              {entityId ? (
                <Grid item xs={12} sm={6} md={2} style={{}}>
                  {/* <Typography
                    style={{
                      fontWeight: "bolder",
                      fontSize: "large",
                      marginBottom: "5%",
                    }}
                  >
                    Select Group
                  </Typography> */}
                  <FormControl style={{width:widthDropdown}}>
                    <InputLabel id="select-network" style={{color:"#4d4d4d"}}>Group</InputLabel>
                    <Select
                      labelId="select-network"
                      id="select-network"
                      value={formik.values.selectedGroup}
                      label="Select Network"
                      // error={
                      //   formik.errors.selectedGroup &&
                      //     formik.touched.selectedGroup
                      //     ? true
                      //     : false
                      // }
                      InputLabelProps={{style:{color:"#4d4d4d"}}}
                      FormHelperTextProps={{style:{color:"red"}}}
                      helperText={formik.touched.selectedGroup && formik.errors.selectedGroup}
                      onChange={(selected) => {
                        formik.setFieldValue(
                          "selectedGroup",
                          selected.target.value
                        );
                      }}
                    >
                      {groupList &&
                        groupList.length > 0 &&
                        groupList.map((group) => (
                          <MenuItem value={group.id}>
                            {group.group_name}
                          </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText style={{ color: "red" }}>
                      {formik.errors.selectedGroup &&
                        formik.touched.selectedGroup
                        ? formik.errors.selectedGroup
                        : ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              ) : null}

              <Grid item xs={12} sm={6} md={2} style={{textAlign:"center"}}>
                <Button type="submit" variant="contained" color="primary">
                  Apply
                </Button>
              </Grid>
            </Grid>
          </form>
          <Hidden smDown>
            {networkadminData.dashboardDataGroup && networkadminData.dashboardDataGroup[0] && networkadminData.dashboardDataGroup[0].no_of_channels ?
              <Grid
              container
              spacing={3}
              justifyContent="center"
              alignItems="center"
              style={{ width: "40%", padding: "1%" }}
              >
                <Grid container item xs={6} sm={6} md={6} alignItems="center" justifyContent="center">
                  <Grid item xs={6} sm={6} md={6}>
                    <div className={classes.circle}>
                      <div className={classes.circleText}>
                        {networkadminData.dashboardDataGroup[0].no_of_active_users || 0}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} >
                    <Typography className={classes.typography}>
                      No. of Users
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item xs={6} sm={6} md={6} alignItems="center" justifyContent="center" >
                  <Grid item xs={6} sm={6} md={6}>
                    <div className={classes.circle}>
                      <div className={classes.circleText}>
                        {networkadminData.dashboardDataGroup[0].no_of_channels}
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6}>
                    <Typography className={classes.typography}>
                      No. of Channels
                    </Typography>

                  </Grid>
                </Grid>
              </Grid>

              : null}
          </Hidden>
          <Hidden lgUp mdUp>
            {networkadminData.dashboardDataGroup && networkadminData.dashboardDataGroup[0] && networkadminData.dashboardDataGroup[0].no_of_channels ?
              <Grid
              container
              spacing={3}
              justifyContent="flex-start"
              alignItems="center"
              style={{ width: "100%", padding: "1%" }}
              >
                <Grid container item xs={6} sm={6} md={6} alignItems="center" justifyContent="center">
                  <Grid item xs={6} sm={6} md={6}>
                    <div className={classes.circle}>
                      <div className={classes.circleText}>
                        {networkadminData.dashboardDataGroup[0].no_of_active_users || 0}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} >
                    <Typography className={classes.typography}>
                      No. of Users
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item xs={6} sm={6} md={6} alignItems="center" justifyContent="center" >
                  <Grid item xs={6} sm={6} md={6}>
                    <div className={classes.circle}>
                      <div className={classes.circleText}>
                        {networkadminData.dashboardDataGroup[0].no_of_channels}
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6}>
                    <Typography className={classes.typography}>
                      No. of Channels
                    </Typography>

                  </Grid>
                </Grid>
              </Grid>

              : null}
          </Hidden>

        </Paper>

        {totalUserData.length > 0 ? (
          <Grid container spacing={1} style={{ paddingTop: "1%" }}>
            <Grid item xs={12} sm={12} md={6}>
              <Paper elevation={2}>
                <Typography
                  style={{
                    fontWeight: "bolder",
                    fontSize: "large",
                    textAlign: "center",
                  }}
                >
                  Total users vs Month
                </Typography>
                <Grid
                  alignItems="center"
                  justifyContent="center"
                  container
                  direction="row"
                >
                  <Grid item xs={2}>
                    <Typography
                      style={{
                        transform: "rotate(-90deg)",
                        fontWeight: "bolder",
                        fontSize: "large",
                      }}
                    >
                      Total Users
                    </Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Chart data={totalUserData}>
                    <ValueScale modifyDomain={domainForTotalUsers} />
                      <ArgumentAxis labelComponent={ArgumentLabel} />

                      <ValueAxis />

                      <BarSeries
                        valueField="user_count"
                        argumentField="month"
                      />

                      <Animation />
                    </Chart>
                  </Grid>
                </Grid>
                <Typography
                  style={{
                    textAlign: "center",
                    fontWeight: "bolder",
                    fontSize: "large",
                  }}
                >
                  Month
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Paper elevation={2}>
                <Typography
                  style={{
                    textAlign: "center",
                    fontWeight: "bolder",
                    fontSize: "large",
                  }}
                >
                  Active users vs Month
                </Typography>
                <Grid
                  alignItems="center"
                  justifyContent="center"
                  container
                  direction="row"
                >
                  <Grid item xs={2}>
                    <Typography
                      style={{
                        transform: "rotate(-90deg)",

                        fontWeight: "bolder",
                        fontSize: "large",
                      }}
                    >
                      Active Users
                    </Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Chart data={activeUserData}>
                    <ValueScale modifyDomain={domainForActiveUsers} />
                      <ArgumentAxis labelComponent={ArgumentLabel} />

                      <ValueAxis />

                      <BarSeries
                        valueField="user_count"
                        argumentField="month"
                        displayMode="rotated"
                      />
                      <Animation />
                    </Chart>
                  </Grid>
                </Grid>
                <Typography
                  style={{
                    textAlign: "center",
                    fontWeight: "bolder",
                    fontSize: "large",
                  }}
                >
                  Month
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Paper elevation={2}>
                <Typography
                  style={{
                    textAlign: "center",
                    fontWeight: "bolder",
                    fontSize: "large",
                  }}
                >
                  Channel Stats
                </Typography>
                <Grid
                  alignItems="center"
                  justifyContent="center"
                  container
                  direction="row"
                >
                  <Grid item xs={2}>
                    <Typography
                      style={{
                        transform: "rotate(-90deg)",

                        fontWeight: "bolder",
                        fontSize: "large",
                      }}
                    >
                      Users
                    </Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Chart data={channelStats}>
                    <ValueScale modifyDomain={domainForChannelStats} />
                      <ArgumentAxis labelComponent={ArgumentLabel} />
                      <ValueAxis />

                      <BarSeries valueField="users" argumentField="channel" />

                      <Animation />
                    </Chart>
                  </Grid>
                </Grid>
                <Typography
                  style={{
                    textAlign: "center",
                    fontWeight: "bolder",
                    fontSize: "large",
                  }}
                >
                  Channel
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        ) : null}
      </div>
    </EMPage>
  );
}
