import {
  Box,
  IconButton,
  TableCell,
  Typography,
  Tooltip,
} from "@material-ui/core";
import EMSimpleDatatable from "../../../components/datatables/EMSimpleDatatable";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import {
  EMDoGetPartnershipList,
  EMDoUpdatePartnershipAction,
} from "../../../redux/actions/EMAdminActions";
import { useEffect, useState } from "react";
import EMSwitch from "../../../components/commans/EMSwitch";
import { Edit } from "@material-ui/icons";
import moment from "moment";
import { DATATABLE_DATE_FORMAT } from "../../../utils/EMConstants";
import EditIcon from "../../../assets/Icon-material-edit.svg";

const useStyles = makeStyles((theme) => ({
  typo: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 1,
    wordBreak: "break-word",
    overflow: "hidden",
  },
}));

export default function EMOrganizationTable({ filter, onEdit }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const paginationData = useSelector(
    (state) => state.admin.partnershipList || {}
  );
  const [limit, setLimit] = useState(15);
  const [offset, setOffset] = useState(0);
  const [order, setOrder] = useState({ id: -1, direction: "asc" });
  const [sortAscending, setSortAscending] = useState(false);
  const classes = useStyles();
  const shoulUpdate = useSelector(
    (state) => state.admin.updatePartnershipList || false
  );

  useEffect(() => {
    if (filter && filter.length > 0) {
      setOffset(0);
    } else if (filter && filter == "") {
      setOffset(offset);
    }
  }, [filter]);

  //const uiData = useSelector((state) => state.ui);

  const columns = [
    {
      title: "Partnership Network Name",
      field: "partnership_name",
      align: "left",
      render: (data) => {
        let organizations = data["partnership_name"];
        return (
          <>
            <Tooltip
              title={<h2 style={{ color: "white" }}>{organizations}</h2>}
              placement="top"
            >
              <Typography className={classes.typo}>{organizations}</Typography>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Partnering Organizations",
      align: "left",
      field: "organization__organization_name",
      sorting: false,
      render: (data) => {
        let organizations = data["organization"]
          .map((org) => org["organization_name"])
          .join(" + ");
        return (
          <>
            <Tooltip
              title={<h2 style={{ color: "white" }}>{organizations}</h2>}
              placement="top"
            >
              <Typography className={classes.typo}>{organizations}</Typography>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Partnership Start Date",
      align: "left",
      field: "start_date",
      render: (data) => {
        let date = data["start_date"];
        date = new Date(date);
        date = moment(date).format("MM/DD/YYYY");
        return <Typography>{date}</Typography>;
      },
    },
    {
      title: "Partnership End Date",
      align: "left",
      field: "end_date",
      render: (data) => {
        let date = data["end_date"];
        date = new Date(date);
        date = moment(date).format("MM/DD/YYYY");
        return <Typography>{date}</Typography>;
      },
    },
    {
      title: "Status",
      align: "left",
      field: "is_active",
      render: (data) => {
        return (
          <EMSwitch
            status={data.is_active}
            dialogText={
              "Are you sure that you want to activate/deactivate the partnership?"
            }
            onChange={(status) => {
              let newData = { ...data, key: "status_change" };
              newData.is_active = status;
              delete newData.tableData;
              dispatch(EMDoUpdatePartnershipAction(newData));
            }}
          />
        );
      },
    },
    {
      title: "Action",
      align: "left",
      sorting: false,
      render: (data) => {
        return (
          <IconButton onClick={() => onEdit(data)}>
            <img alt="Edit" src={EditIcon} />
          </IconButton>
        );
      },
    },
  ];

  const updateData = (query) => {
    dispatch(EMDoGetPartnershipList(query));
  };

  useEffect(() => {
    // updateData("?");
  }, []);

  useEffect(() => {
    const query = [];
    if (limit !== 0) {
      query.push(`limit=${limit}`);
    }
    if (filter) {
      query.push(`search=${filter}`);
    }
    if (offset !== 0) {
      query.push(`offset=${offset}`);
    }
    if (order.id !== -1) {
      if (sortAscending === false) {
        query.push(
          `ordering=${order.direction === "desc" ? "-" : ""}${
            columns[order.id - 1].field
          }`
        );
        setSortAscending(true);
      } else {
        // order.direction = "desc"
        query.push(
          `ordering=${order.direction === "desc" ? "-" : ""}${
            columns[order.id - 1].field
          }`
        );
        setSortAscending(false);
      }
    }
    updateData("?" + query.join("&"));
  }, [limit, filter, offset, order, shoulUpdate]);

  return (
    <Box p={2}>
      {
        <EMSimpleDatatable
          filter={filter}
          columns={columns}
          data={paginationData["results"] || []}
          count={paginationData["count"] || 0}
          updatePageAndPageSizeAndOrder={(page, pageSize, order) => {
            setOffset(page * limit);
            setLimit(pageSize);
            setOrder(order);
          }}
          options={{
            headerStyle: {
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.common.white,
              fontWeight: "bold",
            },
          }}
        />
      }
    </Box>
  );
}
