import React, { useEffect, useReducer } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import moment from "moment";
import {
  Card,
  Box,
  Paper,
  Grid,
  Button,
  TextField,
  Typography,
  IconButton,
  CardContent,
  CardMedia,
  CardHeader,
  GridList,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  useTheme,
  useMediaQuery,
  FormHelperText,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import AddLinkIcon from "@material-ui/icons/InsertLink";
import ReactPlayer from "react-player/lazy";
import { ReactComponent as VideoIcon } from "../../assets/Icon-awesome-video.svg";
import { ReactComponent as ImageIcon } from "../../assets/Icon-metro-image.svg";
import EMTextArea from "../commans/EMTextArea";
import { TimePicker, DatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useFormik, Field, Formik } from "formik";
import * as yup from "yup";
import EMFileSelect from "../commans/EMFileSelect";
import EMMultipleSelect from "../commans/EMMultipleSelect";
import { CloseRounded } from "@material-ui/icons";
import { find, findLast, isEmpty } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { EMSimpleTextFieldView } from "../EMTextFieldView";
import EMFileUploader from "../commans/EMFileUploader";
import EMDatepicker from "../commans/EMDatePicker";
import FormGroup from "@material-ui/core/FormGroup";
import Switch from "@material-ui/core/Switch";
import EMGroupPrivacyDropdown from "../../views/NetworkAdmin/UserManagement/commons/EMPrivacyDropdown";

import {
  SHOW_CREATE_POST,
  USER_IMAGES_UPLOAD,
  USER_IMAGES_DELETE,
  USER_VIDEOS_UPLOAD,
  USER_VIDEOS_DELETE,
  USER_DOCUMENT_UPLOAD,
  USER_DOCUMENT_DELETE,
  SHOW_POST_EDIT,
  POST_IMAGES,
  POST_VIDEOS,
  POST_DOCUMENT,
  POST_IMAGES_DELETE,
  POST_VIDEOS_DELETE,
  POST_DOCUMENT_DELETE,
  USER_EXTERNAL_VIDEO_UPLOAD,
  USER_EXTERNAL_VIDEO_DELETE,
  POST_EXTERNAL_VIDEO_DELETE,
  POST_EXTERNAL_VIDEO_UPLOAD,
} from "../../redux/constants/UserPlatform/index";
import {
  EMDoCreateNewEventAction,
  EMDoCreateNewHashTagAction,
  EMDoEditEventAction,
  EMDoGetEventSharedWithGroupsListAction,
  EMDoGetPeopleMentionForCreatePostListAction,
} from "../../redux/actions/UserPlatform/Home/EMLatestPostPageAction";
import EMDialogBox from "../commans/EMDialogBox";
import EMImageCropper from "../commans/EMImageCropper";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 30,
    // paddingBottom: 5,
    maxHeight: window.screen.height / 1.2,
    width: "100%",
    overflow: "auto",
  },
  content: {
    // marginLeft: "3%",
    padding: "1%",
    width: "100%",
    maxHeight: "80%",
  },
  title: {
    // marginLeft: "1%",
    padding: "1%",
    fontWeight: "bolder",
    // width: "90%",
  },
  description: {
    padding: "1%",
    width: "90%",
  },
  image: {
    height: 0,
    paddingTop: "56.25%",
  },
  button: {
    margin: theme.spacing(2),
    backgroundColor: "white",
  },
  buttonGridforSave: {
    marginTop: theme.spacing(0.5),
    color: "primary",
    paddingBottom: "3%",
    marginLeft: "-8%",
  },
  media: {
    height: 0,
  },
  header: {
    fontWeight: "bold",
    padding: theme.spacing(0.5),
    // padding:"1%"
  },
  attachments: {
    // marginRight: "-100",
  },
  uploadMedia: {
    marginLeft: "-4%",
    marginTop: "1%",
  },
  prevMedia: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    marginLeft: "-10%",
  },
  gridList: {
    width: "80%",
    // height: 450,
  },
}));

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  button: {
    margin: theme.spacing(2),
    backgroundColor: "white",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.primary.main,

    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.primary.main,
      borderColor: theme.palette.common.black,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.common.white,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.common.black}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

export default function EMCreateEvent(props) {
  // const [state, dispatch] = useReducer(reducer, initialState)
  const classes = useStyles();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.home || {});
  const [groupIconFile, setGroupIconFile] = React.useState({});
  const [show_confirm_msg, setShowConfirmMsg] = React.useState(false);
  const postSharedWithGroups = useSelector(
    (state) => state.home.postSharedWithGroups || []
  );
  const postPrivacy = useSelector(
    (state) => state.networkAdmin.privacyList || []
  );
  const userData = useSelector((state) => state.auth || {});
  const [privacyId, setPrivacyId] = React.useState();
  const [description, setDescription] = React.useState("");
  const [videoSelector, setVideoSelector] = React.useState(false);
  const [videoLinkDialog, setVideoLinkDialog] = React.useState(false);
  const [videoLink, setVideoLink] = React.useState("");
  const [image, setImage] = React.useState();

  const theme = useTheme();

  const matchesSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  
  const uiData = useSelector(state => state.ui || {})

  const groupIconFileUploadHandler = (file) => {
    setGroupIconFile({
      file: file,
    });
    let url = URL.createObjectURL(file);
    setImage(url);
  };

  const onClose = () => {
    setVideoSelector(false);
  };

  const onOpen = () => {
    setVideoSelector(true);
  };

  const onCloseVideoLinkDialog = () => {
    setVideoLinkDialog(false);
    setVideoLink("");
  };

  const onOpenVideoLinkDialog = () => {
    setVideoLinkDialog(true);
    setVideoSelector(false);
  };

  // useEffect(() => {
  //   dispatch(EMDoGetEventSharedWithGroupsListAction());
  // },[])

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const validationSchema = yup.object({
    event_name: yup.string().required(" Event Title is required "),
    registration_link: yup.string(),
    event_location: yup.string(),
    event_cost: yup.string(),
    start_time: yup
      .date("Enter valid time")
      .required("Event start time is required"),
    end_time: yup
      .date("Enter valid time")
      .required("Event end time is required"),
    event_start: yup
      .date("Enter Valid Date")
      .min(today, "You can't select past date!")
      .nullable()
      .typeError("Invalid Date")
      .required(" Event start date is required "),
    event_end: yup
      .date("Enter Valid Date")
      .min(today, "You can't select past date!")
      .nullable()
      .typeError("Invalid Date")
      .required(" Event end date is required "),
    groups: yup
      .array()
      .transform(function (value, originalValue) {
        if (this.isType(value) && value !== null) {
          return value;
        }
        return originalValue ? originalValue.split(/[\s,]+/) : [];
      })
      .required("Please Select Groups")
      .min(1, "Select Atleast 1 group"),
  });

  let groupIcon;
  if (props.post && props.post["banner_file"]) {
    let logoFile =
      process.env.REACT_APP_BACKEND_FILE_PREVIEW + props.post["banner_file"];
    let logoFileName = logoFile.substring(
      logoFile.lastIndexOf("/") + 1,
      logoFile.length
    );
    // setGroupIconFile(logoFileName)
    groupIcon = (
      <>
        <p style={{ marginBottom: "2%" }}>
          {" "}
          {"Previous Event Banner Image is : "}{" "}
          <Link
            variant="body2"
            href={logoFile}
            target="_blank"
            rel="noopener"
            download={logoFileName}
          >
            {logoFileName}
          </Link>
        </p>
      </>
    );
  }

  if (props.post && props.post.images && data.post_images == undefined) {
    dispatch({
      type: POST_IMAGES,
      payload: props.post.images,
    });
  }
  if (props.post && props.post.videos && data.post_videos == undefined) {
    dispatch({
      type: POST_VIDEOS,
      payload: props.post.videos,
    });
  }
  if (props.post && props.post.documents && data.post_documents == undefined) {
    dispatch({
      type: POST_DOCUMENT,
      payload: props.post.documents,
    });
  }
  if (
    props.post &&
    props.post.video_links &&
    data.post_external_video_links == undefined
  ) {
    dispatch({
      type: POST_EXTERNAL_VIDEO_UPLOAD,
      payload: props.post.video_links,
    });
  }

  let event_isOnline;

  React.useEffect(() => {
    if (props.post && postPrivacy.length > 0) {
      let index = find(postPrivacy, {
        privacy_type: props.post.privacy_type_name,
      });
      let showValue = index.id;
      setPrivacyId(showValue);
    }
  }, [props.post]);

  // let prevDataForGroups = [];
  // if (props.post && prevDataForGroups.length <= 0) {
  //   let groupArray = props.post.groupName.split(",");
  //   if(groupArray){
  //     groupArray.forEach(group => {
  //       let index = find(postSharedWithGroups, {
  //         group_name: group.trim(),
  //       });
  //       let showValue = index !== undefined && index.group_name + " |" + index.id
  //       if (index !== undefined && index !== false) {
  //         prevDataForGroups.push(showValue);
  //       }
  //     })
  //   }
  // } else if (
  //   props.forGroup &&
  //   props.groupName &&
  //   postSharedWithGroups.length > 0
  // ) {
  //   let index = find(postSharedWithGroups, { group_name: props.groupName });
  //   let showValue = index !== undefined && index.group_name + " |" + index.id;
  //   if (index !== undefined && index !== false) {
  //     prevDataForGroups.push(showValue);
  //   }
  // }

  // let selected_channelIds = [];
  // if (props.post) {
  //   selected_channelIds = prevDataForGroups;
  // } else if (props.id) {
  //   selected_channelIds = [props.id];
  // } else if (props.forGroup && props.groupName) {
  //   let index = find(postSharedWithGroups, { group_name: props.groupName });
  //   let showValue = index !== undefined && index.id;
  //   if (index !== undefined && index !== false) {
  //     selected_channelIds = [showValue];
  //   }
  // } else {
  //   selected_channelIds = [];
  // }

  const formik = useFormik({
    initialValues: {
      isOnline: props.post ? props.post.is_online : true,
      event_name: props.post && props.post.title ? props.post.title : "",
      registration_link:
        props.post && props.post.registration_link
          ? props.post.registration_link
          : "",
      // event_description:
      //   props.post && props.post.postText ? props.post.postText : "",
      event_start:
        props.post && props.post.event_start_date
          ? new Date(props.post.event_start_date)
          : "",
      event_end:
        props.post && props.post.event_end_date
          ? new Date(props.post.event_end_date)
          : "",
      start_time:
        props.post && props.post.event_start_time
          ? new Date(props.post.event_start + "T" + props.post.event_start_time)
          : new Date(),
      end_time:
        props.post && props.post.event_end_time
          ? new Date(props.post.event_end + "T" + props.post.event_end_time)
          : new Date(),
      event_location:
        props.post && props.post.event_location
          ? props.post.event_location
          : "",
      event_cost:
        props.post && props.post.event_attending_cost
          ? props.post.event_attending_cost
          : "",
      privacy: props.post ? privacyId : "",
      groups: props?.selectedChannelIds ? props.selectedChannelIds : [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (props.post) {
        let groups = [];
        let sharedWithGroup = formik.values.groups;
        sharedWithGroup.map((grp) => {
          if (typeof grp === "string") {
            if (grp.indexOf(" |") > -1) {
              let grpName = grp.substr(0, grp.indexOf(" |"));
              let index = find(postSharedWithGroups, { group_name: grpName });
              groups.push(index.id);
            }
          } else {
            groups.push(grp);
          }
        });
        let already_images = [];
        data.post_images.map((img) => {
          already_images.push(img.id);
        });
        let already_videos = [];
        data.post_videos.map((vid) => {
          already_videos.push(vid.id);
        });
        let already_documents = [];
        data.post_documents.map((doc) => {
          already_documents.push(doc.id);
        });
        let already_Video_links = [];
        data.post_external_video_links.map((link) => {
          already_Video_links.push(link.id);
        });

        function formatDate(date) {
          var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

          if (month.length < 2) month = "0" + month;
          if (day.length < 2) day = "0" + day;

          return [year, month, day].join("-");
        }

        let descriptionText =
          description == undefined
            ? props.post && props.post.postText
            : description;

        let title = formik.values.event_name;
        let location = formik.values.event_location;
        let start_date = formik.values.event_start;
        let end_date = formik.values.event_end;
        let isOnline = formik.values.isOnline;
        let attending_cost = formik.values.event_cost;
        let registration_link = formik.values.registration_link;
        let start_time = formik.values.start_time.toLocaleTimeString("it-IT");
        let end_time = formik.values.end_time.toLocaleTimeString("it-IT");
        let linkArray = descriptionText
          .split(" ")
          .map((word) => word.split("href")[1])
          .filter((word) => word !== undefined && !word.includes("users"));
        let thumbnail_link;
        if (linkArray.length > 0) {
          thumbnail_link = linkArray[0].slice(2, linkArray[0].length - 1);
        } else {
          thumbnail_link = "";
        }

        if(descriptionText == "<p><br></p>"){
          descriptionText = descriptionText.replace("<p><br></p>", "")
        }

        let finalData = {
          title: title,
          privacy_type: privacyId,
          location: location,
          description: descriptionText,
          start_date: start_date,
          end_date: end_date,
          start_time: start_time,
          end_time: end_time,
          isOnline: isOnline,
          isEvent: true,
          attending_cost: attending_cost,
          registration_link: registration_link,
          id: props.post.id,
          images: already_images,
          videos: already_videos,
          documents: already_documents,
          channel_ids: groups,
          new_images: data.images,
          new_videos: data.videos,
          new_documents: data.documents,
          video_links: already_Video_links,
          new_video_links: data.externalVideos,
          thumbnail_link: thumbnail_link,
        };

        let files = {};
        if (!isEmpty(groupIconFile) && groupIconFile.file) {
          files["icon"] = groupIconFile && groupIconFile.file;
        }

        let finalObject = {
          ...finalData,
          files,
        };
        const newTags = descriptionText
          .split(" ")
          .map((word) => word.split("#")[1])
          .filter(
            (word) =>
              word !== undefined &&
              !word.includes("</span>") &&
              !word.includes("users") &&
              !word.includes('"') &&
              !data.postHashTags.some((tag) => tag.display === word)
          );
        newTags.length > 0 &&
          newTags.forEach((el, i, arr) => {
            arr[i] = el.replace(/<\/?p[^>]*>/g, "");
          });
        if (newTags.length > 0) {
          newTags.forEach((tag) => {
            dispatch(EMDoCreateNewHashTagAction(tag));
          });
        }
        let tag = descriptionText
          .split(" ")
          .filter(
            (word) =>
              word !== undefined &&
              word.includes("data-link") &&
              word.includes("people")
          )
          .map((word) =>
            word.slice(word.lastIndexOf("/"), word.lastIndexOf(">"))
          )
          .map((word) => word.slice(1, word.length - 1))
          .map((word) => parseFloat(word));

        if (props.usedIn !== "events") {
          dispatch(
            EMDoEditEventAction({
              finalObject,
              userData,
              nextPost: 1,
              taggedUserId: tag,
            })
          );
        } else {
          dispatch(
            EMDoEditEventAction({
              finalObject,
              userData,
              nextPost: 1,
              post: props.post,
              taggedUserId: tag,
            })
          );
        }

      } else {
        function formatDate(date) {
          var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

          if (month.length < 2) month = "0" + month;
          if (day.length < 2) day = "0" + day;

          return [year, month, day].join("-");
        }

        let groups = [];
        let sharedWithGroup = formik.values.groups;
        sharedWithGroup.map((grp) => {
          if (typeof grp === "string") {
            if (grp.indexOf(" |") > -1) {
              let grpName = grp.substr(0, grp.indexOf(" |"));
              let index = find(postSharedWithGroups, { group_name: grpName });
              groups.push(index.id);
            }
          } else {
            groups.push(grp);
          }
        });

        // let title = formik.values.title
        let descriptionText =
          description == undefined
            ? props.post && props.post.postText
            : description;
        let images = data.images;
        let videos = data.videos;
        let documents = data.documents;
        let title = formik.values.event_name;
        let location = formik.values.event_location;
        let start_date = formik.values.event_start;
        let end_date = formik.values.event_end;
        let isOnline = formik.values.isOnline;
        let attending_cost = formik.values.event_cost;
        let registration_link = formik.values.registration_link;
        let start_time = formik.values.start_time.toLocaleTimeString("it-IT");
        let end_time = formik.values.end_time.toLocaleTimeString("it-IT");
        let videoLinks = data.externalVideos;
        let linkArray = descriptionText
          .split(" ")
          .map((word) => word.split("href")[1])
          .filter((word) => word !== undefined && !word.includes("users"));
        let thumbnail_link;
        if (linkArray.length > 0) {
          thumbnail_link = linkArray[0].slice(2, linkArray[0].length - 1);
        } else {
          thumbnail_link = "";
        }

        if(descriptionText == "<p><br></p>"){
          descriptionText = descriptionText.replace("<p><br></p>", "")
        }

        let finalData = {
          title: title,
          privacy_type: privacyId,
          location: location,
          description: descriptionText,
          images: images,
          videos: videos,
          documents: documents,
          channel_ids: groups,
          start_date: start_date,
          end_date: end_date,
          start_time: start_time,
          end_time: end_time,
          isOnline: isOnline,
          isEvent: true,
          attending_cost: attending_cost,
          registration_link: registration_link,
          video_links: videoLinks,
          thumbnail_link: thumbnail_link,
        };
        let files = {};

        if (!isEmpty(groupIconFile)) {
          files["icon"] = groupIconFile && groupIconFile.file;
        }

        let finalObject = {
          ...finalData,
          files,
        };
        const newTags = descriptionText
          .split(" ")
          .map((word) => word.split("#")[1])
          .filter(
            (word) =>
              word !== undefined &&
              !word.includes("</span>") &&
              !word.includes("users") &&
              !word.includes('"') &&
              !data.postHashTags.some((tag) => tag.display === word)
          );
        newTags.length > 0 &&
          newTags.forEach((el, i, arr) => {
            arr[i] = el.replace(/<\/?p[^>]*>/g, "");
          });
        if (newTags.length > 0) {
          newTags.forEach((tag) => {
            dispatch(EMDoCreateNewHashTagAction(tag));
          });
        }
        let tag = descriptionText
          .split(" ")
          .filter(
            (word) =>
              word !== undefined &&
              word.includes("data-link") &&
              word.includes("people")
          )
          .map((word) =>
            word.slice(word.lastIndexOf("/"), word.lastIndexOf(">"))
          )
          .map((word) => word.slice(1, word.length - 1))
          .map((word) => parseFloat(word));
          
        dispatch(
          EMDoCreateNewEventAction({
            finalObject,
            userData,
            nextPost: data.pageofPosts,
            taggedUserId: tag,
          })
        );


      }
    },
  });

  React.useEffect(() => {
    dispatch(EMDoGetPeopleMentionForCreatePostListAction());
  }, []);

  useEffect(() => {
    if (uiData["messages"]) {
      let refObj = findLast(uiData["messages"], { key: "post_error" });
      if (refObj && refObj.type === "error") {
        formik.setSubmitting(false)
        uiData["messages"] = []
      }
    }
  },[uiData])


  const handleConfirmMsg = (e) => {
    e.preventDefault();
    setShowConfirmMsg(true);
  };

  const handleDescription = (value) => {
    setDescription(value);
  };
  return (
    <Paper elevation={2} className={classes.root}>
      <form
        onSubmit={
          formik.values.groups.length > 1
            ? handleConfirmMsg
            : formik.handleSubmit
        }
      >
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          style={{
            padding: "0px 4px 0px 4px",
            backgroundColor: "#2F92D6",
            height: "auto",
          }}
        >
          {formik.values.groups.length > 1 ? (
            <EMDialogBox
              shouldOpen={show_confirm_msg}
              onSelect={(shouldProcess) => {
                if (shouldProcess) {
                  formik.handleSubmit();
                  setShowConfirmMsg(false);
                } else {
                  setShowConfirmMsg(false);
                }
              }}
              dialogText={
                "You are sharing event with more than one group, do you want to share the event?"
              }
            />
          ) : null}
          <Typography
            style={{
              margin: 10,
              flexGrow: 1,
              fontWeight: "bold",
              color: "white",
              fontSize: "large",
            }}
          >
            {props.post ? props.post.editPost.labels.title : "Create an Event"}
          </Typography>
          <IconButton
            onClick={() =>{
              props.post
                ? dispatch({
                    type: SHOW_POST_EDIT,
                    payload: {
                      id: props.post.id,
                      editPost: false,
                      route: props.post.route,
                      title: "Edit Post",
                      button: "Update Post",
                    },
                  })
                : dispatch({
                    type: SHOW_CREATE_POST,
                    payload: { value: false, route: "create_event" },
                  })
                  dispatch({ type: USER_IMAGES_UPLOAD, payload: null });
                  dispatch({ type: USER_VIDEOS_UPLOAD, payload: null });
                  dispatch({ type: USER_DOCUMENT_UPLOAD, payload: null });
                  dispatch({ type: USER_EXTERNAL_VIDEO_UPLOAD, payload: null });
                  dispatch({
                    type: POST_IMAGES,
                    payload: undefined,
                  });
                  dispatch({
                    type: POST_VIDEOS,
                    payload: undefined,
                  });
                  dispatch({
                    type: POST_DOCUMENT,
                    payload: undefined,
                  });
                  dispatch({
                    type: POST_EXTERNAL_VIDEO_UPLOAD,
                    payload: undefined,
                  });
                }
            }
          >
            <CloseRounded style={{color:"white"}} />
          </IconButton>
        </Box>
        <Box className={classes.content}>
          <EMSimpleTextFieldView
            // required
            className={classes.textField}
            label={"Event Title"}
            id={"event_name"}
            name={"event_name"}
            value={formik.values.event_name}
            onChange={formik.handleChange}
            // error={
            //   formik.touched.event_name && Boolean(formik.errors.event_name)
            // }
            helperText={formik.touched.event_name && formik.errors.event_name}
          />
          <div style={{ paddingTop: "2%" }}>
            <EMTextArea
              placeHolder="Event Description"
              value={(props.post && props.post.postText) || description}
              name={description}
              peopleMention={data.postAtMentions}
              hash={data.postHashTags}
              changeHandler={(value) => {
                handleDescription(value);
              }}
            />
          </div>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              marginTop: "2%",
            }}
          >
            <Grid container spacing={3}>
              {/* <Grid container item md={9} xs={12}> */}
              <Grid item md={6} xs={12}>
                <Typography className={classes.header}>
                  {"Event Banner"}
                </Typography>
                <EMImageCropper
                  id={"iconId"}
                  changeHandler={groupIconFileUploadHandler}
                  accept={[".png", ".jpg", ".jpeg"]}
                  fileSize={11534336}
                  supportedFormats={["image/png", "image/jpg", "image/jpeg"]}
                  maxFileSize={"10MB"}
                  aspectRatio={714.8 / 300}
                  minfilesize={102400}
                  minFileSize={"100KB"}
                />
                {groupIcon}
              </Grid>
              <Grid item md={3} xs={6}>
                <Typography className={classes.header}>
                  {"Event From Date"}
                  {"*"}
                </Typography>
                <EMDatepicker
                  required={true}
                  label={""}
                  id={"event_start"}
                  value={formik.values.event_start}
                  onSelectDate={(date) => {
                    formik.setFieldValue("event_start", date);
                  }}
                  minDate={moment(new Date())}
                  // error={
                  //   formik.touched.event_start &&
                  //   Boolean(formik.errors.event_start)
                  // }
                  // helperText={
                  //   formik.touched.event_start && formik.errors.event_start
                  // }
                />
                 {formik.errors.event_start && (
                <FormHelperText style={{color:"red"}}>
                  {formik.errors.event_start}
                </FormHelperText>
              )}
              </Grid>
              <Grid item md={3} xs={6}>
                <Typography className={classes.header}>
                  {"Event To Date"}
                  {"*"}
                </Typography>
                <EMDatepicker
                  required={true}
                  label={""}
                  id={"event_end"}
                  value={formik.values.event_end}
                  onSelectDate={(date) => {
                    formik.setFieldValue("event_end", date);
                  }}
                  minDate={moment(new Date())}
                  // error={
                  //   formik.touched.event_end && Boolean(formik.errors.event_end)
                  // }
                  // helperText={
                  //   formik.touched.event_end && formik.errors.event_end
                  // }
                />
                {formik.errors.event_end && (
                <FormHelperText style={{color:"red"}}>
                  {formik.errors.event_end}
                </FormHelperText>
              )}
              </Grid>
            </Grid>

            {/* </Grid> */}
          </Box>
          <Grid container spacing={3}>
            <Grid item md={9} xs={6} justifyContent="flex-end">
              <Typography className={classes.header}>
                {"Event Start Time"}
                {"*"}
              </Typography>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <TimePicker
                  required={true}
                  fullWidth
                  ampm={false}
                  openTo="hours"
                  views={["hours", "minutes"]}
                  format="HH:mm"
                  label=""
                  value={formik.values.start_time}
                  onChange={(date) => {
                    formik.setFieldValue("start_time", date);
                  }}
                  error={
                    formik.touched.start_time &&
                    Boolean(formik.errors.start_time)
                  }
                  helperText={
                    formik.touched.start_time && formik.errors.start_time
                  }
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={3} xs={6}>
              <Typography className={classes.header}>
                {"Event End Time"}
                {"*"}
              </Typography>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <TimePicker
                  required={true}
                  fullWidth
                  ampm={false}
                  openTo="hours"
                  views={["hours", "minutes"]}
                  format="HH:mm"
                  label=""
                  value={formik.values.end_time}
                  onChange={(date) => {
                    formik.setFieldValue("end_time", date);
                  }}
                  error={
                    formik.touched.end_time && Boolean(formik.errors.end_time)
                  }
                  helperText={formik.touched.end_time && formik.errors.end_time}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid container item md={9} xs={12} spacing={3}>
              <Grid item xs={6} style={{ marginTop: "3.3%" }}>
                <FormGroup>
                  <Typography component="div">
                    <Grid
                      component="label"
                      container
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>Offline</Grid>
                      <Grid item>
                        <AntSwitch
                          checked={formik.values.isOnline}
                          onChange={formik.handleChange}
                          name="isOnline"
                        />
                      </Grid>
                      <Grid item>Online</Grid>
                    </Grid>
                  </Typography>
                </FormGroup>
              </Grid>
              <Grid item xs={6} style={{}}>
                <EMSimpleTextFieldView
                  className={classes.textField}
                  label={"Event Location"}
                  id={"event_location"}
                  name={"event_location"}
                  value={formik.values.event_location}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.event_location &&
                    Boolean(formik.errors.event_location)
                  }
                  helperText={
                    formik.touched.event_location &&
                    formik.errors.event_location
                  }
                />
              </Grid>
            </Grid>
            {!matchesSmallScreen ? (
              <Grid
                item
                md={3}
                xs={3}
                style={{
                  marginLeft: matchesSmallScreen || props.post ? "0%" : "2.5%",
                }}
              >
                <EMSimpleTextFieldView
                  className={classes.textField}
                  label={"Attending Cost"}
                  id={"event_cost"}
                  name={"event_cost"}
                  value={formik.values.event_cost}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.event_cost &&
                    Boolean(formik.errors.event_cost)
                  }
                  helperText={
                    formik.touched.event_cost && formik.errors.event_cost
                  }
                />
              </Grid>
            ) : null}
          </Grid>
          <Grid container spacing={3}>
            {matchesSmallScreen ? (
              <Grid item md={0} xs={6}>
                <EMSimpleTextFieldView
                  className={classes.textField}
                  label={"Attending Cost"}
                  id={"event_cost"}
                  name={"event_cost"}
                  value={formik.values.event_cost}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.event_cost &&
                    Boolean(formik.errors.event_cost)
                  }
                  helperText={
                    formik.touched.event_cost && formik.errors.event_cost
                  }
                />
              </Grid>
            ) : null}

            <Grid item xs={6} md={12}>
              <EMSimpleTextFieldView
                style={{ marginTop: matchesSmallScreen ? 0 : "3.3%" }}
                className={classes.textField}
                label={"Registration Link"}
                id={"registration_link"}
                name={"registration_link"}
                value={formik.values.registration_link}
                onChange={formik.handleChange}
                error={
                  formik.touched.registration_link &&
                  Boolean(formik.errors.registration_link)
                }
                helperText={
                  formik.touched.registration_link &&
                  formik.errors.registration_link
                }
              />
            </Grid>
          </Grid>
          <Grid style={{ justifyContent: "space-evenly" }} container>
            {data.post_images &&
              data.post_images.map((image, id) => (
                <Card className={classes.postMedia}>
                  <CardHeader
                    action={
                      <IconButton
                        style={{ float: "right" }}
                        onClick={(img) =>
                          dispatch({ type: POST_IMAGES_DELETE, payload: id })
                        }
                      >
                        <CloseRounded color="secondary" />
                      </IconButton>
                    }
                  />
                  <div
                    style={{
                      width: "200px",
                      height: "150px",
                      whiteSpace: "nowrap",
                      textAlign: "center",
                    }}
                  >
                    <span
                      style={{
                        display: "inline-block",
                        height: "100%",
                        verticalAlign: "middle",
                      }}
                    ></span>{" "}
                    <img
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        verticalAlign: "middle",
                      }}
                      src={
                        process.env.REACT_APP_BACKEND_FILE_PREVIEW +
                        image.file_name
                      }
                      alt=""
                    />
                  </div>
                </Card>
              ))}
            {data.post_videos &&
              data.post_videos.map((video, id) => (
                <Card className={classes.postMedia}>
                  <CardHeader
                    action={
                      <IconButton
                        style={{ float: "right" }}
                        onClick={(img) =>
                          dispatch({ type: POST_VIDEOS_DELETE, payload: id })
                        }
                      >
                        <CloseRounded color="secondary" />
                      </IconButton>
                    }
                  />
                  <div
                    style={{
                      width: "200px",
                      height: "150px",
                      whiteSpace: "nowrap",
                      textAlign: "center",
                    }}
                  >
                    <span
                      style={{
                        display: "inline-block",
                        height: "100%",
                        verticalAlign: "middle",
                      }}
                    ></span>{" "}
                    <video
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        verticalAlign: "middle",
                      }}
                      controls
                      alt=""
                    >
                      <source
                        src={
                          process.env.REACT_APP_BACKEND_FILE_PREVIEW +
                          video.file_name
                        }
                      />
                    </video>
                  </div>

                  <CardContent>
                    <Typography>
                      {/* {video.substr(video.lastIndexOf("/") + 1, video.length)} */}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            {data.post_external_video_links &&
              data.post_external_video_links.map((video, id) => (
                <Card className={classes.postMedia}>
                  <CardHeader
                    action={
                      <IconButton
                        style={{ float: "right" }}
                        onClick={(img) =>
                          dispatch({
                            type: POST_EXTERNAL_VIDEO_DELETE,
                            payload: id,
                          })
                        }
                      >
                        <CloseRounded color="secondary" />
                      </IconButton>
                    }
                  />
                  <ReactPlayer
                    config={{
                      youtube: {
                        playerVars: { origin: "https://www.youtube.com" },
                      },
                    }}
                    width="200px"
                    height="150px"
                    url={video.file_path}
                  />
                </Card>
              ))}
            {data.post_documents &&
              data.post_documents.map((document, id) => (
                <Card className={classes.postMedia} id="document1">
                  <CardHeader
                    action={
                      <IconButton
                        style={{ float: "right" }}
                        onClick={(img) =>
                          dispatch({ type: POST_DOCUMENT_DELETE, payload: id })
                        }
                      >
                        <CloseRounded color="secondary" />
                      </IconButton>
                    }
                  />
                  <a
                    href={
                      process.env.REACT_APP_BACKEND_FILE_PREVIEW +
                      document.file_name
                    }
                    style={{ color: "blue" }}
                    target="_blank"
                  >
                    {document &&
                      document.file_name.substr(
                        document && document.file_name.lastIndexOf("/") + 1,
                        document && document.length
                      )}
                  </a>
                </Card>
              ))}
          </Grid>
          {/* </Card> */}
          <Grid style={{ justifyContent: "space-evenly" }} container>
            {/* <Box> */}
            {data.images &&
              data.images.map((img, id) => (
                <Card>
                  <CardHeader
                    action={
                      <IconButton
                        style={{ float: "right" }}
                        onClick={(img) =>
                          dispatch({ type: USER_IMAGES_DELETE, payload: id })
                        }
                      >
                        <CloseRounded color="secondary" />
                      </IconButton>
                    }
                  />
                  <div
                    style={{
                      width: "200px",
                      height: "150px",
                      whiteSpace: "nowrap",
                      textAlign: "center",
                    }}
                  >
                    <span
                      style={{
                        display: "inline-block",
                        height: "100%",
                        verticalAlign: "middle",
                      }}
                    ></span>{" "}
                    <img
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        verticalAlign: "middle",
                      }}
                      src={img.url}
                      alt=""
                    />
                  </div>
                  <CardContent>
                    <Typography>{img.name}</Typography>
                  </CardContent>
                </Card>
              ))}
            {data.videos &&
              data.videos.map((video, id) => (
                <Card>
                  <CardHeader
                    action={
                      <IconButton
                        style={{ float: "right" }}
                        onClick={() =>
                          dispatch({ type: USER_VIDEOS_DELETE, payload: id })
                        }
                      >
                        <CloseRounded color="secondary" />
                      </IconButton>
                    }
                  />
                  <div
                    style={{
                      width: "200px",
                      height: "150px",
                      whiteSpace: "nowrap",
                      textAlign: "center",
                    }}
                  >
                    <span
                      style={{
                        display: "inline-block",
                        height: "100%",
                        verticalAlign: "middle",
                      }}
                    ></span>{" "}
                    <video
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        verticalAlign: "middle",
                      }}
                      controls
                      alt=""
                    >
                      <source src={video.url} />
                    </video>
                  </div>
                  <CardContent>
                    <Typography>{video.name}</Typography>
                  </CardContent>
                </Card>
              ))}
            {data.externalVideos &&
              data.externalVideos.map((video, id) => (
                <Card>
                  <CardHeader
                    action={
                      <IconButton
                        style={{ float: "right" }}
                        onClick={() =>
                          dispatch({
                            type: USER_EXTERNAL_VIDEO_DELETE,
                            payload: id,
                          })
                        }
                      >
                        <CloseRounded color="secondary" />
                      </IconButton>
                    }
                  />

                  <ReactPlayer
                    config={{
                      youtube: {
                        playerVars: { origin: "https://www.youtube.com" },
                      },
                    }}
                    width="200px"
                    height="150px"
                    url={video}
                  />
                </Card>
              ))}
            {data.documents &&
              data.documents.map((doc, id) => (
                <Card>
                  <CardHeader
                    action={
                      <IconButton
                        style={{ float: "right" }}
                        onClick={(doc) =>
                          dispatch({ type: USER_DOCUMENT_DELETE, payload: id })
                        }
                      >
                        <CloseRounded color="secondary" />
                      </IconButton>
                    }
                  />
                  <a href={doc.url} style={{ color: "blue" }} target="_blank">
                    {doc.name}
                  </a>
                </Card>
              ))}
            {/* </Box> */}
          </Grid>

          <Box>
            <Grid container spacing={3}>
              <Grid item md={8} xs={12}>
                <Box className={classes.attachments}>
                  <Typography className={classes.header}>
                    Attachments
                  </Typography>
                  {/* <Grid item className={classes.uploadMedia}> */}
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Grid item sm={4} xs={6}>
                      <EMFileSelect
                        changeHandler={(file) => {
                          if (file != null) {
                            dispatch({
                              type: USER_IMAGES_UPLOAD,
                              payload: file,
                            });
                          }
                        }}
                        id={"img"}
                        accept={[".png", ".jpg", ".jpeg"]}
                        fileSize={11534336}
                        supportedFormats={[
                          "image/png",
                          "image/jpg",
                          "image/jpeg",
                        ]}
                        maxFileSize={"10MB"}
                        icon={<ImageIcon />}
                        label="IMAGE"
                        disabled={
                          data.images && data.images.length >= 10 ? true : false
                        }
                        multipleFileSelect={true}
                        maxNumberOfFiles={10}
                        alreadyPresentFiles={data.images && data.images.length}
                      />
                    </Grid>
                    <Grid item style={{ marginBottom: 20 }} sm={4} xs={6}>
                      <Button
                        style={{ margin: "0 auto", display: "flex" }}
                        onClick={onOpen}
                        variant="contained"
                        className={classes.button}
                        // component="span"
                        startIcon={<VideoIcon />}
                      >
                        VIDEO
                      </Button>
                      <Dialog
                        fullWidth={true}
                        onClose={onCloseVideoLinkDialog}
                        open={videoLinkDialog}
                      >
                        <DialogTitle>External Video Link</DialogTitle>
                        <DialogContent>
                          <DialogContentText>
                            Please add external video link below
                          </DialogContentText>
                          <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Video Link"
                            type="email"
                            fullWidth
                            onChange={(e) => {
                              setVideoLink(e.target.value);
                            }}
                            variant="standard"
                            value={videoLink}
                          />
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={onCloseVideoLinkDialog}>
                            Cancel
                          </Button>
                          <Button
                            onClick={() => {
                              dispatch({
                                type: USER_EXTERNAL_VIDEO_UPLOAD,
                                payload: videoLink,
                              });
                              setVideoLink("");
                              onCloseVideoLinkDialog();
                            }}
                          >
                            Add
                          </Button>
                        </DialogActions>
                      </Dialog>
                      {videoSelector ? (
                        <Dialog
                          fullWidth={true}
                          onClose={onClose}
                          open={videoSelector}
                        >
                          <DialogTitle>
                            Select the video type
                            <IconButton
                              aria-label="close"
                              onClick={onClose}
                              style={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                              }}
                            >
                              <CloseRounded />
                            </IconButton>
                          </DialogTitle>
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Grid item xs={6}>
                              <EMFileSelect
                                changeHandler={(file) => {
                                  if (file != null) {
                                    dispatch({
                                      type: USER_VIDEOS_UPLOAD,
                                      payload: file,
                                    });
                                  }
                                  onClose();
                                }}
                                id={"vid"}
                                accept={".mp4"}
                                fileSize={11534336}
                                supportedFormats={["video/mp4"]}
                                maxFileSize={"10MB"}
                                icon={<VideoIcon />}
                                label="VIDEO FILE"
                                disabled={
                                  data.videos && data.videos.length >= 10
                                    ? true
                                    : false
                                }
                                multipleFileSelect={true}
                                maxNumberOfFiles={10}
                                alreadyPresentFiles={data.videos && data.videos.length}
                              />
                            </Grid>
                            <Grid item xs={6} style={{ marginBottom: 20 }}>
                              <Button
                                onClick={onOpenVideoLinkDialog}
                                variant="contained"
                                className={classes.button}
                                component="span"
                                startIcon={<AddLinkIcon />}
                              >
                                VIDEO LINK
                              </Button>
                            </Grid>
                          </Grid>
                        </Dialog>
                      ) : null}
                    </Grid>
                    <Grid item sm={4} xs={6}>
                      <EMFileSelect
                        changeHandler={(file) => {
                          if (file != null) {
                            dispatch({
                              type: USER_DOCUMENT_UPLOAD,
                              payload: file,
                            });
                          }
                        }}
                        id={"doc"}
                        accept={[
                          ".pdf",
                          ".txt",
                          ".doc",
                          ".xls",
                          ".pptx",
                          ".ppt",
                          ".docx",
                          ".xlsx",
                        ]}
                        fileSize={11534336}
                        supportedFormats={[
                          "text/plain",
                          "application/pdf",
                          "application/doc",
                          "application/ms-doc",
                          "application/msword",
                          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                          "application/mspowerpoint",
                          "application/powerpoint",
                          "application/vnd.ms-powerpoint",
                          "application/x-mspowerpoint",
                          "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                          "application/excel",
                          "application/vnd.ms-excel",
                          "application/x-excel",
                          "application/x-msexcel",
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        ]}
                        maxFileSize={"10MB"}
                        icon={<CloudUploadIcon />}
                        label="DOCUMENT"
                        disabled={
                          data.documents && data.documents.length >= 10
                            ? true
                            : false
                        }
                        multipleFileSelect={true}
                        maxNumberOfFiles={10}
                        alreadyPresentFiles={data.documents && data.documents.length}
                      />
                    </Grid>
                    {/* </Grid> */}
                  </Grid>
                </Box>
              </Grid>

              {props.id ? null : (
                <Grid item md={4} xs={12}>
                  <Box>
                    <Typography className={classes.title}>
                      Share to Groups
                    </Typography>
                    <Box style={{ padding: matchesSmallScreen ? "8px" : 0 }}>
                      <EMMultipleSelect
                        label={"Select Groups"}
                        data={postSharedWithGroups}
                        objKey={"group_name"}
                        objId={"id"}
                        prevData={props?.selectedChannelIds?.length > 0 && props.selectedChannelIds}
                        formik={{
                          errors: formik.errors.groups,
                          touched: formik.touched.groups,
                        }}
                        onChange={(selectedGroups) => {
                          let ids = [];
                          selectedGroups.map((grp) => {
                            let id = String(grp).substr(
                              String(grp).lastIndexOf("|") + 1
                            );
                            ids.push(parseInt(id));
                          });
                          formik.setFieldValue("groups", ids);
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
          <Grid
            container
            spacing={2}
            style={{ marginTop: matchesSmallScreen ? "8px" : 0 }}
            // className={classes.buttonGridforSave}
          >
            <Grid
              item
              md={6}
              xs={7}
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button
                // style={{ width: "50%" }}
                variant="contained"
                color="primary"
                onClick={
                  formik.values.groups.length > 1 ? handleConfirmMsg : null
                }
                type="submit"
                disabled={formik.isSubmitting}
                // disabled={!(formik.isValid && formik.dirty)}
              >
                {props.post
                  ? props.post.editPost.labels.title
                  : "Create an Event"}
              </Button>
            </Grid>
            <Grid
              item
              md={6}
              xs={5}
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <label htmlFor={"contained-button-file"}>
                <Button
                  // style={{ width: "50%" }}
                  onClick={() =>{
                    props.post
                      ? dispatch({
                          type: SHOW_POST_EDIT,
                          payload: {
                            id: props.post.id,
                            route: props.post.route,
                            editPost: false,
                            title: "Edit Post",
                            button: "Update Post",
                          },
                        })
                      : dispatch({
                          type: SHOW_CREATE_POST,
                          payload: { value: false, route: "create_post" },
                        })
                        dispatch({ type: USER_IMAGES_UPLOAD, payload: null });
                        dispatch({ type: USER_VIDEOS_UPLOAD, payload: null });
                        dispatch({ type: USER_DOCUMENT_UPLOAD, payload: null });
                        dispatch({ type: USER_EXTERNAL_VIDEO_UPLOAD, payload: null });
                        dispatch({
                          type: POST_IMAGES,
                          payload: undefined,
                        });
                        dispatch({
                          type: POST_VIDEOS,
                          payload: undefined,
                        });
                        dispatch({
                          type: POST_DOCUMENT,
                          payload: undefined,
                        });
                        dispatch({
                          type: POST_EXTERNAL_VIDEO_UPLOAD,
                          payload: undefined,
                        });
                      }
                  }
                  variant="contained"
                >
                  DISCARD
                </Button>
              </label>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Paper>
  );
}
