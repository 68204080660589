import { useState, useEffect, useCallback } from "react";
import API from "../../api";
import { GET_MEMBERS_OF_GROUP } from "../../redux/constants/UserPlatform/EMGroupConstant";
import { useDispatch } from "react-redux";

function useFetch(id, limit) {
  const [error, setError] = useState(false);
  const [list, setList] = useState([]);
  const dispatch = useDispatch()

  const sendQuery = useCallback(async () => {
    try {
        const res = await API.EMDoGetMembersOfGroup(id, limit)
      await setList((prev) => [
        ...new Set([...prev, ...res.data.results])
      ]);
   await dispatch({
     type:GET_MEMBERS_OF_GROUP,
     payload:list
   })
    } catch (err) {
      setError(err);
    }
  }, [id, limit]);

  useEffect(() => {
    sendQuery(id,limit);
  }, [id, sendQuery, limit]);

  return { error, list };
}

export default useFetch;
