import React, { useState, useEffect, useCallback } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import EMPage from "../../../components/commans/EMPage";
import {
  Grid,
  Paper,
  Typography,
  GridList,
  GridListTile,
  CardContent,
  Card,
  useMediaQuery,
  isWidthUp,
  withWidth
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { EMDoGetNetworksBySearchKeyAction } from "../../../redux/actions/EMCommonActions";
import { useNavigate } from "react-router";
import organizationLogo from "../../../../src/assets/organization_logo.jpg";
import {
  GET_NETWORKS_BY_SEARCH,
  SAVE_GLOBAL_SEARCH,
} from "../../../redux/constants/UserPlatform/EMNavigationConstant";
import EMSideBar from "../../../components/navigation/EMSideBar";
import InfiniteScroll from "react-infinite-scroll-component";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "2%",
  },
  header: {
    fontWeight: "bolder",
    fontSize: "1.5rem",
  },
  title: {
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.1rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.5rem",
    },
    fontWeight: "bold",
    marginLeft: "1%",
    marginBottom: "2%",
    //
  },
  images: {
    [theme.breakpoints.up("sm")]: {
      height: "90%",
    },
  },

  networkInfo: {
    textAlign: "center",
    height: "80px",
    // marginTop:'20%'
  },
  gridListTile: {
    marginLeft: "2%",
    marginBottom: "2%",
    marginTop: "2%",
    borderRadius: "10px",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);",
  },
  root: {
    [theme.breakpoints.up("sm")]: {
      width: "80%",
    },
    marginTop: "2%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    // padding:"10px",
    width: "100%",
    height: "100%",
  },
}));

function EMSearchNetwork(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.navigation || {});
  const navigate = useNavigate();
  const [limit, setLimit] = useState(0);

  const theme = useTheme();

  const matchesMediumScreen = useMediaQuery(theme.breakpoints.up("md"));

  const matchesLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  function getCols(screenWidth) {
    if (isWidthUp("xl", screenWidth)) {
      return 5;
    }

    if (isWidthUp("lg", screenWidth)) {
      return 4;
    }
    if (isWidthUp("md", screenWidth)) {
      return 4;
    }
    if (isWidthUp("sm", screenWidth)) {
      return 2;
    }
    if (isWidthUp("xs", screenWidth)) {
      return 2;
    }
    return 3;
  }
  const cols = getCols(props.width);

  useEffect(() => {
    if (data.filter && data.filter.length > 0) {
      
    } else {
      dispatch({
        type: GET_NETWORKS_BY_SEARCH,
        payload: [],
      });
    }
  }, [data.filter]);

  useEffect(() => {
    if (data.filter && data.filter.length > 0) {
    } else {
      dispatch({
        type: GET_NETWORKS_BY_SEARCH,
        payload: {
          response: { results: [], next: null },
          page: 1,
        },
      });
    }
  }, [data.filter]);

  const sendQuery = useCallback(async () => {
    if (data.filter && data.filter.length > 0) {
      dispatch(EMDoGetNetworksBySearchKeyAction({ searchText: data.filter, limit: limit, }));
    }
  }, [limit]);

  useEffect(() => {
    sendQuery(limit);
  }, [sendQuery, limit]);

  const handleObserver = useCallback(() => {
    if (data.nextNetworks !== null && data.filter && data.filter.length > 0) {
      setLimit((prev) => prev + 10);
    }
  }, [limit, data.nextNetworks, data.filter]);

  return (
<InfiniteScroll
    dataLength={data.searchedNetworks.length}
    next={handleObserver}
    hasMore={true}
  >
    <EMPage
      title="Networks"
      style={{
        width: "100%",
        paddingLeft: matchesLargeScreen  ? "8%" : "4%",
        paddingTop: matchesLargeScreen ? "4%" : matchesMediumScreen ? "6%" : "8%",
        paddingRight: matchesLargeScreen  ? "8%" : "4%",
        paddingBottom: "2%",
      }}
    >
      <Grid container spacing={3}>
      {matchesMediumScreen ? (
            <Grid item xs={matchesLargeScreen ? 2 : 3}>
              <EMSideBar />
            </Grid>
          ) : null}
        <Grid item xs={matchesLargeScreen ? 10 : matchesMediumScreen ? 9 : 12}>
          <Paper elevation={2}>
            <div style={{ padding: "2%" }}>
              <Typography className={classes.title} variant={"h5"}>
                Search Results For "{data.filter}"
              </Typography>
              <div className={classes.root}>
                <GridList
                  cellHeight={160}
                  className={classes.gridList}
                  cols={cols}
                >
                  {data.searchedNetworks.length > 0 &&
                    data.searchedNetworks.map((network) => (
                      <GridListTile
                        className={classes.gridListTile}
                        style={{ height: "100%" }}
                      >
                        <Card
                          style={{ maxWidth: "100%" }}
                          onClick={() => {
                            navigate("/users/networks/details", {
                              state: { NetworkId: network.id },
                            });
                            window.sessionStorage.setItem(
                              "NetworkId",
                              network.id
                            );
                            dispatch({
                              type: SAVE_GLOBAL_SEARCH,
                              payload: "",
                            });
                          }}
                          elevation={2}
                        >
                          <div style={{ height: "180px" }}>
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                                whiteSpace: "nowrap",

                                textAlign: "center",
                              }}
                            >
                              <span
                                style={{
                                  display: "inline-block",
                                  height: "100%",
                                  verticalAlign: "middle",
                                }}
                              ></span>
                              <img
                                alt=""
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                  verticalAlign: "middle",
                                }}
                                src={
                                  network.logo_file
                                    ? process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(
                                        0,
                                        -1
                                      ) + network.logo_file
                                    : organizationLogo
                                }
                              />
                            </div>
                          </div>
                          <CardContent className={classes.networkInfo}>
                            <Typography style={{ marginTop: "1%" }}>
                              {network.partnership_name}
                            </Typography>
                          </CardContent>
                        </Card>
                      </GridListTile>
                    ))}
                </GridList>
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </EMPage>
   </InfiniteScroll>
  );
}

export default withWidth()(EMSearchNetwork)