import React, { useState, useEffect } from "react";
import {
  Paper,
  Box,
  Button,
  Modal,
  Typography,
  IconButton,
  Grid,
  FormControlLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import EMTextFieldSearch from "../../../../../../components/datatables/EMDatatableSearchView";
import EMImportUserTable from "./EMImportUserTable";
import { CloseRounded, CheckBox } from "@material-ui/icons";
import EMEntityRoleDropdown from "../../../commons/EMEntityRoleDropdown";
import EMFilterByGroupDropdown from "../../../commons/EMFilterByGroupDropdown";
import Checkbox from "@material-ui/core/Checkbox";
import { find } from "lodash";
import {
  EMDoGetFilterByGroupListByIdAction,
  EMDoImportAllSelectedUsersList,
} from "../../../../../../redux/actions/EMNetworkAdminActions";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import {
  NETWORK_ADMIN_ADD_REMOVE_IMPORT_USERS_FROM_SECONDARY_ARRAY,
  NETWORK_ADMIN_IMPORT_USERS_CLEAR_UNCLEAR_SELECT_ALL_WRT_SEARCH,
  NETWORK_ADMIN_IMPORT_USERS_SELECT_ALL_FLAG,
  NETWORK_ADMIN_SELECT_UNSELECT_USER_IN_IMPORT_USERS,
} from "../../../../../../redux/constants";
import { uniqBy } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,

    overflowY: "auto",
    width: "50%",
    marginTop: 10,
    margin: "auto",
    padding: "0px",
    marginLeft: "25%",
    maxHeight: window.screen.height / 1.2,
  },
  paper: {
    margin: "3%",
  },
  title: {
    margin: 10,
    flexGrow: 1,
    fontWeight: "bold",
    color: "white",
    fontSize: "large",
  },
  form: {
    padding: "0px 36px",
    width: "100%",
  },
  button: {
    padding: theme.spacing(1),
    margin: 20,
  },
}));

export default function EMImportUsers(props) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(false);
  const [filter, setFilter] = useState("");
  const [role, setRole] = useState();
  const [filterByGroup, setFilterByGroup] = useState(-1);
  const [tabledata, setTabledata] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectAll, setSelectAll] = React.useState(false);
  const [showRoleError, setShowRoleError] = React.useState();
  const [isError, setIsError] = React.useState(true);
  const [checkedUsers, setCheckedUsers] = React.useState([]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const data = useSelector((state) => state.networkAdmin.importUsersList || {});
  const selectAllFlag = useSelector(
    (state) => state.networkAdmin.selectAllForImportUsers
  );
  const selectedUsers = useSelector(
    (state) => state.networkAdmin.checkedUsersForImportUsers
  );

  useEffect(() => {
    runThisFunctionFirst();
  }, [data]);

  const runThisFunctionFirst = () => {
    return new Promise(function (resolve) {
      if (data.results) {
        setTabledata(data);
      }
      resolve();
    });
  };

  const uiData = useSelector((state) => state.ui);

  useEffect(() => {
    if(props.open == false){
      setRole()
    }
  },[props.open])

  // useEffect(() => {
  //   if (uiData["messages"]) {
  //     let refObj = find(uiData["messages"], { key: "user_import" });
  //     if (refObj && refObj.type === "success") {
  //       setOpen(false);
  //       dispatch({
  //         type: NETWORK_ADMIN_IMPORT_USERS_SELECT_ALL_FLAG,
  //         payload: { from: "selectAll", state: false },
  //       });
  //       dispatch({
  //         type:   NETWORK_ADMIN_ADD_REMOVE_IMPORT_USERS_FROM_SECONDARY_ARRAY,
  //         payload:{data: [], from:"selectAll", state:false}
  //       });
  //       refObj.key = "user_imported";
  //     }
  //   }
  // }, [uiData]);

  useEffect(() => {
    if (!isEmpty(role)) {
      setShowRoleError("");
      setIsError(true);
    }
  }, [role]);

  useEffect(() => {
    if (!isEmpty(props.entity)) {
      let contextData = {
        contextType: props.entity.context_type,
        context: props.entity.context,
      };

      let data = {
        group_id: filterByGroup,
      };
      if(filterByGroup){
        dispatch(EMDoGetFilterByGroupListByIdAction({ contextData, data }));
      } 
    }
  }, [filterByGroup]);

  const roleSelectionHandler = () => {
    if (isEmpty(role)) {
      setShowRoleError("Please Select Role Here");
      setIsError(false);
    } else {
      setShowRoleError("");
      setIsError(true);
    }
  };

  const handleChange = (event) => {
    setChecked(false);
  };
  const dispatch = useDispatch();

  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.closeHandler(false);
        setRole();
        setTabledata([]);
        setCheckedUsers([]);
        setFilterByGroup();
        setFilter("")
        dispatch({
          type: NETWORK_ADMIN_IMPORT_USERS_SELECT_ALL_FLAG,
          payload: {state: false },
        });

      }}
    >
      <Box className={classes.root}>
        <form>
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            style={{ padding: "0px 4px 0px 18px", backgroundColor: "#2F92D6" }}
          >
            <Typography className={classes.title}>
              {"Import Users To Partner Network"}
            </Typography>
            <IconButton
              onClick={() => {
                props.closeHandler(false);
                setRole();
                setFilterByGroup();
                setTabledata([]);
                setCheckedUsers([]);
                setFilter("")
                dispatch({
                  type: NETWORK_ADMIN_IMPORT_USERS_SELECT_ALL_FLAG,
                  payload: { state: false },
                });

              }}
            >
              <CloseRounded style={{ color: "white" }} />
            </IconButton>
          </Box>
          <Box className={classes.paper}>
            <Grid container justify="flex-start" style={{ marginLeft: 16 }}>
              <Grid xs={12} md={6}>
                <EMTextFieldSearch
                  hint={"Search for User Name, Email-Address"}
                  onChangeListener={(data) => {
                    setFilter(data);
                  }}
                />
              </Grid>
              {/* <Grid xs={3}>
              <EMDropDown title={"sort by"} options={options} />
            </Grid> */}
              <Grid xs={12} md={3}>
                <EMFilterByGroupDropdown
                  name="filter_by_group"
                  onSelect={(id) => {
                    setFilterByGroup(id);
                  }}
                />
              </Grid>
              <Grid
                xs={12}
                md={3}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {data["count"] > 0 ? (
                  <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    className="input-checkbox"
                    checked={selectAllFlag}
                    onChange={(event) => {
                      let state = event.target.checked;
                      dispatch({
                        type: NETWORK_ADMIN_IMPORT_USERS_SELECT_ALL_FLAG,
                        payload: { state: state },
                      });
                    }}
                  />
                  <svg
                    className={`checkbox ${
                      selectAllFlag ? "checkbox--active" : ""
                    }`}
                    aria-hidden="true"
                    viewBox="0 0 15 11"
                    fill="none"
                  >
                    <path
                      d="M1 4.5L5 9L14 1"
                      strokeWidth="2"
                      stroke={selectAllFlag ? "#fff" : "none"}
                    />
                  </svg>
                  <Typography>Select All</Typography>
                </label>
                  
                ) : (
                  <FormControlLabel
                    value="all"
                    control={<Checkbox />}
                    label="Select All"
                    labelPlacement="end"
                    disabled={true}
                  />
                )}
              </Grid>
            </Grid>
            <Grid>
              <EMImportUserTable
                data={tabledata}
                isChecked={checked}
                filter={filter}
                entity={props.entity}
                open={handleOpen}
                close={handleClose}
                onChangePermission={(permissionData, event) => {
                  if (tabledata.results) {
                    dispatch({
                      type: NETWORK_ADMIN_SELECT_UNSELECT_USER_IN_IMPORT_USERS,
                      payload: {
                        userId: permissionData.id,
                        state: event.target.checked,
                      },
                    });
                  }
                }}
              />
              <Box style={{ paddingLeft: 16, paddingRight: 16 }}>
                <EMEntityRoleDropdown
                  title={"Choose Role In Partner Network"}
                  error={showRoleError}
                  style={{ width: "30%" }}
                  onSelect={(id) => {
                    setRole(id);
                  }}
                />
              </Box>
            </Grid>
            <Grid container justify={"center"}>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  roleSelectionHandler();
                  if (!isEmpty(role)) {
                    
                    let emails = [];
                    let requiredUsers = tabledata.results.filter(
                      (d) => d.isChecked == true
                    );
                    emails = requiredUsers.map((x) => x.email);
                    let data = {
                      email: emails,
                      role: role.id,
                      select_all: false,
                    };
                    let contextData = {
                      contextType: props.entity.context_type,
                      context: props.entity.context,
                    };
                    dispatch(
                      EMDoImportAllSelectedUsersList({
                        data,
                        contextData,
                        key: "user_import",
                      })
                    );
                  }
                  setFilter("");
                }}
              >
                <Typography variant="h7">Invite</Typography>
              </Button>

              <Button
                className={classes.button}
                variant="outlined"
                color="secondary"
                onClick={() => {
                  props.closeHandler(false);
                  setRole();
                  setTabledata([]);
                  setCheckedUsers([]);
                  setFilterByGroup();
                  setFilter("")
                  dispatch({
                    type: NETWORK_ADMIN_IMPORT_USERS_SELECT_ALL_FLAG,
                    payload: { state: false },
                  });

                }}
              >
                <Typography variant="h7">Discard</Typography>
              </Button>
            </Grid>
          </Box>
        </form>
      </Box>
    </Modal>
  );
}
