import { Paper, Box, Button, Modal, CircularProgress, Typography, Grid, Backdrop } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import EMPage from "../../../components/commans/EMPage";
import EMDatatableSearchView from "../../../components/datatables/EMDatatableSearchView";
import EMChannelTable from "./EMChannelTable.js";
import { useSelector, useDispatch } from "react-redux";
import { find } from "lodash";
import EMDropDown from "../../../components/commans/EMDropdown"
import EMChannelSettings from "./channelSettings/index"
import { 
    EMDoGetChannelAdminGroupListDropDownAction,
    EMDoGetChannelAdminChannelDetailsByIdAction
} from "../../../redux/actions/EMChannelAdminActions";
import EMAddEditChannel from "./EMAddEditChannel"
import { findLast } from "lodash";
import {EMDoGetLoggedInUserRolesAction} from "../../../redux/actions/EMAuthActions"
export default function EMChannelManagement(props) {
    const [filter, setFilter] = useState("");
    const [editData, setEditData] = React.useState({});
    const [updateChannelData, setUpdateChannelData] = React.useState({});
    const [showSetting, setShowSetting] = React.useState(false);
    const [edit, setEdit] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [saveClicked , setDisabled] = React.useState(false)
    const dispatch = useDispatch();
    const uiData = useSelector((state) => state.ui);
    
    /* Close popUp once details are updated */
    useEffect(() => {
        if (uiData["messages"]) {
          let refObj = findLast(uiData["messages"], { key: "update_channel_settings" });
          if (refObj && refObj.type === "success") {
            setShowSetting(false)
            refObj.type = "failed"
          }
        }
      }, [uiData]);
    /* open and close modal functionality */
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setEditData({});
    };
    // useEffect(()=>{ dispatch(EMDoGetLoggedInUserRolesAction())},[])
    useEffect(() => {
        if (uiData["messages"] && uiData.loading !== true) {
            let refObj = findLast(uiData["messages"], { key: "edited_channel_details" });
            if (refObj && refObj.type === "success") {
                setEdit(false);
              setDisabled(false)   
              refObj.type = "failed" 
            }else  if (refObj && refObj.type === "error") {
              setDisabled(false)
              refObj.type = "failed"
            }
          }
    }, [uiData]);

    useEffect(() => {
        dispatch(EMDoGetChannelAdminGroupListDropDownAction());
    }, []);

    useEffect(() => {
        if (uiData["messages"]) {
            
          let refObj = findLast(uiData["messages"], { key: "fetch_channel_admin_details" });
          if (refObj && refObj.type === "success") {
            setShowSetting(true)
            refObj.type = "failed"
          }
        }
      }, [uiData]);
    return (
        <EMPage title="Channel Admin">
            <Box p={2}>
            <Modal style={{display:"flex", alignItems:"center", justifyContent:"center"}} open={uiData.loading.state}>
          <Box style={{display:"flex"}} alignItems="center" justifyContent="center">
            <Box textAlign="center">
          <CircularProgress />
          <Typography style={{fontWeight:"medium", fontSize:"large"}}>{uiData.loading.message}</Typography>
          </Box>
          </Box>
          </Modal>
                <Paper elevation={2}>
                    <Box height="100%" display="flex" flexDirection="column">
                        <Box
                            width="100%"
                            display="flex"
                            flexDirection="row"
                            p={3}
                            justifyItems="center"
                            alignItems="center"
                        >
                            <Grid container style={{ maxWidth: "100%" }} justify="flex-start" item xs={12} sm={12} md={12} lg={12}>
                            <Box width="100%" justify="flex-start" xs={12} sm={8} md={10} lg={12}>
                                <EMDatatableSearchView
                                    hint={"Search by Channel Name, Email Address"}
                                    onChangeListener={(data) => {
                                        setFilter(data);
                                    }}
                                />
                            </Box>
                            </Grid>

                            <Box>
                                <EMChannelSettings
                                    open={showSetting}
                                    close={() =>setShowSetting(false)}
                                />
                            </Box>
                            <Box>
                                <EMAddEditChannel
                                    open={edit}
                                    close={()=>setEdit(false)}
                                    closeState = {setEdit}
                                    channelData={editData}
                                    saveClicked = {saveClicked}
                                    setDisabled = {setDisabled}
                                />
                            </Box>
                        </Box>
                        <EMChannelTable
                            filter={filter}
                            onSettings={(channelData) => {
                                dispatch(
                                    EMDoGetChannelAdminChannelDetailsByIdAction({
                                        id:channelData && channelData.id,
                                        key : "fetch_channel_admin_details",
                                        usedIn: "Channel Management",
                                    })
                                )
                                setUpdateChannelData(channelData);
                            }}
                            onEdit={(channelData) => {
                                setEdit(true)
                                setEditData(channelData)
                                setDisabled(false)
                            }
                            }
                        />
                    </Box>
                </Paper>
            </Box>
        </EMPage>
    );
}
