import axios from "axios";

//Get Login User Details for Profile
const EMDoGetProfileDetails = (action)=>{
    let url = `user/${action}/`
    return axios.get(url);
}
//Get Login User Details for Groups
const EMDoGetGroupsOfUserForProfile = (action)=>{
    let url = `user/${action}/groups/`
    return axios.get(url);
}
//Get Login User Details for Channels
const EMDoGetChannelsOfUserForProfile = (action)=>{
    let url = `user/${action}/channels/`
    return axios.get(url);
}
//Get list of members in organisation
const EMDoGetListOfMembersOfOrganisation = (limit)=>{
    let url = `user/organization-members/?limit=20&offset=${limit}`
    return axios.get(url);
}

// search all members 
const EMDoGetListOfMembersOfOrganisationBySearch = (searchText, limit)=>{
    let url = `user/organization-members/?search=${searchText}&limit=20&offset=${limit}`
    return axios.get(url);
}
//Get list of members in other organisation
const EMDoGetListOfMembersOfOtherOrganization = (limit)=>{

    let url = `user/other-organizations-members/?limit=20&offset=${limit}`
    return axios.get(url);
}

//Get list of members in other organisation  for search
const EMDoGetListOfMembersOfOtherOrganizationBySearch = (searchText, limit)=>{
    let url = `user/other-organizations-members/?search=${searchText}&limit=20&offset=${limit}`
    return axios.get(url);
}

const EMDoGetFilteredMembersWithPartenershipsAndOrganizations = (action, limit) => {
    let url;
        url=`user/other-organizations-members/?${action}&limit=20&offset=${limit}`
    return axios.get(url);
  };

  //Get user level details and badges

  const EMDoGetUserLevelsAndBadges = (userId) => {
      let url;
      url = `gamification/user-profile/${userId}/`
      return axios.get(url)
  }

export default{
    EMDoGetProfileDetails,
    EMDoGetGroupsOfUserForProfile,
    EMDoGetChannelsOfUserForProfile,
    EMDoGetListOfMembersOfOrganisation,
    EMDoGetListOfMembersOfOtherOrganization,
    EMDoGetFilteredMembersWithPartenershipsAndOrganizations,
    EMDoGetListOfMembersOfOrganisationBySearch,
    EMDoGetListOfMembersOfOtherOrganizationBySearch,
    EMDoGetUserLevelsAndBadges
}