import React, { useEffect, useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css"
import * as dates from "../../utils/EMDates"
import { Card, CardContent, Paper, Typography, SvgIcon, CardActions, Button, DialogContent } from '@material-ui/core';
import EMCustomToolbar from "./EMCustomToolbar"
import { useSelector, useDispatch } from "react-redux";
import IconButton from '@material-ui/core/IconButton';
import CardHeader from '@material-ui/core/CardHeader';
import Dialog from '@material-ui/core/Dialog';
import { GET_SELECTED_DATE, SHOW_EVENT_DETAILS, GET_SELECTED_DAY_OPTION } from '../../redux/constants/UserPlatform/EMEventsConstant';
import { useNavigate } from 'react-router';
import { EMDoGetEventsByGroupFilterAction, EMDoGetMonthEventsAction } from '../../redux/actions/UserPlatform/Events';
import Parse from "html-react-parser";

const ColoredDateCellWrapper = ({ children }) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: 'lightblue',
     
    },
  })

function eventStyleGetter(event) {
  var backgroundColor = '#' + event.hexColor;
  var style = {
    //backgroundColor: backgroundColor,
    borderRadius: '12px',
    color: 'white',
    fontWeight:"bolder",
    border: '0px',
    display: 'block',
    zIndex:1,
    opacity:0.7,
  };
  style.backgroundColor = event.color
  return {
    style: style
  };
}

const localizer = momentLocalizer(moment);

function EMCalendar({ data, ...props }) {
  const [open, setOpen] = useState(false);
  const [selectedevent, setSelectedEvent] = useState({})
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [currentView, setCurrentView] = useState("month")
  const userData = useSelector((state) => state.auth || {})
  const eventsData = useSelector(state => state.events || {})
  const [displayedDateRange, setdisplayedDateRange] = React.useState({})
  const [groups, setGroups] = useState([])


  function tConvert(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      time = time.slice(1);
      time = time.slice(0, 3)
      time[4] = +time[0] < 12 ? ' AM' : ' PM';
      time[0] = +time[0] % 12 || 12;
    }
    return time.join('');
  }

  useEffect(() => {
    setGroups(eventsData.groups)
  },[eventsData.groups])

  let setselectedDate1 = async (date) => {
    await computeDisplayedDateRange();
    setSelectedDate(date);
    dispatch({
      type: GET_SELECTED_DAY_OPTION,
      payload: 3
    })
    dispatch({
      type: GET_SELECTED_DATE,
      payload: date
    })
  }
  let setCurrentView1 = async (view) => {
    await computeDisplayedDateRange();
    setCurrentView(view)
  }

  let computeDisplayedDateRange = () => {
    let start = moment(selectedDate).startOf(currentView);
    let end = moment(selectedDate).endOf(currentView);
    if (currentView == 'month') {
      start = start.startOf('week').add(7, "days");
      end = end.endOf('week');
    }
    setdisplayedDateRange({ start: start.toString(), end: end.toString() })
  }

  useEffect(() => {
    computeDisplayedDateRange()
    
    let groupsSelected = groups.filter(event => event.value == true)
    if(groupsSelected && groupsSelected.length > 0){
      if (groupsSelected.length == 1) {
        let selectedNetwork = groupsSelected[0];
        let id = selectedNetwork.id;
        let calendarView = selectedDate;
        setTimeout(() => {
          dispatch(
            EMDoGetEventsByGroupFilterAction({
              userData,
              calendarView: calendarView,
              id: id,
            })
          );
        },1000)

      } else if (groupsSelected.length > 1) {
        // let action
        let groupAction;
        // let organizationAction
        let groupIds = [];
        let calendarView = selectedDate;
        groupsSelected.map((selectedGroup, key) => {
          groupIds.push(selectedGroup.id);
          groupAction = `${groupIds.toString()}`;
        });
        setTimeout(() => {
          dispatch(
            EMDoGetEventsByGroupFilterAction({
              userData,
              calendarView: calendarView,
              id: groupAction,
            })
          );
        },1000)

      }
    }else{
      setTimeout(() => {
        dispatch(EMDoGetMonthEventsAction({ userData, calendarView: selectedDate }))
      }, 100)
    }

  }, [selectedDate, currentView])

  const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN",
    "JUL", "AUG", "SEPT", "OCT", "NOV", "DEC"
  ];


  return (
    <div>
      {/* <div>Displayed Date Rage: {displayedDateRange.start} - {displayedDateRange.end}, currentDate: {selectedDate.toString()} </div> */}
      <Paper elevation={2}>

        <Calendar
          onNavigate={setselectedDate1}
          onView={setCurrentView1}
          localizer={localizer}
          events={data}
          // views={views}
          style={{ height: window.screen.height/1.4, fontSize:"13px" }}
          showMultiDayTimes
          max={dates.add(dates.endOf(new Date(), 'day'), -1, 'hours')}
          // defaultDate={new Date()}
          date={selectedDate}
          defaultDate={new Date()}
          view={currentView}
          views={["month", "week", "day"]}
          startAccessor="start"
          endAccessor="end"
          components={{
            timeSlotWrapper: ColoredDateCellWrapper,
            toolbar: EMCustomToolbar
          }}
          eventPropGetter={eventStyleGetter}
          onSelectEvent={(event) => {
            setOpen(true);
            setSelectedEvent(event)
          }}
        />
      </Paper>
      <Dialog
        open={open}
        onClose={() => { setOpen(false) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
      >
        <DialogContent style={{ maxWidth:"50vh" }}>
          <div style={{ position: "absolute", top: 0, right: 0 }}>
            <IconButton onClick={() => { setOpen(false) }} color="secondary">
              <DeleteIcon />
            </IconButton>
          </div>
          <Card>
            <div style={{ display: "flex", textAlign: "initial" }}>
              <CardHeader
                style={{ maxWidth: "70%" }}
                title={selectedevent && selectedevent.title}
                subheader={<>
                   <Typography >
                  {selectedevent.groupName && selectedevent.groupName}
                </Typography>
                  <Typography>{selectedevent.event_start_time && tConvert(selectedevent.event_start_time)} {" "}-{" "}
                    {selectedevent.event_end_time && tConvert(selectedevent.event_end_time)}
                  </Typography>
                  <Typography color="textPrimary" style={{ fontWeight: "bolder", }}>{selectedevent && selectedevent.event_location}</Typography>

                </>}
              />
              <div style={{ margin: "auto", textAlign: "center" }}>
                <Typography component="h6" variant="h6" style={{ fontWeight: "bolder" }}>
                  {selectedevent.start && selectedevent.start.getDate()}
                </Typography>
                <Typography component="h6" variant="h6" style={{ fontWeight: "bolder" }}>{selectedevent.start && monthNames[selectedevent.start.getMonth()]}</Typography>
              </div>
            </div>
            <CardContent>
              {selectedevent.postText !== undefined ? 
                <Typography>
                {Parse(selectedevent.postText)}
              </Typography> : null
              }
             
            </CardContent>
          </Card>
          <CardActions style={{ justifyContent: 'center', marginBottom: '2%' }}>
            <Button variant="contained" style={{ backgroundColor: "#3399ff" }}
              onClick={() => {
                dispatch({
                  type: SHOW_EVENT_DETAILS,
                  payload: {
                    route: selectedevent.route,
                    eventId: selectedevent.id,
                    value: true,
                    selectedEvent:selectedevent
                  },
                })
                if (selectedevent.route === "eventsByMe" || "allMyEvents") {
                  navigate("/users/events/yourevents")
                } else {
                  navigate("/users/events/searchevents")
                }
              }}>
              View Details
            </Button>
          </CardActions>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default EMCalendar;

function DeleteIcon(props) {
  return (
    <SvgIcon  {...props}>
      <path d="M11.436,3.375A8.061,8.061,0,1,0,19.5,11.436,8.06,8.06,0,0,0,11.436,3.375Zm2.042,10.979-2.042-2.042L9.394,14.354a.619.619,0,1,1-.876-.876l2.042-2.042L8.518,9.394a.619.619,0,0,1,.876-.876l2.042,2.042,2.042-2.042a.619.619,0,1,1,.876.876l-2.042,2.042,2.042,2.042a.622.622,0,0,1,0,.876A.615.615,0,0,1,13.478,14.354Z" />
    </SvgIcon>
  );
}




