import { AUTH_LOGOUT, RESET } from "../../../constants";
import {
  LIST_USER_EVENTS,
  USER_EVENTS_POPUP,
  SHOW_EVENT_DETAILS,
  LIST_EVENTS_BY_ME,
  LIST_ALL_MY_EVENTS,
  LIST_UPCOMING_EVENTS,
  LIST_PAST_EVENTS,
  USER_EXPAND_TEXT,
  USER_EXPAND_COMMENT,
  USER_SHOW_COMMENT,
  SHOW_POST_MEDIA_PREVIEW,
  LIST_MONTH_EVENTS,
  LIST_GROUPS_FOR_FILTER,
  GET_SELECTED_DATE,
  EVENT_APPLY_GROUP_FILTER,
  GET_SELECTED_DAY_OPTION,
  TO_GET_THE_POLL_FORM,
  PLAY_VIDEO_BY_ID,
  DISABLE_ENABLE_FILTER_FOR_GROUPS,
  DISABLE_ENABLE_FILTER_FOR_GROUPS_FUTURE_EVENTS,
  DISABLE_ENABLE_FILTER_FOR_GROUPS_PAST_EVENTS,
} from "../../../constants/UserPlatform/EMEventsConstant";
import {
  LIKE_DISLIKE_POSTS,
  SUBMIT_NEW_COMMENT,
  POST_SHOW_EMOJI,
  POST_SHOW_HASHTAG,
  POST_EMOJI_SELECTED,
  POST_HASHTAG_SELECTED,
  POST_SHOW_TAGPEOPLE,
  POST_TAGPEOPLE_SELECTED,
  POST_NEW_COMMENT,
  LIST_HASHTAG,
  LIST_MENTIONS,
  DELETE_POST_COMMENT,
  SHOW_CREATE_POST,
  POST_IMAGES,
  POST_VIDEOS,
  POST_DOCUMENT,
  POST_IMAGES_DELETE,
  POST_VIDEOS_DELETE,
  POST_DOCUMENT_DELETE,
  USER_IMAGES_UPLOAD,
  USER_IMAGES_DELETE,
  USER_VIDEOS_UPLOAD,
  USER_VIDEOS_DELETE,
  USER_DOCUMENT_UPLOAD,
  USER_DOCUMENT_DELETE,
  SHOW_POST_EDIT,
  POST_SHOW_MORE_SELECTED,
  USER_EXTERNAL_VIDEO_UPLOAD,
  USER_EXTERNAL_VIDEO_DELETE,
  POST_EXTERNAL_VIDEO_UPLOAD,
  POST_EXTERNAL_VIDEO_DELETE,
  CHANGE_LIKE_STATUS_OF_POST,
  CHANGE_LIKE_BUTTON_STATE,

} from "../../../constants/UserPlatform/index";
import { cloneDeep, findIndex, groupBy } from "lodash";

const initialState = {
  selectedDate: new Date(),
  groups: [],
  singleEventDetails: {},
  showCreatePost: {},
  images: [],
  documents: [],
  selctedDayOption: 1,
  videos: [],
  externalVideos: [],
  postHashTags: [
    { id: "reg", display: "region" },
    { id: "endreg", display: "endregion" },
  ],
  postAtMentions: [],
  events: [],
  eventsToRenderInCalender: [],
  popup: false,
  selectedEvent: {},
  showEventDetails: false,
  eventsByMe: [],
  allMyEvents: [],
  upcomingEvents: [],
  pastEvents: [],
  combinedEvents: [],
  isFilterDisabled : true,
  isFilterDisabledForPastEvents : true,
  isFilterDisabledForFutureEvents : true
};

export default function EMEventsReducer(state = initialState, action) {
  let newState = cloneDeep(state);
  switch (action.type) {
    /* Latest Post -index page reducers */
    // List Posts - from server
    case LIST_USER_EVENTS: {
      let currentEvent = action.payload;
      newState["events"].map((event) => {
        if (event.id !== currentEvent.id) {
          event.push(currentEvent);
        }
      });
      return newState;
    }

    case DISABLE_ENABLE_FILTER_FOR_GROUPS:{
      newState["isFilterDisabled"] = action.payload
      return newState
    }

    case DISABLE_ENABLE_FILTER_FOR_GROUPS_PAST_EVENTS:{
      newState["isFilterDisabledForPastEvents"] = action.payload
      return newState
    }

    case DISABLE_ENABLE_FILTER_FOR_GROUPS_FUTURE_EVENTS:{
      newState["isFilterDisabledForFutureEvents"] = action.payload
      return newState
    }

    //applying network filter
    case EVENT_APPLY_GROUP_FILTER: {
      let postIndex = action.payload.postIndex;
      if (postIndex >= 0) {
        newState["groups"][postIndex].value = action.payload.checked_value;
      }
      return newState;
    }

    case GET_SELECTED_DATE: {
      newState["selectedDate"] = action.payload;
      return newState;
    }

    case GET_SELECTED_DAY_OPTION: {
      newState["selctedDayOption"] = action.payload;
      return newState;
    }

    case LIST_GROUPS_FOR_FILTER: {
      newState["groups"] = action.payload;
      newState["groups"].map((group) => {
        group["value"] = false;
      });
      return newState;
    }

    case LIST_MONTH_EVENTS: {
      newState["events"] = action.payload.response;
      newState["events"].map((event) => {
        event["userName"] = event["firstName"] + " " + event["lastName"];
        event["showEventDetails"] = false;
        event["expandText"] = false;
        event["expandPostComment"] = false;
        event["showComments"] = false;
        event["showMore"] = false;
        event["mediaView"] = false;
        event["likeButtonDisabled"] = false
        let start = new Date(event["event_start_date"]);
        let end = new Date(event["event_end_date"]);
        function ChangeDate(date) {
          var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

          if (month.length < 2) month = "0" + month;
          if (day.length < 2) day = "0" + day;

          function showTimeZone(date) {
            const dateAsString = date.toString();
            const timezone = dateAsString.match(/\(([^\)]+)\)$/)[1];
            return timezone;
          }
          event["timeZone"] = showTimeZone(start);

          return [year, month, day].join("-");
        }
        function ChangeTime(date) {
          return (
            ("00" + date.getHours()).slice(-2) +
            ":" +
            ("00" + date.getMinutes()).slice(-2) +
            ":" +
            ("00" + date.getSeconds()).slice(-2)
          );
        }
        event["event_start"] = ChangeDate(start);
        event["event_end"] = ChangeDate(end);
        event["event_start_time"] = ChangeTime(start);
        event["event_end_time"] = ChangeTime(end);
        event["owner"] =
          event.userName == action.payload.userData.name ? true : false;
          event["images"].map((img) => {
            img["type"] = "images";
            img["isPlaying"] = false
          });
          event["videos"].map((video) => {
            video["type"] = "videos";
            video["isPlaying"] = false
          });
          event["video_links"].map((video) => {
            video["type"] = "video_link";
            video["isPlaying"] = false
          });
        event["documents"].map((doc) => {
          doc["type"] = "documents";
        });
        event["totalMedia"] = event["images"].concat(event["videos"]);
        event["totalCombinedMedia"] = event["totalMedia"].concat(
          event["video_links"]
        );
        event["postAtMentions"] = [];
        event["showHashTag"] = false;
        event["showTagPeople"] = false;
        event["showEmoji"] = false;
        event["newComment"] = "";
      });
      newState["eventsToRenderInCalender"] = newState["events"];
      newState["eventsToRenderInCalender"].map((event) => {
        event["start"] = new Date(event["event_start_date"]);
        event["end"] = new Date(event["event_end_date"]);

        // var colors = [
        //   "Salmon",
        //   "PaleGreen ",
        //   "PapayaWhip ",
        //   "SandyBrown",
        //   "LightCoral",
        //   "YellowGreen",
        //   "plum",
        //   "Turquoise",
        // ];
        // event["color"] = colors[Math.floor(Math.random() * colors.length)];
        var q = new Date();
        var m = q.getMonth();
        var d = q.getDay();
        var y = q.getFullYear();
        let mydate = new Date(event.event_start_date);
        var date = new Date(y, m, d);
        if (event.owner) {
          event["route"] = "eventsByMe";
        } else if (event.owner == false) {
          event["route"] = "allMyEvents";
        }
      });

      function colorSpectrum(N) {
        var colorMap = [],
          inc = 50,
          start = 1000;
        for (var i = start; i < start + N * inc; i += inc) {
          var num = ((7509 * i) >>> 0).toString(16);
          while (num.length < 3) {
            num = "0" + num;
          }
          colorMap.push("#" + num);
        }
        return colorMap;
      }

      function process(data) {
        var map = {},
          colorMap = colorSpectrum(data.length);
        data.forEach(function (item, index) {
          if (!map.hasOwnProperty(item.groupName)) {
            map[item.groupName] = colorMap[index];
          }
          data[index].color = map[item.groupName];
        });

        return data;
      }

      newState["events"] = process(newState["events"]);

      return newState;
    }

    case SHOW_CREATE_POST: {
      newState["showCreatePost"]["value"] = action.payload.value;
      newState["showCreatePost"]["route"] = action.payload.route;
      return newState;
    }

    case USER_IMAGES_UPLOAD: {
      let data = action.payload;
      if (data != null) {
        let newImages = [];
        for (let i = 0; i < data.length; i++) {
          let file = data[i];
          let fileObj = file && file;

          let url = URL.createObjectURL(data[i]);
          fileObj["url"] = url;
          newImages.push(fileObj);
        }
        let imgArray;
        if (newState["images"]) {
          let arr = newState["images"].concat(newImages);
          newState["images"] = arr;
        } else {
          imgArray = newImages;
          newState["images"] = imgArray;
        }
      } else {
        newState["images"] = [];
      }
      return newState;
    }
    case USER_IMAGES_DELETE: {
      let imagesArray = newState["images"];
      let index = action.payload;
      imagesArray.splice(index, 1);
      newState["images"] = imagesArray;
      return newState;
    }
    //Images Already uploaded - post Specific
    case POST_IMAGES: {
      newState["post_images"] = action.payload;
      return newState;
    }
    case POST_IMAGES_DELETE: {
      let imagesArray = newState["post_images"];
      let index = action.payload;
      imagesArray.splice(index, 1);
      newState["post_images"] = imagesArray;
      return newState;
    }
    //videos
    case USER_VIDEOS_UPLOAD: {
      let data = action.payload;
      if (data != null) {
        let newVideos = [];
        for (let i = 0; i < data.length; i++) {
          let file = data[i];
          let fileObj = file && file;
          let url = URL.createObjectURL(data[i]);
          fileObj["url"] = url;
          newVideos.push(fileObj);
        }
        let videoArray;
        if (newState["videos"]) {
          let arr = newState["videos"].concat(newVideos);
          newState["videos"] = arr;
        } else {
          videoArray = newVideos;
          newState["videos"] = videoArray;
        }
      } else {
        newState["videos"] = [];
      }
      return newState;
    }
    case USER_VIDEOS_DELETE: {
      let imagesArray = newState["videos"];
      let index = action.payload;
      imagesArray.splice(index, 1);
      newState["videos"] = imagesArray;
      return newState;
    }

    //Videos Already uploaded - post Specific
    case POST_VIDEOS: {
      newState["post_videos"] = action.payload;
      return newState;
    }
    case POST_VIDEOS_DELETE: {
      let imagesArray = newState["post_videos"];
      let index = action.payload;
      imagesArray.splice(index, 1);
      newState["post_videos"] = imagesArray;
      return newState;
    }

    // link Videos
    case USER_EXTERNAL_VIDEO_UPLOAD: {
      let data = action.payload;
      if (data != null) {
        let newVideos = [];
        newVideos.push(action.payload);
        let videoArray;
        if (newState["externalVideos"]) {
          let arr = newState["externalVideos"].concat(newVideos);
          newState["externalVideos"] = arr;
        } else {
          videoArray = newVideos;
          newState["externalVideos"] = videoArray;
        }
      } else {
        newState["externalVideos"] = [];
      }
      return newState;
    }
    case USER_EXTERNAL_VIDEO_DELETE: {
      let imagesArray = newState["externalVideos"];
      let index = action.payload;
      imagesArray.splice(index, 1);
      newState["externalVideos"] = imagesArray;
      return newState;
    }

    // Link-Videos Already uploaded - post Specific
    case POST_EXTERNAL_VIDEO_UPLOAD: {
      newState["post_external_video_links"] = action.payload;
      return newState;
    }
    case POST_EXTERNAL_VIDEO_DELETE: {
      let imagesArray = newState["post_external_video_links"];
      let index = action.payload;
      imagesArray.splice(index, 1);
      newState["post_external_video_links"] = imagesArray;
      return newState;
    }

    //document
    case USER_DOCUMENT_UPLOAD: {
      let data = action.payload;
      if (data != null) {
        let newDocuments = [];
        for (let i = 0; i < data.length; i++) {
          let file = data[i];
          let fileObj = file && file;
          let url = URL.createObjectURL(data[i]);
          fileObj["url"] = url;
          newDocuments.push(fileObj);
        }
        let documentArray;
        if (newState["documents"]) {
          let arr = newState["documents"].concat(newDocuments);
          newState["documents"] = arr;
        } else {
          documentArray = newDocuments;
          newState["documents"] = documentArray;
        }
      } else {
        newState["documents"] = [];
      }
      return newState;
    }
    case USER_DOCUMENT_DELETE: {
      let imagesArray = newState["documents"];
      let index = action.payload;
      imagesArray.splice(index, 1);
      newState["documents"] = imagesArray;
      return newState;
    }
    //Videos Already uploaded - post Specific
    case POST_DOCUMENT: {
      newState["post_documents"] = action.payload;
      return newState;
    }

    case POST_DOCUMENT_DELETE: {
      let imagesArray = newState["post_documents"];
      let index = action.payload;
      imagesArray.splice(index, 1);
      newState["post_documents"] = imagesArray;
      return newState;
    }

    /* Show Edit Post */
    case SHOW_POST_EDIT: {
      let route = action.payload.route;
      let eventId = action.payload.id;
      let affectingEventList;
      if (route === "allMyEvents") {
        affectingEventList = newState["allMyEvents"];
      } else if (route === "eventsByMe") {
        affectingEventList = newState["eventsByMe"];
      } else if (route === "upcomingEvents") {
        affectingEventList = newState["upcomingEvents"];
      } else if (route === "pastEvents") {
        affectingEventList = newState["pastEvents"];
      }
      let postId = eventId;
      let postIndex = findIndex(affectingEventList, { id: postId });
      if (postIndex >= 0) {
        affectingEventList[postIndex].showEventDetails = action.payload.value;
        affectingEventList[postIndex].route = action.payload.route;
        newState["singleEventDetails"] = affectingEventList[postIndex];
        newState["singleEventDetails"].editPost = {
          showEditPost: action.payload.editPost,
          labels: {
            title: action.payload.title,
            button: action.payload.button,
          },
        };
      }

      return newState;
    }

    case LIST_EVENTS_BY_ME: {
      newState["eventsByMe"] = action.payload.response;
      newState["eventsByMe"].map((event) => {
        event["userName"] = event["firstName"] + " " + event["lastName"];
        event["showEventDetails"] = false;
        event["expandText"] = false;
        event["expandPostComment"] = false;
        event["showComments"] = false;
        event["showMore"] = false;
        event["mediaView"] = false;
        event["likeButtonDisabled"] = false
        let start = new Date(event["event_start_date"]);
        let end = new Date(event["event_end_date"]);
        function ChangeDate(date) {
          var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

          if (month.length < 2) month = "0" + month;
          if (day.length < 2) day = "0" + day;

          return [year, month, day].join("-");
        }

        function ChangeTime(date) {
          return (
            ("00" + date.getHours()).slice(-2) +
            ":" +
            ("00" + date.getMinutes()).slice(-2) +
            ":" +
            ("00" + date.getSeconds()).slice(-2)
          );
        }
        function showTimeZone(date) {
          const dateAsString = date.toString();
          const timezone = dateAsString.match(/\(([^\)]+)\)$/)[1];
          return timezone;
        }
        event["event_start"] = ChangeDate(start);
        event["event_end"] = ChangeDate(end);
        event["event_start_time"] = ChangeTime(start);
        event["event_end_time"] = ChangeTime(end);
        event["timeZone"] = showTimeZone(start);
        event["owner"] =
          event.userName == action.payload.userData.name ? true : false;
        event["images"].map((img) => {
          img["type"] = "images";
          img["isPlaying"] = false
        });
        event["videos"].map((video) => {
          video["type"] = "videos";
          video["isPlaying"] = false
        });
        event["video_links"].map((video) => {
          video["type"] = "video_link";
          video["isPlaying"] = false
        });
        event["documents"].map((doc) => {
          doc["type"] = "documents";
        });
        event["totalMedia"] = event["images"].concat(event["videos"]);
        event["totalCombinedMedia"] = event["totalMedia"].concat(
          event["video_links"]
        );
        // event["videos"].map((video) => {
        //   video["type"] = "videos";
        // });
        // event["documents"].map((doc) => {
        //   doc["type"] = "documents";
        // });
        // event["totalMedia"] = event["documents"].concat(
        //   event["images"],
        //   event["videos"]
        // );
        // if (event["totalMedia"].length > 10) {
        //   event["totalCombinedMedia"] = event["totalMedia"].slice(0, 10);
        // } else if (event["totalMedia"].length < 10) {
        //   event["totalCombinedMedia"] = event["totalMedia"];
        // }
        event["postAtMentions"] = [];
        event["showHashTag"] = false;
        event["showTagPeople"] = false;
        event["showEmoji"] = false;
        event["newComment"] = "";
      });
      return newState;
    }

    case LIST_ALL_MY_EVENTS: {
      newState["allMyEvents"] = action.payload.response;
      newState["allMyEvents"].map((event) => {
        event["userName"] = event["firstName"] + " " + event["lastName"];
        event["showEventDetails"] = false;
        event["expandText"] = false;
        event["expandPostComment"] = false;
        event["owner"] =
          event.userName == action.payload.userData.name ? true : false;
        event["showComments"] = false;
        event["mediaView"] = false;
        event["likeButtonDisabled"] = false
        let start = new Date(event["event_start_date"]);
        let end = new Date(event["event_end_date"]);
        function ChangeDate(date) {
          var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

          if (month.length < 2) month = "0" + month;
          if (day.length < 2) day = "0" + day;

          return [year, month, day].join("-");
        }

        function showTimeZone(date) {
          const dateAsString = date.toString();
          const timezone = dateAsString.match(/\(([^\)]+)\)$/)[1];
          return timezone;
        }
        event["timeZone"] = showTimeZone(start);
        function ChangeTime(date) {
          return (
            ("00" + date.getHours()).slice(-2) +
            ":" +
            ("00" + date.getMinutes()).slice(-2) +
            ":" +
            ("00" + date.getSeconds()).slice(-2)
          );
        }
        event["event_start"] = ChangeDate(start);
        event["event_end"] = ChangeDate(end);
        event["event_start_time"] = ChangeTime(start);
        event["event_end_time"] = ChangeTime(end);
        event["images"].map((img) => {
          img["type"] = "images";
          img["isPlaying"] = false
        });
        event["videos"].map((video) => {
          video["type"] = "videos";
          video["isPlaying"] = false
        });
        event["video_links"].map((video) => {
          video["type"] = "video_link";
          video["isPlaying"] = false
        });
        event["documents"].map((doc) => {
          doc["type"] = "documents";
        });
        event["totalMedia"] = event["images"].concat(event["videos"]);
        event["totalCombinedMedia"] = event["totalMedia"].concat(
          event["video_links"]
        );
        // event["videos"].map((video) => {
        //   video["type"] = "videos";
        // });
        // event["documents"].map((doc) => {
        //   doc["type"] = "documents";
        // });
        // event["totalMedia"] = event["documents"].concat(
        //   event["images"],
        //   event["videos"]
        // );
        // if (event["totalMedia"].length > 10) {
        //   event["totalCombinedMedia"] = event["totalMedia"].slice(0, 10);
        // } else if (event["totalMedia"].length < 10) {
        //   event["totalCombinedMedia"] = event["totalMedia"];
        // }
        event["postAtMentions"] = [];
        event["showHashTag"] = false;
        event["showTagPeople"] = false;
        event["showEmoji"] = false;
        event["newComment"] = "";
      });
      return newState;
    }

    case LIST_UPCOMING_EVENTS: {
      newState["upcomingEvents"] = action.payload.response;
      newState["upcomingEvents"].map((event) => {
        event["userName"] = event["firstName"] + " " + event["lastName"];
        event["showEventDetails"] = false;
        event["expandText"] = false;
        event["owner"] =
          event.userName == action.payload.userData.name ? true : false;
        event["expandPostComment"] = false;
        event["showComments"] = false;
        event["mediaView"] = false;
        event["likeButtonDisabled"] = false
        let start = new Date(event["event_start_date"]);
        let end = new Date(event["event_end_date"]);
        function ChangeDate(date) {
          var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

          if (month.length < 2) month = "0" + month;
          if (day.length < 2) day = "0" + day;

          return [year, month, day].join("-");
        }
        function showTimeZone(date) {
          const dateAsString = date.toString();
          const timezone = dateAsString.match(/\(([^\)]+)\)$/)[1];
          return timezone;
        }
        event["timeZone"] = showTimeZone(start);
        function ChangeTime(date) {
          return (
            ("00" + date.getHours()).slice(-2) +
            ":" +
            ("00" + date.getMinutes()).slice(-2) +
            ":" +
            ("00" + date.getSeconds()).slice(-2)
          );
        }
        event["event_start"] = ChangeDate(start);
        event["event_end"] = ChangeDate(end);
        event["event_start_time"] = ChangeTime(start);
        event["event_end_time"] = ChangeTime(end);
        event["images"].map((img) => {
          img["type"] = "images";
        });
        event["videos"].map((video) => {
          video["type"] = "videos";
        });
        event["video_links"].map((video) => {
          video["type"] = "video_link";
        });
        event["documents"].map((doc) => {
          doc["type"] = "documents";
        });
        event["totalMedia"] = event["images"].concat(event["videos"]);
        event["totalCombinedMedia"] = event["totalMedia"].concat(
          event["video_links"]
        );
        // event["videos"].map((video) => {
        //   video["type"] = "videos";
        // });
        // event["documents"].map((doc) => {
        //   doc["type"] = "documents";
        // });
        // event["totalMedia"] = event["documents"].concat(
        //   event["images"],
        //   event["videos"]
        // );
        // if (event["totalMedia"].length > 10) {
        //   event["totalCombinedMedia"] = event["totalMedia"].slice(0, 10);
        // } else if (event["totalMedia"].length < 10) {
        //   event["totalCombinedMedia"] = event["totalMedia"];
        // }
        event["postAtMentions"] = [];
        event["showHashTag"] = false;
        event["showTagPeople"] = false;
        event["showEmoji"] = false;
        event["newComment"] = "";
      });
      return newState;
    }

    case LIST_PAST_EVENTS: {
      newState["pastEvents"] = action.payload.response;
      newState["pastEvents"].map((event) => {
        event["userName"] = event["firstName"] + " " + event["lastName"];
        event["showEventDetails"] = false;
        event["expandText"] = false;
        event["expandPostComment"] = false;
        event["owner"] =
          event.userName == action.payload.userData.name ? true : false;
        event["mediaView"] = false;
        event["showComments"] = false;
        event["likeButtonDisabled"] = false
        let start = new Date(event["event_start_date"]);
        let end = new Date(event["event_end_date"]);
        function ChangeDate(date) {
          var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

          if (month.length < 2) month = "0" + month;
          if (day.length < 2) day = "0" + day;

          return [year, month, day].join("-");
        }
        function showTimeZone(date) {
          const dateAsString = date.toString();
          const timezone = dateAsString.match(/\(([^\)]+)\)$/)[1];
          return timezone;
        }
        event["timeZone"] = showTimeZone(start);
        function ChangeTime(date) {
          return (
            ("00" + date.getHours()).slice(-2) +
            ":" +
            ("00" + date.getMinutes()).slice(-2) +
            ":" +
            ("00" + date.getSeconds()).slice(-2)
          );
        }
        event["event_start"] = ChangeDate(start);
        event["event_end"] = ChangeDate(end);
        event["event_start_time"] = ChangeTime(start);
        event["event_end_time"] = ChangeTime(end);
        event["images"].map((img) => {
          img["type"] = "images";
          img["isPlaying"] = false
        });
        event["videos"].map((video) => {
          video["type"] = "videos";
          video["isPlaying"] = false
        });
        event["video_links"].map((video) => {
          video["type"] = "video_link";
          video["isPlaying"] = false
        });
        event["documents"].map((doc) => {
          doc["type"] = "documents";
        });
        event["totalMedia"] = event["images"].concat(event["videos"]);
        event["totalCombinedMedia"] = event["totalMedia"].concat(
          event["video_links"]
        );
        // event["totalMedia"] = event["documents"].concat(
        //   event["images"],
        //   event["videos"]
        // );
        // if (event["totalMedia"].length > 10) {
        //   event["totalCombinedMedia"] = event["totalMedia"].slice(0, 10);
        // } else if (event["totalMedia"].length < 10) {
        //   event["totalCombinedMedia"] = event["totalMedia"];
        // }
        event["postAtMentions"] = [];
        event["showHashTag"] = false;
        event["showTagPeople"] = false;
        event["showEmoji"] = false;
        event["newComment"] = "";
      });
      return newState;
    }

    case PLAY_VIDEO_BY_ID: {
      let videoId = action.payload.videoId;
      let media = action.payload.media;
      let previewOpen = action.payload.fromPreview;
      ;
      if (previewOpen == false) {
        let videoIndex = findIndex(
          newState["singleEventDetails"].totalCombinedMedia,
          { id: videoId }
        );
        if (videoIndex >= 0) {
          newState["singleEventDetails"].totalCombinedMedia = newState[
            "singleEventDetails"
          ].totalCombinedMedia.map((item) => ({ ...item, isPlaying: false }));
          let upcomingMedia = media;
          newState["singleEventDetails"].totalCombinedMedia[videoIndex] =
            upcomingMedia;
        }
      } else {
        if (media.type == "videos") {
          let videoIndex = findIndex(newState["singleEventDetails"].videos, {
            id: videoId,
          });
          if (videoIndex >= 0) {
            newState["singleEventDetails"].videos = newState[
              "singleEventDetails"
            ].videos.map((item) => ({ ...item, isPlaying: false }));
            let upcomingMedia = media;
            newState["singleEventDetails"].videos[videoIndex] = upcomingMedia;
            newState["singleEventDetails"].totalCombinedMedia = newState[
              "singleEventDetails"
            ].totalCombinedMedia.map((item) => ({ ...item, isPlaying: false }));
          }
        } else {
          let videoIndex = findIndex(
            newState["singleEventDetails"].video_links,
            { id: videoId }
          );
          if (videoIndex >= 0) {
            newState["singleEventDetails"].video_links = newState[
              "singleEventDetails"
            ].video_links.map((item) => ({ ...item, isPlaying: false }));
            let upcomingMedia = media;
            newState["singleEventDetails"].video_links[videoIndex] =
              upcomingMedia;
            newState["singleEventDetails"].totalCombinedMedia = newState[
              "singleEventDetails"
            ].totalCombinedMedia.map((item) => ({ ...item, isPlaying: false }));
          }
        }
      }

      return newState;
    }

    case SHOW_EVENT_DETAILS: {
      
      let route = action.payload.route;
      let eventId = action.payload.eventId;
      let affectingEventList;
      if (route === "pastEvents") {
        affectingEventList = newState["pastEvents"];
      } else if (route === "upcomingEvents") {
        affectingEventList = newState["upcomingEvents"];
      } else if (route === "allMyEvents") {
        affectingEventList = newState["allMyEvents"];
      } else if (route === "eventsByMe") {
        affectingEventList = newState["eventsByMe"];
      }
      let postId = eventId;
      let postIndex = findIndex(affectingEventList, { id: postId });
      if (postIndex >= 0) {
        affectingEventList[postIndex].showEventDetails = action.payload.value;
        affectingEventList[postIndex].route = action.payload.route;
        newState["singleEventDetails"] = affectingEventList[postIndex];
      }else{
        let selectedEvent = action.payload.selectedEvent
        if(selectedEvent){
          selectedEvent.showEventDetails = action.payload.value
          newState["singleEventDetails"] = selectedEvent
        }else{
          newState["singleEventDetails"] = {...newState["singleEventDetails"], showEventDetails:action.payload.value};
        }
      }
      return newState;
    }

    case USER_EXPAND_TEXT: {
      let id = action.payload.id;
      let post = newState["singleEventDetails"];
      if (newState["singleEventDetails"].id == id) {
        post.expandText = action.payload.content;
      }
      return newState;
    }
    case USER_EXPAND_COMMENT: {
      let id = action.payload.id;
      let post = newState["singleEventDetails"];
      if (newState["singleEventDetails"].id == id) {
        post.expandPostComment = action.payload.content;
      }
      return newState;
    }
    case USER_SHOW_COMMENT: {
      let id = action.payload.id;
      let post = newState["singleEventDetails"];
      if (newState["singleEventDetails"].id == id) {
        post.showComments = action.payload.content;
      }
      return newState;
    }

    case LIKE_DISLIKE_POSTS: {
      let postId = action.payload.id;
      if (postId === newState["singleEventDetails"].id) {
        newState["singleEventDetails"].liked = action.payload.is_liked;
        newState["singleEventDetails"].likes = action.payload.like_count
      }
      return newState;
    }

    case CHANGE_LIKE_STATUS_OF_POST:{
      let postId = action.payload.id
      if(postId === newState["singleEventDetails"].id){
        newState["singleEventDetails"].liked = action.payload.isLiked
      }
      return newState
    }

    case CHANGE_LIKE_BUTTON_STATE:{
      let postId = action.payload.id
      if(postId === newState["singleEventDetails"].id){
        newState["singleEventDetails"].likeButtonDisabled = action.payload.isDisabled
      }
      return newState
    }

    /* Hashtag added in comment */
    case POST_SHOW_HASHTAG: {
      let post = newState["singleEventDetails"];
      if (post.id == action.payload.id) {
        post["showHashTag"] = action.payload.showHashtag;
      }

      return newState;
    }
    case POST_EMOJI_SELECTED: {
      let post = newState["singleEventDetails"];
      if (post.id == action.payload.id) {
        post["newComment"] = post["newComment"].substr(
          0,
          post["newComment"].length
        );
        post["newComment"] = post["newComment"] + action.payload.selectedEmoji;
      }
      return newState;
    }
    case POST_HASHTAG_SELECTED: {
      let post = newState["singleEventDetails"];
      if (post.id == action.payload.id) {
        post["newComment"] = post["newComment"].substr(
          0,
          post["newComment"].length - 1
        );
        post["newComment"] =
          post["newComment"] + action.payload.selectedHashTag;
      }
      return newState;
    }
    /* comment update */
    case POST_NEW_COMMENT: {
      let post = newState["singleEventDetails"];
      if (post.id == action.payload.id) {
        post["newComment"] = action.payload.comment;
      }
      return newState;
    }
    case SUBMIT_NEW_COMMENT: {
      let post = newState["singleEventDetails"];
      //submit content from newComment for that Id
      let data = action.payload.response;
      let userData = action.payload.userData;
      if (post.id == data.post_id) {
        let content = data.content;
        let commentedOn = data.created_on;
        let newComment = {
          first_name: data.first_name,
          last_name: data.last_name,
          // userName: userData.name,
          organisationName: userData.organization_name,
          // designation: userData.designation == null ? "" : userData.designation,
          designation: data.user_designation,
          comment: content,
          // profileIcon: userData.user_profile,
          profileIcon: data.user_profile_pic,
          commentedOn: commentedOn,
          comment_id: data.id,
          user_id: userData.profile_id,
        };
        if (post.comments == null) {
          post.comments = [];
        }
        post.comments.push(newComment);
        post.newComment = "";
        // post.comments++
      }
      return newState;
    }
    /* TagPeople in Comment */
    case POST_SHOW_TAGPEOPLE: {
      let post = newState["singleEventDetails"];
      if (post.id == action.payload.id) {
        post["showTagPeople"] = action.payload.showTagPeople;
      }
      return newState;
    }
    case POST_SHOW_EMOJI: {
      let post = newState["singleEventDetails"];
      if (post.id == action.payload.id) {
        post["showEmoji"] = action.payload.showEmoji;
      }
      return newState;
    }
    case POST_TAGPEOPLE_SELECTED: {
      let post = newState["singleEventDetails"];
      if (post.id == action.payload.id) {
        post["newComment"] = post["newComment"].substr(
          0,
          post["newComment"].length
        );
        post["newComment"] =
          post["newComment"] + action.payload.selectedAtPeople.mentions.name;
      }
      return newState;
    }

    case LIST_HASHTAG: {
      newState["postHashTags"] = action.payload;
      return newState;
    }
    //mentions for post
    case LIST_MENTIONS: {
      let post = newState["singleEventDetails"];
      if (post.id == action.payload.id) {
        post["postAtMentions"] = action.payload.response;
      }
      return newState;
    }

    case SHOW_POST_MEDIA_PREVIEW: {
      let post = newState["singleEventDetails"];
      if (post.id == action.payload.id) {
        post["mediaView"] = action.payload.showPreview;
      }
      return newState;
    }

    case DELETE_POST_COMMENT: {
      let post = newState["singleEventDetails"];
      let data = action.payload;
      if (post.id == data.postId) {
        let commentIndex = findIndex(post.comments, {
          comment_id: data.commentId,
        });
        post.comments.splice(commentIndex, 1);
      }
      return newState;
    }

    case USER_EVENTS_POPUP: {
      newState["popup"] = action.payload.open;
      newState["selectedEvent"] = action.payload.targetedEvent;
      return newState;
    }

    case AUTH_LOGOUT: {
      return { ...initialState };
    }

    default: {
      // initialise state with additional keys
      return newState;
    }
  }
}
