import React, { useEffect } from "react";
import EMTabs from "../../../components/navigation/EMTabs";
import { useSelector, useDispatch } from "react-redux";
import EMGroupManageUsers from "./ManageUsers/index";
import EMGroupManageRolesAndPermissions from "./ManageRolesAndPermissions/index";
import { EMDoGetLoggedInUserRolesAction } from "../../../redux/actions/EMAuthActions";
import { RESET } from "../../../redux/constants";

export default function EMGroupUserManagement() {
  const userData = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(EMDoGetLoggedInUserRolesAction());
    dispatch({
        type:RESET
    })

  }, []);
  let tabs = [];
  let components = [];
  // let tabs = ["Manage Users","Manage Roles & Permissions"]
  // let components =[ <EMGroupManageUsers /> ,<EMGroupManageRolesAndPermissions />]
  if (userData.boolean_group_manage_users) {
    tabs.push("Manage Users");
    components.push(<EMGroupManageUsers />);
  }
  if (userData.boolean_group_manage_roles_and_permissions) {
    tabs.push("Manage Roles & Permissions");
    components.push(<EMGroupManageRolesAndPermissions />);
  }
  return (
    <EMTabs
      title={"Group - User Management"}
      tabs={tabs}
      components={components}
    />
  );
}
