import { cloneDeep, findIndex } from "lodash";
import { AUTH_LOGOUT } from "../../../constants";
import {
  ORGANISATION_MEMBERS,
  PROFILE_EDIT_TOGGLE,
  LINK_PROFILE,
  LOGIN_USER_DETAILS,
  LOGIN_USER_GROUPS,
  LOGIN_USER_CHANNELS,
  OTHER_NETWORKS_MEMBERS,
  SHOW_NEXT_MEMBERS_SEARCHOTHERS,
  GET_USER_LEVEL_AND_BADGES,
  DISABLE_ENABLE_SEARCH_MY_COMPANY,
  DISABLE_ENABLE_SEARCH_OTHERS,
} from "../../../constants/UserPlatform/EMPeopleConstant";
const initialState = {
  showProfileEdit: false,
  members: [],
  nextMembers: undefined,
  pageOfmembers: undefined,
  otherOrganizationMembers: [],
  levelAndBadges: {},
  isDisabledSearchMyCompany:true,
  isDisabledSearchOthers:true
};

export default function EMPeopleReducer(state = initialState, action) {
  let newState = cloneDeep(state);
  switch (action.type) {
    case PROFILE_EDIT_TOGGLE: {
      newState["showProfileEdit"] = action.payload;
      return newState;
    }
    case LOGIN_USER_DETAILS: {
      newState["profileDetails"] = action.payload;
      newState["profileDetails"]["location"] =
        newState.profileDetails.city +
        ", " +
        newState.profileDetails.state +
        ", " +
        newState.profileDetails.country;
      newState["profileDetails"]["name"] =
        newState.profileDetails.first_name +
        " " +
        newState.profileDetails.last_name;
      return newState;
    }
    case LOGIN_USER_GROUPS: {
      newState["profileGroups"] = action.payload;
      return newState;
    }
    case LOGIN_USER_CHANNELS: {
      newState["profileChannels"] = action.payload;
      return newState;
    }
    case LINK_PROFILE: {
      newState["showLinkProfile"] = action.payload;
      return newState;
    }

    case DISABLE_ENABLE_SEARCH_MY_COMPANY:{
      let state = action.payload
      newState["isDisabledSearchMyCompany"] = state
      return newState
    }

    case DISABLE_ENABLE_SEARCH_OTHERS:{
      let state = action.payload
      newState["isDisabledSearchOthers"] = state
      return newState
    }

    case SHOW_NEXT_MEMBERS_SEARCHOTHERS: {
      newState["pageOfmembers"] = action.payload.nextMembers;
      return newState;
    }

    case ORGANISATION_MEMBERS: {
      newState["nextMembers"] = action.payload.response.next;
      if (action.payload.limit == 0) {
        newState["members"] = action.payload.response.results;
      } else {
        newState["members"] = newState["members"].concat(
          action.payload.response.results
        );
      }

      return newState;
    }

    case OTHER_NETWORKS_MEMBERS: {
      if (action.payload.limit == 0) {
        newState["otherOrganizationMembers"] = action.payload.response.results;
      } else {
        newState["otherOrganizationMembers"] = newState[
          "otherOrganizationMembers"
        ].concat(action.payload.response.results);
      }
      return newState;
    }

    case GET_USER_LEVEL_AND_BADGES: {
      newState["levelAndBadges"] = action.payload;
      let result = Object.keys(action.payload.badges).map((key) => ({
        badgeName: key,
        value: action.payload.badges[key],
      }));
      newState["levelAndBadges"] = {
        ...newState["levelAndBadges"],
        newBadges: result,
      };
      return newState;
    }

    case AUTH_LOGOUT: {
      return { ...initialState };
    }

    default: {
      return newState;
    }
  }
}
