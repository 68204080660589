import {
  Paper,
  Box,
  Button,
  Modal,
  CircularProgress,
  Typography,
  Grid,
  Hidden,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import EMPage from "../../../components/commans/EMPage";
import EMDatatableSearchView from "../../../components/datatables/EMDatatableSearchView";
import EMChannelTable from "./EMChannelTable.js";
import { useSelector, useDispatch } from "react-redux";
import { find } from "lodash";
import EMDropDown from "../../../components/commans/EMDropdown";
import EMChannelSettings from "./channelSettings/index";
import {
  EMDoGetChannelDetailsByIdAction,
  EMDoGetAllChannelListDetailsAction,
  EMDoGetGroupAdminGroupListDropDownAction,
  EMDoGetGroupAdminGroupListDropDownForChannelAction,
} from "../../../redux/actions/EMGroupAdminActions";
import EMAddEditChannel from "./EMAddEditChannel";
import { findLast } from "lodash";
import { EMDoGetLoggedInUserRolesAction } from "../../../redux/actions/EMAuthActions";
import { GROUP_ADMIN_UPDATE_GROUPS_LIST } from "../../../redux/constants";
export default function EMChannelManagement(props) {
  const [filter, setFilter] = useState("");
  const [editData, setEditData] = React.useState({});
  const [updateChannelData, setUpdateChannelData] = React.useState({});
  const [showSetting, setShowSetting] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const uiData = useSelector((state) => state.ui);
  const [showLabels, setShowLabels] = React.useState({
    label: "",
    buttonLabel: "",
  });
  const [saveClicked, setDisabled] = React.useState(false);
  const userData = useSelector((state) => state.auth);
  /* Close popUp once details are updated */
  useEffect(() => {
    if (uiData["messages"]) {
      let refObj = findLast(uiData["messages"], {
        key: "update_channel_settings",
      });
      if (refObj && refObj.type === "success") {
        setShowSetting(false);
        refObj.type = "failed";
      }
    }
  }, [uiData]);
  /* open and close modal functionality */
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    dispatch(EMDoGetGroupAdminGroupListDropDownAction());
    dispatch(EMDoGetGroupAdminGroupListDropDownForChannelAction());
    // dispatch(EMDoGetLoggedInUserRolesAction())
  }, []);

  const handleClose = () => {
    setOpen(false);
    setEditData({});
  };

  useEffect(() => {
    if (uiData["messages"] && uiData.loading !== true) {
      let refObj = findLast(uiData["messages"], {
        key: "channel_add_edit_details",
      });
      if (refObj && refObj.type === "success") {
        setEdit(false);
        setDisabled(false);
        refObj.type = "failed";
        dispatch({ type: GROUP_ADMIN_UPDATE_GROUPS_LIST, payload: true });
      } else if (refObj && refObj.type === "error") {
        setDisabled(false);
        refObj.type = "failed";
      }
    }
  }, [uiData]);

  useEffect(() => {
    if (uiData["messages"]) {
      let refObj = findLast(uiData["messages"], {
        key: "fetch_channel_details",
      });
      if (refObj && refObj.type === "success") {
        setShowSetting(true);
        refObj.type = "failed";
      }
    }
  }, [uiData]);
  return (
    <EMPage title="Channel Admin">
      <Box p={2}>
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={uiData.loading.state}
        >
          <Box
            style={{ display: "flex" }}
            alignItems="center"
            justifyContent="center"
          >
            <Box textAlign="center">
              <CircularProgress />
              <Typography style={{ fontWeight: "medium", fontSize: "large" }}>
                {uiData.loading.message}
              </Typography>
            </Box>
          </Box>
        </Modal>
        <Paper elevation={2}>
          <Box height="100%" display="flex" flexDirection="column">
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              p={3}
              justifyItems="center"
              alignItems="center"
            >
              <Grid container style={{ maxWidth: "100%" }} justify="flex-start">
                <Grid
                  width="100%"
                  justify="flex-start"
                  item
                  xs={12}
                  sm={12}
                  md={10}
                  lg={10}
                >
                  <EMDatatableSearchView
                    hint={"Search by Channel Name, Group Name, Email Address"}
                    onChangeListener={(data) => {
                      setFilter(data);
                    }}
                  />
                </Grid>
                <Hidden smDown>
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <Button
                      disabled={!userData.boolean_group_manage_channels}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setEdit(true);
                        setShowLabels({
                          label: "Add A New Channel",
                          buttonLabel: "Save",
                        });
                        setDisabled(false);
                        dispatch({
                          type: GROUP_ADMIN_UPDATE_GROUPS_LIST,
                          payload: false,
                        });
                        setEditData({});
                      }}
                    >
                      {"Add New Channel"}
                    </Button>
                  </Grid>
                </Hidden>
                <Hidden lgUp mdUp>
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <Button
                      style={{ marginTop: "2rem" }}
                      disabled={!userData.boolean_group_manage_channels}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setEdit(true);
                        setShowLabels({
                          label: "Add A New Channel",
                          buttonLabel: "Save",
                        });
                        dispatch({
                          type: GROUP_ADMIN_UPDATE_GROUPS_LIST,
                          payload: false,
                        });
                        setDisabled(false);
                        setEditData({});
                      }}
                    >
                      {"Add New Channel"}
                    </Button>
                  </Grid>
                </Hidden>
              </Grid>
              <Box>
                <EMChannelSettings
                  open={showSetting}
                  close={() => setShowSetting(false)}
                />
              </Box>
              <Box>
                <EMAddEditChannel
                  open={edit}
                  close={handleClose}
                  closeState={setEdit}
                  pagelabel={showLabels.label}
                  buttonLabel={showLabels.buttonLabel}
                  channelData={editData}
                  saveClicked={saveClicked}
                  setDisabled={setDisabled}
                />
              </Box>
            </Box>
            <EMChannelTable
              filter={filter}
              onSettings={(channelData) => {
                //setShowSetting(true)
                dispatch(
                  EMDoGetChannelDetailsByIdAction({
                    id: channelData && channelData.id,
                    key: "fetch_channel_details",
                  })
                );
                setUpdateChannelData(channelData);
              }}
              onEdit={(channelData) => {
                setEdit(true);
                setEditData(channelData);
                setShowLabels({
                  label: "Edit Channel Details",
                  buttonLabel: "Save",
                });
              }}
            />
          </Box>
        </Paper>
      </Box>
    </EMPage>
  );
}
