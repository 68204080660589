import React, { useState, useEffect, useRef } from "react";
import EMPage from "../../../components/commans/EMPage";
import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Box,
  Button,
  makeStyles,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  InputLabel,
  Hidden,
} from "@material-ui/core";

import EMDatePicker from "../../../components/commans/EMDatePicker";
import EMOnBoardingReport from "./EMOnBoardingReport";
import EMNetworksStatsReport from "./EMNetworksStatsReport";
import EMUsageReport from "./EMUsageReport";
import EMUserEngagementReport from "./EMUserEngagementReport";
import { useFormik, Field, Formik } from "formik";
import * as yup from "yup";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  EMDoExportNetworkStatsReportAction,
  EMDoExportOnboardingReportAction,
  EMDoExportUserEngagementReportAction,
  EMDoGetNetworkListForDashboardAndReportsAction,
  EMDoGetNetworkStatsReportAction,
  EMDoGetOnboardingReportAction,
  EMDoGetUsageReportAction,
  EMDoGetUserEngagementReportAction,
  EMExportUsageReportAction,
} from "../../../redux/actions/EMAdminActions";

const dataForReportType = [
  {
    id: 4,
    name: "Network Stats Report",
  },
  {
    id: 1,
    name: "Onboarding Report",
  },
  {
    id: 2,
    name: "Usage Report",
  },
  {
    id: 3,
    name: "User Engagement Report",
  },
];

const browseReportType = [
  { id: 2, type: "Channel-Wise" },
  { id: 1, type: "Group-Wise" },
];

const useStyles = makeStyles((theme) => ({
  Title: {
    textAlign: "center",
    fontWeight: "bolder",
    fontSize: "large",
  },
  circle: {
    width: "100px",
    height: "100px",
    lineHeight: "100px",
    borderRadius: "50%",
    fontSize: "20px",
    color: "#fff",
    textAlign: "center",
    background: "#000080",
  },
}));

export default function EMPlatformReportsManagement(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [networkList, setNetworkList] = useState([]);
  const [listOfOrganizations, setListOfOrganizations] = useState([]);
  const adminData = useSelector((state) => state.admin || {}); 
  const [reportType, setReportType] = useState(0);
  const [isGroupWise, setIsGroupWise] = useState();
  const [widthDropdown, setWidth] = useState("80%");
  const [dataForTableData, setDataForTableData] = useState([])


  useEffect(() => {
    dispatch(EMDoGetNetworkListForDashboardAndReportsAction());
  }, []);

  useEffect(() => {
    let organizationsArray = [];
    let partnershipsArray = [];
    if (adminData && adminData.networkList) {
      if (
        adminData.networkList &&
        adminData.networkList[0] &&
        adminData.networkList[0].partnerships.length > 0
      ) {
        partnershipsArray = adminData.networkList[0].partnerships.map(
          (part, index) => ({
            ...part,
            isPartnership: true,
            newId: index + 1,
            networkName: part.partnership_name,
          })
        );
      }
      let newIndex = partnershipsArray.length;
      if (adminData && adminData.networkList) {
        if (
          adminData.networkList &&
          adminData.networkList[0] &&
          adminData.networkList[0].organizations.length > 0
        )
          organizationsArray = adminData.networkList[0].organizations.map(
            (org, index) => ({
              ...org,
              isPartnership: false,
              newId: newIndex + index + 1,
              networkName: org.organization_name,
            })
          );
      }

      function SortArray(x, y) {
        if (x.networkName < y.networkName) {
          return -1;
        }
        if (x.networkName > y.networkName) {
          return 1;
        }
        return 0;
      }

      var sortedOrgArray = organizationsArray.sort(SortArray)

      setListOfOrganizations([...sortedOrgArray]);

      var combinedArray = [...partnershipsArray, ...organizationsArray];

      var sortedArray = combinedArray.sort(SortArray);

      setNetworkList([...sortedArray]);
    }
  }, [adminData]);

  const validationSchema = yup.object({
    startDate: yup.date("Enter valid date").required("start date is required"),
    endDate: yup
      .date("Enter valid date")
      .required("end date is required")
      .min(yup.ref("startDate"), "End Date Must be After Start Date")
      .test("", "end date should not be greater than 1 year", (val, props) => {
        const expiryDate = moment(val);
        const enteredDate = moment(props.parent.startDate);
        const tmpExpiryDate = moment(enteredDate).add(365, "days");

        if (!tmpExpiryDate.isBefore(expiryDate)) {
          return true;
        }
      }),
    selectedNetwork: yup
      .string("Please select the network")
      .required("Please select the network"),
    reportType: yup
      .string("Please select the report type")
      .required("Please select the report type"),
    browseReportType: yup
      .string("Please select the report type")
      .when("reportType", (showReport) => {
        if (showReport == 3)
          return yup
            .string("Please select the report type")
            .required("Please select the report type");
      }),
  });

  let date = new Date();
  date.setMonth(date.getMonth() + 1);

  const formik = useFormik({
    initialValues: {
      startDate: new Date(),
      endDate: date,
      selectedNetwork: "",
      reportType: "",
      browseReportType: "",
    },
    validationSchema: validationSchema,
    onSubmit: (e) => {
      function formatDate(date) {
        var d = new Date(date),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
      }
      let result = networkList.find((obj) => {
        return obj.newId === formik.values.selectedNetwork;
      });

      let requiredData = {
        start_date: formatDate(formik.values.startDate),
        end_date: formatDate(formik.values.endDate),
        organizationOrPartnershipId: result && result.id,
        isPartnership: result && result.isPartnership,
      };
      if (formik.values.browseReportType == 1) {
        setIsGroupWise(true);
      } else {
        setIsGroupWise(false);
      }
      let dataForUserEngagementReport = {
        start_date: formatDate(formik.values.startDate),
        end_date: formatDate(formik.values.endDate),
        organizationOrPartnershipId: result && result.id,
        isPartnership: result && result.isPartnership,
        groupOrChannel: formik.values.browseReportType,
      };

      let report_type = formik.values.reportType;
      if (report_type === 1) {
        dispatch(EMDoGetOnboardingReportAction({ requiredData: requiredData }));
        setReportType(1);
      } else if (report_type === 2) {
        dispatch(EMDoGetUsageReportAction({ requiredData: requiredData }));
        setReportType(2);
      } else if (report_type === 3) {
        setDataForTableData(dataForUserEngagementReport)
        setReportType(3);
      } else if (report_type === 4) {
        setDataForTableData(requiredData)
        setReportType(4);
      }
    },
  });

  
  window.addEventListener("resize", () => {
    let width = document.body.clientWidth;

    if (width <= 600) { //Close Drawer when screen size medium
      setWidth("100%")
    } else {
      setWidth("80%")
    }
  });


  return (
    <EMPage title="Reports" style={{ padding: "2%" }}>
      <Paper elevation={2} style={{ marginBottom: "1%" }}>
        <form
          onSubmit={formik.handleSubmit}
          style={{ width: "100%", padding: "1%", display: "inline-block" }}
        >
          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid item xs={6} sm={4} md={2} >

              <EMDatePicker
                label={"Start Date"}
                id={"Start Date"}
                value={formik.values.startDate}
                onSelectDate={(date) => {
                  formik.setFieldValue("startDate", date);
                }}
                minDate={new Date("01/01/1960")}
                error={
                  formik.touched.startDate && Boolean(formik.errors.startDate)
                }
                helperText={formik.touched.startDate && formik.errors.startDate}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={2} >
              <div></div>
              <EMDatePicker
              style={{width:"80%"}}
                label={"End Date"}
                id={"End Date"}
                value={formik.values.endDate}
                onSelectDate={(date) => {
                  formik.setFieldValue("endDate", date);
                }}
                minDate={formik.values.startDate}
                error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                helperText={formik.touched.endDate && formik.errors.endDate}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={2} style={{}}>
              <FormControl
                style={{width:widthDropdown}}
                
              >
                <InputLabel id="select-report" style={{color:"#4d4d4d"}}>Report</InputLabel>
                <Select
                  labelId="select-report"
                  FormHelperTextProps={{style:{color:"red"}}}
                  id="select-report"
                  value={formik.values.reportType}
                  label="Select Report"
                  // error={
                  //   formik.errors.reportType && formik.touched.reportType
                  //     ? true
                  //     : false
                  // }
                  helperText={formik.touched.selectedNetwork && formik.errors.selectedNetwork}
                  onChange={(selected) => {
                    formik.setFieldValue("reportType", selected.target.value);
                  }}
                >
                  {dataForReportType &&
                    dataForReportType.map((report) => (
                      <MenuItem value={report.id}>{report.name}</MenuItem>
                    ))}
                </Select>
                <FormHelperText style={{ color: "red" }}>
                  {formik.errors.reportType && formik.touched.reportType
                    ? formik.errors.reportType
                    : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            {formik.values.reportType == 1 ||
            formik.values.reportType == 2 ||
            formik.values.reportType == 3 ||
            formik.values.reportType == 4 ? (
              <Grid item xs={12} sm={4} md={2} style={{ marginTop: "0.1%" }}>
                {/* <Typography
                  style={{
                    fontWeight: "bolder",
                    fontSize: "large",
                    marginBottom: "-5%",
                  }}
                >
                  Select Network
                </Typography> */}
                <FormControl style={{width: widthDropdown}}>
                  <InputLabel id="select-network" style={{color:"#4d4d4d"}}>Network</InputLabel>
                  <Select
                    labelId="select-network"
                    id="select-network"
                    value={formik.values.selectedNetwork}
                    label="Select Network"
                    // error={
                    //   formik.errors.selectedNetwork &&
                    //   formik.touched.selectedNetwork
                    //     ? true
                    //     : false
                    // }
                    FormHelperTextProps={{style:{color:"red"}}}
                    helperText={formik.touched.selectedNetwork && formik.errors.selectedNetwork}
                    onChange={(selected) => {
                      formik.setFieldValue(
                        "selectedNetwork",
                        selected.target.value
                      );
                    }}
                  >
                    {formik.values.reportType === 3
                      ? networkList &&
                        networkList.length > 0 &&
                        networkList.map((network) => (
                          <MenuItem value={network.newId}>
                            {network.networkName}
                          </MenuItem>
                        ))
                      : listOfOrganizations &&
                        listOfOrganizations.length > 0 &&
                        listOfOrganizations.map((network) => (
                          <MenuItem value={network.newId}>
                            {network.organization_name}
                          </MenuItem>
                        ))}
                  </Select>
                  <FormHelperText style={{ color: "red" }}>
                    {formik.errors.selectedNetwork &&
                    formik.touched.selectedNetwork
                      ? formik.errors.selectedNetwork
                      : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
            ) : null}

            {formik.values.reportType == 3 ? (
              <Grid item xs={12} sm={3} md={2}>
                {/* <Typography
                  style={{
                    fontWeight: "bolder",
                    fontSize: "large",
                    marginBottom: "-5%",
                  }}
                >
                  Select Group/Channel wise
                </Typography> */}
                <FormControl fullWidth style={{ marginTop: "0.1%" }}>
                  <InputLabel id="select-report" style={{color:"#4d4d4d"}}>Report</InputLabel>
                  <Select
                    labelId="select-report"
                    id="select-report"
                    value={formik.values.browseReportType}
                    label="Select Report"
                    // error={
                    //   formik.errors.browseReportType &&
                    //   formik.touched.browseReportType
                    //     ? true
                    //     : false
                    // }
                    FormHelperTextProps={{style:{color:"red"}}}
                    helperText={formik.touched.selectedNetwork && formik.errors.selectedNetwork}
                    onChange={(selected) => {
                      formik.setFieldValue(
                        "browseReportType",
                        selected.target.value
                      );
                    }}
                  >
                    {browseReportType &&
                      browseReportType.map((report) => (
                        <MenuItem value={report.id}>{report.type}</MenuItem>
                      ))}
                  </Select>
                  <FormHelperText style={{ color: "red" }}>
                    {formik.errors.browseReportType &&
                    formik.touched.browseReportType
                      ? formik.errors.browseReportType
                      : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
            ) : null}
<Hidden smDown>
            <Grid item xs={2} sm={2} md={1} style={{ textAlign: "center" }}>
              <Button type="submit" variant="contained" color="primary">
                Apply
              </Button>
            </Grid>
            <Grid item xs={2} sm={2} md={1} style={{ textAlign: "center" }}>
              <Button
              style={{width:'100%'}}
                onClick={() => {
                  function formatDate(date) {
                    var d = new Date(date),
                      month = "" + (d.getMonth() + 1),
                      day = "" + d.getDate(),
                      year = d.getFullYear();

                    if (month.length < 2) month = "0" + month;
                    if (day.length < 2) day = "0" + day;

                    return [year, month, day].join("-");
                  }

                  let result = networkList.find((obj) => {
                    return obj.newId === formik.values.selectedNetwork;
                  });

                  let requiredData;

                  if (result && result.isPartnership) {
                    requiredData = {
                      start_date: formatDate(formik.values.startDate),
                      end_date: formatDate(formik.values.endDate),
                      partnership: result && result.id,
                    };
                  } else {
                    requiredData = {
                      start_date: formatDate(formik.values.startDate),
                      end_date: formatDate(formik.values.endDate),
                      organization: result && result.id,
                    };
                  }

                  let groupOrChannel = formik.values.browseReportType;

                  if (reportType === 1) {
                    dispatch(
                      EMDoExportOnboardingReportAction({
                        requiredData: requiredData,
                      })
                    );
                  } else if (reportType === 2) {
                    dispatch(
                      EMExportUsageReportAction({ requiredData: requiredData })
                    );
                  } else if (reportType === 3) {
                    dispatch(
                      EMDoExportUserEngagementReportAction({
                        requiredData: requiredData,
                        groupOrChannel: groupOrChannel,
                      })
                    );
                  } else if (reportType === 4) {
                    dispatch(
                      EMDoExportNetworkStatsReportAction({
                        requiredData: requiredData,
                      })
                    );
                  }
                }}
                disabled={reportType === 0}
                variant="contained"
                color="primary"
              >
                Download
              </Button>
            </Grid>
            </Hidden>
          </Grid>
          <Hidden lgUp mdUp>
          <Grid container style={{marginTop:"2rem",alignItems:"center",justifyContent:"center"}}>
          <Grid item xs={2} sm={2} md={1} >
              <Button type="submit" variant="contained" color="primary" >
                Apply
              </Button>
            </Grid>
            <Grid item xs={2} sm={2} md={1} style={{ textAlign: "center", marginLeft:"2rem" }}>
              <Button 
            
                onClick={() => {
                  function formatDate(date) {
                    var d = new Date(date),
                      month = "" + (d.getMonth() + 1),
                      day = "" + d.getDate(),
                      year = d.getFullYear();

                    if (month.length < 2) month = "0" + month;
                    if (day.length < 2) day = "0" + day;

                    return [year, month, day].join("-");
                  }

                  let result = networkList.find((obj) => {
                    return obj.newId === formik.values.selectedNetwork;
                  });

                  let requiredData;

                  if (result && result.isPartnership) {
                    requiredData = {
                      start_date: formatDate(formik.values.startDate),
                      end_date: formatDate(formik.values.endDate),
                      partnership: result && result.id,
                    };
                  } else {
                    requiredData = {
                      start_date: formatDate(formik.values.startDate),
                      end_date: formatDate(formik.values.endDate),
                      organization: result && result.id,
                    };
                  }

                  let groupOrChannel = formik.values.browseReportType;

                  if (reportType === 1) {
                    dispatch(
                      EMDoExportOnboardingReportAction({
                        requiredData: requiredData,
                      })
                    );
                  } else if (reportType === 2) {
                    dispatch(
                      EMExportUsageReportAction({ requiredData: requiredData })
                    );
                  } else if (reportType === 3) {
                    dispatch(
                      EMDoExportUserEngagementReportAction({
                        requiredData: requiredData,
                        groupOrChannel: groupOrChannel,
                      })
                    );
                  } else if (reportType === 4) {
                    dispatch(
                      EMDoExportNetworkStatsReportAction({
                        requiredData: requiredData,
                      })
                    );
                  }
                }}
                disabled={reportType === 0}
                variant="contained"
                color="primary"
              >
                Download
              </Button>
            </Grid>
            </Grid>
          </Hidden>
        </form>
      </Paper>
      <div style={{marginTop:"1.5rem"}}></div>
      {reportType === 1 ? <EMOnBoardingReport /> : null}
      {reportType === 2 ? <EMUsageReport /> : null}
      {reportType === 3 ? (
        <EMUserEngagementReport groupOrChannel={isGroupWise} initialData={dataForTableData}/>
      ) : null}
      {reportType === 4 ? <EMNetworksStatsReport initialData={dataForTableData}/> : null}
    </EMPage>
  );
}
