export const CHANGE_ITEM_INDEX = "change_item_index"
export const CHANGE_USER_TO_ADMIN = "change_user_to_admin"
export const CHANGE_ELEMENT_FOR_SUBTABS = "change_element_for_subtabs"
export const SAVE_GLOBAL_SEARCH = "save_global_search"
export const GET_GROUPS_BY_SEARCH = "get_groups_by_search"
export const GET_CHANNELS_BY_SEARCH = "get_channels_by_search"
export const GET_EVENTS_BY_SEARCH = "get_events_by_search"
export const GET_POSTS_BY_SEARCH = "get_posts_by_search"
export const GET_POLLS_BY_SEARCH = "get_polls_by_search"
export const GET_QUIZZES_BY_SEARCH = "get_quizzes_by_search"
export const GET_NETWORKS_BY_SEARCH = "get_networks_by_search"
export const FIRST_TIME_LOG_IN = "first_time_log_in"
export const NAVIGATE_BETWEEN_DIFFERENT_PAGES = "navigate_between_different_pages"
export const ADD_SUBTABS_TO_GROUP = "add_subtabs_to_group"
