import React, { useState, useEffect } from 'react'
import { Paper, Box, Button, Grid, makeStyles, Modal, CircularProgress, Typography } from "@material-ui/core";
import EMPage from "../../../../components/commans/EMPage";
import EMUsersTable from './EMUserTable'
import EMGroupDropdown from "../../commons/groupDropdown";
import EMDatatableSearchView from '../../../../components/datatables/EMDatatableSearchView';
import EMImportUsers from "./ImportUsers/"
import EMResendInvites from "./ResendInvites/"
import {
  EMDoGetGroupAdminGroupListDropDownAction,
  EMDoGetGroupUserRolesListAction,
  EMDoExportGroupAdminUsersAction,
  EMDoGetListOfGroupsForDropdownInUserManagementAction,
  EMDoGetPermissionSpecificToGroupAction
} from "../../../../redux/actions/EMGroupAdminActions";
import { useSelector, useDispatch } from "react-redux";
import { find, findLast, isEmpty } from "lodash"
import EMEditUserDetails from './EMEditUserDetails'
import { EMDoGetEntityInformationBasedOnPermissionAction } from "../../../../redux/actions/EMAuthActions"
import { GROUP_ADMIN_ADD_REMOVE_IMPORT_USERS_FROM_SECONDARY_ARRAY, GROUP_ADMIN_IMPORT_USERS_SELECT_ALL_FLAG, GROUP_ADMIN_UPDATE_USERS_LIST } from '../../../../redux/constants';

const useStyles = makeStyles((theme) => ({
  button: {
    //margin: 10
    margin: 10,
    width: 170,
    height: 40
  },
  justifyEnd:{
   
    [theme.breakpoints.up("md")]: {
      justifyContent: "end"
    },
    [theme.breakpoints.up("lg")]: {
      justifyContent: "end"
    },
  },
  widthForSearchBar: {
    [theme.breakpoints.up("xs")]: {
      //width: "12ch",
      width: "100%",
      height:"fit-content"
    },
    [theme.breakpoints.up("sm")]: {
      //width: "12ch",
      width: "100%",
      height:"fit-content"
    },
    [theme.breakpoints.up("md")]: {
      //width: "12ch",
      width: "100%",
      height:"fit-content"
    },
    [theme.breakpoints.up("lg")]: {
      //width: "12ch",
      width: "100%",
      height:"fit-content"
    },
  },
}));

export default function EMManageGroupUsers(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState("");
  const [open, setOpen] = React.useState(false);
  const [editData, setEditData] = React.useState({});
  const [importUserActive, setImportUserActive] = React.useState(false);
  const [resendInviteActive, setResendInviteActive] = React.useState(false);
  const [groupId, setGroupId] = React.useState()
  const [showGroupError, setShowGroupError] = React.useState();
  const [isError, setIsError] = React.useState(true);
  const tableData = useSelector(state => state.groupAdmin.groupUsersList || {});
  const [permissionData,setPermissionData] = React.useState()
  const groupSpecificPermissions = useSelector(state => state.groupAdmin.groupSpecificRolesAndPermissions || {})
  
  /* open and close modal functionality */

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditData({});
  };

  //call dropdown Api 
  useEffect(() => {
    
    dispatch(EMDoGetListOfGroupsForDropdownInUserManagementAction())
  }, [])

//rolepermissions based on group selection
useEffect(()=>{
  if(groupSpecificPermissions){
    let permission = find(groupSpecificPermissions,{context:groupId})
    let specificPermission = find(permission && permission.rolePermissions,{permission_name:"manage_users"})
    setPermissionData(specificPermission)
  }
},[groupSpecificPermissions])

  const uiData = useSelector((state) => state.ui);

  useEffect(() => {
    if (uiData["messages"]) {
      let refObj = find(uiData["messages"], { key: "user_edit" });
      if (refObj && refObj.type === "success") {
        setOpen(false);
      }
    }
  }, [uiData]);

  useEffect(() => {
    
    if (groupId) {
      setShowGroupError("");
      let id = groupId
      dispatch(EMDoGetGroupUserRolesListAction(id));
      dispatch(EMDoGetPermissionSpecificToGroupAction({id:id}))
    }
  }, [groupId]);

  useEffect(() => {
    if (uiData["messages"]) {
      let refObj1 = findLast(uiData["messages"], { key: "users_import_ga" });
      if (refObj1 && refObj1.type === "success") {

        setImportUserActive(false) 
        refObj1.key = "users_import_ga_successful"
        dispatch({
          type: GROUP_ADMIN_IMPORT_USERS_SELECT_ALL_FLAG,
          payload: { from: "selectAll", state: false },
        });
        dispatch({
          type:   GROUP_ADMIN_ADD_REMOVE_IMPORT_USERS_FROM_SECONDARY_ARRAY,
          payload:{data: [], from:"selectAll", state:false}
        });
        dispatch({type:GROUP_ADMIN_UPDATE_USERS_LIST, payload:true})
      }
      let refObj2 = findLast(uiData["messages"], { key: "resend_list_success_ga" });
      if (refObj2 && refObj2.type === "success") {
        setResendInviteActive(false) 
        refObj2.type = "Modal closed"
      }
    }
  }, [uiData]);

  const groupSelectionHandler = () => {
    
    if (groupId == undefined) {
      setShowGroupError("Please Select Group Here");
      setIsError(false);
    } else {
      setShowGroupError("");
      setIsError(true);
    }
  };

  let disabledValue = (permissionData && permissionData["add_flag"]) || true
  return (
    <EMPage title="Group - Manage Users">
      <Box >
      <Modal style={{display:"flex", alignItems:"center", justifyContent:"center"}} open={uiData.loading.state}>
          <Box style={{display:"flex"}} alignItems="center" justifyContent="center">
            <Box textAlign="center">
          <CircularProgress />
          <Typography style={{fontWeight:"medium", fontSize:"large"}}>{uiData.loading.message}</Typography>
          </Box>
          </Box>
          </Modal>
        <Paper elevation={2}>
          {/* <Box height="100%" display="flex" flexDirection="column"> */}
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            p={3}
            justifyItems="center"
            alignItems="center"

          >
            <Grid container>
              <Grid container spacing={40} style={{ maxWidth: "100%" }}>
                <Grid justify="flex-start"item xs={12} sm={4} md={4} lg={4}>
                  <EMGroupDropdown
                    name= "group"
                    error={showGroupError}
                    onSelect={id => {
                      setGroupId(id);
                    }}
                  />
                </Grid>
                <Grid container className={classes.justifyEnd} item xs={12} sm={4} md={8} lg={8}>
                  <Button
                    className={classes.button}
                    disabled = {!disabledValue}
                    variant="contained"
                    color="primary"
                    onClick={() => { 
                      groupSelectionHandler();
                      if (groupId !== undefined) {
                        dispatch({type:GROUP_ADMIN_UPDATE_USERS_LIST, payload:false})
                        setImportUserActive(true)
                      }
                    }}
                  >
                    {"Import Users"}
                  </Button>
                </Grid>
              </Grid>
              <Box >
                <EMImportUsers
                
                  open={importUserActive}
                  close={() => { 
                    groupSelectionHandler();  
                    setImportUserActive(false) 
                  }}
                  userData={editData}
                  groupId={groupId}
                  closeHandler = {setImportUserActive}
                />
              
              </Box>
              <Box >
                <EMResendInvites
                  open={resendInviteActive}
                  close={() => { 
                    groupSelectionHandler();
                    setResendInviteActive(false) 
                  }}
                  userData={editData}
                  groupId={groupId}
                  closeHandler = {setResendInviteActive}
                />
              </Box>

              <Grid container style={{ maxWidth: "100%" }} justify="flex-start" alignItems='center' item xs={12} sm={4} md={6} lg={8}> 
                <Box width="100%" justify="flex-start" className={classes.widthForSearchBar} xs={12} sm={4} md={6} lg={8}>
                  <EMDatatableSearchView
                    hint={"Search by First Name, Last Name, E-mail Address"} 
                    onChangeListener={(data) => {
                      setFilter(data);
                  }}
                  />
                </Box>
              </Grid>
              <Grid container className={classes.justifyEnd} item xs={12} sm={4} md={4} lg={4}>
                <Button
                disabled = {!disabledValue}
                  className={classes.button}
                  variant="contained" 
                  color="primary"
                  onClick={() => {
                    groupSelectionHandler();
                    if (groupId) {
                      if (tableData) {
                        let contextData = {
                          context: groupId
                        };
                        let data = {
                          ...tableData,
                          key: "export_user_success"
                        };
                        dispatch(
                          EMDoExportGroupAdminUsersAction({
                            data,
                            contextData,
                            key: "user_export"
                          })
                        );
                      }
                    }
                  }}
                >
                  {"Export Users"}
                </Button>

              </Grid>
            </Grid>
          </Box>
          <EMUsersTable
              filter={filter}
              groupId={groupId}
              permissionData = {permissionData}
              onEdit={(roleData) => {
                setEditData(roleData);
                setOpen(true);
              }}
            />
          <Box>
            <EMEditUserDetails
              open={open}
              close={handleClose}
              userData={editData}
              permissionData={permissionData}
            />
          </Box>
          {/* </Box> */}
        </Paper>
      </Box>
    </EMPage>
  )
}