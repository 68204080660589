import React, { useEffect } from "react";
import {
  Paper,
  Box,
  Button,
  Modal,
  Typography,
  IconButton,
  Grid,
  Link,
  Card,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  ListItemText,
  FormHelperText,
  Input,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import sampleDocument from "./test.csv";
import EMDropDown from "../../../commons/EMEntityTemplateDropdown";
import { EMDoBulkUploadUsersListAction } from "../../../../../../redux/actions/EMNetworkAdminActions";
import { useDispatch, useSelector } from "react-redux";
// import EMEntityDropdown from "../UserManagement/commons/EMEntityDropdown"
import BulkUploadResult from "./result";
import { findLast, isEmpty } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("xs")]: {
      backgroundColor: theme.palette.common.white,
      // height: "auto",
      minWidth: "50%",
      margin: "auto",
      padding: "0px",
      // maxWidth: '100vw',
      maxHeight: "50%",
      // position: 'fixed',
      marginTop: "25rem",
      transform: "translate(0, -50%)",
      width: "70%",
      marginLeft: "5rem",
      overflowY: "auto",
      overflowX:"hidden"
    },
    [theme.breakpoints.up("sm")]: {
      backgroundColor: theme.palette.common.white,
      height: "auto",
      width: "50%",
      marginTop: "25rem",
      margin: "auto",
      padding: "0px",
      marginLeft: "30%",
      overflowY: "auto",
      overflowX:"hidden"
    },
    [theme.breakpoints.up("md")]: {
      backgroundColor: theme.palette.common.white,
      height: "auto",
      width: "50%",
      marginTop: "25rem",
      margin: "auto",
      padding: "0px",
      marginLeft: "30%",
      overflowY: "auto",
      overflowX:"hidden"
    },
    [theme.breakpoints.up("lg")]: {
      backgroundColor: theme.palette.common.white,
      height: "auto",
      width: "50%",
      marginTop: "20rem",
      margin: "auto",
      padding: "0px",
      marginLeft: "30%",
      overflowY: "auto",
      overflowX:"hidden"
    },
  },
  title: {
    margin: 10,
    flexGrow: 1,
    fontWeight: "bold",
    color:"white",
    fontSize:"large"
  },
  form: {
    padding: "3%",
  },
  buttonHolder: {
    padding: theme.spacing(3),
  },
  card: {
    [theme.breakpoints.up("xs")]: {
      padding: "0px 36px",
      width: "100%",
      height: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "0px 10px",
      width: "100%",
      height: "100%",
    },
  },
  buttonStyle: {
    [theme.breakpoints.up("xs")]: {
      marginTop: "1rem",
      marginBottom: "1rem",
      marginLeft: "2rem",
    },
  },
  formControl: {
    //margin: theme.spacing(1),
    width: "100%"
  },
  noLabel: {
    marginTop: theme.spacing(3)
  },
  label: {
    "&:focus": {
      color: theme.palette.common.black
    },
    color:"#4d4d4d"
  }
}));

export default function EMBulkUpload(props) {
  const classes = useStyles();
  const [fileName, setFileName] = React.useState();
  const [file, setFile] = React.useState();
  const [showResult, setShowResult] = React.useState();
  const [emailTemplate, setEmailTemplate] = React.useState();
  const [showTemplateError, setshowTemplateError] = React.useState();
  const [showFileError, setshowFileError] = React.useState();
  const emailTemplateData = useSelector(state => state.networkAdmin["emailTemplates"])

  const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
  getContentAnchorEl: null
};

  useEffect(() => {
    if (emailTemplate != undefined) {
      setshowTemplateError("");
    }
  }, [emailTemplate]);

  useEffect(() => {
    if (file != undefined) {
      
      setshowFileError("");
    }
  }, [file]);
  

  const fileselectionHandler = () => {
    
    if (isEmpty(file)) {
      setshowFileError("Please select a File");
    } else {
      setshowFileError("");
    }
  };
  const dispatch = useDispatch();
  const fileChangeHandler = (event) => {
    
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files;
      let file_name = event.target.files[0].name;
      setFileName(file_name);
      setFile(file);
    }
  };

  const sendFileToServer = () => {
    // TemplateSelectionHandler();
    if (file == undefined) {
      setshowFileError("please select a file");
    } else if (emailTemplate != undefined && file != undefined) {
      let template = emailTemplateData.find((email) => email.template_name == emailTemplate)
      let contextData = {
        contextType: props.entity.context_type,
        context: props.entity.context,
        email_template: template && template.id,
      };
      let data = file;
      dispatch(
        EMDoBulkUploadUsersListAction({
          data,
          contextData,
          key: "bulk_upload_user_export",
        })
      );
      setFile();
      setFileName();
    } else {
      if (emailTemplate == undefined) {
        setshowTemplateError("Please select a Template");
      }
    }
  };

  const uiData = useSelector((state) => state.ui);

  useEffect(() => {
    if (uiData["messages"]) {   
      let refObj = findLast(uiData["messages"], { key: "bulk_upload_user_export" });
      if (refObj && refObj.type === "success") {
        setShowResult(true);
        refObj.type = "failed";
      }
    }
  }, [uiData]);


  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.close();
        setFile();
        setFileName();
      }}
    >
      <Box className={classes.root}>
        <form>
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            style={{    padding: "0px 4px 0px 18px",
            backgroundColor:"#2F92D6"}}
          >
            <Typography className={classes.title}>
              {"Bulk Upload Users"}
            </Typography>
            <IconButton
              onClick={() => {
                props.close();
                setFile();
                setFileName();
              }}
            >
              <CloseRounded style={{color:"white"}} />
            </IconButton>
          </Box>
          <Box className={classes.form}>
          <Grid
            // style={{ marginBottom: 20 }}
            container
            alignItems="flex-start"
            justifyContent="center"
          >
            <Grid item xs={12} sm={6} md={6} className={classes.card}>
              <Card style={{ width: "100%" }}>
                {file && file[0].name == fileName ? (
                  <Typography>File Selected - {fileName}</Typography>
                ) : (
                  <Typography>Choose a file...</Typography>
                )}
                <div style={{ color: "red" }}> {showFileError}</div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <input
                style={{ display: "none" }}
                id="contained-button-file"
                type="file"
                accept=".csv"
                onChange={fileChangeHandler}
                onClick={(event)=> {
                  event.target.value = null
                }}
              />
              <label htmlFor="contained-button-file">
                <Button variant="contained" color="primary" component="span">
                  Browse
                </Button>
              </label>
              <div>
                <Typography>only .csv file type is allowed</Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <Link
                variant="body2"
                href={sampleDocument}
                download="sampleDocument.csv"
              >
                Download Sample
              </Link>
            </Grid>
          </Grid>
          <BulkUploadResult
            open={showResult}
            close={() => {
              
              setShowResult(false)
            }}
            // uploadedContacts = {responseData["uploaded_contacts"]}
            // contactsAlreadyPresent = {responseData["contacts_already_present"]}
            // invalidRecords = {responseData["invalid_records"]}
            // contactsInvited = {responseData["contacts_invited"]}
          />
          <Grid className={classes.form}>
            {/* <EMDropDown
              onSelect={(id) => {
                setEmailTemplate(id);
              }}
              title={"Email Template"}
              style={{ width: "50%" }}
              // entity={props.entity}
              error={showTemplateError}
            /> */}
                <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="org_network_select" className={classes.label}>
        Select Template
        </InputLabel>
        <Select
          labelId="org_network_select"
          value={emailTemplate}
          defaultValue=""
          onChange={(event) => {
            setEmailTemplate(event.target.value);
          }}
          // onClick={(e) => e.target.value == null}
          MenuProps={MenuProps}
          fullWidth
          helperText={showTemplateError}
        >
          
          {emailTemplateData && emailTemplateData.map(d => {
            
            let dataToShow = d["template_name"];
            return (
              <MenuItem key={dataToShow} value={dataToShow}>
                <Tooltip title={<h2 style={{ color: "white" }}>{dataToShow}</h2>}  placement="top">
                <ListItemText primary={dataToShow && dataToShow.length > 48 ? dataToShow.slice(0,48) + ".." : dataToShow} />
                </Tooltip>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormHelperText style={{color:"red"}}>
        {showTemplateError && showTemplateError}
      </FormHelperText>
    </div>
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="row"
            className={classes.buttonHolder}
            spacing={2}
          >
            <Grid item>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                // disabled={emailTemplate == null && fileName == null}
                onClick={() => {
                  fileselectionHandler();
                  sendFileToServer();
                }}
              >
                <Typography variant="h7">Send</Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.button}
                variant="outlined"
                color="secondary"
                onClick={() => {
                  props.close();
                  setFile();
                  setFileName();
                }}
              >
                <Typography variant="h7">Discard</Typography>
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="flex-start"
            style={{ padding: 8 }}
          >
            <Typography color="error" style={{ fontSize: "small" }}>
              Note: Make sure that you have added departments and levels for
              corresponding network
            </Typography>
          </Grid>
          </Box>
        </form>
      </Box>
    </Modal>
  );
}
