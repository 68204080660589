import React, { useState, useEffect, useRef } from "react";
import EMPage from "../../../../components/commans/EMPage";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EMGroupView from "../../../../components/user/EMGroupView";
import { useLocation } from "react-router";
import Parse from "html-react-parser";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import organizationLogo from "../../../../../src/assets/organization_logo.jpg";
import {
  Card,
  Typography,
  Grid,
  Paper,
  GridList,
  Box,
  GridListTile,
  CardContent,
  Avatar,
  Button,
  IconButton,
  useMediaQuery,
  Tooltip,
} from "@material-ui/core";
import { useNavigate } from "react-router";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { CHANGE_ELEMENT_FOR_SUBTABS } from "../../../../redux/constants/UserPlatform/EMNavigationConstant";
import { useDispatch, useSelector } from "react-redux";
import {
  EMDoGetAllChannelsForNetworkAction,
  EMDoGetAllGroupsForNetworkAction,
  EMDoGetDetailsForNetwork,
} from "../../../../redux/actions/UserPlatform/Network/EMMyNetworkAction";
import { isEmpty, find } from "lodash";

const useStyles = makeStyles((theme) => ({
  rootForNetwork: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridListforNetwork: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  rootForGroups: {
    // marginTop: "2%",
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridListforGroups: {
    width: "100%",
    height: "100%",
    padding: 10,
    maxHeight: window.screen.height,
    "&::-webkit-scrollbar": {
      width: "0.1em",
      height: "0em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#4A4A4A",
      outline: "1px solid slategrey",
    },
  },
  root: {
    width: "100%",
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-around",
    overflow: "hidden",
    marginRight: "2%",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
    overflowY: "hidden",
    width: "100%",
    padding: 10,
    maxWidth: window.screen.width,
    "&::-webkit-scrollbar": {
      width: "0em",
      height: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#4A4A4A",
      outline: "1px solid slategrey",
    },
  },
  rootForPaper: {
    paddingBottom: "2%",
    width: "100%",
  },
  title: {
    fontWeight: "bold",
    marginLeft: "1%",
    marginBottom: "2%",
  },
  networkInfo: {
    textAlign: "center",
    marginTop: "9%",
  },

  partner: {
    width: "100%",
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridListnetwork: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    width: "100%",
    height: "100%",
    padding: 10,
    maxWidth: window.screen.width,
    "&::-webkit-scrollbar": {
      // width: '0.5em',
      height: "0.2em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#4A4A4A",
      outline: "1px solid slategrey",
    },
  },
  gridListforNetworks: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
    height: "50px",
    overflowY: "visible",
    width: "45%",
  },
  Gridstyle: {},
  gridImages: {
    marginLeft: "15%",
  },
  button: {
    marginTop: "15px",
    marginRight: "15px",
    fontWeight: "bolder",
    float: "right",
  },
  rootForOrganizations: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridListForOrganizations: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    "&::-webkit-scrollbar": {
      // width: '0.5em',
      height: "0.2em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#4A4A4A",
      outline: "1px solid slategrey",
    },
  },
  rootForGrid: {
    display: "flex",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    marginTop: "1%",
  },
  paper: {
    paddingLeft: "1%",
    fontWeight: "bolder",
    color: theme.palette.text.primary,
  },
  gridListTile: {
    marginTop: "4px",
    marginBottom: "10px",
    borderRadius: "10px",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);",
  },
  rootForAttachments: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
  },
  gridListForAttachments: {
    // padding:"10px",
    width: "100%",
    height: "100%",
    maxHeight: window.screen.height / 2.5,
    "&::-webkit-scrollbar": {
      width: "0.1em",
    },
  },
  typo:{
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 1,
    wordBreak:"break-word",
    overflow: "hidden",
  },
  typo1:{
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 2,
    wordBreak:"break-word",
    overflow: "hidden",
  },
}));

function EMNetworkDetail(props) {
  const myRef = useRef(null);
  const channelRef = useRef(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const data = useSelector((state) => state.myNetwork || {});
  const userData = useSelector((state) => state.auth || {});

  useEffect(() => {
    let networkId = location.state && location.state.NetworkId;
    dispatch(
      EMDoGetDetailsForNetwork({
        id: networkId || window.sessionStorage.getItem("NetworkId"),
      })
    );
    dispatch(
      EMDoGetAllGroupsForNetworkAction({
        id: networkId || window.sessionStorage.getItem("NetworkId"),
      })
    );
    dispatch(
      EMDoGetAllChannelsForNetworkAction({
        id: networkId || window.sessionStorage.getItem("NetworkId"),
      })
    );
    dispatch(
      EMDoGetAllGroupsForNetworkAction({
        id: networkId || window.sessionStorage.getItem("NetworkId"),
      })
    );
  }, [location]);

  function getCols(screenWidth) {
    if (isWidthUp("xl", screenWidth)) {
      return 4;
    }

    if (isWidthUp("lg", screenWidth)) {
      return 4;
    }
    if (isWidthUp("md", screenWidth)) {
      return 3;
    }
    if (isWidthUp("sm", screenWidth)) {
      return 2;
    }
    if (isWidthUp("xs", screenWidth)) {
      return 1;
    }
    return 4;
  }
  const cols = getCols(props.width);

  function getColsForOrg(screenWidth) {
    if (isWidthUp("xl", screenWidth)) {
      return 8;
    } else if (isWidthUp("lg", screenWidth)) {
      return 7;
    } else if (isWidthUp("md", screenWidth)) {
      return 6;
    } else if (isWidthUp("sm", screenWidth)) {
      return 5;
    } else if (isWidthUp("xs", screenWidth)) {
      return 4;
    }
    return 4;
  }

  const colsForOrg = getColsForOrg(props.width);

  function getColsForAdm(screenWidth) {
    if (isWidthUp("xl", screenWidth)) {
      return 8;
    } else if (isWidthUp("lg", screenWidth)) {
      return 7;
    } else if (isWidthUp("md", screenWidth)) {
      return 6;
    } else if (isWidthUp("sm", screenWidth)) {
      return 5;
    } else if (isWidthUp("xs", screenWidth)) {
      return 3;
    }
    return 4;
  }

  const colsForAdm = getColsForAdm(props.width);

  const theme = useTheme();

  const matchesMediumScreen = useMediaQuery(theme.breakpoints.up("md"));

  const matchesLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const matchesSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      style={{
        width: "100%",
        paddingLeft: matchesLargeScreen ? "8%" : "4%",
        paddingTop: matchesLargeScreen
          ? "4%"
          : matchesMediumScreen
          ? "6%"
          : "8%",
        paddingRight: matchesLargeScreen ? "8%" : "4%",
        paddingBottom: "2%",
      }}
    >
      <Paper elevation={2} className={classes.rootForPaper}>
        <Grid
          container
          spacing={2}
          style={{
            paddingLeft: "2%",
            paddingRight: "2%",
            paddingTop: "2%",
          }}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            item
            lg={2}
            md={3}
            sm={4}
            xs={5}
          >
            <Card
              elevation={2}
              // style={{
              //   height: "180px",
              // }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                  // height: "100%",
                  backgroundColor: "#d3d3d3",
                }}
              >
                <img
                  alt=""
                  style={{
                    flexShrink: 0,
                    minWidth: "100%",
                    minHeight: "100%",
                  }}
                  src={
                    data.networkDetails.logo_file
                      ? process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(
                          0,
                          -1
                        ) + data.networkDetails.logo_file
                      : organizationLogo
                  }
                />
              </div>
              {/* </CardContent> */}
            </Card>
          </Grid>

          <Grid item lg={7} md={6} sm={5} xs={7}>
            <Card>
              <Typography variant="h6" style={{ fontWeight: "bold" }} className={classes.typo}>
                {data.networkDetails.partnership_name}
              </Typography>
              <Typography>{data.networkDetails.tagline}</Typography>
              <Typography color="textSecondary">
                {data.networkDetails.number_of_network_users} members
              </Typography>
              <Typography style={{ fontWeight: "bold" }}>
                Working With Partners
              </Typography>
              <div
                className={classes.rootForOrganizations}
                style={{ marginTop: "1%" }}
              >
                <GridList
                  className={classes.gridListForOrganizations}
                  cols={colsForOrg}
                >
                  {data.networkDetails.organizations &&
                    data.networkDetails.organizations.map((group, index) => (
                      <GridListTile
                        key={index}
                        style={{
                          overflow: "hidden",
                          width: "100px",
                          height: "100px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Paper
                          elevation={2}
                          style={{
                            width: "70px",
                            height: "70px",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          {group.logo_file ? (
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                                whiteSpace: "nowrap",
                                textAlign: "center",
                              }}
                            >
                              <span
                                style={{
                                  display: "inline-block",
                                  height: "100%",
                                  verticalAlign: "middle",
                                }}
                              ></span>
                              <img
                                alt=""
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                  verticalAlign: "middle",
                                }}
                                src={
                                  process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(
                                    0,
                                    -1
                                  ) + group.logo_file
                                }
                              />
                            </div>
                          ) : (
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Avatar
                                style={{
                                  margin: "auto",
                                  verticalAlign: "middle",
                                }}
                              >
                                {group.organization_name
                                  .charAt(0)
                                  .toUpperCase()}
                              </Avatar>
                            </Box>
                          )}
                        </Paper>
                      </GridListTile>
                    ))}
                </GridList>
              </div>
            </Card>
          </Grid>
          {matchesSmallScreen ? null : (
            <Grid
              direction="row"
              container
              justifyContent="flex-end"
              alignItems="flex-start"
              item
              lg={3}
              md={3}
              sm={3}
            >
              <Button
                onClick={() => {
                  navigate("/users/networks");
                }}
                variant="outlined"
                // className={classes.button}
              >
                <ArrowBackIcon></ArrowBackIcon>
                {matchesSmallScreen ? "" : "Back to networks"}
              </Button>
            </Grid>
          )}
        </Grid>

        <div style={{ width: "100%", padding: "1%" }}>
          {data.networkDetails.vision_stmt ||
          data.networkDetails.partnership_info ||
          data.networkDetails.mission_stmt ||
          data.networkDetails.partnership_info ? (
            <Card style={{}}>
              <CardContent className={classes.information}>
                <div
                  style={{
                    display: matchesSmallScreen ? "" : "flex",
                    marginBottom: "1%",
                  }}
                >
                  <Paper style={{ width: matchesSmallScreen ? "" : "50%" }}>
                    <Typography paragraph={true}>
                      {data.networkDetails.vision_stmt &&
                        Parse(data.networkDetails.vision_stmt)}
                    </Typography>
                  </Paper>
                  <Paper
                    style={{
                      width: matchesSmallScreen ? "" : "50%",
                      marginLeft: matchesSmallScreen ? "0%" : "2%",
                      marginBottom: matchesSmallScreen ? "1%" : "0%",
                    }}
                  >
                    <Typography paragraph={true}>
                      {data.networkDetails.mission_stmt &&
                        Parse(data.networkDetails.mission_stmt)}
                    </Typography>
                  </Paper>
                </div>
                <div
                  style={{
                    display: matchesSmallScreen ? "" : "flex",
                    marginBottom: "1%",
                  }}
                >
                  <Paper style={{ width: matchesSmallScreen ? "" : "50%" }}>
                    <Typography paragraph={true}>
                      {data.networkDetails.partnership_info &&
                        Parse(data.networkDetails.partnership_info)}
                    </Typography>
                  </Paper>
                  <Paper
                    style={{
                      width: matchesSmallScreen ? "" : "50%",
                      marginLeft: matchesSmallScreen ? "0%" : "2%",
                      marginBottom: matchesSmallScreen ? "1%" : "0%",
                    }}
                  >
                    {data.networkDetails.attachments &&
                    data.networkDetails.attachments.length > 0 ? (
                      <div className={classes.rootForAttachments}>
                        <GridList
                          cellHeight="auto"
                          className={classes.gridListForAttachments}
                          cols={1}
                        >
                          {data.networkDetails.attachments.map((doc, id) => (
                            <Box display="flex">
                              <Typography>
                                <a
                                  href={
                                    process.env.REACT_APP_BACKEND_FILE_PREVIEW +
                                    doc.file
                                  }
                                  style={{ color: "#2F92D6" }}
                                  target="_blank"
                                >
                                  {doc &&
                                    doc.file.substr(
                                      doc && doc.file.lastIndexOf("/") + 1,
                                      doc && doc.length
                                    )}
                                </a>
                              </Typography>
                            </Box>
                          ))}
                        </GridList>
                      </div>
                    ) : null}
                  </Paper>
                </div>
              </CardContent>
            </Card>
          ) : null}

          <div style={{ paddingLeft: 30, display: "flex" }}>
            <Typography variant="h6" style={{ fontWeight: "bolder" }}>
              {" "}
              My Network Groups
            </Typography>
          </div>
          <div style={{ display: "flex" }}>
            {data.allgroupsfornetwork.length > 0 &&
            data.allgroupsfornetwork[0].groups.length >= 4 ? (
              <IconButton
                style={{ marginLeft: "10px" }}
                onClick={() => {
                  if (myRef.current.scrollLeft !== myRef.current.scrollWidth) {
                    myRef.current.scrollLeft -= myRef.current.scrollWidth / 10;
                  }
                }}
              >
                <NavigateBeforeIcon
                  fontSize="small"
                  style={{ color: "black" }}
                />
              </IconButton>
            ) : null}
            <div
              className={
                data.allgroupsfornetwork.length > 0 &&
                data.allgroupsfornetwork[0].groups.length >= 4
                  ? classes.root
                  : classes.rootForGroups
              }
            >
              <GridList
                className={
                  data.allgroupsfornetwork.length > 0 &&
                  data.allgroupsfornetwork[0].groups.length >= 4
                    ? classes.gridList
                    : classes.gridListforGroups
                }
                cols={cols}
                ref={myRef}
                style={{ overflowY: "hidden" }}
              >
                {data.allgroupsfornetwork.length > 0 &&
                  data.allgroupsfornetwork[0].groups.map((network, key) => (
                    <GridListTile
                      key={network.id}
                      style={{
                        marginLeft: "1%",
                        marginTop: "2%",
                        height:"150px"
                      }}
                      className={classes.gridListTile}
                    >
                      <EMGroupView
                        route="browse_groups"
                        onStarClick={() => {}}
                        id={network.id}
                        group_name={network.group_name}
                        active={network.is_active}
                        logo_file={network.logo_file}
                        privacy_type_name={network.privacy_type_name}
                        is_fav={network.is_fav}
                        users_count={network.number_of_group_users}
                        onCardClick={() => {
                          
                            navigate("/users/groups/activity", {
                              state: { GroupId: network.id },
                            });
                            window.sessionStorage.setItem(
                              "GroupId",
                              network.id
                            );
                          }
                        }
                      />
                    </GridListTile>
                  ))}
              </GridList>
            </div>
            {data.allgroupsfornetwork.length > 0 &&
            data.allgroupsfornetwork[0].groups.length >= 4 ? (
              <IconButton
                style={{ marginLeft: "-20px" }}
                onClick={() => {
                  if (myRef.current.scrollLeft !== myRef.current.scrollWidth) {
                    myRef.current.scrollLeft += myRef.current.scrollWidth / 10;
                  }
                }}
              >
                <NavigateNextIcon fontSize="small" style={{ color: "black" }} />
              </IconButton>
            ) : null}
          </div>

          <div style={{ paddingLeft: 30, display: "flex" }}>
            <Typography variant="h6" style={{ fontWeight: "bolder" }}>
              {" "}
              My Network Channels
            </Typography>
          </div>
          <div style={{ display: "flex" }}>
            {data.allChannelsfornetwork.length > 0 &&
            data.allChannelsfornetwork[0].channel.length > 4 ? (
              <IconButton
                style={{ marginLeft: "8px" }}
                onClick={() => {
                  if (
                    channelRef.current.scrollLeft !==
                    channelRef.current.scrollWidth
                  ) {
                    channelRef.current.scrollLeft -=
                      channelRef.current.scrollWidth / 10;
                  }
                }}
              >
                <NavigateBeforeIcon
                  fontSize="small"
                  style={{ color: "black" }}
                />
              </IconButton>
            ) : null}

            <div
              className={
                data.allChannelsfornetwork.length > 0 &&
                data.allChannelsfornetwork[0].channel.length > 4
                  ? classes.root
                  : classes.rootForGroups
              }
            >
              <GridList
                className={
                  data.allChannelsfornetwork.length > 0 &&
                  data.allChannelsfornetwork[0].channel.length > 4
                    ? classes.gridList
                    : classes.gridListforGroups
                }
                cols={cols}
                ref={channelRef}
                style={{ overflowY: "hidden" }}
              >
                {data.allChannelsfornetwork.length > 0 &&
                  data.allChannelsfornetwork[0].channel.map((network, key) => (
                    <GridListTile
                      key={network.id}
                      style={{
                        marginLeft: "1%",
                        marginTop: "2%",
                        height:"150px"
                      }}
                      className={classes.gridListTile}
                    >
                      <EMGroupView
                        route="browse_groups"
                        onStarClick={() => {}}
                        id={network.id}
                        group_name={network.channel_name || ""}
                        logo_file={network.logo_file}
                        privacy_type_name={network.privacy_type_name}
                        isFav={network.is_fav}
                        users_count={network.number_of_channel_users}
                        onCardClick={() => {
                          
                            navigate("/users/channels/activity", {
                              state: { ChannelId: network.id },
                            });
                            window.sessionStorage.setItem(
                              "ChannelId",
                              network.id
                            );
                          }
                        }
                      />
                    </GridListTile>
                  ))}
              </GridList>
            </div>
            {data.allChannelsfornetwork.length > 0 &&
            data.allChannelsfornetwork[0].channel.length > 4 ? (
              <IconButton
                style={{ marginLeft: "-20px" }}
                onClick={() => {
                  if (
                    channelRef.current.scrollLeft !==
                    channelRef.current.scrollWidth
                  ) {
                    channelRef.current.scrollLeft +=
                      channelRef.current.scrollWidth / 10;
                  }
                }}
              >
                <NavigateNextIcon fontSize="small" style={{ color: "black" }} />
              </IconButton>
            ) : null}
          </div>
        </div>
      </Paper>
      <Paper elevation={2} style={{ marginTop: "2%" }}>
        <Grid
          container
          direction="row"
          style={{ padding: 10 }}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item lg={2} md={2} sm={3} xs={5}>
            <Typography
              component="h6"
              variant="h6"
              style={{ fontWeight: "bolder" }}
            >
              Network Admins
            </Typography>
          </Grid>
          <Grid item lg={10} md={10} sm={9} xs={7}>
            <div className={classes.root}>
              <GridList
                className={classes.gridList}
                // style={{ alignItems: "center" }}
                cellHeight="auto"
                cols={colsForAdm}
              >
                {data.networkDetails.admins &&
                  data.networkDetails.admins.map((admin, index) => (
                    <GridListTile>
                      <Avatar
                        onClick={
                          () =>
                            navigate(
                              `/users/people/${parseInt(admin.user_id)}`,
                              {
                                state: {
                                  name: admin.first_name,
                                  id: parseInt(admin.user_id),
                                },
                              }
                            )
                          // dispatch(EMDoGetProfileDetailsAction(loginUserData.profile_id))
                        }
                        style={{
                          margin: "auto",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "48px",
                          width: "48px",
                        }}
                        src={
                          process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(
                            0,
                            -1
                          ) + admin.profile_pic_file
                        }
                      />
                      <Tooltip
                        title={
                          <h2 style={{ color: "white" }}>
                            {admin.first_name + " " + admin.last_name}
                          </h2>
                        }
                        placement="top"
                      >
                        <Typography
                          style={{ textAlign: "center", marginTop: "5%" }}
                          className={classes.typo1}
                        >
                          {admin.first_name} {admin.last_name}
                        </Typography>
                      </Tooltip>
                    </GridListTile>
                  ))}
              </GridList>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
    // </ThemeProvider>
  );
}

export default withWidth()(EMNetworkDetail);
