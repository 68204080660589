import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  Grid,
  Paper,
  Typography,
  GridList,
  GridListTile,
  useMediaQuery,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { ReactComponent as CategoryIcon } from "../../../../../assets/Icon-awesome-tag.svg";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import EMUser from "../../../../../components/user/EMUser";
import EMPage from "../../../../../components/commans/EMPage";
import { useSelector, useDispatch } from "react-redux";
import { EMDoGetNetworkFiltersAction } from "../../../../../redux/actions/UserPlatform/Groups/EMGroupsAction";
import {
  EMDoGetMembersOfChannelAction,
  EMDoGetGroupsForFilterInChannelAction,
  EMDoGetMembersOfChannelByGroupFilterAction,
  EMDoGetListOfChannelMembersBySearchAction,
} from "../../../../../redux/actions/UserPlatform/Channels/EMChannelsAction";
import {
  SHOW_NEXT_MEMBERS,
  CHANNEL_APPLY_GROUP_FILTER,
  DISABLE_ENABLE_FILTER_FOR_CHANNEL_MEMBERS,
  DISABLE_ENABLE_SEARCH_CHANNEL_MEMBERS,
} from "../../../../../redux/constants/UserPlatform/EMChannelConstant";
import { useNavigate } from "react-router";
import profile_image from "../../../../../assets/user_image.png";
import EMSearchView from "../../../../../components/navigation/EMSearchView";
import InfinitScroll from "react-infinite-scroll-component";
import EMSideBar from "../../../../../components/navigation/EMSideBar";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { LocalOffer } from "@material-ui/icons";
import { EMGetRolesAndPermissionsBasedOnEntityAction } from "../../../../../redux/actions/EMAuthActions";
import EMSearch from "../../../../../components/navigation/EMSearch";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    fontWeight: "bolder",
    fontSize: "large",
  },
  root: {
    marginTop: "2%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    fontWeight: "bold",
  },
  gridList: {
    width: "100%",
    height: "100%",
    marginLeft: "2%",
    // maxHeight: window.screen.height,
    // "&::-webkit-scrollbar": {
    //   width: "0.4em",
    // },
    // "&::-webkit-scrollbar-track": {
    //   boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    //   webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    // },
    // "&::-webkit-scrollbar-thumb": {
    //   backgroundColor: "#4A4A4A",
    //   outline: "1px solid slategrey",
    // },
  },
}));

function EMChannelMembersPage(props) {
  const classes = useStyles();
  const data = useSelector((state) => state.channels || {});
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [selectedNetworks, setSelectedNetworks] = React.useState([]);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(0);
  const [limitWhileSearching, setLimitWhileSearching] = useState(0);
  const [limitWhileFiltering, setLimitWhileFiltering] = useState(0);
  const ref = useRef(null);
  const theme = useTheme();
  const matchesMediumScreen = useMediaQuery(theme.breakpoints.up("md"));
  const matchesLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  useEffect(() => {
    dispatch({
      type: SHOW_NEXT_MEMBERS,
      payload: {
        nextMembers: undefined,
      },
    });
    dispatch(EMDoGetGroupsForFilterInChannelAction());
    dispatch({
      type:DISABLE_ENABLE_SEARCH_CHANNEL_MEMBERS,
      payload:true
    })
    dispatch({
      type:DISABLE_ENABLE_FILTER_FOR_CHANNEL_MEMBERS,
      payload:true
    })
  }, []);

  useEffect(() => {
    dispatch(
      EMGetRolesAndPermissionsBasedOnEntityAction({
        entity: "channel",
        entityId: window.sessionStorage.getItem("ChannelId"),
      })
    );
  }, []);

  useEffect(() => {
    let appliedFilters = selectedNetworks;
    if (appliedFilters.length > 0) {
      setLimitWhileFiltering(0);
      if (appliedFilters.length == 1) {
        let selectedNetwork = appliedFilters[0];
        let id = selectedNetwork.id;
        let action;
        action = "groups=" + id;
        if (search.length > 0) {
          action = action + "&" + "search=" + search;
        }
        dispatch(
          EMDoGetMembersOfChannelByGroupFilterAction({
            id: window.sessionStorage.getItem("ChannelId"),
            limit: limitWhileFiltering,
            action: action,
          })
        );
      } else if (appliedFilters.length > 1) {
        let groupAction;
        // let organizationAction
        let groupIds = [];

        selectedNetworks.map((selectedGroup, key) => {
          groupIds.push(selectedGroup.id);
          groupAction = `${groupIds.toString()}`;
        });
        let action;
        action = "groups=" + groupAction;
        if (search.length > 0) {
          action = action + "&" + "search=" + search;
        }
        dispatch(
          EMDoGetMembersOfChannelByGroupFilterAction({
            id: window.sessionStorage.getItem("ChannelId"),
            limit: limitWhileFiltering,
            action: action,
          })
        );
      } else if (
        appliedFilters.length == 0 &&
        search.length == 0
      ) {
        setLimit(0);
        dispatch(
          EMDoGetMembersOfChannelAction({
            id: window.sessionStorage.getItem("Channel"),
            limit: limit,
          })
        );
      }
    } else {
      setLimitWhileSearching(0);
      dispatch(
        EMDoGetListOfChannelMembersBySearchAction({
          id: window.sessionStorage.getItem("ChannelId"),
          limit: limitWhileSearching,
          searchText: search,
        })
      );
    }
  }, [search]);

  const sendQuery = useCallback(async () => {
    if (search.length === 0 && selectedNetworks.length === 0) {
      dispatch(
        EMDoGetMembersOfChannelAction({
          id: window.sessionStorage.getItem("ChannelId"),
          limit: limit,
        })
      );
    } else if (search.length > 0 && selectedNetworks.length == 0) {
      dispatch(
        EMDoGetListOfChannelMembersBySearchAction({
          id: window.sessionStorage.getItem("ChannelId"),
          limit: limitWhileSearching,
          searchText: search,
        })
      );
    } else if (selectedNetworks.length > 0 && search.length == 0) {
      if (selectedNetworks.length == 1) {
        let selectedNetwork = selectedNetworks[0];
        let id = selectedNetwork.id;
        let action;
        action = "groups=" + id;
        dispatch(
          EMDoGetMembersOfChannelByGroupFilterAction({
            id: window.sessionStorage.getItem("ChannelId"),
            limit: limitWhileFiltering,
            action: action,
          })
        );
      } else if (selectedNetworks.length > 1) {
        let groupAction;
        // let organizationAction
        let groupIds = [];

        selectedNetworks.map((selectedGroup, key) => {
          groupIds.push(selectedGroup.id);
          groupAction = `${groupIds.toString()}`;
        });
        let action;
        action = "groups=" + groupAction;
        dispatch(
          EMDoGetMembersOfChannelByGroupFilterAction({
            id: window.sessionStorage.getItem("ChannelId"),
            limit: limitWhileFiltering,
            action: action,
          })
        );
      }
    } else if (selectedNetworks.length > 0 && search.length > 0) {
      if (selectedNetworks.length == 1) {
        let selectedNetwork = selectedNetworks[0];
        let id = selectedNetwork.id;
        let action;
        action = "groups=" + id;
        if (search.length > 0) {
          action = action + "&" + "search=" + search;
        }
        dispatch(
          EMDoGetMembersOfChannelByGroupFilterAction({
            id: window.sessionStorage.getItem("ChannelId"),
            limit: limitWhileFiltering,
            action: action,
          })
        );
      } else if (selectedNetworks.length > 1) {
        // let action
        let groupAction;
        // let organizationAction
        let groupIds = [];

        selectedNetworks.map((selectedGroup, key) => {
          groupIds.push(selectedGroup.id);
          groupAction = `${groupIds.toString()}`;
        });
        let action;
        action = "groups=" + groupAction;
        if (search.length > 0) {
          action = action + "&" + "search=" + search;
        }
        dispatch(
          EMDoGetMembersOfChannelByGroupFilterAction({
            id: window.sessionStorage.getItem("ChannelId"),
            limit: limitWhileFiltering,
            action: action,
          })
        );
      }
    }
  }, [limit, limitWhileSearching, limitWhileFiltering]);

  useEffect(() => {
    if (search.length === 0 && selectedNetworks.length === 0) {
      sendQuery(limit);
    } else if (search.length > 0 && selectedNetworks.length == 0) {
      sendQuery(limitWhileSearching);
    } else if (selectedNetworks.length > 0 || search.length > 0) {
      sendQuery(limitWhileFiltering);
    }
  }, [sendQuery, limit, limitWhileSearching, limitWhileFiltering]);

  const handleObserver = useCallback(
    (entries) => {
      if (search.length === 0 && selectedNetworks.length === 0) {
        setLimit((prev) => prev + 20);
        setLimitWhileFiltering(0);
        setLimitWhileSearching(0);
      } else if (search.length > 0 && selectedNetworks.length == 0) {
        setLimitWhileSearching((prev) => prev + 20);
        setLimit(0);
        setLimitWhileFiltering(0);
      } else if (selectedNetworks.length > 0 || search.length > 0) {
        setLimitWhileFiltering((prev) => prev + 20);
        setLimit(0);
        setLimitWhileSearching(0);
      }
    },
    [search, selectedNetworks]
  );

  function getCols(screenWidth) {
    if (isWidthUp("xl", screenWidth)) {
      return 4;
    } else if (isWidthUp("lg", screenWidth)) {
      return 4;
    } else if (isWidthUp("md", screenWidth)) {
      return 4;
    } else if (isWidthUp("sm", screenWidth)) {
      return 3;
    } else if (isWidthUp("xs", screenWidth)) {
      return 2;
    }
    return 4;
  }

  const cols = getCols(props.width);

  return (
    <InfinitScroll
      dataLength={data.members.length}
      next={handleObserver}
      hasMore={true}
    >
      <EMPage
        title="Channel Members"
        style={{
          width: "100%",
          paddingLeft: matchesLargeScreen ? "4%" : "2%",
          paddingTop: matchesLargeScreen
            ? "4%"
            : matchesMediumScreen
            ? "6%"
            : "8%",
          paddingRight: matchesLargeScreen ? "4%" : "2%",
          paddingBottom: "2%",
          backgroundColor: "#e7dadc",
        }}
      >
        <div id="page" ref={ref}>
          <Grid
            container
            direction="row"
            alignItems="flexStart"
            spacing={3}
            style={{}}
          >
            {matchesMediumScreen ? (
              <Grid item xs={matchesLargeScreen ? 2 : 3}>
                <EMSideBar />
              </Grid>
            ) : null}

            <Grid
              item
              xs={matchesLargeScreen ? 7 : matchesMediumScreen ? 9 : 12}
            >
              <Paper elevation={2}>
                <Grid container alignItems="center" style={{ padding: "2%" }}>
                  <Grid md={9} sm={8} xs={7}>
                    <Typography className={classes.title} variant={"h5"}>
                      Channel Members
                    </Typography>
                  </Grid>
                  <Grid md={3} sm={4} xs={5}>
                    <EMSearch
                      disabled={
                        data.isSearchDisabledChannelMembers ||
                        data.isFilterDisabledForMembers
                      }
                      data={search}
                      usedIn="browse_groups"
                      route={true}
                      hint={"Search"}
                      onChangeListener={(serachData) => {
                        setSearch(serachData);
                        dispatch({
                          type: DISABLE_ENABLE_SEARCH_CHANNEL_MEMBERS,
                          payload: true,
                        });
                      }}
                    />
                  </Grid>
                </Grid>

                <div className={classes.root}>
                  <GridList
                    cellHeight={160}
                    className={classes.gridList}
                    cols={cols}
                  >
                    {data.members &&
                      data.members.map((user) => (
                        <GridListTile
                          style={{
                            // minWidth: "18%",
                            height: "100%",
                            padding: "1%",
                          }}
                        >
                          <EMUser
                            user={user}
                            profileImage={
                              user.profile_pic_url && user.profile_pic_url
                                ? process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(
                                    0,
                                    -1
                                  ) + user.profile_pic_url
                                : profile_image
                            }
                            userFirstName={user.first_name}
                            userLastName={user.last_name}
                            designation={user.professional_title}
                            organisationName={user.organization_name}
                            email={user.email}
                            linkedinLink={
                              user.linkedin_profile_link &&
                              user.linkedin_profile_link
                            }
                            profileClickHandler={
                              () =>
                                navigation(`/users/people/${user.id}`, {
                                  state: {
                                    name: user.first_name,
                                    id: user.user_id,
                                  },
                                })
                              // dispatch(EMDoGetProfileDetailsAction(loginUserData.profile_id))
                            }
                          />
                        </GridListTile>
                      ))}
                  </GridList>
                </div>
              </Paper>
            </Grid>
            {matchesLargeScreen ? (
              <Grid item xs={3} spacing={2}>
                <Paper elevation={2} style={{ padding: "5%" }}>
                  <Grid container direction="row">
                    <Grid item xs={2}>
                      <LocalOffer fontSize="large" color="primary" />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography
                        style={{
                          color: "#2F92D6",
                          fontSize: "large",
                          fontWeight: "bolder",
                        }}
                      >
                        GROUP FILTER
                      </Typography>
                    </Grid>
                  </Grid>
                  <FormControl>
                    <Typography
                      style={{ fontWeight: "bolder", fontSize: "medium" }}
                    >
                      Internal Groups
                    </Typography>
                    {data.groupsForFilter &&
                      data.groupsForFilter.internal_groups.map((group, key) => (
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                              disabled={
                                data.isSearchDisabledChannelMembers ||
                                data.isFilterDisabledForMembers
                              }
                                checked={group.value}
                                name={group.group_name}
                                onChange={(event) => {
                                  dispatch({
                                    type: DISABLE_ENABLE_FILTER_FOR_CHANNEL_MEMBERS,
                                    payload: true,
                                  });
                                  let arr;
                                  if (event.target.checked) {
                                    let checkedValue = event.target.name;
                                    arr =
                                      selectedNetworks.length > 0
                                        ? [...selectedNetworks, group]
                                        : [group];
                                    setSelectedNetworks(arr);
                                    dispatch({
                                      type: CHANNEL_APPLY_GROUP_FILTER,
                                      payload: {
                                        checked_value: !group.value,
                                        postIndex: key,
                                        group_type: "internal_group",
                                      },
                                    });
                                  } else {
                                    //find this value in array and delete it
                                    let checkedValue = event.target.name;
                                    let index = selectedNetworks.findIndex(
                                      (x) => x.group_name == checkedValue
                                    );
                                    selectedNetworks.splice(index, 1);
                                    arr = selectedNetworks;
                                    dispatch({
                                      type: CHANNEL_APPLY_GROUP_FILTER,
                                      payload: {
                                        checked_value: !group.value,
                                        postIndex: key,
                                        group_type: "internal_group",
                                      },
                                    });
                                  }
                                  if (arr.length > 0) {
                                    //
                                    if (arr.length == 1) {
                                      setLimitWhileFiltering(0);
                                      let selectedNetwork = arr[0];
                                      let id = selectedNetwork.id;
                                      let action;
                                      action = "groups=" + id;
                                      if (search.length > 0) {
                                        action =
                                          action + "&" + "search=" + search;
                                      }
                                      dispatch({
                                        type: SHOW_NEXT_MEMBERS,
                                        payload: {
                                          nextMembers: 1,
                                        },
                                      });
                                      dispatch(
                                        EMDoGetMembersOfChannelByGroupFilterAction(
                                          {
                                            id: window.sessionStorage.getItem(
                                              "ChannelId"
                                            ),
                                            limit: limitWhileFiltering,
                                            action: action,
                                          }
                                        )
                                      );
                                    } else if (arr.length > 1) {
                                      setLimitWhileFiltering(0);
                                      // let action
                                      let groupAction;
                                      // let organizationAction
                                      let groupIds = [];

                                      arr.map((selectedGroup, key) => {
                                        groupIds.push(selectedGroup.id);
                                        groupAction = `${groupIds.toString()}`;
                                      });
                                      dispatch({
                                        type: SHOW_NEXT_MEMBERS,
                                        payload: {
                                          nextMembers: arr.length,
                                        },
                                      });
                                      let action;
                                      action = "groups=" + groupAction;
                                      if (search.length > 0) {
                                        action =
                                          action + "&" + "search=" + search;
                                      }
                                      dispatch(
                                        EMDoGetMembersOfChannelByGroupFilterAction(
                                          {
                                            id: window.sessionStorage.getItem(
                                              "ChannelId"
                                            ),
                                            limit: limitWhileFiltering,
                                            action: action,
                                          }
                                        )
                                      );
                                    }
                                  } else if (
                                    arr.length == 0 &&
                                    search.length == 0
                                  ) {
                                    dispatch({
                                      type: SHOW_NEXT_MEMBERS,
                                      payload: {
                                        nextMembers: 0,
                                      },
                                    });
                                    setLimit(0);
                                    dispatch(
                                      EMDoGetMembersOfChannelAction({
                                        id: data.singleChannel.id
                                          ? data.singleChannel.id
                                          : window.sessionStorage.getItem(
                                              "ChannelId"
                                            ),
                                        limit: limit,
                                      })
                                    );
                                  } else if (
                                    arr.length == 0 &&
                                    search.length > 0
                                  ) {
                                    setLimitWhileSearching(0);
                                    let action;
                                    if (search.length > 0) {
                                      action = "&" + "search=" + search;
                                    }
                                    dispatch(
                                      EMDoGetMembersOfChannelByGroupFilterAction(
                                        {
                                          id: window.sessionStorage.getItem(
                                            "ChannelId"
                                          ),
                                          limit: limitWhileSearching,
                                          action: action,
                                        }
                                      )
                                    );
                                  }
                                }}
                              />
                            }
                            label={group.group_name}
                            key={group.id}
                          />
                        </FormGroup>
                      ))}
                    <Typography
                      style={{ fontWeight: "bolder", fontSize: "medium" }}
                    >
                      Joint Groups
                    </Typography>
                    {data.groupsForFilter &&
                      data.groupsForFilter.joint_groups.map((group, key) => (
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                              disabled={
                                data.isSearchDisabledChannelMembers ||
                                data.isFilterDisabledForMembers
                              }
                                checked={group.value}
                                name={group.group_name}
                                onChange={(event) => {
                                  dispatch({
                                    type: DISABLE_ENABLE_FILTER_FOR_CHANNEL_MEMBERS,
                                    payload: true,
                                  });
                                  let arr;
                                  if (event.target.checked) {
                                    let checkedValue = event.target.name;
                                    arr =
                                      selectedNetworks.length > 0
                                        ? [...selectedNetworks, group]
                                        : [group];
                                    setSelectedNetworks(arr);
                                    dispatch({
                                      type: CHANNEL_APPLY_GROUP_FILTER,
                                      payload: {
                                        checked_value: !group.value,
                                        postIndex: key,
                                        group_type: "joint_group",
                                      },
                                    });
                                  } else {
                                    //find this value in array and delete it
                                    let checkedValue = event.target.name;
                                    let index = selectedNetworks.findIndex(
                                      (x) => x.group_name == checkedValue
                                    );
                                    selectedNetworks.splice(index, 1);
                                    arr = selectedNetworks;
                                    dispatch({
                                      type: CHANNEL_APPLY_GROUP_FILTER,
                                      payload: {
                                        checked_value: !group.value,
                                        postIndex: key,
                                        group_type: "joint_group",
                                      },
                                    });
                                  }
                                  if (arr.length > 0) {
                                    //
                                    if (arr.length == 1) {
                                      setLimitWhileFiltering(0);
                                      let selectedNetwork = arr[0];
                                      let id = selectedNetwork.id;
                                      let action;
                                      action = "groups=" + id;
                                      if (search.length > 0) {
                                        action =
                                          action + "&" + "search=" + search;
                                      }
                                      dispatch({
                                        type: SHOW_NEXT_MEMBERS,
                                        payload: {
                                          nextMembers: 1,
                                        },
                                      });
                                      dispatch(
                                        EMDoGetMembersOfChannelByGroupFilterAction(
                                          {
                                            id: window.sessionStorage.getItem(
                                              "ChannelId"
                                            ),
                                            limit: limitWhileFiltering,
                                            action: action,
                                          }
                                        )
                                      );
                                    } else if (arr.length > 1) {
                                      setLimitWhileFiltering(0);
                                      // let action
                                      let groupAction;
                                      // let organizationAction
                                      let groupIds = [];

                                      arr.map((selectedGroup, key) => {
                                        groupIds.push(selectedGroup.id);
                                        groupAction = `${groupIds.toString()}`;
                                      });
                                      dispatch({
                                        type: SHOW_NEXT_MEMBERS,
                                        payload: {
                                          nextMembers: arr.length,
                                        },
                                      });
                                      let action;
                                      action = "groups=" + groupAction;
                                      if (search.length > 0) {
                                        action =
                                          action + "&" + "search=" + search;
                                      }
                                      dispatch(
                                        EMDoGetMembersOfChannelByGroupFilterAction(
                                          {
                                            id: window.sessionStorage.getItem(
                                              "ChannelId"
                                            ),
                                            limit: limitWhileFiltering,
                                            action: action,
                                          }
                                        )
                                      );
                                    }
                                  } else if (
                                    arr.length == 0 &&
                                    search.length == 0
                                  ) {
                                    dispatch({
                                      type: SHOW_NEXT_MEMBERS,
                                      payload: {
                                        nextMembers: 0,
                                      },
                                    });
                                    setLimit(0);
                                    dispatch(
                                      EMDoGetMembersOfChannelAction({
                                        id: data.singleChannel.id
                                          ? data.singleChannel.id
                                          : window.sessionStorage.getItem(
                                              "ChannelId"
                                            ),
                                        limit: limit,
                                      })
                                    );
                                  } else if (
                                    arr.length == 0 &&
                                    search.length > 0
                                  ) {
                                    setLimitWhileSearching(0);
                                    let action;
                                    if (search.length > 0) {
                                      action = "&" + "search=" + search;
                                    }
                                    dispatch(
                                      EMDoGetMembersOfChannelByGroupFilterAction(
                                        {
                                          id: window.sessionStorage.getItem(
                                            "ChannelId"
                                          ),
                                          limit: limitWhileSearching,
                                          action: action,
                                        }
                                      )
                                    );
                                  }
                                }}
                              />
                            }
                            label={group.group_name}
                            key={group.id}
                          />
                        </FormGroup>
                      ))}
                  </FormControl>
                </Paper>
              </Grid>
            ) : null}
          </Grid>
        </div>
      </EMPage>
    </InfinitScroll>
  );
}
export default withWidth()(EMChannelMembersPage);
