import axios from "axios";
import networkAdmin from "../../networkAdmin";

//Get Get All My Networks
const EMDoGetAllMyNetworks = () => {
  let url = `user/partnerships/`;
  return axios.get(url);
};
const EMDoGetDetailsForNetwork = (id) => {
  let url = `user/partnerships/${id}/`;
  return axios.get(url);
};
const EMDoGetAllGroupsForNetwork = (id) => {
  let url = `user/partnerships-with-groups/?partnerships=${id}`;
  return axios.get(url);
};
const EMDoGetAllChannelsForNetwork = (id) => {
  let url = `user/partnerships-with-channels/?partnerships=${id}`;
  return axios.get(url);
};
const EMDoGetFilterForNetwork = () => {
  let url = `user/organizations`;
  return axios.get(url);
};
// Get Organization Details
const EMDoGetOrganizationdetails = () => {
  let url = `user/organization-info/`;
  return axios.get(url);
};
//Get network by group Filter
const EMDoGetNetworkByOrganizationFilter = (id) => {
  let url;
  url = `user/partnerships/?organizations=${id}`;
  return axios.get(url);
};

const EMDoGetActionsListForDropdown = () => {
  let url;
  url = `gamification/list-actions-for-dropdown/`;
  return axios.get(url);
};

const EMDoGetActionsForOrganization = (queryData) => {
  let url;
  url = `gamification/list-org-actions/${queryData}`;
  return axios.get(url);
};

const EMDoCreateActionsForOrganization = (organizationId, data) => {
  let url;
  
  url = `gamification/list-org-actions/retrieve-update/${organizationId}/`;
  return axios.post(url, data);
};

const EMDoUpdateActionsForOrganization = (actionId, data) => {
  let url;  
  url = `gamification/list-org-actions/retrieve-update/${actionId}/`;
  return axios.put(url, data);
};

const EMDoDeleteActionsForOrganization = (actionId, data) => {
  let url;
  
  url = `gamification/list-org-actions/retrieve-update/${actionId}/`;
  return axios.delete(url);
};

export default {
  EMDoGetAllMyNetworks,
  EMDoGetDetailsForNetwork,
  EMDoGetAllChannelsForNetwork,
  EMDoGetAllGroupsForNetwork,
  EMDoGetFilterForNetwork,
  EMDoGetNetworkByOrganizationFilter,
  EMDoGetOrganizationdetails,
  EMDoGetActionsListForDropdown,
  EMDoGetActionsForOrganization,
  EMDoCreateActionsForOrganization,
  EMDoUpdateActionsForOrganization,
  EMDoDeleteActionsForOrganization
};
