import React from "react";
import { TextField, Chip, Avatar, Typography, Box } from "@material-ui/core";

export function EMSimpleTextFieldView(props) {
  return (
    <TextField
      {...props}
      style={{ ...props.style, width: "100%" }}
      variant="standard"
      label={props.label}
      InputLabelProps={{style:{color:"#4d4d4d"}}}
      FormHelperTextProps={{style:{color:"red"}}} /* InputProps={{ style: {fontFamily: "Arial" } }} */
    />
  );
}

export function EMChipTextFieldView(props) {
  return (
    <Box width="100%">
      <Typography
        style={{ fontWeight: "bold", fontSize: 12, marginBottom: 5 }}
        variant="h4"
      >
        {props.label}
      </Typography>
      <TextField
        disabled={props.disabled}
        required={props.required}
        value={props.item}
        onKeyDown={props.handleKeyDown}
        onChange={props.handleChipChange}
        fullWidth
        multiline
        InputProps={{
          startAdornment: (
            <Chip
              avatar={<Avatar alt={props.item.charAt(0)} src="" />}
              label={props.item}
              onDelete={() => props.handleDelete()}
              //InputLableProps={{ style: {fontFamily: "Arial" } }}
            />
          ),
        }}
        //InputProps={{ style: {fontFamily: "Arial" } }}
      />
    </Box>
  );
}
