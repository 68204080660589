import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Card, Box, Paper } from "@material-ui/core/";
import CardActionArea from "@material-ui/core/CardActionArea";
import { CardHeader, Tooltip } from "@material-ui/core";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CreateIcon from "@material-ui/icons/Create";
import { useDispatch } from "react-redux";
import { SHOW_POST_EDIT } from "../../redux/constants/UserPlatform";
import bannerImage from "../../../src/assets/event_banner.png";
import { Divide } from "react-feather";

const useStyles = makeStyles((theme) => ({
  root: {
    // margin: "2%",
    width: "100%",
    height: "-webkit-fill-available",
  },
  media: {
    maxWidth: "100%",
    [(theme.breakpoints.up("xl"))]:{
      height:300
    },
    [(theme.breakpoints.between("lg", "xl"))]:{
      height:300
    },
    [(theme.breakpoints.between("md", "lg"))]:{
      height:300
    },
    [(theme.breakpoints.between("sm", "md"))]:{
      height:200
    },
    [(theme.breakpoints.between("xs", "sm"))]:{
      height:150
    },
  },
}));

export default function EMLinkPreview({
  url,
  preview_image,
  preview_title,
  preview_description,
  style
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <a
      href={url}
      rel="noreferrer"
      target="_blank"
      onClick="javascript.function();"
      style={{ textDecoration: "none", }}
    >
      <Paper elevation={2} style={style}>
        <Grid container>
          <Card className={classes.root}>
            {preview_image ? (
              <div className={classes.media}>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                    // backgroundColor: "#d3d3d3",
                  }}
                >
                  <span
                    style={{
                      display: "inline-block",
                      height: "100%",
                      verticalAlign: "middle",
                    }}
                  ></span>

                  <img
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      verticalAlign: "middle",
                    }}
                    src={preview_image}
                    alt={"Event Banner"}
                  />
                </div>
              </div>
            ) : null}

            <Grid
              conatiner
              direction="row"
              style={{ display: "flex", padding: "2%" }}
            >
              <Grid item xs={12}>
                <Box alignItems="flex-end" display="flex">
                  <Typography style={{ fontWeight: "bolder" }}>
                    {preview_title}
                  </Typography>
                </Box>
                <Box alignItems="flex-end" display="flex">
                  <Typography style={{ fontWeight: "normal" }}>
                    {preview_description}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Paper>
    </a>
  );
}
