import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import { MoreHoriz } from "@material-ui/icons";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import Parse from "html-react-parser";
import EMDialogBox from "../../components/commans/EMDialogBox";
import DeleteIcon from "@material-ui/icons/Delete";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import ReactPlayer from "react-player";
import { useLocation, useNavigate } from "react-router";
import InfoIcon from "@material-ui/icons/Info";
import "react-quill/dist/quill.snow.css";
// import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import {
  CardActions,
  SvgIcon,
  Paper,
  Menu,
  MenuItem,
  Box,
  ListItemIcon,
  Modal,
  GridList,
  Grid,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import { useSelector, useDispatch } from "react-redux";
import EMCreatePost from "../../views/UserPlatform/Home/LatestPost/EMCreatePost";
import { ReactTinyLink } from "react-tiny-link";
import {
  USER_EXPAND_TEXT,
  USER_EXPAND_COMMENT,
  USER_SHOW_COMMENT,
  POST_SHOW_MORE,
  SHOW_POST_EDIT,
  POST_SHOW_MORE_SELECTED,
  SHOW_POST_MEDIA_PREVIEW,
  OPEN_DELETE_CONFIRMATION_DIALOG,
  PLAY_VIDEO_BY_ID,
} from "../../redux/constants/UserPlatform/index";
import EMPostContent from "./EMPostContent";

import {
  EMDoDeletePostAction,
  EMDoDeleteCommentFromPostAction,
  EMDoGetPostSharedWithGroupsListAction,
} from "../../redux/actions/UserPlatform/Home/EMLatestPostPageAction";
import EMAddNewComment from "./EMAddNewComment";
import EMLinkPreview from "./EMLinkPreview";
import { EMGetRolesAndPermissionsBasedOnEntityAction } from "../../redux/actions/EMAuthActions";
// import classes from "*.module.css";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "0.5%",
  },
  addComment: {
    width: "100%",
    display: "flex",
    height: "auto",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: red[500],
  },
  title: {
    fontWeight: "bolder",
    fontSize: "14px",
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 1,
    wordBreak: "break-word",
    overflow: "hidden",
  },
  groupAvtar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  like: {
    "&:hover": {
      color: "auqa",
      cursor: "pointer",
    },
  },
  comment: {
    "&:hover": {
      color: "auqa",
      cursor: "pointer",
    },
  },
  editPost: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "80%",
    marginLeft: "10%",
  },
  myTextStyle: {
    textDecoration: "none",
    color: "blue",
    float: "right",
    marginTop: "2%",
    // marginRight: "3%",
    "&:hover": {
      cursor: "pointer",
    },
  },
  myTextStyle2: {
    textDecoration: "none",
    color: "blue",
    float: "right",
    // marginRight: "3%",
    "&:hover": {
      cursor: "pointer",
    },
  },
  postMedia: {
    margin: "1%",
    padding: "1%",
  },
  currentMediaOfPost: {
    "&:hover": {
      opacity: "0.3",
    },
  },
  textStyle: {
    paddingRight: "2%",
    float: "right",
    "&:hover": {
      cursor: "pointer",
    },
  },
  typo: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 1,
    wordBreak: "break-word",
    overflow: "hidden",
  },
}));

export default function EMPost(
  { id, post, pinHandler, likeHandler, permission, usedIn, searchedPost },
  ...props
) {
  const classes = useStyles();
  const data = useSelector((state) => state.home || {});
  const userData = useSelector((state) => state.auth);
  const channelData = useSelector((state) => state.channels);
  const anchorRef = React.useRef(null);
  const [postMoreoptions, setPostmoreoption] = React.useState([]);
  const location = useLocation();
  const [pinPermission, setPinPermission] = React.useState(false);
  const navigation = useNavigate();
  const [selectedMediaId, setSelectedMediaId] = useState()

  const theme = useTheme();
  const matchesSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  React.useEffect(() => {
    if (post.postFrom == "channel" || id) {
      if (
        post.permissions &&
        post.permissions.AddFlag_PinPostsUnderChannel == true
      ) {
        setPinPermission(true);
      } else {
        setPinPermission(false);
      }
    } else {
      if (
        post.permissions &&
        post.permissions.AddFlag_PinPostsUnderGroup == true
      ) {
        setPinPermission(true);
      } else {
        setPinPermission(false);
      }
    }
  }, [post]);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  function ChangeDate(date) {
    let newDate = new Date(date);
    var hours = newDate.getHours();
    var minutes = newDate.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    let dateToDisplay =
      newDate.getDate() +
      " " +
      monthNames[newDate.getMonth()] +
      " " +
      newDate.getFullYear() +
      " " +
      strTime;

    return dateToDisplay;
  }

  React.useEffect(() => {
    if (post.postFrom == "channel" || id) {
      if (
        post.permissions &&
        post.permissions.AddFlag_CreatePostsUnderChannel == true
      ) {
        setPostmoreoption(channelData.postMoreOptions);
      } else {
        setPostmoreoption([{ name: "Delete Post", icon: <DeleteIcon /> }]);
      }
    } else {
      if (
        post.permissions &&
        post.permissions.AddFlag_CreatePostsUnderGroup == true
      ) {
        setPostmoreoption(data.postMoreOptions);
      } else {
        setPostmoreoption([{ name: "Delete Post", icon: <DeleteIcon /> }]);
      }
    }
  }, [post]);

  function PinPermissionRenderer() {
    if (pinPermission) {
      if (!matchesSmallScreen) {
        return post.isPinnedPost ? (
          <Tooltip title={<h2>Unpin post</h2>} placement="top">
            <IconButton aria-label="pin" onClick={pinHandler}>
              <PinIcon color="secondary" />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title={<h2>Pin post</h2>} placement="top">
            <IconButton aria-label="pin" onClick={pinHandler}>
              <PinIcon style={{ color: "#C8CDD0" }} />
            </IconButton>
          </Tooltip>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  const dispatch = useDispatch();
  return (
    <div style={{ marginBottom: "3.5%", fontSize: "large" }}>

      <Card elevation={2} className={classes.root}>
        <CardHeader
          avatar={
            <Avatar
              onClick={() => navigation(`/users/people/${post.user_id}`)}
              aria-label="recipe"
              className={classes.avatar}
              style={{ width: "48px", height: "48px", cursor: "pointer" }}
              alt={post.userName.charAt(0).toUpperCase()}
              src={
                post.user_profile &&
                process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(0, -1) +
                  post.user_profile
              }
            ></Avatar>
          }
          action={
            searchedPost ? null : (
              <>
                <PinPermissionRenderer />
                {!matchesSmallScreen ? (
                  <IconButton aria-label="settings">{post.category}</IconButton>
                ) : null}
                {post.owner ? (
                  <>
                    <IconButton
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      aria-label="settings"
                      ref={anchorRef}
                      onClick={() => {
                        dispatch({
                          type: POST_SHOW_MORE,
                          payload: { id: post.id, showMore: true },
                        });
                        dispatch(EMDoGetPostSharedWithGroupsListAction());
                      }}
                    >
                      <MoreHoriz />
                    </IconButton>
                    <EMDialogBox
                      shouldOpen={post.shouldOpen}
                      onSelect={(shouldProcess) => {
                        if (shouldProcess) {
                          let queryData = {
                            id: post.id,
                            action: `delete`,
                          };
                          dispatch(
                            EMDoDeletePostAction({ queryData, isEvent: false })
                          );
                        } else {
                          dispatch({
                            type: OPEN_DELETE_CONFIRMATION_DIALOG,
                            payload: { id: post.id, shouldOpen: false },
                          });
                        }
                        dispatch({
                          type: OPEN_DELETE_CONFIRMATION_DIALOG,
                          payload: { id: post.id, shouldOpen: false },
                        });
                      }}
                      dialogText={"Are you sure you want to delete the post ?"}
                    />
                    <Menu
                      aria-controls="customized-menu"
                      getContentAnchorEl={null}
                      anchorEl={anchorRef.current}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      open={post.showMore}
                      onClose={() => {
                        dispatch({
                          type: POST_SHOW_MORE,
                          payload: { id: post.id, showMore: false },
                        });
                      }}
                    >
                      <div>
                        {postMoreoptions.map((option) => (
                          <MenuItem
                            onClick={() => {
                              if (
                                String(option.name).includes("Edit Post") ||
                                String(option.name).includes(
                                  "Share with Groups"
                                )
                              ) {
                                dispatch({
                                  type: POST_SHOW_MORE_SELECTED,
                                  payload: {
                                    id: post.id,
                                    option: option.name,
                                    args: {
                                      editPost: true,
                                      title: "Edit Post",
                                      button: "Update Post",
                                    },
                                  },
                                });
                              } else if (
                                String(option.name).includes("Delete Post")
                              ) {
                                dispatch({
                                  type: OPEN_DELETE_CONFIRMATION_DIALOG,
                                  payload: { id: post.id, shouldOpen: true },
                                });
                              }
                            }}
                          >
                            <ListItemIcon>{option.icon}</ListItemIcon>
                            <Typography variant="inherit">
                              {option.name}
                            </Typography>
                          </MenuItem>
                        ))}
                        {matchesSmallScreen ? (
                          <MenuItem onClick={pinHandler}>
                            <ListItemIcon>
                              {post.isPinnedPost ? (
                                <PinIcon color="secondary" />
                              ) : (
                                <PinIcon style={{ color: "#C8CDD0" }} />
                              )}
                            </ListItemIcon>
                            <Typography variant="inherit">
                              {post.isPinnedPost ? "Unpin Post" : "Pin Post"}
                            </Typography>
                          </MenuItem>
                        ) : null}
                      </div>
                    </Menu>
                  </>
                ) : null}
              </>
            )
          }
          title={
            !matchesSmallScreen ? (
              <Box align="center" display="flex">
                <Box align="start">
                  <Typography
                    className={classes.title}
                    style={{ cursor: "pointer" }}
                    onClick={() => navigation(`/users/people/${post.user_id}`)}
                  >
                    {post.userName},&nbsp;
                  </Typography>
                </Box>
                <Box align="start">
                  <Typography
                    style={{ fontSize: "small" }}
                    className={classes.typo}
                  >
                    {post.organisationName}
                  </Typography>
                </Box>
                {id || post.postFrom == "channel" ? null : (
                  <>
                    <Box alignItems="center">
                      <ChevronRightIcon />
                    </Box>
                    <Box alignItems="center" paddingRight={0.8}>
                      <Avatar
                        className={classes.groupAvtar}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          dispatch(
                            EMGetRolesAndPermissionsBasedOnEntityAction({
                              entity: "group",
                              entityId: post.group_id,
                            })
                          );
                          navigation("/users/groups/activity", {
                            state: { GroupId: post.group_id },
                          });
                          window.sessionStorage.setItem(
                            "GroupId",
                            post.group_id
                          );
                        }}
                        src={
                          post.groupLogo && post.groupLogo.includes("localhost")
                            ? post.groupLogo
                            : process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(
                                0,
                                -1
                              ) + post.groupLogo
                        }
                      ></Avatar>
                    </Box>
                    <Box align="start">
                      <Tooltip
                        title={
                          <h2 style={{ color: "white" }}>{post.groupName}</h2>
                        }
                        placement="top"
                      >
                        <Typography
                          className={classes.title}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            dispatch(
                              EMGetRolesAndPermissionsBasedOnEntityAction({
                                entity: "group",
                                entityId: post.group_id,
                              })
                            );
                            navigation("/users/groups/activity", {
                              state: { GroupId: post.group_id },
                            });
                            window.sessionStorage.setItem(
                              "GroupId",
                              post.group_id
                            );
                          }}
                        >
                          {post.groupName}
                        </Typography>
                      </Tooltip>
                    </Box>
                  </>
                )}
              </Box>
            ) : (
              <Box alignItems="center">
                <Box display="flex">
                  <Box align="start">
                    <Typography
                      className={classes.title}
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigation(`/users/people/${post.user_id}`)
                      }
                    >
                      {post.userName},&nbsp;
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      style={{ fontSize: "small" }}
                      className={classes.typo}
                    >
                      {post.organisationName}
                    </Typography>
                  </Box>
                </Box>
                {post.postFrom == "channel" ? null : (
                  <Box>
                    <Typography className={classes.title}>
                      {post.groupName}
                    </Typography>
                  </Box>
                )}
              </Box>
            )
          }
          subheader={
            <>
              <Typography color="textPrimary">{post.designation}</Typography>
              <Typography color="textSecondary" style={{ fontSize: "small" }}>
                {post.postCreatedOn && ChangeDate(post.postCreatedOn)}
              </Typography>
            </>
          }
        />
        <CardContent style={{ paddingTop: "10px" }}>
          {post && post.expandText ? (
            <>
              <Typography paragraph="true">
                <div
                  className="ql-editor"
                  style={{ padding: 0, overflow: "hidden" }}
                >
                  {Parse(post.postText)}
                </div>

                <a
                  className={classes.myTextStyle}
                  onClick={() => {
                    dispatch({
                      type: USER_EXPAND_TEXT,
                      payload: {
                        id: post.id,
                        content: false,
                        is_event: post.is_event,
                      },
                    });
                  }}
                >
                  {post.postText.length > 250 ? " ...See Less" : null}
                </a>
              </Typography>
              {post.thumbnail_link !== null &&
              post.thumbnail_link.thumbnail_link.length !== 0 ? (
                <EMLinkPreview
                  style={{ marginTop: "5%" }}
                  preview_description={post.thumbnail_link.description}
                  preview_image={post.thumbnail_link.image}
                  preview_title={post.thumbnail_link.title}
                  url={post.thumbnail_link.thumbnail_link}
                />
              ) : null}
            </>
          ) : (
            <>
              <Typography paragraph="true" style={{ marginBottom: "0px" }}>
                {post.postText ? (
                  <>
                    <div
                      className="ql-editor"
                      style={{ padding: 0, overflow: "hidden" }}
                    >
                      {Parse(post.postText.substring(0, 250))}
                    </div>
                    {post.postText && post.postText.length > 250 ? (
                      <a
                        className={classes.myTextStyle}
                        onClick={() => {
                          dispatch({
                            type: USER_EXPAND_TEXT,
                            payload: {
                              id: post.id,
                              content: true,
                              is_event: post.is_event,
                            },
                          });
                        }}
                      >
                        {post.postText.length > 250 ? " ...See More" : null}
                      </a>
                    ) : null}{" "}
                  </>
                ) : (
                  ""
                )}
              </Typography>
              {post.thumbnail_link !== null &&
              post.thumbnail_link.thumbnail_link.length !== 0 ? (
                <EMLinkPreview
                  style={{ marginTop: "5%" }}
                  preview_description={post.thumbnail_link.description}
                  preview_image={post.thumbnail_link.image}
                  preview_title={post.thumbnail_link.title}
                  url={post.thumbnail_link.thumbnail_link}
                />
              ) : null}
            </>
          )}
        </CardContent>
        <div>
          {post.documents &&
            post.documents.map((media, key) => (
              <Card className={classes.postMedia} id="document1">
                <a
                  href={
                    process.env.REACT_APP_BACKEND_FILE_PREVIEW + media.file_name
                  }
                  style={{ color: "blue", fontSize: "small" }}
                  target="_blank"
                >
                  {media &&
                    media.file_name &&
                    media.file_name.substr(
                      media && media.file_name.lastIndexOf("/") + 1,
                      media && media.length
                    )}
                </a>
              </Card>
            ))}
          <ImageList
            rowHeight={"10%"}
            style={{
              marginTop:
                post.totalCombinedMedia &&
                post.postText &&
                post.postText.length > 250 &&
                post.totalCombinedMedia.length > 0
                  ? "3%"
                  : "0%",
            }}
          >
            {post.totalCombinedMedia &&
              post.totalCombinedMedia.map((media, key) =>
                key < 6 ? (
                  <ImageListItem
                    key={1}
                    cols={
                      (post.totalCombinedMedia &&
                        post.totalCombinedMedia.length == 1) ||
                      matchesSmallScreen
                        ? 2
                        : 1
                    }
                  >
                    {media.type == "images" ? (
                      <div
                        style={{
                          width: "99%",
                          height: "100%",
                          whiteSpace: "nowrap",
                          textAlign: "center",
                          // backgroundColor: "#d3d3d3",
                        }}
                        onClick={() => {
                          if (!matchesSmallScreen) {
                            setSelectedMediaId(key)
                            dispatch({
                              type: SHOW_POST_MEDIA_PREVIEW,
                              payload: {
                                id: post.id,
                                showPreview: true,
                                is_event: post.is_event,
                              },
                            });
                          }
                        }}
                      >
                        <span
                          style={{
                            display: "inline-block",
                            height: "100%",
                            verticalAlign: "middle",
                          }}
                        ></span>
                        <img
                          className={classes.currentMediaOfPost}
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            verticalAlign: "middle",
                          }}
                          onMouseEnter={() => {}}
                          src={
                            process.env.REACT_APP_BACKEND_FILE_PREVIEW +
                            media.file_name
                          }
                          alt={"df"}
                        />
                      </div>
                    ) : media.type == "videos" ? (
                      <div
                        style={{ position: "relative", paddingTop: "56.25%" }}
                      >
                        <ReactPlayer
                          width="100%"
                          controls={true}
                          playing={media.isPlaying}
                          onEnded={() => {
                            dispatch({
                              type: PLAY_VIDEO_BY_ID,
                              payload: {
                                postId: post.id,
                                videoId: media.id,
                                media: {
                                  file_name: media.file_name,
                                  file_path: media.file_path,
                                  id: media.id,
                                  isPlaying: false,
                                  type: media.type,
                                },
                                fromPreview: false,
                                is_event: post.is_event,
                              },
                            });
                          }}
                          onPause={() => {
                            dispatch({
                              type: PLAY_VIDEO_BY_ID,
                              payload: {
                                postId: post.id,
                                videoId: media.id,
                                media: {
                                  file_name: media.file_name,
                                  file_path: media.file_path,
                                  id: media.id,
                                  isPlaying: false,
                                  type: media.type,
                                },
                                fromPreview: false,
                                is_event: post.is_event,
                              },
                            });
                          }}
                          onPlay={() => {
                            dispatch({
                              type: PLAY_VIDEO_BY_ID,
                              payload: {
                                postId: post.id,
                                videoId: media.id,
                                media: {
                                  file_name: media.file_name,
                                  file_path: media.file_path,
                                  id: media.id,
                                  isPlaying: true,
                                  type: media.type,
                                },
                                fromPreview: false,
                                is_event: post.is_event,
                              },
                            });
                          }}
                          height="100%"
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                          }}
                          url={
                            process.env.REACT_APP_BACKEND_FILE_PREVIEW +
                            media.file_name
                          }
                        />
                      </div>
                    ) : media.type === "video_link" ? (
                      <div
                        style={{ position: "relative", paddingTop: "56.25%" }}
                      >
                        <ReactPlayer
                          config={{
                            youtube: {
                              playerVars: { origin: "https://www.youtube.com" },
                            },
                          }}
                          width="100%"
                          height="100%"
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                          }}
                          controls={true}
                          playing={media.isPlaying}
                          onEnded={() => {
                            dispatch({
                              type: PLAY_VIDEO_BY_ID,
                              payload: {
                                postId: post.id,
                                videoId: media.id,
                                media: {
                                  file_name: media.file_name,
                                  file_path: media.file_path,
                                  id: media.id,
                                  isPlaying: false,
                                  type: media.type,
                                },
                                fromPreview: false,
                                is_event: post.is_event,
                              },
                            });
                          }}
                          onPause={() => {
                            dispatch({
                              type: PLAY_VIDEO_BY_ID,
                              payload: {
                                postId: post.id,
                                videoId: media.id,
                                media: {
                                  file_name: media.file_name,
                                  file_path: media.file_path,
                                  id: media.id,
                                  isPlaying: false,
                                  type: media.type,
                                },
                                fromPreview: false,
                                is_event: post.is_event,
                              },
                            });
                          }}
                          onPlay={() => {
                            dispatch({
                              type: PLAY_VIDEO_BY_ID,
                              payload: {
                                postId: post.id,
                                videoId: media.id,
                                media: {
                                  file_name: media.file_name,
                                  file_path: media.file_path,
                                  id: media.id,
                                  isPlaying: true,
                                  type: media.type,
                                },
                                fromPreview: false,
                                is_event: post.is_event,
                              },
                            });
                          }}
                          url={media.file_path}
                        />
                      </div>
                    ) : null}
                  </ImageListItem>
                ) : null
              )}
          </ImageList>
        </div>
        <div>
          {post.totalCombinedMedia &&
          post.totalCombinedMedia.length > 6 &&
          !matchesSmallScreen ? (
            // <Card className={classes.postMedia}>
            <Typography className={classes.textStyle}>
              <a
                style={{ color: "blue" }}
                onClick={() => {
                  dispatch({
                    type: SHOW_POST_MEDIA_PREVIEW,
                    payload: {
                      id: post.id,
                      showPreview: true,
                      is_event: post.is_event,
                    },
                  });
                }}
              >
                {" ...View all media"}
              </a>
            </Typography>
          ) : // </Card>
          null}
        </div>
        <Dialog
          open={post.mediaView}
          onClose={() => {
            dispatch({
              type: SHOW_POST_MEDIA_PREVIEW,
              payload: {
                id: post.id,
                showPreview: false,
                is_event: post.is_event,
              },
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="lg"
        >
          <DialogContent
            style={{
              height: "100%",
              width: "100%",
              overflow: "hidden",
              padding: 0,
            }}
          >
            {" "}
            <EMPostContent
              selectedMediaId={selectedMediaId}
              post={post}
              likehandler={likeHandler}
              permission={permission}
              searched={searchedPost}
              id={id}
            />
          </DialogContent>
        </Dialog>
        <CardActions>
          {post.liked ? (
            <>
              <ThumbUpAltIcon
                color="primary"
                className={classes.like}
                onClick={post.likeButtonDisabled ? () => {} : likeHandler}
              />

              <Typography>{post.likes} Likes</Typography>
            </>
          ) : (
            <>
              <ThumbUpAltIcon
                color="action"
                className={classes.like}
                onClick={post.likeButtonDisabled ? () => {} : likeHandler}
              />

              <Typography>{post.likes} Likes</Typography>
            </>
          )}
          {post.comments && post.comments.length > 0 ? (
            <>
              <ChatBubbleIcon
                color="primary"
                className={classes.comment}
                onClick={() => {
                  dispatch({
                    type: USER_SHOW_COMMENT,
                    payload: {
                      id: post.id,
                      content: !post.showComments,
                      is_event: post.is_event,
                    },
                  });
                }}
              />
              <Typography
                style={{ cursor: "pointer" }}
                onClick={() => {
                  dispatch({
                    type: USER_SHOW_COMMENT,
                    payload: {
                      id: post.id,
                      content: !post.showComments,
                      is_event: post.is_event,
                    },
                  });
                }}
              >
                {post.comments.length} Comments
              </Typography>
            </>
          ) : (
            <>
              <ChatBubbleIcon color="action" className={classes.comment} />
              <Typography>
                {post.comments && post.comments.length} Comments
              </Typography>
            </>
          )}
        </CardActions>
        {post.showComments
          ? post.comments &&
            post.comments.map((comment) => {
              return (
                <Card elevation={2} style={{ width: "100%" }}>
                  <CardHeader
                    avatar={
                      <Avatar
                        aria-label="recipe"
                        className={classes.avatar}
                        style={{ width: "48px", height: "48px" }}
                        src={
                          process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.substr(
                            0,
                            process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.length -
                              1
                          ) + comment.profileIcon
                        }
                        alt={
                          comment.first_name &&
                          comment.first_name.charAt(0).toUpperCase()
                        }
                      ></Avatar>
                    }
                    title={
                      <Box align="center" display="flex">
                        <Box align="start">
                          <Typography className={classes.title}>
                            {comment.first_name} {comment.last_name},&nbsp;
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            style={{ fontSize: "small" }}
                            className={classes.typo}
                          >
                            {comment.organisationName}
                          </Typography>
                        </Box>
                      </Box>
                    }
                    subheader={
                      <>
                        <Typography color="textPrimary">
                          {comment.designation}
                        </Typography>
                        {matchesSmallScreen ? (
                          <Box>
                            <Typography
                              color="textSecondary"
                              style={{ fontSize: "small" }}
                            >
                              {comment.commentedOn &&
                                ChangeDate(comment.commentedOn)}
                            </Typography>
                          </Box>
                        ) : null}
                      </>
                    }
                    action={
                      <Box
                        alignItems="center"
                        display="flex"
                        justifyContent="space-between"
                      >
                        {!matchesSmallScreen ? (
                          <Box>
                            <Typography
                              color="textSecondary"
                              style={{ fontSize: "small" }}
                            >
                              {comment.commentedOn &&
                                ChangeDate(comment.commentedOn)}
                            </Typography>
                          </Box>
                        ) : null}

                        <Box>
                          {comment.user_id == userData.profile_id &&
                          !searchedPost ? (
                            <IconButton
                              style={{ marginLeft: "10%" }}
                              onClick={() => {
                                let data = {
                                  postId: post.id,
                                  commentId: comment.comment_id,
                                };
                                dispatch(EMDoDeleteCommentFromPostAction(data));
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          ) : null}
                        </Box>
                      </Box>
                    }
                  />
                  <CardContent style={{ paddingTop: "0px" }}>
                    {post.expandPostComment ? (
                      <Typography>
                        {Parse(comment.comment)}
                        <a
                          className={classes.myTextStyle2}
                          onClick={() => {
                            dispatch({
                              type: USER_EXPAND_COMMENT,
                              payload: {
                                id: post.id,
                                content: false,
                                is_event: post.is_event,
                              },
                            });
                          }}
                        >
                          {comment.comment.length > 250 ? " ...See Less" : null}
                        </a>
                      </Typography>
                    ) : (
                      <Typography>
                        {Parse(
                          comment.comment
                            ? comment.comment.substring(0, 250)
                            : ""
                        )}
                        <a
                          className={classes.myTextStyle2}
                          onClick={() => {
                            dispatch({
                              type: USER_EXPAND_COMMENT,
                              payload: {
                                id: post.id,
                                content: true,
                                is_event: post.is_event,
                              },
                            });
                          }}
                        >
                          {comment.comment.length > 250 ? " ...See More" : null}
                        </a>
                      </Typography>
                    )}
                  </CardContent>
                </Card>
              );
            })
          : null}
      </Card>
      {post.editPost.showEditPost ? (
        <Modal
          className={classes.editPost}
          open={post.editPost.showEditPost}
          onClose={() => {
            dispatch({
              type: SHOW_POST_EDIT,
              payload: {
                id: post.id,
                editPost: false,
                title: "Edit Post",
                button: "Update Post",
              },
            });
          }}
        >
          <EMCreatePost
            post={post}
            id={id}
            usedIn={usedIn}
            postFrom={post.postFrom}
            selectedChannelIds={[
              `${post.groupName} |${post.channel_details[0].channel_id}`,
            ]}
          />
        </Modal>
      ) : null}

      {/* Add Comment Component Here */}
      {permission ? (
        <Paper elevation={2} className={classes.addComment}>
          <EMAddNewComment post={post} permission={permission} />
        </Paper>
      ) : null}
    </div>
  );
}

function PinIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M23.541,23.763l-9.431-5.9a2.321,2.321,0,0,0-3.306.958,2.745,2.745,0,0,0,.886,3.581l9.429,5.9a2.32,2.32,0,0,0,3.306-.959,2.739,2.739,0,0,0-.885-3.58Zm-7.778-6.378L23.1,21.973l2.495-7.521-5.065-3.167ZM10.356,36.706l7.017-9.233-3.144-1.966-3.873,11.2ZM28.625,10.292,22.339,6.36a1.74,1.74,0,0,0-2.479.72,2.057,2.057,0,0,0,.664,2.686L26.811,13.7a1.74,1.74,0,0,0,2.479-.72,2.057,2.057,0,0,0-.665-2.686Z"
        transform="translate(-10.356 -6.096)"
      />
    </SvgIcon>
  );
}
