import { Box, IconButton, Typography, Tooltip } from "@material-ui/core";
import EMSimpleDatatable from "../../../components/datatables/EMSimpleDatatable";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import {
  EMDOGetNetworkAdminChannelListAction,
  EMDoUpdateNetworkAdminChannelAction,
} from "../../../redux/actions/EMNetworkAdminActions";
import { useEffect, useState } from "react";
import EMSwitch from "../../../components/commans/EMSwitch";
import { Edit } from "@material-ui/icons";
import { isEmpty } from "lodash";
import moment from "moment";
import { NETWORK_ADMIN_CHANNEL_LIST_SUCCESS } from "../../../redux/constants";

const useStyles = makeStyles((theme) => ({
  typo: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 1,
    wordBreak: "break-word",
    overflow: "hidden",
  },
}));

export default function EMChannelTable({
  filter,
  onEdit,
  entity,
  permissionData,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const paginationData = useSelector(
    (state) => state.networkAdmin.networkAdminsChannelList || {}
  );
  const [limit, setLimit] = useState(15);
  const [offset, setOffset] = useState(0);
  const [order, setOrder] = useState({ id: -1, direction: "asc" });
  const [sortAscending, setSortAscending] = useState(false);
  const userData = useSelector((state) => state.auth);
  const uiData = useSelector((state) => state.ui);
  const classes = useStyles();
  const shouldUpdate = useSelector(
    state => state.networkAdmin.updateChannelsList || false
);

  useEffect(() => {
    if (filter && filter.length > 0) {
      setOffset(0);
    } else if (filter && filter == "") {
      setOffset(offset);
    }
  }, [filter]);

  const columns = [
    {
      title: "Channel Name",
      field: "channel_name",
      align: "left",
      render: (data) => {
        return (
          <>
            <Tooltip
              title={<h2 style={{ color: "white" }}>{data["channel_name"]}</h2>}
              placement="top"
            >
              <Typography className={classes.typo}>
                {data["channel_name"] && data["channel_name"]}
              </Typography>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Group Name",
      align: "left",
      field: "group__group_name",
      render: (data) => {
        return (
          <>
            <Tooltip
              title={<h2 style={{ color: "white" }}>{data["group_name"]}</h2>}
              placement="top"
            >
              <Typography className={classes.typo}>
                {data["group_name"] && data["group_name"]}
              </Typography>
            </Tooltip>
            </>
          )
        }
     
        },
        {
        title: "Admin Emails ",
        align: "left",
        field: "channeluser_channel_id__user",
        sorting: false,
        render: data => {
          let emails = data["users"]
              .map(user=>user["email"]).join(", ");
              return (
                <>
                <Tooltip title={<h2 style={{ color: "white" }}>{emails}</h2>} placement='top'>
                <Typography className={classes.typo}>{emails && emails }</Typography>
                </Tooltip>
                </>
              )
          }
        },
        {
        title: "Status",
        align: "left",
        field: "is_active",
        render: data => {
          return (
            <EMSwitch
              status={data.is_active}
              isDisabled={!(permissionData&&permissionData["edit_flag"] )}
              dialogText= {"Are you sure that you want to activate/deactivate the channel?"}
              onChange={status => {
                let admin_emails = []
                admin_emails = data["users"].map(user=>user["email"])
                let channel = data.id;
                let group_id = data.group.id;
                let newData = {  admin_emails, ...data, channel, group_id };
                newData.is_active = status;
                delete newData.tableData;
                // delete newData.users;
                // delete newData.context_type;
                dispatch(
                  EMDoUpdateNetworkAdminChannelAction({
                      ...newData
                  })
              );
              }}
            />
          );
        }
        },
        {
        title: "Created By",
        align: "left",
        field: "created_by",
        sorting: true,
        render:data =>{
          return (
            <>
            <Tooltip title={<h2 style={{ color: "white" }}>{data["created_by"]}</h2>} placement='top'>
            <Typography className={classes.typo}>{data["created_by"] && data["created_by"] }</Typography>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Privacy",
      align: "left",
      field: "privacy_type",
      render: (data) => {
        return <Typography>{data["privacy_type_name"]}</Typography>;
      },
    },
    {
      title: "Created on",
      field: "created_on",
      align: "left",
      sorting: true,
      render: (data) => {
        let date = data["created_on"];
        date = new Date(date);
        date = moment(date).format("MM/DD/YYYY");
        return <Typography>{data["created_on"] ? date : ""}</Typography>;
      },
    },
    {
      title: "Last Active",
      field: "last_active",
      align: "left",
      sorting: true,
      render: (data) => {
        let date = data["last_active"];
        date = new Date(date);
        date = moment(date).format("MM/DD/YYYY");
        return <Typography>{data["last_active"] ? date : ""}</Typography>;
      },
    },
    {
      title: "Action",
      align: "left",
      sorting: false,
      render: (data) => {
        return (
          <>
            <IconButton
              onClick={() => onEdit(data)}
              disabled={!(permissionData && permissionData["edit_flag"])}
            >
              <Edit />
            </IconButton>
          </>
        );
      },
    },
  ];

  const updateData = (queryData) => {
    if (
      queryData.indexOf("context_type") >= 0 &&
      queryData.indexOf("limit") >= 0
    ) {
      dispatch(EMDOGetNetworkAdminChannelListAction(queryData));
    }
  };

  useEffect(() => {
    updateData("?");
    dispatch({
      type: NETWORK_ADMIN_CHANNEL_LIST_SUCCESS,
      payload: undefined
    });
  }, []);

  useEffect(() => {
    const query = [];
    if (limit !== 0) {
      query.push(`limit=${limit}`);
    }
    if (filter) {
      query.push(`search=${filter}`);
    }
    if (!isEmpty(entity)) {
      query.push(`context_type=${entity.context_type}`, `context=${entity.context}`);
    }
    if (offset !== 0) {
      query.push(`offset=${offset}`);
    }
    if (order.id !== -1) {
      if (sortAscending === false) {
        // order.direction = "asc"
        query.push(
          `ordering=${order.direction === "desc" ? "-" : ""}${
            columns[order.id - 1].field
          }`
        );
        setSortAscending(true);
      } else {
        // order.direction = "desc"
        query.push(
          `ordering=${order.direction === "desc" ? "-" : ""}${
            columns[order.id - 1].field
          }`
        );
        setSortAscending(false);
      }
    }
    updateData("?" + query.join("&"));
  }, [limit, filter, offset, order, entity, shouldUpdate]);

  return (
    <Box p={2}>
      {
        <EMSimpleDatatable
        filter={filter}
          columns={columns}
          data={paginationData["results"] || []}
          count={paginationData["count"] || 0}
          updatePageAndPageSizeAndOrder={(page, pageSize, order) => {
            setOffset(page * limit);
            setLimit(pageSize);
            setOrder(order);
          }}
          options={{
            headerStyle: {
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.common.white,
              fontWeight: "bold",
            },
          }}
        />
      }
    </Box>
  );
}
