import React, {useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

export default function EMDialogBox({shouldOpen,onSelect, dialogText}) {
  const [open, setOpen] = React.useState(shouldOpen);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect( () => {
    setOpen(shouldOpen)
  },[shouldOpen])

  return (
    <div>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        disableBackdropClick={true}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <DialogContentText>
            {dialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => {
              onSelect(true)
          }} color="primary">
            Yes, I'm sure
          </Button>
          <Button onClick={() => {
              onSelect(false)
          }} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}