import {
  Grid,
  Paper,
  Typography,
  Button,
  GridList,
  GridListTile,
  useMediaQuery,
} from "@material-ui/core";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import EMPage from "../../../../components/commans/EMPage";
import EMUser from "../../../../components/user/EMUser";
import { useLocation } from "react-router-dom";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import LinkIcon from "@material-ui/icons/Link";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import EMGroupView from "../../../../components/user/EMGroupView";
import EMSignupPage from "../../../auth/EMSignupPage";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  DISABLE_ENABLE_SEARCH_OTHERS,
  PROFILE_EDIT_TOGGLE,
  SHOW_NEXT_MEMBERS_SEARCHOTHERS,
} from "../../../../redux/constants/UserPlatform/EMPeopleConstant";
import EMProfileDetails from "../MyCompany/EMProfileDetails";
import {
  EMDoGetProfileDetailsAction,
  EMDoGetGroupsOfUserForProfileAction,
  EMDoGetChannelsOfUserForProfileAction,
  EMDoGetListOfMembersOfOrganisationAction,
  EMDoGetListOfMembersOfOtherOrganizationAction,
  EMDoGetListOfMembersOfOtherOrganizationBySearchAction,
  EMDoGetFilteredMembersWithPartenershipsAndOrganizationsAction,
} from "../../../../redux/actions/UserPlatform/People/EMPeopleActions";
import profile_image from "../../../../assets/user_image.png";
import EMFilter from "../../../../components/user/EMFilter";
import { DISABLE_ENABLE_FILTER, GROUP_APPLY_NETWORK_FILTER } from "../../../../redux/constants/UserPlatform/EMGroupConstant";
import { EMDoGetNetworkFiltersAction } from "../../../../redux/actions/UserPlatform/Groups/EMGroupsAction";
import { EMDoGetAllInformationAboutUserAction } from "../../../../redux/actions/EMCommonActions";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import EMSearchView from "../../../../components/navigation/EMSearchView";
import { EMDoGetListOfAllGroupsBySearchAction } from "../../../../redux/actions/UserPlatform/Groups/EMGroupsAction";
import InfinitScroll from "react-infinite-scroll-component";
import EMSideBar from "../../../../components/navigation/EMSideBar";
import EMSearch from "../../../../components/navigation/EMSearch";
const useStyles = makeStyles((theme) => ({
  profileRoot: {
    margin: "4%",
    padding: "2%",
    // width: "80%"
  },
  title: {
    fontWeight: "bold",
    marginLeft: "1%",
    // marginBottom: "2%"
  },
  root: {
    // marginTop: "2%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },

  gridList: {
    width: "100%",
    height: "100%",
    marginLeft: "2%",
    // maxHeight: window.screen.height,
    // "&::-webkit-scrollbar": {
    //   width: "0.4em",
    // },
    // "&::-webkit-scrollbar-track": {
    //   boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    //   webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    // },
    // "&::-webkit-scrollbar-thumb": {
    //   backgroundColor: "#4A4A4A",
    //   outline: "1px solid slategrey",
    // },
  },
  //User Profile Specific Styles
  profilePicture: {
    minHeight: "40%",
    minWidth: "40%",
  },
  image: {
    width: 128,
    height: 128,
  },
  Filter: {
    // [theme.breakpoints.up("sm")]: {
    //   fontSize: "1.1rem"
    // },
    // [theme.breakpoints.up("xl")]: {
    //   fontSize: "1.5rem"
    // },
    // padding:"2%",
    // marginTop:"2.8%",
    // marginLeft:"-1%",
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  topBar: {
    float: "right",
    display: "flex",
    // marginLeft: "59%",
  },
  button: {
    backgroundColor: "white",
    float: "right",
    height: "1%",
  },
  titleGroupChannel: {
    marginTop: "3%",
  },
}));

function SearchOthers(props) {
  const classes = useStyles();
  const location = useLocation();
  const navigation = useNavigate();
  const data = useSelector((state) => state.myPeople);
  const members = useSelector(
    (state) => state.myPeople.otherOrganizationMembers
  );
  const groupsData = useSelector((state) => state.groups || {});
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(0);
  const [limitWhileSearching, setLimitWhileSearching] = useState(0);
  const [limitWhileFiltering, setLimitWhileFiltering] = useState(0);
  const [networkValue, setNetworkValue] = useState([]);
  const dispatch = useDispatch();

  const theme = useTheme();

  const matchesMediumScreen = useMediaQuery(theme.breakpoints.up("md"));

  const matchesLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    dispatch(EMDoGetNetworkFiltersAction({ route: true }));
    dispatch({
      type: SHOW_NEXT_MEMBERS_SEARCHOTHERS,
      payload: {
        nextMembers: undefined,
      },
    });
    dispatch({
      type:DISABLE_ENABLE_SEARCH_OTHERS,
      payload:true
    })
    dispatch({
      type:DISABLE_ENABLE_FILTER,
      payload:true
    })
  }, []);

  const sendQuery = useCallback(async () => {
    
    if (search.length === 0 && networkValue.length === 0) {
      dispatch(EMDoGetListOfMembersOfOtherOrganizationAction({ limit: limit }));
    } else if (search.length > 0 && networkValue.length == 0) {
      dispatch(
        EMDoGetListOfMembersOfOtherOrganizationBySearchAction({
          searchText: search,
          limit: limitWhileSearching,
        })
      );
    }else if (networkValue.length > 0 && search.length == 0) {
      if (networkValue.length == 1) {
        let selectedNetwork = networkValue[0];
        let action =
          selectedNetwork.context_type_name + "=" + selectedNetwork.context;
        if (selectedNetwork.context_type_name === "partnership") {
          action = `${selectedNetwork.context_type_name}s=${selectedNetwork.context}`;
        }
        dispatch({
          type: SHOW_NEXT_MEMBERS_SEARCHOTHERS,
          payload: {
            nextMembers: 1,
          },
        });
        dispatch(
          EMDoGetFilteredMembersWithPartenershipsAndOrganizationsAction({
            action: action,
            limit: limitWhileFiltering,
          })
        );
      } else if (networkValue.length > 1) {
        let action;
        let partnershipAction;
        let organizationAction;
        let partnershipIds = [];
        networkValue.map((selectedNetwork, key) => {
          if (selectedNetwork.context_type_name === "partnership") {
            partnershipIds.push(selectedNetwork.context);
            partnershipAction = `${
              selectedNetwork.context_type_name
            }s=${partnershipIds.toString()}`;
          }
          if (selectedNetwork.context_type_name === "organization") {
            organizationAction =
              selectedNetwork.context_type_name + "=" + selectedNetwork.context;
          }
          if (organizationAction != undefined) {
            action = organizationAction;
          }
          if (partnershipAction != undefined) {
            action = partnershipAction;
          }
          if (
            organizationAction != undefined &&
            partnershipAction != undefined
          ) {
            action = organizationAction + "&" + partnershipAction;
          }
        });
        dispatch(
          EMDoGetFilteredMembersWithPartenershipsAndOrganizationsAction({
            action: action,
            limit: limitWhileFiltering,
          })
        );
      }
    } else if(networkValue.length > 0 && search.length > 0){
      if (networkValue.length == 1) {
        let selectedNetwork = networkValue[0];
        let action =
          selectedNetwork.context_type_name + "=" + selectedNetwork.context;
        if (selectedNetwork.context_type_name === "partnership") {
          action = `${selectedNetwork.context_type_name}s=${selectedNetwork.context}`;
        }
        if(search.length > 0){
          action = action + "&" + "search=" + search
       }
        dispatch({
          type: SHOW_NEXT_MEMBERS_SEARCHOTHERS,
          payload: {
            nextMembers: 1,
          },
        });
        dispatch(
          EMDoGetFilteredMembersWithPartenershipsAndOrganizationsAction({
            action: action,
            limit: limitWhileFiltering,
          })
        );
      } else if (networkValue.length > 1) {
        let action;
        let partnershipAction;
        let organizationAction;
        let partnershipIds = [];
        networkValue.map((selectedNetwork, key) => {
          if (selectedNetwork.context_type_name === "partnership") {
            partnershipIds.push(selectedNetwork.context);
            partnershipAction = `${
              selectedNetwork.context_type_name
            }s=${partnershipIds.toString()}`;
          }
          if (selectedNetwork.context_type_name === "organization") {
            organizationAction =
              selectedNetwork.context_type_name + "=" + selectedNetwork.context;
          }
          if (organizationAction != undefined) {
            action = organizationAction;
          }
          if (partnershipAction != undefined) {
            action = partnershipAction;
          }
          if (
            organizationAction != undefined &&
            partnershipAction != undefined
          ) {
            action = organizationAction + "&" + partnershipAction;
          }
        });
        if(search.length > 0){
          action = action + "&" + "search=" + search
       }
        dispatch(
          EMDoGetFilteredMembersWithPartenershipsAndOrganizationsAction({
            action: action,
            limit: limitWhileFiltering,
          })
        );
      }
    }
  }, [limit, limitWhileSearching, limitWhileFiltering,]);

  useEffect(() => {
      if (search.length === 0 && networkValue.length === 0) {
        sendQuery(limit);
      } else if (search.length > 0 && networkValue.length == 0) {
        sendQuery(limitWhileSearching);
      } else if (networkValue.length > 0 || search.length > 0) {
        sendQuery(limitWhileFiltering);
      }
  }, [sendQuery, limit, limitWhileSearching, limitWhileFiltering]);

  const handleObserver = useCallback(() => {
    if (search.length === 0 && networkValue.length === 0) {
      setLimit((prev) => prev + 20);
      setLimitWhileFiltering(0)
      setLimitWhileSearching(0)
    } else if (search.length > 0 && networkValue.length == 0) {
      setLimitWhileSearching((prev) => prev + 20);
      setLimitWhileFiltering(0)
      setLimit(0)
    } else if (networkValue.length > 0 || search.length > 0) {
      setLimitWhileFiltering((prev) => prev + 20);
      setLimit(0)
      setLimitWhileSearching(0)
    }
  }, [search, networkValue]);

  useEffect(() => {
    setNetworkValue(
      groupsData.networks.filter((network) => network.value === true)
    );
  }, [groupsData.networks]);

  function getCols(screenWidth) {

    if (isWidthUp("xl", screenWidth)) {
      return 4;
    }else if (isWidthUp("lg", screenWidth)) {
      return 4;
    }else if (isWidthUp("md", screenWidth)) {
      return 4;
    }else if (isWidthUp("sm", screenWidth)) {
      return 3;
    } else if (isWidthUp("xs", screenWidth)) {
      return 2;
    }
    return 4;
  }

  useEffect(() => {
    let appliedFilters = networkValue
    if(appliedFilters.length > 0){
      setLimitWhileFiltering(0)
      if (appliedFilters.length == 1) {
        let selectedNetwork = appliedFilters[0];
        let action =
          selectedNetwork.context_type_name +
          "=" +
          selectedNetwork.context;
        if (
          selectedNetwork.context_type_name === "partnership"
        ) {
          action = `${selectedNetwork.context_type_name}s=${selectedNetwork.context}`;
        }
        if(search.length > 0){
           action = action + "&" + "search=" + search
        } 
        dispatch(
          EMDoGetFilteredMembersWithPartenershipsAndOrganizationsAction({
            action: action,
            limit: limitWhileFiltering,
          })
        );
      }else if (appliedFilters.length > 1) {
       
          let action
          let partnershipAction 
          let organizationAction
          let partnershipIds = []
          let orgIds = []
          appliedFilters.map((selectedNetwork, key) => {
            if (
              selectedNetwork.context_type_name === "partnership"
            ) {
              partnershipIds.push(selectedNetwork.context);
              partnershipAction = `${
                selectedNetwork.context_type_name
              }s=${partnershipIds.toString()}`;
            }
            if (selectedNetwork.context_type_name === "organization") {
              orgIds.push(selectedNetwork.context)
              organizationAction=  `${selectedNetwork.context_type_name}=${orgIds.toString()}`
            } 
            if(organizationAction != undefined){
              action=organizationAction  
            }
            if (organizationAction != undefined) {
              action = organizationAction;
            }
            if (partnershipAction != undefined) {
              action = partnershipAction;
            }
            if (
              organizationAction != undefined &&
              partnershipAction != undefined
            ) {
              action =
                organizationAction + "&" + partnershipAction;
            }
            if(search.length > 0){
              action = action + "&" + "search=" + search
           }
          });
          dispatch(
            EMDoGetFilteredMembersWithPartenershipsAndOrganizationsAction({
              action: action,
              limit: limitWhileFiltering,
            })
          );
        }else if (appliedFilters.length == 0 && search.length == 0) {
          
          setLimit(0)
          dispatch(EMDoGetListOfMembersOfOtherOrganizationAction({ limit: limit }));
        }
    } else{
      setLimitWhileSearching(0)
      let action = "search=" + search
      dispatch(
        EMDoGetFilteredMembersWithPartenershipsAndOrganizationsAction({
          action: action,
          limit: limitWhileSearching,
        })
      );
    }   
  },[search])

  const cols = getCols(props.width);


  return (
    <InfinitScroll
      dataLength={members.length}
      next={handleObserver}
      hasMore={true}
    >
      <EMPage
        title="Search Others"
        style={{
          width: "100%",
          paddingLeft: matchesLargeScreen  ? "8%" : "4%",
          paddingTop: matchesLargeScreen ? "4%" : matchesMediumScreen ? "6%" : "8%",
          paddingRight: matchesLargeScreen  ? "8%" : "4%",
          paddingBottom: "2%",
        }}
      >
        <Grid
          container
          direction="row"
          alignItems="flexStart"
          spacing={3}
        >
                 {matchesMediumScreen ? (
            <Grid item xs={matchesLargeScreen ? 2 : 3}>
              <EMSideBar />
            </Grid>
          ) : null}
          <Grid item xs={matchesLargeScreen ? 7 : matchesMediumScreen ? 9 : 12}>
            <Paper elevation={2}>
              <div style={{ padding: "1%" }}>
                <Grid container alignItems="center" style={{ padding: "1%" }}>
                  <Grid md={9} sm={8} xs={7}>
                    <Typography className={classes.title} variant={"h5"}>
                      {"Members Of Other Networks"}
                    </Typography>
                  </Grid>
                  <Grid md={3} sm={4} xs={5}>
                    <EMSearch
                      disabled={data.isDisabledSearchOthers || groupsData.isFilterDisabled}
                      data={search}
                      usedIn="browse_groups"
                      route={true}
                      hint={"Search"}
                      onChangeListener={(serachData) => {
                        // 
                        dispatch({
                          type:DISABLE_ENABLE_SEARCH_OTHERS,
                          payload:true
                        })
                        setSearch(serachData);
                      }}
                    />
                  </Grid>
                </Grid>
                <div className={classes.root}>
                  <GridList
                    cellHeight={160}
                    className={classes.gridList}
                    cols={cols}
                  >
                    {members &&
                      members.map((user) => (
                        <GridListTile style={{ height: "100%", padding: "1%" }}>
                          <EMUser
                            user={user}
                            profileImage={
                              user.profile_pic_file && user.profile_pic_file
                                ? process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(
                                    0,
                                    -1
                                  ) + user.profile_pic_file
                                : profile_image
                            }
                            userFirstName={user.first_name}
                            userLastName={user.last_name}
                            designation={user.professional_title}
                            organisationName={user.organization_name}
                            email={user.email}
                            linkedinLink={
                              user.linkedin_profile_link &&
                              user.linkedin_profile_link
                            }
                            profileClickHandler={() =>
                              navigation(`/users/people/${user.id}`, {
                                state: { name: user.first_name, id: user.id },
                              })
                            }
                          />
                        </GridListTile>
                      ))}
                  </GridList>
                </div>
              </div>
            </Paper>
          </Grid>
          {matchesLargeScreen ? 
          <Grid item xs={3} className={classes.Filter}>
            <Paper elevation={2} style={{}}>
              <EMFilter
              disabled={groupsData.isFilterDisabled || data.isDisabledSearchOthers}
                filter_name="NETWORK FILTER"
                network={groupsData.networks}
                network_filter={GROUP_APPLY_NETWORK_FILTER}
                route={"search_others"}
                handleLimitInSearchOthers={(limit) => setLimit(limit)}
                handleLimitWhileFilteringInSearchOthers={(limit) => setLimitWhileFiltering(limit)}
                search={search}
                handleLimitWhileSearchingInSearchOthers = {(limit) => setLimitWhileSearching(limit)}
              />
            </Paper>
          </Grid> : null}
        </Grid>
      </EMPage>
    </InfinitScroll>
  );
}
export default withWidth()(SearchOthers);
