import EMDropdown from "../../../../components/commans/EMDropdown";
import { useSelector,useDispatch } from "react-redux";
import { find } from "lodash";
import FormHelperText from "@material-ui/core/FormHelperText";
// import {EMDoGetRolesListAction} from "../../../../redux/actions/EMNetworkAdminActions"
// import {useEffect} from "react"
export default function EMEntityRoleDropdown({disabled,initialValue, onSelect, name ,entity,  error, helperText,},props) {
  const roleData = useSelector(state => state.networkAdmin.roles);
   
  let preValue = initialValue ? find(roleData,{"id" : initialValue}) : ''
  preValue = preValue !== ''&& preValue !== undefined ? preValue["role_name"] : ''
 
  return (
    <>
    <EMDropdown
      label="Select Role"
      data={roleData}
      objKey={"role_name"}
      onChange={selected => {
        onSelect(find(roleData, { role_name: selected }));
      }}
      error={error}
      helperText={helperText}
      isDisabled = {disabled}
      prevData={preValue}
      {...props}
    />
      <FormHelperText style = {{color:'red'}}>
        {error}
        {helperText}
      </FormHelperText>
    </>
  );
}
