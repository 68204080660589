import { Grid, Typography, Paper ,Tooltip} from "@material-ui/core";
import EMSimpleDatatable from "../../../components/datatables/EMSimpleDatatable";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import EMPage from "../../../components/commans/EMPage";
import { EMDoGetNetworkStatsReportAction } from "../../../redux/actions/EMAdminActions";
import { isEmpty } from "lodash";

const useStyles = makeStyles((theme) => ({
  typo: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 1,
    wordBreak: "break-word",
    overflow: "hidden",
  },
}));

export default function EMNetworksStatsReport({ filter, onEdit, initialData }) {
  const theme = useTheme();
  const adminData = useSelector(state => state.admin || {})
  const [networkStats, setNetworkStats] = useState({})
  const classes = useStyles()
  const [limit, setLimit] = useState(15);
  const [offset, setOffset] = useState(0);
  const [order, setOrder] = useState({ id: -1, direction: "asc" });
  const [sortAscending, setSortAscending] = useState(false);
  const dispatch = useDispatch()

  const columns = [
    {
      title: "Organization Name",
      field: "organization_name",
      align: "left",
      render: data => {
        let name = data["organization_name"];
        return(
          <>
           <Tooltip title={<h2 style={{ color: "white" }}>{name}</h2>} placement='top'>
           <Typography className={classes.typo}>{ name}</Typography>
           </Tooltip>
           </>
          )
      }
    },
    {
      title: "No. Of Groups",
      field: "no_of_active_groups",
      align: "left",
      render: data => {
        let name = data["no_of_active_groups"];
        return <Typography>{name}</Typography>;
      }
    },
    {
      title: "No Of Active Users",
      field: "no_of_active_users",
      align: "left",
      render: data => {
        let name = data["no_of_active_users"];
        return <Typography>{name}</Typography>;
      }
    },    {
      title: "No Of Channels",
      field: "no_of_channels",
      align: "left",
      render: data => {
        let name = data["no_of_channels"];
        return <Typography>{name}</Typography>;
      }
    },    {
      title: "No Of Private Groups",
      field: "no_of_private_groups",
      align: "left",
      render: data => {
        let name = data["no_of_private_groups"];
        return <Typography>{name}</Typography>;
      }
    },    {
      title: "No Of Public Groups",
      field: "no_of_public_groups",
      align: "left",
      render: data => {
        let name = data["no_of_public_groups"];
        return <Typography>{name}</Typography>;
      }
    },
    {
      title: "No Of Restricted Groups",
      field: "no_of_restricted_groups",
      align: "left",
      render: data => {
        let name = data["no_of_restricted_groups"];
        return <Typography>{name}</Typography>;
      }
    },
    {
      title: "No Of Secret Groups",
      field: "no_of_secret_groups",
      align: "left",
      render: data => {
        let name = data["no_of_secret_groups"];
        return <Typography>{name}</Typography>;
      }
    },

  ];

  const updateData = query => {
    dispatch(EMDoGetNetworkStatsReportAction({ requiredData: initialData, paginationData: query }))
  };
  /* forming url wrt functionality */
  useEffect(() => {
    const query = [];
    if (limit !== 0) {
      query.push(`limit=${limit}`);
    }
    if (offset !== 0) {
      query.push(`offset=${offset}`);
    }
    if (order.id !== -1) {
      /* Logic for including sorting */
      if(sortAscending === false){
        query.push(
          `ordering=${order.direction === "desc" ? "-" : ""}${
            columns[order.id-1].field
          }`
        );
        setSortAscending(true)
      }else{
        // order.direction = "desc"
        query.push(
          `ordering=${order.direction === "desc" ? "-" : ""}${
            columns[order.id-1].field
          }`
        );
        setSortAscending(false)
      }
    }
    updateData( query.join("&"));


  }, [limit, offset, order, initialData]);


  useEffect(() => {
    
    if(adminData.networkStatsData){  
    setNetworkStats(adminData.networkStatsData)
    }
  },[adminData])

  return (
      <EMPage title="Network Stats">
    <Paper elevation={2}>
        <Typography style={{paddingTop:"2%", paddingLeft:"2%", fontWeight:"bolder", fontSize:"large"}}>
            Network Stats Report
        </Typography>
      <Grid style={{padding:"2%"}}>
        {!isEmpty(networkStats) ?      <EMSimpleDatatable
          columns={columns}
          data={networkStats.results}
          count={networkStats.count || 0}
          updatePageAndPageSizeAndOrder={(page, pageSize, order) => {
            setOffset(page * limit);
            setLimit(pageSize);
            setOrder(order)
          }}
          options={{
            headerStyle: {
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.common.white,
              fontWeight: "bold"
            }
          }}
        />: null}       
      
      </Grid>
    </Paper>
    </EMPage>
  );
}

