import { Box, InputBase, IconButton } from "@material-ui/core";
import { makeStyles, fade } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import debounce from 'lodash.debounce';
import { useState, useRef } from 'react'
import { useDispatch } from "react-redux";

const style = makeStyles((theme) => ({
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    //  backgroundColor: fade(theme.palette.common.white, 0.25),
    "& ::-webkit-input-placeholder": {
      color: theme.palette.common.white + "!important"
    },
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.15),
    },
    marginRight: theme.spacing(1),
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      //marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    pointerEvents: "none",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    //paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      //width: "12ch",
      "&:focus": {
        //width: "20ch",
      },
    },
    "&::placeholder": {
      color: fade(theme.palette.primary.dark, 1)
    }
  },
  inputRoot1: {
    color: "black",
    width: "100%",
  },
  inputInput1: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    //paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      //width: "12ch",
      "&:focus": {
        //width: "20ch",
      },
    },
    "&::placeholder": {
      color: fade(theme.palette.primary.dark, 1)
    }
  },
  search1: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    //  backgroundColor: fade(theme.palette.common.white, 0.25),
    "& ::-webkit-input-placeholder": {
      color: theme.palette.common.black + "!important"
    },
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.15),
    },
    marginRight: theme.spacing(1),
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      //marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  root: {
    borderBottom: "solid",
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.common.white,
  },
  root1: {
    borderBottom: "solid",
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.common.black,
  },
}));

export default function EMSearch({ hint, onChangeListener, route, data, usedIn, disabled }) {
  const dispatch = useDispatch();
  const cssClasses = style();
  const debouncedSave = useRef(debounce((q) => onChangeListener(q), 700)).current;

  const handleChange = event => {   
    debouncedSave(encodeURIComponent(event.target.value))
  };

  return (
   !route ? <Box
      height="80%"
      display="flex"
      flexDirection="row"
      justifyItems="center"
      alignItems="center"
      mr={2}
      className={cssClasses.root}
    >
      <div className={cssClasses.search}>
        <InputBase
        disabled={disabled}
          placeholder={hint}
          classes={{
            root: cssClasses.inputRoot,
            input: cssClasses.inputInput
          }}
          inputProps={{ "aria-label": "search" }}
          onChange={handleChange}
        />
      </div>
      <div className={cssClasses.searchIcon}>
        <SearchIcon />
      </div>
    </Box> :     
    <Box
      height="100%"
      display="flex"
      flexDirection="row"
      justifyItems="center"
      alignItems="center"
      mr={2}
      className={cssClasses.root1}
    >
      <div className={cssClasses.search1}>
        <InputBase
        disabled={disabled}
          placeholder={hint}
          classes={{
            root: cssClasses.inputRoot1,
            input: cssClasses.inputInput1
          }}
          inputProps={{ "aria-label": "search" }}
          onChange={handleChange}
        />
      </div>
      <div className={cssClasses.searchIcon}>
             <SearchIcon  /> 
      </div>
    </Box>
  );
}
