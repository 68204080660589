import axios from "axios";

//services related to Platform admin
const EMDoGetPlatformAdminList = (queryData) => {
  let url = "";
  if (queryData) {
    url = `/platform/users/${queryData}`;
  } else {
    url = `/platform/users/`;
  }
  return axios.get(url);
};

const EMDoUpdatePlatformAdmin = (userData) => {
  let url = `/platform/users/${userData.id}/`;
  return axios.put(url, { ...userData });
};

const EMDoCreatePlatformAdmin = (userData) => {
  let url = `/platform/users/`;
  return axios.post(url, { ...userData });
};

//services related to Organization management
const EMDoGetOrganizationList = (queryData) => {
  let url = "";
  if (queryData) {
    url = `/organization/${queryData}`;
  }
  return axios.get(url);
};

const EMDoGetOrganizationTypeList = () => {
  let url = `/organization/listType/`;
  return axios.get(url);
};

const EMGetAllOrganizations = () => {
  let url = `/organization/listAll/`;
  return axios.get(url);
};

const EMDoUpdateOrganization = (organizationData) => {
  let url = `/organization/${organizationData.id}/`;
  return axios.put(url, { ...organizationData });
};

const EMDoCreateOrganization = (organizationData) => {
  let url = `/organization/`;
  return axios.post(url, { ...organizationData });
};

//services related to Partnership management
const EMDoGetPartnershipList = (queryData) => {
  let url = "";
  if (queryData) {
    url = `/network/${queryData}`;
  }
  return axios.get(url);
};

const EMDoUpdatePartnership = (partnershipData) => {
  let url = `/network/${partnershipData.id}/`;
  return axios.put(url, { ...partnershipData });
};

const EMDoCreatePartnership = (partnershipData) => {
  let url = `/network/`;
  return axios.post(url, { ...partnershipData });
};

const EMDoExportOrganizations = () => {
  let url = `/organization/export-org/`;
  return axios.get(url);
};

const EMDoGetNetworkListForDashboardAndReports = () => {
  let url = `/user/list-user-orgs-and-partnerships/`;
  return axios.get(url);
};

const EMDoGetDashboardInfo = (requiredData) => {
  let url;
  if (requiredData && requiredData.isPartnership) {
    url = `/user/platform-admin-network-dashboard/partnership/?start_date=${requiredData.start_date}&end_date=${requiredData.end_date}&partnership=${requiredData.organizationOrPartnershipId}`;
  } else {
    url = `/user/platform-admin-network-dashboard/organization/?start_date=${requiredData.start_date}&end_date=${requiredData.end_date}&organization=${requiredData.organizationOrPartnershipId}`;
  }

  return axios.get(url);
};
const EMDoGetOnboardingReport = (requiredData) => {
  let url;
  if (requiredData && requiredData.isPartnership) {
    url = `/user/platform-admin-onboarding-report/?start_date=${requiredData.start_date}&end_date=${requiredData.end_date}&partnership=${requiredData.organizationOrPartnershipId}`;
  } else {
    url = `/user/platform-admin-onboarding-report/?start_date=${requiredData.start_date}&end_date=${requiredData.end_date}&organization=${requiredData.organizationOrPartnershipId}`;
  }
  return axios.get(url);
};

const EMDoExportOnboardingReport = (requiredData) => {
  let url = `/user/platform-admin-onboarding-report/`;

  return axios.post(url, requiredData);
};

const EMDoGetUsageReport = (requiredData) => {
  let url;
  if (requiredData && requiredData.isPartnership) {
    url = `/user/platform-admin-usage-report/?start_date=${requiredData.start_date}&end_date=${requiredData.end_date}&partnership=${requiredData.organizationOrPartnershipId}`;
  } else {
    url = `/user/platform-admin-usage-report/?start_date=${requiredData.start_date}&end_date=${requiredData.end_date}&organization=${requiredData.organizationOrPartnershipId}`;
  }
  return axios.get(url);
};

const EMExportUsageReport = (requiredData) => {
  let url = `/user/platform-admin-usage-report/`;
  return axios.post(url, requiredData);
};

const EMDoGetUserEngagementReport = (requiredData, paginationData) => {
  let url;
  if (requiredData.groupOrChannel == 1) {
    if (requiredData && requiredData.isPartnership) {
      url = `user/platform-admin-user-engagement-report/group/?${paginationData}&start_date=${requiredData.start_date}&end_date=${requiredData.end_date}&partnership=${requiredData.organizationOrPartnershipId}`;
    } else {
      url = `user/platform-admin-user-engagement-report/group/?${paginationData}&start_date=${requiredData.start_date}&end_date=${requiredData.end_date}&organization=${requiredData.organizationOrPartnershipId}`;
    }
  } else {
    if (requiredData && requiredData.isPartnership) {
      url = `user/platform-admin-user-engagement-report/channel/?${paginationData}&start_date=${requiredData.start_date}&end_date=${requiredData.end_date}&partnership=${requiredData.organizationOrPartnershipId}`;
    } else {
      url = `user/platform-admin-user-engagement-report/channel/?${paginationData}&start_date=${requiredData.start_date}&end_date=${requiredData.end_date}&organization=${requiredData.organizationOrPartnershipId}`;
    }
  }
  return axios.get(url);
};

const EMDoExportUserEngagementReport = (requiredData, groupOrChannel) => {
  let url;
  if (groupOrChannel == 1) {
    url = `/user/platform-admin-user-engagement-report/group/`;
  } else {
    url = `/user/platform-admin-user-engagement-report/channel/`;
  }
  return axios.post(url, requiredData);
};

const EMDoGetNetworkStatsReport = (requiredData, paginationData) => {
  let url;

  if (requiredData && requiredData.isPartnership) {
    url = `/user/platform-admin-network-stats-report/?${paginationData}&start_date=${requiredData.start_date}&end_date=${requiredData.end_date}&partnership=${requiredData.organizationOrPartnershipId}`;
  } else {
    url = `/user/platform-admin-network-stats-report/?${paginationData}&start_date=${requiredData.start_date}&end_date=${requiredData.end_date}&organization=${requiredData.organizationOrPartnershipId}`;
  }
  return axios.get(url);
};

const EMDoExportNetworkStatsReport = (requiredData) => {
  let url = `/user/platform-admin-network-stats-report/`;
  return axios.post(url, requiredData);
};

const EMDoGetNetworkOrgGrpChn = () => {
  let url = `/user/platform-admin-entity-list/`;
  return axios.get(url);
};

const EMDoGetPlatformAdminResendInviteUsersList = (queryData) => {
  let url = "";
  if (queryData) {
    url = `/invitations/invited-platform-admins/${queryData}`;
  } else {
    url = `/invitations/invited-platform-admins/`;
  }
  return axios.get(url);
};

const EMDoResendInviteToAdmins = ({ data }) => {
  let url = `/invitations/invited-platform-admins/`;
  return axios.post(url, { ...data });
};

const EMDoGetOrganizationSubTypes = (orgType) => {
  let url = `/organization/org-sub-type/${orgType}/`;
  return axios.get(url);
};

const EMDoGetOrganizationAdmins = (orgnizations) => {
  let url = `/network/get-organization-admins/`;
  const formData = new FormData();
  formData.append("organization_ids", orgnizations);
  return axios.post(url, formData);
};

const EMDoGetPartnershipAdmins = (partnershipId) => {
  let url = `/network/get-checked_organization-admins/${partnershipId}/`;
  return axios.post(url);
};

export default {
  EMDoGetPlatformAdminList,
  EMDoUpdatePlatformAdmin,
  EMDoCreatePlatformAdmin,
  EMDoGetOrganizationList,
  EMDoGetOrganizationTypeList,
  EMGetAllOrganizations,
  EMDoUpdateOrganization,
  EMDoCreateOrganization,
  EMDoGetPartnershipList,
  EMDoUpdatePartnership,
  EMDoCreatePartnership,
  EMDoExportOrganizations,
  EMDoGetNetworkListForDashboardAndReports,
  EMDoGetDashboardInfo,
  EMDoGetOnboardingReport,
  EMDoGetUsageReport,
  EMDoGetUserEngagementReport,
  EMDoGetNetworkStatsReport,
  EMDoGetNetworkOrgGrpChn,
  EMDoExportOnboardingReport,
  EMDoExportNetworkStatsReport,
  EMExportUsageReport,
  EMDoExportUserEngagementReport,
  EMDoGetPlatformAdminResendInviteUsersList,
  EMDoResendInviteToAdmins,
  EMDoGetOrganizationSubTypes,
  EMDoGetOrganizationAdmins,
  EMDoGetPartnershipAdmins
};
