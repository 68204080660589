import React, { useRef, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { MentionsInput, Mention } from "react-mentions";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { CloseRounded, EmojiEmotions, } from "@material-ui/icons";
import Picker, { SKIN_TONE_MEDIUM_DARK } from "emoji-picker-react";
import {
  CardActions,
  SvgIcon,
  Paper,
  Menu,
  MenuItem,
  Box,
  ListItemIcon,
  Modal,
  GridList,
  Typography,
  IconButton,
} from "@material-ui/core";
import { POST_SHOW_EMOJI } from "../../redux/constants/UserPlatform/index";
import {
  EMDoCreateNewHashTagAction,
  EMDoGetPeopleMentionForPostListAction,
  EMDoSubmitCommentOnPostAction,
} from "../../redux/actions/UserPlatform/Home/EMLatestPostPageAction";

const useStyles = makeStyles((theme) => ({
  addComment: {
    width: "100%",
    display: "flex",
    // paddingTop: 8,
    height: "65px",
    alignItems: "center",
  },
  comments: {
    width: "100%",
    fontSize: 14,
  },
  commentButton: {
    marginRight: "3%",
    "&:hover": {
      color: "auqa",
      cursor: "pointer",
    },
  },
  commentButtonBeforeposting: { marginRight: "3%" },
  picker: {
    position: "fixed",
    marginTop: "10%",
    marginLeft: "58%",
  },
  comment: {
    width: "2%",
    "&:hover": {
      color: "auqa",
      cursor: "pointer",
    },
  },
}));

export default function EMAddNewComment({ post }) {
  const classes = useStyles();
  const userData = useSelector((state) => state.auth);
  const data = useSelector((state) => state.home || {});
  const anchorRef = React.useRef(null);
  const [comment, setComment] = React.useState("");

  const dispatch = useDispatch();
  return (
    <>
      <MentionsInput
        value={comment}
        // value={post.newComment}
        placeholder="Add a comment"
        className={classes.comments}
        onChange={(event) => {
          if (post.postAtMentions.length === 0) {
            dispatch(
              EMDoGetPeopleMentionForPostListAction({ postId: post.id })
            );
          }
          setComment(event.target.value);
        }}
        style={defaultStyle}
      >
        <Mention
          style={{ border: "none", overflowY: "auto", height: 70 }}
          markup="@@@____id__^^^____display__@@@^^^"
          trigger="@"
          appendSpaceOnAdd={true}
          data={post.postAtMentions}
          renderSuggestion={(
            suggestion,
            search,
            highlightedDisplay,
            index,
            focused
          ) => (
            <div className={`user ${focused ? "focused" : ""}`}>
              {highlightedDisplay}
            </div>
          )}
          onAdd={() => {}}
        />
        <Mention
          style={{ border: "none" }}
          trigger="#"
          markup="$$$____id__~~~____display__$$$~~~"
          appendSpaceOnAdd={true}
          data={data.postHashTags}
          renderSuggestion={(
            suggestion,
            search,
            highlightedDisplay,
            index,
            focused
          ) => (
            <div className={`user ${focused ? "focused" : ""}`}>
              {highlightedDisplay}
            </div>
          )}
          onAdd={() => {}}
        />
      </MentionsInput>
      <Box width="20%" alignItems="center" justifyContent="space-evenly" display="flex" >
        <Box>
      <EmojiEmotions
      ref={anchorRef}
      style={{marginTop:"20%"}}
        id="emoji"
        color="action"
        className={classes.commentButton}
        onClick={() => {
          dispatch({
            type: POST_SHOW_EMOJI,
            payload: { id: post.id, showEmoji: true },
          });
        }}
      />
      </Box>
      <Box>
      {comment ? (
        <SendIcon
          color="action"
          className={classes.commentButton}
          onClick={() => {
            let newComment = comment;
            newComment = newComment
              .split("@@@__")
              .join('<a href="/#/users/people/');
            newComment = newComment.split("^^^__").join(`">@`);
            newComment = newComment.split("@@@^^^").join("</a>");
            newComment = newComment
              .split("$$$__")
              .join('<a href="/#/users/hashtags/');
            newComment = newComment.split("~~~__").join(`">#`);
            newComment = newComment.split("$$$~~~").join("</a>");

            const newTags =
              // Split the comment into "words"
              newComment
                .split(" ")

                // Get the "words" that are hashtags (or `undefined` for ones that aren't)
                .map((word) => word.split("#")[1])

                // Filter out the "words" that either A) Are `undefined` or B) Are already known
                // in `hashtagData`
                .filter(
                  (word) =>
                    word !== undefined &&
                    !word.includes("@") &&
                    !word.includes("users") &&
                    !data.postHashTags.some((tag) => tag.display === word)
                );
                
            if (newTags.length > 0) {
              newTags.forEach((tag) => {
                dispatch(EMDoCreateNewHashTagAction(tag));
              });
            }
            let tag = newComment
              .split(" ")
              .map((word) => word.split("href")[1])
              .filter((word) => word !== undefined && word.includes("people"))
              .map((word) =>
                word.slice(word.lastIndexOf("/"), word.lastIndexOf(">"))
              )
              .map((word) => word.slice(1, word.length - 1)).map((word) => parseFloat(word))

            let commentForPosting = "";
            if (newComment !== "") {
              commentForPosting = newComment.trim();
            }

            let finalData = {
              post_id: post.id,
              // content: post.newComment,
              content: commentForPosting,
            };

            dispatch(EMDoSubmitCommentOnPostAction({ finalData, userData, tag:tag }));
            setComment("");
          }}
        />
      ) : (
        <SendIcon
          color="action"
          className={classes.commentButtonBeforeposting}
        />
      )}
      </Box>
      </Box>
      <Menu
        // className={classes.picker}
        anchorEl={anchorRef.current}
        open={post.showEmoji}
        keepMounted
        onClose={() => {
          dispatch({
            type: POST_SHOW_EMOJI,
            payload: { id: post.id, showEmoji: false },
          });
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            style={{
              margin: 10,
              flexGrow: 1,
              fontWeight: "bold",
            }}
          >
            Emoji
          </Typography>
          <IconButton
            style={{ float: "right" }}
            onClick={() => {
              dispatch({
                type: POST_SHOW_EMOJI,
                payload: { id: post.id, showEmoji: false },
              });
            }}
          >
            <CloseRounded color="secondary" />
          </IconButton>
        </Box>
        <Picker
          onEmojiClick={(target, emojiObject) => {
            // dispatch({
            //   type: POST_EMOJI_SELECTED,
            //   payload: { id: post.id, selectedEmoji: emojiObject.emoji },
            // });
            if (comment) {
              let updatedComment = comment + emojiObject.emoji;
              setComment(updatedComment);
            } else {
              setComment(emojiObject.emoji);
            }
          }}
        />
      </Menu>
    </>
  );
}

function EmojiIcon(props) {
  return (
    <SvgIcon viewBox="0 0 29 29" {...props}>
      <path d="M15.987,3A13,13,0,1,0,29,16,12.994,12.994,0,0,0,15.987,3ZM16,26.4A10.4,10.4,0,1,1,26.4,16,10.4,10.4,0,0,1,16,26.4Zm4.55-11.7a1.95,1.95,0,1,0-1.95-1.95A1.947,1.947,0,0,0,20.55,14.7Zm-9.1,0A1.95,1.95,0,1,0,9.5,12.75,1.947,1.947,0,0,0,11.45,14.7ZM16,23.15a7.145,7.145,0,0,0,6.643-4.55H9.357A7.145,7.145,0,0,0,16,23.15Z" />
    </SvgIcon>
  );
}

function SendIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M3.011,22.008,26.5,13.254,3.011,4.5,3,11.309l16.786,1.945L3,15.2Z" />
    </SvgIcon>
  );
}

const defaultStyle = {
  control: {
    backgroundColor: "#fff",

    fontSize: 12,
    fontWeight: "normal",
    border: "none",
    // height: "20px",
    outline: "0",
  },

  highlighter: {
    overflow: "hidden",
  },

  input: {
    margin: 0,
  },

  "&singleLine": {
    control: {
      display: "inline-block",
      fontFamily: "Montserrat",
      width: 130,
    },

    highlighter: {
      padding: 1,
    },

    input: {
      padding: 1,
    },
  },

  "&multiLine": {
    control: {
      fontFamily: "Montserrat",
      // height: "65px",
    },

    highlighter: {
      padding: 9,
    },

    input: {
      padding: 9,
      minHeight: 25,
      outline: 0,
      border: 0,
    },
  },
  suggestions: {
    list: {
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 12,
      overflowY: "auto",
      height: "auto",
      maxHeight:"200px"
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",

      "&focused": {
        backgroundColor: "#cee4e5",
      },
    },
  },
};
