import axios from "axios";

//Get Polls
const EMDoGetMyPolls = (action)=>{
    let url = `posts/my-polls/polls/`
    return axios.get(url);
}

//Get Open Ended Question
const EMDoGetMYOpenEndedQuestion = (action)=>{
    let url = `posts/my-polls/open-ended-question/`
    return axios.get(url);
}

const EMDoGetPollDetailsForOwner = (pollId, queryData) => {
    let url;
    if (queryData) {
        url = `posts/my-polls/user-poll-details/${pollId}/${queryData}`;
      } else {
        url = `posts/my-polls/user-poll-details/${pollId}/`;
      }
    return axios.get(url);
}

const EMDoExportPollDetailsForOwner = (pollId) => {
    let url = `posts/my-polls/user-poll-details/export/${pollId}/`
    return axios.get(url);
}

const EMDoGetOpenPollDetailsForOwner = (pollId, queryData) => {
    let url;
    if (queryData) {
        url = `posts/my-open-polls/user-poll-details/${pollId}/${queryData}`;
      } else {
        url = `posts/my-open-polls/user-poll-details/${pollId}/`;
      }
    return axios.get(url);
}

const EMDoExportOpenPollDetailsForOwner = (pollId) => {
    let url = `posts/my-open-polls/user-poll-details/export/${pollId}/`
    return axios.get(url);
}


export default{
    EMDoGetMyPolls,
    EMDoGetMYOpenEndedQuestion,
    EMDoGetPollDetailsForOwner,
    EMDoExportPollDetailsForOwner,
    EMDoGetOpenPollDetailsForOwner,
    EMDoExportOpenPollDetailsForOwner
}