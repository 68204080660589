import React from "react";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Button,
  ListItem,
  makeStyles,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";
import { isEmpty } from "lodash";
import { CHANGE_TAB_INDEX_TO_ZERO, RESET } from "../../../redux/constants";
import { useSelector, useDispatch } from "react-redux";
import { CHANGE_USER_TO_ADMIN } from "../../../redux/constants/UserPlatform/EMNavigationConstant";
const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.common.grey,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: "flex-start",
    letterSpacing: 0,
    padding: "10px 8px",
    textTransform: "none",
    width: "100%",
  },
  icon: {
    marginRight: theme.spacing(2.3),
    size: "20",
  },
  title: {
    //marginRight: "auto",
    whiteSpace: "normal",
    marginRight: theme.spacing(5.5),
    color: theme.palette.common.grey,
    size: 15,
  },
  active: {    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: "flex-start",
    letterSpacing: 0,
    padding: "10px 8px",
    textTransform: "none",
    width: "100%",
    "& $title": {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.common.white,
    },
    "& $icon": {
      color: theme.palette.common.white,
      borderBottom: "solid",
      borderBottomColor: theme.palette.primary.light,
      borderBottomWidth: 3,
    },
  },
}));

// const NavItem = ({ className, href, icon: Icon, title, ...rest }) => {
//   const cssClasses = useStyles();

//   return (
//     <ListItem
//       className={clsx(cssClasses.item, className)}
//       disableGutters
//       {...rest}
//     >
//       <Button
//         activeClassName={cssClasses.active}
//         className={cssClasses.button}
//         component={RouterLink}
//         to={href}
//       >
//         {Icon && <Icon className={cssClasses.icon} size="70" />}
//         <span className={cssClasses.title}>{title}</span>
//         {/* <ListItemText
//           primary={title}
//           className={cssClasses.title}
//           primaryTypographyProps={{ style: { whiteSpace: "normal" } }}
//         /> */}
//       </Button>
//     </ListItem>
//   );
// };

const NavItem = ({ className, href, icon: Icon, title, active, state, ...rest }) => {
  const cssClasses = useStyles();
  const dispatch = useDispatch();
  let isActive = isEmpty(href) ? true : false;
  const location = useLocation()

  return (
    <ListItem
      className={clsx(cssClasses.item, className)}
      disableGutters
      {...rest}
    >
      <Button
        className={location.pathname == href ? cssClasses.active : cssClasses.button}
        // className={cssClasses.button}
        component={RouterLink}
        to={href}
        disabled={isActive}
        onClick={(event) => {
          // if (!window.location.href.includes("admin/dashboard")) {
            // dispatch({ type: RESET });
            dispatch({type:CHANGE_TAB_INDEX_TO_ZERO, payload : 0})
            dispatch({
              type: CHANGE_USER_TO_ADMIN,
              payload: true,
            });
          // }
        }}
      >
        {Icon && (
          <ListItemIcon>
            <Icon className={cssClasses.icon} />
          </ListItemIcon>
        )}
        {/* <span className={cssClasses.title}>{title}</span> */}
        <ListItemText
          primary={title}
          className={cssClasses.title}
          primaryTypographyProps={{ style: { whiteSpace: "normal" } }}
        />
      </Button>
    </ListItem>
  );
};
NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
};

export default NavItem;
