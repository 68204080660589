import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import {
  CardHeader,
  FormControl,
  FormControlLabel,
  Paper,
  Grid,
  Typography,
  RadioGroup,
  Radio,
  Button,
  IconButton,
  SvgIcon,
  Menu,
  MenuItem,
  ListItemIcon,
  Modal,
  Box,
  CardContent,
  Tooltip,
  useMediaQuery,
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import { red } from "@material-ui/core/colors";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useSelector, useDispatch } from "react-redux";
import {
  EMDoDeleteOpenPollAction,
  EMDoDeletePollAction,
  EMDoDeleteQuizAction,
  EMDoGetPollSharedWithGroupsListAction,
  EMDoSubmitOpenPollAction,
  EMDoSubmitPoll,
  EMDoSubmitQuizAction,
} from "../../redux/actions/UserPlatform/Home/EMLatestPostPageAction";
import {
  OPEN_DELETE_CONFIRMATION_DIALOG,
  POST_SHOW_MORE,
  POST_SHOW_MORE_SELECTED,
  SHOW_POST_EDIT,
  TO_GET_THE_POLL_FORM,
} from "../../redux/constants/UserPlatform";
import { MoreHoriz, NaturePeopleOutlined } from "@material-ui/icons";
import EMDialogBox from "../commans/EMDialogBox";
import EMSimpleDatatable from "../datatables/EMSimpleDatatable";
import { useTheme } from "@material-ui/core/styles";
import "react-quill/dist/quill.snow.css";
import DeleteIcon from "@material-ui/icons/Delete";
import ProgressBar from "./EMProgressBar";
import EMCreateQuiz from "./EMCreateQuiz";
import {
  EMDoGetQuizReportAction,
  EMDownloadQuizReportsAction,
} from "../../redux/actions/UserPlatform/Quiz/EMQuizActions";
import { useLocation, useNavigate } from "react-router";

// import ImageListItemBar from '@material-ui/core/ImageListItemBar';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "0.5%",
  },
  addComment: {
    width: "100%",
    display: "flex",
    height: "65px",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: red[500],
  },
  title: {
    fontWeight: "bolder",
    fontSize: "14px",
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 1,
    wordBreak: "break-word",
    overflow: "hidden",
  },
  typo: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 1,
    wordBreak: "break-word",
    overflow: "hidden",
  },
  groupAvtar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  like: {
    "&:hover": {
      color: "auqa",
      cursor: "pointer",
    },
  },
  comment: {
    "&:hover": {
      color: "auqa",
      cursor: "pointer",
    },
  },
  editPost: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "80%",
    marginLeft: "10%",
  },
  myTextStyle: {
    textDecoration: "none",
    color: "blue",
    float: "right",
    marginTop: "2%",
    // marginRight: "3%",
    "&:hover": {
      cursor: "pointer",
    },
  },
  myTextStyle2: {
    textDecoration: "none",
    color: "blue",
    float: "right",
    // marginRight: "3%",
    "&:hover": {
      cursor: "pointer",
    },
  },
  postMedia: {
    margin: "1%",
  },
  currentMediaOfPost: {
    "&:hover": {
      opacity: "0.3",
    },
  },
  preview: {
    width: "100px",
    height: "100px",
  },
  picker: {
    position: "fixed",
    marginTop: "10%",
    marginLeft: "58%",
  },
  dialogPaper: {
    minHeight: "80vh",
    maxHeight: "80vh",
  },
  // media: {
  //   display: "flex",
  //   justifyContent: "space-evenly",
  //   alignItems: "center",
  // },
  gridList: {
    marginLeft: "3%",
    width: "100%",
    // height: 450,
  },
  comments: {
    width: "100%",
  },
  commentButton: {
    marginRight: "3%",
    "&:hover": {
      color: "auqa",
      cursor: "pointer",
    },
  },

  mediaOfPost: {
    display: "flex",
  },
  dialogTitle: {
    marginBottom: "1%",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  image: {
    maxHeight: "10%",
    paddingTop: "56.25%",
  },
  paperWidthXl: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  dialog: {
    // position: "relative",
    paperWidthXl: {
      width: "90%",
      height: "90%",
      marginLeft: "20%",
      marginTop: "15%",
    },
  },
  imageList: {
    width: 500,
    height: 450,
    // Promote the list into its own layer in Chrome. This cost memory, but helps keep FPS high.
    transform: "translateZ(0)",
  },
  viewMoreOnMediaHover: {
    "&:hover": {
      opacity: "1",
    },
  },
  card: {
    maxWidth: "100%",
  },
  closeButton: {
    marginTop: "1%",
    marginLeft: "1%",
    color: "white",
    borderColor: "white",
    //  color:"white"
  },
  media: {
    height: "400px",
  },
  commentButtonBeforeposting: { marginRight: "3%" },
  paper: {
    maxWidth: "100%",
    width: "auto",
    marginTop: "15%",
    marginLeft: "2%",
    marginRight: "2%",
  },
  formControl: {
    padding: theme.spacing(1.5),
    fontWeight: "bolder",
    fontSize: "large",
    // width: "100%",
  },
}));
export default function EMQuiz({
  id,
  post,
  pinHandler,
  permission,
  usedIn,
  searchedPost,
  quizFromQuiz,
}) {
  const classes = useStyles();
  const [value, setValue] = useState("");
  const [chartData, setChartData] = useState([]);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.home || {});
  const quizData = useSelector((state) => state.myQuizzes);
  const channelData = useSelector((state) => state.channels);
  const anchorRef = React.useRef(null);
  const [postMoreoptions, setPostmoreoption] = React.useState([]);
  const theme = useTheme();
  const [pinPermission, setPinPermission] = useState(false);
  const [dayLeft, setDaysLeft] = useState("");
  const [limit, setLimit] = useState(15);
  const [offset, setOffset] = useState(0);
  const [order, setOrder] = useState({ id: -1, direction: "asc" });
  const [sortAscending, setSortAscending] = useState(false);
  const [page, setPage] = useState(1);
  const [open, setOpen] = React.useState("");
  const [regularQuizDetails, setRegularQuizDetails] = React.useState([]);
  const [anoymousQuizDetails, setAnonymousQuizDetails] = React.useState([]);
  const [anonymousQuiz, setAnonymousQuiz] = useState(false);
  const location = useLocation();
  const navigation = useNavigate();

  const columns = [
    {
      title: "Users",
      field: "user",
      align: "left",
      render: (data) => {
        let name = data["user"];
        return (
          <>
            <Tooltip
              title={<h2 style={{ color: "white" }}>{name}</h2>}
              placement="top"
            >
              <Typography>
                {name && name.length > 15 ? name.slice(0, 15) + "..." : name}
              </Typography>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Groups",
      field: "group",
      align: "left",
      render: (data) => {
        let name = data["group"];
        return (
          <>
            <Tooltip
              title={<h2 style={{ color: "white" }}>{name}</h2>}
              placement="top"
            >
              <Typography>
                {name && name.length > 15 ? name.slice(0, 15) + "..." : name}
              </Typography>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Option Choosen",
      field: "option",
      align: "left",
      render: (data) => {
        let name = data["option"];
        return (
          <>
            <Tooltip
              title={<h2 style={{ color: "white" }}>{name}</h2>}
              placement="top"
            >
              <Typography>
                {name && name.length > 15 ? name.slice(0, 15) + "..." : name}
              </Typography>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const anonymousQuizColumns = [
    {
      title: "Group",
      field: "group",
      align: "left",
      render: (data) => {
        let name = data["group"];
        return (
          <>
            <Tooltip
              title={<h2 style={{ color: "white" }}>{name}</h2>}
              placement="top"
            >
              <Typography>
                {name && name.length > 15 ? name.slice(0, 15) + "..." : name}
              </Typography>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Option",
      field: "option",
      align: "left",
      render: (data) => {
        let name = data["option"];
        return (
          <>
            <Tooltip
              title={<h2 style={{ color: "white" }}>{name}</h2>}
              placement="top"
            >
              <Typography>
                {name && name.length > 15 ? name.slice(0, 15) + "..." : name}
              </Typography>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Vote",
      field: "votes",
      align: "left",
      render: (data) => {
        let name = data["votes"];
        return (
          <>
            <Tooltip
              title={<h2 style={{ color: "white" }}>{name}</h2>}
              placement="top"
            >
              <Typography>
                {name && name.length > 15 ? name.slice(0, 15) + "..." : name}
              </Typography>
            </Tooltip>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (
      quizData &&
      quizData.quizDetails &&
      quizData.quizDetails.results &&
      anonymousQuiz === false
    ) {
      if(quizData.quizDetails.results.every(item => item !== undefined)){
      setRegularQuizDetails(quizData.quizDetails.results);
      }
    }
  }, [quizData]);

  useEffect(() => {
    if (
      quizData &&
      quizData.quizDetails &&
      quizData.quizDetails.results &&
      anonymousQuiz === true
    ) {
      let newPollData = [];
      quizData.quizDetails.results.map((poll) => {
        newPollData = newPollData.concat(
          poll.options_votes &&
            poll.options_votes.map((option) => ({
              ...option,
              group: poll.group,
            }))
        );
      });
      if(newPollData.every(item => item !== undefined)){
      setAnonymousQuizDetails(newPollData)
      }
    }
  }, [quizData, anonymousQuiz]);

  const updateData = (query) => {
    if (query.indexOf("limit") >= 0 && quizFromQuiz) {
      dispatch(EMDoGetQuizReportAction({ quizId: post.id, query: query }));
    }
  };

  useEffect(() => {
    updateData("?");
  }, []);
  useEffect(() => {
    const query = [];
    if (limit !== 0) {
      query.push(`limit=${limit}`);
    }
    if (offset !== 0) {
      query.push(`offset=${offset}`);
    }
    if (order.id !== -1) {
      if (sortAscending === false) {
        // order.direction = "asc"
        query.push(
          `ordering=${order.direction === "desc" ? "-" : ""}${
            columns[order.id - 1].field
          }`
        );
        setSortAscending(true);
      } else {
        // order.direction = "desc"
        query.push(
          `ordering=${order.direction === "desc" ? "-" : ""}${
            columns[order.id - 1].field
          }`
        );
        setSortAscending(false);
      }
    }
    updateData("?" + query.join("&"));
  }, [limit, offset, order, page]);

  const matchesSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  React.useEffect(() => {
    if (post.postFrom == "channel" || id) {
      if (
        post.permissions &&
        post.permissions.AddFlag_PinPostsUnderChannel == true
      ) {
        setPinPermission(true);
      } else {
        setPinPermission(false);
      }
    } else {
      if (
        post.permissions &&
        post.permissions.AddFlag_PinPostsUnderGroup == true
      ) {
        setPinPermission(true);
      } else {
        setPinPermission(false);
      }
    }
  }, [post]);

  const OnOpen = () => {
    setOpen(true);
  };
  const Onclose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    let postCreatedOn = post && post.postCreatedOn;
    let newDate = new Date(postCreatedOn);
    let todayDate = new Date();
    let daysLeft =
      post &&
      post.quiz_details &&
      post.quiz_details.quiz_duration &&
      parseInt(post.quiz_details.quiz_duration.split(" ")[0]);
    newDate.setHours(newDate.getHours() + daysLeft * 24);
    // To calculate the time difference of two dates
    let Difference_In_Time = newDate.getTime() - todayDate.getTime();

    function dhm(ms) {
      const days = Math.floor(ms / (24 * 60 * 60 * 1000));
      const daysms = ms % (24 * 60 * 60 * 1000);
      const hours = Math.floor(daysms / (60 * 60 * 1000));
      if (days == 0) {
        return hours + " " + "hours left";
      } else {
        return days + " " + "days &" + " " + hours + " " + "hours left";
      }
    }

    if (Difference_In_Time > 0) {
      setDaysLeft(dhm(Difference_In_Time));
    } else {
      setDaysLeft("Quiz has expired");
    }
  }, [post]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  React.useEffect(() => {
    if (post.postFrom == "channel" || id) {
      if (
        post.permissions &&
        post.permissions.AddFlag_CreateQuizUnderChannel == true
      ) {
        if (quizFromQuiz) {
          setPostmoreoption(channelData.postMoreOptionsForQuiz.slice(0, 1));
        } else {
          setPostmoreoption(channelData.postMoreOptionsForQuiz);
        }
      } else {
        if (quizFromQuiz) {
          setPostmoreoption([]);
        } else {
          setPostmoreoption([{ name: "Delete Quiz", icon: <DeleteIcon /> }]);
        }
      }
    } else {
      if (
        post.permissions &&
        post.permissions.AddFlag_CreateQuizUnderGroup == true
      ) {
        if (quizFromQuiz) {
          setPostmoreoption(data.postMoreOptionsForQuiz.slice(0, 2));
        } else {
          setPostmoreoption(data.postMoreOptionsForQuiz);
        }
      } else {
        if (quizFromQuiz) {
          setPostmoreoption([]);
        } else {
          setPostmoreoption([{ name: "Delete Quiz", icon: <DeleteIcon /> }]);
        }
      }
    }
  }, [post]);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  function ChangeDate(date) {
    let newDate = new Date(date);
    var hours = newDate.getHours();
    var minutes = newDate.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    let dateToDisplay =
      newDate.getDate() +
      " " +
      monthNames[newDate.getMonth()] +
      " " +
      newDate.getFullYear() +
      " " +
      strTime;

    return dateToDisplay;
  }

  function PinPermissionRenderer() {
    if (pinPermission) {
      if (!matchesSmallScreen) {
        return post.isPinnedPost ? (
          <Tooltip title={<h2>Unpin post</h2>} placement="top">
            <IconButton aria-label="pin" onClick={pinHandler}>
              <PinIcon color="secondary" />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title={<h2>Pin post</h2>} placement="top">
            <IconButton aria-label="pin" onClick={pinHandler}>
              <PinIcon style={{ color: "#C8CDD0" }} />
            </IconButton>
          </Tooltip>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  return (
    <Grid style={{ marginBottom: "3.5%" }}>
      <Paper elevation={2}>
        <Card>
          <CardHeader
            avatar={
              <Avatar
                onClick={() => navigation(`/users/people/${post.user_id}`)}
                aria-label="recipe"
                className={classes.avatar}
                style={{ width: "48px", height: "48px", cursor: "pointer" }}
                alt={post.userName.charAt(0).toUpperCase()}
                src={
                  post.user_profile &&
                  process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(0, -1) +
                    post.user_profile
                }
              ></Avatar>
            }
            action={
              searchedPost ? null : (
                <>
                  <PinPermissionRenderer />
                  {!matchesSmallScreen ? (
                    <IconButton aria-label="settings">
                      {post.category}
                    </IconButton>
                  ) : null}
                  {post.owner ? (
                    <>
                      <IconButton
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        aria-label="settings"
                        ref={anchorRef}
                        onClick={() => {
                          dispatch({
                            type: POST_SHOW_MORE,
                            payload: { id: post.id, showMore: true },
                          });
                          dispatch(EMDoGetPollSharedWithGroupsListAction());
                        }}
                      >
                        <MoreHoriz />
                      </IconButton>
                      <EMDialogBox
                        shouldOpen={post.shouldOpen}
                        onSelect={(shouldProcess) => {
                          if (shouldProcess) {
                            let queryData = {
                              id: post.id,
                            };

                            dispatch(EMDoDeleteQuizAction({ queryData }));
                          } else {
                            dispatch({
                              type: OPEN_DELETE_CONFIRMATION_DIALOG,
                              payload: { id: post.id, shouldOpen: false },
                            });
                          }
                          dispatch({
                            type: OPEN_DELETE_CONFIRMATION_DIALOG,
                            payload: { id: post.id, shouldOpen: false },
                          });
                        }}
                        dialogText={
                          "Are you sure you want to delete the quiz ?"
                        }
                      />
                      <Menu
                        aria-controls="customized-menu"
                        getContentAnchorEl={null}
                        anchorEl={anchorRef.current}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        open={post.showMore}
                        onClose={() => {
                          dispatch({
                            type: POST_SHOW_MORE,
                            payload: { id: post.id, showMore: false },
                          });
                        }}
                      >
                        <>
                          {postMoreoptions &&
                            postMoreoptions.length > 0 &&
                            postMoreoptions.map((option, index) => (
                              <MenuItem
                                key={index}
                                onClick={() => {
                                  if (
                                    String(option.name).includes("Edit Quiz") ||
                                    String(option.name).includes(
                                      "Share with Groups"
                                    )
                                  ) {
                                    dispatch({
                                      type: TO_GET_THE_POLL_FORM,
                                      payload: post.is_open_ended_question
                                        ? false
                                        : true,
                                    });
                                    dispatch({
                                      type: POST_SHOW_MORE_SELECTED,
                                      payload: {
                                        id: post.id,
                                        option: "Edit Post",
                                        args: {
                                          editPost: true,
                                          title: "Edit Quiz",
                                          button: "Update Quiz",
                                        },
                                      },
                                    });
                                  } else if (
                                    String(option.name).includes("Delete Quiz")
                                  ) {
                                    dispatch({
                                      type: OPEN_DELETE_CONFIRMATION_DIALOG,
                                      payload: {
                                        id: post.id,
                                        shouldOpen: true,
                                      },
                                    });
                                  }
                                }}
                              >
                                <ListItemIcon>{option.icon}</ListItemIcon>
                                <Typography variant="inherit">
                                  {option.name}
                                </Typography>
                              </MenuItem>
                            ))}
                          {matchesSmallScreen ? (
                            <MenuItem onClick={pinHandler}>
                              <ListItemIcon>
                                {post.isPinnedPost ? (
                                  <PinIcon color="secondary" />
                                ) : (
                                  <PinIcon style={{ color: "#C8CDD0" }} />
                                )}
                              </ListItemIcon>
                              <Typography variant="inherit">
                                {post.isPinnedPost ? "Unpin Quiz" : "Pin Quiz"}
                              </Typography>
                            </MenuItem>
                          ) : null}
                        </>
                      </Menu>
                    </>
                  ) : null}
                </>
              )
            }
            title={
              !matchesSmallScreen ? (
                <Box alignItems="center" display="flex">
                  <Box align="start">
                    <Typography
                      className={classes.title}
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigation(`/users/people/${post.user_id}`)
                      }
                    >
                      {post.userName},&nbsp;
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      style={{ fontSize: "small" }}
                      className={classes.typo}
                    >
                      {post.organisationName}
                    </Typography>
                  </Box>
                  {post.postFrom == "channel" ? null : (
                    <>
                      <Box>
                        <ChevronRightIcon style={{ marginTop: "30%" }} />
                      </Box>
                      <Box
                        paddingRight={0.8}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Avatar
                          className={classes.groupAvtar}
                          src={
                            post.groupLogo &&
                            post.groupLogo.includes("localhost")
                              ? post.groupLogo
                              : process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(
                                  0,
                                  -1
                                ) + post.groupLogo
                          }
                        ></Avatar>
                      </Box>
                      <Box>
                        <Typography className={classes.title}>
                          {post.groupName}
                        </Typography>
                      </Box>
                    </>
                  )}
                </Box>
              ) : (
                <Box alignItems="center">
                  <Box display="flex">
                    <Box align="start">
                      <Typography
                        className={classes.title}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigation(`/users/people/${post.user_id}`)
                        }
                      >
                        {post.userName},&nbsp;
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        style={{ fontSize: "small" }}
                        className={classes.typo}
                      >
                        {post.organisationName}
                      </Typography>
                    </Box>
                  </Box>
                  {post.postFrom == "channel" ? null : (
                    <Box>
                      <Typography className={classes.title}>
                        {post.groupName}
                      </Typography>
                    </Box>
                  )}
                </Box>
              )
            }
            subheader={
              <>
                <Typography>{post.designation && post.designation}</Typography>
                <Typography>
                  {post.postCreatedOn && ChangeDate(post.postCreatedOn)}
                </Typography>
              </>
            }
          />
        </Card>
        <CardContent>
          <Grid>
            <Typography
              variant="h6"
              component="h6"
              style={{ fontWeight: "bolder", paddingBottom: 10 }}
            >
              {post.quiz_details && post.quiz_details.title}
            </Typography>
            <Typography style={{ paddingBottom: 0, fontSize: "14px" }}>
              {post.quiz_details && post.quiz_details.question}
            </Typography>
            <Typography color="textSecondary">
              {post.quiz_details &&
              post.quiz_details.quiz_privacy === "Regular" &&
              post.owner
                ? "You can see how people answer, "
                : post.quiz_details &&
                  post.quiz_details.quiz_privacy === "Regular" &&
                  post.owner == false
                ? "Author can see how you answer, "
                : null}
              {dayLeft}
            </Typography>
            <Typography color="textSecondary" style={{ paddingBottom: 10 }}>
              {post.quiz_details && post.quiz_details.vote_count} answers
            </Typography>
            {(post.quiz_details && post.quiz_details.isVoted === true) ||
            (post.quiz_details && post.quiz_details.is_expired === true) ? (
              <Paper style={{ paddingBottom: "2%" }}>
                <Typography>Your Answer:</Typography>
                <Typography
                  style={{
                    fontWeight: "bolder",
                    paddingBottom: 10,
                    fontSize: "14px",
                  }}
                >
                  {post.quiz_details && post.quiz_details.your_answer}
                </Typography>
                <Typography>Correct Answer:</Typography>
                <Typography
                  style={{
                    fontWeight: "bolder",
                    paddingBottom: 10,
                    fontSize: "14px",
                  }}
                >
                  {post.quiz_details && post.quiz_details.right_ans}
                </Typography>
              </Paper>
            ) : (
              <>
                <RadioGroup
                  aria-label="options"
                  name="controlled-radio-buttons-group"
                  value={value}
                  onChange={handleChange}
                  style={{ display: "inline-block" }}
                >
                  <FormControl className={classes.formControl}>
                    {post.quiz_details &&
                      post.quiz_details.Answers.map((poll, id) => (
                        <FormControlLabel
                          key={id}
                          value={poll.Ans && poll.Ans}
                          control={<Radio />}
                          label={poll.Ans && poll.Ans}
                        />
                      ))}
                  </FormControl>
                </RadioGroup>
                <Grid
                  item
                  xs={6}
                  container
                  alignItems="center"
                  justifyContent={matchesSmallScreen ? "center" : "flex-start"}
                  style={{ padding: "16px" }}
                >
                  <Button
                    disabled={value.length == 0}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      let selectedAnsObject = post.quiz_details.Answers.find(
                        (poll) => poll.Ans == value
                      );
                      let finalObject = {
                        quiz_id: post.quiz_details.id,
                        option_id: selectedAnsObject.id,
                      };
                      post.quiz_details &&
                        dispatch(EMDoSubmitQuizAction({ finalObject }));
                    }}
                  >
                    Submit Quiz
                  </Button>
                </Grid>
              </>
            )}
            {quizFromQuiz && post.owner && post.quiz_details ? (
              <>
                <Box
                  p={2}
                  display="flex"
                  alignItems="center"
                  justifyContent={matchesSmallScreen ? "space-evenly" : ""}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (post.quiz_details.quiz_privacy === "Anonymous") {
                        setAnonymousQuiz(true);
                      }
                      dispatch(
                        post.quiz_details &&
                          EMDoGetQuizReportAction({
                            quizId: post.id,
                            query: "",
                          })
                      );
                      setTimeout(() => {
                        OnOpen();
                      }, 100);
                    }}
                  >
                    See More Details
                  </Button>
                  <Button
                    style={{ marginLeft: "2%" }}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      dispatch(
                        post.quiz_details &&
                          EMDownloadQuizReportsAction({ quizId: post.id })
                      );
                    }}
                  >
                    export report
                  </Button>
                </Box>

                <Modal open={open} onClose={Onclose}>
                  <Paper className={classes.paper}>
                    <EMSimpleDatatable
                      columns={anonymousQuiz ? anonymousQuizColumns : columns}
                      data={
                        (anonymousQuiz
                          ? anoymousQuizDetails
                          : regularQuizDetails) || []
                      }
                      count={
                        (quizData.quizDetails && quizData.quizDetails.count) ||
                        0
                      }
                      updatePageAndPageSizeAndOrder={(
                        page,
                        pageSize,
                        order
                      ) => {
                        setOffset(page * limit);
                        setLimit(pageSize);
                        setPage(page + 1);
                        setOrder(order);
                      }}
                      options={{
                        headerStyle: {
                          backgroundColor: theme.palette.secondary.main,
                          color: theme.palette.common.white,
                          fontWeight: "bold",
                        },
                      }}
                    />
                  </Paper>
                </Modal>
              </>
            ) : null}
          </Grid>
        </CardContent>
        {post.editPost.showEditPost ? (
          <Modal
            className={classes.editPost}
            open={post.editPost.showEditPost}
            onClose={() => {
              dispatch({
                type: SHOW_POST_EDIT,
                payload: {
                  id: post.id,
                  editPost: false,
                  title: "Edit Quiz",
                  button: "Update Quiz",
                },
              });
            }}
          >
            <EMCreateQuiz
              selectedChannelIds={[
                `${post.groupName} |${post.channel_details[0].channel_id}`,
              ]}
              post={post}
              id={id}
              usedIn={usedIn}
              postFrom={post.postFrom}
            />
          </Modal>
        ) : null}
      </Paper>
    </Grid>
  );
}

function PinIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M23.541,23.763l-9.431-5.9a2.321,2.321,0,0,0-3.306.958,2.745,2.745,0,0,0,.886,3.581l9.429,5.9a2.32,2.32,0,0,0,3.306-.959,2.739,2.739,0,0,0-.885-3.58Zm-7.778-6.378L23.1,21.973l2.495-7.521-5.065-3.167ZM10.356,36.706l7.017-9.233-3.144-1.966-3.873,11.2ZM28.625,10.292,22.339,6.36a1.74,1.74,0,0,0-2.479.72,2.057,2.057,0,0,0,.664,2.686L26.811,13.7a1.74,1.74,0,0,0,2.479-.72,2.057,2.057,0,0,0-.665-2.686Z"
        transform="translate(-10.356 -6.096)"
      />
    </SvgIcon>
  );
}

function DataTable(props) {
  return (
    <div>
      <Grid style={{ padding: "2%" }}>
        <EMSimpleDatatable
          columns={props.columns}
          data={(props.Listdata && props.Listdata) || []}
          count={(props.Listdata && props.Listdata.length) || 0}
          updatePageAndPageSizeAndOrder={() =>
            props.updatePageAndPageSizeAndOrder()
          }
          options={{
            headerStyle: {
              backgroundColor: props.theme.palette.secondary.main,
              color: props.theme.palette.common.white,
              fontWeight: "bold",
            },
          }}
        />
      </Grid>
    </div>
  );
}
