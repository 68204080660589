import {
  ROLES_LIST_SUCCESS,
  USERDATA_FETCH_SUCCESS,
  USER_CREATE_SUCCESS,
  ENTITY_ROLE_RETRIEVE_SUCCESS,
  USER_INFO,
  GET_NOTIFICATIONS,
  GET_ORGANIZATION_TYPE,
  GET_ORGANIZATION_TYPE_FIELDS,
  GET_ORGANIZATION_LEVELS,
  GET_ORGANIZATION_DEPARTMENTS,
  GET_LIST_PRONOUNS,
  GET_LIST_GENDER_IDENTITY,
  GET_LIST_RACE,
  GET_LIST_MINORITY_IDENTIFICATION,
  GET_LIST_WORK_TYPES,
  GET_COUNTRY_CODES,
  GET_PHONE_TYPES,
  CHANGE_TAB_INDEX_TO_ZERO,
  AUTH_LOGOUT,
  GET_ETHNICITY_TYPES,
  OPEN_CLOSE_CRASH_REPORT_DIALOG_BOX,
  GET_CRASH_REPORT_TEXT,
  MARK_NOTIFICATION_AS_SEEN,
} from "../constants";
import { cloneDeep, findIndex, find } from "lodash";
import {
  LIST_USER_POSTS,
  USER_EXPAND_TEXT,
  USER_EXPAND_COMMENT,
  USER_SHOW_COMMENT,
  LIST_HASHTAG,
  LIST_MENTIONS,
  POST_SHOW_HASHTAG,
  POST_EMOJI_SELECTED,
  POST_HASHTAG_SELECTED,
  POST_NEW_COMMENT,
  SUBMIT_NEW_COMMENT,
  POST_SHOW_TAGPEOPLE,
  POST_SHOW_EMOJI,
  POST_TAGPEOPLE_SELECTED,
  SHOW_POST_MEDIA_PREVIEW,
  LIKE_DISLIKE_POSTS,
  DELETE_POST_COMMENT,
  GET_POST_BY_NOTIFICATION_TAG,
  PLAY_VIDEO_BY_ID,
  CHANGE_LIKE_STATUS_OF_POST,
  CHANGE_LIKE_BUTTON_STATE,
} from "../constants/UserPlatform";

const initialState = {
  posts: [],
  org_types: [],
  org_type_fields: [],
  org_type_levels: [],
  org_type_departments: [],
  pronouns: [],
  genders: [],
  race: [],
  minority_identification: [],
  work_types: [],
  phone_types: [],
  country_codes: [],
  tabIndex:0,
  ethnicity:[],
  crashReportDialogState:false,
  crashReportText:"",
  nextDataForNotifications:null,
  notificationCount: 0, 
  notifications:[]
}

export default function EMCommonReducer(
  state = initialState,
  action
) {
  const newState = cloneDeep(state);

  switch (action.type) {
    case ROLES_LIST_SUCCESS: {
      //check if permission is related to role

      //check if applicable
      let data = action.payload;
      // data.map(permission=>{
      // let propertyCheck =permission.hasOwnProperty("add_flag_applicable")
      // if(propertyCheck){
      //   permission["add_flag"] = permission["add_flag_applicable"]
      //   permission["edit_flag"] = permission["edit_flag_applicable"]
      //   permission["view_flag"] = permission["view_flag_applicable"]

      // }
      // })
      newState["roles"] = data;
      // newState["roles"] = action.payload;
      return newState;
    }

    case CHANGE_TAB_INDEX_TO_ZERO:{
      newState["tabIndex"] = action.payload
      return newState
    }

    case ENTITY_ROLE_RETRIEVE_SUCCESS: {
      let data = action.payload;
      if (data.hasOwnProperty("rolePermissions")) {
        newState["permissionsForRole"] = action.payload;
        let rolesData = newState["roles"];
        rolesData.map((permission) => {
          data.rolePermissions.map((rolePermission) => {
            if (rolePermission.permission_name == permission.permission_name) {
              permission["add_flag"] = rolePermission.add_flag;
              permission["edit_flag"] = rolePermission.edit_flag;
              permission["view_flag"] = rolePermission.view_flag;
            }
          });

          //check permission such that it is not in response data and has values rspectively
          let condition = find(data.rolePermissions, {
            permission_name: permission.permission_name,
          });
          condition = !condition;
          if (permission.hasOwnProperty("add_flag") && condition) {
            delete permission.add_flag;
          }
          if (permission.hasOwnProperty("edit_flag") && condition) {
            delete permission.edit_flag;
          }
          if (permission.hasOwnProperty("view_flag") && condition) {
            delete permission.view_flag;
          }
        });

        rolesData.map((permission) => {
          if (
            permission.permission_name == "create_posts_in_group" &&
            permission.add_flag == true
          ) {
            permission["edit_flag"] = permission["add_flag"];
          }
          if (
            permission.permission_name == "create_polls_in_group" &&
            permission.add_flag == true
          ) {
            permission["edit_flag"] = permission["add_flag"];
          }
          if (
            permission.permission_name == "create_events_in_group" &&
            permission.add_flag == true
          ) {
            permission["edit_flag"] = permission["add_flag"];
          }
          if (
            permission.permission_name == "create_polls_in_channel" &&
            permission.add_flag == true
          ) {
            permission["edit_flag"] = permission["add_flag"];
          }
          if (
            permission.permission_name == "create_posts_in_channel" &&
            permission.add_flag == true
          ) {
            permission["edit_flag"] = permission["add_flag"];
          }
          if (
            permission.permission_name == "create_quiz_in_channel" &&
            permission.add_flag == true
          ) {
            permission["edit_flag"] = permission["add_flag"];
          }
          if (
            permission.permission_name == "create_quiz_in_group" &&
            permission.add_flag == true
          ) {
            permission["edit_flag"] = permission["add_flag"];
          }
        });
        newState["roles"] = rolesData;
      }
      return newState;
    }

    case GET_NOTIFICATIONS: {
      newState["nextDataForNotifications"] = action.payload.response.next
      newState["notificationCount"] = action.payload.response.count
      if (action.payload.limit == 0) {
        newState["notifications"] = action.payload.response.results.map(d => ({...d, isSeen:false}));
      } else {
        newState["notifications"] = newState["notifications"].concat(
          action.payload.response.results.map(d => ({...d, isSeen:false}))
        );
      }
      return newState;
    }

    case MARK_NOTIFICATION_AS_SEEN:{
      let notificationId = action.payload
      let notificationIndex = findIndex(newState["notifications"], {id: notificationId});
      if(notificationIndex >= 0){
        newState["notifications"][notificationIndex].isSeen = true
      }
      return newState
    }

    case USERDATA_FETCH_SUCCESS: {
      newState["userData"] = action.payload;
      return newState;
    }

    case USER_CREATE_SUCCESS: {
      newState["user"] = action.payload;
      return newState;
    }

    case USER_INFO: {
      newState["loginUserInfo"] = action.payload;
      return newState;
    }

    case GET_POST_BY_NOTIFICATION_TAG: {
      if (action.payload) {
        newState["posts"] = [action.payload.response];
      }

      newState["posts"].map((post) => {
        post["userName"] = post["firstName"] + " " + post["lastName"];
        post["expandText"] = false;
        post["expandPostComment"] = false;
        post["showComments"] = false;
        post["showHashTag"] = false;
        post["showTagPeople"] = false;
        post["showEmoji"] = false;
        post["mediaView"] = false;
        post["likeButtonDisabled"] = false
        let start = new Date(post["event_start_date"]);
        let end = new Date(post["event_end_date"]);
        function ChangeDate(date) {
          var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

          if (month.length < 2) month = "0" + month;
          if (day.length < 2) day = "0" + day;

          return [year, month, day].join("-");
        }
        function ChangeTime(date) {
          return (
            ("00" + date.getHours()).slice(-2) +
            ":" +
            ("00" + date.getMinutes()).slice(-2) +
            ":" +
            ("00" + date.getSeconds()).slice(-2)
          );
        }

        function showTimeZone(date) {
          const dateAsString = date.toString();
          const timezone = dateAsString.match(/\(([^\)]+)\)$/)[1];
          return timezone;
        }

        post["event_start"] = ChangeDate(start);
        post["event_end"] = ChangeDate(end);
        post["event_start_time"] = ChangeTime(start);
        post["event_end_time"] = ChangeTime(end);
        post["showMore"] = false;
        post["timeZone"] = showTimeZone(start);

        post["channel_details"].map((channel) => {
          if (channel.channel_name.includes("MAIN_CHANNEL")) {
            post["postFrom"] = "home";
          } else {
            post["postFrom"] = "channel";
          }
        });

        post["shouldOpen"] = false;
        post["images"].map((img) => {
          img["type"] = "images";
          img["isPlaying"] = false
        });
        post["videos"].map((video) => {
          video["type"] = "videos";
          video["isPlaying"] = false
        });
        post["video_links"].map((video) => {
          video["type"] = "video_link";
          video["isPlaying"] = false
        });
        post["documents"].map((doc) => {
          doc["type"] = "documents";
        });
        post["totalMedia"] = post["images"].concat(post["videos"]);
        post["totalCombinedMedia"] = post["totalMedia"].concat(
          post["video_links"]
        );
        // if(post["totalMedia"].length > 10) {
        //   post["totalCombinedMedia"] = post["totalMedia"].slice(0,10)
        // } else if (post["totalMedia"].length < 10) {
        //   post["totalCombinedMedia"] = post["totalMedia"]
        // }
        post["postAtMentions"] = [];
        post["editPost"] = {
          showEditPost: false,
          labels: { title: "", button: "" },
        };
        post["newComment"] = "";
        post["owner"] =
          post.userName == action.payload.userData.name ? true : false;
        let postCategory = post["category"];

        // newState["categories"].map(category => {

        //   if (category.category_name && category.category_name.includes(postCategory)) {
        //     post["category"] = category.icon;
        //   }
        // });
      });
      return newState;
    }

    case PLAY_VIDEO_BY_ID: {
      let postId = action.payload.postId;
      let postIndex = findIndex(newState["posts"], { id: postId });
      let videoId = action.payload.videoId;
      let media = action.payload.media;
      let previewOpen = action.payload.fromPreview;
      ;
      if (previewOpen == false) {
        if (postIndex >= 0) {
          let videoIndex = findIndex(
            newState["posts"][postIndex].totalCombinedMedia,
            { id: videoId }
          );
          if (videoIndex >= 0) {
            newState["posts"][postIndex].totalCombinedMedia = newState["posts"][
              postIndex
            ].totalCombinedMedia.map((item) => ({ ...item, isPlaying: false }));
            let upcomingMedia = media;
            newState["posts"][postIndex].totalCombinedMedia[videoIndex] =
              upcomingMedia;
          }
        }
      } else {
        if (postIndex >= 0) {
          if (media.type == "videos") {
            let videoIndex = findIndex(newState["posts"][postIndex].videos, {
              id: videoId,
            });
            if (videoIndex >= 0) {
              newState["posts"][postIndex].videos = newState["posts"][
                postIndex
              ].videos.map((item) => ({ ...item, isPlaying: false }));
              let upcomingMedia = media;
              newState["posts"][postIndex].videos[videoIndex] = upcomingMedia;
              newState["posts"][postIndex].totalCombinedMedia = newState["posts"][
                postIndex
              ].totalCombinedMedia.map((item) => ({ ...item, isPlaying: false }));
            }
          } else {
            let videoIndex = findIndex(
              newState["posts"][postIndex].video_links,
              { id: videoId }
            );
            if (videoIndex >= 0) {
              newState["posts"][postIndex].video_links = newState["posts"][
                postIndex
              ].video_links.map((item) => ({ ...item, isPlaying: false }));
              let upcomingMedia = media;
              newState["posts"][postIndex].video_links[videoIndex] =
                upcomingMedia;
                newState["posts"][postIndex].totalCombinedMedia = newState["posts"][
                  postIndex
                ].totalCombinedMedia.map((item) => ({ ...item, isPlaying: false }));
            }
          }
        }
      }

      return newState;
    }

    case USER_EXPAND_TEXT: {
      let id = action.payload.id;
      let posts = newState["posts"].map((post) => {
        if (post.id == id) {
          return { ...post, expandText: action.payload.content };
        } else {
          return { ...post };
        }
      });
      newState["posts"] = posts;
      return newState;
    }
    case USER_EXPAND_COMMENT: {
      let id = action.payload.id;
      let posts = newState["posts"].map((post) => {
        if (post.id == id) {
          return { ...post, expandPostComment: action.payload.content };
        } else {
          return { ...post };
        }
      });
      newState["posts"] = posts;
      return newState;
    }
    case USER_SHOW_COMMENT: {
      let id = action.payload.id;
      let posts = newState["posts"].map((post) => {
        if (post.id == id) {
          return { ...post, showComments: action.payload.content };
        } else {
          return { ...post };
        }
      });
      newState["showCommentsGlobal"] = action.payload.content;
      newState["posts"] = posts;
      return newState;
    }

    case LIST_HASHTAG: {
      newState["postHashTags"] = action.payload;
      return newState;
    }
    //mentions for post
    case LIST_MENTIONS: {
      newState["posts"].map((post) => {
        if (post.id == action.payload.id) {
          post["postAtMentions"] = action.payload.response;
        }
      });
      return newState;
    }

    /* Hashtag added in comment */
    case POST_SHOW_HASHTAG: {
      newState["posts"].map((post) => {
        if (post.id == action.payload.id) {
          post["showHashTag"] = action.payload.showHashtag;
        }
      });
      return newState;
    }
    case POST_EMOJI_SELECTED: {
      newState["posts"].map((post) => {
        if (post.id == action.payload.id) {
          post["newComment"] = post["newComment"].substr(
            0,
            post["newComment"].length
          );
          post["newComment"] =
            post["newComment"] + action.payload.selectedEmoji;
        }
      });
      return newState;
    }
    case POST_HASHTAG_SELECTED: {
      newState["posts"].map((post) => {
        if (post.id == action.payload.id) {
          post["newComment"] = post["newComment"].substr(
            0,
            post["newComment"].length - 1
          );
          post["newComment"] =
            post["newComment"] + action.payload.selectedHashTag;
        }
      });
      return newState;
    }
    /* comment update */
    case POST_NEW_COMMENT: {
      newState["posts"].map((post) => {
        if (post.id == action.payload.id) {
          post["newComment"] = action.payload.comment;
        }
      });
      return newState;
    }
    case SUBMIT_NEW_COMMENT: {
      //submit content from newComment for that Id
      let data = action.payload.response;
      let userData = action.payload.userData;
      newState["posts"].map((post) => {
        if (post.id == data.post_id) {
          let content = data.content;
          let commentedOn = data.created_on;
          let newComment = {
            first_name: data.first_name,
            last_name: data.last_name,
            // userName: userData.name,
            organisationName: userData.organization_name,
            // designation: userData.designation == null ? "" : userData.designation,
            designation: data.user_designation,
            comment: content,
            // profileIcon: userData.user_profile,
            profileIcon: data.user_profile_pic,
            commentedOn: commentedOn,
            comment_id: data.id,
            user_id: userData.profile_id,
          };
          if (post.comments == null) {
            post.comments = [];
          }
          post.comments.push(newComment);
          post.newComment = "";
          // post.comments++
        }
      });
      return newState;
    }
    /* TagPeople in Comment */
    case POST_SHOW_TAGPEOPLE: {
      newState["posts"].map((post) => {
        if (post.id == action.payload.id) {
          post["showTagPeople"] = action.payload.showTagPeople;
        }
      });
      return newState;
    }
    case POST_SHOW_EMOJI: {
      newState["posts"].map((post) => {
        if (post.id == action.payload.id) {
          post["showEmoji"] = action.payload.showEmoji;
        }
      });
      return newState;
    }
    case POST_TAGPEOPLE_SELECTED: {
      newState["posts"].map((post) => {
        if (post.id == action.payload.id) {
          post["newComment"] = post["newComment"].substr(
            0,
            post["newComment"].length
          );
          post["newComment"] =
            post["newComment"] + action.payload.selectedAtPeople.mentions.name;
        }
      });
      return newState;
    }

    case SHOW_POST_MEDIA_PREVIEW: {
      newState["posts"].map((post) => {
        if (post.id == action.payload.id) {
          post["mediaView"] = action.payload.showPreview;
        }
      });
      return newState;
    }

    case LIKE_DISLIKE_POSTS: {
      let postId = action.payload.id;
      let postIndex = findIndex(newState["posts"], { id: postId });

      if (postIndex >= 0) {
        newState["posts"][postIndex].liked = action.payload.is_liked;
        newState["posts"][postIndex].likes = action.payload.like_count
      }
      return newState;
    }

    case CHANGE_LIKE_STATUS_OF_POST:{
      let postId = action.payload.id
      let postIndex = findIndex(newState["posts"], {id: postId});
      if(postIndex >= 0){
        newState["posts"][postIndex].liked = action.payload.isLiked
      }
      return newState
    }

    case CHANGE_LIKE_BUTTON_STATE:{
      let postId = action.payload.id
      let postIndex = findIndex(newState["posts"], {id: postId});
      if(postIndex >= 0){
        newState["posts"][postIndex].likeButtonDisabled = action.payload.isDisabled
      }
      return newState
    }

    case DELETE_POST_COMMENT: {
      let data = action.payload;
      newState["posts"].map((post) => {
        if (post.id == data.postId) {
          let commentIndex = findIndex(post.comments, {
            comment_id: data.commentId,
          });
          post.comments.splice(commentIndex, 1);
        }
      });
      return newState;
    }

    case GET_ORGANIZATION_LEVELS: {
      let data = action.payload;
      newState["org_type_levels"] = data;
      return newState;
    }

    case GET_ORGANIZATION_DEPARTMENTS: {
      let data = action.payload;
      newState["org_type_departments"] = data;
      return newState;
    }

    case GET_LIST_PRONOUNS: {
      let data = action.payload;
      data = data.map((pronoun, index) => ({
        ...pronoun,
        id: index,
        pronoun: pronoun[index + 1],
      }));
      newState["pronouns"] = data;
      return newState;
    }

    case GET_LIST_GENDER_IDENTITY: {
      let data = action.payload;
      data = data.map((gender, index) => ({
        ...gender,
        id: index,
        gender: gender[index + 1],
      }));
      newState["genders"] = data;
      return newState;
    }

    case GET_LIST_RACE: {
      let data = action.payload;
      data = data.map((race, index) => ({
        ...race,
        id: index,
        race: race[index + 1],
      }));
      newState["race"] = data;
      return newState;
    }

    case GET_LIST_MINORITY_IDENTIFICATION: {
      let data = action.payload;
      data = data.map((minority, index) => ({
        ...minority,
        id: index,
        minority: minority[index + 1].trim(),
      }));
      newState["minority_identification"] = data;
      return newState;
    }

    case GET_LIST_WORK_TYPES: {
      let data = action.payload;
      data = data.map((work_types, index) => ({
        ...work_types,
        id: index,
        work_types: work_types[index + 1],
      }));
      newState["work_types"] = data;
      return newState;
    }

    case GET_PHONE_TYPES: {
      let data = action.payload;
      data = data.sort(function (a, b) {
        return (Object.keys(a)[0] > Object.keys(b)[0]) - 0.5;
      });
      data = data.map((phone_types, index) => ({
        ...phone_types,
        id: index,
        phone_types: phone_types[index + 1],
      }));
      newState["phone_types"] = data;
      return newState;
    }

    case GET_COUNTRY_CODES: {
      let data = action.payload;
      newState["country_codes"] = data;
      return newState;
    }

    case GET_ETHNICITY_TYPES:{
      let data = action.payload
      newState["ethnicity"] = data
      return newState
    }

    case OPEN_CLOSE_CRASH_REPORT_DIALOG_BOX:{
      let state = action.payload
      newState["crashReportDialogState"] = state
      return newState
    }

    case GET_CRASH_REPORT_TEXT:{
      let data = action.payload
      newState["crashReportText"] = data
      return newState
    }

    case AUTH_LOGOUT: {
      return { ...initialState };
    }

    default: {
      return newState;
    }
  }
}
