import React, { useState, useEffect, useRef, useCallback } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import EMGroupDetails from "./EMGroupDetails";
import EMGroupDetailsForNew from "./EMGroupDetailsForNew";
import EMPage from "../../../../../components/commans/EMPage";
import { Box, Grid, useMediaQuery } from "@material-ui/core";
import EMPost from "../../../../../components/user/EMPost";
import EMChannelsPreview from "../../../../../components/user/EMChannelsPreview";
import { useSelector, useDispatch } from "react-redux";
import EMEvent from "../../../../../components/user/EMEvent";
import EMDropdown from "../../../../../components/user/EMCategoriesDropdown";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  // EMDoGetPostsListAction,
  EMDoLikeDislikePinUnpinPostAction,
  EMDoGetPostSharedWithGroupsListAction,
  EMDoGetHashTagListsAction,
  EMDoGetCategoryListAction,
  // EMDoGetCategoryFilteredPostListAction,
  EMDoLikeDislikePostAction,
} from "../../../../../redux/actions/UserPlatform/Home/EMLatestPostPageAction";
import {
  EMDoGetLoggedInUserRolesAction,
  EMGetRolesAndPermissionsBasedOnEntityAction,
} from "../../../../../redux/actions/EMAuthActions";
import {
  EMDoGetGroupPostsAction,
  EMDoGetGroupDetailsAction,
  EMDoGetCategoryFilteredPostForGroupListAction,
  EMDoActionForGroupAction,
  EMDoGetChannelListByGroupIdAction,
  EMDoGetEmailNotificationGroupsAction
} from "../../../../../redux/actions/UserPlatform/Groups/EMGroupsAction";
import {
  SHOW_NEXT_POSTS,
  POST_CATEGORY_SELECTED_DATA,
  CLEAR_POST_FROM_STATE,
  CHANGE_LIKE_STATUS_OF_POST,
  CHANGE_LIKE_BUTTON_STATE,
  // CLEAR_POST_FROM_STATE_FOR_EVERY_LOAD,
} from "../../../../../redux/constants/UserPlatform/index";
import {
  GROUP_EDIT,
  SHOW_INFO_OF_GROUP,
} from "../../../../../redux/constants/UserPlatform/EMGroupConstant";
import { EMDoGetPrivacyList } from "../../../../../redux/actions/EMNetworkAdminActions";
import { debounce, findLast } from "lodash";
import { SHOW_MESSAGE } from "../../../../../redux/constants";
import InfiniteScroll from "react-infinite-scroll-component";
import EMSideBar from "../../../../../components/navigation/EMSideBar";
import EMPoll from "../../../../../components/user/EMPoll";
import EMQuiz from "../../../../../components/user/EMQuiz";
import { CHANGE_ELEMENT_FOR_SUBTABS } from "../../../../../redux/constants/UserPlatform/EMNavigationConstant";
import { EMDoGetGroupDetailsByIdAction } from "../../../../../redux/actions/EMGroupAdminActions";

const useStyles = makeStyles((theme) => ({}));
export default function GroupActivityFeed(props) {
  const data = useSelector((state) => state.groups || {});
  const userData = useSelector((state) => state.auth || {});
  const uiData = useSelector((state) => state.ui || {});
  const [category, setCategory] = useState("");
  const [limit, setLimit] = useState(1);
  const [limitForCategories, setLimitForCategories] = useState(1);
  const groupData = useSelector((state) => state.groupAdmin || {});
  const dispatch = useDispatch();
  const ref = useRef(null);
  const selectedCategory = useSelector((state) => state.home.categorySelected);
  const selectedDataForDropdown = useSelector((state) => state.home.categories);

  const theme = useTheme();
  const matchesMediumScreen = useMediaQuery(theme.breakpoints.up("md"));
  const matchesLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  
  const [permissions, setPermissions] = useState([])

  const location = useLocation();

  const rolesAndPermissionsForSpecificEntity = useSelector(
    (state) => state.auth["rolesAndPermissionsForSpecificEntity"]
  );

  useEffect(() => {
    setPermissions(rolesAndPermissionsForSpecificEntity)
  },[rolesAndPermissionsForSpecificEntity])

  useEffect(() => {
    let groupId = location.state && location.state.GroupId;
    dispatch(
      EMDoGetGroupDetailsAction({
        id: groupId || window.sessionStorage.getItem("GroupId"),
      })
    );
    dispatch({
      type: SHOW_INFO_OF_GROUP,
      payload: false,
    });
    dispatch({
      type: SHOW_NEXT_POSTS,
      payload: {
        nextPosts: undefined,
      },
    });
    dispatch(EMDoGetPrivacyList());
    dispatch(
      EMDoGetChannelListByGroupIdAction({
        groupId: groupId || window.sessionStorage.getItem("GroupId"),
      })
    );
  }, [location]);

  useEffect(() => {
    // dispatch(
    //   EMDoGetGroupPostsAction({
    //     id: window.sessionStorage.getItem("GroupId"),
    //     page: limit,
    //     userData,
    //   })
    // );
    dispatch(EMDoGetHashTagListsAction());
    dispatch(EMDoGetCategoryListAction());
    dispatch(
      EMGetRolesAndPermissionsBasedOnEntityAction({
        entity: "group",
        entityId: window.sessionStorage.getItem("GroupId"),
      })
    );

  }, []);

  useEffect(() => {
    if (uiData["messages"]) {
      let refObj = findLast(uiData["messages"], {
        key: "user_create_poll_post_event_quiz",
      });
      if (refObj && refObj.type === "success") {
        setLimit(1);

        dispatch(
          EMDoGetGroupPostsAction({
            id: window.sessionStorage.getItem("GroupId"),
            page: limit,
            userData,
          })
        );
        refObj.key = "user_create_poll_post_event_quiz_success";
      }
    }
  }, [uiData]);

  useEffect(() => {
    if (uiData["messages"]) {
      let refObj = findLast(uiData["messages"], {
        key: "update_group_settings",
      });
      if (refObj && refObj.type === "success") {
        refObj.key = "update_group_settings_done";
        dispatch(EMDoGetGroupDetailsAction({ id: data.singleGroup.id }));
        dispatch({
          type: GROUP_EDIT,
          payload: false,
        });
      }
    }
  }, [uiData]);

  useEffect(() => {
    setCategory(selectedCategory);
  }, [selectedCategory]);

  const sendQuery = useCallback(async () => {
    if (typeof data.categorySelected === "string") {
      dispatch(
        EMDoGetGroupPostsAction({
          id: window.sessionStorage.getItem("GroupId"),
          page: limit,
          userData,
        })
      );
    } else if (typeof data.categorySelected !== "string") {
      dispatch(
        EMDoGetCategoryFilteredPostForGroupListAction({
          selectedDataOption: data.categorySelected.category_name,
          page: limitForCategories,
          userData,
          id: data.singleGroup.id,
        })
      );
    }
  }, [limit, limitForCategories]);

  useEffect(() => {
    if (typeof data.categorySelected === "string") {
      sendQuery(limit);
    } else if (typeof data.categorySelected !== "string") {
      sendQuery(limitForCategories);
    }
  }, [sendQuery, limit, limitForCategories]);

  const handleObserver = useCallback(() => {
    if (typeof data.categorySelected === "string" && data.next !== null) {
      setLimit((prev) => prev + 1);
    } else if (
      typeof data.categorySelected !== "string" &&
      data.next !== null
    ) {
      setLimitForCategories((prev) => prev + 1);
    }
  }, [limitForCategories, limit, data.categorySelected, data.next]);

  return (
    <InfiniteScroll
      dataLength={data.posts.length}
      hasMore={true}
      next={handleObserver}
    >
      <EMPage
        title="Group Detail"
        style={{
          width: "100%",
          paddingLeft: matchesLargeScreen ? "4%" : "2%",
          paddingTop: matchesLargeScreen
            ? "4%"
            : matchesMediumScreen
            ? "6%"
            : "8%",
          paddingRight: matchesLargeScreen ? "4%" : "2%",
          paddingBottom: "2%",
        }}
      >
        <div id="page" ref={ref}>
          <Grid container direction="row" alignItems="flexStart" spacing={3}>
            {matchesMediumScreen ? (
              <Grid item xs={2}>
                <EMSideBar />
              </Grid>
            ) : null}
            {data.singleGroup && data.singleGroup.is_group_user ? (
              <Grid
                item
                xs={matchesLargeScreen ? 7 : matchesMediumScreen ? 9 : 12}
                style={{ zIndex: 1 }}
              >
                <EMGroupDetails
                  groupId={data.singleGroup.id}
                  onInfoClick={() => {
                    dispatch({
                      type: SHOW_INFO_OF_GROUP,
                      payload: !data.groupInfo,
                    });
                  }}
                  onStarClick={() => {
                    let actions;
                    data.singleGroup.is_fav
                      ? (actions = `remove-favorite`)
                      : (actions = `favorite`);
                    dispatch(
                      EMDoActionForGroupAction({
                        id: data.singleGroup.id
                          ? data.singleGroup.id
                          : window.sessionStorage.getItem("groupId"),
                        myaction: actions,
                        // postIndex:key,
                        route: "group_details",
                      })
                    );
                  }}
                  onNotificationClick={() => {
                    let actions;
                    data.singleGroup.is_subscribed
                      ? (actions = `unsubscribe`)
                      : (actions = `subscribe`);
                    dispatch(
                      EMDoActionForGroupAction({
                        id: data.singleGroup.id
                          ? data.singleGroup.id
                          : window.sessionStorage.getItem("groupId"),
                        myaction: actions,
                        // postIndex:key,
                        route: "group_details",
                      })
                    );
                  }}
                  onEmailNotificationClick={(e) => {
                    dispatch(
                      EMDoGetEmailNotificationGroupsAction({
                        id: data.singleGroup.id
                        ? data.singleGroup.id
                        : window.sessionStorage.getItem("groupId"),
                        is_email_notification: !data.singleGroup.email_notification,
                        route: "email_notification",
                      })
                    );
                  }}
                  groupBanner={
                    data.singleGroup.banner_file
                      ? data.singleGroup.banner_file
                      : null
                  }
                  groupDescription={
                    data.singleGroup.tagline ? data.singleGroup.tagline : null
                  }
                  groupAdditionalInfo={
                    data.singleGroup.additional_info
                      ? data.singleGroup.additional_info
                      : null
                  }
                  groupLogo={
                    data.singleGroup.logo_file
                      ? data.singleGroup.logo_file
                      : null
                  }
                  groupName={
                    data.singleGroup.group_name
                      ? data.singleGroup.group_name
                      : null
                  }
                  groupMembers={
                    data.singleGroup.number_of_group_users
                      ? data.singleGroup.number_of_group_users
                      : null
                  }
                  groupOrganizations={
                    data.singleGroup.organizations
                      ? data.singleGroup.organizations
                      : null
                  }
                  groupMission={
                    data.singleGroup && data.singleGroup.mission_stmt
                      ? data.singleGroup.mission_stmt
                      : null
                  }
                  groupVision={
                    data.singleGroup && data.singleGroup.vision_stmt
                      ? data.singleGroup.vision_stmt
                      : null
                  }
                  is_subscribed={
                    data.singleGroup.is_subscribed
                      ? data.singleGroup.is_subscribed
                      : null
                  }
                  is_fav={
                    data.singleGroup.is_fav ? data.singleGroup.is_fav : null
                  }
                  is_email_notification={
                    data.singleGroup.email_notification ? data.singleGroup.email_notification : null
                  }
                  groupPrivacy={
                    data.singleGroup.privacy_type_name
                      ? data.singleGroup.privacy_type_name
                      : null
                  }
                  groupAdmins={
                    data.singleGroup.admins ? data.singleGroup.admins : null
                  }
                  // onMemberClick={() => {
                  //   navigation("/users/groups/members");
                  // }}
                  isGroupUser={true}
                />
                {data.groupInfo ? null : (
                  <div>
                    <Grid
                      container
                      direction="row"
                      // spacing={2}
                      // justify="center"
                      alignItems="center"
                      style={{ marginTop: "2%" }}
                    >
                      <Grid item md={8} sm={6} xs={4}>
                        <hr />
                      </Grid>
                      <Grid item md={4} sm={6} xs={8}>
                        <EMDropdown
                          handleChange={(event) => {
                            setLimitForCategories(1);
                            let selectedDataOption =
                              event.target.value == "Images & Videos"
                                ? "Media"
                                : event.target.value;
                            dispatch({
                              type: POST_CATEGORY_SELECTED_DATA,
                              payload: selectedDataOption,
                            });

                            dispatch(
                              EMDoGetCategoryFilteredPostForGroupListAction({
                                selectedDataOption: selectedDataOption,
                                page: limitForCategories,
                                userData,
                                id: data.singleGroup.id,
                              })
                            );
                          }}
                          deleteHandler={(event) => {
                            let selectedDataOption =
                              event.target.value == "Images & Videos"
                                ? "Media"
                                : event.target.value;
                            dispatch({
                              type: POST_CATEGORY_SELECTED_DATA,
                              payload: selectedDataOption,
                            });
                            dispatch({
                              type: CLEAR_POST_FROM_STATE,
                              payload: [],
                            });
                            setLimit(1);
                            dispatch(
                              EMDoGetGroupPostsAction({
                                id: data.singleGroup.id,
                                userData,
                                page: limit,
                              })
                            );
                          }}
                          selectedCategory={selectedCategory}
                          selectedDataForDropdown={selectedDataForDropdown}
                        />
                      </Grid>
                    </Grid>
                    <Box style={{ marginTop: "2%" }}>
                      {data.posts.map((post, id) =>
                        post.is_event ? (
                          <EMEvent
                            permission={true}
                            post={post}
                            pinHandler={() => {
                              let actions = [];
                              post.isPinnedPost
                                ? actions.push(`unpin`)
                                : actions.push(`pin`);
                              let queryData = {
                                id: post.id,
                                action: actions[0],
                              };
                              dispatch(
                                EMDoLikeDislikePinUnpinPostAction({ queryData })
                              );
                            }}
                            likeHandler={() => {
                              let actions = [];
                              post.liked
                                ? actions.push(`dislike`)
                                : actions.push(`like`);
                          
                              let queryData = {
                                id: post.id,
                                action: actions[0],
                              };

                              dispatch({
                                type:CHANGE_LIKE_STATUS_OF_POST,
                                payload:{
                                  id:post.id,
                                  isLiked: post.liked ? false : true
                                }
                              })
                              dispatch({
                                type:CHANGE_LIKE_BUTTON_STATE,
                                payload:{
                                  id:post.id,
                                  isDisabled:true
                                }
                              })
                            
                                dispatch(
                                  EMDoLikeDislikePostAction({ queryData: queryData })
                                );

                            }}
                          />
                        ) : post.is_quiz ? (
                          <EMQuiz
                            quizFromQuiz={false}
                            permission={true}
                            post={post}
                            pinHandler={() => {
                              let actions = [];
                              post.isPinnedPost
                                ? actions.push(`unpin`)
                                : actions.push(`pin`);
                              let queryData = {
                                id: post.id,
                                action: actions[0],
                              };
                              dispatch(
                                EMDoLikeDislikePinUnpinPostAction({ queryData })
                              );
                            }}
                          />
                        ) : post.is_poll ? (
                          <EMPoll
                            pinHandler={() => {
                              let actions = [];
                              post.isPinnedPost
                                ? actions.push(`unpin`)
                                : actions.push(`pin`);
                              let queryData = {
                                id: post.id,
                                action: actions[0],
                              };
                              dispatch(
                                EMDoLikeDislikePinUnpinPostAction({ queryData })
                              );
                            }}
                            permission={true}
                            post={post}
                          />
                        ) : (
                          <EMPost
                            permission={true}
                            post={post}
                            pinHandler={() => {
                              let actions = [];
                              post.isPinnedPost
                                ? actions.push(`unpin`)
                                : actions.push(`pin`);
                              let queryData = {
                                id: post.id,
                                action: actions[0],
                              };
                              dispatch(
                                EMDoLikeDislikePinUnpinPostAction({ queryData })
                              );
                            }}
                            likeHandler={() => {
                              let actions = [];
                              post.liked
                                ? actions.push(`dislike`)
                                : actions.push(`like`);
                       
                              let queryData = {
                                id: post.id,
                                action: actions[0],
                              };

                              dispatch({
                                type:CHANGE_LIKE_STATUS_OF_POST,
                                payload:{
                                  id:post.id,
                                  isLiked: post.liked ? false : true
                                }
                              })
                              dispatch({
                                type:CHANGE_LIKE_BUTTON_STATE,
                                payload:{
                                  id:post.id,
                                  isDisabled:true
                                }
                              })

                                dispatch(
                                  EMDoLikeDislikePostAction({ queryData: queryData })
                                );

                            }}
                          />
                        )
                      )}
                    </Box>
                  </div>
                )}
              </Grid>
            ) : (
              <Grid
                item
                xs={matchesLargeScreen ? 7 : matchesMediumScreen ? 9 : 12}
                style={{ zIndex: 1 }}
              >
                {data.singleGroup.privacy_type_name === "Private" ? (
                  <EMGroupDetailsForNew
                    groupBanner={
                      data.singleGroup.banner_file
                        ? data.singleGroup.banner_file
                        : null
                    }
                    groupDescription={
                      data.singleGroup.additional_info
                        ? data.singleGroup.additional_info
                        : null
                    }
                    groupAdditionalInfo={
                      data.singleGroup.additional_info
                        ? data.singleGroup.additional_info
                        : null
                    }
                    groupMission={
                      data.singleGroup.mission_stmt
                        ? data.singleGroup.mission_stmt
                        : null
                    }
                    groupVision={
                      data.singleGroup.vision_stmt
                        ? data.singleGroup.vision_stmt
                        : null
                    }
                    groupLogo={
                      data.singleGroup.logo_file
                        ? data.singleGroup.logo_file
                        : null
                    }
                    groupName={
                      data.singleGroup.group_name
                        ? data.singleGroup.group_name
                        : null
                    }
                    groupMembers={
                      data.singleGroup.number_of_group_users
                        ? data.singleGroup.number_of_group_users
                        : null
                    }
                    groupOrganizations={
                      data.singleGroup.organizations
                        ? data.singleGroup.organizations
                        : null
                    }
                    isPinned={
                      data.singleGroup.is_active
                        ? data.singleGroup.is_active
                        : null
                    }
                    groupPrivacy={
                      data.singleGroup.privacy_type_name
                        ? data.singleGroup.privacy_type_name
                        : null
                    }
                    // onMemberClick={() => {
                    //   navigation("members");
                    // }}
                    groupAdmins={
                      data.singleGroup.admins ? data.singleGroup.admins : null
                    }
                  />
                ) : null}

                {data.singleGroup.privacy_type_name === "Public" ? (
                  <div>
                    <EMGroupDetails
                      isGroupUser={false}
                      groupId={data.singleGroup.id}
                      onInfoClick={() => {
                        dispatch({
                          type: SHOW_INFO_OF_GROUP,
                          payload: !data.groupInfo,
                        });
                      }}
                      onStarClick={() => {
                        let actions;
                        data.singleGroup.is_fav
                          ? (actions = `remove-favorite`)
                          : (actions = `favorite`);
                        dispatch(
                          EMDoActionForGroupAction({
                            id: data.singleGroup.id
                              ? data.singleGroup.id
                              : window.sessionStorage.getItem("groupId"),
                            myaction: actions,
                            // postIndex:key,
                            route: "group_details",
                          })
                        );
                      }}
                      onNotificationClick={() => {
                        let actions;
                        data.singleGroup.is_subscribed
                          ? (actions = `unsubscribe`)
                          : (actions = `subscribe`);
                        dispatch(
                          EMDoActionForGroupAction({
                            id: data.singleGroup.id
                              ? data.singleGroup.id
                              : window.sessionStorage.getItem("groupId"),
                            myaction: actions,
                            // postIndex:key,
                            route: "group_details",
                          })
                        );
                      }}
                      groupBanner={
                        data.singleGroup.banner_file
                          ? data.singleGroup.banner_file
                          : null
                      }
                      groupDescription={
                        data.singleGroup.tagline
                          ? data.singleGroup.tagline
                          : null
                      }
                      groupAdditionalInfo={
                        data.singleGroup.additional_info
                          ? data.singleGroup.additional_info
                          : null
                      }
                      groupLogo={
                        data.singleGroup.logo_file
                          ? data.singleGroup.logo_file
                          : null
                      }
                      groupName={
                        data.singleGroup.group_name
                          ? data.singleGroup.group_name
                          : null
                      }
                      groupMembers={
                        data.singleGroup.number_of_group_users
                          ? data.singleGroup.number_of_group_users
                          : null
                      }
                      groupOrganizations={
                        data.singleGroup.organizations
                          ? data.singleGroup.organizations
                          : null
                      }
                      groupMission={
                        data.singleGroup && data.singleGroup.mission_stmt
                          ? data.singleGroup.mission_stmt
                          : null
                      }
                      groupVision={
                        data.singleGroup && data.singleGroup.vision_stmt
                          ? data.singleGroup.vision_stmt
                          : null
                      }
                      is_subscribed={
                        data.singleGroup.is_subscribed
                          ? data.singleGroup.is_subscribed
                          : null
                      }
                      is_fav={
                        data.singleGroup.is_fav ? data.singleGroup.is_fav : null
                      }
                      groupPrivacy={
                        data.singleGroup.privacy_type_name
                          ? data.singleGroup.privacy_type_name
                          : null
                      }
                      groupAdmins={
                        data.singleGroup.admins ? data.singleGroup.admins : null
                      }
                      // onMemberClick={() => {
                      //   navigation("/users/groups/members");
                      // }}
                    />
                    {data.groupInfo ? null : (
                      <div>
                        <Grid
                          container
                          direction="row"
                          // spacing={2}
                          // justify="center"
                          alignItems="center"
                          style={{ marginTop: "2%" }}
                        >
                          <Grid item md={8} sm={6} xs={4}>
                            <hr />
                          </Grid>
                          <Grid item md={4} sm={6} xs={8}>
                            <EMDropdown
                              handleChange={(event) => {
                                setLimitForCategories(1);
                                let selectedDataOption =
                                  event.target.value == "Images & Videos"
                                    ? "Media"
                                    : event.target.value;
                                dispatch({
                                  type: POST_CATEGORY_SELECTED_DATA,
                                  payload: selectedDataOption,
                                });
                                dispatch(
                                  EMDoGetCategoryFilteredPostForGroupListAction(
                                    {
                                      selectedDataOption: selectedDataOption,
                                      page: limitForCategories,
                                      userData,
                                      id: data.singleGroup.id,
                                    }
                                  )
                                );
                              }}
                              deleteHandler={(event) => {
                                setLimit(1);
                                let selectedDataOption =
                                  event.target.value == "Images & Videos"
                                    ? "Media"
                                    : event.target.value;
                                dispatch({
                                  type: POST_CATEGORY_SELECTED_DATA,
                                  payload: selectedDataOption,
                                });
                                dispatch({
                                  type: CLEAR_POST_FROM_STATE,
                                  payload: [],
                                });
                                dispatch(
                                  EMDoGetGroupPostsAction({
                                    id: data.singleGroup.id,
                                    userData,
                                    page: limit,
                                  })
                                );
                              }}
                              selectedCategory={selectedCategory}
                              selectedDataForDropdown={selectedDataForDropdown}
                            />
                          </Grid>
                        </Grid>
                        <Box style={{ marginTop: "2%" }}>
                          {data.posts.map((post, id) =>
                            post.is_event ? (
                              <EMEvent
                                permission={true}
                                post={post}
                                pinHandler={() => {
                                  let actions = [];
                                  post.isPinnedPost
                                    ? actions.push(`unpin`)
                                    : actions.push(`pin`);
                                  let queryData = {
                                    id: post.id,
                                    action: actions[0],
                                  };
                                  dispatch(
                                    EMDoLikeDislikePinUnpinPostAction({
                                      queryData,
                                    })
                                  );
                                }}
                                likeHandler={() => {
                                  let actions = [];
                                  post.liked
                                    ? actions.push(`dislike`)
                                    : actions.push(`like`);
                                  
                                  let queryData = {
                                    id: post.id,
                                    action: actions[0],
                                  };

                                  dispatch({
                                    type:CHANGE_LIKE_STATUS_OF_POST,
                                    payload:{
                                      id:post.id,
                                      isLiked: post.liked ? false : true
                                    }
                                  })
                                  dispatch({
                                    type:CHANGE_LIKE_BUTTON_STATE,
                                    payload:{
                                      id:post.id,
                                      isDisabled:true
                                    }
                                  })

                                    dispatch(
                                      EMDoLikeDislikePostAction({ queryData: queryData })
                                    );

                                }}
                              />
                            ) : post.is_quiz ? (
                              <EMQuiz
                                quizFromQuiz={false}
                                permission={true}
                                post={post}
                                pinHandler={() => {
                                  let actions = [];
                                  post.isPinnedPost
                                    ? actions.push(`unpin`)
                                    : actions.push(`pin`);
                                  let queryData = {
                                    id: post.id,
                                    action: actions[0],
                                  };
                                  dispatch(
                                    EMDoLikeDislikePinUnpinPostAction({
                                      queryData,
                                    })
                                  );
                                }}
                              />
                            ) : post.is_poll ? (
                              <EMPoll
                                pinHandler={() => {
                                  let actions = [];
                                  post.isPinnedPost
                                    ? actions.push(`unpin`)
                                    : actions.push(`pin`);
                                  let queryData = {
                                    id: post.id,
                                    action: actions[0],
                                  };
                                  dispatch(
                                    EMDoLikeDislikePinUnpinPostAction({
                                      queryData,
                                    })
                                  );
                                }}
                                permission={true}
                                post={post}
                              />
                            ) : (
                              <EMPost
                                permission={true}
                                post={post}
                                pinHandler={() => {
                                  let actions = [];
                                  post.isPinnedPost
                                    ? actions.push(`unpin`)
                                    : actions.push(`pin`);
                                  let queryData = {
                                    id: post.id,
                                    action: actions[0],
                                  };
                                  dispatch(
                                    EMDoLikeDislikePinUnpinPostAction({
                                      queryData,
                                    })
                                  );
                                }}
                                likeHandler={() => {
                                  let actions = [];
                                  post.liked
                                    ? actions.push(`dislike`)
                                    : actions.push(`like`);
                                  
                                  let queryData = {
                                    id: post.id,
                                    action: actions[0],
                                  };

                                  dispatch({
                                    type:CHANGE_LIKE_STATUS_OF_POST,
                                    payload:{
                                      id:post.id,
                                      isLiked: post.liked ? false : true
                                    }
                                  })
                                  dispatch({
                                    type:CHANGE_LIKE_BUTTON_STATE,
                                    payload:{
                                      id:post.id,
                                      isDisabled:true
                                    }
                                  })

                                    dispatch(
                                      EMDoLikeDislikePostAction({ queryData: queryData })
                                    );

                                }}
                              />
                            )
                          )}
                        </Box>
                      </div>
                    )}
                  </div>
                ) : null}

                {data.singleGroup.privacy_type_name === "Restricted" ? (
                  <div>
                    <EMGroupDetails
                      isGroupUser={false}
                      onInfoClick={() => {
                        dispatch({
                          type: SHOW_INFO_OF_GROUP,
                          payload: !data.groupInfo,
                        });
                      }}
                      onStarClick={() => {
                        let actions;
                        data.singleGroup.is_fav
                          ? (actions = `remove-favorite`)
                          : (actions = `favorite`);
                        dispatch(
                          EMDoActionForGroupAction({
                            id: data.singleGroup.id
                              ? data.singleGroup.id
                              : window.sessionStorage.getItem("groupId"),
                            myaction: actions,
                            // postIndex:key,
                            route: "group_details",
                          })
                        );
                      }}
                      onNotificationClick={() => {
                        let actions;
                        data.singleGroup.is_subscribed
                          ? (actions = `unsubscribe`)
                          : (actions = `subscribe`);
                        dispatch(
                          EMDoActionForGroupAction({
                            id: data.singleGroup.id
                              ? data.singleGroup.id
                              : window.sessionStorage.getItem("groupId"),
                            myaction: actions,
                            // postIndex:key,
                            route: "group_details",
                          })
                        );
                      }}
                      groupId={data.singleGroup.id}
                      groupBanner={
                        data.singleGroup.banner_file
                          ? data.singleGroup.banner_file
                          : null
                      }
                      groupDescription={
                        data.singleGroup.tagline
                          ? data.singleGroup.tagline
                          : null
                      }
                      groupAdditionalInfo={
                        data.singleGroup.additional_info
                          ? data.singleGroup.additional_info
                          : null
                      }
                      groupLogo={
                        data.singleGroup.logo_file
                          ? data.singleGroup.logo_file
                          : null
                      }
                      groupName={
                        data.singleGroup.group_name
                          ? data.singleGroup.group_name
                          : null
                      }
                      groupMembers={
                        data.singleGroup.number_of_group_users
                          ? data.singleGroup.number_of_group_users
                          : null
                      }
                      groupOrganizations={
                        data.singleGroup.organizations
                          ? data.singleGroup.organizations
                          : null
                      }
                      groupMission={
                        data.singleGroup && data.singleGroup.mission_stmt
                          ? data.singleGroup.mission_stmt
                          : null
                      }
                      groupVision={
                        data.singleGroup && data.singleGroup.vision_stmt
                          ? data.singleGroup.vision_stmt
                          : null
                      }
                      is_subscribed={
                        data.singleGroup.is_subscribed
                          ? data.singleGroup.is_subscribed
                          : null
                      }
                      is_fav={
                        data.singleGroup.is_fav ? data.singleGroup.is_fav : null
                      }
                      groupPrivacy={
                        data.singleGroup.privacy_type_name
                          ? data.singleGroup.privacy_type_name
                          : null
                      }
                      groupAdmins={
                        data.singleGroup.admins ? data.singleGroup.admins : null
                      }
                      // onMemberClick={() => {
                      //   navigation("/users/groups/members");
                      // }}
                    />
                    {data.groupInfo ? null : (
                      <div>
                        <Grid
                          container
                          direction="row"
                          // spacing={2}
                          // justify="center"
                          alignItems="center"
                          style={{ marginTop: "2%" }}
                        >
                          <Grid item md={8} sm={6} xs={4}>
                            <hr />
                          </Grid>
                          <Grid item md={4} sm={6} xs={8}>
                            <EMDropdown
                              handleChange={(event) => {
                                setLimitForCategories(1);
                                let selectedDataOption =
                                  event.target.value == "Images & Videos"
                                    ? "Media"
                                    : event.target.value;
                                dispatch({
                                  type: POST_CATEGORY_SELECTED_DATA,
                                  payload: selectedDataOption,
                                });

                                dispatch(
                                  EMDoGetCategoryFilteredPostForGroupListAction(
                                    {
                                      selectedDataOption: selectedDataOption,
                                      page: limitForCategories,
                                      userData,
                                      id: data.singleGroup.id,
                                    }
                                  )
                                );
                              }}
                              deleteHandler={(event) => {
                                setLimit(1);
                                let selectedDataOption =
                                  event.target.value == "Images & Videos"
                                    ? "Media"
                                    : event.target.value;
                                dispatch({
                                  type: POST_CATEGORY_SELECTED_DATA,
                                  payload: selectedDataOption,
                                });
                                dispatch({
                                  type: CLEAR_POST_FROM_STATE,
                                  payload: [],
                                });
                                dispatch(
                                  EMDoGetGroupPostsAction({
                                    id: data.singleGroup.id,
                                    userData,
                                    page: limit,
                                  })
                                );
                              }}
                              selectedCategory={selectedCategory}
                              selectedDataForDropdown={selectedDataForDropdown}
                            />
                          </Grid>
                        </Grid>
                        <Box style={{ marginTop: "2%" }}>
                          {data.posts.map((post, id) =>
                            post.is_event ? (
                              <EMEvent
                                permission={false}
                                post={post}
                                pinHandler={() => {
                                  dispatch({
                                    type: SHOW_MESSAGE,
                                    payload: {
                                      type: "error",
                                      message: "You are not a part of group",
                                    },
                                  });
                                }}
                                likeHandler={() => {
                                  dispatch({
                                    type: SHOW_MESSAGE,
                                    payload: {
                                      type: "error",
                                      message: "You are not a part of group",
                                    },
                                  });
                                }}
                              />
                            ) : post.is_poll ? (
                              <EMPoll
                                pinHandler={() => {
                                  dispatch({
                                    type: SHOW_MESSAGE,
                                    payload: {
                                      type: "error",
                                      message: "You are not a part of group",
                                    },
                                  });
                                }}
                                permisssion={false}
                                post={post}
                              />
                            ) : post.is_quiz ? (
                              <EMQuiz
                                quizFromQuiz={false}
                                pinHandler={() => {
                                  dispatch({
                                    type: SHOW_MESSAGE,
                                    payload: {
                                      type: "error",
                                      message: "You are not a part of group",
                                    },
                                  });
                                }}
                                permisssion={false}
                                post={post}
                              />
                            ) : (
                              <EMPost
                                permission={false}
                                post={post}
                                pinHandler={() => {
                                  dispatch({
                                    type: SHOW_MESSAGE,
                                    payload: {
                                      type: "error",
                                      message: "You are not a part of group",
                                    },
                                  });
                                }}
                                likeHandler={() => {
                                  dispatch({
                                    type: SHOW_MESSAGE,
                                    payload: {
                                      type: "error",
                                      message: "You are not a part of group",
                                    },
                                  });
                                }}
                              />
                            )
                          )}
                        </Box>
                      </div>
                    )}
                  </div>
                ) : null}
              </Grid>
            )}
            {matchesLargeScreen ? (
              <Grid item xs={3} style={{ marginBottom: "2%" }}>
                <EMChannelsPreview
                  data={data.channelList}
                  route="groups"
                  usedIn="groups"
                />
              </Grid>
            ) : null}
          </Grid>
        </div>
      </EMPage>
    </InfiniteScroll>
  );
}
