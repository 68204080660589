//Page Constants
export const SHOW_CREATE_POST = "show_create_post";
export const SHOW_USER_POSTS = "show_user_posts";
//post
export const USER_EXPAND_TEXT = "user_expand_post";
export const USER_EXPAND_COMMENT = "user_expand_comment";
export const USER_SHOW_COMMENT = "user_show_comment";
export const POST_SHOW_HASHTAG = "post_show_hashtag";
export const POST_HASHTAG_SELECTED = "post_hashtag_selected";
export const POST_SHOW_TAGPEOPLE = "post_show_tagpeople";
export const POST_TAGPEOPLE_SELECTED = "post_tagpeople_selected";
export const POST_SHOW_EMOJI = "post_show_emoji";
export const POST_EMOJI_SELECTED = "post_emoji_selected";
export const POST_NEW_COMMENT = "post_new_comment";
export const SUBMIT_NEW_COMMENT = "submit_new_comment";
export const POST_SHOW_MORE = "post_show_more";
export const POST_SHOW_MORE_SELECTED = "post_show_more_selected";
export const SHOW_POST_EDIT = "show_post_edit";
export const SHOW_POST_MEDIA_PREVIEW = "show_post_media_preview";
export const SHOW_NEXT_POSTS = "show_next_posts";
export const GET_MENTIONS_FOR_POST = "get_mentions_for_post";
export const GET_POST_BY_NOTIFICATION_TAG = "get_post_by_notification_tag";
export const PLAY_VIDEO_BY_ID = "play_video_by_id"
export const CHANGE_LIKE_STATUS_OF_POST = "change_like_status_of_post"
export const CHANGE_LIKE_BUTTON_STATE = "change_like_button_state"
//create Post
export const USER_IMAGES_UPLOAD = "user_image_upload";
export const USER_IMAGES_DELETE = "user_image_delete";
export const USER_VIDEOS_UPLOAD = "user_video_upload";
export const USER_VIDEOS_DELETE = "user_video_delete";
export const USER_DOCUMENT_UPLOAD = "user_document_upload";
export const USER_DOCUMENT_DELETE = "user_document_delete";
export const USER_EXTERNAL_VIDEO_UPLOAD = "user_external_video_upload";
export const USER_EXTERNAL_VIDEO_DELETE = "user_external_video_delete";
export const TO_GET_THE_POLL_FORM="to_get_poll_form"
//edit post
export const POST_IMAGES = "post_image";
export const POST_IMAGES_DELETE = "post_image_delete";
export const POST_VIDEOS = "post_video";
export const POST_VIDEOS_DELETE = "post_video_delete";
export const POST_DOCUMENT = "post_document";
export const POST_DOCUMENT_DELETE = "post_document_delete";
export const POST_EXTERNAL_VIDEO_UPLOAD = "post_external_video_upload";
export const POST_EXTERNAL_VIDEO_DELETE = "post_external_video_delete";

//category dropdown
//category dropdown for Latest Post
export const POST_CATEGORY_DATA = "post_category_data";
export const POST_CATEGORY_SELECTED_DATA = "post_category_selected_data";
//category dropdown for PinnedPost
export const PINNED_POST_CATEGORY_SELECTED_DATA = "pinned_post_category_selected_data";
//Data From Server
//post
export const LIST_USER_POSTS = "list_user_posts"
export const LIST_USER_PINNED_POSTS = "list_user_pinned_post"
export const CHANNEL_PREVIEW_LIST_SUCCESS = "channel_preview_list_success";
export const CHANNEL_COMPONENT_POP_UP = "channel_component_pop_up";
export const LIST_SHARE_WITH_GROUP = "list_share_with_group"
export const LIST_USER_POSTS_CATEGORY_SELECTED = "list_user_posts_category_selected";
export const LIST_HASHTAG = "list_hashtag"
export const LIST_MENTIONS = "list_mentions"
export const DELETE_POST = "delete_post"
export const ADD_NEW_POST = "add_new_post"
export const CLEAR_POST_FROM_STATE = "clear_post_from_state"
export const OPEN_DELETE_CONFIRMATION_DIALOG = "open_delete_confirmation_dialog"
export const LIKE_DISLIKE_POSTS = "like_dislike_posts"
export const CLEAR_POST_FROM_STATE_FOR_EVERY_LOAD = "clear_post_from_state_for_every_load"
export const DELETE_POST_COMMENT = "delete_post_comment"
//PINNED POST
export const LIKE_DISLIKE_PIN_UNPIN_POSTS = "like_unlike_pin_unpin_posts"
export const LIST_USER_PINNED_POSTS_CATEGORY_SELECTED = "list_user_pinned_posts_category_selected"
export const SHOW_NEXT_PINNED_POSTS = "show_next_pinned_post"
export const LIKE_DISLIKE_PIN_UNPIN_PINNED_POSTS = "like_dislike_pin_unpin_pinned_posts"
export const LIKE_DISLIKE_PINNED_POSTS = "like_dislike_pinned_posts"
export const CHANGE_LIKE_STATUS_OF_PINNED_POST = "change_like_status_of_post_post"
export const CHANGE_LIKE_BUTTON_STATE_OF_PINNED_POST = "change_like_button_state_of_pinned_post"
//POLL
export const SUBMIT_POLL = "submit_poll"
export const SUBMIT_OPEN_POLL = "submit_open_poll"

//events
export const LIST_USER_EVENTS = "list_user_events"
export const USER_EVENTS_POPUP = "user_events_popup"

//groups
export const LIST_USER_GROUPS = "list_user_groups"

//leaderboard
export const GET_USER_POINTS = "get_user_points"
export const GET_LEADERBOARD = "get_leaderboard"

//Quiz
export const SUBMIT_QUIZ = "submit_quiz"
