import React, { useState, useEffect } from "react";
import EMPage from "../../../../components/commans/EMPage";
import EMCalendar from "../../../../components/user/EMCalendar";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Paper, Button, Typography, useMediaQuery } from "@material-ui/core";
import calendar from "../../../../assets/Icon material-event-1.png";
import { Carousel } from "react-responsive-carousel";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import EMSingleEvent from "../../../../components/user/EMSingleEvent";
import EMFilter from "../../../../components/user/EMFilter";
import { GROUP_APPLY_NETWORK_FILTER } from "../../../../redux/constants/UserPlatform/EMGroupConstant";
import { EMDoGetNetworkFiltersAction } from "../../../../redux/actions/UserPlatform/Groups/EMGroupsAction";
import {
  DISABLE_ENABLE_FILTER_FOR_GROUPS,
  EVENT_APPLY_GROUP_FILTER,
  SHOW_EVENT_DETAILS,
} from "../../../../redux/constants/UserPlatform/EMEventsConstant";
import { useNavigate } from "react-router";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { ReactComponent as CategoryIcon } from "../../../../assets/Icon-awesome-tag.svg";
import {
  EMDoGetEventsByGroupFilterAction,
  EMDoGetEventsByMeAction,
  EMDoGetGroupsForFilterAction,
  EMDoGetMonthEventsAction,
  EMDoGetMyAllEventsAction,
  EMDoGetPastEventsAction,
  EMDoGetUpcomingEventsAction,
} from "../../../../redux/actions/UserPlatform/Events";
import EMSideBar from "../../../../components/navigation/EMSideBar";
import { Event, LocalOffer } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    padding: theme.spacing(1.5),
    fontWeight: "bolder",
    fontSize: "large",
    width: "100%",
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 2,
    wordBreak: "break-word",
    overflow: "hidden",
  },
}));

export default function Calender(props) {
  const data = useSelector((state) => state.events || {});
  const groupsData = useSelector((state) => state.groups || {});
  const dispatch = useDispatch();
  const classes = useStyles();
  const userData = useSelector((state) => state.auth || {});
  const [selectedNetworks, setSelectedNetworks] = React.useState([]);

  const navigate = useNavigate();

  const theme = useTheme()

  const matchesMediumScreen = useMediaQuery(theme.breakpoints.up('md'));

  const matchesLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  useEffect(() => {
    dispatch(EMDoGetNetworkFiltersAction({ route: false }));
    dispatch(EMDoGetUpcomingEventsAction({ userData }));
    dispatch(EMDoGetEventsByMeAction({ userData }));
    dispatch(EMDoGetPastEventsAction({ userData }));
    dispatch(EMDoGetMyAllEventsAction({ userData }));
    dispatch(EMDoGetGroupsForFilterAction());
    dispatch({
      type:DISABLE_ENABLE_FILTER_FOR_GROUPS,
      payload:true
    })
  }, []);

  function prettyDate2(date) {
    // eslint-disable-next-line no-const-assign
    var parts = date.split("-");
    var mydate = new Date(parts[0], parts[1] - 1, parts[2]);
    return mydate;
  }

  return (
    <EMPage
      title="Events Calendar"
      style={{
        width: "100%",
        paddingLeft: matchesLargeScreen  ? "8%" : "4%",
        paddingTop: matchesLargeScreen ? "4%" : matchesMediumScreen ? "6%" : "8%",
        paddingRight: matchesLargeScreen  ? "8%" : "4%",
        paddingBottom: "2%",
      }}
    >
      <Grid container direction="row" alignItems="flex-start" spacing={3}>
      {matchesMediumScreen ?  <Grid item xs={matchesLargeScreen ? 2 : 3}>
            <EMSideBar />
          </Grid> : null}
        <Grid item xs={matchesLargeScreen ? 7 : matchesMediumScreen ? 9 : 12}>
          <EMCalendar data={data.eventsToRenderInCalender} />
        </Grid>
        {matchesLargeScreen ? 
        <Grid  item xs={3}>
          <Paper elevation={2}>
            {/* <Paper elevation={2} style={{marginBottom:"5%"}}> */}
            <div style={{ padding: 20 }}>
              <div style={{ display: "flex" }}>
                <Event fontSize="large" color="primary" />
                <Typography
                  style={{
                    color: "#2F92D6",
                    fontWeight: "bolder",
                    fontSize: "medium",
                    marginLeft: "5%",
                  }}
                >
                  {" "}
                  UPCOMING EVENTS{" "}
                </Typography>
              </div>
              <div style={{ marginTop: "5%" }}>
                <Carousel
                  swipeable={true}
                  emulateTouch={true}
                  renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                      <Button
                        type="button"
                        onClick={onClickHandler}
                        title={label}
                        style={{ ...arrowStyles, left: 0 }}
                      >
                        <ChevronLeftIcon fontSize="large" />
                      </Button>
                    )
                  }
                  renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                      <Button
                        type="button"
                        onClick={onClickHandler}
                        title={label}
                        style={{ ...arrowStyles, right: 0 }}
                      >
                        <ChevronRightIcon fontSize="large" />
                      </Button>
                    )
                  }
                  showThumbs={false}
                  showIndicators={false}
                >
                  {data.upcomingEvents &&
                    data.upcomingEvents.map((event, key) => (
                      <div>
                        <EMSingleEvent
                          groupName={event.groupName}
                          route="latest-post"
                          eventBanner={
                            event.banner_file
                              ? process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(
                                  0,
                                  -1
                                ) + event.banner_file
                              : null
                          }
                          eventName={event.title}
                          eventLocation={event.event_location}
                          eventStart={event.event_start_time}
                          eventEnd={event.event_end_time}
                          eventStartDate={
                            event.event_start && prettyDate2(event.event_start)
                          }
                          getEventDetails={() => {
                            dispatch({
                              type: SHOW_EVENT_DETAILS,
                              payload: {
                                route: "upcomingEvents",
                                eventId: event.id,
                                value: true,
                              },
                            });
                            navigate("/users/events/searchevents");
                          }}
                        />
                      </div>
                    ))}
                </Carousel>
              </div>
            </div>
            {/* </Paper> */}
            <Paper className={classes.formControl}>
                <Grid container direction="row" >
                  <Grid item xs={2}>
                  <LocalOffer fontSize="large" color="primary"/>
                  </Grid>
                  <Grid item xs={10}>
                  <Typography style={{ color: "#2F92D6", fontSize:"medium", fontWeight:"bolder" }}>
                    GROUP FILTER
                  </Typography>
                  </Grid>
                </Grid>
                {data.groups.map((group, key) => (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                        disabled={data.isFilterDisabled}
                          checked={group.value}
                          name={group.group_name}
                          onChange={(event) => {
                            dispatch({
                              type:DISABLE_ENABLE_FILTER_FOR_GROUPS,
                              payload:true
                            })
                            let arr;
                            if (event.target.checked) {
                              let checkedValue = event.target.name;
                              arr =
                                selectedNetworks.length > 0
                                  ? [...selectedNetworks, group]
                                  : [group];
                              setSelectedNetworks(arr);
                              dispatch({
                                type: EVENT_APPLY_GROUP_FILTER,
                                payload: {
                                  checked_value: !group.value,
                                  postIndex: key,
                                },
                              });
                            } else {
                              //find this value in array and delete it
                              let checkedValue = event.target.name;
                              let index = selectedNetworks.findIndex(
                                (x) => x.group_name == checkedValue
                              );
                              selectedNetworks.splice(index, 1);
                              arr = selectedNetworks;
                              dispatch({
                                type: EVENT_APPLY_GROUP_FILTER,
                                payload: {
                                  checked_value: !group.value,
                                  postIndex: key,
                                },
                              });
                            }
                            if (arr.length > 0) {
                              //
                              if (arr.length == 1) {
                                let selectedNetwork = arr[0];
                                let id = selectedNetwork.id;
                                let calendarView = data.selectedDate;
                                dispatch(
                                  EMDoGetEventsByGroupFilterAction({
                                    userData,
                                    calendarView: calendarView,
                                    id: id,
                                  })
                                );
                              } else if (arr.length > 1) {
                                // let action
                                let groupAction;
                                // let organizationAction
                                let groupIds = [];
                                let calendarView = data.selectedDate;
                                arr.map((selectedGroup, key) => {
                                  groupIds.push(selectedGroup.id);
                                  groupAction = `${groupIds.toString()}`;
                                });
                                dispatch(
                                  EMDoGetEventsByGroupFilterAction({
                                    userData,
                                    calendarView: calendarView,
                                    id: groupAction,
                                  })
                                );
                              }
                            } else if (arr.length == 0) {
                              let calendarView = data.selectedDate;
                              dispatch(
                                EMDoGetMonthEventsAction({
                                  userData,
                                  calendarView: calendarView,
                                })
                              );
                            }
                          }}
                        />
                      }
                      label={group.group_name}
                      key={group.id}
                    />
                  </FormGroup>
                ))}
            </Paper>
          </Paper>
        </Grid> : null}
      </Grid>
    </EMPage>
  );
}

const arrowStyles = {
  position: "absolute",
  zIndex: 2,
  top: "calc(35% - 15px)",
  width: 30,
  height: 30,
  color: "white",
  cursor: "pointer",
  backgroundColor: "transparent",
};
