import React, { useState, useEffect, useRef } from "react";
import EMPage from "../../../../components/commans/EMPage";
import {
  Paper,
  GridList,
  GridListTile,
  Typography,
  IconButton,
  Modal,
  Grid,
  Badge,
  useMediaQuery,
} from "@material-ui/core";
import EMSingleEvent from "../../../../components/user/EMSingleEvent";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { Box } from "react-feather";
import {
  SHOW_EVENT_DETAILS,
  EVENT_APPLY_GROUP_FILTER,
  DISABLE_ENABLE_FILTER_FOR_GROUPS,
  DISABLE_ENABLE_FILTER_FOR_GROUPS_FUTURE_EVENTS,
  DISABLE_ENABLE_FILTER_FOR_GROUPS_PAST_EVENTS,
} from "../../../../redux/constants/UserPlatform/EMEventsConstant";
import {
  CHANGE_LIKE_BUTTON_STATE_OF_PINNED_POST,
  CHANGE_LIKE_STATUS_OF_PINNED_POST,
  SHOW_POST_EDIT,
} from "../../../../redux/constants/UserPlatform";
import EMCreateEvent from "../../../../components/user/EMCreateEvent";
import EMSingleEventDetail from "../../../../components/user/EMSingleEventDetail";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import {
  EMDoGetEventSharedWithGroupsListAction,
  EMDoGetPostSharedWithGroupsListAction,
  EMDoLikeDislikePostAction,
} from "../../../../redux/actions/UserPlatform/Home/EMLatestPostPageAction";
import {
  EMDoGetPastEventsAction,
  EMDoGetMonthEventsAction,
  EMDoGetUpcomingEventsAction,
  EMDoGetGroupsForFilterAction,
  EMDoGetPastEventsByGroupFilterAction,
  EMDoGetUpcomingEventsByGroupFilterAction,
} from "../../../../redux/actions/UserPlatform/Events";
import { EMDoGetPrivacyList } from "../../../../redux/actions/EMNetworkAdminActions";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker } from "@material-ui/pickers";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { ReactComponent as CategoryIcon } from "../../../../assets/Icon-awesome-tag.svg";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { findLast } from "lodash";
import { HIDE_MESSAGE } from "../../../../redux/constants";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import EMSideBar from "../../../../components/navigation/EMSideBar";
import { LocalOffer } from "@material-ui/icons";

const defaultMaterialTheme = createTheme({
  overrides: {
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: "#2F92D6",
        color: "white",
        // "&:hover ":{
        //   background: "none",
        // }
      },
      iconButton: {
        backgroundColor: "#2F92D6",
      },
      MuiPickersBasePicker: {
        pickerView: {
          maxWidth: "100%",
          minWidth: "100%",
        },
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  header: {
    // marginTop: "1%",
    marginLeft: "2%",
    paddingLeft: "10px",
    paddingTop: "30px",
  },
  header1: {
    marginTop: "1%",
    marginLeft: "2%",
    paddingLeft: "10px",
  },

  formControl: {
    padding: theme.spacing(1.5),
    fontWeight: "bolder",
    fontSize: "large",
    width: "100%",
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 2,
    wordBreak: "break-word",
    overflow: "hidden",
  },
  editPost: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "80%",
    marginLeft: "10%",
  },
  rootForGroups: {
    // marginTop: "2%",
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridListforGroups: {
    width: "100%",
    height: "100%",
    padding: 10,
    // maxHeight: window.screen.height,
    maxWidth: window.screen.width,
    "&::-webkit-scrollbar": {
      width: "0em",
      height: "0em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#4A4A4A",
      outline: "1px solid slategrey",
    },
  },
  root: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    marginRight: "2%",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    maxWidth: window.screen.width,
    "&::-webkit-scrollbar": {
      width: "0em",
      height: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#4A4A4A",
      outline: "1px solid slategrey",
    },
  },
  dayWithDot: {
    position: "absolute",
    height: 0,
    width: 0,
    border: "4px solid",
    borderRadius: 4,
    borderColor: theme.palette.primary.main,
    right: "50%",
    transform: "translateX(1px)",
    top: "80%",
  },
}));
function SearchEvents(props) {
  const classes = useStyles();
  const data = useSelector((state) => state.events || {});
  const dispatch = useDispatch();
  const myRef = useRef(null);
  const jointRef = useRef(null);
  const userData = useSelector((state) => state.auth || {});
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedDate, handleDateChange] = useState(new Date());
  const [viewDate, setViewDate] = useState(new Date());
  const uiData = useSelector((state) => state.ui || {});
  const [selectedNetworks, setSelectedNetworks] = React.useState([]);

  const handleMonthChange = async (date) => {
    setViewDate(date);
  };

  function getCols(screenWidth) {
    if (isWidthUp("xl", screenWidth)) {
      return 3;
    }

    if (isWidthUp("lg", screenWidth)) {
      return 3;
    }
    if (isWidthUp("md", screenWidth)) {
      return 2;
    }
    if (isWidthUp("sm", screenWidth)) {
      return 2;
    }
    if (isWidthUp("xs", screenWidth)) {
      return 1;
    }
    return 3;
  }
  const cols = getCols(props.width);

  useEffect(() => {
    let dotDays = [];
    if (data.eventsToRenderInCalender.length > 0) {
      data.eventsToRenderInCalender.map((event) => {
        dotDays.push(event.start.getDate());
        setSelectedDays(dotDays);
      });
    } else {
      setSelectedDays([]);
    }
  }, [data.eventsToRenderInCalender]);

  const theme = useTheme();

  const matchesMediumScreen = useMediaQuery(theme.breakpoints.up("md"));

  const matchesLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    setTimeout(() => {
      dispatch(EMDoGetMonthEventsAction({ userData, calendarView: viewDate }));
    });
  }, [viewDate]);

  useEffect(() => {
    dispatch(EMDoGetUpcomingEventsAction({ userData }));
    dispatch(EMDoGetPastEventsAction({ userData }));
    // dispatch(EMDoGetPostSharedWithGroupsListAction())
    dispatch(EMDoGetPrivacyList());
    dispatch(EMDoGetGroupsForFilterAction());
    dispatch(EMDoGetEventSharedWithGroupsListAction());
    dispatch({
      type: DISABLE_ENABLE_FILTER_FOR_GROUPS_FUTURE_EVENTS,
      payload: true,
    });
    dispatch({
      type: DISABLE_ENABLE_FILTER_FOR_GROUPS_PAST_EVENTS,
      payload: true,
    });
  }, []);

  function prettyDate2(date) {
    // eslint-disable-next-line no-const-assign
    var parts = date.split("-");
    var mydate = new Date(parts[0], parts[1] - 1, parts[2]);
    return mydate;
  }

  return (
    <EMPage
      title="Search Events"
      style={{
        width: "100%",
        paddingLeft: matchesLargeScreen ? "8%" : "4%",
        paddingTop: matchesLargeScreen
          ? "4%"
          : matchesMediumScreen
          ? "6%"
          : "8%",
        paddingRight: matchesLargeScreen ? "8%" : "4%",
        paddingBottom: "2%",
      }}
    >
      <Grid container spacing={3}>
        {matchesMediumScreen ? (
          <Grid item xs={matchesLargeScreen ? 2 : 3}>
            <EMSideBar />
          </Grid>
        ) : null}
        <Grid item xs={matchesLargeScreen ? 7 : matchesMediumScreen ? 9 : 12}>
          {data.singleEventDetails.showEventDetails &&
          (data.singleEventDetails.route === "pastEvents" ||
            data.singleEventDetails.route === "upcomingEvents") ? (
            <EMSingleEventDetail
              post={data.singleEventDetails}
              backToEvents={() => {
                dispatch({
                  type: SHOW_EVENT_DETAILS,
                  payload: {
                    value: false,
                    route: data.singleEventDetails.route,
                    eventId: data.singleEventDetails.id,
                  },
                });
              }}
              likeHandler={() => {
                let actions = [];
                data.singleEventDetails.liked
                  ? actions.push(`dislike`)
                  : actions.push(`like`);
                data.singleEventDetails.liked = !data.singleEventDetails.liked;
                let queryData = {
                  id: data.singleEventDetails.id,
                  action: actions[0],
                };
                dispatch({
                  type: CHANGE_LIKE_STATUS_OF_PINNED_POST,
                  payload: {
                    id: data.singleEventDetails.id,
                    isLiked: data.singleEventDetails.liked ? false : true,
                  },
                });
                dispatch({
                  type: CHANGE_LIKE_BUTTON_STATE_OF_PINNED_POST,
                  payload: {
                    id: data.singleEventDetails.id,
                    isDisabled: true,
                  },
                });
                dispatch(EMDoLikeDislikePostAction({ queryData: queryData }));
              }}
            />
          ) : (
            <Paper elevation={2}>
              <div className={classes.header}>
                <Typography>EVENTS PAST WEEK</Typography>
                <Typography
                  component="h5"
                  variant="h5"
                  style={{ fontWeight: "bolder" }}
                >
                  Past Events
                </Typography>
              </div>
              <div style={{ display: "flex" }}>
                {data.pastEvents.length > 3 ? (
                  <IconButton
                    style={{ marginLeft: "-10px" }}
                    onClick={() => {
                      if (
                        myRef.current &&
                        myRef.current.scrollLeft !== myRef.current.scrollWidth
                      ) {
                        myRef.current.scrollLeft -=
                          myRef.current.scrollWidth / 10;
                      }
                    }}
                  >
                    <NavigateBeforeIcon
                      fontSize="small"
                      style={{ color: "black" }}
                    />
                  </IconButton>
                ) : null}
                <div
                  className={
                    data.pastEvents.length > 3
                      ? classes.root
                      : classes.rootForGroups
                  }
                >
                  <GridList
                    className={
                      data.pastEvents.length > 3
                        ? classes.gridList
                        : classes.gridListforGroups
                    }
                    cols={cols}
                    ref={myRef}
                  >
                    {data.pastEvents &&
                      data.pastEvents.map((event, key) => (
                        <GridListTile key={event.id} style={{ height: "auto" }}>
                          <EMSingleEvent
                            groupName={event.groupName}
                            post={event}
                            route="events"
                            editingRoute="pastEvents"
                            eventBanner={
                              event.banner_file
                                ? process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(
                                    0,
                                    -1
                                  ) + event.banner_file
                                : null
                            }
                            eventName={event.title}
                            eventLocation={event.event_location}
                            eventStart={event.event_start_time}
                            eventEnd={event.event_end_time}
                            eventStartDate={
                              event.event_start &&
                              prettyDate2(event.event_start)
                            }
                            getEventDetails={() => {
                              dispatch({
                                type: SHOW_EVENT_DETAILS,
                                payload: {
                                  route: "pastEvents",
                                  eventId: event.id,
                                  value: true,
                                },
                              });
                            }}
                          />
                        </GridListTile>
                      ))}
                  </GridList>
                </div>
                {data.pastEvents.length > 3 ? (
                  <IconButton
                    style={{ marginLeft: "-10px" }}
                    onClick={() => {
                      if (
                        myRef.current.scrollLeft !== myRef.current.scrollWidth
                      ) {
                        myRef.current.scrollLeft +=
                          myRef.current.scrollWidth / 10;
                      }
                    }}
                  >
                    <NavigateNextIcon
                      fontSize="small"
                      style={{ color: "black" }}
                    />
                  </IconButton>
                ) : null}
              </div>
              <div className={classes.header1}>
                <Typography>EVENTS THIS WEEK</Typography>
                <Typography
                  component="h5"
                  variant="h5"
                  style={{ fontWeight: "bolder" }}
                >
                  Upcoming Events
                </Typography>
              </div>
              <div style={{ display: "flex" }}>
                {data.upcomingEvents.length > 3 ? (
                  <IconButton
                    style={{ marginLeft: "-10px" }}
                    onClick={() => {
                      if (
                        jointRef.current &&
                        jointRef.current.scrollLeft !==
                          jointRef.current.scrollWidth
                      ) {
                        jointRef.current.scrollLeft -=
                          jointRef.current.scrollWidth / 10;
                      }
                    }}
                  >
                    <NavigateBeforeIcon
                      fontSize="small"
                      style={{ color: "black" }}
                    />
                  </IconButton>
                ) : null}
                <div
                  className={
                    data.upcomingEvents.length > 3
                      ? classes.root
                      : classes.rootForGroups
                  }
                >
                  <GridList
                    className={
                      data.upcomingEvents.length > 3
                        ? classes.gridList
                        : classes.gridListforGroups
                    }
                    cols={cols}
                    ref={jointRef}
                  >
                    {data.upcomingEvents &&
                      data.upcomingEvents.map((event, key) => (
                        <GridListTile key={event.id} style={{ height: "auto" }}>
                          <EMSingleEvent
                            groupName={event.groupName}
                            post={event}
                            route="events"
                            editingRoute="upcomingEvents"
                            eventBanner={
                              event.banner_file
                                ? process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(
                                    0,
                                    -1
                                  ) + event.banner_file
                                : null
                            }
                            eventName={event.title}
                            eventLocation={event.event_location}
                            eventStart={event.event_start_time}
                            eventEnd={event.event_end_time}
                            eventStartDate={
                              event.event_start &&
                              prettyDate2(event.event_start)
                            }
                            getEventDetails={() => {
                              dispatch({
                                type: SHOW_EVENT_DETAILS,
                                payload: {
                                  route: "upcomingEvents",
                                  eventId: event.id,
                                  value: true,
                                },
                              });
                            }}
                          />
                        </GridListTile>
                      ))}
                  </GridList>
                </div>
                {data.upcomingEvents.length > 3 ? (
                  <IconButton
                    style={{ marginLeft: "-10px" }}
                    onClick={() => {
                      if (
                        jointRef.current.scrollLeft !==
                        jointRef.current.scrollWidth
                      ) {
                        jointRef.current.scrollLeft +=
                          jointRef.current.scrollWidth / 10;
                      }
                    }}
                  >
                    <NavigateNextIcon
                      fontSize="small"
                      style={{ color: "black" }}
                    />
                  </IconButton>
                ) : null}
              </div>
            </Paper>
          )}
          {data.singleEventDetails.editPost &&
          data.singleEventDetails.editPost.showEditPost ? (
            <Modal
              className={classes.editPost}
              open={data.singleEventDetails.editPost.showEditPost}
              onClose={() => {
                dispatch({
                  type: SHOW_POST_EDIT,
                  payload: {
                    id: data.singleEventDetails.id,
                    route: data.singleEventDetails.route,
                    editPost: false,
                    title: "Edit Post",
                    button: "Update Post",
                  },
                });
              }}
            >
              <EMCreateEvent
                selectedChannelIds={[
                  `${data.singleEventDetails.channel_details[0].channel_name.slice(
                    13,
                    data.singleEventDetails.channel_details[0].channel_name
                      .length
                  )} |${data.singleEventDetails.channel_details[0].channel_id}`,
                ]}
                post={data.singleEventDetails}
                usedIn="events"
              />
            </Modal>
          ) : null}
        </Grid>
        {matchesLargeScreen ? (
          <Grid item xs={3}>
            <div>
              <Paper
                elevation={2}
                style={{ maxWidth: "310px", minWidth: "310px" }}
              >
                <div
                  style={{
                    display: "flex",
                    marginLeft: "4%",
                    marginBottom: "2%",
                  }}
                >
                  <CalendarTodayIcon
                    fontSize="large"
                    style={{ color: "#2F92D6", marginTop: "5%" }}
                  />
                  <Typography
                    style={{
                      color: "#2F92D6",
                      fontSize: "medium",
                      fontWeight: "bolder",
                      marginLeft: "6%",
                      marginTop: "5%",
                    }}
                  >
                    CALENDAR
                  </Typography>
                </div>
                <ThemeProvider theme={defaultMaterialTheme}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      className={classes.calendar}
                      label="Responsive"
                      disableToolbar={true}
                      variant={"static"}
                      value={selectedDate}
                      onChange={handleDateChange}
                      onMonthChange={handleMonthChange}
                      renderDay={(
                        day,
                        selectedDate,
                        isInCurrentMonth,
                        dayComponent
                      ) => {
                        const date = day; // skip this step, it is required to support date libs
                        const isSelected =
                          isInCurrentMonth &&
                          selectedDays.includes(date.getDate());
                        return (
                          <Badge
                            badgeContent={
                              isSelected ? (
                                <div className={classes.dayWithDot}></div>
                              ) : undefined
                            }
                          >
                            {dayComponent}
                          </Badge>
                        );
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
              </Paper>

              <Paper elevation={2} style={{ marginTop: "5%" }}>
                <FormControl className={classes.formControl}>
                  <Grid container direction="row">
                    <Grid item xs={2}>
                      <LocalOffer fontSize="large" color="primary" />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography
                        style={{
                          color: "#2F92D6",
                          fontSize: "medium",
                          fontWeight: "bolder",
                        }}
                      >
                        GROUP FILTER
                      </Typography>
                    </Grid>
                  </Grid>
                  {data.groups.map((group, key) => (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={
                              data.isFilterDisabledForPastEvents ||
                              data.isFilterDisabledForFutureEvents
                            }
                            checked={group.value}
                            name={group.group_name}
                            onChange={(event) => {
                              dispatch({
                                type: DISABLE_ENABLE_FILTER_FOR_GROUPS_FUTURE_EVENTS,
                                payload: true,
                              });
                              dispatch({
                                type: DISABLE_ENABLE_FILTER_FOR_GROUPS_PAST_EVENTS,
                                payload: true,
                              });
                              let arr;
                              if (event.target.checked) {
                                arr =
                                  selectedNetworks.length > 0
                                    ? [...selectedNetworks, group]
                                    : [group];
                                setSelectedNetworks(arr);
                                dispatch({
                                  type: EVENT_APPLY_GROUP_FILTER,
                                  payload: {
                                    checked_value: !group.value,
                                    postIndex: key,
                                  },
                                });
                              } else {
                                //find this value in array and delete it
                                let checkedValue = event.target.name;
                                let index = selectedNetworks.findIndex(
                                  (x) => x.group_name == checkedValue
                                );
                                selectedNetworks.splice(index, 1);
                                arr = selectedNetworks;
                                dispatch({
                                  type: EVENT_APPLY_GROUP_FILTER,
                                  payload: {
                                    checked_value: !group.value,
                                    postIndex: key,
                                  },
                                });
                              }
                              if (arr.length > 0) {
                                //
                                if (arr.length == 1) {
                                  let selectedNetwork = arr[0];
                                  let id = selectedNetwork.id;

                                  dispatch(
                                    EMDoGetPastEventsByGroupFilterAction({
                                      userData,
                                      groupId: id,
                                    })
                                  );
                                  dispatch(
                                    EMDoGetUpcomingEventsByGroupFilterAction({
                                      userData,
                                      groupId: id,
                                    })
                                  );
                                } else if (arr.length > 1) {
                                  // let action
                                  let groupAction;
                                  // let organizationAction
                                  let groupIds = [];

                                  arr.map((selectedGroup, key) => {
                                    groupIds.push(selectedGroup.id);
                                    groupAction = `${groupIds.toString()}`;
                                  });
                                  dispatch(
                                    EMDoGetPastEventsByGroupFilterAction({
                                      userData,
                                      groupId: groupAction,
                                    })
                                  );
                                  dispatch(
                                    EMDoGetUpcomingEventsByGroupFilterAction({
                                      userData,
                                      groupId: groupAction,
                                    })
                                  );
                                }
                              } else if (arr.length == 0) {
                                dispatch(EMDoGetPastEventsAction({ userData }));
                                dispatch(
                                  EMDoGetUpcomingEventsAction({ userData })
                                );
                              }
                            }}
                          />
                        }
                        label={group.group_name}
                        key={group.id}
                      />
                    </FormGroup>
                  ))}
                </FormControl>
              </Paper>
            </div>
          </Grid>
        ) : null}
      </Grid>
    </EMPage>
  );
}
export default withWidth()(SearchEvents);
