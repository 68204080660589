import React, { useState, useEffect, useCallback, useRef } from "react";
import EMPage from "../../../../components/commans/EMPage";
import {
  Box,
  Grid,
  Button,
  Paper,
  Avatar,
  Fab,
  CssBaseline,
  useMediaQuery,
  CircularProgress,
} from "@material-ui/core/";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/styles";
import EMChannelsPreview from "../../../../components/user/EMChannelsPreview";
import EMStartTyping from "./EMStartTyping";
import EMDropdown from "../../../../components/user/EMCategoriesDropdown";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import EMSingleEvent from "../../../../components/user/EMSingleEvent";
import Typography from "@material-ui/core/Typography";
import { Carousel } from "react-responsive-carousel";
import {
  EMDoGetChannelPreviewListAction,
  EMDoGetPostsListAction,
  EMDoLikeDislikePinUnpinPostAction,
  EMDoGetHashTagListsAction,
  EMDoGetCategoryListAction,
  EMDoGetCategoryFilteredPostListAction,
  EMDoLikeDislikePostAction,
} from "../../../../redux/actions/UserPlatform/Home/EMLatestPostPageAction";
import {
  SHOW_NEXT_POSTS,
  POST_CATEGORY_SELECTED_DATA,
  CLEAR_POST_FROM_STATE,
  CHANGE_LIKE_STATUS_OF_POST,
  CHANGE_LIKE_BUTTON_STATE,
} from "../../../../redux/constants/UserPlatform/index";
import { SHOW_EVENT_DETAILS } from "../../../../redux/constants/UserPlatform/EMEventsConstant";
import { useNavigate } from "react-router";
import { EMDoGetPrivacyList } from "../../../../redux/actions/EMNetworkAdminActions";
import { findLast } from "lodash";
import { EMDoGetUpcomingEventsAction } from "../../../../redux/actions/UserPlatform/Events";
import InfiniteScroll from "react-infinite-scroll-component";
import EMSideBar from "../../../../components/navigation/EMSideBar";
import { Event } from "@material-ui/icons";
import EMLeaderBoard from "./EMLeaderBoard";
import { debounce } from "lodash";
const EMCreatePoll = React.lazy(() =>
  import("../../../../components/user/EMCreatePoll")
);
const EMCreateOpenPoll = React.lazy(() =>
  import("../../../../components/user/EMCreateOpenPoll")
);
const EMCreateQuiz = React.lazy(() =>
  import("../../../../components/user/EMCreateQuiz")
);
const EMCreateEvent = React.lazy(() =>
  import("../../../../components/user/EMCreateEvent")
);
const EMCreatePost = React.lazy(() => import("./EMCreatePost"));
const EMQuiz = React.lazy(() => import("../../../../components/user/EMQuiz"));
const EMPost = React.lazy(() => import("../../../../components/user/EMPost"));
const EMEvent = React.lazy(() => import("../../../../components/user/EMEvent"));
const EMPoll = React.lazy(() => import("../../../../components/user/EMPoll"));

const useStyles = makeStyles((theme) => ({
  top: {
    marginTop: "5%",
    marginLeft: "15%",
    marginRight: "15%",
  },
  text: {
    marginLeft: 5,
    marginTop: 5,
    fontWeight: "bold",
  },
  button: {
    marginLeft: "auto",
    marginTop: "10",
    variant: "h4",
    fontWeight: "bold",
  },
  root: {
    display: "flex",
    marginLeft: "3",
    borderBottom: "1px solid",
    // marginBottom: '20px',
    padding: "20px",
  },
}));

export default function LatestPost(props) {
  const theme = useTheme();

  const matchesMediumScreen = useMediaQuery(theme.breakpoints.up("md"));

  const matchesLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const dispatch = useDispatch();
  const classes = useStyles();
  const data = useSelector((state) => state.home || {});
  const eventsData = useSelector((state) => state.events || {});
  const userData = useSelector((state) => state.auth || {});
  const [limit, setLimit] = useState(1);
  const [limitForCategories, setLimitForCategories] = useState(1);
  const navigate = useNavigate();
  const [category, setCategory] = useState("");
  const uiData = useSelector((state) => state.ui || {});
  const categorySelected = useSelector((state) => state.home.categorySelected);
  const allCategories = useSelector((state) => state.home.categories);
  useEffect(() => {
    dispatch({
      type: SHOW_NEXT_POSTS,
      payload: {
        nextPosts: undefined,
      },
    });

    dispatch(EMDoGetChannelPreviewListAction());
    //dispatch(EMDoGetPostsListAction({ nextPost: limit, userData }));
    dispatch(EMDoGetHashTagListsAction());
    dispatch(EMDoGetCategoryListAction());
    dispatch(EMDoGetPrivacyList());
    dispatch(EMDoGetUpcomingEventsAction({ userData }));
  }, []);

  useEffect(() => {
    if (uiData["messages"]) {
      let refObj = findLast(uiData["messages"], {
        key: "user_create_poll_post_event_quiz",
      });
      if (refObj && refObj.type === "success") {
        setLimit(1);
        dispatch(EMDoGetPostsListAction({ nextPost: limit, userData }));
        refObj.key = "user_create_poll_post_event_quiz_success";
      }
    }
  }, [uiData]);

  useEffect(() => {
    setCategory(data.categorySelected);
  }, [data.categorySelected]);

  const sendQuery = useCallback(async () => {
    if (typeof data.categorySelected === "string") {
      dispatch(EMDoGetPostsListAction({ nextPost: limit, userData }));
    } else if (typeof data.categorySelected !== "string") {
      dispatch(
        EMDoGetCategoryFilteredPostListAction({
          selectedDataOption: data.categorySelected.category_name,
          page: limitForCategories,
          userData,
        })
      );
    }
  }, [limit, limitForCategories]);

  useEffect(() => {
    if (typeof data.categorySelected === "string") {
      sendQuery(limit);
    } else if (typeof data.categorySelected !== "string") {
      sendQuery(limitForCategories);
    }
  }, [sendQuery, limit, limitForCategories]);

  const handleObserver = useCallback(() => {
    if (typeof data.categorySelected === "string" && data.next !== null) {
      setLimit((prev) => prev + 1);
    } else if (
      typeof data.categorySelected !== "string" &&
      data.next !== null
    ) {
      setLimitForCategories((prev) => prev + 1);
    }
  }, [limitForCategories, limit, data.categorySelected, data.next]);

  function prettyDate2(date) {
    var parts = date.split("-");
    var mydate = new Date(parts[0], parts[1] - 1, parts[2]);
    return mydate;
  }

  return (
    <InfiniteScroll
      dataLength={data.posts.length}
      hasMore={true}
      next={handleObserver}
    >
      <CssBaseline />
      <EMPage
        title="Latest Posts"
        style={{
          width: "100%",
          paddingLeft: matchesLargeScreen ? "8%" : "4%",
          paddingTop: matchesLargeScreen
            ? "4%"
            : matchesMediumScreen
            ? "6%"
            : "8%",
          paddingRight: matchesLargeScreen ? "8%" : "4%",
          paddingBottom: "2%",
        }}
      >
        <Grid
          container
          direction="row"
          alignItems="flexStart"
          spacing={3}

          // style={{flexGrow:1}}
        >
          {matchesMediumScreen ? (
            <Grid item xs={matchesLargeScreen ? 2 : 3}>
              <>
                <Box marginBottom="10%">
                  <EMSideBar />
                </Box>
                <Box>
                  <EMLeaderBoard fromProfile={false} />
                </Box>
              </>
            </Grid>
          ) : null}

          <Grid
            item
            xs={matchesLargeScreen ? 7 : matchesMediumScreen ? 9 : 12}
            style={{ zIndex: 1 }}
          >
            <Box>
              {data.showCreatePost.value ? (
                data.showCreatePost.route === "create_post" ? (
                  <Box style={{ marginBottom: "2%" }}>
                    <React.Suspense fallback={<CircularProgress />}>
                      <EMCreatePost />
                    </React.Suspense>
                  </Box>
                ) : data.showCreatePost.route === "create_event" ? (
                  <Box style={{ marginBottom: "2%" }}>
                    <React.Suspense fallback={<CircularProgress />}>
                      <EMCreateEvent />
                    </React.Suspense>
                  </Box>
                ) : data.showCreatePost.route === "create_quiz" ? (
                  <Box style={{ marginBottom: "2%" }}>
                    <React.Suspense fallback={<CircularProgress />}>
                      <EMCreateQuiz />
                    </React.Suspense>
                  </Box>
                ) : data.pollWithOptions ? (
                  <Box style={{ marginBottom: "2%" }}>
                    <React.Suspense fallback={<CircularProgress />}>
                      <EMCreatePoll />
                    </React.Suspense>
                  </Box>
                ) : (
                  <Box style={{ marginBottom: "2%" }}>
                    <React.Suspense fallback={<CircularProgress />}>
                      <EMCreateOpenPoll />
                    </React.Suspense>
                  </Box>
                )
              ) : (
                <Box style={{ marginBottom: "2%" }}>
                  <EMStartTyping />
                </Box>
              )}
            </Box>

            <Grid
              container
              direction="row"
              // justify="center"
              alignItems="center"
              style={{}}
            >
              <Grid item md={8} sm={6} xs={4}>
                <hr />
              </Grid>
              <Grid item md={4} sm={6} xs={8}>
                <EMDropdown
                  handleChange={(event) => {
                    let selectedDataOption =
                      event.target.value == "Images & Videos"
                        ? "Media"
                        : event.target.value;
                    dispatch({
                      type: POST_CATEGORY_SELECTED_DATA,
                      payload: selectedDataOption,
                    });
                    setLimitForCategories(1);
                    dispatch(
                      EMDoGetCategoryFilteredPostListAction({
                        selectedDataOption: selectedDataOption,
                        page: limitForCategories,
                        userData,
                      })
                    );
                  }}
                  deleteHandler={(event) => {
                    let selectedDataOption =
                      event.target.value == "Images & Videos"
                        ? "Media"
                        : event.target.value;
                    dispatch({
                      type: POST_CATEGORY_SELECTED_DATA,
                      payload: selectedDataOption,
                    });
                    dispatch({ type: CLEAR_POST_FROM_STATE, payload: [] });
                    // setLimitForCategories(1)
                    setLimit(1);
                    dispatch(
                      EMDoGetPostsListAction({ nextPost: limit, userData })
                    );
                  }}
                  selectedCategory={categorySelected}
                  selectedDataForDropdown={allCategories}
                />
              </Grid>
            </Grid>
            <Box style={{ marginTop: "2%" }}>
              {data.posts.map((post, id) =>
                post.is_event ? (
                  <React.Suspense fallback={<CircularProgress />}>
                    <EMEvent
                      permission={true}
                      post={post}
                      pinHandler={() => {
                        let actions = [];
                        post.isPinnedPost
                          ? actions.push(`unpin`)
                          : actions.push(`pin`);
                        let queryData = {
                          id: post.id,
                          action: actions[0],
                        };
                        dispatch(
                          EMDoLikeDislikePinUnpinPostAction({ queryData })
                        );
                      }}
                      likeHandler={() => {
                        let actions = [];
                        post.liked
                          ? actions.push(`dislike`)
                          : actions.push(`like`);
                     
                        let queryData = {
                          id: post.id,
                          action: actions[0],
                        };

                        dispatch({
                          type:CHANGE_LIKE_STATUS_OF_POST,
                          payload:{
                            id:post.id,
                            isLiked: post.liked ? false : true
                          }
                        })
                        dispatch({
                          type:CHANGE_LIKE_BUTTON_STATE,
                          payload:{
                            id:post.id,
                            isDisabled:true
                          }
                        })

                        dispatch(
                          EMDoLikeDislikePostAction({ queryData: queryData })
                        );
                      }}
                    />
                  </React.Suspense>
                ) : post.is_poll ? (
                  <React.Suspense fallback={<CircularProgress />}>
                    <EMPoll
                      permission={true}
                      post={post}
                      pinHandler={() => {
                        let actions = [];
                        post.isPinnedPost
                          ? actions.push(`unpin`)
                          : actions.push(`pin`);
                        let queryData = {
                          id: post.id,
                          action: actions[0],
                        };
                        dispatch(
                          EMDoLikeDislikePinUnpinPostAction({ queryData })
                        );
                      }}
                    />
                  </React.Suspense>
                ) : post.is_quiz ? (
                  <React.Suspense fallback={<CircularProgress />}>
                    <EMQuiz
                      quizFromQuiz={false}
                      permission={true}
                      post={post}
                      pinHandler={() => {
                        let actions = [];
                        post.isPinnedPost
                          ? actions.push(`unpin`)
                          : actions.push(`pin`);
                        let queryData = {
                          id: post.id,
                          action: actions[0],
                        };
                        dispatch(
                          EMDoLikeDislikePinUnpinPostAction({ queryData })
                        );
                      }}
                    />
                  </React.Suspense>
                ) : (
                  <React.Suspense fallback={<CircularProgress />}>
                    <EMPost
                      permission={true}
                      post={post}
                      pinHandler={() => {
                        let actions = [];
                        post.isPinnedPost
                          ? actions.push(`unpin`)
                          : actions.push(`pin`);
                        let queryData = {
                          id: post.id,
                          action: actions[0],
                        };
                        dispatch(
                          EMDoLikeDislikePinUnpinPostAction({ queryData })
                        );
                      }}
                      likeHandler={() => {
                        let actions = [];
                        post.liked
                          ? actions.push(`dislike`)
                          : actions.push(`like`);
                        // post.liked = !post.liked;
                        let queryData = {
                          id: post.id,
                          action: actions[0],
                        };

                        dispatch({
                          type:CHANGE_LIKE_STATUS_OF_POST,
                          payload:{
                            id:post.id,
                            isLiked: post.liked ? false : true
                          }
                        })
                        dispatch({
                          type:CHANGE_LIKE_BUTTON_STATE,
                          payload:{
                            id:post.id,
                            isDisabled:true
                          }
                        })

                        dispatch(
                          EMDoLikeDislikePostAction({ queryData: queryData })
                        );
                      }}
                    />
                  </React.Suspense>
                )
              )}
            </Box>
          </Grid>
          {matchesLargeScreen ? (
            <Grid
              item
              xs={3}
              style={{
                marginBottom: "2%",
                // position: "fixed",
                // marginLeft: "64%",
              }}
            >
              <Paper elevation={2} style={{ marginBottom: "5%" }}>
                <div style={{ padding: 20 }}>
                  <div style={{ display: "flex" }}>
                    <Event fontSize="large" color="primary" />
                    {/* <img src={calendar} alt="" /> */}
                    <Typography
                      style={{
                        color: "#2F92D6",
                        fontWeight: "bolder",
                        fontSize: "medium",
                        marginLeft: "5%",
                      }}
                    >
                      {" "}
                      UPCOMING EVENTS{" "}
                    </Typography>
                  </div>
                  <div style={{ marginTop: "5%" }}>
                    <Carousel
                      swipeable={true}
                      emulateTouch={true}
                      renderArrowPrev={(onClickHandler, hasPrev, label) =>
                        hasPrev && (
                          <Button
                            type="button"
                            onClick={onClickHandler}
                            title={label}
                            style={{ ...arrowStyles, left: 0 }}
                          >
                            <ChevronLeftIcon fontSize="medium" />
                          </Button>
                        )
                      }
                      renderArrowNext={(onClickHandler, hasNext, label) =>
                        hasNext && (
                          <Button
                            type="button"
                            onClick={onClickHandler}
                            title={label}
                            style={{ ...arrowStyles, right: 0 }}
                          >
                            <ChevronRightIcon fontSize="medium" />
                          </Button>
                        )
                      }
                      showThumbs={false}
                      showIndicators={false}
                    >
                      {eventsData.upcomingEvents &&
                        eventsData.upcomingEvents.map((event, key) => (
                          <div>
                            <EMSingleEvent
                              groupName={event.groupName}
                              route="latest-post"
                              eventBanner={
                                event.banner_file
                                  ? process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(
                                      0,
                                      -1
                                    ) + event.banner_file
                                  : null
                              }
                              eventName={event.title}
                              eventLocation={event.event_location}
                              eventStart={event.event_start_time}
                              eventEnd={event.event_end_time}
                              eventStartDate={
                                event.event_start &&
                                prettyDate2(event.event_start)
                              }
                              getEventDetails={() => {
                                dispatch({
                                  type: SHOW_EVENT_DETAILS,
                                  payload: {
                                    route: "upcomingEvents",
                                    eventId: event.id,
                                    value: true,
                                  },
                                });
                                navigate("/users/events/searchevents");
                              }}
                            />
                          </div>
                        ))}
                    </Carousel>
                  </div>
                </div>
              </Paper>
              <EMChannelsPreview data={data.channelList} route="home" />
            </Grid>
          ) : null}
        </Grid>
      </EMPage>
    </InfiniteScroll>
  );
}

const arrowStyles = {
  position: "absolute",
  zIndex: 2,
  top: "calc(35% - 15px)",
  width: 30,
  height: 30,
  color: "white",
  cursor: "pointer",
  backgroundColor: "transparent",
};
