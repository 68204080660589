import React, { useState, useEffect } from "react";
import { Typography, Grid, Paper, useMediaQuery } from "@material-ui/core";
import { useParams } from "react-router";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import EMPage from "../../../components/commans/EMPage";
import { useDispatch, useSelector } from "react-redux";
import {
  EMDoGetHashTagListsAction,
  EMDoLikeDislikePinUnpinPostAction,
  EMDoLikeDislikePostAction,
} from "../../../redux/actions/UserPlatform/Home/EMLatestPostPageAction";
import {
  EMDoGetEventsBySearchKeyAction,
  EMDoGetPostsBySearchKeyAction,
} from "../../../redux/actions/EMCommonActions";
import EMEvent from "../../../components/user/EMEvent";
import EMPost from "../../../components/user/EMPost";
import EMSideBar from "../../../components/navigation/EMSideBar";
import { debounce } from "lodash";
import { CHANGE_LIKE_BUTTON_STATE, CHANGE_LIKE_STATUS_OF_POST } from "../../../redux/constants/UserPlatform";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "2%",
  },
  header: {
    fontWeight: "bolder",
    fontSize: "1.5rem",
  },
  subHeader: {
    fontWeight: "bolder",
    fontSize: "1.2rem",
  },
  title: {
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.1rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.5rem",
    },
    fontWeight: "bold",
    marginLeft: "1%",
    marginBottom: "2%",
    //
  },
  images: {
    [theme.breakpoints.up("sm")]: {
      height: "90%",
    },
  },

  networkInfo: {
    textAlign: "center",
    height: "80px",
    // marginTop:'20%'
  },
  gridListTile: {
    marginLeft: "2%",
    marginBottom: "2%",
    marginTop: "2%",
    borderRadius: "10px",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);",
  },
  root: {
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
    marginTop: "2%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    // padding:"10px",
    width: "100%",
    height: "100%",
    maxHeight: window.screen.height,
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#4A4A4A",
      outline: "1px solid slategrey",
    },
  },
}));

export default function EMHashTags(props) {
  const [hashtagId, setHashtagId] = useState();
  const [hashtagKey, setHashtagKey] = useState();
  const data = useSelector((state) => state.navigation || {});
  const userData = useSelector((state) => state.auth || {});
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();
  const hashtagData = useSelector((state) => state.home.postHashTags || {});

  const theme = useTheme();

  const matchesMediumScreen = useMediaQuery(theme.breakpoints.up("md"));

  const matchesLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const getHashtagId = async () => {
    let param = await params;
    if (param) {
      setHashtagId(params.id);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (hashtagId) {
        setHashtagKey(
          hashtagData &&
            hashtagData.filter((obj) => {
              return obj.id == hashtagId;
            })
        );
      }
    }, 100);
  }, [hashtagId]);

  useEffect(() => {
    getHashtagId();
  }, [params]);

  useEffect(() => {
    dispatch(EMDoGetHashTagListsAction());
  }, []);

  useEffect(() => {
    if (hashtagKey) {
      if (hashtagKey.length > 0) {
        sessionStorage.setItem("hashtagWord", hashtagKey[0].display);
      }
    }
    dispatch(
      EMDoGetPostsBySearchKeyAction({
        searchText: window.sessionStorage.getItem("hashtagWord"),
        userData: userData,
      })
    );
    dispatch(
      EMDoGetEventsBySearchKeyAction({
        searchText: window.sessionStorage.getItem("hashtagWord"),
        userData: userData,
      })
    );
  }, [hashtagKey]);

  return (
    <EMPage title="Hashtags"       style={{
      width: "100%",
      paddingLeft: matchesLargeScreen  ? "8%" : "4%",
      paddingTop: matchesLargeScreen ? "4%" : matchesMediumScreen ? "6%" : "8%",
      paddingRight: matchesLargeScreen  ? "8%" : "4%",
      paddingBottom: "2%",
    }}>
         <Grid container spacing={3}>
         {matchesMediumScreen ? (
            <Grid item xs={matchesLargeScreen ? 2 : 3}>
              <EMSideBar />
            </Grid>
          ) : null}
          <Grid item xs={matchesLargeScreen ? 10 : matchesMediumScreen ? 9 : 12}>
      <Paper elevation={2} className={classes.paper}>
        <Typography className={classes.header}>
          "{window.sessionStorage.getItem("hashtagWord")}"
        </Typography>
        <div style={{ marginBottom: "2%" }}>
          <Typography className={classes.subHeader}>Posts</Typography>
        </div>
        {data.searchedPosts &&
          data.searchedPosts.map((post, id) => (
            <EMPost
              searchedPost={true}
              permission={true}
              post={post}
              pinHandler={() => {
                let actions = [];
                post.isPinnedPost ? actions.push(`unpin`) : actions.push(`pin`);
                let queryData = {
                  id: post.id,
                  action: actions[0],
                };
                dispatch(EMDoLikeDislikePinUnpinPostAction({ queryData }));
              }}
              likeHandler={() => {
                let actions = [];
                post.liked ? actions.push(`dislike`) : actions.push(`like`);
                let queryData = {
                  id: post.id,
                  action: actions[0],
                };

                dispatch({
                  type:CHANGE_LIKE_STATUS_OF_POST,
                  payload:{
                    id:post.id,
                    isLiked: post.liked ? false : true
                  }
                })
                dispatch({
                  type:CHANGE_LIKE_BUTTON_STATE,
                  payload:{
                    id:post.id,
                    isDisabled:true
                  }
                })
       
                  dispatch(
                    EMDoLikeDislikePostAction({  queryData })
                  );
          
              }}
            />
          ))}
        <div style={{ marginBottom: "2%" }}>
          <Typography className={classes.subHeader}>Events</Typography>
        </div>
        {data.searchedEvents &&
          data.searchedEvents.map((post, id) => (
            <EMEvent
              permission={true}
              searchedEvent={true}
              post={post}
              pinHandler={() => {
                let actions = [];
                post.isPinnedPost ? actions.push(`unpin`) : actions.push(`pin`);
                let queryData = {
                  id: post.id,
                  action: actions[0],
                };
                dispatch(EMDoLikeDislikePinUnpinPostAction({ queryData }));
              }}
              likeHandler={() => {
                let actions = [];
                post.liked ? actions.push(`dislike`) : actions.push(`like`);

                let queryData = {
                  id: post.id,
                  action: actions[0],
                };

                dispatch({
                  type:CHANGE_LIKE_STATUS_OF_POST,
                  payload:{
                    id:post.id,
                    isLiked: post.liked ? false : true
                  }
                })
                dispatch({
                  type:CHANGE_LIKE_BUTTON_STATE,
                  payload:{
                    id:post.id,
                    isDisabled:true
                  }
                })

                  dispatch(
                    EMDoLikeDislikePostAction({ queryData: queryData })
                  );

              }}
            />
          ))}
      </Paper>
      </Grid>
      </Grid>
    </EMPage>
  );
}
