import React, { useState, useEffect } from "react";
import {
  Paper,
  Box,
  Button,
  Modal,
  Typography,
  IconButton,
  Grid,
  FormControlLabel
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import EMTextFieldSearch from "../../../../../components/datatables/EMDatatableSearchView";
import EMResendInviteTable from "./EMResendInviteTable"
import { CloseRounded, CheckBox } from "@material-ui/icons";
import EmailTemplateDropdown from "../../../commons/EmailTemplateDropdown";
import Checkbox from '@material-ui/core/Checkbox';
import { find, isEmpty } from "lodash";
import { 
  EMDOGetChannelAdminEmailTemplateTypeListAction,
  EMDoResendInviteToChannelAdminUsersAction 
} from "../../../../../redux/actions/EMChannelAdminActions";
import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    height: "auto",
    width: "50%",
    marginTop: 10,
    margin: "auto",
    padding: "12px",
    marginLeft: "25%",
  },
  paper: {
    margin: "3%"
  },
  title: {
    margin: 10,
    flexGrow: 1,
    fontWeight: "bold",
  },
  form: {
    padding: "0px 36px",
    width: "100%",
  },
  button: {
    padding: theme.spacing(1),
    margin:20
  },
}));

export default function EMImportUsers(props) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(false);
  const [filter, setFilter] = useState("");
  const [role,setRole]=useState()
  const [tabledata, setTabledata] = React.useState([])
  const [open, setOpen] = React.useState(false);
  const [selectAllFlag, setSelectAllFlag] = React.useState(false);
  const [selectAll, setSelectAll] = React.useState(false);
  const [emailTemplate, setEmailTemplate] = React.useState();
  const [showTemplateError, setShowTemplateError] = React.useState();
  const [isError, setIsError] = React.useState(true);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  const data = useSelector(
    state => state.channelAdmin.resendUsersList || {}
  );

  const uiData = useSelector((state) => state.ui);

  useEffect(() => {
    if (uiData["messages"]) {
      let refObj = find(uiData["messages"], { key: "user_import" });
      if (refObj && refObj.type === "success") {
        setOpen(false);
      }
    }
  }, [uiData]);

  useEffect(() =>
   {
      dispatch(EMDOGetChannelAdminEmailTemplateTypeListAction(props.channelId))
   },[props.channelId])

  useEffect(() =>
   {
      if(data.results){
        setTabledata({...data, results:[...data["results"].map(e => ({...e, add_flag:false}))]})
        // setSelectAllFlag(tabledata["results"].map(i => i.add_flag===false ? false :true).find(e=>e===false))
      }
   },[data])

   useEffect(() =>
   {
      if(tabledata.results){
        setSelectAll(
          data ? data["results"].map(
              i => i.add_flag===false ? false :true).find(
              e=>e===false) : false
            )
      }
   },[tabledata])

   const templateSelectionHandler = () => {
    if (isEmpty(emailTemplate)) {
      setShowTemplateError("Please Select Email Template Here");
      setIsError(false);
    } else {
      setShowTemplateError("");
      setIsError(true);
    }
  };

  useEffect(()=>{
    if(!isEmpty(emailTemplate)){
      setShowTemplateError("");
      setIsError(true);
    }
  },[emailTemplate])
 
  const handleChange = (event) => {
    setChecked(false);
  };
  const dispatch = useDispatch();
  let options = ['Default', 'manager', 'Network', 'The Elite Network']
  let roleOptions = ['Default', 'manager', 'Network', 'The Elite Network']
  return (
    <Modal open={props.open} onClose={()=>{
        props.closeHandler(false)  
        setEmailTemplate()
        setSelectAllFlag(false)
      }}>
      <Box className={classes.root}>
        <form className={classes.paper}>

          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <Typography className={classes.title}>
              {"Resend Invitations"}
            </Typography>
            <IconButton onClick={()=>{
              props.closeHandler(false)  
              setEmailTemplate()
              setSelectAllFlag(false)
            }}>
              <CloseRounded color="secondary" />
            </IconButton>
          </Box>
          <Grid container justify="flex-start" style={{ marginLeft: 10 }}>
            <Grid xs={6}>
              <EMTextFieldSearch hint={"Search for User Name, Email-Address"}
               onChangeListener={(data) => {
                setFilter(data);
              }} />
            </Grid>
             {/* <Grid xs={3}>
              <EMDropDown title={"sort by"} options={options} />
            </Grid> */}
            <Grid xs={3}>
              {
                data["count"]  > 0?
              <FormControlLabel
                id = "select_all_checkbox"
                value="all"
                control={
                  <Checkbox
                    checked = {selectAllFlag}
                  />
                }
                label="Select All"
                labelPlacement="end"
                onChange={event => {                  
                   let state = event.target.checked;
                   
                   if(tabledata.results)
                    {
                      setTabledata({...tabledata, results:[...tabledata["results"].map(e => ({...e, add_flag:state}))]})
                      setSelectAllFlag(!selectAllFlag)
                    }
                }}
              />
              :
              <FormControlLabel
                value="all"
                control={<Checkbox />}
                label="Select All"
                labelPlacement="end"
                disabled = {true}
              />
              }
              
            </Grid>
          </Grid>
          <Grid>
            <EMResendInviteTable 
            
            data = {tabledata}
            isChecked ={checked}
            filter={filter} 
            channelId={props.channelId}
            open={handleOpen}
            close={handleClose}
            onChangePermission={permissionData => {
              
              if(tabledata.results){
                setTabledata({...tabledata, results:[...tabledata["results"].map(e => (
                  {
                    ...e, add_flag: permissionData.id == e.id ? permissionData.add_flag : e.add_flag 
                  }
                ))]})
                setSelectAllFlag(false)
              }
          }}
          />                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  
            <Box style={{ marginLeft: "2%", width: "100%" }}>
               <EmailTemplateDropdown
                onSelect={id => {
                  setEmailTemplate(id);
                }}
                title={"Email Template"}
                style={{ width: "50%" }}
                error = {showTemplateError}
              /> 
            </Box>
          </Grid>
          <Grid container justify={"center"}>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              type="submit"
              onClick={(e) => {
                e.preventDefault()
                templateSelectionHandler()
                
                if(!isEmpty(emailTemplate)){
                  let keys = []
                  selectAllFlag ? keys = [] : keys = tabledata["results"].filter(x => x.add_flag==true).map(x => x.key)
                  let data = {
                    key: keys,
                    select_all : selectAllFlag,  
                    
                  }
                  let contextData = {
                    channelId : props.channelId,
                    emailTemplate: emailTemplate.id
                  }
                  dispatch(EMDoResendInviteToChannelAdminUsersAction({
                    data,
                    contextData,
                    key: "resend_list_success_ca"
                  }))
                }
                
              }}
            >
              <Typography variant="h7">Invite</Typography>
            </Button>
      
            <Button
              className={classes.button}
              variant="outlined"
              color="secondary"
              onClick={()=>{
                props.closeHandler(false)  
                setEmailTemplate()
                setSelectAllFlag(false)
              }}
            >
              <Typography variant="h7">Discard</Typography>
            </Button>
          </Grid>
        </form>
      </Box >
    </Modal >
  );
}
