import { UI_LOADING, ERROR, SHOW_MESSAGE, HIDE_MESSAGE, AUTH_LOGOUT } from "../constants";
import { cloneDeep, findIndex } from "lodash";

const initialState = { loading: {state:false, message:""}, messages: [] };

export default function EMUIReducer(state = initialState, actions) {
  let newState = cloneDeep(state);
  switch (actions.type) {
    case UI_LOADING: {
      newState.loading.state = actions.payload.state;
      newState.loading.message = actions.payload.message
      return newState;
    }

    case ERROR: {
      newState.loading.state = false;
      newState.loading.message =""
      newState.error = true;
      newState.message = actions.payload;
      return newState;
    }

    case SHOW_MESSAGE: {
      
      newState.messages.push(actions.payload);
      return newState;
    }

    case HIDE_MESSAGE: {
      
      if (actions.payload.key) {
        let index = findIndex(newState["messages"], {
          key: actions.payload.key
        });
        if (index > -1) {
          newState["messages"] = newState["messages"].splice(index, 1);
        }
      } else {
        let index = findIndex(newState["messages"], {
          message: actions.payload.message
        });
        if (index > -1) {
          newState["messages"] = newState["messages"].splice(index, 1);
        }
      }
      return newState;
    }

    case AUTH_LOGOUT: {
      return { ...initialState };
    }

    default: {
      return newState;
    }
  }
}
