import React from "react";
import {
    Box,
    Button,
    Modal,
    Typography,
    IconButton,
    Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import {
    EMSimpleTextFieldView
} from "../../../../components/EMTextFieldView";
import { CloseRounded } from "@material-ui/icons";
import { useFormik } from "formik";
import * as yup from "yup";
import {
    EMDoCreateEmailTemplateAction,
    EMDoUpdateEmailTemplateAction,
    EMDOGetEmailTemplateTypeListAction,
    EMDoUpdateLevelInOrganizationAction,
    EMDoCreateNewLevelInOrganizationAction
} from "../../../../redux/actions/EMNetworkAdminActions";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import EMTemplateTypeDropdown from "../../UserManagement/commons/EMTemplateTypeDropdown";
import EMTextArea from "../../../../components/commans/EMTextArea"
import { isEmpty } from "lodash-es";
import { EMDoCreateNewLevelAction } from "../../../../redux/actions/EMCommonActions";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.common.white,
        height: "auto",
        minWidth: "50%",
        margin: "auto",
        padding: "0px",
        // maxWidth: '100vw',
        maxHeight: '100%',
        position: 'fixed',
        top: '50%',
        transform: 'translate(0, -50%)',
        width: "60%",
        overflowY: "auto",
        overflowX: "hidden"
    },
    title: {
        margin: 10,
        flexGrow: 1,
        fontWeight: "bold",
        color: "white",
        fontSize: "large",
    },
    form: {
        padding: "8px 36px",
        width: "100%",
    },
    buttonHolder: {
        padding: theme.spacing(3),
    },
}));

export default function EMAddEditLevel(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
  
    //reset the form after modal is closed
    /* Validation And Formik Values And Handlers */
    const validationSchema = yup.object({
      levelName: yup.string().required("Level Name is required"),
    });
  

  
    const formik = useFormik({
      // enableReinitialize :props.isEdit,
  
      enableReinitialize: true,
      initialValues: {
        levelName:
          (props.levelData && props.levelData["level"]) || "",
      },
      validationSchema: validationSchema,
  
      onSubmit: (values) => {
        if (props.levelData && isEmpty(props.levelData)) {
          let data = {
            organizationId: props.entity && props.entity.context,
            level: values.levelName,
          };
          dispatch(EMDoCreateNewLevelInOrganizationAction({ data: data,  key: "add_level", }));
        }else{
          let data = {
              organizationId: props.entity && props.entity.context,
              level: values.levelName,
              levelId:props.levelData && props.levelData.id
            };
            dispatch(EMDoUpdateLevelInOrganizationAction({data:data,  key: "add_level"}))
        }
        // formik.resetForm()
        // props.setOpenFalse(false)
      },
    });

    useEffect(() => {
        return () => {
          formik.resetForm();
        };
      }, [props.open]);

    return (
        <Modal
            open={props.open}
            onClose={() => {
                formik.resetForm()
                props.setOpenFalse(false)
            }}
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Box className={classes.root} style={{ outline: 'none' }}>
                <form onSubmit={formik.handleSubmit}>
                    <Box
                        width="100%"
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            padding: "0px 4px 0px 18px",
                            backgroundColor: "#2F92D6",
                            height: "auto",
                          }}
                    >
                        <Typography className={classes.title}>
                            {props.titleLable}
                        </Typography>
                        <IconButton onClick={() => {
                            formik.resetForm()
                            props.setOpenFalse(false)
                        }}>
                            <CloseRounded style={{color:"white"}} />
                        </IconButton>
                    </Box>
                    <Grid
                        container
                        justify="center"
                        alignItems="center"
                        className={classes.form}
                        style={{paddingTop:"16px"}}
                    >
                        <EMSimpleTextFieldView
                            label={"Level Name"}
                            id="levelName"
                            name="levelName"
                            fullwidth
                            value={formik.values.levelName}
                            onChange={formik.handleChange}
                            // error={formik.touched.levelName && Boolean(formik.errors.levelName)}
                            helperText={formik.touched.levelName && formik.errors.levelName}
                        />
                    </Grid>

                    <Grid
                        container
                        justify="center"
                        alignItems="center"
                        direction="row"
                        className={classes.buttonHolder}
                        spacing={2}
                        // onClick={() => {
                        //     //checkValidation()
                        // }}
                    >
                        <Grid item>
                            <Button
                                className={classes.button}
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={props.saveClicked}
                            >
                                <Typography variant="h7">{props.buttonLabel}</Typography>
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                className={classes.button}
                                variant="outlined"
                                color="secondary"
                                onClick={() => {
                                    formik.resetForm()
                                    props.setOpenFalse(false)
                                }}
                            >
                                <Typography variant="h7">Discard</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Modal>
    );
}
