import { Grid, Typography, Paper  ,Tooltip} from "@material-ui/core";
import EMSimpleDatatable from "../../../components/datatables/EMSimpleDatatable";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import EMPage from "../../../components/commans/EMPage";
import { EMDoGetUserEngagementReportForGroupAction } from "../../../redux/actions/EMGroupAdminActions";

const useStyles = makeStyles((theme) => ({
  typo: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 1,
    wordBreak: "break-word",
    overflow: "hidden",
  },
}));

export default function EMUserEngagementReport({initialData, ...props}) {
  const theme = useTheme();
  const groupAdmin = useSelector((state) => state.groupAdmin || {});
  const [userEngagement, setUserEngagement] = useState([]);
  const classes = useStyles()
  const [limit, setLimit] = useState(15);
  const [offset, setOffset] = useState(0);
  const [order, setOrder] = useState({ id: -1, direction: "asc" });
  const [sortAscending, setSortAscending] = useState(false);
  const dispatch = useDispatch();

  const groupColumns = [
    {
      title: "Organization Name",
      field: "organziation_name",
      align: "left",
      render: (data) => {
        let name = data["entity_name"];
        return (
        <>
         <Tooltip title={<h2 style={{ color: "white" }}>{name}</h2>} placement='top'>
         <Typography className={classes.typo}>{ name && name}</Typography>
         </Tooltip>
         </>
        )
      },
    },
    {
      title: "Group Name",
      field: "group_name",
      align: "left",
      render: (data) => {
        let name = data["group_name"];
        return (
          <>
           <Tooltip title={<h2 style={{ color: "white" }}>{name}</h2>} placement='top'>
           <Typography className={classes.typo}>{ name && name}</Typography>
           </Tooltip>
           </>
          )
      },
    },
    {
      title: "Active Users(Based on activity)",
      field: "users_active",
      align: "left",
      render: (data) => {
        let name = data["users_active"];
        return <Typography>{name}</Typography>;
      },
    },
    {
      title: "Active Marked Users",
      field: "active_marked_users",
      align: "left",
      render: (data) => {
        let name = data["active_marked_users"];
        return <Typography>{name}</Typography>;
      },
    },
    {
      title: "No Of Posts",
      field: "no_of_posts",
      align: "left",
      render: (data) => {
        let name = data["no_of_posts"];
        return <Typography>{name}</Typography>;
      },
    },
    {
      title: "No Of Comments",
      field: "no_of_comments",
      align: "left",
      render: (data) => {
        let name = data["no_of_comments"];
        return <Typography>{name}</Typography>;
      },
    },
    {
      title: "No Of Likes",
      field: "no_of_likes",
      align: "left",
      render: (data) => {
        let name = data["no_of_likes"];
        return <Typography>{name}</Typography>;
      },
    },
    {
      title: "No Of Admins",
      field: "no_of_admins",
      align: "left",
      render: (data) => {
        let name = data["no_of_admins"];
        return <Typography>{name}</Typography>;
      },
    },
  ];

  const channelColumns = [
    {
      title: "Organization Name",
      field: "organziation_name",
      align: "left",
      render: (data) => {
        let name = data["entity_name"];
        return (
          <>
           <Tooltip title={<h2 style={{ color: "white" }}>{name}</h2>} placement='top'>
           <Typography>{ name &&  name.length >18?  name.slice(0,18)+"...":name}</Typography>
           </Tooltip>
           </>
          )
      },
    },
    {
      title: "Group Name",
      field: "group_name",
      align: "left",
      render: (data) => {
        let name = data["group_name"];
        return(
          <>
           <Tooltip title={<h2 style={{ color: "white" }}>{name}</h2>} placement='top'>
           <Typography>{ name &&  name.length >18?  name.slice(0,18)+"...":name}</Typography>
           </Tooltip>
           </>
          )
      },
    },
    {
      title: "Channel Name",
      field: "channel_name",
      align: "left",
      render: (data) => {
        let name = data["channel_name"];
        return(
          <>
           <Tooltip title={<h2 style={{ color: "white" }}>{name}</h2>} placement='top'>
           <Typography>{ name &&  name.length >18?  name.slice(0,18)+"...":name}</Typography>
           </Tooltip>
           </>
          )
      },
    },
    {
      title: "Active Users(Based on activity)",
      field: "users_active",
      align: "left",
      render: (data) => {
        let name = data["users_active"];
        return <Typography>{name}</Typography>;
      },
    },
    {
      title: "Active Marked Users",
      field: "active_marked_users",
      align: "left",
      render: (data) => {
        let name = data["active_marked_users"];
        return <Typography>{name}</Typography>;
      },
    },
    {
      title: "No Of Posts",
      field: "no_of_posts",
      align: "left",
      render: (data) => {
        let name = data["no_of_posts"];
        return <Typography>{name}</Typography>;
      },
    },
    {
      title: "No Of Comments",
      field: "no_of_comments",
      align: "left",
      render: (data) => {
        let name = data["no_of_comments"];
        return <Typography>{name}</Typography>;
      },
    },
    {
      title: "No Of Likes",
      field: "no_of_likes",
      align: "left",
      render: (data) => {
        let name = data["no_of_likes"];
        return <Typography>{name}</Typography>;
      },
    },
    {
      title: "No Of Admins",
      field: "no_of_admins",
      align: "left",
      render: (data) => {
        let name = data["no_of_admins"];
        return <Typography>{name}</Typography>;
      },
    },
  ];

  const updateData = (query) => {
    
    dispatch(
      EMDoGetUserEngagementReportForGroupAction({
        requiredData: initialData,
        paginationData: query,
      })
    );
  };

  useEffect(() => {

      setOffset(0);
  }, [initialData]);

  /* forming url wrt functionality */
  useEffect(() => {
setTimeout(() => {
  const query = [];
  if (limit !== 0) {
    query.push(`limit=${limit}`);
  }
  if (offset !== 0) {
    query.push(`offset=${offset}`);
  }
  if (order.id !== -1) {
    /* Logic for including sorting */
    if (sortAscending === false) {
      query.push(
        `ordering=${order.direction === "desc" ? "-" : ""}${
          props.groupOrChannel && props.groupOrChannel == true
            ? groupColumns[order.id - 1].field
            : channelColumns[order.id - 1].field
        }`
      );
      setSortAscending(true);
    } else {
      // order.direction = "desc"
      query.push(
        `ordering=${order.direction === "desc" ? "-" : ""}${
          props.groupOrChannel && props.groupOrChannel == true
            ? groupColumns[order.id - 1].field
            : channelColumns[order.id - 1].field
        }`
      );
      setSortAscending(false);
    }
  }
  updateData(query.join("&"));
}, 1000)
  }, [limit, offset, order, initialData]);

  useEffect(() => {
    if (groupAdmin.userEngagementGroup) {
      
      let newArray = groupAdmin.userEngagementGroup;
      setUserEngagement(newArray);
    }
  }, [groupAdmin]);

  return (
    <EMPage title="User Engagement">
      <Paper elevation={2}>
        <Typography
          style={{
            paddingTop: "2%",
            paddingLeft: "2%",
            fontWeight: "bolder",
            fontSize: "large",
          }}
        >
          User Engagement Report
        </Typography>
        <Grid style={{ padding: "2%" }}>
          <EMSimpleDatatable
          filter={initialData && initialData.organizationOrPartnershipId + initialData.isPartnership + initialData.groupOrChannel + initialData.start_date + initialData.end_date}
          columns={
            props.groupOrChannel && props.groupOrChannel == true
              ? groupColumns
              : channelColumns
          }
          data={userEngagement.results || []}
          count={userEngagement.count || 0}
          updatePageAndPageSizeAndOrder={(page, pageSize, order) => {
            setOffset(page * limit);
            setLimit(pageSize);
            setOrder(order);
          }}
            options={{
              headerStyle: {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.common.white,
                fontWeight: "bold",
              },
            }}
          />
        </Grid>
      </Paper>
    </EMPage>
  );
}
