import React from "react";
import { Navigate } from "react-router-dom";
import EMAdminLayout from "./layouts/AdminLayout/index";
import EMUserLayout from "./layouts/UserLayout/index";
import {
  PlatformAdminManagement,
  PlatformOrganizationManagement,
  PlatformPartnershipManagement,
  PlatformDashboard,
  PlatformReportsManagement,
} from "./views/platformAdmin";
import {
  NetworkAdminManagement,
  EMNetworkAdminManageUsers,
  EMUserManagement,
  EMChannelManagement,
  EMGroupManagement,
  EMOrganizationManagement,
  NetworkAdminDashboard,
  NetworkAdminReports,
  EMGamificationManagement
} from "./views/NetworkAdmin";
import {
  EMUserManagementForGroup,
  EMGroupManagementForGroup,
  EMChannelManagementForGroup,
  GroupAdminDashboard,
  GroupAdminReports,
  GroupAdminGamification
} from "./views/GroupAdmin";
import {
  EMUserManagementForChannel,
  EMChannelManagementForChannel,
  ChannelAdminDashboard,
  ChannelAdminReports,
  ChannelAdminGamification
} from "./views/ChannelAdmin";
import {
  EMMyCompany,
  EMSearchOthers,
} from "./views/UserPlatform";
import EMTermsAndConditions from "./views/auth/EMTermsAndConditions";
const EMNotFoundView = React.lazy(() => import("./views/errors/EMNotFoundView"))
const EMLoginView = React.lazy(() => import("./views/auth/EMLoginView"))
const EMMainLayout = React.lazy(() => import("./layouts/MainLayout"))
const EMSignUpPage = React.lazy(() => import("./views/auth/EMSignupPage"))
const EMForgotPasswordPage = React.lazy(() => import("./views/auth/EMForgotPassword"))
const EMResetPasswordPage = React.lazy(() => import("./views/auth/EMResetPassword"))
const EMSignupPageForPlatformAdmin = React.lazy(() => import( "./views/auth/EMPlatformAdminSignupForm"))
const EMProfileDetails = React.lazy(() => import( "./views/UserPlatform/People/MyCompany/EMProfileDetails"))
const EMSearchPoll = React.lazy(() => import("./views/UserPlatform/GlobalSearch/EMSearchPoll"))
const EMSearchQuiz = React.lazy(() => import( "./views/UserPlatform/GlobalSearch/EMSearchQuizzes"))
const EMLatestPost = React.lazy(() => import("./views/UserPlatform/Home/LatestPost/index"))
const EMPinnedPost = React.lazy(() => import("./views/UserPlatform/Home/PinnedPost/index"))
const EMMyGroups = React.lazy(() => import("./views/UserPlatform/Groups/MyGroups/index"))
const EMBrowseGroups = React.lazy(() => import("./views/UserPlatform/Groups/BrowseGroups/index"))
const EMMyGroupsActivity = React.lazy(() => import("./views/UserPlatform/Groups/MyGroups/ActivityFeed/index"))
const EMGroupMembersPage = React.lazy(() => import("./views/UserPlatform/Groups/MyGroups/Members/index"))
const EMGroupSettingsPage = React.lazy(() => import("./views/UserPlatform/Groups/MyGroups/Settings/index")) 
const EMMyChannels = React.lazy(() => import("./views/UserPlatform/Channels/MyChannels/index"))
const EMBrowseChannels = React.lazy(() => import("./views/UserPlatform/Channels/BrowseChannels/index"))
const ActivityFeed = React.lazy(() => import("./views/UserPlatform/Channels/MyChannels/ActivityFeed/index"))
const ChannelMembers = React.lazy(() => import("./views/UserPlatform/Channels/MyChannels/Members/index"))
const ChannelSettings = React.lazy(() => import("./views/UserPlatform/Channels/MyChannels/Settings/index"))
const EMNetworkDetail = React.lazy(() => import("./views/UserPlatform/Network/NetworkDetails/index"))
const EMMyNetworks = React.lazy(() => import("./views/UserPlatform/Network/index"))
const EMCalender = React.lazy(() => import("./views/UserPlatform/Events/Calender/index"))
const EMEvents = React.lazy(() => import("./views/UserPlatform/Events/EventsImGoing/index"))
const EMSearchEvents = React.lazy(() => import("./views/UserPlatform/Events/SearchEvents/index"))
const EMMyNotifications = React.lazy(() => import("./views/UserPlatform/Notifications/index"))
const EMSearchAll = React.lazy(() => import("./views/UserPlatform/GlobalSearch/EMSearchAll"))
const EMSearchChannel = React.lazy(() => import("./views/UserPlatform/GlobalSearch/EMSearchChannel"))
const EMSearchEvent = React.lazy(() => import("./views/UserPlatform/GlobalSearch/EMSearchEvent"))
const EMSearchNetwork = React.lazy(() => import("./views/UserPlatform/GlobalSearch/EMSearchNetwork"))
const EMSearchGroup = React.lazy(() => import("./views/UserPlatform/GlobalSearch/EMSearchGroup"))
const EMSearchPost = React.lazy(() => import("./views/UserPlatform/GlobalSearch/EMSearchPost"))
const EMOrganizationDetails = React.lazy(() => import("./views/UserPlatform/Network/EMOrganizationDetails"))
const EMHashTags = React.lazy(() => import("./views/UserPlatform/Hashtags"))
const EMTaggedPostOrEvent = React.lazy(() => import("./views/UserPlatform/TaggedPostOrEvent"))
const Mypoll = React.lazy(() => import("./views/UserPlatform/Poll/EMPoll/index"))
const OpenEnded = React.lazy(() => import("./views/UserPlatform/Poll/EMOpenEnded/index"))
const MyQuiz =  React.lazy(() => import("./views/UserPlatform/Quiz/index"))

const routes = (userData) => {
  /**
   * After user log in check if the user is platform admin,
   * organization admin or network admin
   * according to the flag redirect user to the proper route
   */
  let loginUserData =
  userData && userData.is_staff != undefined ? userData : userData.userData;

  const getRedirectionRoute = () => {
    if (loginUserData && loginUserData.is_staff) {
      return <Navigate to="admin/dashboard" />;
    } else if (loginUserData && loginUserData.is_active) {
      return <Navigate to="/users/home" />;
    }
    // if (userData.is_staff) {
    //   return <Navigate to="admin/users" />;
    // } else if (userData.can_view_network_admin_pages || userData.can_view_organization_admin_pages) {
    //   return <Navigate to="networkAdmin/users" />;
    // } else if (userData.can_view_group_admin_pages) {
    //   return <Navigate to="groupAdmin/users" />;
    // }
    // else if (userData.can_view_group_admin_pages) {
    //   return <Navigate to="groupAdmin/users" />;
    // }else {
    //   return <Navigate to="users" />;
    // }
  };

  let defaultRouteForNetworkAdmin;
  if (loginUserData) {
    if (
      loginUserData.boolean_partnership_manage_users ||
      loginUserData.boolean_organization_manage_users ||
      loginUserData.boolean_organization_manage_roles_and_permissions ||
      loginUserData.boolean_partnership_manage_roles_and_permissions
    ) {
      defaultRouteForNetworkAdmin = "/networkAdmin/users";
    } else if (
      loginUserData.boolean_partnership_manage_email_templates ||
      loginUserData.boolean_partnership_manage_profile_page ||
      loginUserData.boolean_organization_manage_email_templates ||
      loginUserData.boolean_organization_manage_profile_page
    ) {
      defaultRouteForNetworkAdmin = "/networkAdmin/networkmanagement";
    } else if (
      loginUserData.boolean_organization_groups ||
      loginUserData.boolean_partnership_groups
    ) {
      defaultRouteForNetworkAdmin = "/networkAdmin/groupmanagement";
    } else if (
      loginUserData.boolean_organization_channel ||
      loginUserData.boolean_partnership_channel
    ) {
      defaultRouteForNetworkAdmin = "/networkAdmin/channelmanagement";
    }
  }
  let defaultRouteForGroupAdmin;
  if (loginUserData) {
    if (
      loginUserData.boolean_group_manage_users ||
      loginUserData.boolean_group_manage_roles_and_permissions
    ) {
      defaultRouteForGroupAdmin = "/groupAdmin/users";
    } else if (loginUserData.boolean_group_manage_profile_page) {
      defaultRouteForGroupAdmin = "/groupAdmin/groupmanagement";
    } else if (loginUserData.boolean_group_manage_channels) {
      defaultRouteForGroupAdmin = "/groupAdmin/channelmanagement";
    }
  }
  let defaultRouteForChannelAdmin;
  if (loginUserData) {
    if (
      loginUserData.boolean_channel_manage_users ||
      loginUserData.boolean_channel_manage_roles_and_permissions
    ) {
      defaultRouteForChannelAdmin = "/channelAdmin/users";
    } else if (loginUserData.boolean_channel_manage_profile_page) {
      defaultRouteForChannelAdmin = "/channelAdmin/channelmanagement";
    }
  }

  const routes = [
    loginUserData &&
      loginUserData.access &&
      loginUserData.is_staff && {
        path: "admin",
        element:
          userData && loginUserData.access ? (
            <EMAdminLayout />
          ) : (
            <Navigate to="/login" />
          ),
        children: [
          {
            path: "dashboard",
            element: <PlatformDashboard />,
          },
          {
            path: "users",
            element: <PlatformAdminManagement />,
          },

          {
            path: "organizations",
            element: <PlatformOrganizationManagement />,
          },
          {
            path: "partnership",
            element: <PlatformPartnershipManagement />,
          },
          {
            path: "reports",
            element: <PlatformReportsManagement />,
          },
        ],
      },
    loginUserData &&
      loginUserData.access &&
      //role Based Permission
      (loginUserData.permission_for_organization ||
        loginUserData.permission_for_partnership ||
        loginUserData.is_network_admin ||
        //Permission Specific organisation
        loginUserData.boolean_organization_manage_users ||
        loginUserData.boolean_organization_manage_roles_and_permissions ||
        loginUserData.boolean_organization_manage_profile_page ||
        loginUserData.boolean_organization_manage_email_templates ||
        loginUserData.boolean_organization_groups ||
        loginUserData.boolean_organization_channel ||
        //Permission Specific partnership
        loginUserData.boolean_partnership_manage_users ||
        loginUserData.boolean_partnership_manage_roles_and_permissions ||
        loginUserData.boolean_partnership_manage_profile_page ||
        loginUserData.boolean_partnership_manage_email_templates ||
        loginUserData.boolean_partnership_groups ||
        loginUserData.boolean_partnership_channel) && {
        path: "networkAdmin",
        element:
          loginUserData && loginUserData.access ? (
            <EMAdminLayout path="/networkAdmin" />
          ) : (
            <Navigate to="/login" />
          ),
        children: [

        
          {
            path: "networkAdmin",
            element: <Navigate to={defaultRouteForNetworkAdmin} />,
          },
          {
            path: "dashboard",
            element: < NetworkAdminDashboard />,
          },
          {
            path: "users",
            element: <EMUserManagement />,
          },
          {
            path: "manageRolesAndPermissions",
            element: <NetworkAdminManagement />,
          },
          {
            path: "manageUsers",
            element: <EMNetworkAdminManageUsers />,
          },
          {
            path: "channelmanagement",
            element: <EMChannelManagement />,
          },
          {
            path: "groupmanagement",
            element: <EMGroupManagement />,
          },
          {
            path: "networkmanagement",
            element: <EMOrganizationManagement />,
          },
          {
            path: "reports",
            element: <NetworkAdminReports />,
          },
          {
            path: "gamification",
            element: <EMGamificationManagement />,
          },
        ],
      },
    loginUserData &&
      loginUserData.access &&
      (loginUserData.permission_for_organization ||
        loginUserData.permission_for_partnership ||
        loginUserData.permission_for_group ||
        loginUserData.is_group_admin ||
        //Permission Specific To Groups
        loginUserData.boolean_group_manage_users ||
        loginUserData.boolean_group_manage_roles_and_permissions ||
        loginUserData.boolean_group_manage_profile_page ||
        loginUserData.boolean_group_manage_joining_requests ||
        loginUserData.boolean_group_manage_channels) && {
        path: "groupAdmin",
        element:
          loginUserData && loginUserData.access ? (
            <EMAdminLayout path="/groupAdmin" />
          ) : (
            <Navigate to="/login" />
          ),
        children: [
          {
            path: "dashboard",
            element: < GroupAdminDashboard />,
          },
          {
            path: "groupAdmin",
            element: <Navigate to={defaultRouteForGroupAdmin} />,
          },
          
          {
            path: "users",
            element: <EMUserManagementForGroup />,
          },
          {
            path: "groupmanagement",
            element: <EMGroupManagementForGroup />,
          },
          {
            path: "channelmanagement",
            element: <EMChannelManagementForGroup />,
          },
          {
            path: "reports",
            element: < GroupAdminReports />,
          },
          {
            path: "gamification",
            element: <GroupAdminGamification />,
          },
        ],
      },
    loginUserData &&
      loginUserData.access &&
      (loginUserData.permission_for_channel ||
        loginUserData.is_channel_admin ||
        //Permission Specific to Channel
        loginUserData.boolean_channel_manage_users ||
        loginUserData.boolean_channel_manage_roles_and_permissions ||
        loginUserData.boolean_channel_manage_profile_page ||
        loginUserData.boolean_channel_manage_joining_requests) && {
        path: "channelAdmin",
        element:
          loginUserData && loginUserData.access ? (
            <EMAdminLayout path="/channelAdmin" />
          ) : (
            <Navigate to="/login" />
          ),
        children: [
          {
            path: "channelAdmin",
            element: <Navigate to={defaultRouteForChannelAdmin} />,
          },
          {
            path: "dashboard",
            element: < ChannelAdminDashboard />,
          },
          {
            path: "users",
            element: <EMUserManagementForChannel />,
          },
          {
            path: "channelmanagement",
            element: <EMChannelManagementForChannel />,
          },
          {
            path: "reports",
            element: <ChannelAdminReports />,
          },
          {
            path: "gamification",
            element: <ChannelAdminGamification />,
          },
        ],
      },
    loginUserData &&
      loginUserData.access && {
        path: "users",
        element:
          loginUserData && loginUserData.access ? (
            <EMUserLayout path="/users" />
          ) : (
            <Navigate to="/login" />
          ),
        children: [
          {
            path: "users",
            element: <Navigate to="/users/home" />,
          },
          {
            path: "home",
            element: <EMLatestPost />,
          },
          {
            path: "home/latestpost",
            element: <EMLatestPost />,
          },
          {
            path: "home/pinnedpost",
            element:<EMPinnedPost /> ,
          },
          {
            path: "groups",
            element: <EMMyGroups />,
          },
          {
            path: "groups/mygroups",
            element: <EMMyGroups />,
          },
          {
            path: "groups/activity",
            element: <EMMyGroupsActivity />,
          },
          {
            path: "groups/members",
            element: <EMGroupMembersPage />,
          },
          {
            path: "groups/settings",
            element: <EMGroupSettingsPage />,
          },
          {
            path: "groups/browsegroups",
            element: <EMBrowseGroups />,
          },
          {
            path: "groups/browsegroups/activity",
            element: <EMMyGroupsActivity />,
          },
          {
            path: "groups/browsegroups/members",
            element: <EMGroupMembersPage />,
          },
          {
            path: "channels",
            element: <EMMyChannels />,
          },
          {
            path: "channels/mychannels",
            element: <EMMyChannels />,
          },
          {
            path: "channels/activity",
            element: <ActivityFeed />,
          },
          {
            path: "channels/members",
            element: <ChannelMembers style={{ backgroundColor: "black" }} />,
          },
          {
            path: "channels/settings",
            element: <ChannelSettings style={{ backgroundColor: "black" }} />,
          },
          {
            path: "channels/browsechannels",
            element: <EMBrowseChannels />,
          },
          {
            path: "channels/browsechannels/activity",
            element: <ActivityFeed />,
          },
          {
            path: "channels/browsechannels/members",
            element: <ChannelMembers />,
          },
          {
            path: "events",
            element: <EMCalender />,
          },
          {
            path: "events/calendar",
            element: <EMCalender />,
          },
          {
            path: "events/yourevents",
            element: <EMEvents />,
          },
          {
            path: "events/searchevents",
            element: <EMSearchEvents />,
          },
          {
            path: "networks",
            element: <EMMyNetworks />,
          },
          {
            path: "networks/organization",
            element: <EMOrganizationDetails />,
          },
          {
            path: "networks/details",
            element: <EMNetworkDetail />,
          },
          {
            path: "people",
            element: <EMMyCompany />,
          },
          {
            path: "people/:id",
            element: <EMProfileDetails />,
          },

          {
            path: "people/mycompany",
            element: <EMMyCompany />,
          },
          {
            path: "people/searchothers",
            element: <EMSearchOthers />,
          },
          {
            path: "quiz",
            element: <MyQuiz />,
          },
          {
            path: "notifications",
            element: <EMMyNotifications />,
          },
          {
            path: "search",
            element: <EMSearchAll />,
          },
          {
            path: "search/posts",
            element: <EMSearchPost />,
          },
          {
            path: "search/groups",
            element: <EMSearchGroup />,
          },
          {
            path: "search/channels",
            element: <EMSearchChannel />,
          },
          {
            path: "search/events",
            element: <EMSearchEvent />,
          },
          {
            path: "search/networks",
            element: <EMSearchNetwork />,
          },
          {
            path: "search/polls",
            element: <EMSearchPoll />,
          },
          {
            path: "search/quizzes",
            element: <EMSearchQuiz />,
          },
          {
            path: "hashtags/:id",
            element: <EMHashTags />,
          },
          {
            path: "taggedpost/:id",
            element: <EMTaggedPostOrEvent />,
          },
          {
            path:"poll",
            element:< Mypoll/>,

          },
          {
            path:"poll/mypoll",
            element:< Mypoll/>,

          },
          {
            path:"poll/openended",
            element:<OpenEnded/>
          }
        ],
      },
    {
      path: "/",
      element:
        loginUserData && loginUserData.access == "" ? (
          <Navigate to="/login" />
        ) : (
          getRedirectionRoute()
        ),
      children: [
        { path: "/login", element: <EMLoginView /> },
        { path: "/", element: <Navigate to="/login" /> },
        { path: "/signup/:key", element: <EMSignUpPage editMode={false} /> },
        {
          path: "/signup-platform/:key",
          element: <EMSignupPageForPlatformAdmin />,
        },
        { path: "/forgot-password", element: <EMForgotPasswordPage /> },
        { path: "/reset-password/:key", element: <EMResetPasswordPage /> },
        { path: "/terms&conditions", element: <EMTermsAndConditions /> },
      ],
    },
    {
      path: "*",
      element:
        loginUserData ? (
          <EMNotFoundView />
        ) : (
          <Navigate to="/login" />
        ),
    },
  ];

  return routes.filter((r) => r != undefined && r !== "");
};

export default routes;