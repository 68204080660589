import {
  Paper,
  makeStyles,
  Grid,
  Button,
  Tooltip,
  Typography,
  IconButton,
  Box,
  Modal,
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EMSimpleDatatable from "../../../components/datatables/EMSimpleDatatable";
import EMSearchView from "../../../components/navigation/EMSearchView";
import {
  EMDoDeleteActionsForOrganizationAction,
  EMDoGetActionsForOrganizationAction,
} from "../../../redux/actions/UserPlatform/Network/EMMyNetworkAction";
import EMAddEditPoints from "./EMAddEditPoints";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "3%",
    marginTop: "2%",
  },
  typo:{
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 1,
    wordBreak:"break-word",
    overflow: "hidden",
  },
}));

export default function EMPointsTable({orgName}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const authData = useSelector((state) => state.auth || {});
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [editData, setEditData] = React.useState({});
  const [entity, setEntity] = React.useState(-1);
  const [labels, setLabels] = React.useState({
    titleLable: "",
    buttonLabel: "",
  });
  const [edit, setEdit] = React.useState(false);
  const [saveClicked, setDisabled] = React.useState(false);
  const [limit, setLimit] = useState(15);
  const [offset, setOffset] = useState(0);
  const [order, setOrder] = useState({ id: -1, direction: "asc" });
  const [sortAscending, setSortAscending] = useState(false);
  const data = useSelector((state) => state.myNetwork || {});

  const updateData = (query) => {
    if (query.indexOf("limit") >= 0) {
      dispatch(
        EMDoGetActionsForOrganizationAction({
          queryData: query,
        })
      );
    }
  };
  useEffect(() => {
    updateData("?");
  }, []);

  useEffect(() => {
    const query = [];
    if (limit !== 0) {
      query.push(`limit=${limit}`);
    }
    if (search.length > 0) {
      query.push(`search=${search}`);
    }
    if (offset !== 0) {
      query.push(`offset=${offset}`);
    }
    if (order.id !== -1) {
      if (sortAscending === false) {
        query.push(
          `ordering=${order.direction === "desc" ? "-" : ""}${
            columns[order.id - 1].field
          }`
        );
        setSortAscending(true);
      } else {
        // order.direction = "desc"
        query.push(
          `ordering=${order.direction === "desc" ? "-" : ""}${
            columns[order.id - 1].field
          }`
        );
        setSortAscending(false);
      }
    }
    updateData("?" + query.join("&"));
  }, [limit, offset, order, entity, search]);

  const handleClose = () => {
    setOpen(false);
    setEditData({});
  };

  const columns = [
    {
      title: "Action Name",
      field: "action_name",
      align: "left",
      render: (data) => {
        return (
          <>
            <Tooltip
              title={
                <h2 style={{ color: "white" }}>
                  {data["actions"] &&
                    data["actions"]["action_name"] &&
                    data["actions"]["action_name"]}
                </h2>
              }
              placement="top"
            >
              <Typography className={classes.typo}>
                {data["actions"] &&
                data["actions"]["action_name"] &&
                 data["actions"]["action_name"]}
              </Typography>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Z - Points",
      field: "custom_points_for_action",
      render: (data) => {
        return (
          <>
            <Typography style={{ fontSize: "medium", fontWeight: "bolder" }}>
              {data.custom_points_for_action}
            </Typography>
          </>
        );
      },
      align: "left",
    },

    {
      title: "Edit",
      align: "left",
      sorting: false,
      render: (data) => {
        return (
          <>
            <IconButton
              disabled={!authData.is_network_admin}
              onClick={() => {
                setOpen(true);
                setLabels({
                  titleLable: "Edit Action",
                  buttonLabel: "Save",
                });
                setEdit(true);
                setEditData(data);
                setDisabled(false);
              }}
            >
              <Edit />
            </IconButton>
          </>
        );
      },
    },
    {
      title: "Delete",
      align: "left",
      sorting: false,
      render: (data) => {
        return (
          <>
            <IconButton
              disabled={!authData.is_network_admin}
              onClick={() => {
                let d = data;
                dispatch(
                  EMDoDeleteActionsForOrganizationAction({ actionId: d.id })
                );
              }}
            >
              <Delete />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <Paper elevation={2} className={classes.root}>
      <Typography variant="h6" style={{paddingBottom:"2%", fontWeight:"bolder",}}>
        {`${orgName}'s Actions`}
      </Typography>
      <Grid
        container
        direction="row"
        spacing={2}
        style={{ marginBottom: "2%" }}
      >
        <Grid
          item
          xs={6}
          container
          style={{ display: "flex", alignItems: "center", width: "100%" }}
        >
          <EMSearchView
            data={search}
            // usedIn="browse_groups"
            route={true}
            hint={"Search Action"}
            onChangeListener={(data) => {
              setSearch(data);
            }}
          />
        </Grid>
        <Grid
          item
          xs={6}
          container
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            disabled={!authData.is_network_admin}
            onClick={() => {
              setOpen(true);
              setLabels({
                titleLable: "Add New Action",
                buttonLabel: "Save",
              });
              setEdit(false);
              setEditData({});
              setDisabled(false);
            }}
            variant="contained"
            color="primary"
          >
            + Add Action
          </Button>
        </Grid>
      </Grid>
      <EMAddEditPoints
        open={open}
        close={handleClose}
        actionData={editData}
        entity={entity}
        titleLable={labels.titleLable}
        buttonLabel={labels.buttonLabel}
        isEdit={edit}
        setOpenFalse={setOpen}
        saveClicked={saveClicked}
        setDisabled={setDisabled}
        setEdit={setEdit}
      />
      <Box>
        {
          <EMSimpleDatatable
          filter={search}
            columns={columns}
            data={data.organizationActions.results || []}
            count={0}
            updatePageAndPageSizeAndOrder={(page, pageSize, order) => {
              setOffset(page * limit);
              setLimit(pageSize);
              setOrder(order);
            }}
            headerStyleColor={true}
            options={{
              headerStyle: {
                backgroundColor: "#2F92D6",
                color: theme.palette.common.white,
                fontWeight: "bold",
              },
            }}
          />
        }
      </Box>
    </Paper>
  );
}
