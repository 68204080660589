import {
  Paper,
  Box,
  Button,
  makeStyles,
  Modal,
  CircularProgress,
  Typography,
  Hidden,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import EMPage from "../../../components/commans/EMPage";
import EMDatatableSearchView from "../../../components/datatables/EMDatatableSearchView";
import EMOrganizationTable from "./EMOrganizationTable";
import EMAddEditOrganization from "./EMAddEditOrganization";
import { useSelector, useDispatch } from "react-redux";
import { find, findLast } from "lodash";
import {
  EMDoExportOrganizationsAction,
  EMDoGetOrganizationSubTypesAction,
} from "../../../redux/actions/EMAdminActions";
import { PLATFORM_ADMIN_UPDATE_ORGANIZATION_LIST } from "../../../redux/constants";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: 10,
    minWidth: 200,
    height: 40,
  },
}));

export default function EMPlatformOrganizationManagement(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState("");
  const [open, setOpen] = React.useState(false);
  const [editData, setEditData] = React.useState({});
  const [showLabels, setShowLabels] = React.useState({
    label: "",
    buttonLabel: "",
  });
  const [saveClicked, setDisabled] = React.useState(false);
  const tableData = useSelector((state) => state.admin.organizationList || {});
  /* open and close modal functionality */
  const handleOpen = () => {
    dispatch({ type: PLATFORM_ADMIN_UPDATE_ORGANIZATION_LIST, payload: false });
    setOpen(true);
    setEditData({});
  };

  const handleClose = () => {
    setOpen(false);
    setEditData({});
  };

  const uiData = useSelector((state) => state.ui);

  useEffect(() => {
    if (uiData["messages"]) {
      let refObj = findLast(uiData["messages"], {
        key: "organization_add_edit",
      });
      if (refObj && refObj.type === "success") {
        setOpen(false);
        setDisabled(false);
        refObj.key = "organization_add_edit_success";
        dispatch({
          type: PLATFORM_ADMIN_UPDATE_ORGANIZATION_LIST,
          payload: true,
        });
      } else if (refObj && refObj.type === "error") {
        setDisabled(false);
      }
    }
  }, [uiData]);

  return (
    <EMPage title="Organization Management" style={{ padding: "2%" }}>
      <Box>
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={uiData.loading.state}
        >
          <Box
            style={{ display: "flex" }}
            alignItems="center"
            justifyContent="center"
          >
            <Box textAlign="center">
              <CircularProgress />
              <Typography style={{ fontWeight: "medium", fontSize: "large" }}>
                {uiData.loading.message}
              </Typography>
            </Box>
          </Box>
        </Modal>
        <Paper elevation={2}>
          <Hidden mdDown>
            <Box height="100%" display="flex" flexDirection="column">
              <Box
                width="100%"
                display="flex"
                flexDirection="row"
                p={3}
                justifyItems="center"
                alignItems="center"
              >
                <Box flexGrow={1}>
                  <EMDatatableSearchView
                    hint={"Search by Organization Name"}
                    onChangeListener={(data) => {
                      setFilter(data);
                    }}
                  />
                </Box>

                <Box>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (tableData) {
                        let data = {
                          ...tableData,
                        };
                        dispatch(
                          EMDoExportOrganizationsAction({
                            data,
                            key: "organization_export",
                          })
                        );
                      }
                    }}
                  >
                    {"Export Organizations"}
                  </Button>
                </Box>

                <Box>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      handleOpen();
                      setShowLabels({
                        label: "Add an Organization",
                        buttonLabel: "Save",
                      });
                      setDisabled(false);
                    }}
                  >
                    {"Add new Organization"}
                  </Button>
                </Box>

                <Box>
                  <EMAddEditOrganization
                    open={open}
                    close={handleClose}
                    organizationData={editData}
                    pagelabel={showLabels.label}
                    buttonLabel={showLabels.buttonLabel}
                    saveClicked={saveClicked}
                    setDisabled={setDisabled}
                  />
                </Box>
              </Box>
              <EMOrganizationTable
                filter={filter}
                onEdit={(organizationData) => {
                  setEditData(organizationData);
                  setOpen(true);
                  setShowLabels({
                    label: "Edit Organization Details",
                    buttonLabel: "Save",
                  });
                  dispatch({type:PLATFORM_ADMIN_UPDATE_ORGANIZATION_LIST, payload:false})
                }}
              />
            </Box>
          </Hidden>
          <Hidden lgUp>
            <Box height="100%" display="flex" flexDirection="column">
              <Box
                width="100%"
                display="flex"
                flexDirection="row"
                p={3}
                justifyItems="center"
                alignItems="center"
              >
                <Box flexGrow={1}>
                  <EMDatatableSearchView
                    hint={"Search by Organization Name"}
                    onChangeListener={(data) => {
                      setFilter(data);
                    }}
                  />
                </Box>
              </Box>
              <Box>
                <Button
                  className={classes.button}
                  style={{ marginLeft: "50px", textAlign: "center" }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (tableData) {
                      let data = {
                        ...tableData,
                      };
                      dispatch(
                        EMDoExportOrganizationsAction({
                          data,
                          key: "organization_export",
                        })
                      );
                    }
                  }}
                >
                  {"Export Organizations"}
                </Button>

                <Button
                  className={classes.button}
                  style={{ marginLeft: "50px", textAlign: "center" }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleOpen();
                    setShowLabels({
                      label: "Add an Organization",
                      buttonLabel: "Save",
                    });
                    setDisabled(false);
                  }}
                >
                  {"Add new Organization"}
                </Button>
              </Box>
              <Box>
                <EMAddEditOrganization
                  open={open}
                  close={handleClose}
                  organizationData={editData}
                  pagelabel={showLabels.label}
                  buttonLabel={showLabels.buttonLabel}
                  saveClicked={saveClicked}
                  setDisabled={setDisabled}
                />
              </Box>
              <EMOrganizationTable
                filter={filter}
                onEdit={(organizationData) => {
                  setEditData(organizationData);
                  setOpen(true);
                  setShowLabels({
                    label: "Edit Organization Details",
                    buttonLabel: "Save",
                  });
                  dispatch({type:PLATFORM_ADMIN_UPDATE_ORGANIZATION_LIST, payload:false})
                }}
              />
            </Box>
          </Hidden>
        </Paper>
      </Box>
    </EMPage>
  );
}
