import { Box, IconButton,Tooltip,Typography } from "@material-ui/core";
import EMSimpleDatatable from "../../../components/datatables/EMSimpleDatatable";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import {
    EMDoUpdateChannelInGroupAdminAction,
    EMDoGetAllChannelListDetailsAction
} from "../../../redux/actions/EMGroupAdminActions";
import { EMAdminRemoveChannelUserAction} from "../../../redux/actions/EMChannelAdminActions"
import { useEffect, useState } from "react";
import EMSwitch from "../../../components/commans/EMSwitch";
import { Edit, Settings } from "@material-ui/icons";
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    typo: {
      display: "-webkit-box",
      boxOrient: "vertical",
      lineClamp: 1,
      wordBreak: "break-word",
      overflow: "hidden",
    },
  }));

export default function EMChannelTable({ filter, onEdit, onSettings }) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const data = useSelector(
        state => state.groupAdmin.allChannelListingDetails || {}
    );
    const [limit, setLimit] = useState(15);
    const [offset, setOffset] = useState(0);
    const [order, setOrder] = useState({ id: -1, direction: "asc" });
    const [sortAscending, setSortAscending] = useState(false);
    const userData = useSelector(state=>state.auth)
    const shouldUpdate = useSelector((state) => state.groupAdmin.updateGroupsList || false);
    const classes = useStyles()

    useEffect(() => {
        if (filter && filter.length > 0) {
          setOffset(0);
        } else if (filter && filter == "") {
          setOffset(offset);
        }
      }, [filter]);

    const columns = [
        {
            title: "Channel Name",
            field: "channel_name",
            align:"left",
            render: data=> {
                return (
                  <>
                  <Tooltip title={<h2 style={{ color: "white" }}>{data["channel_name"]}</h2>} placement='top'>
                  <Typography className={classes.typo}>{data["channel_name"] && data["channel_name"] }</Typography>
                  </Tooltip>
                  </>
                )
              }
        },
        {
            title: "Group Name",
            field: "group_id__group_name",
            align:"left",
            render: data=> {
                let grp_name = data["grp_name"];
                return(
                    <>
                    <Tooltip title={<h2 style={{ color: "white" }}>{grp_name}</h2>} placement="top">
                  <Typography className={classes.typo}>{grp_name && grp_name}</Typography>
                  </Tooltip>
                  </>
                )
              }
        },
        {
            title: "Admin Emails ",
            field: "channeluser_channel_id__user__email",
            align:"left",
            sorting: false,
            render: data => {
                let emails = data["users"]
                    .map(user => user["email"]).join(", ");
                    return (
                        <>
                        <Tooltip title={<h2 style={{ color: "white" }}>{emails}</h2>} placement='top'>
                        <Typography className={classes.typo}>{emails && emails }</Typography>
                        </Tooltip>
                        </>
                      )
                    }
        },

{
    title: "Status",
        align:"left",
        field: "is_active",
            customSort: (a, b) => a.is_active - b.is_active,
                render: eventData => {
                    return (
                        <EMSwitch
                            status={eventData.is_active}
                            isDisabled={!(userData.boolean_group_manage_channels)}
                            dialogText={"Are you sure that you want to activate/deactivate the channel?"}
                            onChange={status => {
                                
                                let data = { ...eventData };
                                let admin_emails = []
                                admin_emails = data["users"]
                                    .map(user => user["email"]);
                                data.is_active = status;
                                data.admin_emails = admin_emails
                                delete data.tableData;
                                dispatch(EMDoUpdateChannelInGroupAdminAction({data}));
                              }}

                        />
                    );
                }
},
{
    title: "Created By",
        align:"left",
            field: "created_by",
            sorting: true,
            render: data=>{
                return (
                    <>
                    <Tooltip title={<h2 style={{ color: "white" }}>{data["created_by"]}</h2>} placement='top'>
                    <Typography className={classes.typo}>{data["created_by"] && data["created_by"] }</Typography>
                    </Tooltip>
                    </>
                  )
                }
},
{
    title: "Privacy",
    align:"left",
    field: "privacy_type",
    render: data=>{
      return <Typography>{data["privacy_name"]}</Typography>
      }
},
{
    title: "Created on",
        field: "created_on",
            align:"left",
            sorting: true,
            render: data=> {
                let date = data["created_on"];
                date = new Date(date)
                date =  moment(date).format('MM/DD/YYYY');
                return(
                  <Typography>{data["created_on"]? date : ""}</Typography>
                )
              }
},
{
    title: "Last Active",
        field: "last_active",
        sorting: true,
            align:"left",
            render: data=> {
                let date = data["last_active"];
                date = new Date(date)
                date =  moment(date).format('MM/DD/YYYY');
                return(
                  <Typography>{data["last_active"]? date : ""}</Typography>
                )
              }
},
{
    title: "Action",
        align:"left",
        sorting: false,
            render: data => {
                return (
                    <>
                        <IconButton onClick={() => onSettings(data)}>
                            <Settings />
                        </IconButton>
                    </>
                );
            }
}
    ];


const updateData = query => {
    dispatch(EMDoGetAllChannelListDetailsAction(query));
};

useEffect(() => {
    updateData("?");
}, []);

useEffect(() => {
    const query = [];
    if (limit !== 0) {
    query.push(`limit=${limit}`);
    }
    if (filter) {
    query.push(`search=${filter}`);
    }
    if (offset !== 0) {
    query.push(`offset=${offset}`);
    }
    if (order.id !== -1) {
        if(sortAscending === false){
            order.direction = order.direction == "asc" ? "asc" : "asc"
          query.push(
            `ordering=${order.direction === "desc" ? "-" : ""}${
              columns[order.id-1].field
            }`
          );
          setSortAscending(true)
        }else{
          order.direction = "desc"
          query.push(
            `ordering=${order.direction === "desc" ? "-" : ""}${
              columns[order.id-1].field
            }`
          );
          setSortAscending(false)
        }
      }
    updateData("?" + query.join("&"));
}, [limit, filter, offset, order, shouldUpdate]);

return (
    <Box p={2}>
        {
            <EMSimpleDatatable
                columns={columns}
                filter={filter}
                data={data["results"]||[]}
                count={data["count"] || 0}
                updatePageAndPageSizeAndOrder={(page, pageSize, order) => {
                    setOffset(page * limit);
                    setLimit(pageSize);
                    setOrder(order)
                }}
                options={{
                    headerStyle: {
                        backgroundColor: theme.palette.secondary.main,
                        color: theme.palette.common.white,
                        fontWeight: "bold"
                    }
                }}
            />
        }
    </Box>
    );
}
