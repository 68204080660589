import { useState, useEffect } from "react";
import {
  Menu,
  MenuItem,
  Typography,
  Hidden,
  Avatar,
  Grid,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { Badge, Paper, Tooltip } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { EMLogoutAction } from "../../redux/actions/EMAuthActions";
import { useLocation, useNavigate } from "react-router-dom";
// import ArrowDropDownIcon from '@material-ui/icons/ ArrowDropDown';
import EMProfileSwitchDropdown from "./EMProfileSwitchDropdown";
import { CHANGE_USER_TO_ADMIN } from "../../redux/constants/UserPlatform/EMNavigationConstant";
import { AUTH_USER_PROFILE_ID, CHANGE_TAB_INDEX_TO_ZERO } from "../../redux/constants/index";
import { EMDoGetProfileDetailsAction } from "../../redux/actions/UserPlatform/People/EMPeopleActions";
import {
  EMDoSwitchProfileAction,
  EMDoGetMultipleProfileDetailsAction,
} from "../../redux/actions/EMAuthActions";
import Notification1 from "../../components/user/Notification1";
import Popover from "@material-ui/core/Popover";
import { find } from "lodash";
import { EMDoGetAllInformationAboutUserAction } from "../../redux/actions/EMCommonActions";
import { findLastKey } from "lodash-es";

const style = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  Popover: {
    minWidth: "400px",
  },
  typo: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 2,
    wordBreak: "break-word",
    overflow: "hidden",
  },
}));

export default function UserMenuOptions(props) {
  const cssClasses = style();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [admin, setAdmin] = useState(false);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const location = useLocation();
  const navigationData = useSelector((state) => state.navigation);
  const [userProfileId, setUserProfileId] = useState();
  const userData = useSelector((state) => state.auth);
  const notificationData = useSelector((state) => state.common || {});
  let loginUserData = userData.access != "" ? userData : userData.userData;

  const [anchorElNotification, setAnchorElNotification] = useState(null);
  const [anchorElSwitchProfile, setAnchorElSwitchProfile] = useState(null);
  const userMultipleProfile = useSelector(
    (state) => state.auth.multipleProfiles
  );
  const [organizationLogo, setOrganizationLogo] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const userName = loginUserData.name;

  const matchesLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const matchesSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (userMultipleProfile && userData.organization_id) {
      let selectedProfile = userMultipleProfile.find(
        (org) => org.organization_id == userData.organization_id
      );
      if (selectedProfile) {
        setOrganizationLogo(selectedProfile.organization_logo_file);
        setOrganizationName(selectedProfile.organization_name);
      }
    }
  }, [userMultipleProfile]);

  useEffect(() => {
    if (userProfileId != undefined) {
      let action = {
        userid: userProfileId,
        key: "profile_switch",
      };
      dispatch(EMDoSwitchProfileAction(action));
      // if (window.location.href.includes("people"))
      navigation("/users/home", { state: {} });
    }
  }, [userProfileId]);
  const uiData = useSelector((state) => state.ui);
  useEffect(() => {
    if (uiData["messages"]) {
      let refObj = find(uiData["messages"], { key: "profile_switch" });
      if (refObj && refObj.type === "success") {
        refObj.key = "profile_switch_success";
        dispatch(EMDoGetMultipleProfileDetailsAction());
        
        window.location.reload(true);
        
      }
    }
  }, [uiData]);

  useEffect(() => {
    dispatch(EMDoGetMultipleProfileDetailsAction());
  }, []);

  const handleClick = (event) => {
    setAnchorElNotification(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElNotification(null);
  };

  const handleClickSwitchProfile = (event) => {
    setAnchorElSwitchProfile(event.currentTarget);
  };

  const openNotification = Boolean(anchorElNotification);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Hidden smDown>
        <Grid container alignItems="center" justifyContent="center">
          <Grid xs={2}>
            {(location.pathname.includes("users/") == true) ? (
              <IconButton
                onClick={handleClick}
                edge="end"
                color="inherit"
                aria-label="user"
              >
                <Badge
                  badgeContent={
                    notificationData.notificationCount
                  }
                  color="error"
                >
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            ) : null}
          </Grid>
          <Popover
            id={id}
            PaperProps={{
              style: { width: "30%" },
            }}
            // className={cssClasses.Popover}
            open={openNotification}
            anchorEl={anchorElNotification}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Notification1
              handleclose={handleClose}
              notificationData={
                notificationData.notifications &&
                notificationData.notifications.slice(0, 3)
              }
              noOfNotifications={
                notificationData.notificationCount
              }
            />
          </Popover>
          <Grid xs={3}>
            <Tooltip
              title={<h2 style={{ color: "white" }}>{loginUserData.name}</h2>}
              placement="top"
            >
              <Typography className={cssClasses.typo}>Hi {userName}</Typography>
            </Tooltip>
          </Grid>

          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorEl)}
            onClose={() => {
              setAnchorEl(null);
            }}
          >
            {loginUserData.is_staff ? (
              //if staff
              <MenuItem
                onClick={() => {
                  dispatch(EMLogoutAction());
                  navigation("/");
                }}
              >
                Logout
              </MenuItem>
            ) : (
              //if user
              <>
                {navigationData.isUserAdmin ? null : (
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      navigation(`/users/people/${loginUserData.profile_id}`, {
                        state: {
                          name: loginUserData.name,
                          id: loginUserData.profile_id,
                        },
                      });
                      dispatch(
                        EMDoGetAllInformationAboutUserAction({
                          id: loginUserData.profile_id,
                        })
                      );
                    }}
                  >
                    View Profile
                  </MenuItem>
                )}
                {loginUserData.permission_for_partnership ||
                loginUserData.is_network_admin ||
                loginUserData.is_group_admin ||
                loginUserData.is_channel_admin ||
                loginUserData.permission_for_organization ||
                loginUserData.permission_for_group ||
                loginUserData.permission_for_channel ? (
                  <MenuItem
                    onClick={() => {
                      if (navigationData.isUserAdmin) {
                        dispatch({
                          type: CHANGE_USER_TO_ADMIN,
                          payload: false,
                        });
                        navigation("/users/home");
                      } else {
                        dispatch({ type: CHANGE_USER_TO_ADMIN, payload: true });
                        dispatch({type:CHANGE_TAB_INDEX_TO_ZERO, payload : 0})
                        if (
                          loginUserData.permission_for_organization ||
                          loginUserData.permission_for_partnership ||
                          loginUserData.is_network_admin
                        ) {
                          navigation("/networkAdmin/users");
                        } else if (
                          loginUserData.permission_for_group ||
                          loginUserData.is_group_admin
                        ) {
                          navigation("/groupAdmin/users");
                        } else if (
                          loginUserData.permission_for_channel ||
                          loginUserData.is_channel_admin
                        ) {
                          navigation("/channelAdmin/users");
                        }
                      }
                    }}
                  >
                    {!location.pathname.includes("networkAdmin") &&
                    !location.pathname.includes("groupAdmin") &&
                    !location.pathname.includes("channelAdmin")
                      ? "Switch to Admin Panel"
                      : "Switch to User Platform"}
                  </MenuItem>
                ) : null}
                <MenuItem
                  onClick={() => {
                    dispatch(EMLogoutAction());
                    navigation("/");
                  }}
                >
                  Logout
                </MenuItem>
              </>
            )}
          </Menu>
          <Grid xs={5}>
            {
              loginUserData.is_staff ? null : (
                // navigationData.isUserAdmin  ?

                <div>
                  {navigationData.isUserAdmin === false &&
                  userMultipleProfile &&
                  userMultipleProfile.length > 1 ? (
                    <EMProfileSwitchDropdown
                      name="profile_id"
                      onSelect={(id) => {
                        setUserProfileId(id);
                        dispatch({
                          type: AUTH_USER_PROFILE_ID,
                          payload: id,
                        });
                      }}
                    />
                  ) : (
                    <Grid container alignItems="center" justifyContent="center">
                      <Grid item xs={6}>
                        {organizationLogo && (
                          <div
                            style={{
                              height: "45px",
                              width: "100%",
                              whiteSpace: "nowrap",
                              textAlign: "center",
                            }}
                          >
                            <span
                              style={{
                                display: "inline-block",
                                height: "100%",
                                verticalAlign: "middle",
                              }}
                            ></span>
                            <img
                              style={{ maxHeight: "100%", maxWidth: "100%" }}
                              alt=""
                              src={
                                process.env.REACT_APP_BACKEND_FILE_PREVIEW +
                                organizationLogo
                              }
                            />
                          </div>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <Tooltip
                          placement="top"
                          title={
                            <h2 style={{ color: "white" }}>
                              {organizationName}
                            </h2>
                          }
                        >
                          <Typography
                            className={cssClasses.typo}
                            style={{ fontSize: "medium bold" }}
                          >
                            {organizationName}
                          </Typography>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  )}
                </div>
              )

              /* </Paper> */
              // : null
            }
          </Grid>
          <Grid xs={2}>
            <IconButton
              edge="end"
              className={cssClasses.menuButton}
              color="inherit"
              aria-label="user"
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
              }}
            >
              <Avatar
                src={
                  process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.substr(
                    0,
                    process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.length - 1
                  ) + loginUserData.user_profile
                }
              ></Avatar>
            </IconButton>
          </Grid>
        </Grid>
      </Hidden>

      <Hidden mdUp>
        <Menu
          style={{ padding: 20 }}
          className={cssClasses.menu}
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={() => {
            setAnchorEl(null);
          }}
        >
          {loginUserData.is_staff || navigationData.isUserAdmin == true  ? null : (
            <MenuItem
              onClick={() => {
                setOpen(false);
              }}
            >
              <Badge
                style={{ marginTop: "4%" }}
                onClick={() => {
                  if (matchesSmallScreen) {
                    window.sessionStorage.setItem(
                      "previousPath",
                      location.pathname
                    );
                    navigation("/users/notifications", {
                      state: { path: location.pathname },
                    });
                  } else {
                    handleClick();
                  }
                }}
                badgeContent={
                  notificationData.notifications &&
                  notificationData.notifications.length
                }
                color="error"
              >
                <Typography variant="p">{`Notifications`}</Typography>
              </Badge>
              <Popover
                id={id}
                PaperProps={{
                  style: { width: "30%" },
                }}
                // className={cssClasses.Popover}
                open={openNotification}
                anchorEl={anchorElNotification}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Notification1
                  handleclose={handleClose}
                  notificationData={
                    notificationData.notifications &&
                    notificationData.notifications.slice(0, 3)
                  }
                  noOfNotifications={
                    notificationData.notificationCount
                  }
                />
              </Popover>
            </MenuItem>
          )}
          {loginUserData.is_staff || navigationData.isUserAdmin == true  ? null : (
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                navigation(`/users/people/${loginUserData.profile_id}`, {
                  state: {
                    name: loginUserData.name,
                    id: loginUserData.profile_id,
                  },
                });
                dispatch(
                  EMDoGetAllInformationAboutUserAction({
                    id: loginUserData.profile_id,
                  })
                );
              }}
            >
              View Profile
            </MenuItem>
          )}
          {loginUserData.permission_for_partnership ||
          loginUserData.is_network_admin ||
          loginUserData.is_group_admin ||
          loginUserData.is_channel_admin ||
          loginUserData.permission_for_organization ||
          loginUserData.permission_for_group ||
          loginUserData.permission_for_channel ? (
            <MenuItem
              onClick={() => {
                if (navigationData.isUserAdmin) {
                  dispatch({
                    type: CHANGE_USER_TO_ADMIN,
                    payload: false,
                  });
                  navigation("/users/home");
                } else {
                  dispatch({ type: CHANGE_USER_TO_ADMIN, payload: true });
                  dispatch({type:CHANGE_TAB_INDEX_TO_ZERO, payload : 0})
                  if (
                    loginUserData.permission_for_organization ||
                    loginUserData.permission_for_partnership ||
                    loginUserData.is_network_admin
                  ) {
                    navigation("/networkAdmin/users");
                  } else if (
                    loginUserData.permission_for_group ||
                    loginUserData.is_group_admin
                  ) {
                    navigation("/groupAdmin/users");
                  } else if (
                    loginUserData.permission_for_channel ||
                    loginUserData.is_channel_admin
                  ) {
                    navigation("/groupAdmin/users");
                  }
                }
              }}
            >
              {!location.pathname.includes("networkAdmin") &&
              !location.pathname.includes("groupAdmin") &&
              !location.pathname.includes("channelAdmin")
                ? "Switch to Admin Panel"
                : "Switch to User Platform"}
            </MenuItem>
          ) : null}
          {(userMultipleProfile && userMultipleProfile.length > 1) && (navigationData.isUserAdmin == false)  ? (
            <MenuItem>
              <Typography onClick={handleClickSwitchProfile}>
                Switch Profile
              </Typography>
              <Popover
                PaperProps={{
                  style: { width: "30%" },
                }}
                // className={cssClasses.Popover}
                open={Boolean(anchorElSwitchProfile)}
                anchorEl={anchorElSwitchProfile}
                onClose={() => {
                  setAnchorElSwitchProfile(null);
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <EMProfileSwitchDropdown
                  name="profile_id"
                  onSelect={(id) => {
                    setUserProfileId(id);
                    dispatch({
                      type: AUTH_USER_PROFILE_ID,
                      payload: id,
                    });
                  }}
                />
              </Popover>
            </MenuItem>
          ) : null}
          <MenuItem
            onClick={() => {
              dispatch(EMLogoutAction());
              navigation("/");
            }}
          >
            Logout
          </MenuItem>
        </Menu>
        <IconButton
          edge="end"
          className={cssClasses.menuButton}
          color="inherit"
          aria-label="user"
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          <Avatar
            src={
              process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.substr(
                0,
                process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.length - 1
              ) + loginUserData.user_profile
            }
          ></Avatar>
        </IconButton>
      </Hidden>
    </>
  );
}
