import React, { useState, useEffect, useCallback } from "react";
import EMPage from "../../../../components/commans/EMPage";
import {
  Grid,
  Paper,
  Typography,
  Button,
  GridList,
  GridListTile,
  SvgIcon,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import EMSearchView from "../../../../components/navigation/EMSearchView";
import { useSelector, useDispatch } from "react-redux";
import EMGroupView from "../../../../components/user/EMGroupView";
import EMFilter from "../../../../components/user/EMFilter";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { ReactComponent as CategoryIcon } from "../../../../assets/Icon-awesome-tag.svg";
import { EMDoActionForGroupAction } from "../../../../redux/actions/UserPlatform/Groups/EMGroupsAction";
import { GROUP_INFO_MODAL } from "../../../../redux/constants/UserPlatform/EMGroupConstant";
import { EMDoGetLoggedInUserRolesAction } from "../../../../redux/actions/EMAuthActions";
import { useNavigate } from "react-router-dom";
import {
  EMDoGetChannelDetailsAction,
  EMDoGetListOfAllChannelsAction,
  EMDoGetGroupsForFilterInChannelAction,
  EMDoGetChannelsByGroupFilterAction,
} from "../../../../redux/actions/UserPlatform/Channels/EMChannelsAction";
import {
  CHANNEL_APPLY_GROUP_FILTER,
  CHANNEL_INFO_MODAL,
  DISABLE_ENABLE_FILTER_FOR_BROWSE_CHANNELS,
  DISABLE_ENABLE_SEARCH_BROWSE_CHANNELS,
} from "../../../../redux/constants/UserPlatform/EMChannelConstant";
import { EMDoMarkHighlightedChannelAsSeenAction } from "../../../../redux/actions/UserPlatform/Home/EMLatestPostPageAction";
import EMSideBar from "../../../../components/navigation/EMSideBar";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { LocalOffer } from "@material-ui/icons";
import { ADD_SUBTABS_TO_GROUP } from "../../../../redux/constants/UserPlatform/EMNavigationConstant";
import EMSearch from "../../../../components/navigation/EMSearch";
import InfiniteScroll from "react-infinite-scroll-component";

const useStyles = makeStyles((theme) => ({
  topBar: {
    float: "right",
    display: "flex",
    marginLeft: "59%",
  },
  paper: {
    // paddingLeft: "1%",
    textAlign: "center",
    fontWeight: "bolder",
    color: theme.palette.text.primary,
  },
  formControl: {
    paddingTop: theme.spacing(1.5),
    fontWeight: "bolder",
    fontSize: "large",
    width: "100%",
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 2,
    wordBreak: "break-word",
    overflow: "hidden",
  },
  dialog: {
    width: "400px",
  },
  gridListTile: {
    // margin:"auto",

    marginTop: "4px",
    marginBottom: "2%",
    // marginLeft:"1%",
    // marginBottom:"10px",
    borderRadius: "10px",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);",
  },
  root: {
    marginTop: "2%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    // padding:"10px",
    width: "100%",
    height: "100%",
    "&::-webkit-scrollbar": {
      width: "0.2em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#4A4A4A",
      outline: "1px solid slategrey",
    },
  },
}));
function DeleteIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M11.436,3.375A8.061,8.061,0,1,0,19.5,11.436,8.06,8.06,0,0,0,11.436,3.375Zm2.042,10.979-2.042-2.042L9.394,14.354a.619.619,0,1,1-.876-.876l2.042-2.042L8.518,9.394a.619.619,0,0,1,.876-.876l2.042,2.042,2.042-2.042a.619.619,0,1,1,.876.876l-2.042,2.042,2.042,2.042a.622.622,0,0,1,0,.876A.615.615,0,0,1,13.478,14.354Z" />
    </SvgIcon>
  );
}
function LatestPost(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [search, setSearch] = useState("");
  // const userData = useSelector((state)=> state.auth || {})
  const data = useSelector((state) => state.channels || {});
  const [selectedNetworks, setSelectedNetworks] = React.useState([]);
  const [limit, setLimit] = useState(0);
  const [limitWhileSearching, setLimitWhileSearching] = useState(0);
  const [limitWhileFiltering, setLimitWhileFiltering] = useState(0);

  useEffect(() => {
    dispatch(EMDoGetGroupsForFilterInChannelAction());
    dispatch({ type: ADD_SUBTABS_TO_GROUP });
    dispatch({
      type: DISABLE_ENABLE_SEARCH_BROWSE_CHANNELS,
      payload: true,
    });
    dispatch({
      type: DISABLE_ENABLE_FILTER_FOR_BROWSE_CHANNELS,
      payload: true,
    });
  }, []);

  const theme = useTheme();

  const matchesMediumScreen = useMediaQuery(theme.breakpoints.up("md"));

  const matchesLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  function getCols(screenWidth) {
    if (isWidthUp("xl", screenWidth)) {
      return 3;
    }

    if (isWidthUp("lg", screenWidth)) {
      return 3;
    }
    if (isWidthUp("md", screenWidth)) {
      return 2.2;
    }
    if (isWidthUp("sm", screenWidth)) {
      return 2;
    }
    if (isWidthUp("xs", screenWidth)) {
      return 1;
    }
    return 3;
  }
  const cols = getCols(props.width);

  const sendQuery = useCallback(async () => {
    let appliedFilters = selectedNetworks;
    if (search.length === 0 && selectedNetworks.length === 0) {
      dispatch(
        EMDoGetListOfAllChannelsAction({
          limit: limit,
        })
      );
    } else if (search.length > 0 && selectedNetworks.length == 0) {
      dispatch(
        EMDoGetChannelsByGroupFilterAction({
          limit: limitWhileSearching,
          myaction: "search=" + search,
        })
      );
    } else if (selectedNetworks.length > 0 && search.length == 0) {
      if (selectedNetworks.length == 1) {
        let selectedNetwork = appliedFilters[0];
        let id = selectedNetwork.id;
        let action;
        action = "groups=" + id;
        dispatch(
          EMDoGetChannelsByGroupFilterAction({
            myaction: action,
            limit: limitWhileFiltering,
          })
        );
      } else if (selectedNetworks.length > 1) {
        let groupAction;
        let groupIds = [];

        appliedFilters.map((selectedGroup, key) => {
          groupIds.push(selectedGroup.id);
          groupAction = `${groupIds.toString()}`;
        });
        let action;
        action = "groups=" + groupAction;
        dispatch(
          EMDoGetChannelsByGroupFilterAction({
            myaction: action,
            limit: limitWhileFiltering,
          })
        );
      }
    } else if (selectedNetworks.length > 0 && search.length > 0) {
      if (selectedNetworks.length == 1) {
        let selectedNetwork = appliedFilters[0];
        let id = selectedNetwork.id;
        let action;
        action = "groups=" + id;
        if (search.length > 0) {
          action = action + "&" + "search=" + search;
        }

        dispatch(
          EMDoGetChannelsByGroupFilterAction({
            myaction: action,
            limit: limitWhileFiltering,
          })
        );
      } else if (selectedNetworks.length > 1) {
        let groupAction;
        let groupIds = [];

        appliedFilters.map((selectedGroup, key) => {
          groupIds.push(selectedGroup.id);
          groupAction = `${groupIds.toString()}`;
        });
        let action;
        action = "groups=" + groupAction;
        if (search.length > 0) {
          action = action + "&" + "search=" + search;
        }
        dispatch(
          EMDoGetChannelsByGroupFilterAction({
            myaction: action,
            limit: limitWhileFiltering,
          })
        );
      }
    }
  }, [limit, limitWhileSearching, limitWhileFiltering]);

  useEffect(() => {
    if (search.length === 0 && selectedNetworks.length === 0) {
      sendQuery(limit);
    } else if (search.length > 0 && selectedNetworks.length == 0) {
      sendQuery(limitWhileSearching);
    } else if (selectedNetworks.length > 0 || search.length > 0) {
      sendQuery(limitWhileFiltering);
    }
  }, [sendQuery, limit, limitWhileSearching, limitWhileFiltering]);

  const handleObserver = useCallback(() => {
    if (search.length === 0 && selectedNetworks.length === 0) {
      setLimit((prev) => prev + 10);
      setLimitWhileFiltering(0);
      setLimitWhileSearching(0);
    } else if (search.length > 0 && selectedNetworks.length == 0) {
      setLimitWhileSearching((prev) => prev + 10);
      setLimitWhileFiltering(0);
      setLimit(0);
    } else if (selectedNetworks.length > 0 || search.length > 0) {
      setLimitWhileFiltering((prev) => prev + 10);
      setLimit(0);
      setLimitWhileSearching(0);
    }
  }, [search, selectedNetworks]);

  useEffect(() => {
    let appliedFilters = selectedNetworks;
    if (appliedFilters.length > 0) {
      setLimitWhileFiltering(0);
      if (appliedFilters.length == 1) {
        let selectedNetwork = appliedFilters[0];
        let id = selectedNetwork.id;
        let action;
        action = "groups=" + id;
        if (search.length > 0) {
          action = action + "&" + "search=" + search;
        }
        dispatch(
          EMDoGetChannelsByGroupFilterAction({
            myaction: action,
            limit: limitWhileFiltering,
          })
        );
      } else if (appliedFilters.length > 1) {
        let action;
        appliedFilters.map((selectedNetwork, key) => {
          let groupAction;
          let groupIds = [];

          appliedFilters.map((selectedGroup, key) => {
            groupIds.push(selectedGroup.id);
            groupAction = `${groupIds.toString()}`;
          });
          action = "groups=" + groupAction;
          if (search.length > 0) {
            action = action + "&" + "search=" + search;
          }
        });
        dispatch(
          EMDoGetChannelsByGroupFilterAction({
            myaction: action,
            limit: limitWhileFiltering,
          })
        );
      } else if (appliedFilters.length == 0 && search.length == 0) {
        setLimit(0);
        dispatch(EMDoGetListOfAllChannelsAction({ limit: limit }));
      }
    } else {
      setLimitWhileSearching(0);
      let action = "search=" + search;
      dispatch(
        EMDoGetChannelsByGroupFilterAction({
          myaction: action,
          limit: limitWhileSearching,
        })
      );
    }
  }, [search]);

  return (
    <InfiniteScroll
    dataLength={data.browseallChannels.length}
    next={handleObserver}
    hasMore={true}
  >
    <EMPage
      title="Browse Channels"
      style={{
        width: "100%",
        paddingLeft: matchesLargeScreen ? "8%" : "4%",
        paddingTop: matchesLargeScreen
          ? "4%"
          : matchesMediumScreen
          ? "6%"
          : "8%",
        paddingRight: matchesLargeScreen ? "8%" : "4%",
        paddingBottom: "2%",
      }}
    >
      <Grid
        container
        direction="row"
        alignItems="flexStart"
        spacing={3}
        // style={{padding:50}}
      >
        {matchesMediumScreen ? (
          <Grid item xs={matchesLargeScreen ? 2 : 3}>
            <EMSideBar />
          </Grid>
        ) : null}
        <Grid item xs={matchesLargeScreen ? 7 : matchesMediumScreen ? 9 : 12}>
          <Paper elevation={2}>
            <Grid container alignItems="center" style={{ padding: 10 }}>
              {/* <div style={{ display: "flex", padding: 20 }}> */}
              <Grid
                item
                md={9}
                xs={8}
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                <Typography
                  style={{ fontSize: "medium", fontWeight: "bolder" }}
                >
                  Search for Channels available
                </Typography>
              </Grid>
              <Grid item md={3} xs={4}>
                <EMSearch
                  disabled={
                    data.isSearchDisabledBrowseChannels ||
                    data.isFilterDisabledForChannels
                  }
                  data={search}
                  usedIn="browse_groups"
                  route={true}
                  hint={"Search"}
                  onChangeListener={(searchData) => {
                    setSearch(searchData);
                    dispatch({
                      type: DISABLE_ENABLE_SEARCH_BROWSE_CHANNELS,
                      payload: true,
                    });
                  }}
                />
              </Grid>
            </Grid>

            <div className={classes.root}>
              <GridList
                cellHeight={180}
                className={classes.gridList}
                cols={cols}
                style={{
                  alignItems: "flex-start",
                }}
              >
                {data.browseallChannels.map((group, key) => (
                  <GridListTile
                    key={key}
                    className={classes.gridListTile}
                    style={{
                      // width: "32%",
                      height: "150px",
                    }}
                  >
                    <EMGroupView
                      route="browse_groups"
                      onStarClick={() => {
                        let actions;
                        group.is_fav
                          ? (actions = `remove-favorite`)
                          : (actions = `favorite`);
                        dispatch(
                          EMDoActionForGroupAction({
                            id: group.id,
                            myaction: actions,
                            postIndex: key,
                            route: "browse_groups",
                          })
                        );
                      }}
                      type={CHANNEL_INFO_MODAL}
                      value={data.channelInfoPopUp.value}
                      id={group.id}
                      isMember={group.is_member}
                      number_of_group_users={group.number_of_channel_users}
                      group_name={group.channel_name}
                      logo_file={group.logo_file}
                      isFav={group.is_fav}
                      created_by={group.created_by}
                      isHighlighted={group.is_highlited}
                      organization_name={group.organization}
                      users_count={group.users_count}
                      privacy_type_name={group.privacy_type_name}
                      group={group}
                      onCardClick={() => {
                        dispatch(EMDoGetChannelDetailsAction({ id: group.id }));
                        navigation("activity", {
                          state: { ChannelId: group.id },
                        });
                        window.sessionStorage.setItem("ChannelId", group.id);
                        if (group.is_highlited === true) {
                          dispatch(
                            EMDoMarkHighlightedChannelAsSeenAction(group.id)
                          );
                        }
                      }}
                    />
                  </GridListTile>
                ))}
              </GridList>
              {data.channelInfoPopUp.network_name ||
              data.channelInfoPopUp.created_by ? (
                <Dialog
                  fullWidth={true}
                  maxWidth="sm"
                  open={data.channelInfoPopUp.value}
                  onClose={() => {
                    dispatch({
                      type: GROUP_INFO_MODAL,
                      payload: { value: false },
                    });
                  }}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  // maxWidth="md"
                  className={classes.dialog}
                >
                  <DialogContent>
                    <div style={{ marginBottom: "5%" }}>
                      <div style={{ display: "flex" }}>
                        {data.channelInfoPopUp &&
                        data.channelInfoPopUp.network_name ? (
                          <Typography color="textSecondary">
                            Network Name
                          </Typography>
                        ) : null}

                        <DeleteIcon
                          style={{ marginLeft: "20%" }}
                          onClick={() => {
                            dispatch({
                              type: GROUP_INFO_MODAL,
                              payload: { value: false },
                            });
                          }}
                        />
                      </div>
                      {data.channelInfoPopUp &&
                      data.channelInfoPopUp.network_name ? (
                        <Typography
                          style={{
                            fontWeight: "bolder",
                            fontSize: "medium",
                            marginTop: "3%",
                          }}
                        >
                          {data.channelInfoPopUp &&
                            data.channelInfoPopUp.network_name}
                        </Typography>
                      ) : null}
                    </div>
                    {data.channelInfoPopUp.groupInfo &&
                    data.channelInfoPopUp.groupInfo.created_by ? (
                      <div>
                        <Typography color="textSecondary">
                          Created By
                        </Typography>
                        <Typography
                          style={{
                            fontWeight: "bolder",
                            fontSize: "medium",
                            marginTop: "3%",
                          }}
                        >
                          {data.channelInfoPopUp.groupInfo &&
                            data.channelInfoPopUp.groupInfo.created_by}
                        </Typography>
                      </div>
                    ) : null}
                  </DialogContent>
                </Dialog>
              ) : null}
            </div>
          </Paper>
        </Grid>
        {matchesLargeScreen ? (
          <Grid item xs={3}>
            <Paper elevation={2} style={{ padding: "5%" }}>
              <Grid container direction="row">
                <Grid item xs={2}>
                  <LocalOffer fontSize="large" color="primary" />
                </Grid>
                <Grid item xs={10}>
                  <Typography
                    style={{
                      color: "#2F92D6",
                      fontSize: "medium",
                      fontWeight: "bolder",
                    }}
                  >
                    GROUP FILTER
                  </Typography>
                </Grid>
              </Grid>
              <FormControl className={classes.formControl}>
                <Typography
                  style={{ fontWeight: "bolder", fontSize: "medium" }}
                >
                  Internal Groups
                </Typography>
                {data.groupsForFilter &&
                  data.groupsForFilter.internal_groups.map((group, key) => (
                    <FormGroup style={{ width: "100%" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={
                              data.isSearchDisabledBrowseChannels ||
                              data.isFilterDisabledForChannels
                            }
                            checked={group.value}
                            name={group.group_name}
                            onChange={(event) => {
                              dispatch({
                                type: DISABLE_ENABLE_FILTER_FOR_BROWSE_CHANNELS,
                                payload: true,
                              });
                              let arr;
                              if (event.target.checked) {
                                let checkedValue = event.target.name;
                                arr =
                                  selectedNetworks.length > 0
                                    ? [...selectedNetworks, group]
                                    : [group];
                                setSelectedNetworks(arr);
                                dispatch({
                                  type: CHANNEL_APPLY_GROUP_FILTER,
                                  payload: {
                                    checked_value: !group.value,
                                    postIndex: key,
                                    group_type: "internal_group",
                                  },
                                });
                              } else {
                                //find this value in array and delete it
                                let checkedValue = event.target.name;
                                let index = selectedNetworks.findIndex(
                                  (x) => x.group_name == checkedValue
                                );
                                selectedNetworks.splice(index, 1);
                                arr = selectedNetworks;
                                dispatch({
                                  type: CHANNEL_APPLY_GROUP_FILTER,
                                  payload: {
                                    checked_value: !group.value,
                                    postIndex: key,
                                    group_type: "internal_group",
                                  },
                                });
                              }
                              if (arr.length > 0) {
                                //
                                if (arr.length == 1) {
                                  let selectedNetwork = arr[0];
                                  let id = selectedNetwork.id;
                                  let action;
                                  action = "groups=" + id;
                                  if (search.length > 0) {
                                    action = action + "&" + "search=" + search;
                                  }
                                  setLimitWhileFiltering(0)
                                  dispatch(
                                    EMDoGetChannelsByGroupFilterAction({myaction:action, limit:limitWhileFiltering})
                                  );
                                } else if (arr.length > 1) {
                                  let groupAction;
                                  let groupIds = [];

                                  arr.map((selectedGroup, key) => {
                                    groupIds.push(selectedGroup.id);
                                    groupAction = `${groupIds.toString()}`;
                                  });
                                  let action;
                                  action = "groups=" + groupAction;
                                  if (search.length > 0) {
                                    action = action + "&" + "search=" + search;
                                  }
                                  setLimitWhileFiltering(0)
                                  dispatch(
                                    EMDoGetChannelsByGroupFilterAction({myaction:action, limit:limitWhileFiltering})
                                  );
                                }
                              } else if (
                                arr.length == 0 &&
                                search.length == 0
                              ) {
                                setLimit(0)
                                dispatch(EMDoGetListOfAllChannelsAction({limit:limit}));
                              } else if (arr.length == 0 && search.length > 0) {
                                let action;
                                if (search.length > 0) {
                                  action = "&" + "search=" + search;
                                }
                                setLimitWhileSearching(0)
                                dispatch(
                                  EMDoGetChannelsByGroupFilterAction({myaction:action, limit:limitWhileSearching})
                                );
                              }
                            }}
                          />
                        }
                        label={group.group_name}
                        key={group.id}
                      />
                    </FormGroup>
                  ))}
                <Typography
                  style={{ fontWeight: "bolder", fontSize: "medium" }}
                >
                  Joint Groups
                </Typography>
                {data.groupsForFilter &&
                  data.groupsForFilter.joint_groups.map((group, key) => (
                    <FormGroup style={{ width: "100%" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={
                              data.isSearchDisabledBrowseChannels ||
                              data.isFilterDisabledForChannels
                            }
                            checked={group.value}
                            name={group.group_name}
                            onChange={(event) => {
                              dispatch({
                                type: DISABLE_ENABLE_FILTER_FOR_BROWSE_CHANNELS,
                                payload: true,
                              });
                              let arr;
                              if (event.target.checked) {
                                let checkedValue = event.target.name;
                                arr =
                                  selectedNetworks.length > 0
                                    ? [...selectedNetworks, group]
                                    : [group];
                                setSelectedNetworks(arr);
                                dispatch({
                                  type: CHANNEL_APPLY_GROUP_FILTER,
                                  payload: {
                                    checked_value: !group.value,
                                    postIndex: key,
                                    group_type: "joint_group",
                                  },
                                });
                              } else {
                                //find this value in array and delete it
                                let checkedValue = event.target.name;
                                let index = selectedNetworks.findIndex(
                                  (x) => x.group_name == checkedValue
                                );
                                selectedNetworks.splice(index, 1);
                                arr = selectedNetworks;
                                dispatch({
                                  type: CHANNEL_APPLY_GROUP_FILTER,
                                  payload: {
                                    checked_value: !group.value,
                                    postIndex: key,
                                    group_type: "joint_group",
                                  },
                                });
                              }
                              if (arr.length > 0) {
                                //
                                if (arr.length == 1) {
                                  let selectedNetwork = arr[0];
                                  let id = selectedNetwork.id;
                                  let action;
                                  action = "groups=" + id;
                                  if (search.length > 0) {
                                    action = action + "&" + "search=" + search;
                                  }
                                  setLimitWhileFiltering(0)
                                  dispatch(
                                    EMDoGetChannelsByGroupFilterAction({myaction:action, limit:limitWhileFiltering})
                                  );
                                } else if (arr.length > 1) {
                                  let groupAction;
                                  let groupIds = [];

                                  arr.map((selectedGroup, key) => {
                                    groupIds.push(selectedGroup.id);
                                    groupAction = `${groupIds.toString()}`;
                                  });
                                  let action;
                                  action = "groups=" + groupAction;
                                  if (search.length > 0) {
                                    action = action + "&" + "search=" + search;
                                  }
                                  setLimitWhileFiltering(0)
                                  dispatch(
                                    EMDoGetChannelsByGroupFilterAction({myaction:action, limit:limitWhileFiltering})
                                  );
                                }
                              } else if (
                                arr.length == 0 &&
                                search.length == 0
                              ) {
                                setLimit(0)
                                dispatch(EMDoGetListOfAllChannelsAction({limit:limit}));
                              } else if (arr.length == 0 && search.length > 0) {
                                let action;
                                if (search.length > 0) {
                                  action = "&" + "search=" + search;
                                }
                                setLimitWhileSearching(0)
                                dispatch(
                                  EMDoGetChannelsByGroupFilterAction({myaction:action, limit:limitWhileSearching})
                                );
                              }
                            }}
                          />
                        }
                        label={group.group_name}
                        key={group.id}
                      />
                    </FormGroup>
                  ))}
              </FormControl>
            </Paper>
          </Grid>
        ) : null}
      </Grid>
    </EMPage>
    </InfiniteScroll>
  );
}
export default withWidth()(LatestPost);
