import { AUTH_LOGOUT, RESET } from "../../constants";
import {
  SAVE_GLOBAL_SEARCH,
  CHANGE_ITEM_INDEX,
  CHANGE_USER_TO_ADMIN,
  CHANGE_ELEMENT_FOR_SUBTABS,
  GET_GROUPS_BY_SEARCH,
  GET_CHANNELS_BY_SEARCH,
  GET_POSTS_BY_SEARCH,
  GET_EVENTS_BY_SEARCH,
  GET_NETWORKS_BY_SEARCH,
  FIRST_TIME_LOG_IN,
  GET_POLLS_BY_SEARCH,
  NAVIGATE_BETWEEN_DIFFERENT_PAGES,
  GET_QUIZZES_BY_SEARCH,
  ADD_SUBTABS_TO_GROUP,
} from "../../constants/UserPlatform/EMNavigationConstant";
import {
  USER_EXPAND_COMMENT,
  USER_SHOW_COMMENT,
  USER_EXPAND_TEXT,
  SHOW_POST_MEDIA_PREVIEW,
  DELETE_POST_COMMENT,
  LIKE_DISLIKE_POSTS,
  POST_SHOW_HASHTAG,
  POST_EMOJI_SELECTED,
  POST_HASHTAG_SELECTED,
  POST_SHOW_TAGPEOPLE,
  POST_NEW_COMMENT,
  SUBMIT_NEW_COMMENT,
  POST_SHOW_EMOJI,
  POST_TAGPEOPLE_SELECTED,
  LIST_HASHTAG,
  LIST_MENTIONS,
  SUBMIT_POLL,
  SUBMIT_OPEN_POLL,
  SUBMIT_QUIZ,
  PLAY_VIDEO_BY_ID,
  CHANGE_LIKE_STATUS_OF_POST,
  CHANGE_LIKE_BUTTON_STATE,
} from "../../constants/UserPlatform/index";
import { cloneDeep, findIndex, find } from "lodash";
// import { ReactComponent as ChannelIcon } from '../../assets/ChannelIcon.svg';
import { ReactComponent as OrganisationIcon } from "../../../assets/GroupIcon.svg";
import { ReactComponent as NetworkIcon } from "../../../assets/NetworkIcon.svg";
import { ReactComponent as ChannelIcon } from "../../../assets/chat.svg";
import { ReactComponent as EventIcon } from "../../../assets/eventIcon.svg";
import { ReactComponent as UserIcon } from "../../../assets/UserMenuIcon.svg";
import { ReactComponent as HomeIcon } from "../../../assets/home.svg";
import { ReactComponent as PollIcon } from "../../../assets/poll icon.svg";
import { ReactComponent as QuizIcon } from "../../../assets/help.svg";
import { Help } from "@material-ui/icons";

//   const userData = useSelector(state => state.auth);
const initialState = {
  firstLogIn: false,
  items: [
    {
      href: "/users/home",
      icon: HomeIcon,
      title: "Home",
      tabs: [
        { id: 0, label: "Latest Posts", path: "/users/home" },
        { id: 1, label: "Pinned Post", path: "home/pinnedpost" },
      ],
      activeIndex: 0,
      isActive: false,
      elements: [],
    },
    {
      href: "/users/groups",
      icon: OrganisationIcon,
      title: "Groups",
      tabs: [
        { id: 0, label: "My Groups", path: "/users/groups" },
        { id: 1, label: "Browse Groups", path: "groups/browsegroups/" },
      ],
      activeIndex: 0,
      // subtabs :[{id:0, label: "Activity Feed", path: "groups/activity" }, {id:1, label: "Members", path: "groups/members" },{id:2, label: "Settings", path: "groups/settings" }]
      elements: [
        {
          path: "groups/",
          subtabs: [
            { id: 0, label: "Activity Feed", path: "groups/activity" },
            { id: 1, label: "Members", path: "groups/members" },
            { id: 2, label: "Settings", path: "groups/settings" },
          ],
        },
        {
          path: "groups/browsegroups/",
          subtabs: [
            {
              id: 0,
              label: "Activity Feed",
              path: "/users/groups/browsegroups/activity",
            },
            {
              id: 1,
              label: "Members",
              path: "/users/groups/browsegroups/members",
            },
          ],
        },
      ],
    },
    {
      href: "/users/channels",
      icon: ChannelIcon,
      title: "Channels",
      isActive: false,
      tabs: [
        { id: 0, label: "My Channels", path: "/users/channels" },
        { id: 1, label: "Browse Channels", path: "channels/browsechannels/" },
      ],
      activeIndex: 0,
      elements: [
        {
          path: "channels/",
          subtabs: [
            { id: 0, label: "Activity Feed", path: "channels/activity" },
            { id: 1, label: "Members", path: "channels/members" },
            { id: 2, label: "Settings", path: "channels/settings" },
          ],
        },
        {
          path: "channels/browsechannels/",
          subtabs: [
            {
              id: 0,
              label: "Activity Feed",
              path: "channels/browsechannels/activity",
            },
            {
              id: 1,
              label: "Members",
              path: "channels/browsechannels/members",
            },
          ],
        },
      ],
    },
    {
      href: "/users/events",
      icon: EventIcon,
      title: "Events",
      isActive: false,
      tabs: [
        { id: 0, label: "Calendar", path: "/users/events" },
        { id: 1, label: "Your Events", path: "events/yourevents" },
        { id: 2, label: "Search Events", path: "events/searchevents" },
      ],
      activeIndex: 0,
      elements: [],
    },
    {
      href: "/users/networks",
      icon: NetworkIcon,
      title: "Networks",
      isActive: false,
      tabs: [
        { id: 0, label: "My Networks", path: "/users/networks" },
        { id: 1, label: "My Organization", path: "networks/organization" },
      ],
      activeIndex: 0,
      elements: [],
    },
    {
      href: "/users/people",
      icon: UserIcon,
      title: "People",
      isActive: false,
      tabs: [
        { id: 0, label: "My Company", path: "/users/people" },
        { id: 1, label: "Search Others", path: "people/searchothers" },
      ],
      activeIndex: 0,
      elements: [
        {
          path: "people/",
          subtabs: [{ id: 0, label: "Profile Details", path: "people/:id" }],
        },
      ],
    },
    {
      href: "/users/poll",
      icon: PollIcon,
      title: "Poll",
      tabs: [
        { id: 0, label: "My Poll", path: "/users/poll" },
        { id: 1, label: "Open ended Question", path: "poll/openended" },
      ],
      activeIndex: 0,
      elements: [],
    },
    {
      href: "/users/quiz",
      icon: QuizIcon,
      title: "Quiz",
      tabs: [{ id: 0, label: "My Quiz", path: "/users/quiz" }],
      activeIndex: 0,
      elements: [],
    },
    {
      href: "/users/search",
      icon: "",
      isActive: false,
      title: "",
      tabs: [
        { id: 0, label: "All", path: "search" },
        { id: 1, label: "Posts", path: "search/posts" },
        { id: 2, label: "Groups", path: "search/groups" },
        { id: 3, label: "Channels", path: "search/channels" },
        { id: 4, label: "Events", path: "search/events" },
        { id: 5, label: "Networks", path: "search/networks" },
        { id: 6, label: "Polls", path: "search/polls" },
        { id: 7, label: "Quizzes", path: "search/quizzes" },
      ],
      activeIndex: 0,
      elements: [],
    },
    {
      href: "/users/notifications",
      icon: "",
      title: "",
      tabs: [{ id: 0, label: "Notifications", path: "/users/notifications" }],
      activeIndex: 0,
      elements: [],
    },
    {
      href: "/users/hashtag",
      icon: "",
      title: "",
      tabs: [{ id: 0, label: "Hashtags", path: "/users/hashtags/:id" }],
      activeIndex: 0,
      elements: [],
    },
    {
      href: "/users/taggedpost",
      icon: "",
      title: "",
      tabs: [{ id: 0, label: "Post", path: "/users/taggedpost/:id" }],
      activeIndex: 0,
      elements: [],
    },
  ],
  isUserAdmin: false,
  filter: "",
  searchedGroups: [],
  searchedChannels: [],
  searchedEvents: [],
  searchedPosts: [],
  searchedNetworks: [],
  searchedPolls: [],
  searchedQuizzes: [],
  categories: [
    { id: 1, categoryName: "Text" },
    { id: 2, categoryName: "Images & Videos" },
    { id: 3, categoryName: "Documents" },
    { id: 4, categoryName: "Events" },
  ],
  nextPosts: null,
  nextEvents: null,
  nextPolls: null,
  nextQuizzes: null,
  nextNetworks: null,
  nextGroups: null,
  nextChannels: null,
  postsCount: 0,
  eventsCount: 0,
  pollsCount: 0,
  quizzesCount: 0,
  networksCount: 0,
  groupsCount: 0,
  channelsCount: 0
};

export default function EMAdminReducer(state = initialState, action) {
  let newState = cloneDeep(state);
  switch (action.type) {
    case FIRST_TIME_LOG_IN: {
      newState["firstLogIn"] = action.payload;
      return newState;
    }

    case NAVIGATE_BETWEEN_DIFFERENT_PAGES: {
      let tabsArray = newState["items"];
      let newTabArray = tabsArray.filter((item) => (item.isActive = true));
      if (newTabArray && newTabArray.length > 0) {
        tabsArray.map((item) => (item.isActive = false));
      }
      newState["items"] = tabsArray;
      let pageName = action.payload.href;

      let count = pageName.split("/").length - 1;
      let pageIndex;
      if (count !== 2) {
        if (action.payload.href.includes("browsechannels")) {
          pageName = "/users/channels";
        } else if (action.payload.href.includes("browsegroups")) {
          pageName = "/users/groups";
        } else if (
          !action.payload.href.includes("browsegroups") &&
          !action.payload.href.includes("browsechannels")
        ) {
          let index = pageName.lastIndexOf("/");
          let newPageName = pageName.slice(0, index);
          pageName = newPageName;
        }
      }
      pageIndex = findIndex(newState["items"], { href: pageName });
      if (pageIndex >= 0) {
        newState["items"][pageIndex].isActive = true;
      }

      return newState;
    }

    case CHANGE_ITEM_INDEX: {
      let id = action.payload.id;
      let newVal = action.payload.activeIndex;
      newState["items"][id]["activeIndex"] = newVal;
      return newState;
    }

    case ADD_SUBTABS_TO_GROUP: {
      let newArray = [
        {
          href: "/users/groups",
          icon: OrganisationIcon,
          title: "Groups",
          tabs: [
            { id: 0, label: "My Groups", path: "groups/" },
            { id: 1, label: "Browse Groups", path: "groups/browsegroups/" },
          ],
          activeIndex: 0,
          // subtabs :[{id:0, label: "Activity Feed", path: "groups/activity" }, {id:1, label: "Members", path: "groups/members" },{id:2, label: "Settings", path: "groups/settings" }]
          elements: [
            {
              path: "groups/",
              subtabs: [
                { id: 0, label: "Activity Feed", path: "groups/activity" },
                { id: 1, label: "Members", path: "groups/members" },
                { id: 2, label: "Settings", path: "groups/settings" },
              ],
            },
            {
              path: "groups/browsegroups/",
              subtabs: [
                {
                  id: 0,
                  label: "Activity Feed",
                  path: "/users/groups/browsegroups/activity",
                },
                {
                  id: 1,
                  label: "Members",
                  path: "/users/groups/browsegroups/members",
                },
              ],
            },
          ],
        },
        {
          href: "/users/channels",
          icon: ChannelIcon,
          title: "Channels",
          isActive: false,
          tabs: [
            { id: 0, label: "My Channels", path: "/users/channels" },
            {
              id: 1,
              label: "Browse Channels",
              path: "channels/browsechannels/",
            },
          ],
          activeIndex: 0,
          elements: [
            {
              path: "channels/",
              subtabs: [
                { id: 0, label: "Activity Feed", path: "channels/activity" },
                { id: 1, label: "Members", path: "channels/members" },
                { id: 2, label: "Settings", path: "channels/settings" },
              ],
            },
            {
              path: "channels/browsechannels/",
              subtabs: [
                {
                  id: 0,
                  label: "Activity Feed",
                  path: "channels/browsechannels/activity",
                },
                {
                  id: 1,
                  label: "Members",
                  path: "channels/browsechannels/members",
                },
              ],
            },
          ],
        },
      ];
      newState["items"] = newState["items"].map(
        (obj) => newArray.find((o) => o.href === obj.href) || obj
      );
      return newState;
    }

    case CHANGE_USER_TO_ADMIN: {
      newState["isUserAdmin"] = action.payload;
      return newState;
    }

    case CHANGE_ELEMENT_FOR_SUBTABS: {
      let items = newState["items"];
      let affectingObjectIndex = findIndex(items, {
        href: action.payload.path,
      });
      ///remove element from the respective path of subtab
      let data = items[affectingObjectIndex];
      let elementData = findIndex(data.elements, {
        path: action.payload.element,
      });
      let subTabs = data.elements[elementData];
      let affectingSubTab = findIndex(subTabs.subtabs, {
        label: action.payload.remove,
      });
      if (affectingSubTab >= 0) {
        subTabs.subtabs.splice(affectingSubTab, 1);
        data.elements[elementData] = subTabs;
        newState["items"][affectingObjectIndex] = data;
      }

      return newState;
    }

    case SAVE_GLOBAL_SEARCH: {
      newState["filter"] = action.payload;
      return newState;
    }
    case GET_GROUPS_BY_SEARCH: {
      newState["groupsCount"] = action.payload.response.count;
      newState["nextGroups"] = action.payload.response.next;
      if (action.payload.limit == 0) {
        newState["searchedGroups"] = action.payload.response.results;
      } else {
        newState["searchedGroups"] = newState["searchedGroups"].concat(
          action.payload.response.results
        );
      }

      return newState;
    }

    case GET_CHANNELS_BY_SEARCH: {
      newState["channelsCount"] = action.payload.response.count;
      newState["nextChannels"] = action.payload.response.next;
      if (action.payload.limit == 0) {
        newState["searchedChannels"] = action.payload.response.results;
      } else {
        newState["searchedChannels"] = newState["searchedChannels"].concat(
          action.payload.response.results
        );
      }
      return newState;
    }

    case GET_POSTS_BY_SEARCH: {
      newState["postsCount"] = action.payload.response.count
      newState["nextPosts"] = action.payload.response.next;
      if (action.payload.page == 1) {
        newState["searchedPosts"] = action.payload.response.results;
      } else {
        newState["searchedPosts"] = newState["searchedPosts"].concat(
          action.payload.response.results
        );
      }

      newState["searchedPosts"] &&
        newState["searchedPosts"].map((post) => {
          post["userName"] = post["firstName"] + " " + post["lastName"];
          post["expandText"] = false;
          post["expandPostComment"] = false;
          post["showComments"] = false;
          post["showHashTag"] = false;
          post["showTagPeople"] = false;
          post["showEmoji"] = false;
          post["mediaView"] = false;
          post["likeButtonDisabled"] = false;
          let start = new Date(post["event_start_date"]);
          let end = new Date(post["event_end_date"]);
          post["timeZone"] = showTimeZone(start);

          function ChangeDate(date) {
            var d = new Date(date),
              month = "" + (d.getMonth() + 1),
              day = "" + d.getDate(),
              year = d.getFullYear();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            return [year, month, day].join("-");
          }
          function ChangeTime(date) {
            return (
              ("00" + date.getHours()).slice(-2) +
              ":" +
              ("00" + date.getMinutes()).slice(-2) +
              ":" +
              ("00" + date.getSeconds()).slice(-2)
            );
          }

          function showTimeZone(date) {
            const dateAsString = date.toString();
            const timezone = dateAsString.match(/\(([^\)]+)\)$/)[1];
            return timezone;
          }
          post["event_start"] = ChangeDate(start);
          post["event_end"] = ChangeDate(end);
          post["event_start_time"] = ChangeTime(start);
          post["event_end_time"] = ChangeTime(end);
          post["showMore"] = false;

          post["channel_details"].map((channel) => {
            if (channel.channel_name.includes("MAIN_CHANNEL")) {
              post["postFrom"] = "home";
            } else {
              post["postFrom"] = "channel";
            }
          });

          post["shouldOpen"] = false;
          post["images"].map((img) => {
            img["type"] = "images";
            img["isPlaying"] = false;
          });
          post["videos"].map((video) => {
            video["type"] = "videos";
            video["isPlaying"] = false;
          });
          post["video_links"].map((video) => {
            video["type"] = "video_link";
            video["isPlaying"] = false;
          });
          post["documents"].map((doc) => {
            doc["type"] = "documents";
          });
          post["totalMedia"] = post["images"].concat(post["videos"]);
          post["totalCombinedMedia"] = post["totalMedia"].concat(
            post["video_links"]
          );
          post["postAtMentions"] = [];
          post["editPost"] = {
            showEditPost: false,
            labels: { title: "", button: "" },
          };
          post["newComment"] = "";
          post["owner"] =
            post.userName == action.payload.userData.name ? true : false;
          let postCategory = post["category"];

          newState["categories"].map((category) => {
            if (
              category.category_name &&
              category.category_name.includes(postCategory)
            ) {
              post["category"] = category.icon;
            }
          });
        });
      return newState;
    }

    case GET_POLLS_BY_SEARCH: {
      newState["pollsCount"] = action.payload.response.count
      newState["nextPolls"] = action.payload.response.next;
      if (action.payload.page == 1) {
        newState["searchedPolls"] = action.payload.response.results;
      } else {
        newState["searchedPolls"] = newState["searchedPolls"].concat(
          action.payload.response.results
        );
      }
      newState["searchedPolls"] &&
        newState["searchedPolls"].map((post) => {
          post["userName"] = post["firstName"] + " " + post["lastName"];
          post["expandText"] = false;
          post["expandPostComment"] = false;
          post["showComments"] = false;
          post["showHashTag"] = false;
          post["showTagPeople"] = false;
          post["showEmoji"] = false;
          post["mediaView"] = false;
          let start = new Date(post["event_start_date"]);
          let end = new Date(post["event_end_date"]);
          post["timeZone"] = showTimeZone(start);

          function ChangeDate(date) {
            var d = new Date(date),
              month = "" + (d.getMonth() + 1),
              day = "" + d.getDate(),
              year = d.getFullYear();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            return [year, month, day].join("-");
          }
          function ChangeTime(date) {
            return (
              ("00" + date.getHours()).slice(-2) +
              ":" +
              ("00" + date.getMinutes()).slice(-2) +
              ":" +
              ("00" + date.getSeconds()).slice(-2)
            );
          }

          function showTimeZone(date) {
            const dateAsString = date.toString();
            const timezone = dateAsString.match(/\(([^\)]+)\)$/)[1];
            return timezone;
          }
          post["event_start"] = ChangeDate(start);
          post["event_end"] = ChangeDate(end);
          post["event_start_time"] = ChangeTime(start);
          post["event_end_time"] = ChangeTime(end);
          post["showMore"] = false;

          post["channel_details"].map((channel) => {
            if (channel.channel_name.includes("MAIN_CHANNEL")) {
              post["postFrom"] = "home";
            } else {
              post["postFrom"] = "channel";
            }
          });

          post["shouldOpen"] = false;
          post["images"].map((img) => {
            img["type"] = "images";
          });
          post["videos"].map((video) => {
            video["type"] = "videos";
          });
          post["video_links"].map((video) => {
            video["type"] = "video_link";
          });
          post["documents"].map((doc) => {
            doc["type"] = "documents";
          });
          post["totalMedia"] = post["images"].concat(post["videos"]);
          post["totalCombinedMedia"] = post["totalMedia"].concat(
            post["video_links"]
          );
          post["postAtMentions"] = [];
          post["editPost"] = {
            showEditPost: false,
            labels: { title: "", button: "" },
          };
          post["newComment"] = "";
          post["owner"] =
            post.userName == action.payload.userData.name ? true : false;
          let postCategory = post["category"];

          newState["categories"].map((category) => {
            if (
              category.category_name &&
              category.category_name.includes(postCategory)
            ) {
              post["category"] = category.icon;
            }
          });
        });
      return newState;
    }

    case GET_QUIZZES_BY_SEARCH: {
      newState["quizzesCount"] = action.payload.response.count
      newState["nextQuizzes"] = action.payload.response.next;
      if (action.payload.page == 1) {
        newState["searchedQuizzes"] = action.payload.response.results;
      } else {
        newState["searchedQuizzes"] = newState["searchedQuizzes"].concat(
          action.payload.response.results
        );
      }
      newState["searchedQuizzes"] &&
        newState["searchedQuizzes"].map((post) => {
          post["userName"] = post["firstName"] + " " + post["lastName"];
          post["expandText"] = false;
          post["expandPostComment"] = false;
          post["showComments"] = false;
          post["showHashTag"] = false;
          post["showTagPeople"] = false;
          post["showEmoji"] = false;
          post["mediaView"] = false;
          let start = new Date(post["event_start_date"]);
          let end = new Date(post["event_end_date"]);
          post["timeZone"] = showTimeZone(start);

          function ChangeDate(date) {
            var d = new Date(date),
              month = "" + (d.getMonth() + 1),
              day = "" + d.getDate(),
              year = d.getFullYear();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            return [year, month, day].join("-");
          }
          function ChangeTime(date) {
            return (
              ("00" + date.getHours()).slice(-2) +
              ":" +
              ("00" + date.getMinutes()).slice(-2) +
              ":" +
              ("00" + date.getSeconds()).slice(-2)
            );
          }

          function showTimeZone(date) {
            const dateAsString = date.toString();
            const timezone = dateAsString.match(/\(([^\)]+)\)$/)[1];
            return timezone;
          }
          post["event_start"] = ChangeDate(start);
          post["event_end"] = ChangeDate(end);
          post["event_start_time"] = ChangeTime(start);
          post["event_end_time"] = ChangeTime(end);
          post["showMore"] = false;

          post["channel_details"].map((channel) => {
            if (channel.channel_name.includes("MAIN_CHANNEL")) {
              post["postFrom"] = "home";
            } else {
              post["postFrom"] = "channel";
            }
          });

          post["shouldOpen"] = false;
          post["images"].map((img) => {
            img["type"] = "images";
          });
          post["videos"].map((video) => {
            video["type"] = "videos";
          });
          post["video_links"].map((video) => {
            video["type"] = "video_link";
          });
          post["documents"].map((doc) => {
            doc["type"] = "documents";
          });
          post["totalMedia"] = post["images"].concat(post["videos"]);
          post["totalCombinedMedia"] = post["totalMedia"].concat(
            post["video_links"]
          );
          post["postAtMentions"] = [];
          post["editPost"] = {
            showEditPost: false,
            labels: { title: "", button: "" },
          };
          post["newComment"] = "";
          post["owner"] =
            post.userName == action.payload.userData.name ? true : false;
          let postCategory = post["category"];

          newState["categories"].map((category) => {
            if (
              category.category_name &&
              category.category_name.includes(postCategory)
            ) {
              post["category"] = category.icon;
            }
          });
        });
      return newState;
    }

    case SUBMIT_QUIZ: {
      let response = action.payload.quiz_details;
      response.map((x) => {
        let index = newState["searchedQuizzes"].findIndex((d) => d.id === x.id);
        if (index >= 0) {
          newState["searchedQuizzes"][index].quiz_details = x;
        }
      });
      return newState;
    }

    case SUBMIT_POLL: {
      let response = action.payload.poll_details;
      response.map((x) => {
        let index = newState["searchedPolls"].findIndex((d) => d.id === x.id);
        if (index >= 0) {
          newState["searchedPolls"][index].poll_details = x;
        }
      });
      return newState;
    }

    case SUBMIT_OPEN_POLL: {
      let response = action.payload.poll_details;
      response.map((x) => {
        let index = newState["searchedPolls"].findIndex((d) => d.id === x.id);
        if (index >= 0) {
          newState["searchedPolls"][
            index
          ].open_ended_question_details.poll_details = x;
        }
      });
      return newState;
    }

    case USER_EXPAND_TEXT: {
      let id = action.payload.id;
      if (action.payload.is_event === false) {
        let posts = newState["searchedPosts"].map((post) => {
          if (post.id == id) {
            return { ...post, expandText: action.payload.content };
          } else {
            return { ...post };
          }
        });
        newState["searchedPosts"] = posts;
      } else if (action.payload.is_event === true) {
        let posts = newState["searchedEvents"].map((post) => {
          if (post.id == id) {
            return { ...post, expandText: action.payload.content };
          } else {
            return { ...post };
          }
        });
        newState["searchedEvents"] = posts;
      }
      return newState;
    }

    case PLAY_VIDEO_BY_ID: {
      let postId = action.payload.postId;
      let videoId = action.payload.videoId;
      let media = action.payload.media;
      let previewOpen = action.payload.fromPreview;
      if (action.payload.is_event === false) {
        let postIndex = findIndex(newState["searchedPosts"], { id: postId });
        if (previewOpen == false) {
          if (postIndex >= 0) {
            let videoIndex = findIndex(
              newState["searchedPosts"][postIndex].totalCombinedMedia,
              { id: videoId }
            );
            if (videoIndex >= 0) {
              newState["searchedPosts"][postIndex].totalCombinedMedia =
                newState["searchedPosts"][postIndex].totalCombinedMedia.map(
                  (item) => ({ ...item, isPlaying: false })
                );
              let upcomingMedia = media;
              newState["searchedPosts"][postIndex].totalCombinedMedia[
                videoIndex
              ] = upcomingMedia;
            }
          }
        } else {
          if (postIndex >= 0) {
            if (media.type == "videos") {
              let videoIndex = findIndex(
                newState["searchedPosts"][postIndex].videos,
                {
                  id: videoId,
                }
              );
              if (videoIndex >= 0) {
                newState["searchedPosts"][postIndex].videos = newState[
                  "searchedPosts"
                ][postIndex].videos.map((item) => ({
                  ...item,
                  isPlaying: false,
                }));
                let upcomingMedia = media;
                newState["searchedPosts"][postIndex].videos[videoIndex] =
                  upcomingMedia;
                newState["searchedPosts"][postIndex].totalCombinedMedia =
                  newState["searchedPosts"][postIndex].totalCombinedMedia.map(
                    (item) => ({ ...item, isPlaying: false })
                  );
              }
            } else {
              let videoIndex = findIndex(
                newState["searchedPosts"][postIndex].video_links,
                { id: videoId }
              );
              if (videoIndex >= 0) {
                newState["searchedPosts"][postIndex].video_links = newState[
                  "searchedPosts"
                ][postIndex].video_links.map((item) => ({
                  ...item,
                  isPlaying: false,
                }));
                let upcomingMedia = media;
                newState["searchedPosts"][postIndex].video_links[videoIndex] =
                  upcomingMedia;
                newState["searchedPosts"][postIndex].totalCombinedMedia =
                  newState["searchedPosts"][postIndex].totalCombinedMedia.map(
                    (item) => ({ ...item, isPlaying: false })
                  );
              }
            }
          }
        }
      } else {
        let postIndex = findIndex(newState["searchedEvents"], { id: postId });
        if (previewOpen == false) {
          if (postIndex >= 0) {
            let videoIndex = findIndex(
              newState["searchedEvents"][postIndex].totalCombinedMedia,
              { id: videoId }
            );
            if (videoIndex >= 0) {
              newState["searchedEvents"][postIndex].totalCombinedMedia =
                newState["searchedEvents"][postIndex].totalCombinedMedia.map(
                  (item) => ({ ...item, isPlaying: false })
                );
              let upcomingMedia = media;
              newState["searchedEvents"][postIndex].totalCombinedMedia[
                videoIndex
              ] = upcomingMedia;
            }
          }
        } else {
          if (postIndex >= 0) {
            if (media.type == "videos") {
              let videoIndex = findIndex(
                newState["searchedEvents"][postIndex].videos,
                {
                  id: videoId,
                }
              );
              if (videoIndex >= 0) {
                newState["searchedEvents"][postIndex].videos = newState[
                  "searchedEvents"
                ][postIndex].videos.map((item) => ({
                  ...item,
                  isPlaying: false,
                }));
                let upcomingMedia = media;
                newState["searchedEvents"][postIndex].videos[videoIndex] =
                  upcomingMedia;
                newState["searchedEvents"][postIndex].totalCombinedMedia =
                  newState["searchedEvents"][postIndex].totalCombinedMedia.map(
                    (item) => ({ ...item, isPlaying: false })
                  );
              }
            } else {
              let videoIndex = findIndex(
                newState["searchedEvents"][postIndex].video_links,
                { id: videoId }
              );
              if (videoIndex >= 0) {
                newState["searchedEvents"][postIndex].video_links = newState[
                  "searchedEvents"
                ][postIndex].video_links.map((item) => ({
                  ...item,
                  isPlaying: false,
                }));
                let upcomingMedia = media;
                newState["searchedEvents"][postIndex].video_links[videoIndex] =
                  upcomingMedia;
                newState["searchedEvents"][postIndex].totalCombinedMedia =
                  newState["searchedEvents"][postIndex].totalCombinedMedia.map(
                    (item) => ({ ...item, isPlaying: false })
                  );
              }
            }
          }
        }
      }
      return newState;
    }

    case USER_EXPAND_COMMENT: {
      let id = action.payload.id;
      if (action.payload.is_event === false) {
        let posts = newState["searchedPosts"].map((post) => {
          if (post.id == id) {
            return { ...post, expandPostComment: action.payload.content };
          } else {
            return { ...post };
          }
        });
        newState["searchedPosts"] = posts;
      } else if (action.payload.is_event === true) {
        let posts = newState["searchedEvents"].map((post) => {
          if (post.id == id) {
            return { ...post, expandPostComment: action.payload.content };
          } else {
            return { ...post };
          }
        });
        newState["searchedEvents"] = posts;
      }
      return newState;
    }
    case USER_SHOW_COMMENT: {
      let id = action.payload.id;
      if (action.payload.is_event === false) {
        let posts = newState["searchedPosts"].map((post) => {
          if (post.id == id) {
            return { ...post, showComments: action.payload.content };
          } else {
            return { ...post };
          }
        });
        newState["searchedPosts"] = posts;
      } else if (action.payload.is_event === true) {
        let posts = newState["searchedEvents"].map((post) => {
          if (post.id == id) {
            return { ...post, showComments: action.payload.content };
          } else {
            return { ...post };
          }
        });
        newState["searchedEvents"] = posts;
      }
      return newState;
    }

    //hashtags from server
    case LIST_HASHTAG: {
      newState["postHashTags"] = action.payload;
      return newState;
    }
    //mentions for post
    case LIST_MENTIONS: {
      let postId = action.payload.id;
      let isEvent;
      let postIndex = findIndex(newState["searchedPosts"], { id: postId });
      if (postIndex >= 0) {
        isEvent = false;
      } else if (postIndex < 0) {
        postIndex = findIndex(newState["searchedEvents"], { id: postId });
        isEvent = true;
      }
      if (postIndex >= 0 && isEvent === false) {
        newState["searchedPosts"].map((post) => {
          if (post.id == action.payload.id) {
            post["postAtMentions"] = action.payload.response;
          }
        });
      } else if (postIndex >= 0 && isEvent === true) {
        newState["searchedEvents"].map((post) => {
          if (post.id == action.payload.id) {
            post["postAtMentions"] = action.payload.response;
          }
        });
      }
      return newState;
    }

    /* Hashtag added in comment */
    case POST_SHOW_HASHTAG: {
      let postId = action.payload.id;
      let isEvent;
      let postIndex = findIndex(newState["searchedPosts"], { id: postId });
      if (postIndex >= 0) {
        isEvent = false;
      } else if (postIndex < 0) {
        postIndex = findIndex(newState["searchedEvents"], { id: postId });
        isEvent = true;
      }
      if (postIndex >= 0 && isEvent === false) {
        newState["searchedPosts"].map((post) => {
          if (post.id == action.payload.id) {
            post["showHashTag"] = action.payload.showHashtag;
          }
        });
      } else if (postIndex >= 0 && isEvent === true) {
        newState["searchedEvents"].map((post) => {
          if (post.id == action.payload.id) {
            post["showHashTag"] = action.payload.showHashtag;
          }
        });
      }
      return newState;
    }
    case POST_EMOJI_SELECTED: {
      let postId = action.payload.id;
      let isEvent;
      let postIndex = findIndex(newState["searchedPosts"], { id: postId });
      if (postIndex >= 0) {
        isEvent = false;
      } else if (postIndex < 0) {
        postIndex = findIndex(newState["searchedEvents"], { id: postId });
        isEvent = true;
      }
      if (postIndex >= 0 && isEvent === false) {
        newState["searchedPosts"].map((post) => {
          if (post.id == action.payload.id) {
            post["newComment"] = post["newComment"].substr(
              0,
              post["newComment"].length
            );
            post["newComment"] =
              post["newComment"] + action.payload.selectedEmoji;
          }
        });
      } else if (postIndex >= 0 && isEvent === true) {
        newState["searchedEvents"].map((post) => {
          if (post.id == action.payload.id) {
            post["newComment"] = post["newComment"].substr(
              0,
              post["newComment"].length
            );
            post["newComment"] =
              post["newComment"] + action.payload.selectedEmoji;
          }
        });
      }
      return newState;
    }
    case POST_HASHTAG_SELECTED: {
      let postId = action.payload.id;
      let isEvent;
      let postIndex = findIndex(newState["searchedPosts"], { id: postId });
      if (postIndex >= 0) {
        isEvent = false;
      } else if (postIndex < 0) {
        postIndex = findIndex(newState["searchedEvents"], { id: postId });
        isEvent = true;
      }
      if (postIndex >= 0 && isEvent === false) {
        newState["searchedPosts"].map((post) => {
          if (post.id == action.payload.id) {
            post["newComment"] = post["newComment"].substr(
              0,
              post["newComment"].length - 1
            );
            post["newComment"] =
              post["newComment"] + action.payload.selectedHashTag;
          }
        });
      } else if (postIndex >= 0 && isEvent === true) {
        newState["searchedEvents"].map((post) => {
          if (post.id == action.payload.id) {
            post["newComment"] = post["newComment"].substr(
              0,
              post["newComment"].length - 1
            );
            post["newComment"] =
              post["newComment"] + action.payload.selectedHashTag;
          }
        });
      }
      return newState;
    }
    /* comment update */
    case POST_NEW_COMMENT: {
      let postId = action.payload.id;
      let isEvent;
      let postIndex = findIndex(newState["searchedPosts"], { id: postId });
      if (postIndex >= 0) {
        isEvent = false;
      } else if (postIndex < 0) {
        postIndex = findIndex(newState["searchedEvents"], { id: postId });
        isEvent = true;
      }
      if (postIndex >= 0 && isEvent === false) {
        newState["searchedPosts"].map((post) => {
          if (post.id == action.payload.id) {
            post["newComment"] = action.payload.comment;
          }
        });
      } else if (postIndex >= 0 && isEvent === true) {
        newState["searchedEvents"].map((post) => {
          if (post.id == action.payload.id) {
            post["newComment"] = action.payload.comment;
          }
        });
      }
      return newState;
    }
    case SUBMIT_NEW_COMMENT: {
      let data = action.payload.response;
      let userData = action.payload.userData;
      let postId = data.post_id;
      let isEvent;
      let postIndex = findIndex(newState["searchedPosts"], { id: postId });
      if (postIndex >= 0) {
        isEvent = false;
      } else if (postIndex < 0) {
        postIndex = findIndex(newState["searchedEvents"], { id: postId });
        isEvent = true;
      }
      if (postIndex >= 0 && isEvent === false) {
        newState["searchedPosts"].map((post) => {
          if (post.id == data.post_id) {
            let content = data.content;
            let commentedOn = data.created_on;
            let newComment = {
              first_name: data.first_name,
              last_name: data.last_name,
              // userName: userData.name,
              organisationName: userData.organization_name,
              designation: data.user_designation,
              comment: content,
              profileIcon: data.user_profile_pic,
              commentedOn: commentedOn,
              comment_id: data.id,
              user_id: userData.profile_id,
            };
            if (post.comments == null) {
              post.comments = [];
            }
            post.comments.push(newComment);
            post.newComment = "";
            // post.comments++
          }
        });
      } else if (postIndex >= 0 && isEvent === true) {
        newState["searchedEvents"].map((post) => {
          if (post.id == data.post_id) {
            let content = data.content;
            let commentedOn = data.created_on;
            let newComment = {
              first_name: data.first_name,
              last_name: data.last_name,
              // userName: userData.name,
              organisationName: userData.organization_name,
              designation: data.user_designation,
              comment: content,
              profileIcon: data.user_profile_pic,
              commentedOn: commentedOn,
              comment_id: data.id,
              user_id: userData.profile_id,
            };
            if (post.comments == null) {
              post.comments = [];
            }
            post.comments.push(newComment);
            post.newComment = "";
            // post.comments++
          }
        });
      }
      return newState;
    }
    /* TagPeople in Comment */
    case POST_SHOW_TAGPEOPLE: {
      let postId = action.payload.id;
      let isEvent;
      let postIndex = findIndex(newState["searchedPosts"], { id: postId });
      if (postIndex >= 0) {
        isEvent = false;
      } else if (postIndex < 0) {
        postIndex = findIndex(newState["searchedEvents"], { id: postId });
        isEvent = true;
      }
      if (postIndex >= 0 && isEvent === false) {
        newState["searchedPosts"].map((post) => {
          if (post.id == action.payload.id) {
            post["showTagPeople"] = action.payload.showTagPeople;
          }
        });
      } else if (postIndex >= 0 && isEvent === false) {
        newState["searchedEvents"].map((post) => {
          if (post.id == action.payload.id) {
            post["showTagPeople"] = action.payload.showTagPeople;
          }
        });
      }
      return newState;
    }
    case POST_SHOW_EMOJI: {
      let postId = action.payload.id;
      let isEvent;
      let postIndex = findIndex(newState["searchedPosts"], { id: postId });
      if (postIndex >= 0) {
        isEvent = false;
      } else if (postIndex < 0) {
        postIndex = findIndex(newState["searchedEvents"], { id: postId });
        isEvent = true;
      }
      if (postIndex >= 0 && isEvent === false) {
        newState["searchedPosts"].map((post) => {
          if (post.id == action.payload.id) {
            post["showEmoji"] = action.payload.showEmoji;
          }
        });
      } else if (postIndex >= 0 && isEvent === true) {
        newState["searchedEvents"].map((post) => {
          if (post.id == action.payload.id) {
            post["showEmoji"] = action.payload.showEmoji;
          }
        });
      }
      return newState;
    }

    case POST_TAGPEOPLE_SELECTED: {
      let postId = action.payload.id;
      let isEvent;
      let postIndex = findIndex(newState["searchedPosts"], { id: postId });
      if (postIndex >= 0) {
        isEvent = false;
      } else if (postIndex < 0) {
        postIndex = findIndex(newState["searchedEvents"], { id: postId });
        isEvent = true;
      }
      if (postIndex >= 0 && isEvent === false) {
        newState["searchedPosts"].map((post) => {
          if (post.id == action.payload.id) {
            post["newComment"] = post["newComment"].substr(
              0,
              post["newComment"].length
            );
            post["newComment"] =
              post["newComment"] +
              action.payload.selectedAtPeople.mentions.name;
          }
        });
      } else if (postIndex >= 0 && isEvent === true) {
        newState["searchedEvents"].map((post) => {
          if (post.id == action.payload.id) {
            post["newComment"] = post["newComment"].substr(
              0,
              post["newComment"].length
            );
            post["newComment"] =
              post["newComment"] +
              action.payload.selectedAtPeople.mentions.name;
          }
        });
      }
      return newState;
    }

    /* Show - Media View for Post */
    case SHOW_POST_MEDIA_PREVIEW: {
      let id = action.payload.id;
      if (action.payload.is_event === false) {
        newState["searchedPosts"].map((post) => {
          if (post.id == action.payload.id) {
            post["mediaView"] = action.payload.showPreview;
          }
        });
      } else if (action.payload.is_event === true) {
        newState["searchedEvents"].map((post) => {
          if (post.id == action.payload.id) {
            post["mediaView"] = action.payload.showPreview;
          }
        });
      }
      return newState;
    }

    case LIKE_DISLIKE_POSTS: {
      let postId = action.payload.id;
      let isEvent;
      let postIndex = findIndex(newState["searchedPosts"], { id: postId });
      if (postIndex >= 0) {
        isEvent = false;
      } else if (postIndex < 0) {
        postIndex = findIndex(newState["searchedEvents"], { id: postId });
        isEvent = true;
      }
      if (postIndex >= 0 && isEvent === false) {
        newState["searchedPosts"][postIndex].liked = action.payload.is_liked;
        newState["searchedPosts"][postIndex].likes = action.payload.like_count;
      } else if (postIndex >= 0 && isEvent === true) {
        newState["searchedEvents"][postIndex].liked = action.payload.is_liked;
        newState["searchedEvents"][postIndex].likes = action.payload.like_count;
      }
      return newState;
    }

    case CHANGE_LIKE_STATUS_OF_POST: {
      let postId = action.payload.id;
      let isEvent;
      let postIndex = findIndex(newState["searchedPosts"], { id: postId });
      if (postIndex >= 0) {
        isEvent = false;
      } else if (postIndex < 0) {
        postIndex = findIndex(newState["searchedEvents"], { id: postId });
        isEvent = true;
      }
      if (postIndex >= 0 && isEvent === false) {
        newState["searchedPosts"][postIndex].liked = action.payload.isLiked;
      } else if (postIndex >= 0 && isEvent === true) {
        newState["searchedEvents"][postIndex].liked = action.payload.isLiked;
      }
      return newState;
    }

    case CHANGE_LIKE_BUTTON_STATE: {
      let postId = action.payload.id;
      let isEvent;
      let postIndex = findIndex(newState["searchedPosts"], { id: postId });
      if (postIndex >= 0) {
        isEvent = false;
      } else if (postIndex < 0) {
        postIndex = findIndex(newState["searchedEvents"], { id: postId });
        isEvent = true;
      }
      if (postIndex >= 0 && isEvent === false) {
        newState["searchedPosts"][postIndex].likeButtonDisabled =
          action.payload.isDisabled;
      } else if (postIndex >= 0 && isEvent === true) {
        newState["searchedEvents"][postIndex].likeButtonDisabled =
          action.payload.isDisabled;
      }

      return newState;
    }

    case GET_EVENTS_BY_SEARCH: {
      newState["eventsCount"] = action.payload.response.count
      newState["nextEvents"] = action.payload.response.next;
      if (action.payload.page == 1) {
        newState["searchedEvents"] = action.payload.response.results;
      } else {
        newState["searchedEvents"] = newState["searchedEvents"].concat(
          action.payload.response.results
        );
      }
      newState["searchedEvents"] &&
        newState["searchedEvents"].map((post) => {
          post["userName"] = post["firstName"] + " " + post["lastName"];
          post["expandText"] = false;
          post["expandPostComment"] = false;
          post["showComments"] = false;
          post["showHashTag"] = false;
          post["showTagPeople"] = false;
          post["showEmoji"] = false;
          post["mediaView"] = false;
          post["likeButtonDisabled"] = false;
          let start = new Date(post["event_start_date"]);
          let end = new Date(post["event_end_date"]);
          function ChangeDate(date) {
            var d = new Date(date),
              month = "" + (d.getMonth() + 1),
              day = "" + d.getDate(),
              year = d.getFullYear();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            return [year, month, day].join("-");
          }
          function ChangeTime(date) {
            return (
              ("00" + date.getHours()).slice(-2) +
              ":" +
              ("00" + date.getMinutes()).slice(-2) +
              ":" +
              ("00" + date.getSeconds()).slice(-2)
            );
          }
          function showTimeZone(date) {
            const dateAsString = date.toString();
            const timezone = dateAsString.match(/\(([^\)]+)\)$/)[1];
            return timezone;
          }

          post["timeZone"] = showTimeZone(start);
          post["event_start"] = ChangeDate(start);
          post["event_end"] = ChangeDate(end);
          post["event_start_time"] = ChangeTime(start);
          post["event_end_time"] = ChangeTime(end);
          post["showMore"] = false;

          post["channel_details"].map((channel) => {
            if (channel.channel_name.includes("MAIN_CHANNEL")) {
              post["postFrom"] = "home";
            } else {
              post["postFrom"] = "channel";
            }
          });

          post["shouldOpen"] = false;
          post["images"].map((img) => {
            img["type"] = "images";
            img["isPlaying"] = false;
          });
          post["videos"].map((video) => {
            video["type"] = "videos";
            video["isPlaying"] = false;
          });
          post["video_links"].map((video) => {
            video["type"] = "video_link";
            video["isPlaying"] = false;
          });
          post["documents"].map((doc) => {
            doc["type"] = "documents";
          });
          post["totalMedia"] = post["images"].concat(post["videos"]);
          post["totalCombinedMedia"] = post["totalMedia"].concat(
            post["video_links"]
          );
          post["postAtMentions"] = [];
          post["editPost"] = {
            showEditPost: false,
            labels: { title: "", button: "" },
          };
          post["newComment"] = "";
          post["owner"] =
            post.userName == action.payload.userData.name ? true : false;
          let postCategory = post["category"];

          newState["categories"].map((category) => {
            if (
              category.category_name &&
              category.category_name.includes(postCategory)
            ) {
              post["category"] = category.icon;
            }
          });
        });
      return newState;
    }
    case GET_NETWORKS_BY_SEARCH: {
      newState["networksCount"] = action.payload.response.count;
      newState["nextNetworks"] = action.payload.response.next;
      if (action.payload.limit == 0) {
        newState["searchedNetworks"] = action.payload.response.results;
      } else {
        newState["searchedNetworks"] = newState["searchedNetworks"].concat(
          action.payload.response.results
        );
      }

      return newState;
    }

    case AUTH_LOGOUT: {
      return { ...initialState };
    }

    case RESET: {
      return initialState;
    }
    default: {
      return newState;
    }
  }
}
