import React from "react";
import {
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT,
  RESET,
  SHOW_MESSAGE,
  AUTH_LOGGEDIN_USER_ROLE_PERMISSIONS,
  ENTITY_LIST_SUCCESS,
  AUTH_MULTIPLE_PROFILE,
  GET_ROLES_BASED_ON_ENTITY,
  GET_COMMON_ADMIN_PANEL_PERMISSIONS,
  GET_COMMON_GLOBAL_PERMISSIONS,
} from "../constants";
import { GET_PERMISSION_DATA } from "../constants/UserPlatform/EMGroupConstant";
import API from "../../api";
import EMStorage from "../../utils/EMStorage";
import { EMDoGetAllInformationAboutUserAction } from "./EMCommonActions";
import {
  CHANGE_ELEMENT_FOR_SUBTABS,
  FIRST_TIME_LOG_IN,
} from "../constants/UserPlatform/EMNavigationConstant";
import { EMDoGetLeaderBoardAction } from "./UserPlatform/Home/EMLatestPostPageAction";
import { handleError } from "../../utils/EMCommonFunctions";
export const EMDoLoginAction = (action) => {
  return (dispatch) => {
    API.EMDoLoginAPI(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          localStorage.setItem("userData", JSON.stringify(response));
          EMStorage.storeUserData(response);
          dispatch({
            type: AUTH_LOGIN_SUCCESS,
            payload: response,
          });
          dispatch({
            type: FIRST_TIME_LOG_IN,
            payload: true,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to login",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

export const EMLogoutAction = () => {
  return (dispatch) => {
    localStorage.clear();
    EMStorage.storeUserData({});
    dispatch({ type: AUTH_LOGOUT });
    dispatch({ type: RESET });
  };
};

//User Role Information - GET request
export const EMDoGetLoggedInUserRolesAction = (action) => {
  return (dispatch) => {
    API.EMDoGetLoggedInUserRoles(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: AUTH_LOGGEDIN_USER_ROLE_PERMISSIONS,
            payload: response,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to get user role information",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetEntityInformationBasedOnPermissionAction = (action) => {
  return (dispatch) => {
    API.EMDoGetEntityInformationBasedOnPermission(action)

      .then((data) => data.data)
      .then((data) => {
        if (data) {
          dispatch({
            type: ENTITY_LIST_SUCCESS,
            payload: data,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching entity list",
              key: action.key,
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};
// Link Email Action
export const EMDoLinkEmailToPrimaryAccountAction = (action) => {
  return (dispatch) => {
    API.EMDoLinkEmailToPrimaryAccount(action)
      .then((data) => data.data)
      .then((data) => {
        if (data) {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message:
                "Kindly check your notifications and confirm the linking process",
            },
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while linking email",
              key: action.key,
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

// UnLink Email Action
export const EMDoUnLinkEmailToPrimaryAccountAction = (action) => {
  return (dispatch) => {
    API.EMDoUnLinkEmailToPrimaryAccount(action)
      .then((data) => data.data)
      .then((data) => {
        if (data) {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Unlink Successful",
            },
          });
          dispatch(EMDoGetAllInformationAboutUserAction({ id: action.id }));
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching entity list",
              key: action.key,
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};
//get multiple Profile details
export const EMDoGetMultipleProfileDetailsAction = (action) => {
  return (dispatch) => {
    API.EMDoGetMultipleProfileDetails(action)
      .then((data) => data.data)
      .then((data) => {
        if (data) {
          dispatch({
            type: AUTH_MULTIPLE_PROFILE,
            payload: data,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching entity list",
              key: action.key,
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

//Switch profile
export const EMDoSwitchProfileAction = (action) => {
  return (dispatch) => {
    API.EMDoSwitchProfile(action.userid)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          localStorage.setItem("userData", JSON.stringify(response));
          EMStorage.storeUserData(response);
          dispatch({
            type: AUTH_LOGIN_SUCCESS,
            payload: response,
          });
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Profile Switched",
              key: action.key,
            },
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to login",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

//Verify Email
export const EMDoVerifyLinkEmailAction = (action) => {
  return (dispatch) => {
    API.EMDoVerifyLinkEmail(action.data)
      .then((data) => data.data)
      .then((data) => {
        if (data) {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Your Account is successfully Linked",
            },
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching entity list",
              key: action.key,
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

//Entity based permissions
export const EMGetRolesAndPermissionsBasedOnEntityAction = (action) => {
  return (dispatch) => {
    API.EMGetRolesAndPermissionsBasedOnEntity(action.entity, action.entityId)
      .then((data) => data.data)
      .then((data) => {
        if (data) {
          dispatch({
            type: GET_ROLES_BASED_ON_ENTITY,
            payload: data,
          });

          let isGroup =
            data &&
            data.find(
              (perm) => perm.permission_name == "create_posts_in_group"
            );
          let requiredData =
            data &&
            data.find(
              (perm) => perm.permission_name == "manage_joining_requests"
            );
          if (
            requiredData &&
            requiredData.view_flag == false &&
            isGroup !== undefined
          ) {
            dispatch({
              type: CHANGE_ELEMENT_FOR_SUBTABS,
              payload: {
                path: "/users/groups",
                element: "groups/",
                remove: "Settings",
              },
            });
          } else if (
            requiredData &&
            requiredData.view_flag == false &&
            isGroup == undefined
          ) {
            dispatch({
              type: CHANGE_ELEMENT_FOR_SUBTABS,
              payload: {
                path: "/users/channels",
                element: "channels/",
                remove: "Settings",
              },
            });
          }
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching entity list",
              key: "entity_list_error",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetCommonAdminPanelPermissionsAction = (action) => {
  return (dispatch) => {
    API.EMDoGetCommonAdminPanelPermissions(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: GET_COMMON_ADMIN_PANEL_PERMISSIONS,
            payload: response,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to get user role information",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetCommonGlobalPermissionsAction = (action) => {
  return (dispatch) => {
    API.EMDoGetCommonGlobalPermissions(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: GET_COMMON_GLOBAL_PERMISSIONS,
            payload: response,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to get user role information",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};
