import React, { useState, useEffect, useRef } from "react";
import EMPage from "../../../components/commans/EMPage";
import { withStyles } from "@material-ui/core/styles";
import { Paper, Box, Button, makeStyles, Grid, Tooltip } from "@material-ui/core";
import {
  Chart,
  BarSeries,
  Title,
  ArgumentAxis,
  ValueAxis,
  Legend,
} from "@devexpress/dx-react-chart-material-ui";
import { Animation, Stack,ArgumentScale, ValueScale } from "@devexpress/dx-react-chart";
import { scaleBand, scaleLinear } from "@devexpress/dx-chart-core";
import { Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  Title: {
    textAlign: "center",
    fontWeight: "bolder",
    fontSize: "large",
  },
  circle: {
    width: "100px",
    height: "100px",
    lineHeight: "100px",
    borderRadius: "50%",
    fontSize: "20px",
    color: "#fff",
    textAlign: "center",
    background: "#000080",
  },
}));

export default function EMUsageReport(props) {
  const classes = useStyles(); 
  const adminData = useSelector((state) => state.admin || {});
  const [usageData, setUsageData] = useState([]);
  const [domain, setDomain] = useState(() => () => [0,10])

  const scale = scaleLinear();

  useEffect(() => {
    if(adminData.usageData){
      const result = Object.entries(groupBy(
        adminData.usageData[0].usage_report_data, "date"))
      .map(([month, items]) => {
        const sum = { month, users: 0, };
        for (const item of items) {
          sum.users  += item.users;
        }
        return sum;
      });
    
    
    function groupBy(objectArray, property) {
       return objectArray.reduce((acc, obj) => {
          const key = obj[property];
          if (!acc[key]) {
             acc[key] = [];
          }

          acc[key].push(obj);
          return acc;
       }, {});
    }
    const maxUser = adminData.usageData[0].usage_report_data.reduce((p, c) => p.users > c.users ? p : c);
    if(maxUser){
      let value = maxUser.users
      value < 10 ? setDomain(() => () => [0,10]) : setDomain(() => () => [0,value + 2])
    }
    setUsageData(result);
    }
  },[adminData])

  const ArgumentLabel = (props, index) => {
      
    const { text } = props

    props = {
      ...props,
      text:text.slice(0,3)
    }
    return (<>
  <Tooltip title={<h2 style={{ color: "white" }}>{text}</h2>}>
    <ArgumentAxis.Label {...props} />
    </Tooltip>         
    </>);
  };

  return (
    <EMPage title="Usage Report">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <Paper elevation={2}>
            <Typography
              style={{
                fontWeight: "bolder",
                fontSize: "large",
                paddingLeft: "4%",
                paddingTop: "4%",
              }}
            >
              Usage Report
            </Typography>
            <Typography style={{ paddingLeft: "4%", paddingTop: "2%" }}>
              Maximum user accounts in selected period :{" "}
              <strong>
                {adminData.usageData &&
                  adminData.usageData[0].max_user_acc_in_selected_period}
              </strong>
            </Typography>
            {adminData.usageData &&
            adminData.usageData[0].usage_report_data.length > 0 ? (
              <>
                <Typography
                  style={{
                    transform: "rotate(-90deg)",
                    float: "left",
                    fontWeight: "bolder",
                    fontSize: "large",
                    marginTop: "10rem",
                    left: "18%",
                  }}
                >
                  Total users
                </Typography>
                <div style={{ marginLeft: "10%" }}>
                  <Chart
                    data={
                     usageData
                    }
                    style={{ marginLeft: "10%", maxWidth: "70%" }}
                  >
                  <ValueScale modifyDomain={domain} />
                    <ArgumentAxis showTicks={true} labelComponent={ArgumentLabel} />
                    <ValueAxis showTicks={true} />

                    <BarSeries valueField="users" argumentField="month" />
                    <Title text="" />
                    <Animation />
                  </Chart>
                </div>
                <Typography
                  style={{
                    textAlign: "center",
                    fontWeight: "bolder",
                    fontSize: "large",
                  }}
                >
                  Month
                </Typography>
              </>
            ) : null}
          </Paper>
        </Grid>
      </Grid>
    </EMPage>
  );
}
