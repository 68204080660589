import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  formControl: {
    //margin: theme.spacing(1),
    width:"100%"
    // [theme.breakpoints.up("xs")]:{
    //   width:"50px"
    // },
    // [theme.breakpoints.up("sm")]:{
    //   width:"100px"
    // },
    // [theme.breakpoints.up("md")]:{
    //   width:"150px"
    // },
    // [theme.breakpoints.up("lg")]:{
    //   width:"200px"
    // },
    // [theme.breakpoints.up("xl")]:{
    //   width:"250px"
    // }
  },
  noLabel: {
    marginTop: theme.spacing(3)
  },
  label: {
    "&:focus": {
      color: theme.palette.common.black
    }
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null
};

export default function EMMultiSelect({
  label,
  data,
  objKey,
  prevData,
  onChange,
  formik,
  objId, required
}) {
  const classes = useStyles();
  const [selectedData, setSelectedData] = React.useState([]);

  React.useEffect(() => { 
    setSelectedData(prevData);
  }, [prevData]);

  const handleChange = event => {
    setSelectedData(event.target.value);
    onChange(event.target.value);
  };

  return (
    <div>
      <FormControl required={required && required} className={classes.formControl}>
        <InputLabel id="demo-mutiple-checkbox-label" style={{color:"#4d4d4d"}} className={classes.label}>
          {label}
        </InputLabel>
        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          value={selectedData || []}
          onChange={handleChange}
          input={<Input style={{maxWidth:"280px"}} />}
          renderValue={selected =>
            selected.join(", ") 
          }
          MenuProps={MenuProps}
          // error={formik.errors && formik.touched ? true : false}
          helperText={formik.touched && formik.errors}
          InputLabelProps={{style:{color:"#4d4d4d"}}}
          FormHelperTextProps={{style:{color:"red"}}}
          fullWidth
        >
          {data.map(d => {
            
            let dataToShow = objId ? d[objKey]+" |"+d[objId] :d[objKey] ;
            return (
              <MenuItem key={dataToShow} value={dataToShow}>
                <Checkbox checked={selectedData && selectedData.indexOf(dataToShow) > -1} />
                <Tooltip title={<h2 style={{ color: "white" }}>{dataToShow}</h2>} placement="top">
                <ListItemText style={{
                      display: "-webkit-box",
                      boxOrient: "vertical",
                      lineClamp: 1,
                      wordBreak: "break-word",
                      overflow: "hidden",
                    }} primary={dataToShow} />
                </Tooltip>
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText style = {{color:'red'}}>
          {formik.errors && formik.touched ? formik.errors : ""}
        </FormHelperText>
      </FormControl>
    </div>
  );
}
