import EMDropdown from "../../../../components/commans/EMDropdown";
import { useSelector } from "react-redux";
import { find, findIndex } from "lodash";
import { useEffect, useState } from "react";

export default function EMEntityDropdownForDep({ onSelect, error, helperText, errorText, onlyOrganizationsNeeded }) {
  const [dataForDropdown, setDataForDropDown] = useState([])
  const data = useSelector(state => state.networkAdmin["networkListForUserDropdown"]);
  
  useEffect(() => {
      let requiredData = data && data.filter((entity) => entity.entity_type == "organization")
      setDataForDropDown(requiredData)
  },[data])

  return (
    <>
      <EMDropdown
        label="Select Network"
        data={dataForDropdown || []}
        objKey={"entity_name"}
        onChange={selected => {
          onSelect(find(data, { entity_name: selected }));
        }}
        prevData={[]}
        error={error}
        helperText={helperText}
      />
      <p style={{ color: "red" }} >{errorText}</p>
    </>
  );
}
