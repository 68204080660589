import axios from "axios";

const EMDoGetCountryAndRegionList = () => {
  let url = "/commans/";
  return axios.get(url);
};

const EMDoGetRoles = () => {
  let url = "/commans/listRoles/";
  return axios.get(url);
};

//get notifications
const EMDoGetNotifications = (action) => {
  let url = `/notifications/?limit=${action.limit}&offset=${action.offset}`;
  return axios.get(url);
};

//seen notification
const EMDoSeenNotification = (action) => {
  let url = `notifications/${action.id}/action:seen/`;
  const fileData = new FormData();
  fileData.append("is_seen", true);
  if (action.key && action.key == "cancel_linking_request") {
    fileData.append("key", action.key);
  }
  return axios.patch(url, fileData, {
    body: {
      "Content-Type": "multipart/form-data",
    },
  });
};

//services related to Platform admin
const EMDoGetEntityForRoleAndPermission = () => {
  let url = "/commans/entitiesForRolesPermissions/";
  return axios.get(url);
};

const EMDoGetPrivacy = () => {
  let url = "/commans/privacy/";
  return axios.get(url);
};

const EMDoGetEntityRoleList = (queryData) => {
  let url = "";
  if (queryData) {
    url = `/commans/roles/${queryData}`;
  } else {
    url = `/commans/roles/`;
  }
  return axios.get(url);
};

const EMDoUpdateEntityRole = ({ roleData, contextData }) => {
  let url = `/commans/roles/${roleData.id}/?context_type=${contextData.contextType}&context=${contextData.context}`;
  return axios.put(url, { ...roleData });
};

const EMDoCreateEntityRole = ({ roleData, contextData }) => {
  let url = `/commans/roles/?context_type=${contextData.contextType}&context=${contextData.context}`;
  return axios.post(url, { ...roleData });
};

const EMDogetUserData = (queryData) => {
  let url = "";
  if (queryData) {
    url = `/invitations/${queryData}`;
  } else {
    url = `/invitations`;
  }
  return axios.get(url);
};

const EMDoAcceptPlatformAdminInvite = ({ userData, queryData }) => {
  let url = "";
  if (queryData) {
    url = `/invitations/accept-invite-platform/${queryData}`;
  } else {
    url = `/invitations/accept-invite-platform`;
  }
  return axios.post(url, { ...userData });
};

const EMDoCreateUser = ({ userData, queryData }) => {
  let url = "";
  if (queryData) {
    url = `/invitations/accept-invite/${queryData}`;
  } else {
    url = `/invitations/accept-invite/`;
  }
  const fileData = new FormData();

  if (
    userData.hasOwnProperty("file") &&
    userData.file !== undefined &&
    userData.file !== null
  ) {
    fileData.append("profileIcon", userData.file);
  } else {
    fileData.append("profileIcon", "");
  }
  fileData.append("first_name", userData.first_name);
  fileData.append("last_name", userData.last_name);
  fileData.append("professional_title", userData.professional_title);
  fileData.append("pronouns", userData.pronouns);
  fileData.append("organization", userData.organization);
  fileData.append("department", userData.department);
  fileData.append("organization_type_field", userData.organization_type_field);
  fileData.append("organization_name", userData.organization_name);
  fileData.append("organization_level", userData.level);
  fileData.append("linkedin_profile_url", userData.linkedin_profile_url);
  fileData.append("about_me", userData.about_me);
  fileData.append("gender_identity", userData.gender);
  fileData.append("leadership_roles", userData.leadership_roles);
  fileData.append("race", userData.race);
  fileData.append("ethnicity", userData.ethnicity);
  fileData.append(
    "other_minority_identification",
    userData.other_minority_identification
  );
  fileData.append("date_of_birth", userData.date_of_birth);
  fileData.append("phone_country_code", userData.country_code);
  fileData.append("phone", userData.phone);
  fileData.append("phone_type", userData.phone_type);
  fileData.append("work_type", userData.work_type);
  fileData.append("email", userData.email);
  fileData.append("address", userData.address);
  fileData.append("city", userData.city);
  fileData.append("country", userData.country);
  fileData.append("state", userData.state);
  fileData.append("zip_code", userData.zip_code);
  fileData.append("key", userData.key);
  fileData.append("role", userData.role);
  fileData.append("oranizaion_id", userData.oranizaion_id);
  fileData.append("password", userData.password);
  fileData.append("confirm_password", userData.confirm_password);

  // return axios.post(url, { ...userData });
  return axios.post(url, fileData, {
    body: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const EMDoGetPasswordResetLink = ({ userData }) => {
  let url = `/commans/forgot_password/`;
  return axios.post(url, { ...userData });
};

const EMDoResetPassword = ({ userData, queryData }) => {
  let url = "";
  if (queryData) {
    url = `/commans/new_password/${queryData}`;
  } else {
    url = `/commans/new_password/`;
  }
  return axios.post(url, { ...userData });
};

const EMDoCheckPasswordResetLinkExpiry = (queryData) => {
  let url = "";
  if (queryData) {
    url = `/commans/new_password/${queryData}`;
  } else {
    url = `/commans/new_password/`;
  }
  return axios.get(url);
};

/* API For Roles And Permission */

const EMDoGetPermissionForEntity = (action) => {
  let url = `/commans/permissions/${action}`;
  return axios.get(url);
};

/* API for user information */
const EMDoGetAllInformationAboutUser = (id) => {
  let url = `/user/profile/${id}/`;
  return axios.get(url);
};
/* API for updating user information */
const EMDoUpdateInformationAboutUser = (userData, id) => {
  let url = `/user/profile/${id}/`;
  const fileData = new FormData();
  if (
    userData.hasOwnProperty("file") &&
    userData.file !== undefined &&
    userData.file !== null
  ) {
    fileData.append("profile_pic_file", userData.file);
  }
  fileData.append("profile_summary", userData.about_me);
  fileData.append("address", userData.address);
  fileData.append("city", userData.city);
  fileData.append("birthdate", userData.date_of_birth);
  fileData.append("department", userData.department);
  fileData.append("email", userData.email);
  fileData.append("ethnicity", userData.ethnicity);
  fileData.append("first_name", userData.first_name);
  fileData.append("last_name", userData.last_name);
  fileData.append("leadership_roles", userData.leadership_roles);
  fileData.append("linkedin_profile_link", userData.linkedin_profile_url);
  fileData.append("organization", userData.organization);
  fileData.append("phone", userData.phone);
  fileData.append("professional_title", userData.professional_title);
  fileData.append("pronouns", userData.pronouns);
  fileData.append("race", userData.race);
  fileData.append("state", userData.state);
  fileData.append("zip_code", userData.zip_code);
  fileData.append("key", userData.key);
  fileData.append("role", userData.role);
  fileData.append("oranizaion_id", userData.oranizaion_id);
  fileData.append("password", userData.password);
  fileData.append("confirm_password", userData.confirm_password);
  fileData.append("country", userData.country);
  fileData.append("organization_type_field", userData.organization_type_field);
  fileData.append("organization_name", userData.organization_name);
  fileData.append("organization_level", userData.level);
  fileData.append("gender_identity", userData.gender);
  fileData.append(
    "other_minority_identification",
    userData.other_minority_identification
  );
  fileData.append("phone_country_code", userData.country_code);
  fileData.append("phone_type", userData.phone_type);
  fileData.append("work_type", userData.work_type);

  // return axios.post(url, { ...userData });
  return axios.patch(url, fileData, {
    body: {
      "Content-Type": "multipart/form-data",
    },
  });
};
/* API for searched events */
const EMDoGetEventsBySearchKey = (action) => {
  let url = `/posts/search-events/?search=${action.searchText}&page=${action.limit}`;
  return axios.get(url);
};
/* API for searched networks */
const EMDoGetPostsBySearchKey = (action) => {
  let url = `/posts/search-posts/?search=${action.searchText}&page=${action.limit}`;
  return axios.get(url);
};


//API for searched polls
const EMDoGetPollsBySearchKey = (action) => {
  let url = `/posts/search-polls/?search=${action.searchText}&page=${action.limit}`;
  return axios.get(url);
};

//API for searched quizzes
const EMDoGetQuizzesBySearchKey = (action) => {
  let url = `/posts/search-quizzes/?search=${action.searchText}&page=${action.limit}`;
  return axios.get(url);
};

//API for searched quizzes
const EMDoGetGroupsBySearchKey = (action) => {
  let url = `/user/groups-with-pagination/?search=${action.searchText}&limit=10&offset=${action.limit}`;
  return axios.get(url);
};

//API for searched quizzes
const EMDoGetChannelsBySearchKey = (action) => {
  let url = `/user/channels/browse-with-pagination/?search=${action.searchText}&limit=10&offset=${action.limit}`;
  return axios.get(url);
};

//API for searched quizzes
const EMDoGetNetworksBySearchKey = (action) => {
  let url = `/user/partnerships-with-pagination/?search=${action.searchText}&limit=10&offset=${action.limit}`;
  return axios.get(url);
};


const EMDoGetPostByNotification = (postId) => {
  let url = `/posts/get-post/${postId}/`;
  return axios.get(url);
};

//Get organization types list
const EMDoGetOrganizationType = (orgId) => {
  let url = `organization/get-org-type/${orgId}/`;
  return axios.get(url);
};

//Get organization type fields list
const EMDoGetOrganizationTypeField = (orgId) => {
  let url = `organization/get-org-type-fields/${orgId}/`;
  return axios.get(url);
};

//Get organization levels list
const EMDoGetOrganizationLevels = (orgId) => {
  let url = `organization/get-org-levels/${orgId}/`;
  return axios.get(url);
};

//Get organization departments list
const EMDoGetOrganizationDepartments = (orgId) => {
  let url = `organization/get-org-depts/${orgId}/`;
  return axios.get(url);
};

//Get pronouns list
const EMDoGetPronounsList = () => {
  let url = `commans/list-pronouns/`;
  return axios.get(url);
};

//Get gender list
const EMDoGetGenderList = () => {
  let url = `commans/list-gender-identity/`;
  return axios.get(url);
};

//Get race list
const EMDoGetRaceList = () => {
  let url = `commans/list-race/`;
  return axios.get(url);
};

//Get minority list
const EMDoGetMinorityList = () => {
  let url = `commans/list-minority-identification/`;
  return axios.get(url);
};

//Get country code list
const EMDoGetCountryCodeList = () => {
  let url = `commans/list-phone-codes/`;
  return axios.get(url);
};

//Get work types list
const EMDoGetWorkTypeList = () => {
  let url = `commans/list-work-type/`;
  return axios.get(url);
};

//Get phone types list
const EMDoGetPhoneTypeList = () => {
  let url = `commans/list-phone-type/`;
  return axios.get(url);
};

//Get ethnicity types list
const EMDoGetEthnicityTypesList = () => {
  let url = `commans/list-ethnicity/`;
  return axios.get(url);
};

//Get ethnicity types list
const EMDoSendCrashReport = (errorText) => {
  let url = `commans/send-error-report/`;
  let data = {
    error: errorText,
  };
  return axios.post(url, data);
};

export default {
  EMDoGetCountryAndRegionList,
  EMDoGetRoles,
  EMDoGetEntityForRoleAndPermission,
  EMDoGetEntityRoleList,
  EMDoUpdateEntityRole,
  EMDoCreateEntityRole,
  EMDoGetPrivacy,
  EMDogetUserData,
  EMDoCreateUser,
  EMDoGetPasswordResetLink,
  EMDoResetPassword,
  EMDoCheckPasswordResetLinkExpiry,
  EMDoAcceptPlatformAdminInvite,
  EMDoGetPermissionForEntity,
  EMDoGetAllInformationAboutUser,
  EMDoUpdateInformationAboutUser,
  EMDoGetNotifications,
  EMDoSeenNotification,
  EMDoGetEventsBySearchKey,
  EMDoGetPostsBySearchKey,
  EMDoGetPostByNotification,
  EMDoGetPollsBySearchKey,
  EMDoGetOrganizationType,
  EMDoGetOrganizationTypeField,
  EMDoGetOrganizationLevels,
  EMDoGetOrganizationDepartments,
  EMDoGetPronounsList,
  EMDoGetGenderList,
  EMDoGetRaceList,
  EMDoGetMinorityList,
  EMDoGetCountryCodeList,
  EMDoGetWorkTypeList,
  EMDoGetPhoneTypeList,
  EMDoGetQuizzesBySearchKey,
  EMDoGetEthnicityTypesList,
  EMDoSendCrashReport,
  EMDoGetGroupsBySearchKey,
  EMDoGetChannelsBySearchKey,
  EMDoGetNetworksBySearchKey
};
