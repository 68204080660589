import React, { useEffect } from "react";
import EMPage from "../../../../components/commans/EMPage";
import { useSelector, useDispatch } from "react-redux";
import {
  EMDoGetCategoryListAction,
  EMDoGetHashTagListsAction,
  EMDoGetPostSharedWithGroupsListAction,
  EMDoLikeDislikePinUnpinPostAction,
} from "../../../../redux/actions/UserPlatform/Home/EMLatestPostPageAction";
import {
  EMDoGetMYOpenEndedQuestionAction,
  EMDoGetPollsListAction,
} from "../../../../redux/actions/UserPlatform/Poll/EMPollActions";
import EMPoll from "../../../../components/user/EMPoll";
import { findLast } from "lodash";
import { Grid, useMediaQuery } from "@material-ui/core";
import EMSideBar from "../../../../components/navigation/EMSideBar";
import {useTheme} from "@material-ui/core/styles"

function OpenEnded(props) {
  const userData = useSelector((state) => state.auth || {});
  const data = useSelector((state) => state.myPoll);
  const dispatch = useDispatch();
  const uiData = useSelector((state) => state.ui || {});

  const theme = useTheme();

  const matchesMediumScreen = useMediaQuery(theme.breakpoints.up("md"));

  const matchesLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    if (uiData["messages"]) {
      let refObj = findLast(uiData["messages"], {
        key: "user_create_poll_post_event_quiz",
      });
      if (refObj && refObj.type === "success") {
        dispatch(EMDoGetMYOpenEndedQuestionAction({ userData }));
        refObj.key = "user_create_poll_post_event_quiz_success"
      }
    }
  }, [uiData]);


  useEffect(() => {
    // dispatch(EMDoGetPostSharedWithGroupsListAction());
    dispatch(EMDoGetMYOpenEndedQuestionAction({ userData }));
    dispatch(EMDoGetHashTagListsAction());
    dispatch(EMDoGetCategoryListAction());
  }, []);

  return (
    <EMPage
      title="My Polls"
      style={{
        width: "100%",
        paddingLeft: matchesLargeScreen  ? "8%" : "4%",
        paddingTop: matchesLargeScreen ? "4%" : matchesMediumScreen ? "6%" : "8%",
        paddingRight: matchesLargeScreen  ? "8%" : "4%",
        paddingBottom: "2%",
      }}
    >
            <Grid container spacing={3}>
      {matchesMediumScreen ? (
            <Grid item xs={matchesLargeScreen ? 2 : 3}>
              <EMSideBar />
            </Grid>
          ) : null}
        <Grid item xs={matchesLargeScreen ? 10 : matchesMediumScreen ? 9 : 12}>
      {data.openEndedQuestions.map((post, id) => (
        <EMPoll
          usedIn={
            post.channel_details[0].channel_name.includes("MAIN_CHANNEL")
              ? "polls"
              : "channels"
          }
          id={
            post.channel_details[0].channel_name.includes("MAIN_CHANNEL")
              ? undefined
              : post.channel_details[0].channel_id
          }
          permission={true}
          PollFromOpenPoll={true}
          post={post}
          pinHandler={() => {
            let actions = [];
            post.isPinnedPost ? actions.push(`unpin`) : actions.push(`pin`);
            let queryData = {
              id: post.id,
              action: actions[0],
            };
            dispatch(EMDoLikeDislikePinUnpinPostAction({ queryData }));
          }}
        />
      ))}
      </Grid>
      </Grid>
    </EMPage>
  );
}

export default OpenEnded;
