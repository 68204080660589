import React from "react";

const ProgressBar = (props) => {
  const { bgcolor, completed, label, notShowCompleted } = props;

  const containerStyles = {
    height: !notShowCompleted ? 10 : 50,
    width: "auto",
    backgroundColor: "#e0e0de",
    borderRadius: 5,
    margin: !notShowCompleted ? 5 : 20,
  };

  const fillerStyles = {
    height: "100%",
    width: `${completed}%`,
    backgroundColor: bgcolor,
    transition: "width 1s ease-in-out",
    borderRadius: "inherit",
    alignItems:"center",
  };

  const labelStyles = {
    padding:5,
    color: completed !== 0 ? "white" : "black",
    fontWeight: "bold",
    // whiteSpace:"nowrap",
    alignItems:"center",
    fontSize:"small",
    marginTop:"5px",
    width: "auto",
    marginTop: "-50px"
  };

  const labelStyles1 = {
    padding:5,
    color: completed !== 0 ? "white" : "black",
    fontWeight: "bold",
    // whiteSpace:"nowrap",
    alignItems:"center",
    fontSize:"small",
    marginTop:"5px",
    width: "auto",
    marginTop: "-50px",
    float:"right"
  };

  const container = {
    display:"flex",
    width:"100%",
    alignItems:"center",
  }
  
  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
      </div>
      <div style={container}>
        <div style={labelStyles}>{label}</div>
        {notShowCompleted ? <div style={labelStyles1}>{`${completed}%`}</div> : <div style={labelStyles1}></div>}
      </div>
    </div>
  );
};

export default ProgressBar;
