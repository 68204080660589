import {
  ENTITY_LIST_SUCCESS,
  PRIVACY_LIST_SUCCESS,
  ENTITY_ROLE_CREATE_SUCCESS,
  ENTITY_ROLE_LIST_SUCCESS,
  ENTITY_ROLE_UPDATE_SUCCESS,
  NETWORK_ADMIN_GROUP_LIST_SUCCESS,
  NETWORK_ADMIN_GROUP_CREATE_SUCCESS,
  NETWORK_ADMIN_GROUP_UPDATE_SUCCESS,
  NETWORK_ADMIN_CHANNEL_LIST_SUCCESS,
  NETWORK_ADMIN_CHANNEL_CREATE_SUCCESS,
  NETWORK_ADMIN_CHANNEL_UPDATE_SUCCESS,
  EMAIL_TEMPLATE_LIST_SUCCESS,
  EMAIL_TEMPLATE_UPDATE_SUCCESS,
  EMAIL_TEMPLATE_CREATE_SUCCESS,
  GROUP_LIST_DROPDOWN_SUCCESS,
  EMAIL_TEMPLATE_TYPE_LIST_SUCCESS,
  NETWORK_ADMIN_USERS_LIST_SUCCESS,
  NETWORK_ADMIN_UPDATE_SUCCESS,
  NETWORK_ADMIN_CREATE_SUCCESS,
  NETWORK_ADMIN_RESEND_INVITE_SUCCESS,
  NETWORK_ADMIN_IMPORT_USERS_LIST_SUCCESS,
  NETWOEK_ADMIN_EMAIL_TEMPLATE_LIST_SUCCESS,
  NETWORK_ADMIN_BULK_UPLOAD_SUCCESS,
  NETWOEK_ADMIN_ROLE_LIST_SUCCESS,
  NETWORK_LIST_SUCCESS,
  NETWORK_DETAILS_LIST_SUCCESS,
  NETWORK_FILE_UPLOAD_DETAILS,
  RESET,
  NETWORK_ADMIN_RESEND_USERS_LIST_SUCCESS,
  NETWORK_ADMIN_FILTER_BY_GROUP_DROPDOWN_VALUES_SUCCESS,
  NETWORK_ADMIN_FILTER_BY_GROUP_LIST_SUCCESS,
  NETWORK_ADMIN_EMAIL_TEMPLATE,
  NETWORK_ADMIN_GET_DASHBOARD_DATA,
  NETWORK_ADMIN_GET_ONBOARDING_DATA,
  NETWORK_ADMIN_GET_USAGE_REPORT_DATA,
  NETWORK_ADMIN_GET_USER_ENGAGEMENT_DATA,
  NETWORK_ADMIN_GET_NETWORK_STATS_DATA,
  NETWORK_ADMIN_ENTITY_LIST,
  NETWORK_ADMIN_GET_ORGANIZATION_DEPARTMENTS,
  NETWORK_ADMIN_GET_ORGANIZATION_LEVELS,
  NETWORK_ADMIN_GET_LEVELS_LIST,
  NETWORK_ADMIN_GET_BADGES_LIST,
  NETWORK_ADMIN_GET_DATA_FOR_DROPDOWN_IN_GAMIFICATION,
  NETWORK_ADMIN_GET_LEVEL_REPORT,
  NETWORK_ADMIN_GET_BADGE_REPORT,
  NETWORK_ADMIN_GET_LIST_OF_NETWORKS_FOR_DROPDOWN,
  NETWORK_ADMIN_GET_PERMISSION_BASED_ON_NETWORK,
  NETWORK_ADMIN_SELECT_UNSELECT_USER_IN_IMPORT_USERS,
  NETWORK_ADMIN_IMPORT_USERS_SELECT_ALL_FLAG,
  NETWORK_ADMIN_ADD_REMOVE_IMPORT_USERS_FROM_SECONDARY_ARRAY,
  NETWORK_ADMIN_IMPORT_USERS_CLEAR_UNCLEAR_SELECT_ALL_WRT_SEARCH,
  NETWORK_ADMIN_SELECT_UNSELECT_USER_IN_RESEND_INVITES_USERS,
  NETWORK_ADMIN_RESEND_INVITES_USERS_SELECT_ALL_FLAG,
  NETWORK_ADMIN_ADD_REMOVE_RESEND_INVITES_USERS_FROM_SECONDARY_ARRAY,
  AUTH_LOGOUT,
  NETWORK_ADMIN_RESEND_INVITES_CLEAR_UNCLEAR_SELECT_ALL_WRT_SEARCH,
  NETWORK_ADMIN_UPDATE_USERS_LIST_FOR_IMPORT_USERS,
  NETWORK_ADMIN_UPDATE_USERS_LIST_FOR_RESEND_INVITES,
  NETWORK_ADMIN_UPDATE_GROUPS_LIST,
  NETWORK_ADMIN_UPDATE_CHANNELS_LIST,
  NETWORK_ADMIN_GET_EMAIL_TEMPLATE_BODY
} from "../constants";
import { cloneDeep, findIndex } from "lodash";
import { Dashboard } from "@material-ui/icons";
import { isEmpty } from "lodash";

const initialState = {
  importUsersList: {},
  selectAllForImportUsers: false,
  checkedUsersForImportUsers: [],
  resendUsersList: {},
  selectAllForResendUsers: false,
  checkedUsersForResendUsers: [],
  updateUsersListForImportUsers: false,
  updateUsersListForResendInvites: false,
  updateGroupsList: false,
  updateChannelsList: false,
};

export default function EMNetworkAdminReducer(state = initialState, action) {
  const newState = cloneDeep(state);
  switch (action.type) {
    case ENTITY_LIST_SUCCESS: {
      newState["entityList"] = action.payload;
      return newState;
    }

    case NETWORK_ADMIN_GET_ORGANIZATION_DEPARTMENTS: {
      newState["departments"] = action.payload;
      return newState;
    }

    case NETWORK_ADMIN_GET_ORGANIZATION_LEVELS: {
      newState["levels"] = action.payload;
      return newState;
    }

    case NETWORK_ADMIN_GET_LEVELS_LIST: {
      newState["defaultLevels"] = action.payload;
      return newState;
    }

    case NETWORK_ADMIN_GET_BADGES_LIST: {
      newState["defaultBadges"] = action.payload;
      return newState;
    }

    case NETWORK_ADMIN_GET_DATA_FOR_DROPDOWN_IN_GAMIFICATION: {
      newState["gamificationDropdownData"] = action.payload;
      return newState;
    }

    case NETWORK_ADMIN_GET_LEVEL_REPORT: {
      newState["levelReport"] = action.payload;
      return newState;
    }

    case NETWORK_ADMIN_GET_BADGE_REPORT: {
      if (action.payload) {
        newState["badgeReport"] = action.payload;
        let result = Object.keys(action.payload.report).map((key) => ({
          badgeName: key,
          userCount: action.payload.report[key],
        }));
        newState["badgeReport"] = {
          ...newState["badgeReport"],
          newBadges: result,
        };
      }
      return newState;
    }

    case NETWORK_LIST_SUCCESS: {
      newState["networkList"] = action.payload;
      return newState;
    }
    case NETWORK_DETAILS_LIST_SUCCESS: {
      newState["networkDetailInfo"] = action.payload;
      return newState;
    }

    case NETWORK_FILE_UPLOAD_DETAILS: {
      newState["networkFileUploadDetails"] = action.payload;
      return newState;
    }
    case PRIVACY_LIST_SUCCESS: {
      newState["privacyList"] = action.payload;
      return newState;
    }

    case ENTITY_ROLE_LIST_SUCCESS: {
      newState["networkRoles"] = action.payload;
      return newState;
    }

    case NETWOEK_ADMIN_ROLE_LIST_SUCCESS: {
      newState["roles"] = action.payload;
      return newState;
    }

    case EMAIL_TEMPLATE_TYPE_LIST_SUCCESS: {
      newState["emailTemplateType"] = action.payload;
      return newState;
    }

    case NETWORK_ADMIN_EMAIL_TEMPLATE: {
      newState["emailTemplates"] = action.payload;
      return newState;
    }

    case ENTITY_ROLE_UPDATE_SUCCESS: {
      let userId = action.payload.id;
      let userIndex = findIndex(newState["networkRoles"]["results"], {
        id: userId,
      });
      if (userIndex > -1) {
        newState["networkRoles"]["results"][userIndex] = {
          ...action.payload,
        };
      }
      return newState;
    }

    // For Dashboard_data
    case NETWORK_ADMIN_GET_DASHBOARD_DATA: {
      newState["dashboardDataNetwork"] = action.payload;
      return newState;
    }
    case NETWOEK_ADMIN_EMAIL_TEMPLATE_LIST_SUCCESS: {
      newState["emailTemplates"] = action.payload;
      return newState;
    }
    case NETWORK_ADMIN_BULK_UPLOAD_SUCCESS: {
      newState["bulkUploadStatus"] = action.payload;
      return newState;
    }
    case NETWORK_ADMIN_ENTITY_LIST: {
      newState["dashboardEntityList"] = action.payload;
      return newState;
    }

    case ENTITY_ROLE_CREATE_SUCCESS: {
      newState["networkRoles"]["results"].unshift(action.payload);
      newState["networkRoles"]["count"] = newState["networkRoles"]["count"]++;
      return newState;
    }
    case NETWORK_ADMIN_USERS_LIST_SUCCESS: {
      newState["networkUsers"] = action.payload;
      return newState;
    }
    case NETWORK_ADMIN_UPDATE_SUCCESS: {
      let userId = action.payload.id;
      let userIndex = findIndex(newState["networkUsers"]["results"], {
        id: userId,
      });
      if (userIndex > -1) {
        newState["networkUsers"]["results"][userIndex] = {
          ...action.payload,
        };
      }
      return newState;
    }
    case NETWORK_ADMIN_CREATE_SUCCESS: {
      newState["networkUsers"]["results"].unshift(action.payload);
      // newState["networkUsers"]["count"] = newState["platformAdminsList"]["count"]++
      return newState;
    }
    case NETWORK_ADMIN_RESEND_INVITE_SUCCESS: {
      newState["invites"] = action.payload;
      return newState;
    }

    case NETWORK_ADMIN_RESEND_USERS_LIST_SUCCESS: {
      newState["nextUsersResend"] = action.payload.next;
      newState["resendUsersList"] = action.payload;
      newState["selectAllForResendUsers"] = false;
      newState["resendUsersList"] = {
        ...newState["resendUsersList"],
        results: newState["resendUsersList"].results.map((user) => ({
          ...user,
          isChecked: false,
        })),
      };
      return newState;
    }

    case NETWORK_ADMIN_SELECT_UNSELECT_USER_IN_RESEND_INVITES_USERS: {
      let userId = action.payload.userId;

      let foundIndex = newState["resendUsersList"].results.findIndex(
        (x) => x.id == userId
      );
      if (foundIndex >= 0) {
        newState["resendUsersList"].results[foundIndex].isChecked =
          action.payload.state;
      }

      let selectAllState = newState["resendUsersList"].results.every(
        (user) => user.isChecked == true
      );
      if (selectAllState) {
        newState["selectAllForResendUsers"] = true;
      } else {
        newState["selectAllForResendUsers"] = false;
      }
      return newState;
    }

    case NETWORK_ADMIN_RESEND_INVITES_USERS_SELECT_ALL_FLAG: {
      newState["selectAllForResendUsers"] = action.payload.state;
      newState["resendUsersList"] = {
        ...newState["resendUsersList"],
        results: newState["resendUsersList"].results.map((user) => ({
          ...user,
          isChecked: action.payload.state,
        })),
      };
      return newState;
    }

    case NETWORK_ADMIN_UPDATE_USERS_LIST_FOR_IMPORT_USERS: {
      newState["updateUsersListForImportUsers"] = action.payload;
      return newState;
    }

    case NETWORK_ADMIN_UPDATE_USERS_LIST_FOR_RESEND_INVITES: {
      newState["updateUsersListForResendInvites"] = action.payload;
      return newState;
    }

    case NETWORK_ADMIN_UPDATE_GROUPS_LIST: {
      newState["updateGroupsList"] = action.payload;
      return newState;
    }

    case NETWORK_ADMIN_UPDATE_CHANNELS_LIST: {
      newState["updateChannelsList"] = action.payload;
      return newState;
    }

    case NETWORK_ADMIN_IMPORT_USERS_LIST_SUCCESS: {
      newState["nextUsersImport"] = action.payload.next;
      newState["importUsersList"] = action.payload;
      newState["selectAllForImportUsers"] = false;
      newState["importUsersList"] = {
        ...newState["importUsersList"],
        results: newState["importUsersList"].results.map((user) => ({
          ...user,
          isChecked: false,
        })),
      };
      return newState;
    }

    case NETWORK_ADMIN_SELECT_UNSELECT_USER_IN_IMPORT_USERS: {
      let userId = action.payload.userId;

      var foundIndex = newState["importUsersList"].results.findIndex(
        (x) => x.id == userId
      );
      if (foundIndex >= 0) {
        newState["importUsersList"].results[foundIndex].isChecked =
          action.payload.state;
      }

      let selectAllState = newState["importUsersList"].results.every(
        (user) => user.isChecked == true
      );
      if (selectAllState) {
        newState["selectAllForImportUsers"] = true;
      } else {
        newState["selectAllForImportUsers"] = false;
      }
      return newState;
    }

    case NETWORK_ADMIN_IMPORT_USERS_SELECT_ALL_FLAG: {
      newState["selectAllForImportUsers"] = action.payload.state;
      newState["importUsersList"] = {
        ...newState["importUsersList"],
        results: newState["importUsersList"].results.map((user) => ({
          ...user,
          isChecked: action.payload.state,
        })),
      };
      return newState;
    }

    case NETWORK_ADMIN_FILTER_BY_GROUP_LIST_SUCCESS: {
      newState["importUsersList"] = action.payload;
      return newState;
    }

    case NETWORK_ADMIN_FILTER_BY_GROUP_DROPDOWN_VALUES_SUCCESS: {
      newState["filterByGroupDropdown"] = action.payload;
      return newState;
    }

    case NETWORK_ADMIN_GROUP_LIST_SUCCESS: {
      newState["networkAdminsGroupList"] = action.payload;
      return newState;
    }

    case NETWORK_ADMIN_GROUP_UPDATE_SUCCESS: {
      let groupId = action.payload.id;
      let groupIndex = findIndex(
        newState["networkAdminsGroupList"]["results"],
        {
          id: groupId,
        }
      );
      if (groupIndex > -1) {
        newState["networkAdminsGroupList"]["results"][groupIndex] = {
          ...action.payload,
        };
      }
      return newState;
    }

    case NETWORK_ADMIN_GROUP_CREATE_SUCCESS: {
      newState["networkAdminsGroupList"]["results"].unshift(action.payload);
      newState["networkAdminsGroupList"]["count"] = newState[
        "networkAdminsGroupList"
      ]["count"]++;
      return newState;
    }

    case NETWORK_ADMIN_CHANNEL_LIST_SUCCESS: {
      newState["networkAdminsChannelList"] = action.payload;
      return newState;
    }

    case NETWORK_ADMIN_CHANNEL_UPDATE_SUCCESS: {
      let userId = action.payload.id;
      let userIndex = findIndex(
        newState["networkAdminsChannelList"]["results"],
        {
          id: userId,
        }
      );
      if (userIndex > -1) {
        newState["networkAdminsChannelList"]["results"][userIndex] = {
          ...action.payload,
        };
      }
      return newState;
    }

    case NETWORK_ADMIN_CHANNEL_CREATE_SUCCESS: {
      newState["networkAdminsChannelList"]["results"].unshift(action.payload);

      newState["networkAdminsChannelList"]["count"]++;
      // newState["networkAdminsChannelList"]["count"] = newState["networkAdminsChannelList"]["count"]++
      return newState;
    }

    case EMAIL_TEMPLATE_LIST_SUCCESS: {
      newState["emailTemplateList"] = action.payload;
      return newState;
    }

    case EMAIL_TEMPLATE_UPDATE_SUCCESS: {
      let userId = action.payload.id;
      let userIndex = findIndex(newState["emailTemplateList"]["results"], {
        id: userId,
      });
      if (userIndex > -1) {
        newState["emailTemplateList"]["results"][userIndex] = {
          ...action.payload,
        };
      }
      return newState;
    }

    case EMAIL_TEMPLATE_CREATE_SUCCESS: {
      newState["emailTemplateList"]["results"].unshift(action.payload);
      newState["emailTemplateList"]["count"] = newState["emailTemplateList"][
        "count"
      ]++;
      return newState;
    }

    //  FOR REPORTS
    case NETWORK_ADMIN_GET_ONBOARDING_DATA: {
      newState["onboardingDataNetwork"] = action.payload;
      return newState;
    }

    case NETWORK_ADMIN_GET_USAGE_REPORT_DATA: {
      newState["usageDataNetwork"] = action.payload;
      return newState;
    }

    case NETWORK_ADMIN_GET_USER_ENGAGEMENT_DATA: {
      newState["userEngagementDataNetwork"] = action.payload;
      return newState;
    }

    case NETWORK_ADMIN_GET_NETWORK_STATS_DATA: {
      newState["networkStatsDataNetwork"] = action.payload;
      return newState;
    }

    case GROUP_LIST_DROPDOWN_SUCCESS: {
      newState["groupDropdownList"] = action.payload;
      return newState;
    }

    case NETWORK_ADMIN_GET_LIST_OF_NETWORKS_FOR_DROPDOWN: {
      newState["networkListForUserDropdown"] = action.payload;
      return newState;
    }

    case NETWORK_ADMIN_GET_PERMISSION_BASED_ON_NETWORK: {
      newState["networkSpecificRolesAndPermissions"] = action.payload;
      return newState;
    }

    case NETWORK_ADMIN_GET_EMAIL_TEMPLATE_BODY:{
      newState["defaultEmailBody"] = action.payload;
      return newState
    }

    case AUTH_LOGOUT: {
      return { ...initialState };
    }
    

    case RESET: {
      return {};
    }

    default: {
      return newState;
    }
  }
}
