//events
export const LIST_USER_EVENTS = "list_user_events"
export const USER_EVENTS_POPUP = "user_events_popup"
export const SHOW_EVENT_DETAILS = "show_event_details"

export const LIST_EVENTS_BY_ME = "list_events_by_me"
export const LIST_ALL_MY_EVENTS = "list_all_my_events"
export const LIST_UPCOMING_EVENTS = "list_upcoming_events"
export const LIST_PAST_EVENTS = "list_past_events"
export const USER_EXPAND_TEXT = "user_expand_text"
export const USER_EXPAND_COMMENT = "user_expand_comment"
export const USER_SHOW_COMMENT = "user_show_comment"
export const SHOW_POST_MEDIA_PREVIEW = "show_post_media_preview"
export const LIST_MONTH_EVENTS = "list_month_events"
export const LIST_WEEK_EVENTS = "list_week_events"
export const LIST_DAY_EVENTS = "list_day_events"
export const LIST_GROUPS_FOR_FILTER = "list_groups_for_filter"
export const GET_SELECTED_DATE = "get_selected_date"
export const EVENT_APPLY_GROUP_FILTER = "event_apply_group_filter"
export const GET_SELECTED_DAY_OPTION = "GET_SELECTED_DAY_OPTION"
export const PLAY_VIDEO_BY_ID = "play_video_by_id"

export const DISABLE_ENABLE_FILTER_FOR_GROUPS = "disable_enable_filter_for_groups"
export const DISABLE_ENABLE_FILTER_FOR_GROUPS_PAST_EVENTS = "disable_enable_filter_for_groups_past_events"
export const DISABLE_ENABLE_FILTER_FOR_GROUPS_FUTURE_EVENTS = "disable_enable_filter_for_groups_future_events"
