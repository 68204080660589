import API from "../../../../api";
import { SHOW_MESSAGE } from "../../../constants/index";
import {
  ALL_MY_NETWORKS,
  NETWORK_BY_ORGANIZATION_FILTER,
  DETAIL_FOR_NETWORK,
  FILTER_FOR_NETWORK,
  ALL_CHANNELS_FOR_NETWORK,
  ALL_GROUPS_FOR_NETWORK,
  ORGANIZATION_DETAILS,
  GET_ACTIONS_LIST_FOR_DROPDOWN,
  GET_ACTIONS_OF_ORGANIZATION,
  DISABLE_ENABLE_FILTER_FOR_NETWORKS,
} from "../../../constants/UserPlatform/EMNetworkConstant";
import { handleError } from "../../../../utils/EMCommonFunctions";
export const EMDoGetAllMyNetworksAction = (action) => {
  return (dispatch) => {
    API.EMDoGetAllMyNetworks(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: ALL_MY_NETWORKS,
            payload: response,
          });
          dispatch({
            type:DISABLE_ENABLE_FILTER_FOR_NETWORKS,
            payload:false
          })
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error while fetching user's networks",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

//get details for a group
export const EMDoGetDetailsForNetwork = (action) => {
  return (dispatch) => {
    API.EMDoGetDetailsForNetwork(action.id)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: DETAIL_FOR_NETWORK,
            payload: response,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching available networks",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};
// get all groups for network
export const EMDoGetAllGroupsForNetworkAction = (action) => {
  return (dispatch) => {
    API.EMDoGetAllGroupsForNetwork(action.id)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: ALL_GROUPS_FOR_NETWORK,
            payload: response,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error while fetching user's networks",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};
// get all channels for network
export const EMDoGetAllChannelsForNetworkAction = (action) => {
  return (dispatch) => {
    API.EMDoGetAllChannelsForNetwork(action.id)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: ALL_CHANNELS_FOR_NETWORK,
            payload: response,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error while fetching user's networks",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};
// get filter for network
export const EMDoGetFilterForNetworkAction = (action) => {
  return (dispatch) => {
    API.EMDoGetFilterForNetwork(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: FILTER_FOR_NETWORK,
            payload: response,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error while fetching user's networks",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};
// get filter by organization
export const EMDoGetNetworkByOrganizationFilterAction = (action) => {
  return (dispatch) => {
    API.EMDoGetNetworkByOrganizationFilter(action.id)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: ALL_MY_NETWORKS,
            payload: response,
          });
          dispatch({
            type:DISABLE_ENABLE_FILTER_FOR_NETWORKS,
            payload:false
          })
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error while fetching networks",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};
// Get organization details
export const EMDoGetOrganizationdetailsAction = (action) => {
  return (dispatch) => {
    API.EMDoGetOrganizationdetails(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: ORGANIZATION_DETAILS,
            payload: response,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching available networks",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

// Get organization action's list
export const EMDoGetActionsListForDropdownAction = (action) => {
  return (dispatch) => {
    API.EMDoGetActionsListForDropdown(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: GET_ACTIONS_LIST_FOR_DROPDOWN,
            payload: response,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching action list",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

// Get organization action's list
export const EMDoGetActionsForOrganizationAction = (action) => {
  return (dispatch) => {

    API.EMDoGetActionsForOrganization(action.queryData)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: GET_ACTIONS_OF_ORGANIZATION,
            payload: response,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching action list",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

// Get organization action's list
export const EMDoCreateActionsForOrganizationAction = (action) => {
  return (dispatch) => {
    
    API.EMDoCreateActionsForOrganization(action.organizationId, action.data)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch(EMDoGetActionsForOrganizationAction({queryData:"?limit=15"}));
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Action Added Successfully",
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching action list",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

// Get organization action's list
export const EMDoUpdateActionsForOrganizationAction = (action) => {
  return (dispatch) => {
    
    API.EMDoUpdateActionsForOrganization(action.actionId, action.data)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch(EMDoGetActionsForOrganizationAction({queryData:"?limit=15"}));
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Action Updated Successfully",
              key: "user_add_edit"
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching action list",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

// Get organization action's list
export const EMDoDeleteActionsForOrganizationAction = (action) => {
  return (dispatch) => {
    
    API.EMDoDeleteActionsForOrganization(action.actionId)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch(EMDoGetActionsForOrganizationAction({queryData:"?limit=15"}));
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Action Deleted Successfully",
              key: "user_add_edit"
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching action list",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};
