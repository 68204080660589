import React, { useEffect, useReducer, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  Box,
  Paper,
  Grid,
  Button,
  TextField,
  Typography,
  IconButton,
  CardContent,
  CardMedia,
  CardHeader,
  GridList,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import AddLinkIcon from "@material-ui/icons/InsertLink";
import ReactPlayer from "react-player/lazy";
import { ReactComponent as VideoIcon } from "../../../../assets/Icon-awesome-video.svg";
import { ReactComponent as ImageIcon } from "../../../../assets/Icon-metro-image.svg";
import EMTextArea from "../../../../components/commans/EMTextArea";
import { useFormik, Field, Formik } from "formik";
import * as yup from "yup";
import EMFileSelect from "../../../../components/commans/EMFileSelect";
import EMMultipleSelect from "../../../../components/commans/EMMultipleSelect";
import { CloseRounded } from "@material-ui/icons";

import { find, findLast, cloneDeep, findIndex } from "lodash";
import { useSelector, useDispatch } from "react-redux";

import {
  SHOW_CREATE_POST,
  USER_IMAGES_UPLOAD,
  USER_IMAGES_DELETE,
  USER_VIDEOS_UPLOAD,
  USER_VIDEOS_DELETE,
  USER_DOCUMENT_UPLOAD,
  USER_DOCUMENT_DELETE,
  SHOW_POST_EDIT,
  POST_IMAGES,
  POST_VIDEOS,
  POST_DOCUMENT,
  POST_IMAGES_DELETE,
  POST_VIDEOS_DELETE,
  POST_DOCUMENT_DELETE,
  USER_EXTERNAL_VIDEO_UPLOAD,
  USER_EXTERNAL_VIDEO_DELETE,
  POST_EXTERNAL_VIDEO_DELETE,
  POST_EXTERNAL_VIDEO_UPLOAD,
} from "../../../../redux/constants/UserPlatform/index";
import {
  EMDoCreateNewHashTagAction,
  EMDoCreateNewPostAction,
  EMDoEditPostAction,
  EMDoGetPeopleMentionForCreatePostListAction,
  EMDoGetPostSharedWithGroupsListAction,
} from "../../../../redux/actions/UserPlatform/Home/EMLatestPostPageAction";
import EMDialogBox from "../../../../components/commans/EMDialogBox";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: 30,
    // paddingBottom: 5,
    // maxHeight: "5%",
    overflowX: "hidden",
    width: "100%",
    overflow: "auto",
    maxHeight: window.screen.height / 1.2,
    "&::-webkit-scrollbar": {
      width: "0.3em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#4A4A4A",
      outline: "1px solid slategrey",
    },
  },
  content: {
    // marginLeft: "3%",
    padding: "1%",
    maxWidth: "100%",
    maxHeight: "80%",
  },
  title: {
    // marginLeft: "1%",
    padding: "1%",
    // width: "90%",
  },
  description: {
    padding: "1%",
    maxWidth: "100%",
  },
  image: {
    height: 0,
    paddingTop: "56.25%",
  },
  button: {
    margin: theme.spacing(2),
    backgroundColor: "white",
  },
  buttonGridforSave: {
    marginTop: theme.spacing(0.5),
    color: "primary",
    paddingBottom: "3%",
    marginLeft: "-8%",
  },
  media: {
    height: 0,
  },
  attachments: {
    marginRight: "-100",
  },
  uploadMedia: {
    marginLeft: "-4%",
    marginTop: "1%",
  },
  prevMedia: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    marginLeft: "-10%",
  },
  gridList: {
    width: "80%",
    // height: 450,
  },
}));

export default function EMCreatePost(props) {
  // const [state, dispatch] = useReducer(reducer, initialState)
  const classes = useStyles();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.home || {});
  const [show_confirm_msg, setShowConfirmMsg] = React.useState(false);
  const [videoSelector, setVideoSelector] = React.useState(false);
  const [videoLinkDialog, setVideoLinkDialog] = React.useState(false);
  const [hashtag, setHashtag] = useState([]);
  const [videoLink, setVideoLink] = React.useState("");
  const postSharedWithGroups = useSelector(
    (state) => state.home.postSharedWithGroups || []
  );
  const userData = useSelector((state) => state.auth || {});
  const [organizationData, setOrganizationData] = React.useState([]);
  const [description, setDescription] = React.useState("");
  const theme = useTheme();
  const uiData = useSelector(state => state.ui || {})

  const matchesSmallScreen = useMediaQuery(theme.breakpoints.between(0, 600));

  // useEffect(() => {
  //   dispatch(EMDoGetPostSharedWithGroupsListAction());
  // },[])

  const onClose = () => {
    setVideoSelector(false);
  };

  const onOpen = () => {
    setVideoSelector(true);
  };

  const onCloseVideoLinkDialog = () => {
    setVideoLinkDialog(false);
    setVideoLink("");
  };

  const onOpenVideoLinkDialog = () => {
    setVideoLinkDialog(true);
    setVideoSelector(false);
  };

  let docUrl = "https://docs.google.com/gview?url=";
  let groupsSelected = [];
  const validationSchema = yup.object({
    // title: yup
    //   .string("Enter title for your post")
    //   .required("Title is required"),
    groups: yup
      .array()
      .transform(function (value, originalValue) {
        if (this.isType(value) && value !== null) {
          return value;
        }
        return originalValue ? originalValue.split(/[\s,]+/) : [];
      })
      .required("Please Select Groups")
      .min(1, "Select Atleast 1 group"),
  });
  if (props.post && props.post.images && data.post_images == undefined) {
    dispatch({
      type: POST_IMAGES,
      payload: props.post.images,
    });
  }
  if (props.post && props.post.videos && data.post_videos == undefined) {
    dispatch({
      type: POST_VIDEOS,
      payload: props.post.videos,
    });
  }
  if (props.post && props.post.documents && data.post_documents == undefined) {
    dispatch({
      type: POST_DOCUMENT,
      payload: props.post.documents,
    });
  }

  if (
    props.post &&
    props.post.video_links &&
    data.post_external_video_links == undefined

  ) {
    dispatch({
      type: POST_EXTERNAL_VIDEO_UPLOAD,
      payload: props.post.video_links,
    });
  }

  let prevDataForGroups = [];
  if (props.post && prevDataForGroups.length <= 0) {
    let index = find(postSharedWithGroups, {
      group_name: props.post.groupName,
    });
    let showValue = index && index.group_name + " |" + index.id;
    prevDataForGroups.push(showValue);
  }

  React.useEffect(() => {
    dispatch(EMDoGetPeopleMentionForCreatePostListAction());
  }, []);

  React.useEffect(() => {
    setHashtag(data.postHashTags);
  }, [data.postHashTags]);


  const formik = useFormik({
    initialValues: {
      groups: props.selectedChannelIds ? props.selectedChannelIds : [],
    },
    validationSchema: validationSchema,

    onSubmit: (values) => {
      if (props.post) {
        let groups = [];
        if (props.postFrom == "home") {
          let sharedWithGroup = formik.values.groups;
          sharedWithGroup.map((grp) => {
            if (typeof grp === "string") {
              if (grp.indexOf(" |") > -1) {
                let grpName = grp.substr(0, grp.indexOf(" |"));
                let index = find(postSharedWithGroups, { group_name: grpName });
                groups.push(index.id);
              }
            } else {
              groups.push(grp);
            }
          });
        } else if (props.postFrom == "channel") {
          props.post.channel_details.map((channel) => {
            let channelId = channel.channel_id;
            groups.push(channelId);
          });
        }
        let already_images = [];
        data.post_images.map((img) => {
          already_images.push(img.id);
        });
        let already_videos = [];
        data.post_videos.map((vid) => {
          already_videos.push(vid.id);
        });
        let already_documents = [];
        data.post_documents.map((doc) => {
          already_documents.push(doc.id);
        });
        let already_Video_links = [];

        data.post_external_video_links.map((link) => {
          already_Video_links.push(link.id);
        });
        let descriptionText =
          description == undefined
            ? props.post && props.post.postText
            : description;

        let linkArray = descriptionText
          .split(" ")
          .map((word) => word.split("href")[1])
          .filter((word) => word !== undefined && !word.includes("users"));

        let thumbnail_link;
        if (linkArray.length > 0) {
          thumbnail_link = linkArray[0].slice(2, linkArray[0].length - 1);
        } else {
          thumbnail_link = "";
        }

        if(descriptionText == "<p><br></p>"){
          descriptionText = descriptionText.replace("<p><br></p>", "")
        }

        let finalObject = {
          title: "",
          id: props.post.id,
          description: descriptionText,
          images: already_images,
          videos: already_videos,
          video_links: already_Video_links,
          documents: already_documents,
          channel_ids: groups,
          new_images: data.images,
          new_videos: data.videos,
          new_documents: data.documents,
          new_video_links: data.externalVideos,
          thumbnail_link: thumbnail_link,
        };

        const newTags = descriptionText
          .split(" ")
          .map((word) => word.split("#")[1])
          .filter(
            (word) =>
              word !== undefined &&
              !word.includes("</span>") &&
              !word.includes("users") &&
              !word.includes('"') &&
              !data.postHashTags.some((tag) => tag.display === word)
          );
        newTags.length > 0 &&
          newTags.forEach((el, i, arr) => {
            arr[i] = el.replace(/<\/?p[^>]*>/g, "");
          });
        if (newTags.length > 0) {
          newTags.forEach((tag) => {
            dispatch(EMDoCreateNewHashTagAction(tag));
          });
        }

        let tag = descriptionText
          .split(" ")
          .filter(
            (word) =>
              word !== undefined &&
              word.includes("data-link") &&
              word.includes("people")
          )
          .map((word) =>
            word.slice(word.lastIndexOf("/"), word.lastIndexOf(">"))
          )
          .map((word) => word.slice(1, word.length - 1))
          .map((word) => parseFloat(word));

        dispatch(
          EMDoEditPostAction({
            finalObject,
            userData,
            nextPost: 1,
            taggedUserId: tag,
          })
        );
        //reset form


      } else {
        let groups = [];
        if (props.usedIn !== "channels") {
          let sharedWithGroup = formik.values.groups;
          sharedWithGroup.map((grp) => {
            if (typeof grp === "string") {
              if (grp.indexOf(" |") > -1) {
                let grpName = grp.substr(0, grp.indexOf(" |"));
                let index = find(postSharedWithGroups, { group_name: grpName });
                groups.push(index.id);
              }
            } else {
              groups.push(grp);
            }
          });
        } else if (props.usedIn == "channels") {
          groups.push(props.selectedChannelIds);
        }
        // let title = formik.values.title
        let descriptionText =
          description == undefined
            ? props.post && props.post.postText
            : description;
        let images = data.images;
        let videos = data.videos;
        let documents = data.documents;
        let videoLinks = data.externalVideos;

        let linkArray = descriptionText
          .split(" ")
          .map((word) => word.split("href")[1])
          .filter((word) => word !== undefined && !word.includes("users"));
        let thumbnail_link;
        if (linkArray.length > 0) {
          thumbnail_link = linkArray[0].slice(2, linkArray[0].length - 1);
        } else {
          thumbnail_link = "";
        }

        if(descriptionText == "<p><br></p>"){
          descriptionText = descriptionText.replace("<p><br></p>", "")
        }

        let finalObject = {
          title: "",
          description: descriptionText,
          images: images,
          videos: videos,
          documents: documents,
          channel_ids: groups,
          video_links: videoLinks,
          thumbnail_link: thumbnail_link,
        };

        const newTags = descriptionText
          .split(" ")
          .map((word) => word.split("#")[1])
          .filter(
            (word) =>
              word !== undefined &&
              !word.includes("</span>") &&
              !word.includes("users") &&
              !word.includes('"') &&
              !data.postHashTags.some((tag) => tag.display === word)
          );

        let tag = descriptionText
          .split(" ")
          .filter(
            (word) =>
              word !== undefined &&
              word.includes("data-link") &&
              word.includes("people")
          )
          .map((word) =>
            word.slice(word.lastIndexOf("/"), word.lastIndexOf(">"))
          )
          .map((word) => word.slice(1, word.length - 1))
          .map((word) => parseFloat(word));

        newTags.length > 0 &&
          newTags.forEach((el, i, arr) => {
            arr[i] = el.replace(/<\/?p[^>]*>/g, "");
          });
        if (newTags.length > 0) {
          newTags.forEach((tag) => {
            dispatch(EMDoCreateNewHashTagAction(tag));
          });
        }

        dispatch(
          EMDoCreateNewPostAction({
            finalObject,
            userData,
            nextPost: data.pageofPosts,
            taggedUserId: tag,
          })
        );
        // formik.resetForm();

      }
    },
  });

  const handleConfirmMsg = (e) => {
    e.preventDefault();
    setShowConfirmMsg(true);
  };

  const handleDescription = (value) => {
    setDescription(value);
  };

  useEffect(() => {
    if (uiData["messages"]) {
      let refObj = findLast(uiData["messages"], { key: "post_error" });
      if (refObj && refObj.type === "error") {
        formik.setSubmitting(false)
        uiData["messages"] = []
      }
    }
  },[uiData])

  return (
    <Paper elevation={2} className={classes.root}>
      <form
        onSubmit={
          formik.values.groups.length > 1
            ? handleConfirmMsg
            : formik.handleSubmit
        }
      >
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          style={{
            padding: "0px 4px 0px 4px",
            backgroundColor: "#2F92D6",
            height: "auto",
          }}
        >
          <Typography
            style={{
              margin: 10,
              flexGrow: 1,
              fontWeight: "bold",
              color: "white",
              fontSize: "large",
            }}
          >
            {props.post ? props.post.editPost.labels.title : "Create a Post"}
          </Typography>
          <IconButton
            onClick={() => {
              props.post
                ? dispatch({
                    type: SHOW_POST_EDIT,
                    payload: {
                      id: props.post.id,
                      editPost: false,
                      title: "Edit Post",
                      button: "Update Post",
                    },
                  })
                : dispatch({
                    type: SHOW_CREATE_POST,
                    payload: { value: false, route: "create_post" },
                  });
                  dispatch({ type: USER_IMAGES_UPLOAD, payload: null });
                  dispatch({ type: USER_VIDEOS_UPLOAD, payload: null });
                  dispatch({ type: USER_DOCUMENT_UPLOAD, payload: null });
                  dispatch({ type: USER_EXTERNAL_VIDEO_UPLOAD, payload: null });
                  dispatch({
                    type: POST_IMAGES,
                    payload: undefined,
                  });
                  dispatch({
                    type: POST_VIDEOS,
                    payload: undefined,
                  });
                  dispatch({
                    type: POST_DOCUMENT,
                    payload: undefined,
                  });
                  dispatch({
                    type: POST_EXTERNAL_VIDEO_UPLOAD,
                    payload: undefined,
                  });
            }}
          >
            <CloseRounded style={{color:"white"}} />
          </IconButton>
        </Box>
        <Box className={classes.content}>
          <Box className={classes.description}>
            <EMTextArea
              placeHolder="Description"
              value={(props.post && props.post.postText) || description}
              name={description}
              hash={hashtag}
              peopleMention={data.postAtMentions}
              changeHandler={(value) => {
                handleDescription(value);
              }}
            />
          </Box>
          {/* <Card className={classes.prevMedia}> */}
          <Grid style={{ justifyContent: "space-evenly" }} container>
            {data.post_images &&
              data.post_images.map((image, id) => (
                <Card className={classes.postMedia}>
                  <CardHeader
                    action={
                      <IconButton
                        style={{ float: "right" }}
                        onClick={(img) =>
                          dispatch({ type: POST_IMAGES_DELETE, payload: id })
                        }
                      >
                        <CloseRounded color="secondary" />
                      </IconButton>
                    }
                  />
                  <div
                    style={{
                      width: "200px",
                      height: "150px",
                      whiteSpace: "nowrap",
                      textAlign: "center",
                    }}
                  >
                    <span
                      style={{
                        display: "inline-block",
                        height: "100%",
                        verticalAlign: "middle",
                      }}
                    ></span>{" "}
                    <img
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        verticalAlign: "middle",
                      }}
                      src={
                        process.env.REACT_APP_BACKEND_FILE_PREVIEW +
                        image.file_name
                      }
                      alt=""
                    />
                  </div>
                </Card>
              ))}
            {data.post_videos &&
              data.post_videos.map((video, id) => (
                <Card className={classes.postMedia}>
                  <CardHeader
                    action={
                      <IconButton
                        style={{ float: "right" }}
                        onClick={(img) =>
                          dispatch({ type: POST_VIDEOS_DELETE, payload: id })
                        }
                      >
                        <CloseRounded color="secondary" />
                      </IconButton>
                    }
                  />
                  <div
                    style={{
                      width: "200px",
                      height: "150px",
                      whiteSpace: "nowrap",
                      textAlign: "center",
                    }}
                  >
                    <span
                      style={{
                        display: "inline-block",
                        height: "100%",
                        verticalAlign: "middle",
                      }}
                    ></span>{" "}
                    <video
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        verticalAlign: "middle",
                      }}
                      controls
                      alt=""
                    >
                      <source
                        src={
                          process.env.REACT_APP_BACKEND_FILE_PREVIEW +
                          video.file_name
                        }
                      />
                    </video>
                  </div>

                  <CardContent>
                    <Typography>
                      {/* {video.substr(video.lastIndexOf("/") + 1, video.length)} */}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            {data.post_external_video_links &&
              data.post_external_video_links.map((video, id) => (
                <Card className={classes.postMedia}>
                  <CardHeader
                    action={
                      <IconButton
                        style={{ float: "right" }}
                        onClick={(img) =>
                          dispatch({
                            type: POST_EXTERNAL_VIDEO_DELETE,
                            payload: id,
                          })
                        }
                      >
                        <CloseRounded color="secondary" />
                      </IconButton>
                    }
                  />
                  <ReactPlayer
                    config={{
                      youtube: {
                        playerVars: { origin: "https://www.youtube.com" },
                      },
                    }}
                    width="200px"
                    height="150px"
                    url={video.file_path}
                  />

                  <CardContent>
                    <Typography>
                      {/* {video.substr(video.lastIndexOf("/") + 1, video.length)} */}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            {data.post_documents &&
              data.post_documents.map((document, id) => (
                <Card className={classes.postMedia} id="document1">
                  <CardHeader
                    action={
                      <IconButton
                        style={{ float: "right" }}
                        onClick={(img) =>
                          dispatch({ type: POST_DOCUMENT_DELETE, payload: id })
                        }
                      >
                        <CloseRounded color="secondary" />
                      </IconButton>
                    }
                  />
                  <a
                    href={
                      process.env.REACT_APP_BACKEND_FILE_PREVIEW +
                      document.file_name
                    }
                    style={{ color: "blue" }}
                    target="_blank"
                  >
                    {document &&
                      document.file_name.substr(
                        document && document.file_name.lastIndexOf("/") + 1,
                        document && document.length
                      )}
                  </a>
                </Card>
              ))}
          </Grid>
          {/* </Card> */}
          <Grid style={{ justifyContent: "space-evenly" }} container>
            {/* <Box> */}
            {data.images &&
              data.images.map((img, id) => (
                <Card>
                  <CardHeader
                    action={
                      <IconButton
                        style={{ float: "right" }}
                        onClick={(img) =>
                          dispatch({ type: USER_IMAGES_DELETE, payload: id })
                        }
                      >
                        <CloseRounded color="secondary" />
                      </IconButton>
                    }
                  />
                  <div
                    style={{
                      width: "200px",
                      height: "150px",
                      whiteSpace: "nowrap",
                      textAlign: "center",
                    }}
                  >
                    <span
                      style={{
                        display: "inline-block",
                        height: "100%",
                        verticalAlign: "middle",
                      }}
                    ></span>{" "}
                    <img
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        verticalAlign: "middle",
                      }}
                      src={img.url}
                      alt=""
                    />
                  </div>
                  <CardContent>
                    <Typography>{img.name}</Typography>
                  </CardContent>
                </Card>
              ))}
            {data.videos &&
              data.videos.map((video, id) => (
                <Card>
                  <CardHeader
                    action={
                      <IconButton
                        style={{ float: "right" }}
                        onClick={() =>
                          dispatch({ type: USER_VIDEOS_DELETE, payload: id })
                        }
                      >
                        <CloseRounded color="secondary" />
                      </IconButton>
                    }
                  />
                  <div
                    style={{
                      width: "200px",
                      height: "150px",
                      whiteSpace: "nowrap",
                      textAlign: "center",
                    }}
                  >
                    <span
                      style={{
                        display: "inline-block",
                        height: "100%",
                        verticalAlign: "middle",
                      }}
                    ></span>{" "}
                    <video
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        verticalAlign: "middle",
                      }}
                      controls
                      alt=""
                    >
                      <source src={video.url} />
                    </video>
                  </div>
                  <CardContent>
                    <Typography>{video.name}</Typography>
                  </CardContent>
                </Card>
              ))}
            {data.externalVideos &&
              data.externalVideos.map((video, id) => (
                <Card>
                  <CardHeader
                    action={
                      <IconButton
                        style={{ float: "right" }}
                        onClick={() =>
                          dispatch({
                            type: USER_EXTERNAL_VIDEO_DELETE,
                            payload: id,
                          })
                        }
                      >
                        <CloseRounded color="secondary" />
                      </IconButton>
                    }
                  />

                  <ReactPlayer
                    config={{
                      youtube: {
                        playerVars: { origin: "https://www.youtube.com" },
                      },
                    }}
                    width="200px"
                    height="150px"
                    url={video}
                  />
                </Card>
              ))}
            {data.documents &&
              data.documents.map((doc, id) => (
                <Card>
                  <CardHeader
                    action={
                      <IconButton
                        style={{ float: "right" }}
                        onClick={(doc) =>
                          dispatch({ type: USER_DOCUMENT_DELETE, payload: id })
                        }
                      >
                        <CloseRounded color="secondary" />
                      </IconButton>
                    }
                  />
                  <a href={doc.url} style={{ color: "blue" }} target="_blank">
                    {doc.name}
                  </a>
                </Card>
              ))}
            {/* </Box> */}
          </Grid>
          <Box>
            <Grid container spacing={1}>
              <Grid item md={8} xs={12}>
                <Box>
                  <Typography className={classes.title}>Attachments</Typography>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Grid item sm={4} xs={6}>
                      <EMFileSelect
                        changeHandler={(file) => {
                          if (file != null) {
                            dispatch({
                              type: USER_IMAGES_UPLOAD,
                              payload: file,
                            });
                          }
                        }}
                        id={"img"}
                        accept={[".png", ".jpg", ".jpeg"]}
                        fileSize={11534336}
                        supportedFormats={[
                          "image/png",
                          "image/jpg",
                          "image/jpeg",
                        ]}
                        maxFileSize={"10MB"}
                        icon={<ImageIcon />}
                        label="IMAGE"
                        disabled={
                          data.images && data.images.length >= 10 ? true : false
                        }
                        multipleFileSelect={true}
                        maxNumberOfFiles={10}
                        alreadyPresentFiles={data.images && data.images.length}
                      />
                    </Grid>
                    <Grid item style={{ marginBottom: 20 }} sm={4} xs={6}>
                      <Button
                        style={{ margin: "0 auto", display: "flex" }}
                        onClick={onOpen}
                        variant="contained"
                        className={classes.button}
                        // component="span"
                        startIcon={<VideoIcon />}
                      >
                        VIDEO
                      </Button>
                      <Dialog
                        fullWidth={true}
                        onClose={onCloseVideoLinkDialog}
                        open={videoLinkDialog}
                      >
                        <DialogTitle>External Video Link</DialogTitle>
                        <DialogContent>
                          <DialogContentText>
                            Please add external video link below
                          </DialogContentText>
                          <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Video Link"
                            type="email"
                            fullWidth
                            onChange={(e) => {
                              setVideoLink(e.target.value);
                            }}
                            variant="standard"
                            value={videoLink}
                          />
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={onCloseVideoLinkDialog}>
                            Cancel
                          </Button>
                          <Button
                            onClick={() => {
                              dispatch({
                                type: USER_EXTERNAL_VIDEO_UPLOAD,
                                payload: videoLink,
                              });
                              setVideoLink("");
                              onCloseVideoLinkDialog();
                            }}
                          >
                            Add
                          </Button>
                        </DialogActions>
                      </Dialog>
                      {videoSelector ? (
                        <Dialog
                          fullWidth={true}
                          onClose={onClose}
                          open={videoSelector}
                        >
                          <DialogTitle>
                            Select the video type
                            <IconButton
                              aria-label="close"
                              onClick={onClose}
                              style={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                              }}
                            >
                              <CloseRounded />
                            </IconButton>
                          </DialogTitle>
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Grid item xs={6}>
                              <EMFileSelect
                                changeHandler={(file) => {
                                  if (file != null) {
                                    dispatch({
                                      type: USER_VIDEOS_UPLOAD,
                                      payload: file,
                                    });
                                    onClose();
                                  }

                                }}
                                id={"vid"}
                                accept={".mp4"}
                                fileSize={11534336}
                                supportedFormats={["video/mp4"]}
                                maxFileSize={"10MB"}
                                icon={<VideoIcon />}
                                label="VIDEO FILE"
                                maxNumberOfFiles={10}
                                disabled={
                                  data.videos && data.videos.length >= 10
                                    ? true
                                    : false
                                }
                                multipleFileSelect={true}
                                alreadyPresentFiles={data.videos && data.videos.length}
                              />
                            </Grid>
                            <Grid item xs={6} style={{ marginBottom: 20 }}>
                              <Button
                                onClick={onOpenVideoLinkDialog}
                                variant="contained"
                                className={classes.button}
                                component="span"
                                startIcon={<AddLinkIcon />}
                              >
                                VIDEO LINK
                              </Button>
                            </Grid>
                          </Grid>
                        </Dialog>
                      ) : null}
                    </Grid>
                    <Grid item sm={4} xs={6}>
                      <EMFileSelect
                        changeHandler={(file) => {
                          if (file != null) {
                            dispatch({
                              type: USER_DOCUMENT_UPLOAD,
                              payload: file,
                            });
                          }
                        }}
                        id={"doc"}
                        accept={[
                          ".pdf",
                          ".txt",
                          ".doc",
                          ".xls",
                          ".pptx",
                          ".ppt",
                          ".docx",
                          ".xlsx",
                        ]}
                        fileSize={11534336}
                        supportedFormats={[
                          "text/plain",
                          "application/pdf",
                          "application/doc",
                          "application/ms-doc",
                          "application/msword",
                          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                          "application/mspowerpoint",
                          "application/powerpoint",
                          "application/vnd.ms-powerpoint",
                          "application/x-mspowerpoint",
                          "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                          "application/excel",
                          "application/vnd.ms-excel",
                          "application/x-excel",
                          "application/x-msexcel",
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        ]}
                        maxFileSize={"10MB"}
                        icon={<CloudUploadIcon />}
                        label="DOCUMENT"
                        disabled={
                          data.documents && data.documents.length >= 10
                            ? true
                            : false
                        }
                        multipleFileSelect={true}
                        maxNumberOfFiles={10}
                        alreadyPresentFiles={data.documents && data.documents.length}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              {formik.values.groups.length > 1 ? (
                <EMDialogBox
                  shouldOpen={show_confirm_msg}
                  onSelect={(shouldProcess) => {
                    if (shouldProcess) {
                      formik.handleSubmit();
                      setShowConfirmMsg(false);
                    } else {
                     // formik.resetForm();
                      setShowConfirmMsg(false);
                    }
                  }}
                  dialogText={
                    "You are sharing post with more than one group, do you want to share the post?"
                  }
                />
              ) : null}
              <Grid item md={4} xs={12}>
                {props.id || props.postFrom == "channel" ? null : (
                  <>
                    <Typography className={classes.title}>
                      Share to Groups
                    </Typography>
                    <Box style={{ padding: matchesSmallScreen ? "8px" : 0 }}>
                      <EMMultipleSelect
                        label={"Select Groups"}
                        data={postSharedWithGroups}
                        objKey={"group_name"}
                        objId={"id"}
                        prevData={
                          props?.selectedChannelIds?.length > 0 &&
                          props?.selectedChannelIds
                        }
                        formik={{
                          errors: formik.errors.groups,
                          touched: formik.touched.groups,
                        }}
                        onChange={(selectedGroups) => {
                          let ids = [];
                          selectedGroups.map((grp) => {
                            let id = String(grp).substr(
                              String(grp).lastIndexOf("|") + 1
                            );
                            ids.push(parseInt(id));
                          });
                          formik.setFieldValue("groups", ids);
                        }}
                      />
                    </Box>
                  </>
                )}
              </Grid>
            </Grid>
          </Box>

          <Grid
            container
            spacing={2}
            style={{marginTop:matchesSmallScreen ? "8px" : 0}}
          >
            <Grid
              item
              xs={6}
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button
                // style={{height:"36px",marginLeft:"-12px"}}
                variant="contained"
                color="primary"
                onClick={
                  formik.values.groups.length > 1 ? handleConfirmMsg : null
                }
                type="submit"
                disabled={formik.isSubmitting}
              >
                {props.post ? props.post.editPost.labels.title : "Create  Post"}
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <label htmlFor={"contained-button-file"}>
                <Button
                  onClick={() => {
                    props.post
                      ? dispatch({
                          type: SHOW_POST_EDIT,
                          payload: {
                            id: props.post.id,
                            editPost: false,
                            title: "Edit Post",
                            button: "Update Post",
                          },
                        })
                      : dispatch({
                          type: SHOW_CREATE_POST,
                          payload: { value: false, route: "create_post" },
                        });
                    dispatch({
                      type: POST_IMAGES,
                      payload: undefined,
                    });
                    dispatch({
                      type: POST_VIDEOS,
                      payload: undefined,
                    });
                    dispatch({
                      type: POST_DOCUMENT,
                      payload: undefined,
                    });
                    dispatch({
                      type: POST_EXTERNAL_VIDEO_UPLOAD,
                      payload: undefined,
                    });
                    dispatch({
                      type: USER_DOCUMENT_UPLOAD,
                      payload: undefined,
                    });
                    dispatch({
                      type: USER_VIDEOS_UPLOAD,
                      payload: undefined,
                    });
                    dispatch({
                      type: USER_IMAGES_UPLOAD,
                      payload: undefined,
                    });
                    dispatch({
                      type: USER_EXTERNAL_VIDEO_UPLOAD,
                      payload: undefined,
                    });
                  }}
                  variant="contained"
                >
                  Discard
                </Button>
              </label>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Paper>
  );
}
