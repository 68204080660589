import EMDropdown from "../../../../components/commans/EMDropdown";
import { useSelector } from "react-redux";
import { find } from "lodash";
import FormHelperText from "@material-ui/core/FormHelperText";
export default function EMTemplateTypeDropdown({ onSelect, name, initialValue, error, helperText }) {
  const data = useSelector(state => state.networkAdmin["emailTemplateType"]);
  // let preValue = initialValue ? find(data,{"id" : initialValue}) : ''
  // preValue = preValue !== '' ? preValue["email_templ_type"] : ''
  let preValue = initialValue ? find(data,{"id" : initialValue}) : ''
  preValue = preValue !== ''&& preValue !== undefined ? preValue["email_templ_type"] : ''
 
  return (
    <>
    <EMDropdown
      label="Select Template Type"
      data={data || []}
      objKey={"email_templ_type"}
      onChange={selected => {
        onSelect(find(data, { email_templ_type: selected }));
      }}
      prevData={preValue ||""}
      name={name}
      error = {error}
      helperText = {helperText}
    />
      <FormHelperText style = {{color:'red'}}>
          {error}
          {helperText}
        </FormHelperText>
    </>
  );
}
