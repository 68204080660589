import React, { useState, useEffect } from "react";
import EMPage from "../../../components/commans/EMPage";
import {
  Card,
  Typography,
  Grid,
  Paper,
  GridList,
  Box,
  GridListTile,
  GridListTileBar,
  CardContent,
  Tooltip,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { isWidthUp, isWidthDown, withWidth } from "@material-ui/core";
import NetworkDetail from "./NetworkDetails/index";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { ReactComponent as CategoryIcon } from "../../../assets/Icon-awesome-tag.svg";
import { useNavigate } from "react-router";
import EMFilter from "../../../components/user/EMFilter";
import { DISABLE_ENABLE_FILTER_FOR_NETWORKS, NETWORK_BY_ORGANIZATION_FILTER } from "../../../redux/constants/UserPlatform/EMNetworkConstant";
import {
  EMDoGetNetworkByOrganizationFilterAction,
  EMDoGetFilterForNetworkAction,
  EMDoGetAllMyNetworksAction,
} from "../../../redux/actions/UserPlatform/Network/EMMyNetworkAction";
import organizationLogo from "../../../../src/assets/organization_logo.jpg";
import EMSideBar from "../../../components/navigation/EMSideBar";
import { LocalOffer } from "@material-ui/icons";
import { ADD_SUBTABS_TO_GROUP } from "../../../redux/constants/UserPlatform/EMNavigationConstant";
const useStyles = makeStyles((theme) => ({
  // root: {
  //   marginLeft: "4%",
  //   marginTop: "3%"
  // },
  title: {
    fontWeight: "bold",
    marginLeft: "1%",
    marginBottom: "2%",
  },

  networkInfo: {
    textAlign: "center",
    height: "80px",
    // marginTop:'20%'
  },
  formControl: {
    paddingTop: theme.spacing(1.5),
    fontWeight: "bolder",
    fontSize: "large",
    width: "100%",
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 2,
    wordBreak: "break-word",
    overflow: "hidden",
  },
  gridListTile: {
    margin: "1%",
    borderRadius: "10px",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);",
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: "100%",
    height: "100%",
  },
  typo:{
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 2,
    wordBreak:"break-word",
    overflow: "hidden",
  },
}));

function EMNetworkDetails(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => state.myNetwork || {});
  const groupData = useSelector((state) => state.groups || {});
  const [selectedNetworks, setSelectedNetworks] = React.useState([]);

  useEffect(() => {
    dispatch(EMDoGetAllMyNetworksAction());
    dispatch(EMDoGetFilterForNetworkAction());
    dispatch({type:ADD_SUBTABS_TO_GROUP})
    dispatch({
      type:DISABLE_ENABLE_FILTER_FOR_NETWORKS,
      payload:true
    })
  }, []);

  const theme = useTheme();

  const matchesMediumScreen = useMediaQuery(theme.breakpoints.up("md"));

  const matchesLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  function getCols(screenWidth) {
    if (isWidthUp("xl", screenWidth)) {
      return 4;
    }
    if (isWidthUp("lg", screenWidth)) {
      return 4;
    }
    if (isWidthUp("md", screenWidth)) {
      return 3;
    }
    if (isWidthUp("sm", screenWidth)) {
      return 3;
    }
    if (isWidthUp("xs", screenWidth)) {
      return 2;
    }

    return 5;
  }

  const cols = getCols(props.width);

  return (
    <EMPage
      title="Network"
      style={{
        width: "100%",
        paddingLeft: matchesLargeScreen ? "8%" : "4%",
        paddingTop: matchesLargeScreen
          ? "4%"
          : matchesMediumScreen
          ? "6%"
          : "8%",
        paddingRight: matchesLargeScreen ? "8%" : "4%",
        paddingBottom: "2%",
      }}
    >
      <Grid
        xs={12}
        container
        direction="row"
        alignItems="flexStart"
        spacing={3}
      >
        {matchesMediumScreen ? (
          <Grid item xs={matchesLargeScreen ? 2 : 3}>
            <EMSideBar />
          </Grid>
        ) : null}
        <Grid item xs={matchesLargeScreen ? 7 : matchesMediumScreen ? 9 : 12}>
          <Paper elevation={2}>
            <div style={{ padding: "2%" }}>
              <Typography className={classes.title} variant={"h5"}>
                {"Your Networks"}
              </Typography>
              <div className={classes.root}>
                <GridList className={classes.gridList} cols={cols}>
                  {data.myNetworks.length > 0 &&
                    data.myNetworks.map((network) => (
                      <GridListTile
                        className={classes.gridListTile}
                        style={{ height: "100%" }}
                      >
                        <Card
                          // style={{ maxWidth: "100%", }}
                          onClick={() => {
                            navigate("details", {
                              state: { NetworkId: network.id },
                            });
                            window.sessionStorage.setItem(
                              "NetworkId",
                              network.id
                            );
                          }}
                          elevation={2}
                        >
                          {/* <div style={{ height: "150px" }}> */}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                overflow: "hidden",
                                height: "100%",
                                backgroundColor: "#d3d3d3",
                              }}
                            >
                              <img
                                alt=""
                                style={{
                                  flexShrink: 0,
                                  minWidth: "100%",
                                  minHeight: "100%",
                                }}
                                src={
                                  network.logo_file
                                    ? process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(
                                        0,
                                        -1
                                      ) + network.logo_file
                                    : organizationLogo
                                }
                              />
                            </div>
                          {/* </div> */}
                          <CardContent className={classes.networkInfo}>
                            <Tooltip
                              title={
                                <h2 style={{ color: "white" }}>
                                  {network.partnership_name}
                                </h2>
                              }
                              placement="top"
                            >
                              <Typography style={{ marginTop: "1%" }} className={classes.typo}>
                                {network.partnership_name}
                              </Typography>
                            </Tooltip>
                          </CardContent>
                        </Card>
                      </GridListTile>
                    ))}
                </GridList>
              </div>
            </div>
          </Paper>
        </Grid>
        {matchesLargeScreen ? (
          <Grid item lg={3}>
            <Paper elevation={2} style={{ padding: "5%" }}>
              <Grid container direction="row">
                <Grid item xs={2}>
                  <LocalOffer fontSize="large" color="primary" />
                </Grid>
                <Grid item xs={10}>
                  <Typography
                    style={{
                      color: "#2F92D6",
                      fontSize: "medium",
                      fontWeight: "bolder",
                    }}
                  >
                    NETWORK FILTER
                  </Typography>
                </Grid>
              </Grid>
              <FormControl className={classes.formControl}>
                {data.networkFilter &&
                  data.networkFilter.map((group, key) => (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                          disabled={data.isFilterDisabled}
                            checked={group.value}
                            name={group.organization_name}
                            onChange={(event) => {
                              dispatch({
                                type:DISABLE_ENABLE_FILTER_FOR_NETWORKS,
                                payload:true
                              })
                              let arr;
                              if (event.target.checked) {
                                let checkedValue = event.target.name;
                                arr =
                                  selectedNetworks.length > 0
                                    ? [...selectedNetworks, group]
                                    : [group];
                                setSelectedNetworks(arr);
                                dispatch({
                                  type: NETWORK_BY_ORGANIZATION_FILTER,
                                  payload: {
                                    checked_value: !group.value,
                                    postIndex: key,
                                  },
                                });
                              } else {
                                //find this value in array and delete it
                                let checkedValue = event.target.name;
                                let index = selectedNetworks.findIndex(
                                  (x) => x.organization_name == checkedValue
                                );
                                selectedNetworks.splice(index, 1);
                                arr = selectedNetworks;
                                dispatch({
                                  type: NETWORK_BY_ORGANIZATION_FILTER,
                                  payload: {
                                    checked_value: !group.value,
                                    postIndex: key,
                                  },
                                });
                              }
                              if (arr.length > 0) {
                                //
                                if (arr.length == 1) {
                                  let selectedNetwork = arr[0];
                                  let id = selectedNetwork.id;
                                  let calendarView = data.selectedDate;
                                  dispatch(
                                    EMDoGetNetworkByOrganizationFilterAction({
                                      id: id,
                                    })
                                  );
                                } else if (arr.length > 1) {
                                  let groupAction;

                                  let groupIds = [];

                                  arr.map((selectedGroup, key) => {
                                    groupIds.push(selectedGroup.id);
                                    groupAction = `${groupIds.toString()}`;
                                  });
                                  dispatch(
                                    EMDoGetNetworkByOrganizationFilterAction({
                                      id: groupAction,
                                    })
                                  );
                                }
                              } else if (arr.length == 0) {
                                dispatch(EMDoGetAllMyNetworksAction());
                              }
                            }}
                          />
                        }
                        label={group.organization_name}
                        key={group.id}
                      />
                    </FormGroup>
                  ))}
              </FormControl>
            </Paper>
          </Grid>
        ) : null}
      </Grid>
    </EMPage>
  );
}

export default withWidth()(EMNetworkDetails);
