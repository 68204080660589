import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import EMTopBar from "../../components/navigation/EMUserTopBar";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { EMDoGetCommonAdminPanelPermissionsAction, EMDoGetCommonGlobalPermissionsAction, EMDoGetLoggedInUserRolesAction } from "../../redux/actions/EMAuthActions";
import { useLocation } from "react-router";
import { EMDoGetNotificationsAction } from "../../redux/actions/EMCommonActions";
import {
  POST_CATEGORY_SELECTED_DATA,
  SHOW_CREATE_POST,
} from "../../redux/constants/UserPlatform";
import { CHANGE_USER_TO_ADMIN } from "../../redux/constants/UserPlatform/EMNavigationConstant";
import { CLEAR_GROUP_DATA } from "../../redux/constants/UserPlatform/EMGroupConstant";
import { CLEAR_CHANNEL_DATA } from "../../redux/constants/UserPlatform/EMChannelConstant";
import withNavigate from "../../components/navigation/EMWithNavigate";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
}));

const DashboardLayout = (props) => {
  const cssClasses = useStyles();
  const location = useLocation();
  const userData = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  let topBar = <EMTopBar />;
  useEffect(() => {
      // dispatch(EMDoGetLoggedInUserRolesAction());
      dispatch(EMDoGetCommonAdminPanelPermissionsAction())
      dispatch(EMDoGetCommonGlobalPermissionsAction())

    dispatch(EMDoGetNotificationsAction({limit:5, offset:0}));
    dispatch({
      type: SHOW_CREATE_POST,
      payload: { value: false, route: "create_post" },
    });
    dispatch({
      type: SHOW_CREATE_POST,
      payload: { value: false, route: "create_event" },
    });

    dispatch({
      type: POST_CATEGORY_SELECTED_DATA,
      payload: null,
    });
    dispatch({type:CLEAR_GROUP_DATA})
    dispatch({type:CLEAR_CHANNEL_DATA})
  }, [location]);
  return <div className={cssClasses.root}>{topBar}</div>;
};

export default DashboardLayout;
