import React, { useState, useEffect, useRef } from "react";
import EMPage from "../../../components/commans/EMPage";
import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Box,
  Button,
  makeStyles,
  Grid,
  Tooltip,
} from "@material-ui/core";
import {
  Chart,
  BarSeries,
  Title,
  ArgumentAxis,
  ValueAxis,
  Legend,
} from "@devexpress/dx-react-chart-material-ui";
import { Animation, Stack, ValueScale } from "@devexpress/dx-react-chart";
import { Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

const ProgressBar = (props) => {
  const { completed, number1, number2 } = props;

  const containerStyles = {
    height: 20,
    width: "90%",
    backgroundColor: "#e0e0de",
    borderRadius: 50,
    margin: 50,
    position: "relative",
  };

  const fillerStyles = {
    height: "100%",
    width: `${completed}%`,
    backgroundImage: "linear-gradient(to right,#2F92D6, #000080 )",
    borderRadius: "inherit",
    textAlign: "right",
  };

  const circleStyles1 = {
    display: "flex",
    position: "absolute",
    right: "0%",
    width: "50px",
    height: "50px",
    background: "#2F92D6",
    top: "-60%",
    borderRadius: "50px",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
  };

  const circleStyles2 = {
    display: "flex",
    position: "absolute",
    right: "50%",
    width: "50px",
    height: "50px",
    background: "#2F92D6",
    top: "-60%",
    borderRadius: "50px",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
  };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
        <span style={circleStyles1}>{number1}</span>
        <span style={circleStyles2}>{number2}</span>
      </div>
    </div>
  );
};

const item = { bgcolor: "#2F92D6", completed: 100 };

const data = [
  { year: "1950", population: 2.525 },
  { year: "1960", population: 3.018 },
  { year: "1970", population: 3.682 },
  { year: "1980", population: 4.44 },
  { year: "1990", population: 5.31 },
  { year: "2000", population: 6.127 },
  { year: "2010", population: 6.93 },
];

const useStyles = makeStyles((theme) => ({
  Title: {
    textAlign: "center",
    fontWeight: "bolder",
    fontSize: "large",
  },
  circle: {
    width: "100px",
    height: "100px",
    lineHeight: "100px",
    borderRadius: "50%",
    fontSize: "20px",
    color: "#fff",
    textAlign: "center",
    background: "#000080",
  },
  paddingTopClass: {
    [theme.breakpoints.up("xs")]: {
      //width: "12ch",
      paddingTop: "11%",
    },
    [theme.breakpoints.up("sm")]: {
      //width: "12ch",
      paddingTop: "5%",
    },
    [theme.breakpoints.up("md")]: {
      //width: "12ch",
      paddingTop: "6%",
    },
    [theme.breakpoints.up("lg")]: {
      //width: "12ch",
      paddingTop: "5%",
    },
  },
}));

export default function EMOnBoardingReport(props) {
  const classes = useStyles();
  const networkAdminData = useSelector((state) => state.networkAdmin || {});
  const [domain, setDomain] = useState(() => () => [0, 10]);

  const ArgumentLabel = (props, index) => {
    const { text } = props;

    props = {
      ...props,
      text: text.slice(0, 3),
    };
    return (
      <>
        <Tooltip title={<h2 style={{ color: "white" }}>{text}</h2>}>
          <ArgumentAxis.Label {...props} />
        </Tooltip>
      </>
    );
  };

  useEffect(() => {
    if(networkAdminData.onboardingDataNetwork){
      const maxUser =
      networkAdminData.onboardingDataNetwork[0].invitation_performance_data.reduce(
        (p, c) => (p.users > c.users ? p : c)
      );
    if (maxUser) {
      let value = maxUser.users;
      value < 10
        ? setDomain(() => () => [0, 10])
        : setDomain(() => () => [0, value + 2]);
    }
    }
  }, [networkAdminData]);

  return (
    <EMPage title="Onboarding Report">
      <Paper elevation={2} style={{ marginBottom: "1%" }}>
        <Grid container spacing={3}>
          <Grid item xs={10} sm={12} md={6}>
            <Typography
              style={{
                fontWeight: "bolder",
                fontSize: "large",
                paddingLeft: "2%",
              }}
            >
              User Summary
            </Typography>
            <ProgressBar
              bgcolor={item.bgcolor}
              number1={
                networkAdminData.onboardingDataNetwork &&
                networkAdminData.onboardingDataNetwork[0].users_invited
              }
              number2={
                networkAdminData.onboardingDataNetwork &&
                networkAdminData.onboardingDataNetwork[0].accepted_invites
              }
              completed={item.completed}
            />
            <Grid container style={{ paddingLeft: "20%" }}>
              <Grid item xs={6} sm={6} md={6}>
                <Typography>Accepted Invites</Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <Typography>Users Invited</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6} container>
            <Grid item md={6}>
              <Typography
                style={{
                  fontWeight: "bolder",
                  fontSize: "large",
                  paddingLeft: "5%",
                  paddingBottom: "2%",
                }}
              >
                Onboarding Report
              </Typography>
              <Typography
                style={{
                  paddingLeft: "5%",
                  paddingTop: "2%",
                  paddingBottom: "2%",
                }}
              >
                Max user accounts in selected period
              </Typography>

              <Typography
                style={{
                  paddingLeft: "5%",
                  paddingTop: "2%",
                  paddingBottom: "2%",
                }}
              >
                New Members Joined in specific period
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              className={classes.paddingTopClass}
              style={{ paddingLeft: "2%" }}
            >
              <Typography style={{ fontWeight: "bolder", fontSize: "large" }}>
                {networkAdminData.onboardingDataNetwork &&
                  networkAdminData.onboardingDataNetwork[0].max_user_accounts}
              </Typography>

              <Typography
                style={{
                  fontWeight: "bolder",
                  fontSize: "large",
                  paddingTop: "2%",
                }}
              >
                {networkAdminData.onboardingDataNetwork &&
                  networkAdminData.onboardingDataNetwork[0]
                    .members_joined_in_period}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      {networkAdminData.onboardingDataNetwork &&
      networkAdminData.onboardingDataNetwork[0].invitation_performance_data
        .length > 0 ? (
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <Paper elevation={2}>
              <Typography
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  fontSize: "large",
                }}
              >
                Invitation Performance
              </Typography>
              <Grid
                direction="row"
                container
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={3}></Grid>
                <Grid item xs={1}>
                  <Typography
                    style={{
                      transform: "rotate(-90deg)",
                      fontWeight: "bolder",
                      fontSize: "1rem",
                      height: "10rem",
                      width: "10rem",
                    }}
                  >
                    No. of Users
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Chart
                    data={
                      networkAdminData.onboardingDataNetwork &&
                      networkAdminData.onboardingDataNetwork[0]
                        .invitation_performance_data
                    }
                  >
                    <ValueScale modifyDomain={domain} />
                    <ArgumentAxis labelComponent={ArgumentLabel} />
                    <ValueAxis />

                    <BarSeries
                      valueField="users"
                      argumentField="invitation_count"
                    />
                    <Animation />
                  </Chart>
                </Grid>
                <Grid item xs={3}></Grid>
              </Grid>

              <Typography
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  fontSize: "large",
                }}
              >
                No. of Invitations Requested to Join
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      ) : null}
    </EMPage>
  );
}
