import React, { useState } from "react";
import {
  Paper,
  Box,
  Button,
  Modal,
  Typography,
  IconButton,
  Grid,
  FormHelperText,
  Chip,
  Tooltip,
  useTheme,
  Divider,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { makeStyles } from "@material-ui/core";
import { EMSimpleTextFieldView } from "../../../components/EMTextFieldView";
import {
  ArrowLeft,
  ArrowRight,
  CloseRounded,
  Edit,
  InfoRounded,
} from "@material-ui/icons";
import { useFormik, Field } from "formik";
import * as yup from "yup";
import ChipInput from "material-ui-chip-input";
import { useEffect } from "react";
import {
  EMDoCreatePartnershipAction,
  EMDoUpdatePartnershipAction,
  EMDoGetOrganizationAdminsAction,
} from "../../../redux/actions/EMAdminActions";
import { useDispatch, useSelector } from "react-redux";
import { DATATABLE_DATE_FORMAT } from "../../../utils/EMConstants";
import moment from "moment";
import { findIndex, find, difference, filter } from "lodash";
import EMDatepicker from "../../../components/commans/EMDatePicker";
import EMMultiplSelect from "../../../components/commans/EMMultipleSelect";
import API from "../../../api/";
import EMMultiSelect from "../../../components/commans/EMMultiSelect";
import {
  PLATFORM_ADMIN_GET_ADMIN_LIST_BASED_ON_ORGANIZATIONS,
  PLATFORM_ADMIN_GET_ADMIN_LIST_FOR_PARTNERSHIP,
  SHOW_MESSAGE,
} from "../../../redux/constants";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import EMSimpleDatatable from "../../../components/datatables/EMSimpleDatatable";
import { useCallback } from "react";
import { useMemo } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("xs")]: {
      backgroundColor: theme.palette.common.white,
      minWidth: "50%",
      margin: "auto",
      padding: "0px",
      maxHeight: "70%",
      marginTop: "25rem",
      transform: "translate(0, -50%)",
      // marginLeft: "5rem",
      overflowY: "auto",
      overflowX: "hidden",
    },
    [theme.breakpoints.up("sm")]: {
      backgroundColor: theme.palette.common.white,
      height: "auto",
      minWidth: "50%",
      margin: "auto",
      padding: "0px",
      maxHeight: "100%",
      position: "fixed",
      top: "50%",
      transform: "translate(0, -50%)",
      width: "50%",
      overflowY: "auto",
      overflowX: "hidden",
    },
    [theme.breakpoints.up("md")]: {
      backgroundColor: theme.palette.common.white,
      height: "auto",
      minWidth: "50%",
      margin: "auto",
      padding: "0px",
      maxHeight: "100%",
      position: "fixed",
      top: "50%",
      transform: "translate(0, -50%)",
      width: "50%",
      overflowY: "auto",
      overflowX: "hidden",
    },
    [theme.breakpoints.up("lg")]: {
      backgroundColor: theme.palette.common.white,
      height: "auto",
      minWidth: "50%",
      margin: "auto",
      padding: "0px",
      maxHeight: "100%",
      position: "fixed",
      top: "50%",
      transform: "translate(0, -50%)",
      width: "50%",
      overflowY: "auto",
      overflowX: "hidden",
    },
  },
  title: {
    margin: 10,
    flexGrow: 1,
    fontWeight: "bold",
    color: "white",
    fontSize: "large",
  },
  orgTitle: {
    margin: 10,
    flexGrow: 1,
    fontWeight: "bolder",
  },
  form: {
    padding: theme.spacing(3),
    width: "100%",
  },
  buttonHolder: {
    padding: theme.spacing(3),
  },
  chip: {
    marginLeft: "10px",
    marginBottom: "10px",
  },
  item: {
    //display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
  },
  typo: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 1,
    wordBreak: "break-word",
    overflow: "hidden",
  },
}));

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export default function EMAddEditPartnership(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [superAdminEmails, setSuperAdminEmails] = React.useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedAdminInfo, setSelectedAdminInfo] = React.useState({
    organizations: [],
    partnerships: [],
  });

  const [organizationData, setOrganizationData] = React.useState([]);

  const [previousOrganizations, setPreviousOrganizations] = React.useState([]);

  const [limit, setLimit] = useState(15);
  const [offset, setOffset] = useState(0);
  const [filterForTable, setFilterForTable] = useState(0);

  const adminsListForSelctedOrg = useSelector(
    (state) => state.admin.adminsForPartnerships
  );

  const adminsListForSelctedPartnership = useSelector(
    (state) => state.admin.adminsOfSelectedPartnership
  );

  const selectUnselectAdminFromList = (e, orgId, userId) => {
    let state = e.target.checked;
    let adminData = [...adminList];
    let curretOrgnizationIndex = adminData.findIndex((d) => d.id == orgId);
    if (curretOrgnizationIndex >= 0) {
      let curretAdminIndex = adminData[curretOrgnizationIndex].admins.findIndex(
        (d) => d.id == userId
      );
      if (curretAdminIndex >= 0) {
        adminData[curretOrgnizationIndex].admins[curretAdminIndex].is_checked =
          state;
      }
    }
    debugger
    setAdminList(adminData);
  };

  const [adminList, setAdminList] = useState([]);

  useEffect(() => {
    let currentAdminData = [];
    let incomingAdminData = [];
    if (adminList && adminList.length > 0) {
      currentAdminData = [...adminList];
    }
    if (adminsListForSelctedOrg && adminsListForSelctedOrg?.length > 0) {
      incomingAdminData = [...adminsListForSelctedOrg];
    }
    const updatedAdminData = incomingAdminData.map((currentObj) => {
      const resultObj = currentAdminData.find(
        (resultObj) => resultObj.id === currentObj.id
      );

      if (resultObj) {
        const updatedAdmins = currentObj.admins.map((admin) => {
          const resultAdmin = resultObj.admins.find(
            (resultAdmin) => resultAdmin.id === admin.id
          );

          if (resultAdmin && resultAdmin.is_checked) {
            return { ...admin, is_checked: true };
          }

          return admin;
        });

        return { ...currentObj, admins: updatedAdmins };
      }

      return currentObj;
    });

    let idArray = updatedAdminData.map((obj) => {
      const filteredAdmins = obj.admins.filter(
        (admin) => admin.is_checked === true
      );
      const idArray = filteredAdmins.map((admin) => admin.id);
      return idArray;
    });
    idArray = [].concat.apply([], idArray);
    formik.setFieldValue("admin_emails", idArray);

    setAdminList(updatedAdminData);
  }, [adminsListForSelctedOrg]);

  const theme = useTheme();

  const clearSelectedOrgData = () => {
    dispatch({
      type: PLATFORM_ADMIN_GET_ADMIN_LIST_BASED_ON_ORGANIZATIONS,
      payload: [],
    });
    dispatch({
      type: PLATFORM_ADMIN_GET_ADMIN_LIST_FOR_PARTNERSHIP,
      payload: [],
    });
  };

  const CustomButtonGroup = ({ next, previous }) => {
    return (
      <div className="custom-button-group">
        <IconButton
          onClick={() => {
            setLimit(15);
            setOffset(0);
            setFilterForTable((filter) => filter - 1);
            previous();
          }}
          className="custom-button-left"
        >
          <ArrowLeft style={{ fontSize: "24px" }} />
        </IconButton>
        <IconButton
          className="custom-button-right"
          onClick={() => {
            next();
            setLimit(15);
            setFilterForTable((filter) => filter + 1);
            setOffset(0);
          }}
        >
          <ArrowRight style={{ fontSize: "24px" }} />
        </IconButton>

        <style jsx>{`
          .custom-button-group {
            position: absolute;
            top: 0;
            width: 100%;
          }
          .custom-button-left {
            right: -1%;
          }
          .custom-button-right {
            left: 85%;
          }
        `}</style>
      </div>
    );
  };

  const columns = [
    {
      title: "E-mail Address",
      field: "email",
      align: "left",
      sorting: false,
      width: `calc((100% - 0px) / 2.5)`,
      render: (data) => {
        let emailId = data["email"];
        return (
          <>
            <Tooltip
              title={<h2 style={{ color: "white" }}>{emailId}</h2>}
              placement="top"
            >
              <Typography className={classes.typo}>{emailId}</Typography>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Number of organizations/partnerships for which user is admin of",
      align: "center",
      field: "organizations",
      sorting: false,
      width: `calc((100% - 0px) / 2.5)`,
      headerStyle: {
        whiteSpace: "normal",
        textAlign: "center",
      },
      render: (data) => {
        let noOfOrg = data.organizations?.length;
        let noOfPartnerships = data.partnerships?.length;
        return (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography>{`${noOfOrg} / ${noOfPartnerships}`}</Typography>
            <IconButton
              onClick={() => {
                setSelectedAdminInfo({
                  organizations: data.organizations,
                  partnerships: data.partnerships,
                });
                openInfoModal();
              }}
            >
              <InfoRounded style={{ fontSize: "18px" }} />
            </IconButton>
          </Box>
        );
      },
    },
    {
      title: "Action",
      align: "center",
      sorting: false,
      width: `calc((100% - 0px) / 4)`,
      render: (data) => {
        return (
          <Box
            style={{
              height: "35px",
              width: "35px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              marginLeft: "30%",
            }}
          >
            <label
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <input
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                type="checkbox"
                className="input-checkbox"
                checked={data["is_checked"]}
                onChange={(event) => {
                  let currentAdmins = [...formik.values.admin_emails];
                  if (event.target.checked) {
                    currentAdmins.push(data.id);
                    formik.setFieldValue("admin_emails", currentAdmins);
                  } else {
                    let currentIndex = currentAdmins.findIndex(
                      (d) => d == data.id
                    );
                    if (currentIndex >= 0) {
                      currentAdmins.splice(currentIndex, 1);
                      formik.setFieldValue("admin_emails", currentAdmins);
                    }
                  }
                  selectUnselectAdminFromList(
                    event,
                    data.organization_id,
                    data.id
                  );
                }}
              />
              <svg
                className={`checkbox ${
                  data["is_checked"] ? "checkbox--active" : ""
                }`}
                aria-hidden="true"
                viewBox="0 0 15 11"
                fill="none"
              >
                <path
                  d="M1 4.5L5 9L14 1"
                  strokeWidth="2"
                  stroke={data["is_checked"] ? "#fff" : "none"}
                />
              </svg>
            </label>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    if (props.partnershipData && props.partnershipData["organization"]) {
      setPreviousOrganizations(
        props.partnershipData &&
          props.partnershipData["organization"].length > 0 &&
          props.partnershipData["organization"].map(
            (org) => org["organization_name"]
          )
      );
    } else {
      setPreviousOrganizations([]);
    }
  }, [props.partnershipData]);

  const validationSchema = yup.object({
    partnership_name: yup
      .string("Enter Partner Network Name")
      .trim()
      .max(150, "Maximum 150 characters are allowed")
      .required("Partner Network Name is required"),
    organization: yup
      .array()
      .transform(function (value, originalValue) {
        if (this.isType(value) && value !== null) {
          return value;
        }
        return originalValue ? originalValue.split(/[\s,]+/) : [];
      })
      .required("Organization is required")
      .min(2, "Select Atleast 2 Organizations"),
    admin_emails: yup
      .array()
      .min(1, "Please select admins from each organization")
      .required("Admin email is required"),
    start_date: yup.date(),
    end_date: yup
      .date()
      .min(
        yup.ref("start_date"),
        "Subscription End Date Must be After Subscription Start Date"
      ),
  });

  useEffect(() => {
    if (props.partnershipData) {
      filter(props.partnershipData["users"], {
        role_name: "Super Admin",
      }).map((x) => setSuperAdminEmails(x["email"]));
    }
  }, [props.partnershipData]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      partnership_name: props.partnershipData["partnership_name"] || "",
      organization: props.partnershipData["organization"]
        ? props.partnershipData["organization"].map(
            (p) => p["organization_name"]
          )
        : [],
      super_admin_email: props.partnershipData["users"]
        ? props.partnershipData["users"]
            .filter((x) => x.role_name == "Super Admin")
            .map((x) => x["email"])
        : [],
      admin_emails: props.partnershipData["users"]
        ? props.partnershipData["users"]
            .filter((x) => x.role_name == "Admin")
            .map((x) => x["email"])
        : [],

      start_date: props.partnershipData["start_date"]
        ? moment(props.partnershipData["start_date"]).format(
            DATATABLE_DATE_FORMAT
          )
        : moment().format(DATATABLE_DATE_FORMAT),

      end_date: props.partnershipData["end_date"]
        ? moment(props.partnershipData["end_date"]).format(
            DATATABLE_DATE_FORMAT
          )
        : moment(
            moment().format(DATATABLE_DATE_FORMAT),
            DATATABLE_DATE_FORMAT
          ).add(1, "month"),
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      props.setDisabled(true);
      let organization =
        values.organization &&
        values.organization.map(
          (item) => find(organizationData, { organization_name: item }).id
        );

      let orgArray = [];
      organization.map((org) => {
        orgArray.push({ id: org });
      });

      values.start_date = moment(values.start_date).utc();
      values.end_date = moment(values.end_date).utc();
      organization = orgArray;

      if (props.partnershipData && props.partnershipData.id > 0) {
        let deleted_admin_ids = difference(
          props.partnershipData["users"]
            .filter((x) => x.role_name == "Admin")
            .map((x) => x["id"]),
          formik.values.admin_emails
        );
        let deleted_super_admin_email = difference(
          props.partnershipData["users"]
            .filter((x) => x.role_name == "Super Admin")
            .map((x) => x["email"]),
          formik.values.super_admin_email
        );
        let new_super_admin_email = formik.values.super_admin_email;
        let new_admin_ids = difference(
          formik.values.admin_emails,
          filter(props.partnershipData["users"], {
            role_name: "Admin",
          }).map((x) => x["id"])
        );
        let newPartnershipData = {
          ...props.partnershipData,
          ...values,
          organization,
          deleted_admin_ids: deleted_admin_ids?.join(","),
          deleted_super_admin_email,
          new_super_admin_email,
          new_admin_ids: new_admin_ids?.join(","),
        };
        dispatch(
          EMDoUpdatePartnershipAction({
            ...newPartnershipData,
            key: "partnership_add_edit",
            admin_ids: formik.values.admin_emails.join(","),
          })
        );
      } else {
        let newPartnershipData = {
          ...values,
          organization,
          admin_ids: formik.values.admin_emails.join(","),
          //super_admin_email: values.super_admin_email[0]
        };
        dispatch(
          EMDoCreatePartnershipAction({
            ...newPartnershipData,
            key: "partnership_add_edit",
          })
        );
      }
    },
  });

  useMemo(() => {
    if (props && props.partnershipData && Object.keys(props.partnershipData).length > 0) {
      let idArray = adminsListForSelctedPartnership?.map((obj) => {
        const filteredAdmins = obj.admins.filter(
          (admin) => admin.is_checked === true
        );
        const idArray = filteredAdmins?.map((admin) => admin.id);
        return idArray;
      });
      idArray = [].concat.apply([], idArray);
      formik.setFieldValue("admin_emails", idArray);
      setAdminList(adminsListForSelctedPartnership);
    }
  }, [adminsListForSelctedPartnership]);

  const getOrganizationListFromServer = () => {
    API.EMGetAllOrganizations()
      .then((data) => data.data)
      .then((data) => {
        if (data) {
          setOrganizationData(data);
          isPrevOrgExist(data);
        }
      })
      .catch((err) => {});
  };

  //reset the form after modal is closed
  useEffect(() => {
    getOrganizationListFromServer();
    return () => {
      formik.resetForm();
    };
  }, [props.open]);

  const isPrevOrgExist = (allOrgList) => {
    if (props.partnershipData && props.partnershipData["organization"]) {
      const res = props.partnershipData["organization"].filter(
        (x) =>
          !allOrgList.some((y) => y.organization_name === x.organization_name)
      );

      const notExistOrgArray = res.map((x) => x.organization_name);

      if (notExistOrgArray.length !== 0) {
        dispatch({
          type: SHOW_MESSAGE,
          payload: {
            type: "error",
            message: `${notExistOrgArray.toString()} Organizations are expired or deactived!`,
            key: "orgKey",
          },
        });
      }
    }
  };

  const openInfoModal = () => {
    setOpenModal(true);
  };

  const closeInfoModal = () => {
    setOpenModal(false);
  };

  return (
    <Modal
      open={props.open}
      onClose={() => {
        clearSelectedOrgData();
        props.close();
      }}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box className={classes.root} style={{ outline: "none" }}>
        <Modal
          open={openModal}
          onClose={closeInfoModal}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Paper
            elevation={2}
            style={{ width: "20%", height: "50%", overflowY: "auto" }}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography className={classes.orgTitle}>Admin Of</Typography>
              <IconButton onClick={closeInfoModal} style={{ float: "right" }}>
                <CancelIcon />
              </IconButton>
            </Box>
            <Box style={{ padding: "0.5%" }}>
              <Typography className={classes.orgTitle}>
                Organizations
              </Typography>
              <Box style={{ paddingLeft: "10%" }}>
                {selectedAdminInfo.organizations?.map((d) => (
                  <Typography>- {d}</Typography>
                ))}
              </Box>
            </Box>
            <Divider />
            <Box style={{ padding: "0.5%" }}>
              <Typography className={classes.orgTitle}>Partnerships</Typography>
              <Box style={{ paddingLeft: "10%" }}>
                {selectedAdminInfo.partnerships?.map((d) => (
                  <Typography>- {d}</Typography>
                ))}
              </Box>
            </Box>
          </Paper>
        </Modal>
        <form onSubmit={formik.handleSubmit}>
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            style={{ padding: "0px 4px 0px 18px", backgroundColor: "#2F92D6" }}
          >
            <Typography className={classes.title}>{props.pagelabel}</Typography>
            <IconButton
              onClick={() => {
                clearSelectedOrgData();
                props.close();
              }}
            >
              <CloseRounded style={{ color: "white" }} />
            </IconButton>
          </Box>
          <Grid
            container
            justify="flex-start"
            alignItems="flex-start"
            direction="column"
            spacing={2}
            className={classes.form}
          >
            <Grid item xs={12} style={{ width: "100%" }}>
              <EMSimpleTextFieldView
                label={"Partner Network Name"}
                id="partnership_name"
                name="partnership_name"
                value={formik.values.partnership_name}
                onChange={formik.handleChange}
                // error={
                //   formik.touched.partnership_name &&
                //   Boolean(formik.errors.partnership_name)
                // }
                helperText={
                  formik.touched.partnership_name &&
                  formik.errors.partnership_name
                }
              />
            </Grid>
            <Typography className={classes.orgTitle}>
              {"Partner Organizations"}
            </Typography>
            <Grid item xs={12} style={{ width: "100%" }}>
              <EMMultiSelect
                disabled={false}
                label={"Select Organization"}
                data={organizationData}
                objKey={"organization_name"}
                prevData={previousOrganizations}
                multiple={true}
                formik={{
                  errors: formik.errors.organization,
                  touched: formik.touched.organization,
                }}
                onChange={(selectedOrganziations) => {
                  let selectedOrgIds = selectedOrganziations.map((name) => {
                    let matchedObj = organizationData.find(
                      (obj) => obj.organization_name == name
                    );

                    return matchedObj ? matchedObj.id : null;
                  });
                  if (selectedOrgIds?.length > 0) {
                    dispatch(EMDoGetOrganizationAdminsAction(selectedOrgIds));
                  } else {
                    clearSelectedOrgData();
                  }
                  formik.setFieldValue("organization", selectedOrganziations);
                }}
              />
            </Grid>
            {props.partnershipData &&
            formik.values.super_admin_email?.length > 0 ? (
              <Grid item xs style={{ width: "100%" }}>
                <ChipInput
                  disabled
                  name="super_admin_email"
                  id="super_admin_email"
                  type="email"
                  FormHelperTextProps={{ style: { color: "red" } }}
                  InputLabelProps={{ style: { color: "#4d4d4d" } }}
                  label="Superadmin E-mail Address"
                  helperText={
                    formik.errors.super_admin_email &&
                    formik.touched.super_admin_email
                      ? formik.errors.super_admin_email
                      : ""
                  }
                  value={formik.values.super_admin_email}
                  chipRenderer={({ value }, key) => (
                    <Chip
                      className={classes.chip}
                      key={key}
                      label={value}
                      deleteIcon={<CancelIcon />}
                      onDelete={
                        superAdminEmails.length > 0 &&
                        superAdminEmails.includes(value)
                          ? undefined
                          : () => {
                              let oldValues = [
                                ...formik.values.super_admin_email,
                              ];
                              let index = key;
                              if (index > -1) {
                                oldValues.splice(index, 1);
                                formik.setFieldValue(
                                  "super_admin_email",
                                  oldValues
                                );
                              }
                            }
                      }
                    ></Chip>
                  )}
                  margin="none"
                  onAdd={(chip) => {
                    if (chip) {
                      let oldvalues = [...formik.values.super_admin_email];
                      let index = findIndex(oldvalues, chip);
                      if (index === -1) {
                        oldvalues.push(chip.toLowerCase());
                        formik.setFieldValue("super_admin_email", oldvalues);
                      }
                    }
                  }}
                  fullWidth
                />
              </Grid>
            ) : null}

            <Typography className={classes.orgTitle}>
              Select admins to add in partnership
            </Typography>
            <Grid container xs={12} style={{ width: "100%" }}>
              <Carousel
                customButtonGroup={<CustomButtonGroup />}
                arrows={false}
                additionalTransfrom={0}
                className="react-multi-carousel-track"
                swipeable={false}
                draggable={false}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlaySpeed={1000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                deviceType="desktop"
                dotListClass="custom-dot-list-style"
                itemClass={classes.item}
              >
                {adminList?.map((data) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: 8,
                    }}
                  >
                    <Paper
                      key={data.id}
                      elevation={4}
                      style={{
                        width: "100%",
                        height: "max-content",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: 14,
                          fontWeight: "bolder",
                          textAlign: "center",
                          padding: 8,
                        }}
                      >
                        {data.organization_name}
                      </Typography>
                      <Box style={{ padding: "0px 8px 8px 8px" }}>
                        <EMSimpleDatatable
                          forPartnershipCreation={true}
                          filter={filterForTable}
                          columns={columns}
                          data={
                            data?.admins?.slice(
                              offset * limit,
                              offset * limit + limit
                            ) || []
                          }
                          count={data?.admins?.length || 0}
                          updatePageAndPageSizeAndOrder={(page, pageSize) => {
                            setOffset(page);
                            setLimit(pageSize);
                          }}
                          options={{
                            headerStyle: {
                              backgroundColor: theme.palette.secondary.main,
                              color: theme.palette.common.white,
                              fontWeight: "bold",
                            },
                          }}
                        />
                      </Box>
                    </Paper>
                  </div>
                ))}
              </Carousel>
            </Grid>
            {formik.errors.admin_emails && formik.touched.admin_emails ? (
              <FormHelperText style={{ color: "red", paddingLeft: "1%" }}>
                {formik.errors.admin_emails}
              </FormHelperText>
            ) : null}
            <Grid
              item
              container
              direction="row"
              justify="space-between"
              alignContent="center"
            >
              <Grid item xs={12} sm={12} md={6}>
                <EMDatepicker
                  label={"Subscription Start Date"}
                  id={"start_date"}
                  value={formik.values.start_date}
                  onSelectDate={(date) => {
                    formik.setFieldValue("start_date", date);
                  }}
                />
              </Grid>
              <Grid xs={12} sm={12} md={6}>
                <EMDatepicker
                  label={"Subscription End Date"}
                  id={"end_date"}
                  value={formik.values.end_date}
                  minDate={formik.values.start_date}
                  onSelectDate={(date) => {
                    formik.setFieldValue("end_date", date);
                  }}
                  error={
                    formik.touched.end_date && Boolean(formik.errors.end_date)
                  }
                  helperText={formik.touched.end_date && formik.errors.end_date}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="row"
            className={classes.buttonHolder}
            spacing={4}
          >
            <Grid item>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                type="submit"
                // disabled = {props.saveClicked}
              >
                <Typography variant="h7">{props.buttonLabel}</Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.button}
                variant="outlined"
                color="secondary"
                onClick={() => {
                  clearSelectedOrgData();
                  props.close();
                }}
              >
                <Typography variant="h7">Discard</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
}
