import React, { useState, useEffect } from "react";
import {
  Paper,
  Box,
  Button,
  Modal,
  Typography,
  IconButton,
  Grid,
  FormControlLabel,
  Hidden,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import EMTextFieldSearch from "../../../../components/datatables/EMDatatableSearchView";
import EMResendInviteTable from "./EMResendInviteTable";
import { CloseRounded, CheckBox } from "@material-ui/icons";
import EMEntityTemplateDropdown from "../../../NetworkAdmin/UserManagement/commons/EMEntityTemplateDropdown";
import Checkbox from "@material-ui/core/Checkbox";
import { find, isEmpty, uniqBy } from "lodash";
// import { EMDoResendInviteToUsersAction } from "../../../../../../redux/actions/EMNetworkAdminActions";
import { useSelector, useDispatch } from "react-redux";
import { EMDoResendInviteToAdminAction } from "../../../../redux/actions/EMAdminActions";
import {
  PLATFORM_ADMIN_SELECT_UNSELECT_USER_IN_RESEND_INVITES,
  PLATFORM_ADMIN_RESEND_INVITES_SELECT_ALL_FLAG,
  PLATFORM_ADMIN_ADD_REMOVE_RESEND_INVITES_FROM_SECONDARY_ARRAY,
  PLATFORM_ADMIN_IMPORT_USERS_CLEAR_UNCLEAR_SELECT_ALL_WRT_SEARCH,
} from "../../../../redux/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("xs")]: {
      backgroundColor: theme.palette.common.white,
      // height: "auto",
      minWidth: "50%",
      margin: "auto",
      padding: "0px",
      // maxWidth: '100vw',
      maxHeight: "50%",
      // position: 'fixed',
      marginTop: "25rem",
      transform: "translate(0, -50%)",
      width: "70%",
      marginLeft: "5rem",
      overflow: "auto",
      overflowY: "auto",
      overflowX: "hidden",
    },
    [theme.breakpoints.up("sm")]: {
      backgroundColor: theme.palette.common.white,
      height: "auto",
      width: "50%",
      marginTop: 250,
      margin: "auto",
      padding: "0px",
      marginLeft: "25%",
      overflowY: "auto",
      overflowX: "hidden",
    },
    [theme.breakpoints.up("md")]: {
      backgroundColor: theme.palette.common.white,
      height: "auto",
      width: "50%",
      marginTop: 250,
      margin: "auto",
      padding: "0px",
      marginLeft: "25%",
      overflowY: "auto",
      overflowX: "hidden",
    },
    [theme.breakpoints.up("lg")]: {
      backgroundColor: theme.palette.common.white,
      height: "auto",
      width: "50%",
      marginTop: 250,
      margin: "auto",
      padding: "0px",
      marginLeft: "25%",
      overflowY: "auto",
      overflowX: "hidden",
    },
  },
  paper: {
    // margin: "3%"
  },
  title: {
    margin: 10,
    flexGrow: 1,
    fontWeight: "bold",
    color: "white",
    fontSize: "large",
  },
  form: {
    padding: "0px 36px",
    width: "100%",
  },
  button: {
    padding: theme.spacing(1),
    margin: 20,
  },
  buttonHolder: {
    padding: theme.spacing(3),
  },
}));

export default function EMResendInvite(props) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(false);
  const [filter, setFilter] = useState("");
  const [tabledata, setTabledata] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectAll, setSelectAll] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const data = useSelector((state) => state.admin.resendUsersList || {});
  const selectAllFlag = useSelector((state) => state.admin.selectAll);
  const selectedUsers = useSelector((state) => state.admin.checkedUsers);

  const uiData = useSelector((state) => state.ui);

  useEffect(() => {
    if (uiData["messages"]) {
      let refObj = find(uiData["messages"], { key: "resend_list_success" });
      if (refObj && refObj.type === "success") {
        setOpen(false);
        props.closeHandler(false);
        setFilter("");
        refObj.key = "resend_list_success1";
        dispatch({
          type: PLATFORM_ADMIN_RESEND_INVITES_SELECT_ALL_FLAG,
          payload: { state: false },
        });
      }
    }
  }, [uiData]);

  useEffect(() => {
    runThisFunctionFirst();
  }, [data]);

  const runThisFunctionFirst = () => {
    return new Promise(function (resolve) {
      if (data.results) {
        setTabledata(data);
      }
      resolve();
    });
  };

  const dispatch = useDispatch();

  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.closeHandler(false);
        setFilter("");
        dispatch({
          type: PLATFORM_ADMIN_RESEND_INVITES_SELECT_ALL_FLAG,
          payload: { state: false },
        });
      }}
    >
      <Box className={classes.root}>
        <form className={classes.paper}>
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            style={{ padding: "0px 4px 0px 18px", backgroundColor: "#2F92D6" }}
          >
            <Typography className={classes.title}>
              {"Resend Invitations"}
            </Typography>
            <IconButton
              onClick={() => {
                props.closeHandler(false);

                setFilter("");
                dispatch({
                  type: PLATFORM_ADMIN_RESEND_INVITES_SELECT_ALL_FLAG,
                  payload: { state: false },
                });
              }}
            >
              <CloseRounded style={{ color: "white" }} />
            </IconButton>
          </Box>
          <Grid
            container
            justify="flex-start"
            style={{ marginLeft: 16, paddingTop: "8px" }}
          >
            <Grid item xs={12} sm={12} md={5}>
              <EMTextFieldSearch
                hint={"Search for User Name, Email-Address"}
                onChangeListener={(data) => {
                  setFilter(data);
                }}
              />
            </Grid>
            {/* <Grid xs={3}>
              <EMDropDown title={"sort by"} options={options} />
            </Grid> */}
            <Grid item xs={12} sm={12} md={3}>
              {data["count"] > 0 ? (
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    className="input-checkbox"
                    checked={selectAllFlag}
                    onChange={(event) => {
                      let state = event.target.checked;
                      dispatch({
                        type: PLATFORM_ADMIN_RESEND_INVITES_SELECT_ALL_FLAG,
                        payload: { state: state },
                      });
                    }}
                  />
                  <svg
                    className={`checkbox ${
                      selectAllFlag ? "checkbox--active" : ""
                    }`}
                    aria-hidden="true"
                    viewBox="0 0 15 11"
                    fill="none"
                  >
                    <path
                      d="M1 4.5L5 9L14 1"
                      strokeWidth="2"
                      stroke={selectAllFlag ? "#fff" : "none"}
                    />
                  </svg>
                  <Typography>Select All</Typography>
                </label>
              ) : (
                <FormControlLabel
                  value="all"
                  control={<Checkbox />}
                  label="Select All"
                  labelPlacement="end"
                  disabled={true}
                />
              )}
            </Grid>
          </Grid>
          <Grid style={{ maxHeight: "750px" }}>
            <EMResendInviteTable
              data={tabledata}
              isChecked={checked}
              filter={filter}
              open={handleOpen}
              close={handleClose}
              onChangePermission={(permissionData, event) => {
                if (tabledata.results) {
                  dispatch({
                    type: PLATFORM_ADMIN_SELECT_UNSELECT_USER_IN_RESEND_INVITES,
                    payload: {
                      userId: permissionData.id,
                      state: event.target.checked,
                    },
                  });
                }
              }}
            />
          </Grid>
          <Hidden smDown>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              className={classes.buttonHolder}
            >
              <Grid item xs={2}>
                <Button
                  // className={classes.button}
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={(e) => {
                    ;
                    e.preventDefault();
                    let keys = [];
                    let requiredUsers = tabledata.results.filter(
                      (d) => d.isChecked == true
                    );
                    keys = requiredUsers.map((x) => x.key);

                    let data = {
                      key: keys,
                      select_all: false,
                    };
                    dispatch(
                      EMDoResendInviteToAdminAction({
                        data,
                        key: "resend_list_success",
                      })
                    );
                    setFilter("");
                  }}
                >
                  <Typography variant="h7">Invite</Typography>
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  // className={classes.button}
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    props.closeHandler(false);

                    setFilter("");
                    dispatch({
                      type: PLATFORM_ADMIN_RESEND_INVITES_SELECT_ALL_FLAG,
                      payload: { state: false },
                    });
                  }}
                >
                  <Typography variant="h7">Discard</Typography>
                </Button>
              </Grid>
            </Grid>
          </Hidden>
          <Hidden lgUp mdUp>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              className={classes.buttonHolder}
            >
              <Grid item xs={2}>
                <Button
                  style={{ marginLeft: "-2rem" }}
                  // className={classes.button}
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    let keys = [];
                    let requiredUsers = tabledata.results.filter(
                      (d) => d.isChecked == true
                    );
                    keys = requiredUsers.map((x) => x.key);

                    let data = {
                      key: keys,
                      select_all: false,
                    };
                    dispatch(
                      EMDoResendInviteToAdminAction({
                        data,
                        key: "resend_list_success",
                      })
                    );
                    setFilter("");
                  }}
                >
                  <Typography variant="h7">Invite</Typography>
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  // className={classes.button}
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    setFilter("");
                    dispatch({
                      type: PLATFORM_ADMIN_RESEND_INVITES_SELECT_ALL_FLAG,
                      payload: { state: false },
                    });
                    props.closeHandler(false);
                  }}
                >
                  <Typography variant="h7">Discard</Typography>
                </Button>
              </Grid>
            </Grid>
          </Hidden>
        </form>
      </Box>
    </Modal>
  );
}
