import {
  Badge,
  Box,
  Button,
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import clsx from "clsx";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
import { Forum, Navigation } from "@material-ui/icons";
import {
  NAVIGATE_BETWEEN_DIFFERENT_PAGES,
  SAVE_GLOBAL_SEARCH,
} from "../../redux/constants/UserPlatform/EMNavigationConstant";
import { POST_DOCUMENT, POST_EXTERNAL_VIDEO_UPLOAD, POST_IMAGES, POST_VIDEOS, USER_DOCUMENT_UPLOAD, USER_EXTERNAL_VIDEO_UPLOAD, USER_IMAGES_UPLOAD, USER_VIDEOS_UPLOAD } from "../../redux/constants/UserPlatform";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
    // minWidth:"100%"
  },
  root: {
    borderRadius: "5px",
    position: "sticky",
  },
  button: {
    color: theme.palette.common.grey,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: "flex-start",
    letterSpacing: 0,
    padding: "10px 8px",
    textTransform: "none",
  },
  icon: {
    //   marginRight: theme.spacing(2.3),
    //   marginTop: theme.spacing(1.3),
    size: "large",
  },
  title: {
    //marginRight: "auto",
    whiteSpace: "normal",
    //   marginRight: "auto",
    //   marginTop: theme.spacing(0.6),
    color: theme.palette.common.black,
    size: 18,
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 1,
    wordBreak:"break-word",
    overflow: "hidden",
  },
  active: {
    "& $title": {
      fontWeight: "bolder",
      color: "rgb(47, 146, 214)",
    },
    "& $icon": {
      color: theme.palette.common.white,
      borderBottom: "solid",
      borderBottomColor: "rgb(47, 146, 214)",
      borderBottomWidth: 3,
    },
  },
  badge: {
    fontSize: 2,
  },

}));

export default function EMSideBar({ items }) {
  const data = useSelector((state) => state.navigation || {});
  const authData = useSelector((state) => state.auth || {})
  const cssClasses = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();

  const matchesLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const navItems = authData.boolean_group_create_quiz_in_group || authData.boolean_group_create_quiz_in_channel ? data.items.slice(0, 8) : data.items.slice(0, 7)

  useEffect(() => {
    dispatch({
      type: NAVIGATE_BETWEEN_DIFFERENT_PAGES,
      payload: {
        href: location.pathname,
      },
    });
    dispatch({ type: USER_IMAGES_UPLOAD, payload: null });
    dispatch({ type: USER_VIDEOS_UPLOAD, payload: null });
    dispatch({ type: USER_DOCUMENT_UPLOAD, payload: null });
    dispatch({ type: USER_EXTERNAL_VIDEO_UPLOAD, payload: null });
    dispatch({
      type: POST_IMAGES,
      payload: undefined,
    });
    dispatch({
      type: POST_VIDEOS,
      payload: undefined,
    });
    dispatch({
      type: POST_DOCUMENT,
      payload: undefined,
    });
    dispatch({
      type: POST_EXTERNAL_VIDEO_UPLOAD,
      payload: undefined,
    });
  }, [location]);

  return (
    <Paper elevation={2}>
      <Box
        p={1}
        style={{
          backgroundColor: "rgb(47, 146, 214)",
          borderRadius: "5px",
        }}
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={1}
          style={{display:"flex"}}
        >
          
          <Grid item xs={12}  container
          direction="row"
          alignItems="center"
          justifyContent="center"
          style={{display:"flex"}}>
            <Typography
              style={{
                fontSize: "medium",
                color: "white",
                fontWeight: "bold",
              }}
            >
              MENU
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box p={1}>
        <MenuList>
          {navItems.map((item) => (
            <ListItem
              className={clsx(cssClasses.item)}
              disableGutters
              //  style={{justifyContent:"center", alignItems:"center"}}
            >
              <Button
                fullWidth
                activeClassName={
                  item.isActive ? cssClasses.active : cssClasses.button
                }
                className={cssClasses.button}
                component={RouterLink}
                to={item.href}
                onClick={() => {
                  dispatch({
                    type: SAVE_GLOBAL_SEARCH,
                    payload: "",
                  });
                  dispatch({
                    type: NAVIGATE_BETWEEN_DIFFERENT_PAGES,
                    payload: {
                      href: location.pathname,
                    },
                  });
                }}
              >
                {item.icon && (
                  <ListItemIcon>
                    <item.icon className={cssClasses.icon} />
                  </ListItemIcon>
                )}
                {/* <span className={cssClasses.title}>{title}</span> */}
                <ListItemText
                  primary={item.title}
                  className={cssClasses.title}
                  primaryTypographyProps={{ style: { whiteSpace: "normal" } }}
                />
                <Badge
                  invisible={!item.isActive}
                  variant="dot"
                  color="primary"
                  className={cssClasses.badge}
                />
              </Button>
            </ListItem>
          ))}
        </MenuList>
      </Box>
    </Paper>
  );
}
