import React, {useState, useEffect} from 'react'
import { Box, makeStyles, Paper, Tooltip, Typography, useTheme } from '@material-ui/core';
import EMPage from "../../../components/commans/EMPage";
import { useDispatch, useSelector } from 'react-redux';
import { EMDoGetDefaultBadgesInOrganizationAction, EMDoGetDefaultLevelsInOrganizationAction } from '../../../redux/actions/EMNetworkAdminActions';
import EMSimpleDatatable from "../../../components/datatables/EMSimpleDatatable";

const useStyles = makeStyles((theme) => ({
  typo: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 1,
    wordBreak: "break-word",
    overflow: "hidden",
  },
}));


export default function LevelAndBadgesTable() {
  const dispatch = useDispatch()
  const data = useSelector(state => state.networkAdmin || {})
  const classes = useStyles()
  const theme = useTheme();

  useEffect(() => {
    dispatch(EMDoGetDefaultLevelsInOrganizationAction())
    dispatch(EMDoGetDefaultBadgesInOrganizationAction())
  },[])

  const levelColumns = [
    {
    title: "Level",
    field: "name",
    align: "left",
    render: data=> {
      return (
        <>
        <Tooltip title={<h2 style={{ color: "white" }}>{data["name"]}</h2>}  placement='top'>
        <Typography className={classes.typo}>{ data["name"] && data["name"] }</Typography>
        </Tooltip>
        </>
      )
    }
    },
    {
    title: "Lower Limit",
    field: "lower_limit",
    align: "left",
    render: data=> {
      return (
        <>
        <Tooltip title={<h2 style={{ color: "white" }}>{data["lower_limit"]}</h2>}  placement='top'>
        <Typography className={classes.typo}>{ data["lower_limit"] && data["lower_limit"] }</Typography>
        </Tooltip>
        </>
      )
    }
    },
    {
      title: "Upper Limit",
      field: "upper_limit",
      align: "left",
      render: data=> {
        return (
          <>
          <Tooltip title={<h2 style={{ color: "white" }}>{data["upper_limit"]}</h2>}  placement='top'>
          <Typography className={classes.typo}>{ data["upper_limit"] && data["upper_limit"] }</Typography>
          </Tooltip>
          </>
        )
      }
      },
];  

const badgesColumns = [
  {
  title: "Badge",
  field: "badge_name",
  align: "left",
  render: data=> {
    return (
      <>
      <Tooltip title={<h2 style={{ color: "white" }}>{data["badge_name"]}</h2>}  placement='top'>
      <Typography className={classes.typo}>{ data["badge_name"] && data["badge_name"] }</Typography>
      </Tooltip>
      </>
    )
  }
  },
  {
  title: "Condition",
  field: "condition",
  align: "left",
  render: data=> {
    return (
      <>
      <Tooltip title={<h2 style={{ color: "white" }}>{data["condition"]}</h2>}  placement='top'>
      <Typography className={classes.typo}>{ data["condition"] && data["condition"] }</Typography>
      </Tooltip>
      </>
    )
  }
  },
];  

  return (
    <EMPage title="Levels and Badges" >
      <Paper elevation={2}>
        <Box p={2}>
          <Typography style={{fontSize:"medium", fontWeight:"bolder", paddingBottom:"16px"}}>
            Levels:
          </Typography>
        <EMSimpleDatatable
            columns={levelColumns}
            data={(data["defaultLevels"] && data["defaultLevels"]["levels"]) || []}
            count={0}
            updatePageAndPageSizeAndOrder={(page, pageSize, order) => {
            
            }}
            options={{
                headerStyle: {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.common.white,
                fontWeight: "bold"
                }
            }}
            />
        </Box>
        <Box p={2}>
          <Typography style={{fontSize:"medium", fontWeight:"bolder", paddingBottom:"16px"}}>
            Badges:
          </Typography>
        <EMSimpleDatatable
            columns={badgesColumns}
            data={(data["defaultBadges"] && data["defaultBadges"]["badges"] )|| []}
            count={0}
            updatePageAndPageSizeAndOrder={(page, pageSize, order) => {
            
            }}
            options={{
                headerStyle: {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.common.white,
                fontWeight: "bold"
                }
            }}
            />
        </Box>
      </Paper>
    </EMPage>
  )
}