import {
  Paper,
  Box,
  Button,
  Grid,
  CircularProgress,
  Modal,
  Typography,
  makeStyles,
  Hidden,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import EMPage from "../../../../components/commans/EMPage";
import EMDatatableSearchView from "../../../../components/datatables/EMDatatableSearchView";
import EMEmailTemplateTable from "./EMEmailTemplateTable";
import EMAddEditEmailTemplate from "./EMAddEditEmailTemplate";
import { useDispatch, useSelector } from "react-redux";
import { findLast, isEmpty, findIndex } from "lodash";
import EMEntityDropdown from "../../UserManagement/commons/EMEntityDropdown";
import { EMDoGetEntityInformationBasedOnPermissionAction } from "../../../../redux/actions/EMAuthActions";
import { find } from "lodash";
import { EMDoGetListOfNetworksForDropdownAction, EMDoGetPermissionSpecificToNetworkAction } from "../../../../redux/actions/EMNetworkAdminActions";
const useStyles = makeStyles((theme) => ({
  justifyEnd: {
    [theme.breakpoints.up("md")]: {
      justifyContent: "end",
    },
    [theme.breakpoints.up("lg")]: {
      justifyContent: "end",
    },
  },
}));
export default function NetworkEmailTemplateManagement(props) {
  const classes = useStyles();
  const [filter, setFilter] = useState("");
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [editData, setEditData] = React.useState({});
  const [entity, setEntity] = React.useState();
  const [showNetworkError, setShowNetworkError] = React.useState();
  const [isError, setIsError] = React.useState(true);
  const [labels, setLabels] = React.useState({
    titleLable: "",
    buttonLabel: "",
  });
  const [edit, setEdit] = React.useState(false);
  const [saveClicked, setDisabled] = React.useState(false);
  const userData = useSelector((state) => state.auth);
  const uiData = useSelector((state) => state.ui);
  const [permissionData, setPermissionData] = React.useState();
  const networkSpecificPermissions = useSelector(state => state.networkAdmin.networkSpecificRolesAndPermissions)

  useEffect(()=>{
    if(networkSpecificPermissions && entity){
      let permission = find(networkSpecificPermissions,{context:entity.context})
      let specificPermission = find(permission && permission.rolePermissions,{permission_name:"manage_email_templates"})
      setPermissionData(specificPermission)
    }
  },[networkSpecificPermissions])

  //call dropdown Api
  useEffect(() => {
    dispatch(EMDoGetListOfNetworksForDropdownAction());
  }, []);

  useEffect(() => {
    if(entity){
      dispatch(EMDoGetPermissionSpecificToNetworkAction({id:entity.context, networkType:entity.entity_type}))
    }
  },[entity])


  /* Show error if entity is not selected */
  const networkChangeHandler = () => {
    if (isEmpty(entity)) {
      setShowNetworkError("Please Select Network Here");
      setIsError(false);
    } else {
      setShowNetworkError();
      setIsError(true);
    }
  };

  useEffect(() => {
    if (!isEmpty(entity)) {
      setShowNetworkError();
      setIsError(true);
    }
  }, [entity]);

  /* open and close modal functionality */
  const handleClose = () => {
    setOpen(false);
    setEditData({});
  };

  useEffect(() => {
    if (uiData["messages"] && uiData.loading !== true) {
      let refObj = findLast(uiData["messages"], { key: "user_add_edit" });
      if (refObj && refObj.type === "success") {
        setOpen(false);
        setDisabled(false);
        //   refObj.type = "failed"
      } else if (refObj && refObj.type === "error") {
        setDisabled(false);
        //   refObj.type = "failed"
      }
    }
  }, [uiData]);

  let isDisabled = (permissionData && permissionData["add_flag"]) || true;

  return (
    <EMPage title="Email Templates">
      <Box>
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={uiData.loading.state}
        >
          <Box
            style={{ display: "flex" }}
            alignItems="center"
            justifyContent="center"
          >
            <Box textAlign="center">
              <CircularProgress />
              <Typography style={{ fontWeight: "medium", fontSize: "large" }}>
                {uiData.loading.message}
              </Typography>
            </Box>
          </Box>
        </Modal>
        <Paper elevation={2}>
          <Box height="100%" display="flex" flexDirection="column">
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              p={3}
              justifyItems="center"
              alignItems="center"
            >
              <Grid container style={{ maxWidth: "100%" }} justify="flex-start">
                <Grid
                  width="100%"
                  justify="flex-start"
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                >
                  <EMEntityDropdown
                    // data={networkInfo}
                    errorText={showNetworkError}
                    onSelect={(data) => {
                      setEntity(data);
                    }}
                  />
                </Grid>
                <Hidden smDown>
                  <Grid
                    container
                    className={classes.justifyEnd}
                    item
                    xs={12}
                    sm={12}
                    md={8}
                    lg={8}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!isDisabled}
                      onClick={() => {
                        networkChangeHandler();
                        if (!isEmpty(entity)) {
                          setOpen(true);
                          setLabels({
                            titleLable: "Add New Email Template",
                            buttonLabel: "Save",
                          });
                          setEdit(false);
                          setEditData({});
                          setDisabled(false);
                        }
                      }}
                    >
                      {"Add New Template"}
                    </Button>
                  </Grid>
                </Hidden>
                <Hidden lgUp mdUp>
                  <Grid
                    container
                    className={classes.justifyEnd}
                    item
                    xs={12}
                    sm={12}
                    md={8}
                    lg={8}
                  >
                    <Button
                      style={{ marginTop: "2rem" }}
                      variant="contained"
                      color="primary"
                      disabled={!isDisabled}
                      onClick={() => {
                        networkChangeHandler();
                        if (!isEmpty(entity)) {
                          setOpen(true);
                          setLabels({
                            titleLable: "Add New Email Template",
                            buttonLabel: "Save",
                          });
                          setEdit(false);
                          setEditData({});
                          setDisabled(false);
                        }
                      }}
                    >
                      {"Add New Template"}
                    </Button>
                  </Grid>
                </Hidden>
              </Grid>
              <Box>
                <EMAddEditEmailTemplate
                  open={open}
                  close={handleClose}
                  emailTemplateData={editData}
                  entity={entity}
                  titleLable={labels.titleLable}
                  buttonLabel={labels.buttonLabel}
                  isEdit={edit}
                  setOpenFalse={setOpen}
                  saveClicked={saveClicked}
                  setDisabled={setDisabled}
                />
              </Box>
            </Box>
            <Box onClick={networkChangeHandler}>
              <EMEmailTemplateTable
                filter={filter}
                permissionData={permissionData}
                entity={entity}
                onEdit={(emailTemplateData) => {
                  
                  setEditData(emailTemplateData);
                  setOpen(true);
                  setLabels({
                    titleLable: "Edit Email Template",
                    buttonLabel: "Save",
                  });
                  setEdit(true);
                }}
              />
            </Box>
          </Box>
        </Paper>
      </Box>
    </EMPage>
  );
}
