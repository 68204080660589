import React, { useState, useEffect } from "react";
import { Grid, Paper, Box, Button, Hidden } from "@material-ui/core";
import EMPage from "../../../../components/commans/EMPage";
import EMRolesPermissionsTable from "./EMRolesPermissionsTable";
import EMAddEditRole from "./EMAddEditRole";
import EMChannelDropdown from "../../commons/channelDropdown";
import { useSelector, useDispatch } from "react-redux";
import { find, isEmpty } from "lodash";
import { EMDoGetListOfChannelsForDropdownInUserManagementAction, EMDoGetPermissionSpecificToChannelAction, EMDoRetrievePermissionsForChannelAction } from "../../../../redux/actions/EMChannelAdminActions";
import { EMDoGetPermissionForEntityAction } from "../../../../redux/actions/EMCommonActions";
import { EMDoGetEntityInformationBasedOnPermissionAction } from "../../../../redux/actions/EMAuthActions";
import { EMDoGetListOfGroupsForDropdownInUserManagementAction } from "../../../../redux/actions/EMGroupAdminActions";

export default function EMManageGroupUsers(props) {
  const [filter, setFilter] = useState("");
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [channelId, setChannelId] = React.useState();
  const [editData, setEditData] = React.useState({});
  const [labels, setLabels] = React.useState({ title: "", buttonLabel: "" });
  const [showNetworkError, setShowNetworkError] = React.useState();
  const [isError, setIsError] = React.useState(true);
  const userData = useSelector((state) => state.auth);
  const channelSpecificPermissions = useSelector(
    (state) => state.channelAdmin.channelSpecificRolesAndPermissions || {}
  );
  const [permissionData, setPermissionData] = React.useState();
  const uiData = useSelector((state) => state.ui);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  //call dropdown Api
  useEffect(() => {
    dispatch(EMDoGetListOfChannelsForDropdownInUserManagementAction());
  }, []);

  useEffect(() => {
    if (channelSpecificPermissions) {
      let permission = find(channelSpecificPermissions, { context: channelId });
      let specificPermission = find(permission && permission.rolePermissions, {
        permission_name: "manage_roles_and_permissions",
      });
      setPermissionData(specificPermission);
    }
  }, [channelSpecificPermissions]);

  useEffect(() => {
    if (uiData["messages"]) {
      let refObj = find(uiData["messages"], {
        key: "role_permission_add_edit_success",
      });
      if (refObj && refObj.type === "success") {
        refObj.key = "role_permission_add_edit_success_done";
        setOpen(false);
      }
    }
  }, [uiData]);

  useEffect(() => {
    if (channelId) {
      groupSelectionHandler();
      let entityInfo = "channel";
      dispatch(EMDoGetPermissionForEntityAction(entityInfo));
      dispatch(EMDoGetPermissionSpecificToChannelAction({ id: channelId }));
    }
  }, [channelId]);

  const groupSelectionHandler = () => {
    if (channelId == undefined) {
      setShowNetworkError("Please Select Channel Here");
      setIsError(false);
    } else {
      setShowNetworkError("");
      setIsError(true);
    }
  };

  let disabledValue = (permissionData && permissionData["add_flag"]) || true;

  return (
    <EMPage title="Manage Roles And Permissions">
      <Box>
        <Paper elevation={2}>
          <Box height="100%" display="flex" flexDirection="column">
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              p={3}
              justifyItems="center"
              alignItems="center"
            >
              <Grid container style={{ maxWidth: "100%" }} justify="flex-start">
                <Grid
                  width="100%"
                  justify="flex-start"
                  item
                  xs={12}
                  sm={12}
                  md={10}
                  lg={10}
                >
                  <EMChannelDropdown
                    name="channel"
                    error={showNetworkError}
                    onSelect={(id) => {
                      setChannelId(id);
                    }}
                  />
                </Grid>
                <Hidden smDown>
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <Button
                      style={{ marginTop: "1rem", marginLeft: "2rem" }}
                      disabled = {!disabledValue}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        groupSelectionHandler();
                        if (channelId !== undefined) {
                          setOpen(true);
                          setEditData();
                          setEdit(false);
                          setLabels({
                            title: "Add A New Role",
                            buttonLabel: "Save",
                          });
                          let entityInfo = "channel";
                          dispatch(
                            EMDoGetPermissionForEntityAction(entityInfo)
                          );
                        }
                      }}
                    >
                      {"Add New Role"}
                    </Button>
                  </Grid>
                </Hidden>
                <Hidden lgUp mdUp>
                  <Grid item xs={12} sm={12} md={2} lg={1}>
                    <Button
                      style={{ marginTop: "2rem" }}
                      disabled = {!disabledValue}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        groupSelectionHandler();
                        if (channelId !== undefined) {
                          setOpen(true);
                          setEditData();
                          setEdit(false);
                          setLabels({
                            title: "Add A New Role",
                            buttonLabel: "Save",
                          });
                          let entityInfo = "channel";
                          dispatch(
                            EMDoGetPermissionForEntityAction(entityInfo)
                          );
                        }
                      }}
                    >
                      {"Add New Role"}
                    </Button>
                  </Grid>
                </Hidden>
              </Grid>
              <Box>
                <EMAddEditRole
                  open={open}
                  close={handleClose}
                  entity={channelId}
                  roleData={editData}
                  labels={labels}
                  closeSetter={setOpen}
                  isEdit={edit}
                />
              </Box>
            </Box>
            <EMRolesPermissionsTable
            permissionData={permissionData}
              entity={channelId}
              onEdit={(roleData) => {
                let contextData = {
                  entity: "channel",
                  id: channelId,
                  roleId: roleData.id,
                };

                dispatch(
                  EMDoRetrievePermissionsForChannelAction({ contextData })
                );
                setEdit(true);
                setEditData(roleData);
                setOpen(true);
                setLabels({ title: "Edit A Role", buttonLabel: "Update" });
              }}
            />
          </Box>
        </Paper>
      </Box>
    </EMPage>
  );
}
