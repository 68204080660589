import React, { useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { SvgIcon, Typography, Paper } from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { ReactComponent as ImageIcon } from "../../assets/Icon-metro-image.svg";
import { ReactComponent as CategoryIcon } from "../../assets/Icon-awesome-tag.svg";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { useSelector, useDispatch } from "react-redux";
import { POST_CATEGORY_SELECTED_DATA } from "../../redux/constants/UserPlatform/index";
import { EMDoGetCategoryFilteredPostListAction } from "../../redux/actions/UserPlatform/Home/EMLatestPostPageAction";
import { LocalOffer } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    backgroundColor: "white",
    borderRadius: "5px",
    // boxShadow:
    //   "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    // backgroundColor :theme.palette.primary,
  },
  flexcontainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    overflow: "none",
    // height:window.screen.width/60
  },
  menu: {
    justifyContent: "flex-start",
  },
  label: {
    "&:focus": {
      color: theme.palette.common.black,
    },
  },
  select: {
    height: "40px",
    borderRadius: "5px",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5.7 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function EMSelect(
  {
    label,
    name,
    handleChange,
    error,
    helperText,
    isDisabled,
    deleteHandler,
    selectedCategory,
    selectedDataForDropdown,
  },
  props
) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedData = selectedCategory;
  const data = selectedDataForDropdown;

  function DeleteIcon(props) {
    return (
      <SvgIcon
        onClick={() => {
          dispatch({ type: POST_CATEGORY_SELECTED_DATA, payload: null });
          dispatch(EMDoGetCategoryFilteredPostListAction());
        }}
        {...props}
      >
        <path d="M11.436,3.375A8.061,8.061,0,1,0,19.5,11.436,8.06,8.06,0,0,0,11.436,3.375Zm2.042,10.979-2.042-2.042L9.394,14.354a.619.619,0,1,1-.876-.876l2.042-2.042L8.518,9.394a.619.619,0,0,1,.876-.876l2.042,2.042,2.042-2.042a.619.619,0,1,1,.876.876l-2.042,2.042,2.042,2.042a.622.622,0,0,1,0,.876A.615.615,0,0,1,13.478,14.354Z" />
      </SvgIcon>
    );
  }

  return (
    <div>
      <Paper className={classes.formControl} elevation={2}>
        <FormControl
          className={classes.formControl}
          disabled={isDisabled}
          variant="outlined"
        >
          <Select
            name={name}
            placeholder={name}
            labelId="org_network_select"
            value={
              selectedData == ""
                ? selectedData
                : selectedData && selectedData.category_name == "Media"
                ? "Images & Videos"
                : selectedData.category_name
            }
            onChange={handleChange}
            // label="categories"
            // MenuProps={MenuProps}
            displayEmpty
            // fullWidth
            error={error}
            IconComponent={() => {
              return selectedData ? (
                <>
                  <DeleteIcon onClick={deleteHandler} />
                </>
              ) : (
                <ArrowDropDownIcon style={{}} />
              );
            }}
            helperText={helperText}
            // {...props}
          >
            <MenuItem disabled={true} value="">
              <div className={classes.flexcontainer}>
                <ListItemIcon>
                  <LocalOffer fontSize="large" color="primary" />
                </ListItemIcon>
                <Typography style={{ fontSize: "small", fontWeight: 600 }}>
                  CATEGORIES
                </Typography>
              </div>
            </MenuItem>

            {data.map((d) => {
              let dataToShow =
                d["category_name"] == "Media"
                  ? "Images & Videos"
                  : d["category_name"];
              return (
                <MenuItem key={dataToShow} value={dataToShow}>
                  <div className={classes.flexcontainer}>
                    <ListItemIcon>{d.icon}</ListItemIcon>
                    <Typography style={{ fontSize: "small", fontWeight: 600 }}>
                      {dataToShow && dataToShow.toUpperCase()}
                    </Typography>
                  </div>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Paper>
    </div>
  );
}
