import React from "react";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { useSelector, useDispatch } from "react-redux";

import MuiAlert from "@material-ui/lab/Alert";
import { HIDE_MESSAGE } from "../../redux/constants";

function Alert(props) {
  return <MuiAlert elevation={2} variant="filled" {...props} />;
}

function CustomSnakbar({ key, message, type }) {
  const [open, setOpen] = React.useState(true);
  const dispatch = useDispatch();

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    dispatch({ type: HIDE_MESSAGE, payload: { key, message } });
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose}>
              UNDO
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert severity={type}>{message}</Alert>
      </Snackbar>
    </div>
  );
}

export default function EMSnakbar(props) {
  const uiData = useSelector((data) => data.ui.messages || []);
  return (
    <div>
      {uiData.map((message) => (
        <CustomSnakbar {...message} />
      ))}
    </div>
  );
}
