import React, { useState, useEffect } from "react";
import {
    Paper,
    Box,
    Button,
    Modal,
    Typography,
    IconButton,
    Grid,
    TextField
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import EMDatatableSearchView from "../../../../components/datatables/EMDatatableSearchView";
import EMAddEditPermisssionTable from "./EMAddEditPermissionsTable";
import { findIndex, isEmpty } from "lodash";
// import { EMDoCreateNewRoleForGroupAction } from "../../";
import {EMDoCreateNewRoleForGroupAction,EMDoUpdateRoleForGroupAction} from "../../../../redux/actions/EMGroupAdminActions"
import { useDispatch, useSelector } from "react-redux";
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.common.white,
        height: "auto",
        width: "70%",
        marginTop: 15,
        margin: "auto",
        padding: "0px",
        marginLeft: "auto"
    },
    title: {
        margin: 10,
        flexGrow: 1,
        fontWeight: "bold",
        color:"white",
        fontSize:"large"
    },
    form: {
        padding: "5px 36px",
        width: "100%",
        flexGrow: 1
    },
    buttonHolder: {
        padding: theme.spacing(3)
    },
    box: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px 20px 0px 20px"
    },
    association: {
        paddingLeft: "25px"
    },
    formStyle: {
        minWidth: 120
    }
}));

export default function EMAddEditRole(props) {
    const classes = useStyles();
    const [roleName, setRoleName] = useState("");
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const data = useSelector(state => state.common.permissionsForRole || []);
    const dispatch = useDispatch();


    const submitNewRoleData = () => {

        if ((props.roleData && props.roleData.id > 0)) {
            let data = {
                roleData: {
                  ...props.roleData,
                  role_name: roleName,
                  rolePermissions: selectedPermissions
                },
                contextData: {
                    entity: "group",
                    id: props.entity,
                    roleId: props.roleData.id
                },
                key: "role_permission_add_edit"
              };
            dispatch(EMDoUpdateRoleForGroupAction(data));
        }
        else {

            let data = {
                roleData: {
                    role_name: roleName,
                    rolePermissions: selectedPermissions
                },
                contextData: {
                    entity: "group",
                    id: props.entity
                },
                key: "role_permission_add_edit"
            };
            dispatch(EMDoCreateNewRoleForGroupAction(data));
        };
    }


    useEffect(() => {
        return () => {

            if (!isEmpty(props.roleData || props.isEdit)) {
                setRoleName(props.roleData.role_name)
                let permissionsData =data && data.rolePermissions
        setSelectedPermissions(permissionsData)
            } else {
                setRoleName("");
                setSelectedPermissions([]);
            }

        };
    }, [props.open, props.close, props.roleData, props.isEdit]);

    return (
        <Modal open={props.open} onClose={props.close}>
            <Box className={classes.root}>
                <Box
                    width="100%"
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{    padding: "0px 4px 0px 18px",
                    backgroundColor:"#2F92D6"}}
                >
                    <Typography className={classes.title}>{props.labels.title}</Typography>
                    <IconButton onClick={() => {
                        props.closeSetter(false)
                        setRoleName("")
                        setSelectedPermissions([])
                    }}>
                        <CloseRounded style={{color:"white"}} />
                    </IconButton>
                </Box>
                <Grid
                    container
                    justify="flex-start"
                    alignItems="flex-start"
                    direction="column"
                    spacing={2}
                    className={classes.form}
                >
                    <Grid item xs={12} style={{ width: "100%", marginTop:"16px"  }}>
                        <TextField
                            id="standard-basic"
                            InputLabelProps={{style:{color:"#4d4d4d"}}}
                            label="Role Name"
                            value={roleName}
                            fullWidth
                            onChange={event => {

                                setRoleName(event.target.value);
                            }}
                        //InputProps={{ style: {fontFamily: "Arial" } }}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ width: "100%" }}>
                        <EMAddEditPermisssionTable
                            entity={props.entity}
                            onChangePermission={permissionData => {
                                
                                let prevSelection = [...selectedPermissions];
                                // if (!selectedPermissions.length > 0 && props.roleData) {
                                //     prevSelection = [...props.roleData.rolePermissions]
                                // }

                                let index = findIndex(prevSelection, { permission_name: permissionData.permission_name });
                                if (index > -1) {
                                    let data = {
                                        permission_name: permissionData.permission_name,
                                        add_flag: permissionData.add_flag,
                                        edit_flag: permissionData.edit_flag,
                                        view_flag: permissionData.view_flag
                                    }
                                    prevSelection[index] = data;
                                    //prevSelection[index] = {
                                    //     ...prevSelection[index],
                                    //     data
                                    // };
                                } else {
                                    let data = {
                                        permission_name: permissionData.permission_name,
                                        add_flag: permissionData.add_flag,
                                        edit_flag: permissionData.edit_flag,
                                        view_flag: permissionData.view_flag
                                    }
                                    prevSelection.push(data);
                                }
                                setSelectedPermissions(prevSelection);
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    justify="center"
                    alignItems="center"
                    direction="row"
                    className={classes.buttonHolder}
                    spacing={2}
                >
                    <Grid item>
                        <Button
                            className={classes.button}
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={roleName == "" }
                            onClick={() => {
                                
                                submitNewRoleData();
                            }}
                        >
                            <Typography variant="h7">{props.labels.buttonLabel}</Typography>
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            className={classes.button}
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                                props.closeSetter(false)
                                setRoleName("")
                                setSelectedPermissions([])
                            }}
                        >
                            <Typography variant="h7">Discard</Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
}
