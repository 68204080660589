import {
  ROLES_LIST_SUCCESS,
  SHOW_MESSAGE,
  USERDATA_FETCH_SUCCESS,
  USER_CREATE_SUCCESS,
  UI_LOADING,
  PASSWORD_RESET_LINK_SUCCESS,
  PASSWORD_RESET_SUCCESS,
  PLATFORM_ADMIN_CREATE_SUCCESS,
  USER_INFO,
  GET_NOTIFICATIONS,
  CHANGE_PROFILE_PIC,
  GET_ORGANIZATION_TYPE,
  GET_ORGANIZATION_TYPE_FIELDS,
  GET_ORGANIZATION_LEVELS,
  GET_ORGANIZATION_DEPARTMENTS,
  GET_LIST_PRONOUNS,
  GET_LIST_GENDER_IDENTITY,
  GET_LIST_RACE,
  GET_LIST_MINORITY_IDENTIFICATION,
  GET_COUNTRY_CODES,
  GET_LIST_WORK_TYPES,
  GET_PHONE_TYPES,
  GET_ETHNICITY_TYPES,
  OPEN_CLOSE_CRASH_REPORT_DIALOG_BOX,
  GET_CRASH_REPORT_TEXT,
  MARK_NOTIFICATION_AS_SEEN,
} from "../constants";
import API from "../../api";
import {
  LOGIN_USER_DETAILS,
  PROFILE_EDIT_TOGGLE,
} from "../constants/UserPlatform/EMPeopleConstant";
import { EMDoGetProfileDetailsAction } from "./UserPlatform/People/EMPeopleActions";
import {
  GET_CHANNELS_BY_SEARCH,
  GET_EVENTS_BY_SEARCH,
  GET_GROUPS_BY_SEARCH,
  GET_NETWORKS_BY_SEARCH,
  GET_POLLS_BY_SEARCH,
  GET_POSTS_BY_SEARCH,
  GET_QUIZZES_BY_SEARCH,
} from "../constants/UserPlatform/EMNavigationConstant";
import { EMDoGetMultipleProfileDetailsAction } from "./EMAuthActions";
import {
  GET_POST_BY_NOTIFICATION_TAG,
  LIST_USER_POSTS,
} from "../constants/UserPlatform";
import { handleError } from "../../utils/EMCommonFunctions";
export const EMDoGetAllRolesAction = (action) => {
  return (dispatch) => {
    API.EMDoGetRoles()
      .then((data) => data.data)
      .then((data) => {
        if (data) {
          dispatch({
            type: ROLES_LIST_SUCCESS,
            payload: data,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching roles list",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetUserDataFromKeyAction = (action) => {
  return (dispatch) => {
    API.EMDogetUserData(action)
      .then((data) => data.data)
      .then((data) => {
        if (data) {
          dispatch({
            type: USERDATA_FETCH_SUCCESS,
            payload: data,
          });
          if(data.data.oranizaion_id){
            dispatch(EMDoGetOrganizationLevelsAction({ orgId: data.data.oranizaion_id }));
            dispatch(
              EMDoGetOrganizationDepartmentsAction({ orgId: data.data.oranizaion_id })
            );
          }
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching details",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetNotificationsAction = (action) => {
  return (dispatch) => {
    API.EMDoGetNotifications(action)
      .then((data) => data.data)
      .then((data) => {
        if (data) {
          dispatch({
            type: GET_NOTIFICATIONS,
            payload: { limit:action.offset ,response:data},
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching notifications",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoSeenNotificationAction = (action) => {
  return (dispatch) => {
    API.EMDoSeenNotification(action)
      .then((data) => data.data)
      .then((data) => {
        if (data) {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "info",
              message: "Notification Seen",
            },
          });
          if(action.from == "popup"){
            dispatch(EMDoGetNotificationsAction({limit:5, offset:0}))
          }else{
            dispatch({
              type:MARK_NOTIFICATION_AS_SEEN,
              payload:action.id
            })
          }
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoCreateUserAction = (action) => {
  return (dispatch) => {
    dispatch({
      type: UI_LOADING,
      payload: { state: true, message: "Please wait" },
    });
    let groupData = { ...action };
    delete groupData.key;
    API.EMDoCreateUser(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: USER_CREATE_SUCCESS,
            payload: response,
          });
          dispatch({
            type: UI_LOADING,
            payload: { state: false, message: "" },
          });
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "User Created Successfully",
              key: action.key,
            },
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to create the group",
            },
          });
          dispatch({
            type: UI_LOADING,
            payload: { state: false, message: "" },
          });
        }
      })
      .catch((err) => {
        dispatch({ type: UI_LOADING, payload: { state: false, message: "" } });
        handleError(dispatch, err)
      });
  };
};

export const EMDoAcceptPlatformAdminInviteAction = (action) => {
  return (dispatch) => {
    dispatch({
      type: UI_LOADING,
      payload: { state: true, message: "Please wait" },
    });
    let groupData = { ...action };
    delete groupData.key;
    API.EMDoAcceptPlatformAdminInvite(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: PLATFORM_ADMIN_CREATE_SUCCESS,
            payload: response,
          });
          dispatch({
            type: UI_LOADING,
            payload: { state: false, message: "" },
          });
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Platform Admin Created Successfully",
              key: action.key,
            },
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to Create Platform Admin",
            },
          });
          dispatch({
            type: UI_LOADING,
            payload: { state: false, message: "" },
          });
        }
      })
      .catch((err) => {
        dispatch({ type: UI_LOADING, payload: { state: false, message: "" } });
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetPasswordResetLinkAction = (action) => {
  return (dispatch) => {
    dispatch({
      type: UI_LOADING,
      payload: { state: true, message: "Please wait" },
    });
    API.EMDoGetPasswordResetLink(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: PASSWORD_RESET_LINK_SUCCESS,
            payload: response,
          });
          dispatch({
            type: UI_LOADING,
            payload: { state: false, message: "" },
          });
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Password reset link has been sent to your Email",
              key: action.key,
            },
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "please enter valid email address",
            },
          });
          dispatch({
            type: UI_LOADING,
            payload: { state: false, message: "" },
          });
        }
      })
      .catch((err) => {
        dispatch({ type: UI_LOADING, payload: { state: false, message: "" } });
        handleError(dispatch, err)
      });
  };
};

export const EMDoResetPasswordAction = (action) => {
  return (dispatch) => {
    dispatch({
      type: UI_LOADING,
      payload: { state: true, message: "Please wait" },
    });
    API.EMDoResetPassword(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: PASSWORD_RESET_SUCCESS,
            payload: response,
          });
          dispatch({
            type: UI_LOADING,
            payload: { state: false, message: "" },
          });
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Password Reset Successful",
              key: action.key,
            },
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Password Reset Failed, Try Again",
            },
          });
          dispatch({
            type: UI_LOADING,
            payload: { state: false, message: "" },
          });
        }
      })
      .catch((err) => {
        dispatch({ type: UI_LOADING, payload: { state: false, message: "" } });
        handleError(dispatch, err)
      });
  };
};

export const EMDoCheckPasswordResetLinkExpiryAction = (action) => {
  return (dispatch) => {
    API.EMDoCheckPasswordResetLinkExpiry(action)
      .then((data) => data.data)
      .then((data) => {
        if (data) {
          // dispatch({
          //   type: ROLES_LIST_SUCCESS,
          //   payload: data
          // });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while checking link expiry",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

/* Action for Manage Roles And Permissions */
export const EMDoGetPermissionForEntityAction = (action) => {
  return (dispatch) => {
    API.EMDoGetPermissionForEntity(action)
      .then((data) => data.data)
      .then((data) => {
        if (data) {
          dispatch({
            type: ROLES_LIST_SUCCESS,
            payload: data,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching roles list",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

/* Action For User Information */
export const EMDoGetAllInformationAboutUserAction = (action) => {
  return (dispatch) => {
    API.EMDoGetAllInformationAboutUser(action.id)
      .then((data) => data.data)
      .then((data) => {
        if (data) {
          dispatch({
            type: USER_INFO,
            payload: data,
          });
          dispatch({
            type: LOGIN_USER_DETAILS,
            payload: data,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching roles list",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoUpdateInformationAboutUserAction = (action) => {
  return (dispatch) => {
    API.EMDoUpdateInformationAboutUser(action.userData, action.id)
      .then((data) => data.data)
      .then((data) => {
        if (data) {
          dispatch({
            type: USER_INFO,
            payload: data,
          });
          dispatch({
            type: CHANGE_PROFILE_PIC,
            payload: data,
          });
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "User data is updated",
            },
          });
          dispatch(EMDoGetAllInformationAboutUserAction({ id: action.id }));

          dispatch({
            type: PROFILE_EDIT_TOGGLE,
            payload: false,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching roles list",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

/* Action For User Information */
export const EMDoGetGroupsBySearchKeyAction = (action) => {
  return (dispatch) => {
    API.EMDoGetGroupsBySearchKey(action)
      .then((data) => data.data)
      .then((data) => {
        if (data) {
          dispatch({
            type: GET_GROUPS_BY_SEARCH,
            payload: {response : data, limit : action.limit},
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching roles list",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetChannelsBySearchKeyAction = (action) => {
  return (dispatch) => {
    API.EMDoGetChannelsBySearchKey(action)
      .then((data) => data.data)
      .then((data) => {
        if (data) {
          dispatch({
            type: GET_CHANNELS_BY_SEARCH,
            payload: {response : data, limit : action.limit},
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching joint channels",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetPostsBySearchKeyAction = (action) => {
  return (dispatch) => {
    API.EMDoGetPostsBySearchKey(action)
      .then((data) => data.data)
      .then((data) => {
        if (data) {
          dispatch({
            type: GET_POSTS_BY_SEARCH,
            payload: { response: data, userData: action.userData, page:action.limit },
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching joint channels",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetEventsBySearchKeyAction = (action) => {
  return (dispatch) => {
    API.EMDoGetEventsBySearchKey(action)
      .then((data) => data.data)
      .then((data) => {
        if (data) {
          dispatch({
            type: GET_EVENTS_BY_SEARCH,
            payload: { response: data, userData: action.userData, page:action.limit },
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching joint channels",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetPollsBySearchKeyAction = (action) => {
  return (dispatch) => {
    API.EMDoGetPollsBySearchKey(action)
      .then((data) => data.data)
      .then((data) => {
        if (data) {
          dispatch({
            type: GET_POLLS_BY_SEARCH,
            payload: { response: data, userData: action.userData, page:action.limit },
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching joint channels",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetQuizzesBySearchKeyAction = (action) => {
  return (dispatch) => {
    API.EMDoGetQuizzesBySearchKey(action)
      .then((data) => data.data)
      .then((data) => {
        if (data) {
          dispatch({
            type: GET_QUIZZES_BY_SEARCH,
            payload: { response: data, userData: action.userData, page:action.limit },
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching joint channels",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetNetworksBySearchKeyAction = (action) => {
  return (dispatch) => {
    API.EMDoGetNetworksBySearchKey(action)
      .then((data) => data.data)
      .then((data) => {
        if (data) {
          dispatch({
            type: GET_NETWORKS_BY_SEARCH,
            payload: {response : data, limit : action.limit},
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching joint channels",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetPostByNotificationAction = (action) => {
  return (dispatch) => {
    API.EMDoGetPostByNotification(action.id)
      .then((data) => data.data)
      .then((data) => {
        if (data) {
          dispatch({
            type: GET_POST_BY_NOTIFICATION_TAG,
            payload: { response: data, userData: action.userData },
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching posts",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetOrganizationLevelsAction = (action) => {
  return (dispatch) => {
    API.EMDoGetOrganizationLevels(action.orgId)
      .then((data) => data.data)
      .then((data) => {
        if (data) {
          dispatch({
            type: GET_ORGANIZATION_LEVELS,
            payload: data,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching organization levels",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetOrganizationDepartmentsAction = (action) => {
  return (dispatch) => {
    API.EMDoGetOrganizationDepartments(action.orgId)
      .then((data) => data.data)
      .then((data) => {
        if (data) {
          dispatch({
            type: GET_ORGANIZATION_DEPARTMENTS,
            payload: data,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching organization departments",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetPronounsListAction = (action) => {
  return (dispatch) => {
    API.EMDoGetPronounsList()
      .then((data) => data.data)
      .then((data) => {
        if (data) {
          dispatch({
            type: GET_LIST_PRONOUNS,
            payload: data,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching pronouns",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetGenderListAction = (action) => {
  return (dispatch) => {
    API.EMDoGetGenderList()
      .then((data) => data.data)
      .then((data) => {
        if (data) {
          dispatch({
            type: GET_LIST_GENDER_IDENTITY,
            payload: data,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching genders",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetRaceListAction = (action) => {
  return (dispatch) => {
    API.EMDoGetRaceList()
      .then((data) => data.data)
      .then((data) => {
        if (data) {
          dispatch({
            type: GET_LIST_RACE,
            payload: data,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching race",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetMinorityListAction = (action) => {
  return (dispatch) => {
    API.EMDoGetMinorityList()
      .then((data) => data.data)
      .then((data) => {
        if (data) {
          dispatch({
            type: GET_LIST_MINORITY_IDENTIFICATION,
            payload: data,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching minority list",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetCountryCodeListAction = (action) => {
  return (dispatch) => {
    API.EMDoGetCountryCodeList()
      .then((data) => data.data)
      .then((data) => {
        if (data) {
          dispatch({
            type: GET_COUNTRY_CODES,
            payload: data,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching country codes",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetWorkTypeListAction = (action) => {
  return (dispatch) => {
    API.EMDoGetWorkTypeList()
      .then((data) => data.data)
      .then((data) => {
        if (data) {
          dispatch({
            type: GET_LIST_WORK_TYPES,
            payload: data,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching work types",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetPhoneTypeListAction = (action) => {
  return (dispatch) => {
    API.EMDoGetPhoneTypeList()
      .then((data) => data.data)
      .then((data) => {
        if (data) {
          dispatch({
            type: GET_PHONE_TYPES,
            payload: data,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching phone types",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetEthnicityTypesListAction = (action) => {
  return (dispatch) => {
    API.EMDoGetEthnicityTypesList()
      .then((data) => data.data)
      .then((data) => {
        if (data) {
          dispatch({
            type: GET_ETHNICITY_TYPES,
            payload: data,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching ethnicity types",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err) 
      });
  };
};

//Crash report action

export const EMDoSendCrashReportAction = (action) => {
  return (dispatch) => {
    API.EMDoSendCrashReport(action)
      .then((data) => data.data)
      .then((data) => {
        if (data) {
          dispatch({
            type: OPEN_CLOSE_CRASH_REPORT_DIALOG_BOX,
            payload:false
        })
        dispatch({
            type: GET_CRASH_REPORT_TEXT,
            payload:""
        })
        dispatch({
          type: SHOW_MESSAGE,
          payload: {
            type: "info",
            message: "Crash report sent successfully",
          },
        });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while sending crash report",
            },
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: SHOW_MESSAGE,
          payload: {
            type: "error",
            message:
              err.response && err.response.data && err.response.data.detail,
          },
        });
      });
  };
};
