import EMDropdown from "../../../../components/commans/EMDropdown";
import { useSelector } from "react-redux";
import { find } from "lodash";
import FormHelperText from "@material-ui/core/FormHelperText";

export default function EMPrivacyDropdown({ onSelect, name, initialValue, error, helperText, errorText}) {
  const data = useSelector(state => state.networkAdmin["privacyList"]);
  let preValue = initialValue ? find(data,{"id" : initialValue}) : ''
  if(preValue){
    preValue = preValue !== '' ? preValue["privacy_type"] : ''
  }
  
  return (
    <>
    <EMDropdown
      label="Privacy"
      data={data || []}
      objKey={"privacy_type"}
      onChange={selected => {
        onSelect(find(data, { privacy_type: selected }).id);
      }}
      prevData={preValue}
      name={name}
      error={error}
      helperText={helperText}
    />
    <FormHelperText style = {{color:'red'}}>
          {error}
          {helperText}
        </FormHelperText>
    </>
  );
}
