import React, { useState } from "react";
import { Card, Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useFormik, Field } from "formik";
import * as yup from "yup";
const useStyles = makeStyles((theme) => ({
  card: {
    card: {
      //padding: "0px 36px",
      width: "100%",
      height: "100%",
      boxShadow: "0px 3px 6px #00000029",
    },
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "white",
  },
}));

export default function EMFileSelect({
  changeHandler,
  fileName,
  icon,
  id,
  accept,
  fileSize,
  supportedFormats,
  maxFileSize,
  label,
  disabled,
  multipleFileSelect,
  maxNumberOfFiles,
  alreadyPresentFiles
}) {
  const [error, setError] = React.useState();
  const [fileSelectedName, setFileSelectedName] = React.useState(fileName);
  const fileChangeHandler = (event) => { 
    if (Array.from(event.target.files).length + ((alreadyPresentFiles && alreadyPresentFiles) || 0) > maxNumberOfFiles){
      setError(
        `Maximum ${maxNumberOfFiles} files can be selected`
      );
      setFileSelectedName(null);
      changeHandler(null);
    }else{if (event.target.files[0]) {   
      if (supportedFormats.includes(event.target.files[0] && event.target.files[0].type)) {
        if (event.target.files[0] && event.target.files[0].size <= fileSize) {
          let file = event.target.files;
          let fileName = event.target.files[0].name;
          changeHandler(file);
          setError("");
          setFileSelectedName(fileName);
        } else {
          setError(
            "Selected File : " + event.target.files[0] &&
              event.target.files[0].name + " should be less than " + maxFileSize
          );
          setFileSelectedName(null);
          changeHandler(null);
        }
      } else {
        setError(
          "Selected File : " + event.target.files &&
            event.target.files[0] &&
            event.target.files[0].name + " is not supported"
        );
        setFileSelectedName(null);
        changeHandler(null);
      }
    }}
    
  };
  const classes = useStyles();
  let file = null;
  return (
    <form>
      <Grid
        style={{ marginBottom: 20 }}
        alignItems="center"
        justifyContent="center"
        container
        item xs={12}
        // justify={"space-evenly"}
      >
        <Grid >
          <input
            style={{ display: "none" }}
            id={"contained-button-file " + id}
            type="file"
            accept={accept}
            onChange={fileChangeHandler}
            disabled = {disabled}
            multiple={multipleFileSelect ? multipleFileSelect : false}
          />
          <label htmlFor={"contained-button-file " + id}>
            <Button
              variant="contained"
              className={classes.button}
              component="span"
              startIcon={icon}
              disabled = {disabled}
            >
              {label}
            </Button>
          </label>
        </Grid>
      </Grid>
      <p style={{ color: "red" }}>{error}</p>
    </form>
  );
}
