import React from "react";
import Toolbar from "react-big-calendar/lib/Toolbar";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Grid, Paper, Typography } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";

export default class EMCustomToolbar extends Toolbar {
  constructor(props) {
    super(props);

    this.state = {
      selection: 1,
      view: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (event) => {
    this.setState({ selection: event.target.value });
  };

  componentDidMount() {

    this.setState({
      view: this.props.view,
    });
  }

  componentDidUpdate(prevProps, nextState) {
    if (prevProps.view !== this.props.view) {
      if (this.props.view === "month") {
        this.setState({ selection: 1 });
      }
      if (this.props.view === "week") {
        this.setState({ selection: 2 });
      }
      if (this.props.view === "day") {
        this.setState({ selection: 3 });
      }
    }
  }

  render() {
    return (
      <Grid
        conatiner
        style={{ display: "flex", padding: "1%" }}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid lg={5} md={5} sm={4} xs={2}></Grid>
        <Grid item container style={{display:"flex"}} justifyContent="center" alignItems="center" lg={4} md={4} sm={5} xs={6}>
          <Grid item xs={1}  >
            <ArrowBackIosIcon
              style={{
                // height: "1.1em",
                // width: "2em",
                fontSize: "large",
                color: "	#4682B4",
              
                display: "flex", alignItems:"flex-end"
              }}
              onClick={() => this.navigate("PREV")}
            />
          </Grid>
          <Grid xs={9} justifyContent="center" >
            <Typography style={{ color: "	#4682B4", fontSize: "medium",display: "flex", alignItems:"center", justifyContent:"center" }}>
              {this.props.label}
            </Typography>
          </Grid>
          <Grid xs={1} alignItems="flex-end" >
            <ArrowForwardIosIcon
              style={{
                // height: "1.1em",
                // width: "2em",  
                fontSize: "large",
                color: "	#4682B4",
                display: "flex", alignItems:"flex-end",
                "&:hover": {
                  cursor: "pointer",
                },
                // paddingLeft: "5%",
              }}
              onClick={() => this.navigate("NEXT")}
            />
          </Grid>
        </Grid>
        <Grid lg={2} md={2} sm={1} xs={0}></Grid>
        <Grid container direction="row" justifyContent="flex-end" item lg={2} md={2} sm={2} xs={3}>
          <FormControl>
            <Select
              id="demo-simple-select"
              value={this.state.selection}
              onChange={this.handleChange}
              variant={"outlined"}
              style={{ maxHeight: "30px" }}
            >
              {this.props.views.map((view, index) => (
                <MenuItem
                  value={index + 1}
                  onClick={this.view.bind(null, view)}
                >
                  <Typography style={{ color: "	#4682B4" }}>
                    {view.toUpperCase()}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}
