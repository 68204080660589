import React, { useEffect, useRef } from "react";
import EMPage from "../../../../components/commans/EMPage";
import {
    EMSimpleTextFieldView,
    EMChipTextFieldView,
} from "../../../../components/EMTextFieldView";
import {
    Box,
    Button,
    Grid,
    Paper,
    Typography,
    makeStyles,
    Link
} from "@material-ui/core";
import EMFileUploader from "../../../../components/commans/EMFileUploader"
import EMTextArea from "../../../../components/commans/EMTextArea"
import EMCountryAndStateSelector from "../../../../components/commans/EMCountryAndStateSelector"

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 'bold',
        padding: theme.spacing(1, 0)
    },
    header: {
        fontWeight: 'bold',
        padding: theme.spacing(0.5)
    },
    textField: {
        marginBottom: "2%",
        fontSize:"0.5rem"
    },
    spacing: {
        marginTop : "5%",
        marginBottom: "5%"
    },
    paper: {
        marginLeft: "2%",
        marginRight: "10%",
    },
    header: {
        fontWeight: 'bold',
    },
  
}));

export default function EMContactDetails({ labelDetails,
    onChange, 
    countryId,countryValue,countryFormikError,countryHelperText,   
    stateId,stateValue,stateFormikError,stateHelperText,
    policyChangeHandler,policyFileName,policyId,
    emailId, emailFormikError, emailHelperText,emailvalue,
    phoneId, phoneFormikError, phoneHelperText,phoneValue,
    addressId, addressFormikError, addressHelperText,addressValue,
    zipCodeId, zipCodeFormikError, zipCodeHelperText, zipValue,
    cityId, cityFormikError, cityHelperText,cityValue,
    websiteId, websiteFormikError, websiteHelperText, websiteValue,
    additionalInfoId, additionalInfoFormikError, additionalInfoHelperText,additionalInfoValue,
    entityIsPartnership,prevPolicyFileName,prevPolicyFileLink,
        //additionalInfo 
additionalInfoRichTextValue,
additionalInfoRichText,
additionalInfoRichTextHandler,
}) {

    

    const classes = useStyles();
    let policy;
    if (prevPolicyFileName) {
        policy =<><p> {"Previous Policy File is : "} <Link
            variant="body2"
            href={prevPolicyFileLink}
            target="_blank"
            rel="noopener"
            download={prevPolicyFileName}
        >
            {prevPolicyFileName}
        </Link></p></>
    }
    let showAddress
    if (!entityIsPartnership) {
        showAddress = <>
                <EMSimpleTextFieldView className={classes.textField}
                label={"Organization Address"}
                id={addressId}
                name={addressId}
                value = {addressValue}
                onChange={onChange}
                error={addressFormikError}
                helperText={addressHelperText}

            />
            <Grid container spacing={2} className={classes.textField}>
                <Grid item xs={12} sm={12} md={3}>
                    <EMSimpleTextFieldView
                        label={"City"}
                        id={cityId}
                        name={cityId}
                        value = {cityValue}
                        onChange={onChange}
                        error={cityFormikError}
                        helperText={cityHelperText}
                    />
                </Grid>
                <Grid  item xs={12} sm={12} md={3}>
                    <EMCountryAndStateSelector
                    required={false}
                        idCountry={countryId}
                        idState={stateId}
                        onChange={onChange}
                        handleChangeCountry={onChange}
                        handleChangeState={onChange}
                        valueCountry={countryValue}
                        valueState={stateValue}
                        errorCountry={countryFormikError}
                        helperTextCountry={countryHelperText}
                        errorState={stateFormikError}
                        helperTextState={stateHelperText}
                        minWidthProp ={150}
                    />
                </Grid>
                <Grid item xs={12}>
                     <EMSimpleTextFieldView
                        label={"Zip Code"}
                        id={zipCodeId}
                        name={zipCodeId}
                        value = {zipValue}
                        onChange={onChange}
                        // error={zipCodeFormikError}
                        helperText={zipCodeHelperText}
                    />
                </Grid>
            </Grid>
        </>

    }
    return (
        <Paper item className={classes.paper}>
            <Typography className={classes.title}>
                {"Contact Details"}
            </Typography>
            <Box className={classes.paper}>
                <EMSimpleTextFieldView className={classes.textField}
                    label={"Email Address"}
                    id={emailId}
                    name={emailId}
                    value = {emailvalue}
                    onChange={onChange}
                    // error={emailFormikError}
                    helperText={emailHelperText}
                />
                <EMSimpleTextFieldView className={classes.textField}
                    label={"Phone"}
                    value = {phoneValue}
                    id={phoneId}
                    name={phoneId}
                    onChange={onChange}
                    // error={phoneFormikError}
                    helperText={phoneHelperText}
                />

        {showAddress}
                <EMSimpleTextFieldView className={classes.grid1}
                    label={"Website"}
                    id={websiteId}
                    name={websiteId}
                    value = {websiteValue}
                    onChange={onChange}
                    error={websiteFormikError}
                    helperText={websiteHelperText}
                />
                {/* <EMSimpleTextFieldView className={classes.grid1}
                    label={"Additional Information"}
                    id={additionalInfoId}
                    name={additionalInfoId}
                    onChange={onChange}
                    value ={additionalInfoValue}
                    error={additionalInfoFormikError}
                    helperText={additionalInfoHelperText}
                /> */}
                 <Box className={classes.spacing} toolbarId={2}>
                    <EMTextArea placeHolder="Additional Information" changeHandler={additionalInfoRichTextHandler} value={additionalInfoRichTextValue} />
                    {/* {mission} */}
                </Box>
                {/* <Typography className={classes.title}>
                    {"Attachments"}
                </Typography> */}
                {/* <Box className={classes.paper}>
                    <Typography className={classes.header}>
                        {"Upload File"}
                    </Typography>
                    <EMFileUploader
                        id={policyId}
                        changeHandler={policyChangeHandler}
                        fileName={policyFileName}
                        accept={[
                            ".pdf",
                            ".txt",
                            ".doc",
                            ".xls",
                            ".pptx",
                            ".ppt",
                            ".csv",
                            ".docx",
                            ".xlsx",
                          ]}
                          fileSize={5242880}
                          supportedFormats={[
                            "text/plain",
                            "application/pdf",
                            "application/doc",
                            "application/ms-doc",
                            "application/msword",
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                            "application/mspowerpoint",
                            "application/powerpoint",
                            "application/vnd.ms-powerpoint",
                            "application/x-mspowerpoint",
                            "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                            "application/excel",
                            "application/vnd.ms-excel",
                            "application/x-excel",
                            "application/x-msexcel",
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                          ]}
                       
                        maxFileSize={"5MB"}
                        
                    />
                    {policy}
                </Box> */}
            </Box>
        </Paper>
    )
}