import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useRoutes } from "react-router-dom";
import EMRoutes from "./EMRoutes";
import EMSnakbar from "./components/commans/EMSnakbar";
import React, { useEffect } from "react";
import { isEmpty } from "lodash";
import { Box, CircularProgress } from "@material-ui/core";
import EMDialogBox from "./components/commans/EMDialogBox";
import { GET_CRASH_REPORT_TEXT, OPEN_CLOSE_CRASH_REPORT_DIALOG_BOX } from "./redux/constants";
import { EMDoSendCrashReportAction } from "./redux/actions/EMCommonActions";
function App() {
  const localUserData = localStorage.getItem("userData");

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const authenticationData = { ...JSON.parse(localUserData) };

  const userData = useSelector((state) => state.auth) || authenticationData;

  const commonData = useSelector((state) => state.common || {});

  const routing = useRoutes(EMRoutes(userData));
  useEffect(() => {
    const initialValue = document.body.style.zoom;
    const checkForUIExistence =
      window.location.href.includes("admin") ||
      window.location.href.includes("networkAdmin") ||
      window.location.href.includes("groupAdmin") ||
      window.location.href.includes("channelAdmin") ||
      window.location.href.includes("users");
    if (userData && checkForUIExistence == false) {
      if (userData && userData.is_staff) {
        navigate("admin/dashboard");
      } else if (userData && userData.is_active) {
        navigate("/users/home");
      }
    }
    // Change zoom level on mount
    document.body.style.zoom = "100%";
    return () => {
      // Restore default value
      document.body.style.zoom = initialValue;
    };
  }, []);

  return (
    <div>
      <React.Suspense
        fallback={
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: window.screen.availHeight,
            }}
          >
            <CircularProgress />
          </Box>
        }
      >
        {routing}
      </React.Suspense>
      <EMSnakbar />
      <EMDialogBox
        shouldOpen={commonData.crashReportDialogState}
        onSelect={(shouldProcess) => {
          if (shouldProcess) {
            dispatch(EMDoSendCrashReportAction(commonData.crashReportText))
          } else {
            dispatch({
              type: OPEN_CLOSE_CRASH_REPORT_DIALOG_BOX,
              payload: false,
            });
            dispatch({
              type:GET_CRASH_REPORT_TEXT,
              payload:""
            })
          }
          dispatch({
            type: OPEN_CLOSE_CRASH_REPORT_DIALOG_BOX,
            payload: false,
          });
          dispatch({
            type:GET_CRASH_REPORT_TEXT,
            payload:""
          })
        }}
        dialogText={"Something went wrong, do you want to send crash report?"}
      />
    </div>
  );
}

export default App;
