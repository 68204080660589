import { GET_CRASH_REPORT_TEXT, OPEN_CLOSE_CRASH_REPORT_DIALOG_BOX, SHOW_MESSAGE } from "../redux/constants"

export const handleError = (dispatch, err, key) => {
  if(err){
    if(err?.response?.status !== 500){
      dispatch({
        type: SHOW_MESSAGE,
        payload: {
          key: key ? key : "",
          type: "error",
          message:
            err.response && err.response.data && err.response.data.detail,
        },
      });
    }else{
      dispatch({
          type: OPEN_CLOSE_CRASH_REPORT_DIALOG_BOX,
          payload:true
      })
      dispatch({
          type: GET_CRASH_REPORT_TEXT,
          payload:err?.response?.data
      })
    }
  }

}