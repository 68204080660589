import React, { useEffect, useRef ,useState} from "react";
import {
  makeStyles,
} from "@material-ui/core";
import EMPage from "../../components/commans/EMPage";
import TermsPdf from "../../assets/terms&conditions.pdf"

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    minWidth: "80%",
    padding: "0px",
    maxHeight: '100%',
    fontSize : 25,
  },
}));

export default function EMTermsAndConditions() {
  const classes = useStyles();

  return (
    <EMPage className={classes.root} title="Terms & Conditions">
   <object width="100%" height="100%" data={TermsPdf} type="application/pdf">   </object>
    </EMPage>
  );
}
