import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Card, Box } from "@material-ui/core/";
import CardActionArea from "@material-ui/core/CardActionArea";
import { CardHeader, Tooltip } from "@material-ui/core";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CreateIcon from "@material-ui/icons/Create";
import { useDispatch } from "react-redux";
import { SHOW_POST_EDIT } from "../../redux/constants/UserPlatform";
import bannerImage from "../../../src/assets/event_banner.png";
import { Divide } from "react-feather";
import { EMDoGetEventSharedWithGroupsListAction } from "../../redux/actions/UserPlatform/Home/EMLatestPostPageAction";

const useStyles = makeStyles({
  root: {
    margin: "2%",
    width: "100%",
    height: "-webkit-fill-available",
  },
  media: {
    maxWidth: "100%",
    height: 105,
  },
  typo:{
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 1,
    wordBreak:"break-word",
    overflow: "hidden",
  },
});

export default function EMSingleEvent({
  eventBanner,
  eventName,
  eventLocation,
  eventStart,
  eventEnd,
  getEventDetails,
  route,
  eventStartDate,
  post,
  groupName,
  editingRoute,
  timeZone,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const monthNames = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEPT",
    "OCT",
    "NOV",
    "DEC",
  ];

  function tConvert(time) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1);
      time = time.slice(0, 3);
      time[4] = +time[0] < 12 ? " AM" : " PM";
      time[0] = +time[0] % 12 || 12;
    }
    return time.join(""); // return adjusted time or original string
  }

  return (
    <Grid container>
      <Card className={classes.root}>
        <div className={classes.media}>
        <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                  height: "100%",
                  backgroundColor: "#d3d3d3",
                }}
              >
                <img
                  alt=""
                  style={{
                    flexShrink:0,
                    minWidth: "100%",
                    minHeight: "100%",
                  }}
                  src={eventBanner ? eventBanner : bannerImage}
                />
              </div>
          {route === "events" &&
          post.owner &&
          post.permissions &&
          post.permissions.AddFlag_CreateEventsUnderGroup == true ? (
            <IconButton style={{ top: 0, right: 0, position: "absolute" }}>
              <CreateIcon
                style={{ color: "white" }}
                onClick={() => {
                  dispatch(EMDoGetEventSharedWithGroupsListAction())
                  dispatch({
                    type: SHOW_POST_EDIT,
                    payload: {
                      id: post.id,
                      route: editingRoute,
                      editPost: true,
                      title: "Edit Post",
                      button: "Update Post",
                    },
                  });
                }}
              />
            </IconButton>
          ) : null}
        </div>
                <Grid conatiner direction="row" onClick={getEventDetails} style={{ display: "flex", backgroundColor: "#2F92D6", padding:"2%" }}>
                
                <Grid item xs={9}>
                  <Box alignItems="flex-start" display="flex">
                <Tooltip title={<h2 style={{ color: "white" }}>{eventName}</h2>} placement="top">
                  <Typography className={classes.typo} style={{ fontWeight: "bolder", color: "white" }}>
                    { eventName}
                  </Typography>
                </Tooltip>
                </Box>
                <Box alignItems="flex-start" display="flex">
                  <Tooltip title={<h2 style={{ color: "white" }}>{groupName}</h2>}  placement="top">
                  <Typography className={classes.typo} style={{ fontWeight: "bolder", color: "white", textAlign:"left" }}>
                    {groupName}
                  </Typography>
                </Tooltip>
              </Box>
                <Box alignItems="flex-start" display="flex">
                <Typography  style={{ color: "white" }}>
                  {eventStart && tConvert(eventStart)} -{" "}
                  {eventEnd && tConvert(eventEnd)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box alignItems="center">
              <Typography
                component="h6"
                variant="h6"
                style={{
                  fontWeight: "bolder",
                  fontSize: "25px",
                  color: "white",
                  textAlign: "center",
                }}
              >
                {eventStartDate && eventStartDate.getDate()}
              </Typography>
            </Box>
            <Box alignItems="center" justifyContent="center" width="100%">
              <Typography
                component="h6"
                variant="h6"
                style={{
                  fontWeight: "bolder",
                  fontSize: "textSecondary",
                  color: "white",
                  textAlign: "center",
                }}
              >
                {eventStartDate && monthNames[eventStartDate.getMonth()]}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}
