export const ALL_MY_NETWORKS = "all_my_networks"
export const DETAIL_FOR_NETWORK ="details_for_network"
export const ALL_CHANNELS_FOR_NETWORK = "all_channels_for_network"
export const ALL_GROUPS_FOR_NETWORK = "all_groups_for_network"
export const FILTER_FOR_NETWORK = "filter_for_network"
export const NETWORK_BY_ORGANIZATION_FILTER= "network_by_organization_filter"
export const ORGANIZATION_DETAILS="organization_details"
export const GET_ACTIONS_LIST_FOR_DROPDOWN = "get_actions_list_for_dropdown"
export const GET_ACTIONS_OF_ORGANIZATION= "get_actions_of_organization"

//disable enable filter checkbox
export const DISABLE_ENABLE_FILTER_FOR_NETWORKS = "disable_enable_filter_for_networks"