import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { List, AppBar, Toolbar, Grid, Button, useMediaQuery } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import EMUserMenu from "./EMUserMenuOptions";
import ZequalityLogo from '../../assets/zequality logo white transparent square (2).png'
import EMNavItem from "../../layouts/AdminLayout/NavBar/EMNavItem"
import { NavLink as RouterLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import EMNavBar from "../../layouts/AdminLayout/NavBar/index";
import { ReactComponent as userIcon } from '../../assets/userIcon.svg';

import { ReactComponent as OrganisationIcon } from '../../assets/GroupIcon.svg';
import { ReactComponent as NetworkIcon } from '../../assets/NetworkIcon.svg';
import { ReactComponent as ChannelIcon } from '../../assets/ChannelIcon.svg';
import { ReactComponent as GroupIcon } from '../../assets/GroupIcon.svg';
import { ReactComponent as QuizIcon } from "../../assets/help.svg";
import DashboardIcon from '@material-ui/icons/Dashboard';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { Outlet, useOutlet } from "react-router-dom";
import { findIndex } from "lodash"
import { useTheme } from "@material-ui/styles";
import { ENTITY_LIST_SUCCESS } from "../../redux/constants";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: theme.spacing(6) + 1,
    },
  },
  contentContainer: {
    // display: "flex",
    flex: "1 1 auto",
    overflow: "hidden"
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    marginTop: 64,
  },
  contentShift: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  shiftTextRight: {
    marginLeft: drawerWidth - 190
  },
  shiftTextLeft: {
    marginLeft: drawerWidth + 5
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    "& > *": {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  title: {
    whiteSpace: "normal",
    marginRight: theme.spacing(5.5),
    color: theme.palette.common.grey,
    size: 15
  },
  logo: {
    [theme.breakpoints.up("xs")]: {
      width: '100%',
    },
    [theme.breakpoints.up("lg")]: {
      width: '50%',
    },
    [theme.breakpoints.down("lg")]: {
      width: '40%',
    },
    marginTop: 8
  },
  button: {
    color: theme.palette.common.grey,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: "center",
    letterSpacing: 0,
    padding: "10px 8px",
    textTransform: "none",
    // width: "30%"
  },
  active: {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightMedium,
  }
}));

export default function EMTopBar(props) {
  const userData = useSelector(state => state.auth);
  const cssClasses = useStyles();
  const [isNetworkActive, setNetworkActive] = React.useState()
  const [isGroupActive, setGroupActive] = React.useState()
  const [isChannelActive, setChannelActive] = React.useState()
  const [isMobileNavOpen, setMobileNavOpen] = useState(true);
  const [open, setOpen] = React.useState(true);
  const theme = useTheme()
  let loginUserData =userData.access != "" ? userData :userData.userData
  const dispatch = useDispatch()

  const matchesSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if(matchesSmallScreen){
      handleDrawerClose()
    }
    
  },[matchesSmallScreen])

  let items = [
    ...(loginUserData.is_staff ? [
     
     
      {
        href: "/admin/dashboard",
        icon:() => <DashboardIcon style={{color:"gray"}}/>,

        title: "Dashboard"
      },
      {
        href: "/admin/users",
        icon: userIcon,
        title: "User Management"
      },
      {
        href: "/admin/organizations",
        icon: OrganisationIcon,
        title: "Organization Management"
      },
      {
        href: "/admin/partnership",
        icon: NetworkIcon,
        title: "Partnership Network Management"
      },
      {
        href: "/admin/reports",
        icon: ()=> <AssessmentIcon style={{color:"gray"}}/>,
        title: "Reports"
      },
      
    ]
      : []),
  ];

  let menu;

  if (props.items) {
    menu = 
      <Grid container direction="row" spacing={1} alignItems="center" justifyContent="center">
        <Grid item xs={4} container direction="row" spacing={1} alignItems="center" justifyContent="center">
      <Button
        className={cssClasses.button}
        component={RouterLink}
        to={"/networkAdmin/dashboard"}
        disabled={!(loginUserData.is_network_admin ||loginUserData.permission_for_organization ||loginUserData.permission_for_partnership)}
        onClick={() => {
          setNetworkActive(true)
          setGroupActive(false)
          setChannelActive(false)
          dispatch({
            type: ENTITY_LIST_SUCCESS,
            payload: []
          });
        }}>
        <Typography className={isNetworkActive || window.location.href.includes("/networkAdmin") ? cssClasses.active : cssClasses.button} >{"Network"}</Typography>
      </Button>
      </Grid>
      <Grid item xs={4} container direction="row" spacing={1} alignItems="center" justifyContent="center">
      <Button
        className={cssClasses.button}
        component={RouterLink}
        to={"/groupAdmin/dashboard"}
        disabled={!(loginUserData.is_group_admin || loginUserData.permission_for_group)}
        onClick={() => {
          setGroupActive(true)
          setChannelActive(false)
          setNetworkActive(false)
          dispatch({
            type: ENTITY_LIST_SUCCESS,
            payload: []
          });
        }}>
        <Typography className={isGroupActive || window.location.href.includes("/groupAdmin") ? cssClasses.active : cssClasses.button}>{"Group"}</Typography>
      </Button>
      </Grid>
      <Grid item xs={4} container direction="row" spacing={1} alignItems="center" justifyContent="center">
      <Button
        className={cssClasses.button}
        component={RouterLink}
        to={"/channelAdmin/dashboard"}
        disabled={!(loginUserData.is_channel_admin || loginUserData.permission_for_channel)}
        onClick={() => {
          setChannelActive(true)
          setNetworkActive(false)
          setGroupActive(false)
          dispatch({
            type: ENTITY_LIST_SUCCESS,
            payload: []
          });
        }}>
        <Typography className={isChannelActive || window.location.href.includes("/channelAdmin") ? cssClasses.active : cssClasses.button}>{"Channel"}</Typography>
      </Button>
      </Grid>
      </Grid>
   
  }

  if ((isNetworkActive && !isGroupActive && !isChannelActive) || window.location.href.includes("/networkAdmin")) {
    //get permission for user
    items = [
      {
        href: "/networkAdmin/dashboard",
        icon:() => <DashboardIcon style={{color:"gray"}}/>,
        title: "Dashboard"
      },
      (loginUserData.boolean_partnership_manage_users||loginUserData.boolean_partnership_manage_users || loginUserData.boolean_organization_manage_users ||loginUserData.boolean_organization_manage_roles_and_permissions) ?
      {
        href: "/networkAdmin/users",
        icon: userIcon,
        title: "User Management",
      } :
      null,

      
      
      (loginUserData.boolean_partnership_manage_email_templates||loginUserData.boolean_partnership_manage_profile_page||loginUserData.boolean_organization_manage_email_templates || loginUserData.boolean_organization_manage_profile_page) ?
      {
        href: "/networkAdmin/networkmanagement",
        icon: NetworkIcon,
        title: "Network Management",
      } :
      null,
      (loginUserData.boolean_organization_groups || loginUserData.boolean_partnership_groups) ?
      {
        href: "/networkAdmin/groupmanagement",
        icon: GroupIcon,
        title: "Group Management",
      }:null,
      (loginUserData.boolean_organization_channel||loginUserData.boolean_partnership_channel) ?
      {
        href: "/networkAdmin/channelmanagement",
        icon: ChannelIcon,
        title: "Channel Management",
      }:null,
      {
        href: "/networkAdmin/reports",
        icon: ()=> <AssessmentIcon style={{color:"gray"}}/>,
        title: "Reports"
      },
      (loginUserData.boolean_organization_manage_profile_page) ?
      {
        href: "/networkAdmin/gamification",
        icon: QuizIcon,
        title: "Gamification Management",
      } :
      null
    ]
  }

  if ((isGroupActive && !isChannelActive && !isNetworkActive) || window.location.href.includes("/groupAdmin")) {
    items = [
      {
        href: "/groupAdmin/dashboard",
        icon:() => <DashboardIcon style={{color:"gray"}}/>,
        title: "Dashboard"
      },
      (loginUserData.boolean_group_manage_users ||loginUserData.boolean_group_manage_roles_and_permissions) ?
      {
        href: "/groupAdmin/users",
        icon: userIcon,
        title: "User Management"
      } :
      null,
      
      loginUserData.boolean_group_manage_profile_page ?
      {
        href: "/groupAdmin/groupmanagement",
        icon: GroupIcon,
        title: "Group Management"
      }:null,
      loginUserData.boolean_group_manage_channels ?
      {
        href: "/groupAdmin/channelmanagement",
        icon: ChannelIcon,
        title: "Channel Management",
      } :null,
      {
        href: "/groupAdmin/reports",
        icon: ()=> <AssessmentIcon style={{color:"gray"}}/>,
        title: "Reports"
      },
      (loginUserData.boolean_group_manage_profile_page) ?
      {
        href: "/groupAdmin/gamification",
        icon: QuizIcon,
        title: "Gamification Management",
      } :
      null
    ]
  }

  if ((isChannelActive && !isGroupActive && !isNetworkActive) || window.location.href.includes("/channelAdmin")) {
    items = [
      {
        href: "/channelAdmin/dashboard",
        icon:() => <DashboardIcon style={{color:"gray"}}/>,

        title: "Dashboard"
      },
      (loginUserData.boolean_channel_manage_users || loginUserData.boolean_channel_manage_roles_and_permissions) ?
      {
        href: "/channelAdmin/users",
        icon: userIcon,
        title: "User Management"
      }:null,
      
      (loginUserData.boolean_channel_manage_profile_page)?
      {
        href: "/channelAdmin/channelmanagement",
        icon: ChannelIcon,
        title: "Channel Management"
      }:null,
      {
        href: "/channelAdmin/reports",
        icon: ()=> <AssessmentIcon style={{color:"gray"}}/>,
        title: "Reports"
      },
      (loginUserData.boolean_channel_manage_profile_page) ?
      {
        href: "/channelAdmin/gamification",
        icon: QuizIcon,
        title: "Gamification Management",
      } :
      null
    ]
  }

  const matches = useMediaQuery(theme.breakpoints.up('md'));
  
  items = items.filter(function (item){
    return item != null;
  })

  return (
    <div className={cssClasses.root}>
      <AppBar position="fixed" elevation={0}>
        <Toolbar className={cssClasses.toolbar}>
          <Grid container direction="row" alignItems="center" justifyContent="center">
          <Grid item lg={1} md={1} xs={3}>
          <div
            style={{
              width: "100%",
              height: "50px",
              whiteSpace: "nowrap",
              textAlign: "center",
            }}
          >
            <span
              style={{
                display: "inline-block",
                height: "100%",
                verticalAlign: "middle",
              }}
            ></span>
            <img
              alt=""
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                verticalAlign: "middle",
              }}
              src={
                ZequalityLogo
              }
            />
          </div>
          </Grid>
          <Grid spacing={5} direction="row" lg={8} md={7} xs={7} >
            {menu}
          </Grid>
          <Grid item lg={3} md={4} xs={2}>
          <EMUserMenu />
          </Grid>
          </Grid> 
        </Toolbar>
      </AppBar>
      <EMNavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        open={open}
        items={items}
        onDrawerOpen={handleDrawerOpen}
        onDrawerClose={handleDrawerClose}
      />
      <div className={cssClasses.wrapper} className={open ? cssClasses.shiftTextLeft : cssClasses.shiftTextRight} >
        <div className={cssClasses.contentContainer}>
          <div className={cssClasses.content} >
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
