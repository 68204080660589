import React from "react";
import {
  Paper,
  Box,
  Button,
  Modal,
  Typography,
  IconButton,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import {
  EMSimpleTextFieldView,
  EMChipTextFieldView,
} from "../../../components/EMTextFieldView";
import {
  EMDoCreateChannelInGroupAdminAction,
  EMDoUpdateChannelInGroupAdminAction,
} from "../../../redux/actions/EMGroupAdminActions";
import { CloseRounded } from "@material-ui/icons";
import { useFormik, Field, Formik } from "formik";
import * as yup from "yup";
import ChipInput from "material-ui-chip-input";
import GroupDropdown from "../commons/groupDropdownforChannel";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { findIndex, filter, difference, isEmpty, union } from "lodash";
import PrivacyDropdown from "../../NetworkAdmin/UserManagement/commons/EMPrivacyDropdown";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    height: "auto",
    minWidth: "50%",
    margin: "auto",
    padding: "0px",
    // maxWidth: '100vw',
    maxHeight: "100%",
    position: "fixed",
    top: "50%",
    transform: "translate(0, -50%)",
    width: "50%",
  },
  title: {
    margin: 10,
    flexGrow: 1,
    fontWeight: "bold",
    color: "white",
    fontSize: "large",
  },
  form: {
    padding: "5px 36px",
    width: "100%",
  },
  buttonHolder: {
    padding: theme.spacing(3),
  },
}));

export default function EMAddEditChannel(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [localEntity, setLocalEntity] = React.useState(-1);

  const validationSchema = yup.object({
    channel_name: yup
      .string("Enter Channel Name")
      .trim()
      .required("Channel name is required"),
    admin_emails: yup
      .array()
      .transform(function (value, originalValue) {
        if (this.isType(value) && value !== null) {
          return value;
        }
        return originalValue ? originalValue.split(/[\s,]+/) : [];
      })
      .of(yup.string().email(({ value }) => `${value} is not a valid email`))
      .min(1, "Atleast 1 admin email is required")
      .required("Admin email is requreid"),
    privacy_type: yup
      .string()
      .required("Select Privacy Type"),
    group: yup.string().required("Select Group"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      channel_name: props.channelData["channel_name"] || "",
      admin_emails: props.channelData["users"]
        ? props.channelData["users"].map((x) => x["email"])
        : [],
      group: props.channelData["group"] || "",
      privacy_type: props.channelData["privacy_type"] || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      props.setDisabled(true);
      if (props.channelData && props.channelData.id > 0) {
        let deleted_admin_emails = difference(
          filter(props.channelData["users"], {
            role: { role_name: "Admin" },
          }).map((x) => x["email"]),
          formik.values.admin_emails
        );
        let new_admin_emails = difference(
          formik.values.admin_emails,
          filter(props.channelData["users"], {
            role: { role_name: "Admin" },
          }).map((x) => x["email"])
        );
        let temp_admin_emails = union(
          formik.values.admin_emails,
          new_admin_emails
        );
        let final_admin_emails = difference(
          temp_admin_emails,
          deleted_admin_emails
        );
        let id = props.channelData.id;
        let channel_name = props.channelData.channel_name;
        let data = {
          id: props.channelData["id"],
          channel_name: values.channel_name,
          group: values.group,
          admin_emails: final_admin_emails,
          is_active: props.channelData["is_active"],
          privacy_type: values.privacy_type,
        };
        dispatch(
          EMDoUpdateChannelInGroupAdminAction({
            data,
            key: "channel_add_edit_details",
          })
        );
      } else {
        let newChannelData = {
          ...values,
        };
        dispatch(
          EMDoCreateChannelInGroupAdminAction({
            ...newChannelData,
            key: "channel_add_edit_details",
          })
        );
      }
    },
  });

  useEffect(() => {
    return () => {
      formik.resetForm();
    };
  }, [props.open]);

  return (
    <Modal
      open={props.open}
      onClose={props.close}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box className={classes.root} style={{ outline: "none" }}>
        <form onSubmit={formik.handleSubmit}>
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            style={{
              padding: "0px 4px 0px 18px",
              backgroundColor: "#2F92D6",
              height: "auto",
            }}
          >
            <Typography className={classes.title}>{props.pagelabel}</Typography>
            <IconButton
              onClick={() => {
                props.closeState(false);
                props.close();
              }}
            >
              <CloseRounded style={{ color: "white" }} />
            </IconButton>
          </Box>
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="row"
            className={classes.form}
          >
            <EMSimpleTextFieldView
              label={"Channel Name"}
              id="channel_name"
              name="channel_name"
              value={formik.values.channel_name}
              onChange={formik.handleChange}
              fullwidth
              // error={
              //     formik.touched.channel_name && Boolean(formik.errors.channel_name)
              // }
              helperText={
                formik.touched.channel_name && formik.errors.channel_name
              }
            />
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="row"
            spacing={2}
            className={classes.form}
          >
            <Grid item xs>
              <ChipInput
                name="admin_emails"
                id="admin_emails"
                type="email"
                label="Admin E-mail Address"
                helperText={
                  formik.errors.admin_emails && formik.touched.admin_emails
                    ? formik.errors.admin_emails
                    : ""
                }
                value={formik.values.admin_emails}
                InputLabelProps={{ style: { color: "#4d4d4d" } }}
                FormHelperTextProps={{ style: { color: "red" } }}
                // error={
                //     formik.errors.admin_emails && formik.touched.admin_emails
                //         ? true
                //         : false
                // }
                margin="none"
                onAdd={(chip) => {
                  if (chip) {
                    let oldvalues = [...formik.values.admin_emails];
                    let index = findIndex(oldvalues, chip);
                    if (index === -1) {
                      oldvalues.push(chip.toLowerCase());
                      formik.setFieldValue("admin_emails", oldvalues);
                    }
                  }
                }}
                onDelete={(chip) => {
                  let oldValues = [...formik.values.admin_emails];
                  let index = oldValues.indexOf(chip);
                  if (index > -1) {
                    oldValues.splice(index, 1);
                    formik.setFieldValue("admin_emails", oldValues);
                  }
                }}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container direction="row" className={classes.form}>
            <Grid item xs={12} sm={12} md={5}>
              <GroupDropdown
                name="group"
                isDisabled={isEmpty(props.channelData) ? false : true}
                initialValue={formik.values.group}
                onSelect={(id) => {
                  formik.setFieldValue("group", id);
                }}
                // error={formik.touched.group && Boolean(formik.errors.group)}
                helperText={formik.touched.group && formik.errors.group}
              />
            </Grid>
            <Grid xs={1}></Grid>
            <Grid item xs={12} sm={12} md={6}>
              <PrivacyDropdown
                name="privacy_type"
                initialValue={formik.values.privacy_type}
                onSelect={(id) => {
                  formik.setFieldValue("privacy_type", id);
                }}
                // error={
                //   formik.touched.privacy_type &&
                //   Boolean(formik.errors.privacy_type)
                // }
                helperText={
                  formik.touched.privacy_type && formik.errors.privacy_type
                }
              />
            </Grid>
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="row"
            className={classes.buttonHolder}
            spacing={2}
          >
            <Grid item>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                type="submit"
                disabled={props.saveClicked}
              >
                <Typography variant="h7">{props.buttonLabel}</Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.button}
                variant="outlined"
                color="secondary"
                onClick={() => {
                  props.closeState(false);
                  props.close();
                }}
              >
                <Typography variant="h7">Discard</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
}
