import {
  LIST_USER_PINNED_POSTS,
  LIST_USER_PINNED_POSTS_CATEGORY_SELECTED,
  SHOW_CREATE_POST,
  USER_EXPAND_TEXT,
  USER_EXPAND_COMMENT,
  USER_SHOW_COMMENT,
  POST_CATEGORY_SELECTED_DATA,
  POST_SHOW_HASHTAG,
  POST_NEW_COMMENT,
  DELETE_POST,
  POST_HASHTAG_SELECTED,
  SUBMIT_NEW_COMMENT,
  POST_SHOW_TAGPEOPLE,
  POST_TAGPEOPLE_SELECTED,
  POST_EMOJI_SELECTED,
  POST_SHOW_EMOJI,
  POST_SHOW_MORE,
  SHOW_POST_EDIT,
  POST_SHOW_MORE_SELECTED,
  CHANNEL_PREVIEW_LIST_SUCCESS,
  LIKE_DISLIKE_PIN_UNPIN_POSTS,
  SHOW_POST_MEDIA_PREVIEW,
  LIST_SHARE_WITH_GROUP,
  SHOW_NEXT_POSTS,
  LIST_USER_POSTS_CATEGORY_SELECTED,
  LIST_HASHTAG,
  LIST_MENTIONS,
  POST_CATEGORY_DATA,
  SHOW_NEXT_PINNED_POSTS,
  LIKE_DISLIKE_PIN_UNPIN_PINNED_POSTS,
  CLEAR_POST_FROM_STATE,
  OPEN_DELETE_CONFIRMATION_DIALOG,
  LIKE_DISLIKE_PINNED_POSTS,
  PINNED_POST_CATEGORY_SELECTED_DATA,
  CLEAR_POST_FROM_STATE_FOR_EVERY_LOAD,
  SUBMIT_POLL,
  SUBMIT_OPEN_POLL,
  GET_LEADERBOARD,
  GET_USER_POINTS,
  SUBMIT_QUIZ,
  PLAY_VIDEO_BY_ID,
  CHANGE_LIKE_STATUS_OF_PINNED_POST,
  CHANGE_LIKE_BUTTON_STATE_OF_PINNED_POST,
} from "../../../constants/UserPlatform/index";
import { cloneDeep, findIndex, find } from "lodash";
import { ReactComponent as BlueIcon } from "../../../../assets/blue.svg";
import { ReactComponent as GreenIcon } from "../../../../assets/green.svg";
import { ReactComponent as YellowIcon } from "../../../../assets/yellow.svg";
import { ReactComponent as OrangeIcon } from "../../../../assets/orange.svg";
import { ReactComponent as PurpleIcon } from "../../../../assets/purple.svg";
import { ReactComponent as PinkIcon } from "../../../../assets/pink.svg";
import { ReactComponent as EditIcon } from "../../../../assets/Icon-material-edit.svg";
import ShareIcon from "@material-ui/icons/Share";
import DeleteIcon from "@material-ui/icons/Delete";
import { AUTH_LOGOUT } from "../../../constants";

const initialState = {
  showCreatePost: false,
  images: [],
  documents: [],
  pageofPinnedPosts: undefined,
  videos: [],
  comments: "",
  pinnedPosts: [],
  categories: [
    { id: 1, categoryName: "Text" },
    { id: 2, categoryName: "Images & Videos" },
    { id: 3, categoryName: "Documents" },
    { id: 4, categoryName: "Events" },
    { id: 5, categoryName: "Polls" },
  ],
  categorySelected: "",
  postHashTags: [
    { id: "reg", display: "region" },
    { id: "endreg", display: "endregion" },
  ],
  postAtMentions: [],
  postMoreOptions: [
    { name: "Edit Post", icon: <EditIcon /> },
    { name: "Shared with Groups", icon: <ShareIcon /> },
    { name: "Delete Post", icon: <DeleteIcon /> },
  ],
  postSharedWithGroups: [],
  next: null,
};

export default function EMLatestPageReducer(state = initialState, action) {
  let newState = cloneDeep(state);
  switch (action.type) {
    /* Latest Post -index page reducers */
    // List Posts - from server
    case LIST_USER_PINNED_POSTS: {
      newState["next"] = action.payload.response.next;
      if (action.payload.page == 1) {
        newState["pinnedPosts"] = action.payload.response.results;
      } else {
        newState["pinnedPosts"] = newState["pinnedPosts"].concat(
          action.payload.response.results
        );
      }

      newState["pinnedPosts"].map((post) => {
        post["userName"] = post["firstName"] + " " + post["lastName"];
        post["expandText"] = false;
        post["expandPostComment"] = false;
        post["showComments"] = false;
        post["showHashTag"] = false;
        post["showTagPeople"] = false;
        post["showEmoji"] = false;
        post["likeButtonDisabled"] = false
        let start = new Date(post["event_start_date"]);
        let end = new Date(post["event_end_date"]);
        function ChangeDate(date) {
          var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

          if (month.length < 2) month = "0" + month;
          if (day.length < 2) day = "0" + day;

          return [year, month, day].join("-");
        }
        function ChangeTime(date) {
          return (
            ("00" + date.getHours()).slice(-2) +
            ":" +
            ("00" + date.getMinutes()).slice(-2) +
            ":" +
            ("00" + date.getSeconds()).slice(-2)
          );
        }
        post["event_start"] = ChangeDate(start);
        post["event_end"] = ChangeDate(end);
        post["event_start_time"] = ChangeTime(start);
        post["event_end_time"] = ChangeTime(end);
        post["channel_details"].map((channel) => {
          if (channel.channel_name.includes("MAIN_CHANNEL")) {
            post["postFrom"] = "home";
          } else {
            post["postFrom"] = "channel";
          }
        });
        post["mediaView"] = false;
        post["images"].map((img) => {
          img["type"] = "images";
          img["isPlaying"] = false
        });
        post["videos"].map((video) => {
          video["type"] = "videos";
          video["isPlaying"] = false
        });
        post["video_links"].map((video) => {
          video["type"] = "video_link";
          video["isPlaying"] = false
        });
        post["documents"].map((doc) => {
          doc["type"] = "documents";
        });

        function showTimeZone(date) {
          const dateAsString = date.toString();
          const timezone = dateAsString.match(/\(([^\)]+)\)$/)[1];
          return timezone;
        }
        post["totalMedia"] = post["images"].concat(post["videos"]);
        post["totalCombinedMedia"] = post["totalMedia"].concat(
          post["video_links"]
        );
        // post["totalMedia"] = post["documents"].concat(post["images"], post["videos"])
        // if(post["totalMedia"].length > 10) {
        //   post["totalCombinedMedia"] = post["totalMedia"].slice(0,10)
        // } else if (post["totalMedia"].length < 10) {
        //   post["totalCombinedMedia"] = post["totalMedia"]
        // }
        post["shouldOpen"] = false;
        post["showMore"] = false;
        post["timeZone"] = showTimeZone(start);
        post["postAtMentions"] = [];
        post["editPost"] = {
          showEditPost: false,
          labels: { title: "", button: "" },
        };
        post["newComment"] = "";
        post["owner"] =
          post.userName == action.payload.userData.name ? true : false;
        let postCategory = post["category"];

        newState["categories"].map((category) => {
          if (
            category.category_name &&
            category.category_name.includes(postCategory)
          ) {
            post["category"] = category.icon;
          }
        });
      });
      return newState;
    }

    case PLAY_VIDEO_BY_ID: {
      let postId = action.payload.postId;
      let postIndex = findIndex(newState["pinnedPosts"], { id: postId });
      let videoId = action.payload.videoId;
      let media = action.payload.media;
      let previewOpen = action.payload.fromPreview;
      ;
      if (previewOpen == false) {
        if (postIndex >= 0) {
          let videoIndex = findIndex(
            newState["pinnedPosts"][postIndex].totalCombinedMedia,
            { id: videoId }
          );
          if (videoIndex >= 0) {
            newState["pinnedPosts"][postIndex].totalCombinedMedia = newState["pinnedPosts"][
              postIndex
            ].totalCombinedMedia.map((item) => ({ ...item, isPlaying: false }));
            let upcomingMedia = media;
            newState["pinnedPosts"][postIndex].totalCombinedMedia[videoIndex] =
              upcomingMedia;
          }
        }
      } else {
        if (postIndex >= 0) {
          if (media.type == "videos") {
            let videoIndex = findIndex(newState["pinnedPosts"][postIndex].videos, {
              id: videoId,
            });
            if (videoIndex >= 0) {
              newState["pinnedPosts"][postIndex].videos = newState["pinnedPosts"][
                postIndex
              ].videos.map((item) => ({ ...item, isPlaying: false }));
              let upcomingMedia = media;
              newState["pinnedPosts"][postIndex].videos[videoIndex] = upcomingMedia;
              newState["pinnedPosts"][postIndex].totalCombinedMedia = newState["pinnedPosts"][
                postIndex
              ].totalCombinedMedia.map((item) => ({ ...item, isPlaying: false }));
            }
          } else {
            let videoIndex = findIndex(
              newState["pinnedPosts"][postIndex].video_links,
              { id: videoId }
            );
            if (videoIndex >= 0) {
              newState["pinnedPosts"][postIndex].video_links = newState["pinnedPosts"][
                postIndex
              ].video_links.map((item) => ({ ...item, isPlaying: false }));
              let upcomingMedia = media;
              newState["pinnedPosts"][postIndex].video_links[videoIndex] =
                upcomingMedia;
                newState["pinnedPosts"][postIndex].totalCombinedMedia = newState["pinnedPosts"][
                  postIndex
                ].totalCombinedMedia.map((item) => ({ ...item, isPlaying: false }));
            }
          }
        }
      }

      return newState;
    }

    case SUBMIT_POLL: {
      let response = action.payload.poll_details;
      response.map((x) => {
        let index = newState["pinnedPosts"].findIndex((d) => d.id === x.id);
        if (index >= 0) {
          newState["pinnedPosts"][index].poll_details = x;
        }
      });
      return newState;
    }

    case SUBMIT_QUIZ: {
      let response = action.payload.quiz_details;
      response.map((x) => {
        let index = newState["pinnedPosts"].findIndex((d) => d.id === x.id);
        if (index >= 0) {
          newState["pinnedPosts"][index].quiz_details = x;
        }
      });
      return newState;
    }

    case SUBMIT_OPEN_POLL: {
      let response = action.payload.poll_details;
      response.map((x) => {
        let index = newState["pinnedPosts"].findIndex((d) => d.id === x.id);
        if (index >= 0) {
          newState["pinnedPosts"][
            index
          ].open_ended_question_details.poll_details = x;
        }
      });
      return newState;
    }

    //category
    case POST_CATEGORY_DATA: {
      newState["categories"] = action.payload;
      newState["categories"].map((category) => {
        switch (category.category_name) {
          case "Text": {
            category["icon"] = <YellowIcon />;
            break;
          }
          case "Media": {
            category["icon"] = <OrangeIcon />;
            break;
          }
          case "Documents": {
            category["icon"] = <BlueIcon />;
            break;
          }
          case "Events": {
            category["icon"] = <GreenIcon />;
            break;
          }
          case "Poll": {
            category["icon"] = <PurpleIcon />;
            break;
          }
          case "Quiz": {
            category["icon"] = <PinkIcon />;
            break;
          }
        }
      });
      return newState;
    }

    case LIST_USER_PINNED_POSTS_CATEGORY_SELECTED: {
      newState["next"] = action.payload.response.next;
      newState["pinnedPosts"] = action.payload.response.results;
      newState["pinnedPosts"].map((post) => {
        post["userName"] = post["firstName"] + " " + post["lastName"];
        post["expandText"] = false;
        post["expandPostComment"] = false;
        post["showComments"] = false;
        post["showHashTag"] = false;
        post["showTagPeople"] = false;
        post["channel_details"].map((channel) => {
          if (channel.channel_name.includes("MAIN_CHANNEL")) {
            post["postFrom"] = "home";
          } else {
            post["postFrom"] = "channel";
          }
        });
        post["showEmoji"] = false;
        post["mediaView"] = false;
        post["likeButtonDisabled"] = false
        let start = new Date(post["event_start_date"]);
        let end = new Date(post["event_end_date"]);
        function ChangeDate(date) {
          var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

          if (month.length < 2) month = "0" + month;
          if (day.length < 2) day = "0" + day;

          return [year, month, day].join("-");
        }
        function ChangeTime(date) {
          return (
            ("00" + date.getHours()).slice(-2) +
            ":" +
            ("00" + date.getMinutes()).slice(-2) +
            ":" +
            ("00" + date.getSeconds()).slice(-2)
          );
        }
        function showTimeZone(date) {
          const dateAsString = date.toString();
          const timezone = dateAsString.match(/\(([^\)]+)\)$/)[1];
          return timezone;
        }
        post["event_start"] = ChangeDate(start);
        post["event_end"] = ChangeDate(end);
        post["event_start_time"] = ChangeTime(start);
        post["event_end_time"] = ChangeTime(end);
        post["showMore"] = false;
        post["shouldOpen"] = false;
        post["timeZone"] = showTimeZone(start);
        post["images"].map((img) => {
          img["type"] = "images";
        });
        post["videos"].map((video) => {
          video["type"] = "videos";
        });
        post["video_links"].map((video) => {
          video["type"] = "video_link";
        });
        post["documents"].map((doc) => {
          doc["type"] = "documents";
        });
        post["totalMedia"] = post["images"].concat(post["videos"]);
        post["totalCombinedMedia"] = post["totalMedia"].concat(
          post["video_links"]
        );
        // post["totalMedia"] = post["documents"].concat(post["images"], post["videos"])
        // if(post["totalMedia"].length > 10) {
        //   post["totalCombinedMedia"] = post["totalMedia"].slice(0,10)
        // } else if (post["totalMedia"].length < 10) {
        //   post["totalCombinedMedia"] = post["totalMedia"]
        // }
        post["postAtMentions"] = [];
        post["editPost"] = {
          showEditPost: false,
          labels: { title: "", button: "" },
        };
        post["newComment"] = "";
        post["owner"] =
          post.userName == action.payload.userData.name ? true : false;
        let postCategory = post["category"];

        newState["categories"].map((category) => {
          if (
            category.category_name &&
            category.category_name.includes(postCategory)
          ) {
            post["category"] = category.icon;
          }
        });
      });
      return newState;
    }
    //Like Dislike Pin Unpin pinnedPosts
    case LIKE_DISLIKE_PIN_UNPIN_PINNED_POSTS: {
      let postId = action.payload.id;
      let postIndex = findIndex(newState["pinnedPosts"], { id: postId });
      if (postIndex >= 0) {
        if (action.payload.is_pinned === false) {
          newState["pinnedPosts"].splice(postIndex, 1);
        }
      }
      return newState;
    }

    case LIKE_DISLIKE_PINNED_POSTS: {
      let postId = action.payload.id;
      let postIndex = findIndex(newState["pinnedPosts"], { id: postId });
      if (postIndex >= 0) {
        newState["pinnedPosts"][postIndex].liked = action.payload.is_liked;
        newState["pinnedPosts"][postIndex].likes = action.payload.like_count
      }
      return newState;
    }

    case CHANGE_LIKE_STATUS_OF_PINNED_POST:{
      let postId = action.payload.id
      let postIndex = findIndex(newState["pinnedPosts"], {id: postId});
      if(postIndex >= 0){
        newState["pinnedPosts"][postIndex].liked = action.payload.isLiked
      }
      return newState
    }

    case CHANGE_LIKE_BUTTON_STATE_OF_PINNED_POST:{
      let postId = action.payload.id
      let postIndex = findIndex(newState["pinnedPosts"], {id: postId});
      if(postIndex >= 0){
        newState["pinnedPosts"][postIndex].likeButtonDisabled = action.payload.isDisabled
      }
      return newState
    }

    case CLEAR_POST_FROM_STATE_FOR_EVERY_LOAD: {
      newState["pinnedPosts"] = action.payload;
      return newState;
    }

    //to toggle create post component
    case SHOW_CREATE_POST: {
      newState["showCreatePost"] = action.payload;
      return newState;
    }

    /* Post Component */
    case USER_EXPAND_TEXT: {
      let id = action.payload.id;
      let pinnedPosts = newState["pinnedPosts"].map((post) => {
        if (post.id == id) {
          return { ...post, expandText: action.payload.content };
        } else {
          return { ...post };
        }
      });
      newState["pinnedPosts"] = pinnedPosts;
      return newState;
    }
    case USER_EXPAND_COMMENT: {
      let id = action.payload.id;
      let pinnedPosts = newState["pinnedPosts"].map((post) => {
        if (post.id == id) {
          return { ...post, expandPostComment: action.payload.content };
        } else {
          return { ...post };
        }
      });
      newState["pinnedPosts"] = pinnedPosts;
      return newState;
    }
    case USER_SHOW_COMMENT: {
      let id = action.payload.id;
      let pinnedPosts = newState["pinnedPosts"].map((post) => {
        if (post.id == id) {
          return { ...post, showComments: action.payload.content };
        } else {
          return { ...post };
        }
      });
      newState["pinnedPosts"] = pinnedPosts;
      return newState;
    }
    /* Category Selected */
    case PINNED_POST_CATEGORY_SELECTED_DATA: {
      let data = action.payload;
      if (data != null) {
        newState["categories"].map((category) => {
          if (
            category.category_name &&
            category.category_name.indexOf(data) >= 0
          ) {
            newState["categorySelected"] = category;
          }
        });
      } else {
        newState["categorySelected"] = "";
      }
      return newState;
    }

    case CLEAR_POST_FROM_STATE: {
      newState["pinnedPosts"] = action.payload;
      return newState;
    }

    case OPEN_DELETE_CONFIRMATION_DIALOG: {
      newState["pinnedPosts"].map((post) => {
        if (post.id == action.payload.id) {
          post["shouldOpen"] = action.payload.shouldOpen;
        }
      });
      return newState;
    }

    case DELETE_POST: {
      let dataId = action.payload;
      let postIndex = findIndex(newState["posts"], { id: dataId });
      if (postIndex >= 0) {
        newState["pinnedPosts"].splice(postIndex, 1);
      }
      return newState;
    }

    /* Hashtag added in comment */
    case POST_SHOW_HASHTAG: {
      newState["pinnedPosts"].map((post) => {
        if (post.id == action.payload.id) {
          post["showHashTag"] = action.payload.showHashtag;
        }
      });
      return newState;
    }
    case POST_EMOJI_SELECTED: {
      newState["pinnedPosts"].map((post) => {
        if (post.id == action.payload.id) {
          post["newComment"] = post["newComment"].substr(
            0,
            post["newComment"].length
          );
          post["newComment"] =
            post["newComment"] + action.payload.selectedEmoji;
        }
      });
      return newState;
    }
    case POST_HASHTAG_SELECTED: {
      newState["pinnedPosts"].map((post) => {
        if (post.id == action.payload.id) {
          post["newComment"] = post["newComment"].substr(
            0,
            post["newComment"].length - 1
          );
          post["newComment"] =
            post["newComment"] + action.payload.selectedHashTag;
        }
      });
      return newState;
    }
    /* comment update */
    case POST_NEW_COMMENT: {
      newState["pinnedPosts"].map((post) => {
        if (post.id == action.payload.id) {
          post["newComment"] = action.payload.comment;
        }
      });
      return newState;
    }
    case SUBMIT_NEW_COMMENT: {
      //submit content from newComment for that Id
      let data = action.payload.response;
      let userData = action.payload.userData;
      newState["pinnedPosts"].map((post) => {
        if (post.id == data.post_id) {
          let content = data.content;
          let commentedOn = data.created_on;
          let newComment = {
            first_name: data.first_name,
            last_name: data.last_name,
            organisationName: userData.organization_name,
            designation: data.user_designation,
            comment: content,
            profileIcon: data.user_profile_pic,
            commentedOn: commentedOn,
            comment_id: data.id,
            user_id: userData.profile_id,
          };
          if (post.comments == null) {
            post.comments = [];
          }
          post.comments.push(newComment);
          post.newComment = "";
          // post.comments++
        }
      });
      return newState;
    }
    /* TagPeople in Comment */
    case POST_SHOW_TAGPEOPLE: {
      newState["pinnedPosts"].map((post) => {
        if (post.id == action.payload.id) {
          post["showTagPeople"] = action.payload.showTagPeople;
        }
      });
      return newState;
    }
    case POST_SHOW_EMOJI: {
      newState["pinnedPosts"].map((post) => {
        if (post.id == action.payload.id) {
          post["showEmoji"] = action.payload.showEmoji;
        }
      });
      return newState;
    }
    case POST_TAGPEOPLE_SELECTED: {
      newState["pinnedPosts"].map((post) => {
        if (post.id == action.payload.id) {
          post["newComment"] = post["newComment"].substr(
            0,
            post["newComment"].length
          );
          post["newComment"] =
            post["newComment"] + action.payload.selectedAtPeople.mentions.name;
        }
      });
      return newState;
    }
    /* Post - Show More */
    case POST_SHOW_MORE: {
      newState["pinnedPosts"].map((post) => {
        if (post.id == action.payload.id) {
          post["showMore"] = action.payload.showMore;
        }
      });
      return newState;
    }
    /* Show Edit Post */
    case SHOW_POST_EDIT: {
      newState["pinnedPosts"].map((post) => {
        if (post.id == action.payload.id) {
          // post["showMore"] = action.payload.showMore
          post["editPost"] = {
            showEditPost: action.payload.editPost,
            labels: {
              title: action.payload.title,
              button: action.payload.button,
            },
          };
        }
      });
      return newState;
    }
    case POST_SHOW_MORE_SELECTED: {
      newState["pinnedPosts"].map((post) => {
        if (post.id == action.payload.id) {
          switch (action.payload.option) {
            case "Edit Post": {
              post["editPost"] = {
                showEditPost: action.payload.args.editPost,
                labels: {
                  title: action.payload.args.title,
                  button: action.payload.args.button,
                },
              };
              break;
            }
            case "Share with Groups": {
              post["editPost"] = {
                showEditPost: action.payload.args.editPost,
                labels: {
                  title: action.payload.args.title,
                  button: action.payload.args.button,
                },
              };
              break;
            }
          }
        }
      });
      return newState;
    }
    /* Show - Media View for Post */
    case SHOW_POST_MEDIA_PREVIEW: {
      newState["pinnedPosts"].map((post) => {
        if (post.id == action.payload.id) {
          post["mediaView"] = action.payload.showPreview;
        }
      });
      return newState;
    }

    case SHOW_NEXT_PINNED_POSTS: {
      newState["pageofPinnedPosts"] = action.payload.nextPosts;
    }

    //hashtags from server
    case LIST_HASHTAG: {
      newState["postHashTags"] = action.payload;
      return newState;
    }
    //mentions for post
    case LIST_MENTIONS: {
      newState["pinnedPosts"].map((post) => {
        if (post.id == action.payload.id) {
          post["postAtMentions"] = action.payload.response;
        }
      });
      return newState;
    }
    //Share with groups listing
    case LIST_SHARE_WITH_GROUP: {
      newState["postSharedWithGroups"] = action.payload;
      return newState;
    }

    case GET_USER_POINTS: {
      newState["userPoints"] = action.payload;
      return newState;
    }

    case GET_LEADERBOARD: {
      newState["leaderboard"] = action.payload;
      return newState;
    }

    case AUTH_LOGOUT: {
      return { ...initialState };
    }

    default: {
      // initialise state with additional keys

      if (newState["pinnedPosts"]) {
        newState["pinnedPosts"].map((post) => {
          post["expandText"] = false;
          post["expandPostComment"] = false;
          post["showComments"] = false;
          post["showHashTag"] = false;
          post["showTagPeople"] = false;
          post["showEmoji"] = false;
          post["showMore"] = false;
          post["mediaPreview"] = false;
          post["postAtMentions"] = [];
          post["editPost"] = {
            showEditPost: false,
            labels: { title: "", button: "" },
          };
          post["newComment"] = "";
          let postCategory = post["category"];
          newState["categories"].map((category) => {
            if (
              category.category_name &&
              category.category_name.includes(postCategory)
            ) {
              post["category"] = category.icon;
            }
          });
        });
      }
      return newState;
    }
  }
}
