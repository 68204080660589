//Main Page
export const CHANNEL_LIST_MY_CHANNELS = "channel_list_my_group";
export const CHANNEL_LIST_MY_JOINT_CHANNELS = "channel_list_my_joint_channels";
export const CHANNEL_LIST_MY_JOINT_CHANNEL_TOTAL_NUMBER = "channel_list_my_joint_channel_total_number"
export const CHANNEL_EDIT = "channel_edit";
export const SHOW_CREATE_POST = "show_create_post";
export const SHOW_ALL_GROUPS = "show_all_groups";
export const CHANNEL_BROWSE_ALL_CHANNELS = "channel_browse_all_channels";
export const CHANNEL_GET_GROUPS_FOR_FILTER = "channel_get_groups_for_filter";
export const CHANNEL_APPLY_GROUP_FILTER = "channel_apply_group_filter";
export const CHANNEL_INFO_MODAL = "channel_info_modal";
export const FILTERED_CHANNELS = "filtered_channels";
export const ACTION_FOR_CHANNELS = "action_for_channels";
export const SEND_REQUEST_FOR_JOINING_THE_CHANNEL = "send_request_for_joining_the_channel";
export const ACCEPT_OR_REJECT_USER_REQUEST = "accept_or_reject_user_request";
export const CHANNEL_POP_UP = "channel_pop_up"

//Activity
export const GET_DETAILS_FOR_CHANNEL = "get_details_for_channel";
export const GET_CHANNEL_LIST = "get_channel_list";
export const LIST_CHANNEL_POSTS = "list_channel_posts";
export const POST_CATEGORY_DATA = "channel_post_category_data";
export const POST_CATEGORY_SELECTED_DATA = "post_category_selected_data";
export const GET_PERMISSION_DATA = "get_permission_data";
export const SHOW_INFO_OF_CHANNEL = "show_info_of_channel";
export const SHOW_NEXT_POSTS = "show_next_posts";

// export const DETAILS_FOR_GROUP_EDIT = "details_for_group_edit"
export const GET_DETAILS_OF_CHANNEL_FOR_NONMEMBERS = "get_details_of_channel_for_nonmembers";
export const LIST_USER_CHANNEL_POSTS_CATEGORY_SELECTED = "list_user_channel_posts_category_selected";

//Members
export const GET_MEMBERS_OF_CHANNEL = "get_members_of_channel";
export const SHOW_NEXT_MEMBERS = "show_next_members";
export const GET_FILTERED_MEMBERS_OF_CHANNEL = "get_filtered_members_of_group";
export const REMOVE_MEMBERS_FROM_FILTERED_CHANNEL = "remove_members_from_filtered_channel";

//settings
export const SHOW_USER_REQUESTS = "show_user_requests";

//clearing the data
export const CLEAR_CHANNEL_DATA = "clear_channel_data"
export const DISABLE_ENABLE_FILTER_FOR_BROWSE_CHANNELS = "disable_enable_filter_for_browse_channels"
export const DISABLE_ENABLE_FILTER_FOR_CHANNEL_MEMBERS = "disable_enable_filter_for_channel_members"
export const DISABLE_ENABLE_SEARCH_INTERNAL_CHANNELS = "disable_enable_search_internal_channels"
export const DISABLE_ENABLE_SEARCH_JOINT_CHANNELS = "disable_enable_search_joint_channels"
export const DISABLE_ENABLE_SEARCH_BROWSE_CHANNELS = "disable_enable_search_browse_channels"
export const DISABLE_ENABLE_SEARCH_CHANNEL_MEMBERS = "disable_enable_search_channel_members"