import React, { useState, useEffect, useCallback } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import EMPage from "../../../components/commans/EMPage";
import {
  Grid,
  Paper,
  Typography,
  GridList,
  GridListTile,
  useMediaQuery,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { EMDoGetPostsBySearchKeyAction } from "../../../redux/actions/EMCommonActions";
import { useNavigate } from "react-router";
import EMPost from "../../../components/user/EMPost";
import {
  EMDoLikeDislikePinUnpinPostAction,
  EMDoLikeDislikePostAction,
} from "../../../redux/actions/UserPlatform/Home/EMLatestPostPageAction";
import {
  GET_POSTS_BY_SEARCH,
  SAVE_GLOBAL_SEARCH,
} from "../../../redux/constants/UserPlatform/EMNavigationConstant";
import EMSideBar from "../../../components/navigation/EMSideBar";
import { debounce } from "lodash";
import {
  CHANGE_LIKE_BUTTON_STATE,
  CHANGE_LIKE_STATUS_OF_POST,
} from "../../../redux/constants/UserPlatform";
import InfiniteScroll from "react-infinite-scroll-component";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "2%",
  },
  header: {
    fontWeight: "bolder",
    fontSize: "1.5rem",
  },
}));

export default function EMSearchPost(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.navigation || {});
  const userData = useSelector((state) => state.auth || {});
  const navigation = useNavigate();
  const [limit, setLimit] = useState(1);

  const theme = useTheme();

  const matchesMediumScreen = useMediaQuery(theme.breakpoints.up("md"));

  const matchesLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    if (data.filter && data.filter.length > 0) {
    } else {
      dispatch({
        type: GET_POSTS_BY_SEARCH,
        payload: {
          response: { results: [], next: null },
          userData: {},
          page: 1,
        },
      });
    }
  }, [data.filter]);

  const sendQuery = useCallback(async () => {
    if (data.filter && data.filter.length > 0) {
      dispatch(
        EMDoGetPostsBySearchKeyAction({
          searchText: data.filter,
          userData: userData,
          limit: limit,
        })
      );
    }
  }, [limit]);

  useEffect(() => {
    sendQuery(limit);
  }, [sendQuery, limit]);

  const handleObserver = useCallback(() => {
    if (data.nextPosts !== null && data.filter && data.filter.length > 0) {
      setLimit((prev) => prev + 1);
    }
  }, [limit, data.nextPosts, data.filter]);

  return (
    <InfiniteScroll
      dataLength={data.searchedPosts.length}
      hasMore={true}
      next={handleObserver}
    >
      <EMPage
        title="Posts"
        style={{
          width: "100%",
          paddingLeft: matchesLargeScreen ? "8%" : "4%",
          paddingTop: matchesLargeScreen
            ? "4%"
            : matchesMediumScreen
            ? "6%"
            : "8%",
          paddingRight: matchesLargeScreen ? "8%" : "4%",
          paddingBottom: "2%",
        }}
      >
        <Grid container spacing={3}>
          {matchesMediumScreen ? (
            <Grid item xs={matchesLargeScreen ? 2 : 3}>
              <EMSideBar />
            </Grid>
          ) : null}
          <Grid
            item
            xs={matchesLargeScreen ? 10 : matchesMediumScreen ? 9 : 12}
          >
            <Paper elevation={2} className={classes.paper}>
              <Typography className={classes.header}>
                Search Results For "{data.filter}"
              </Typography>
              {data.searchedPosts &&
                data.searchedPosts.map((post, id) => (
                  <EMPost
                    searchedPost={true}
                    permission={true}
                    post={post}
                    pinHandler={() => {
                      let actions = [];
                      post.isPinnedPost
                        ? actions.push(`unpin`)
                        : actions.push(`pin`);
                      let queryData = {
                        id: post.id,
                        action: actions[0],
                      };
                      dispatch(
                        EMDoLikeDislikePinUnpinPostAction({ queryData })
                      );
                    }}
                    likeHandler={() => {
                      let actions = [];
                      post.liked
                        ? actions.push(`dislike`)
                        : actions.push(`like`);

                      let queryData = {
                        id: post.id,
                        action: actions[0],
                      };

                      dispatch({
                        type: CHANGE_LIKE_STATUS_OF_POST,
                        payload: {
                          id: post.id,
                          isLiked: post.liked ? false : true,
                        },
                      });
                      dispatch({
                        type: CHANGE_LIKE_BUTTON_STATE,
                        payload: {
                          id: post.id,
                          isDisabled: true,
                        },
                      });

                      dispatch(
                        EMDoLikeDislikePostAction({ queryData: queryData })
                      );
                    }}
                  />
                ))}
            </Paper>
          </Grid>
        </Grid>
      </EMPage>
    </InfiniteScroll>
  );
}
