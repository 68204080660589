import {
  Grid,
  Paper,
  Typography,
  GridList,
  GridListTile,
  useMediaQuery,
} from "@material-ui/core";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import EMPage from "../../../../components/commans/EMPage";
import EMUser from "../../../../components/user/EMUser";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  EMDoGetListOfMembersOfOrganisationBySearchAction,
  EMDoGetListOfMembersOfOrganisationAction,
} from "../../../../redux/actions/UserPlatform/People/EMPeopleActions";
import profile_image from "../../../../assets/user_image.png";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import EMSearchView from "../../../../components/navigation/EMSearchView";
import InfinitScroll from "react-infinite-scroll-component";
import EMSideBar from "../../../../components/navigation/EMSideBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { ADD_SUBTABS_TO_GROUP } from "../../../../redux/constants/UserPlatform/EMNavigationConstant";
import { DISABLE_ENABLE_SEARCH_MY_COMPANY } from "../../../../redux/constants/UserPlatform/EMPeopleConstant";

const useStyles = makeStyles((theme) => ({
  profileRoot: {
    margin: "4%",
    padding: "2%",
    // width: "80%"
  },
  root: {
  },
  title: {
    fontWeight: "bold",
    // marginLeft: "1%",
    // marginBottom: "2%"
  },
  gridList: {
    marginLeft: "2%",
  },
  //User Profile Specific Styles
  profilePicture: {
    minHeight: "40%",
    minWidth: "40%",
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  button: {
    backgroundColor: "white",
    float: "right",
    height: "1%",
  },
  topBar: {
    float: "right",
    display: "flex",
  },
  titleGroupChannel: {
    marginTop: "3%",
  },
}));

function MyCompany(props) {
  const classes = useStyles();
  const navigation = useNavigate();
  const data = useSelector((state) => state.myPeople);
  const members = useSelector((state) => state.myPeople.members);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(0);
  const [limitWhileSearching, setLimitWhileSearching] = useState(0);

  const theme = useTheme();

  const matchesMediumScreen = useMediaQuery(theme.breakpoints.up("md"));

  const matchesLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    dispatch(EMDoGetListOfMembersOfOrganisationAction({ limit: limit }));
    dispatch({type:ADD_SUBTABS_TO_GROUP})
    dispatch({
      type:DISABLE_ENABLE_SEARCH_MY_COMPANY,
      payload:true
  })
  }, []);

  useEffect(() => {
    if (search.length > 0) {
      dispatch(
        EMDoGetListOfMembersOfOrganisationBySearchAction({
          searchText: search,
          limit: limitWhileSearching,
        })
      );
    } else {
      setLimit(0);
      dispatch(EMDoGetListOfMembersOfOrganisationAction({ limit: limit }));
    }
  }, [search]);

  const sendQuery = useCallback(async () => {
    if (search.length === 0) {
      dispatch(EMDoGetListOfMembersOfOrganisationAction({ limit: limit }));
    } else if (search.length > 0) {
      dispatch(
        EMDoGetListOfMembersOfOrganisationBySearchAction({
          searchText: search,
          limit: limitWhileSearching,
        })
      );
    }
  }, [limit, limitWhileSearching]);

  useEffect(() => {
    if (search.length === 0) {
      sendQuery(limit);
    } else if (search.length > 0) {
      sendQuery(limitWhileSearching);
    }
  }, [sendQuery, limit, limitWhileSearching]);

  const handleObserver = useCallback(() => {
    if (search.length === 0) {
      setLimit((prev) => prev + 20);
    } else if (search.length > 0) {
      setLimitWhileSearching((prev) => prev + 20);
    }
  }, [search, limit]);

  function getCols(screenWidth) {

    if (isWidthUp("xl", screenWidth)) {
      return 5;
    }else if (isWidthUp("lg", screenWidth)) {
      return 5;
    }else if (isWidthUp("md", screenWidth)) {
      return 4;
    }else if (isWidthUp("sm", screenWidth)) {
      return 3;
    } else if (isWidthUp("xs", screenWidth)) {
      return 2;
    }
    return 6;
  }

  const cols = getCols(props.width);

  return (
    <InfinitScroll
      dataLength={members.length}
      next={handleObserver}
      hasMore={true}
    >
      <EMPage
        title="My Company"
        style={{
          width: "100%",
          paddingLeft: matchesLargeScreen ? "8%" : "4%",
          paddingTop: matchesLargeScreen
            ? "4%"
            : matchesMediumScreen
            ? "6%"
            : "8%",
          paddingRight: matchesLargeScreen ? "8%" : "4%",
          paddingBottom: "2%",
        }}
      >
          <Grid container spacing={3}>
            {matchesMediumScreen ? (
              <Grid style={{position:"sticky"}} item xs={matchesLargeScreen ? 2 : 3}>
                <EMSideBar />
              </Grid>
            ) : null}
            <Grid
              item
              xs={matchesLargeScreen ? 10 : matchesMediumScreen ? 9 : 12}
            >
              <Paper elevation={2} className={classes.root}>
                <div style={{ padding: "1%" }}>
                  <Grid container alignItems="center" style={{ padding: "1%" }}>
                    <Grid md={9} sm={8} xs={7}>
                      <Typography className={classes.title} variant={"h5"}>
                        {"Company Members"}
                      </Typography>
                    </Grid>
                    <Grid md={3} sm={4} xs={5}>
                      <EMSearchView
                      disabled={data.isDisabledSearchMyCompany}
                        data={search}
                        usedIn="browse_groups"
                        route={true}
                        hint={"Search"}
                        onChangeListener={(data) => {
                          setSearch(data);
                          dispatch({
                            type:DISABLE_ENABLE_SEARCH_MY_COMPANY,
                            payload:true
                        })
                        }}
                      />
                    </Grid>
                  </Grid>
                  <GridList
                    cellHeight={160}
                    className={classes.gridList}
                    cols={cols}
                  >
                    {members &&
                      members.map((user) => (
                        <GridListTile
                          style={{
                            // minWidth: "16%",
                            // maxWidth: "20%",
                            height: "100%",
                            padding: "1%",
                          }}
                        >
                          <EMUser
                            user={user}
                            profileImage={
                              user.profile_pic_file && user.profile_pic_file
                                ? process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(
                                    0,
                                    -1
                                  ) + user.profile_pic_file
                                : profile_image
                            }
                            userFirstName={user.first_name}
                            userLastName={user.last_name}
                            designation={user.professional_title}
                            organisationName={user.organization_name}
                            email={user.email}
                            linkedinLink={
                              user.linkedin_profile_link &&
                              user.linkedin_profile_link
                            }
                            profileClickHandler={() =>
                              navigation(`/users/people/${user.id}`)
                            }
                          ></EMUser>
                        </GridListTile>
                      ))}
                  </GridList>
                </div>
              </Paper>
            </Grid>
          </Grid>
      </EMPage>
    </InfinitScroll>
  );
}

export default withWidth()(MyCompany);
