import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  AppBar,
  Toolbar,
  Grid,
  Button,
  useTheme,
  useMediaQuery,
  Hidden,
  IconButton,
  Popover,
  Box,
} from "@material-ui/core";
import EMUserMenu from "./EMUserMenuOptions";
import ZequalityLogo from "../../assets/zequality logo white transparent square (2).png";
import { CHANGE_ITEM_INDEX } from "../../redux/constants/UserPlatform/EMNavigationConstant";
import EMSearchView from "../navigation/EMSearchView";
import EMNavItem from "../../layouts/AdminLayout/NavBar/EMNavItem";
import {
  NavLink as RouterLink,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import EMNavBar from "../../layouts/AdminLayout/NavBar/index";
import { Outlet } from "react-router-dom";
import { find } from "lodash-es";
import EMTabs from "../navigation/EMUserTabs";
import { SAVE_GLOBAL_SEARCH } from "../../redux/constants/UserPlatform/EMNavigationConstant";
import EMUserNavigation from "./EMUserNavigation";
import SearchIcon from "@material-ui/icons/Search";
import {
  POST_DOCUMENT,
  POST_EXTERNAL_VIDEO_UPLOAD,
  POST_IMAGES,
  POST_VIDEOS,
  USER_DOCUMENT_UPLOAD,
  USER_EXTERNAL_VIDEO_UPLOAD,
  USER_IMAGES_UPLOAD,
  USER_VIDEOS_UPLOAD,
} from "../../redux/constants/UserPlatform";
import { SHOW_EVENT_DETAILS } from "../../redux/constants/UserPlatform/EMEventsConstant";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    // [theme.breakpoints.up("lg")]: {
    //   paddingLeft: theme.spacing(6) + 1,
    // },
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    // [theme.breakpoints.up("md")]: {
    marginTop: 64,
    // },
    // [theme.breakpoints.down("md")]: {
    //   marginTop: 70,
    // },
  },
  contentShift: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  shiftTextRight: {
    marginLeft: drawerWidth - 190,
  },
  shiftTextLeft: {
    marginLeft: drawerWidth + 5,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    "& > *": {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  title: {
    whiteSpace: "normal",
    marginRight: theme.spacing(5.5),
    color: theme.palette.common.grey,
    size: 15,
  },
  logo: {
    [theme.breakpoints.up("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.down("lg")]: {
      width: "40%",
    },
    // marginTop: 8
  },
  button: {
    color: theme.palette.common.grey,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: "center",
    letterSpacing: 0,
    padding: "10px 8px",
    textTransform: "none",
    width: "30%",
  },
  active: {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

export default function EMUserTopBar(props) {
  const userData = useSelector((state) => state.auth);
  const items = useSelector((state) => state.navigation.items);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cssClasses = useStyles();
  const filter = useSelector((state) => state.navigation.filter);
  const location = useLocation();
  const [anchorElSearchBar, setAnchorElSearchBar] = useState(null);
  let loginUserData = userData.access != "" ? userData : userData.userData;
  const itemsForNavigation = userData.boolean_group_create_polls_in_group
    ? items && items.slice(0, items.length - 3)
    : items && items.slice(0, items.length - 4);
  const itemsForNavigationWithSearch =
    userData.boolean_group_create_polls_in_group
      ? items && items.slice(0, items.length - 3)
      : items && items.slice(0, items.length - 4);

  const handleClose = () => {
    setAnchorElSearchBar(null);
  };

  const handleClick = (event) => {
    setAnchorElSearchBar(event.currentTarget);
  };

  const openSearchBar = Boolean(anchorElSearchBar);

  const id = openSearchBar ? "simple-popover" : undefined;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const matchesMediumScreen = useMediaQuery(theme.breakpoints.up("md"));
  const matchesSmallScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const matchesLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    if (filter.length > 0 && !location.pathname.includes("users/search")) {
      navigate(`/users/search`);
    } else if (
      filter.length === 0 &&
      location.pathname.includes("users/search")
    ) {
      let previousPath = window.sessionStorage
        .getItem("previousPathWhileSearching")
        .substring(1);
      navigate(previousPath);
    }
  }, [filter]);

  let tabs;
  let index;
  let value;


  return (
    <div className={cssClasses.root}>
      <AppBar position="fixed" elevation={0}>
        <Toolbar className={cssClasses.toolbar}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item md={1} xs={3}>
              <div
                style={{
                  width: "100%",
                  height: "50px",
                  whiteSpace: "nowrap",
                  textAlign: "center",
                }}
              >
                <span
                  style={{
                    display: "inline-block",
                    height: "100%",
                    verticalAlign: "middle",
                  }}
                ></span>
                <img
                  alt=""
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    verticalAlign: "middle",
                  }}
                  src={ZequalityLogo}
                />
              </div>
            </Grid>

            <Grid md={5} xs={6}></Grid>
            {!matches ? (
              <Grid md={0} xs={1}>
                <IconButton onClick={handleClick}>
                  <SearchIcon style={{ color: "white" }} />
                </IconButton>
                <Popover
                  id={id}
                  open={openSearchBar}
                  anchorEl={anchorElSearchBar}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <EMSearchView
                    hint={"Search"}
                    onChangeListener={(data) => {
                      if (!window.location.href.includes("users/search")) {
                        window.sessionStorage.setItem(
                          "previousPathWhileSearching",
                          window.location.hash
                        );
                      }
                      dispatch({
                        type: SAVE_GLOBAL_SEARCH,
                        payload: data,
                      });
                    }}
                  />
                </Popover>
              </Grid>
            ) : null}
            <Grid
              alignItems="center"
              justifyContent="center"
              container
              md={6}
              xs={2}
            >
              <Grid item md={6} xs={0}>
                {matches ? (
                  <EMSearchView
                    hint={"Search"}
                    onChangeListener={(data) => {
                      if (!window.location.href.includes("users/search")) {
                        window.sessionStorage.setItem(
                          "previousPathWhileSearching",
                          window.location.hash
                        );
                      }
                      dispatch({
                        type: SAVE_GLOBAL_SEARCH,
                        payload: data,
                      });
                    }}
                  />
                ) : null}
              </Grid>
              <Grid item md={6} xs={12}>
                <EMUserMenu />
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {filter && filter.length > 0
        ? itemsForNavigationWithSearch.map((item, id) => {
            if (item.href.includes("users/search")) {
              tabs = item.tabs;
              value = item.activeIndex;
              index = id;
            }
          })
        : itemsForNavigation.map((item, id) => {
            if (window.location.href.includes(item.href)) {
              if (
                item.elements.length > 0 &&
                (window.location.href.indexOf("activity") >= 0 ||
                  window.location.href.indexOf("members") >= 0 ||
                  window.location.href.indexOf("settings") >= 0)
              ) {
                let url = window.location.href.substr(
                  0,
                  window.location.href.lastIndexOf("/") + 1
                );
                let currentPath = url.substr(
                  url.indexOf("/users/") + 7,
                  url.length
                );
                let newTabs = find(item.elements, { path: currentPath });
                tabs = newTabs.subtabs;
                value = item.activeIndex;
                index = id;

              } else {
                tabs = item.tabs;
                value = item.activeIndex;
                index = id;
    
              }
            }
          })}
      {!matches ? <EMUserNavigation /> : null}

      <div
        style={{
          marginTop: matchesLargeScreen
            ? 85
            : matchesMediumScreen
            ? 75
            : matchesSmallScreen
            ? 110
            : 140,
        }}
      >
        <EMTabs
          tabs={tabs}
          value={value}
          changeHandler={(event, newValue) => {
            dispatch({
              type: CHANGE_ITEM_INDEX,
              payload: { id: index, activeIndex: newValue },
            });
            dispatch({ type: USER_IMAGES_UPLOAD, payload: null });
            dispatch({ type: USER_VIDEOS_UPLOAD, payload: null });
            dispatch({ type: USER_DOCUMENT_UPLOAD, payload: null });
            dispatch({ type: USER_EXTERNAL_VIDEO_UPLOAD, payload: null });
            dispatch({
              type: POST_IMAGES,
              payload: undefined,
            });
            dispatch({
              type: POST_VIDEOS,
              payload: undefined,
            });
            dispatch({
              type: POST_DOCUMENT,
              payload: undefined,
            });
            dispatch({
              type: POST_EXTERNAL_VIDEO_UPLOAD,
              payload: undefined,
            });
            dispatch({
              type: SHOW_EVENT_DETAILS,
              payload: {
                route: "upcomingEvents",
                eventId: -1,
                value: false,
              },
            });
            dispatch({
              type: SHOW_EVENT_DETAILS,
              payload: {
                route: "eventsByMe",
                eventId: -1,
                value: false,
              },
            });
          }}
        />
      </div>
      {/* <div className={cssClasses.wrapper}  >
        <div className={cssClasses.contentContainer}>
          <div className={cssClasses.content} > */}
            <Outlet />
          {/* </div>
        </div>
      </div> */}
    </div>
  );
}
