import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import EMPage from "../../../components/commans/EMPage";
import {
  Grid,
  Paper,
  Typography,
  GridList,
  GridListTile,
  Card,
  CardContent,
  useMediaQuery,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  EMDoGetPostsBySearchKeyAction,
  EMDoGetEventsBySearchKeyAction,
  EMDoGetNetworksBySearchKeyAction,
  EMDoGetGroupsBySearchKeyAction,
  EMDoGetChannelsBySearchKeyAction,
  EMDoGetPollsBySearchKeyAction,
  EMDoGetQuizzesBySearchKeyAction,
} from "../../../redux/actions/EMCommonActions";
import { useNavigate } from "react-router";
import EMPost from "../../../components/user/EMPost";
import {
  EMDoLikeDislikePinUnpinPostAction,
  EMDoLikeDislikePostAction,
} from "../../../redux/actions/UserPlatform/Home/EMLatestPostPageAction";
import {
  GET_POSTS_BY_SEARCH,
  SAVE_GLOBAL_SEARCH,
  GET_CHANNELS_BY_SEARCH,
  GET_EVENTS_BY_SEARCH,
  GET_GROUPS_BY_SEARCH,
  GET_NETWORKS_BY_SEARCH,
  GET_POLLS_BY_SEARCH,
  GET_QUIZZES_BY_SEARCH,
  ADD_SUBTABS_TO_GROUP,
} from "../../../redux/constants/UserPlatform/EMNavigationConstant";
import organizationLogo from "../../../../src/assets/organization_logo.jpg";
import EMGroupView from "../../../components/user/EMGroupView";
import EMEvent from "../../../components/user/EMEvent";
import EMSideBar from "../../../components/navigation/EMSideBar";
import EMPoll from "../../../components/user/EMPoll";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import EMQuiz from "../../../components/user/EMQuiz";
import { EMGetRolesAndPermissionsBasedOnEntityAction } from "../../../redux/actions/EMAuthActions";
import { debounce } from "lodash";
import {
  CHANGE_LIKE_BUTTON_STATE,
  CHANGE_LIKE_STATUS_OF_POST,
} from "../../../redux/constants/UserPlatform";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "2%",
  },
  header: {
    fontWeight: "bolder",
    fontSize: "1.5rem",
  },
  subHeader: {
    fontWeight: "bolder",
    fontSize: "1.2rem",
  },
  title: {
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.1rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.5rem",
    },
    fontWeight: "bold",
    marginLeft: "1%",
    marginBottom: "2%",
    //
  },
  images: {
    [theme.breakpoints.up("sm")]: {
      height: "90%",
    },
  },

  networkInfo: {
    textAlign: "center",
    height: "80px",
    // marginTop:'20%'
  },
  gridListTile: {
    // marginLeft: "2%",
    marginBottom: "2%",
    marginTop: "2%",
    borderRadius: "10px",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);",
  },
  root: {
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
    marginTop: "2%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    // padding:"10px",
    width: "100%",
    height: "100%",
    maxHeight: window.screen.height,
    "&::-webkit-scrollbar": {
      width: "0.2em",
      height: "0em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#4A4A4A",
      outline: "1px solid slategrey",
    },
  },
  gridOne: {
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-start",
    },
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start",
    },
    display: "flex",
    alignItems: "center",
  },
  gridTwo: {
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-end",
    },
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start",
    },
    display: "flex",
    alignItems: "center",
  },
}));

function EMSearchAll(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.navigation || {});
  const userData = useSelector((state) => state.auth || {});
  const navigate = useNavigate();

  const theme = useTheme();

  const matchesMediumScreen = useMediaQuery(theme.breakpoints.up("md"));

  const matchesLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  function getCols(screenWidth) {
    if (isWidthUp("xl", screenWidth)) {
      return 3;
    }

    if (isWidthUp("lg", screenWidth)) {
      return 3;
    }
    if (isWidthUp("md", screenWidth)) {
      return 3;
    }
    if (isWidthUp("sm", screenWidth)) {
      return 2;
    }
    if (isWidthUp("xs", screenWidth)) {
      return 1;
    }
    return 4;
  }
  const cols = getCols(props.width);

  function getColsForNetworks(screenWidth) {
    if (isWidthUp("xl", screenWidth)) {
      return 5;
    }

    if (isWidthUp("lg", screenWidth)) {
      return 4;
    }
    if (isWidthUp("md", screenWidth)) {
      return 4;
    }
    if (isWidthUp("sm", screenWidth)) {
      return 2;
    }
    if (isWidthUp("xs", screenWidth)) {
      return 2;
    }
    return 3;
  }
  const colsForNetworks = getColsForNetworks(props.width);

  useEffect(() => {
    if (data.filter && data.filter.length > 0) {
      dispatch(
        EMDoGetPostsBySearchKeyAction({
          searchText: data.filter,
          userData: userData,
          limit: 1,
        })
      );
      dispatch(
        EMDoGetEventsBySearchKeyAction({
          searchText: data.filter,
          userData: userData,
          limit: 1,
        })
      );
      dispatch(
        EMDoGetPollsBySearchKeyAction({
          searchText: data.filter,
          userData: userData,
          limit: 1,
        })
      );
      dispatch(
        EMDoGetQuizzesBySearchKeyAction({
          searchText: data.filter,
          userData: userData,
          limit: 1,
        })
      );
      dispatch(
        EMDoGetChannelsBySearchKeyAction({ searchText: data.filter, limit: 0 })
      );
      dispatch(
        EMDoGetGroupsBySearchKeyAction({ searchText: data.filter, limit: 0 })
      );
      dispatch(
        EMDoGetNetworksBySearchKeyAction({ searchText: data.filter, limit: 0 })
      );
    } else {
      dispatch({
        type: GET_POSTS_BY_SEARCH,
        payload: {
          response: { results: [], next: null },
          userData: {},
          page: 1,
        },
      });
      dispatch({
        type: GET_EVENTS_BY_SEARCH,
        payload: {
          response: { results: [], next: null },
          userData: {},
          page: 1,
        },
      });
      dispatch({
        type: GET_POLLS_BY_SEARCH,
        payload: {
          response: { results: [], next: null },
          userData: {},
          page: 1,
        },
      });
      dispatch({
        type: GET_QUIZZES_BY_SEARCH,
        payload: {
          response: { results: [], next: null },
          userData: {},
          page: 1,
        },
      });
      dispatch({
        type: GET_CHANNELS_BY_SEARCH,
        payload: {
          response: { results: [], next: null },
          page: 1,
        },
      });
      dispatch({
        type: GET_GROUPS_BY_SEARCH,
        payload: {
          response: { results: [], next: null },
          page: 1,
        },
      });
      dispatch({
        type: GET_NETWORKS_BY_SEARCH,
        payload: {
          response: { results: [], next: null },
          page: 1,
        },
      });
    }
    dispatch({ type: ADD_SUBTABS_TO_GROUP });
  }, [data.filter]);

  return (
    <EMPage
      title="All"
      style={{
        width: "100%",
        paddingLeft: matchesLargeScreen ? "8%" : "4%",
        paddingTop: matchesLargeScreen
          ? "4%"
          : matchesMediumScreen
          ? "6%"
          : "8%",
        paddingRight: matchesLargeScreen ? "8%" : "4%",
        paddingBottom: "2%",
      }}
    >
      <Grid container spacing={3}>
        {matchesMediumScreen ? (
          <Grid item xs={matchesLargeScreen ? 2 : 3}>
            <EMSideBar />
          </Grid>
        ) : null}
        <Grid item xs={matchesLargeScreen ? 10 : matchesMediumScreen ? 9 : 12}>
          <Paper elevation={2} className={classes.paper}>
            <Typography className={classes.header}>
              Search Results For "{data.filter}"
            </Typography>
            <Grid container>
              <Grid item xs={12} md={6} className={classes.gridOne}>
                <Typography className={classes.subHeader}>Networks</Typography>
              </Grid>
              <Grid item xs={12} md={6} className={classes.gridTwo}>
                {data.networksCount > 0 ? (
                  <Typography className={classes.subHeader} style={{cursor:"pointer", textDecoration:"underline"}} onClick={() => {
                    navigate("/users/search/networks")
                  }}>
                    See all {data.networksCount} networks
                  </Typography>
                ) : null}
              </Grid>
            </Grid>
            <div className={classes.root}>
              <GridList
                cellHeight={180}
                className={classes.gridList}
                cols={colsForNetworks}
              >
                {data.searchedNetworks.length > 0 &&
                  data.searchedNetworks.map((network) => (
                    <GridListTile
                      className={classes.gridListTile}
                      style={{ height: "100%" }}
                    >
                      <Card
                        style={{ maxWidth: "100%" }}
                        onClick={() => {
                          navigate("/users/networks/details", {
                            state: { NetworkId: network.id },
                          });
                          window.sessionStorage.setItem(
                            "NetworkId",
                            network.id
                          );
                          dispatch({
                            type: SAVE_GLOBAL_SEARCH,
                            payload: "",
                          });
                        }}
                        elevation={2}
                      >
                        <div style={{ height: "180px" }}>
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              whiteSpace: "nowrap",

                              textAlign: "center",
                            }}
                          >
                            <span
                              style={{
                                display: "inline-block",
                                height: "100%",
                                verticalAlign: "middle",
                              }}
                            ></span>
                            <img
                              alt=""
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                verticalAlign: "middle",
                              }}
                              src={
                                network.logo_file
                                  ? process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(
                                      0,
                                      -1
                                    ) + network.logo_file
                                  : organizationLogo
                              }
                            />
                          </div>
                        </div>
                        <CardContent className={classes.networkInfo}>
                          <Typography style={{ marginTop: "1%" }}>
                            {network.partnership_name}
                          </Typography>
                        </CardContent>
                      </Card>
                    </GridListTile>
                  ))}
              </GridList>
            </div>
            <Grid container>
              <Grid item xs={12} md={6} className={classes.gridOne}>
                <Typography className={classes.subHeader}>Groups</Typography>
              </Grid>
              <Grid item xs={12} md={6} className={classes.gridTwo}>
                {data.groupsCount > 0 ? (
                  <Typography className={classes.subHeader} style={{cursor:"pointer", textDecoration:"underline"}} onClick={() => {
                    navigate("/users/search/groups")
                  }}>
                    See all {data.groupsCount} groups
                  </Typography>
                ) : null}
              </Grid>
            </Grid>
            <div className={classes.root}>
              <GridList
                cellHeight={180}
                className={classes.gridList}
                cols={cols}
                style={{
                  alignItems: "flex-start",
                }}
              >
                {data.searchedGroups &&
                  data.searchedGroups.map((group, key) => (
                    <GridListTile
                      key={group.id}
                      className={classes.gridListTile}
                      // style={{ width: "23.7%", marginLeft: "1%" }}
                      style={{ height: "150px" }}
                    >
                      <EMGroupView
                        route="browse_groups"
                        onStarClick={() => {}}
                        id={group.id}
                        type={""}
                        number_of_group_users={group.number_of_group_users}
                        group_name={group.group_name}
                        logo_file={group.logo_file}
                        isFav={group.is_fav}
                        created_by={group.created_by}
                        organization_name={group.organization_name}
                        users_count={group.users_count}
                        privacy_type_name={group.privacy_type_name}
                        group={group}
                        onCardClick={() => {
                          navigate("/users/groups/browsegroups/activity", {
                            state: { GroupId: group.id },
                          });
                          window.sessionStorage.setItem("GroupId", group.id);
                          dispatch({
                            type: SAVE_GLOBAL_SEARCH,
                            payload: "",
                          });
                        }}
                      />
                    </GridListTile>
                  ))}
              </GridList>
            </div>
            <Grid container>
              <Grid item xs={12} md={6} className={classes.gridOne}>
                <Typography className={classes.subHeader}>Channels</Typography>
              </Grid>
              <Grid item xs={12} md={6} className={classes.gridTwo}>
                {data.channelsCount > 0 ? (
                  <Typography className={classes.subHeader} style={{cursor:"pointer", textDecoration:"underline"}} onClick={() => {
                    navigate("/users/search/channels")
                  }}>
                    See all {data.channelsCount} channels
                  </Typography>
                ) : null}
              </Grid>
            </Grid>

            <div className={classes.root}>
              <GridList
                cellHeight={180}
                className={classes.gridList}
                cols={cols}
                style={{
                  alignItems: "flex-start",
                }}
              >
                {data.searchedChannels &&
                  data.searchedChannels.map((channel, key) => (
                    <GridListTile
                      key={channel.id}
                      className={classes.gridListTile}
                      style={{ height: "150px" }}
                      // style={{ width: "23.7%", marginLeft: "1%" }}
                    >
                      <EMGroupView
                        route="browse_groups"
                        onStarClick={() => {}}
                        id={channel.id}
                        type={""}
                        number_of_group_users={channel.number_of_channel_users}
                        group_name={channel.channel_name}
                        logo_file={channel.logo_file}
                        isFav={channel.is_fav}
                        created_by={channel.created_by}
                        organization_name={channel.organization_name}
                        users_count={channel.users_count}
                        privacy_type_name={channel.privacy_type_name}
                        group={channel}
                        onCardClick={() => {
                          navigate("/users/channels/browsechannels/activity", {
                            state: { ChannelId: channel.id },
                          });
                          window.sessionStorage.setItem(
                            "ChannelId",
                            channel.id
                          );
                          dispatch({
                            type: SAVE_GLOBAL_SEARCH,
                            payload: "",
                          });
                        }}
                      />
                    </GridListTile>
                  ))}
              </GridList>
            </div>
            <div style={{ marginBottom: "2%" }}>
            <Grid container>
              <Grid item xs={12} md={6} className={classes.gridOne}>
                <Typography className={classes.subHeader}>Posts</Typography>
              </Grid>
              <Grid item xs={12} md={6} className={classes.gridTwo}>
                {data.postsCount > 0 ? (
                  <Typography className={classes.subHeader} style={{cursor:"pointer", textDecoration:"underline"}} onClick={() => {
                    navigate("/users/search/posts")
                  }}>
                    See all {data.postsCount} posts
                  </Typography>
                ) : null}
              </Grid>
            </Grid>
            </div>
            {data.searchedPosts &&
              data.searchedPosts.map((post, id) => (
                <EMPost
                  searchedPost={true}
                  permission={true}
                  post={post}
                  pinHandler={() => {
                    let actions = [];
                    post.isPinnedPost
                      ? actions.push(`unpin`)
                      : actions.push(`pin`);
                    let queryData = {
                      id: post.id,
                      action: actions[0],
                    };
                    dispatch(EMDoLikeDislikePinUnpinPostAction({ queryData }));
                  }}
                  likeHandler={() => {
                    let actions = [];
                    post.liked ? actions.push(`dislike`) : actions.push(`like`);

                    let queryData = {
                      id: post.id,
                      action: actions[0],
                    };

                    dispatch({
                      type: CHANGE_LIKE_STATUS_OF_POST,
                      payload: {
                        id: post.id,
                        isLiked: post.liked ? false : true,
                      },
                    });
                    dispatch({
                      type: CHANGE_LIKE_BUTTON_STATE,
                      payload: {
                        id: post.id,
                        isDisabled: true,
                      },
                    });

                    dispatch(EMDoLikeDislikePostAction({ queryData }));
                  }}
                />
              ))}
            <div style={{ marginBottom: "2%" }}>
            <Grid container>
              <Grid item xs={12} md={6} className={classes.gridOne}>
                <Typography className={classes.subHeader}>Events</Typography>
              </Grid>
              <Grid item xs={12} md={6} className={classes.gridTwo}>
                {data.eventsCount > 0 ? (
                  <Typography className={classes.subHeader} style={{cursor:"pointer", textDecoration:"underline"}} onClick={() => {
                    navigate("/users/search/events")
                  }}>
                    See all {data.eventsCount} events
                  </Typography>
                ) : null}
              </Grid>
            </Grid>

            </div>
            {data.searchedEvents &&
              data.searchedEvents.map((post, id) => (
                <EMEvent
                  permission={true}
                  searchedEvent={true}
                  post={post}
                  pinHandler={() => {
                    let actions = [];
                    post.isPinnedPost
                      ? actions.push(`unpin`)
                      : actions.push(`pin`);
                    let queryData = {
                      id: post.id,
                      action: actions[0],
                    };
                    dispatch(EMDoLikeDislikePinUnpinPostAction({ queryData }));
                  }}
                  likeHandler={() => {
                    let actions = [];
                    post.liked ? actions.push(`dislike`) : actions.push(`like`);

                    let queryData = {
                      id: post.id,
                      action: actions[0],
                    };

                    dispatch({
                      type: CHANGE_LIKE_STATUS_OF_POST,
                      payload: {
                        id: post.id,
                        isLiked: post.liked ? false : true,
                      },
                    });
                    dispatch({
                      type: CHANGE_LIKE_BUTTON_STATE,
                      payload: {
                        id: post.id,
                        isDisabled: true,
                      },
                    });

                    dispatch(
                      EMDoLikeDislikePostAction({ queryData: queryData })
                    );
                  }}
                />
              ))}
            <div style={{ marginBottom: "2%" }}>
            <Grid container>
              <Grid item xs={12} md={6} className={classes.gridOne}>
                <Typography className={classes.subHeader}>Polls</Typography>
              </Grid>
              <Grid item xs={12} md={6} className={classes.gridTwo}>
                {data.pollsCount > 0 ? (
                  <Typography className={classes.subHeader} style={{cursor:"pointer", textDecoration:"underline"}} onClick={() => {
                    navigate("/users/search/polls")
                  }}>
                    See all {data.pollsCount} polls
                  </Typography>
                ) : null}
              </Grid>
            </Grid>
            </div>
            {data.searchedPolls &&
              data.searchedPolls.map((post, id) => (
                <EMPoll
                  usedIn={
                    post.channel_details[0].channel_name.includes(
                      "MAIN_CHANNEL_HQ_HUB"
                    )
                      ? "polls"
                      : "channels"
                  }
                  id={
                    post.channel_details[0].channel_name.includes(
                      "MAIN_CHANNEL_HQ_HUB"
                    )
                      ? undefined
                      : post.channel_details[0].channel_id
                  }
                  searchedPost={true}
                  pollFromPoll={false}
                  permisssion={false}
                  post={post}
                  pinHandler={() => {
                    let actions = [];
                    post.isPinnedPost
                      ? actions.push(`unpin`)
                      : actions.push(`pin`);
                    let queryData = {
                      id: post.id,
                      action: actions[0],
                    };
                    dispatch(EMDoLikeDislikePinUnpinPostAction({ queryData }));
                  }}
                />
              ))}
            <div style={{ marginBottom: "2%" }}>
            <Grid container>
              <Grid item xs={12} md={6} className={classes.gridOne}>
                <Typography className={classes.subHeader}>Quizzes</Typography>
              </Grid>
              <Grid item xs={12} md={6} className={classes.gridTwo}>
                {data.quizzesCount > 0 ? (
                  <Typography className={classes.subHeader} style={{cursor:"pointer", textDecoration:"underline"}} onClick={() => {
                    navigate("/users/search/quizzes")
                  }}>
                    See all {data.quizzesCount} quizzes
                  </Typography>
                ) : null}
              </Grid>
            </Grid>
            </div>
            {data.searchedQuizzes &&
              data.searchedQuizzes.map((post, id) => (
                <EMQuiz
                  quizFromQuiz={false}
                  usedIn={
                    post.channel_details[0].channel_name.includes(
                      "MAIN_CHANNEL_HQ_HUB"
                    )
                      ? "polls"
                      : "channels"
                  }
                  id={
                    post.channel_details[0].channel_name.includes(
                      "MAIN_CHANNEL_HQ_HUB"
                    )
                      ? undefined
                      : post.channel_details[0].channel_id
                  }
                  searchedPost={true}
                  pollFromPoll={false}
                  permisssion={false}
                  post={post}
                  pinHandler={() => {
                    let actions = [];
                    post.isPinnedPost
                      ? actions.push(`unpin`)
                      : actions.push(`pin`);
                    let queryData = {
                      id: post.id,
                      action: actions[0],
                    };
                    dispatch(EMDoLikeDislikePinUnpinPostAction({ queryData }));
                  }}
                />
              ))}
          </Paper>
        </Grid>
      </Grid>
    </EMPage>
  );
}

export default withWidth()(EMSearchAll);
