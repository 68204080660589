import {
  Paper,
  SvgIcon,
  Button,
  GridList,
  GridListTile,
  Box,
  Avatar,
  CardContent,
  Grid,
  useMediaQuery,
  isWidthUp,
  withWidth,
  Tooltip,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { useSelector, useDispatch } from "react-redux";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import PublicIcon from "@material-ui/icons/Public";
import CheckIcon from "@material-ui/icons/Check";
import Parse from "html-react-parser";
//   import {EMDoGetGroupDetailsAction} from "../../../../../redux/actions/UserPlatform/Groups"
import groupImage from "../../../../../assets/group_image.jpg";
import bannerImage from "../../../../../assets/banner_image.jpg";
import { EMDoRequestToJoinChannelAction } from "../../../../../redux/actions/UserPlatform/Channels/EMChannelsAction";
import { useNavigate } from "react-router";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 0,
    paddingTop: "16.25%", // 16:9
  },
  card: {
    width: "100%",
    // position: "relative",
  },
  paper: {
    // marginTop: "2%",
    // marginLeft: "4%",
  },
  groupInfo: {
    textAlign: "left",
    fontWeight: "bolder",
    color: theme.palette.text.primary,
    fontSize: "large",
    display: "-webkit-box",
    boxOrient: "vertical",
    wordBreak: "break-word",
    overflow: "hidden",
    // marginBottom:"1%"
  },
  groupCardInfo: {
    marginLeft: "1%",
    maxWidth: "65%",
  },
  root: {
    width: "100%",
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-around",
    overflow: "hidden",
    marginRight: "2%",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    overflowY: "hidden",
    width: "100%",
    // height: window.screen.height/7,
    padding: 10,
    maxWidth: window.screen.width,
    "&::-webkit-scrollbar": {
      width: "0em",
      height: "0em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#4A4A4A",
      outline: "1px solid slategrey",
    },
  },
  gridListTile: {
    marginTop: "2%",
  },
  rootForGrid: {
    display: "flex",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    marginTop: "2%",
  },
  button: {
    width: "200px",
    height: "40px",
    backgroundColor: "#3399ff",
    color: "white",
  },
  rootForAttachments: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
  },
  gridListForAttachments: {
    // padding:"10px",
    width: "100%",
    height: "100%",
    maxHeight: window.screen.height / 2.5,
    "&::-webkit-scrollbar": {
      width: "0.1em",
    },
    // "&::-webkit-scrollbar-track": {
    //   boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    //   webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    // },
    // "&::-webkit-scrollbar-thumb": {
    //   backgroundColor: "#4A4A4A",
    //   outline: "1px solid slategrey",
    // },
  },
  typo: {
    display: "-webkit-box",
    boxOrient: "vertical",
    wordBreak: "break-word",
    overflow: "hidden",
  },
  typo1: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 2,
    wordBreak: "break-word",
    overflow: "hidden",
  },
}));

function EMGroupDetailsForNew(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.channels || {});
  const uiData = useSelector((state) => state.ui);
  const [requestToJoin, setRequestToJoin] = React.useState(false);

  const navigate = useNavigate();

  const theme = useTheme();

  const matchesSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  function getColsForAdm(screenWidth) {
    if (isWidthUp("xl", screenWidth)) {
      return 8;
    } else if (isWidthUp("lg", screenWidth)) {
      return 7;
    } else if (isWidthUp("md", screenWidth)) {
      return 6;
    } else if (isWidthUp("sm", screenWidth)) {
      return 5;
    } else if (isWidthUp("xs", screenWidth)) {
      return 3;
    }
    return 4;
  }

  const colsForAdm = getColsForAdm(props.width);
  return (
    <div style={{}}>
      <Paper elevation={2} className={classes.paper}>
        <Card style={{ width: "100%", padding: "1%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              backgroundColor: "#d3d3d3",
            }}
          >
            <img
              alt=""
              style={{
                flexShrink: 0,
                minWidth: "100%",
                minHeight: "100%",
              }}
              src={
                props.channelBanner
                  ? process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(0, -1) +
                    props.channelBanner
                  : bannerImage
              }
            />
          </div>
          <Grid container style={{ paddingTop: "1%" }}>
            <Grid container item lg={8} md={8} sm={12} xs={12} spacing={1}>
              <Grid item lg={3} md={3} sm={5} xs={5}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: "hidden",
                    // height: "100%",
                    backgroundColor: "#d3d3d3",
                  }}
                >
                  <img
                    alt=""
                    style={{
                      flexShrink: 0,
                      minWidth: "100%",
                      minHeight: "100%",
                    }}
                    src={
                      props.channelLogo
                        ? process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(
                            0,
                            -1
                          ) + props.channelLogo
                        : groupImage
                    }
                  />
                </div>
              </Grid>
              <Grid item lg={9} md={9} sm={7} xs={7}>
                <Typography className={classes.groupInfo}>
                  {props.channelName}
                </Typography>
                {!data.channelInfo ? null : (
                  <Typography style={{ marginBottom: "1%" }}>
                    {props.channelDescription &&
                      Parse(props.channelDescription)}
                  </Typography>
                )}
                <Box style={{ marginBottom: "1%" }}>
                  {props.channelPrivacy === "Public" ? (
                    <Box display="flex" alignItems="center">
                      <PublicIcon style={{ color: "#3399ff" }} />
                      <Typography
                        style={{ fontSize: "small", paddingLeft: "2%" }}
                      >
                        Public Channel
                      </Typography>
                    </Box>
                  ) : null}
                  {props.channelPrivacy === "Restricted" ? (
                    <Box display="flex" alignItems="center">
                      <LockIcon style={{ color: "#3399ff" }} />
                      <Typography
                        style={{ fontSize: "small", paddingLeft: "2%" }}
                      >
                        Restricted Channel
                      </Typography>
                    </Box>
                  ) : null}
                  {props.channelPrivacy === "Private" ? (
                    <Box display="flex" alignItems="center">
                      <LockOpenIcon style={{ color: "#3399ff" }} />
                      <Typography
                        style={{ fontSize: "small", paddingLeft: "2%" }}
                      >
                        Private Channel
                      </Typography>
                    </Box>
                  ) : null}
                  {props.channelPrivacy === "Secret" ? (
                    <Box display="flex" alignItems="center">
                      <SecretIcon
                        style={{ color: "#3399ff", marginTop: "2%" }}
                      />
                      <Typography
                        style={{ fontSize: "small", paddingLeft: "2%" }}
                      >
                        Secret Channel
                      </Typography>
                    </Box>
                  ) : null}
                </Box>
                <Typography
                  style={{ fontWeight: "bolder", marginBottom: "1%" }}
                  // onClick={onMemberClick}
                >
                  {props.channelMembers} members
                </Typography>
                <Typography
                  style={{ fontWeight: "bold", marginBottom: "1%" }}
                  className={classes.typo}
                >
                  {props.groupName}
                </Typography>
              </Grid>
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Box
                style={{
                  display: "flex",
                  justifyContent: matchesSmallScreen ? "center" : "",
                  marginTop: matchesSmallScreen ? "2%" : "",
                }}
              >
                <Button
                  variant="contained"
                  className={classes.button}
                  startIcon={<CheckIcon />}
                  onClick={() => {
                    dispatch(
                      EMDoRequestToJoinChannelAction({
                        id: data.singleChannel.id
                          ? data.singleChannel.id
                          : window.sessionStorage.getItem("ChannelId"),
                      })
                    );
                    setRequestToJoin(true);
                  }}
                  disabled={
                    data.singleChannel.join_request_status === "1" ||
                    requestToJoin === true
                  }
                >
                  {data.singleChannel.join_request_status === "1" ||
                  requestToJoin === true
                    ? "Request Sent"
                    : "Request to join"}
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Card className={classes.card}>
            <CardContent>
              <div
                style={{
                  display: matchesSmallScreen ? "" : "flex",
                  marginBottom: "1%",
                }}
              >
                <Paper style={{ width: matchesSmallScreen ? "" : "50%" }}>
                  <Typography paragraph={true}>
                    {props.channelVision && Parse(props.channelVision)}
                  </Typography>
                </Paper>
                <Paper
                  style={{
                    width: matchesSmallScreen ? "" : "50%",
                    marginLeft: matchesSmallScreen ? "0%" : "2%",
                    marginBottom: matchesSmallScreen ? "1%" : "0%",
                  }}
                >
                  <Typography paragraph={true}>
                    {props.channelMission && Parse(props.channelMission)}
                  </Typography>
                </Paper>
              </div>
              <div
                style={{
                  display: matchesSmallScreen ? "" : "flex",
                  marginBottom: "1%",
                }}
              >
                <Paper style={{ width: matchesSmallScreen ? "" : "50%" }}>
                  <Typography paragraph={true}>
                    {props.channelAdditionalInfo &&
                      Parse(props.channelAdditionalInfo)}
                  </Typography>
                </Paper>
                <Paper
                  style={{
                    width: matchesSmallScreen ? "" : "50%",
                    marginLeft: matchesSmallScreen ? "0%" : "2%",
                    marginBottom: matchesSmallScreen ? "1%" : "0%",
                  }}
                >
                  {data.singleChannel.attachments.length > 0 ? (
                    <div className={classes.rootForAttachments}>
                      <GridList
                        cellHeight="auto"
                        className={classes.gridListForAttachments}
                        cols={1}
                      >
                        {data.singleChannel.attachments.map((doc, id) => (
                          <Box display="flex">
                            <Typography>
                              <a
                                href={
                                  process.env.REACT_APP_BACKEND_FILE_PREVIEW +
                                  doc.file
                                }
                                style={{ color: "#2F92D6" }}
                                target="_blank"
                              >
                                {doc &&
                                  doc.file.substr(
                                    doc && doc.file.lastIndexOf("/") + 1,
                                    doc && doc.length
                                  )}
                              </a>
                            </Typography>
                          </Box>
                        ))}
                      </GridList>
                    </div>
                  ) : null}
                </Paper>
              </div>
            </CardContent>
          </Card>
        </Card>
      </Paper>
      <Paper elevation={2} style={{ marginTop: "2%" }}>
        <Grid
          container
          direction="row"
          style={{ padding: 10 }}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item lg={2} md={2} sm={3} xs={5}>
            <Typography
              component="h6"
              variant="h6"
              style={{ fontWeight: "bolder" }}
            >
              Group Admins
            </Typography>
          </Grid>
          <Grid item lg={10} md={10} sm={9} xs={7}>
            <div className={classes.root}>
              <GridList
                className={classes.gridList}
                // style={{ alignItems: "center" }}
                cellHeight="auto"
                cols={colsForAdm}
              >
                {props.channelAdmins &&
                  props.channelAdmins.map((admin, index) => (
                    <GridListTile className={classes.gridListTile1}>
                      <Avatar
                        onClick={
                          () =>
                            navigate(`/users/people/${parseInt(admin.id)}`, {
                              state: {
                                name: admin.first_name,
                                id: parseInt(admin.id),
                              },
                            })
                          // dispatch(EMDoGetProfileDetailsAction(loginUserData.profile_id))
                        }
                        style={{
                          margin: "auto",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "48px",
                          width: "48px",
                          cursor: "pointer",
                        }}
                        src={
                          process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(
                            0,
                            -1
                          ) + admin.profile_pic_file
                        }
                      />
                      <Tooltip
                        placement="top"
                        title={
                          <h2 style={{ color: "white" }}>
                            {admin.first_name + " " + admin.last_name}
                          </h2>
                        }
                      >
                        <Typography
                          className={classes.typo1}
                          style={{ textAlign: "center", marginTop: "5%" }}
                        >
                          {admin.first_name} {admin.last_name}
                        </Typography>
                      </Tooltip>
                    </GridListTile>
                  ))}
              </GridList>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

function SecretIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M12.227,9.819l.761-1.994a.509.509,0,0,0-.478-.691H10.647A4.038,4.038,0,0,0,11.214,5.1v-.01c1.248-.248,2.038-.608,2.038-1.01,0-.424-.869-.8-2.233-1.051A8.262,8.262,0,0,0,9.726.389,1.022,1.022,0,0,0,8.468.109l-.879.44a1.019,1.019,0,0,1-.911,0L5.8.109a1.022,1.022,0,0,0-1.258.28A8.329,8.329,0,0,0,3.249,3.026c-1.36.252-2.229.627-2.229,1.051,0,.4.79.761,2.038,1.01V5.1a4.038,4.038,0,0,0,.567,2.038H1.793a.509.509,0,0,0-.468.71l.822,1.917A4.266,4.266,0,0,0,0,13.453V14.88a1.429,1.429,0,0,0,1.427,1.427H12.841a1.429,1.429,0,0,0,1.427-1.427V13.453a4.268,4.268,0,0,0-2.041-3.634ZM5.605,15.288,4.28,9.173l1.58,1.019.764,1.274Zm3.057,0L7.644,11.466l.764-1.274,1.58-1.019ZM9.991,5.781a2.342,2.342,0,0,1-.525,1.064c-.322.3-1.529.713-2.038-.8a.312.312,0,0,0-.583,0c-.541,1.6-1.784,1.032-2.038.8A2.311,2.311,0,0,1,4.28,5.781c-.025-.08-.2-.182-.2-.185V5.252a24.526,24.526,0,0,0,3.057.185,23.929,23.929,0,0,0,3.057-.185V5.6S10.016,5.7,9.991,5.781Z" />
    </SvgIcon>
  );
}

export default withWidth()(EMGroupDetailsForNew);
