import {
  Box,
  IconButton,
  TableCell,
  Tooltip,
  Typography,
} from "@material-ui/core";
import EMSimpleDatatable from "../../../../../../components/datatables/EMSimpleDatatable";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import EMSwitch from "../../../../../../components/commans/EMSwitch";
import Checkbox from "@material-ui/core/Checkbox";
import { EMDoGetNetworkAdminImportUsersListAction } from "../../../../../../redux/actions/EMNetworkAdminActions";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { filter as lodash_filter } from "lodash";

const useStyles = makeStyles((theme) => ({
  typo: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 1,
    wordBreak: "break-word",
    overflow: "hidden",
  },
}));

export default function EMNetworkAdminManageUsers({
  filter,
  data,
  onChangePermission,
  entity,
  close,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(15);
  const [offset, setOffset] = useState(0);
  const [order, setOrder] = useState({ id: -1, direction: "asc" });
  const [checked, setChecked] = useState(false);
  const [sortAscending, setSortAscending] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (filter && filter.length > 0) {
      setOffset(0);
    } else if (filter && filter == "") {
      setOffset(offset);
    }
  }, [filter]);

  const columns = [
    {
      title: "Name",
      align: "left",
      field: "first_name",
      render: (data) => {
        return (
          <>
            <Tooltip
              title={
                <h2
                  style={{ color: "white" }}
                >{`${data["first_name"]}  ${data["last_name"]}`}</h2>
              }
              placement="top"
            >
              <Typography
                className={classes.typo}
              >{`${data["first_name"]}  ${data["last_name"]}`}</Typography>
            </Tooltip>
          </>
        );
      },
    },

    {
      title: "E-mail Address ",
      field: "email",
      align: "left",
      render: (data) => {
        return (
          <>
            <Tooltip
              title={<h2 style={{ color: "white" }}>{data["email"]}</h2>}
              placement="top"
            >
              <Typography className={classes.typo}>
                {data["email"] && data["email"]}
              </Typography>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Invite",
      align: "left",
      render: (data) => {
        return (
          <Box
          style={{
            height: "35px",
            width: "35px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <label
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <input
              type="checkbox"
              className="input-checkbox"
              checked={data["isChecked"]}
              onChange={(event) => {
                let state = event.target.checked;
                data["isChecked"] = state;
                onChangePermission(data, event);
              }}
            />
            <svg
              className={`checkbox ${
                data["isChecked"] ? "checkbox--active" : ""
              }`}
              aria-hidden="true"
              viewBox="0 0 15 11"
              fill="none"
            >
              <path
                d="M1 4.5L5 9L14 1"
                strokeWidth="2"
                stroke={data["isChecked"] ? "#fff" : "none"}
              />
            </svg>
          </label>
        </Box>
        );
      },
    },
  ];

  const updateData = (query) => {
    if (query.indexOf("limit") >= 0 && query.indexOf("context_type") >= 0) {
      dispatch(EMDoGetNetworkAdminImportUsersListAction(query));
    }
  };

  useEffect(() => {
    updateData("?");
  }, []);

  useEffect(() => {
    const query = [];
    if (limit !== 0) {
      query.push(`limit=${limit}`);
    }
    if (filter) {
      query.push(`search=${filter}`);
    }
    if (offset !== 0) {
      query.push(`offset=${offset}`);
    }
    if (order.id !== -1) {
      if (sortAscending === false) {
        query.push(
          `ordering=${order.direction === "desc" ? "-" : ""}${
            columns[order.id - 1].field
          }`
        );
        setSortAscending(true);
      } else {
        // order.direction = "desc"
        query.push(
          `ordering=${order.direction === "desc" ? "-" : ""}${
            columns[order.id - 1].field
          }`
        );
        setSortAscending(false);
      }
    }
    if (!isEmpty(entity)) {
      query.push(`context_type=${entity.context_type}`, `context=${entity.context}`);
    }
    updateData("?" + query.join("&"));
  }, [limit, filter, offset, order, entity]);

  return (
    <Box p={2} onClose={close}>
      <EMSimpleDatatable
      filter={filter}
        columns={columns}
        data={data["results"]}
        // updatePageAndPageSizeAndOrder={() => {}}
        enableCustomPagination={false}
        count={data["count"] || 0}
        updatePageAndPageSizeAndOrder={(page, pageSize, order) => {
          setOffset(page * limit);
          setLimit(pageSize);
          setOrder(order);
        }}
        options={{
          headerStyle: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.common.white,
            fontWeight: "bold",
          },
        }}
      />
    </Box>
  );
}
