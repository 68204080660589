import React from "react";
import {
  Box,
  Button,
  Modal,
  Typography,
  IconButton,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { EMSimpleTextFieldView } from "../../../../components/EMTextFieldView";
import { CloseRounded } from "@material-ui/icons";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  EMDoCreateEmailTemplateAction,
  EMDoUpdateEmailTemplateAction,
  EMDOGetEmailTemplateTypeListAction,
  EMDoCreateNewDepartmentInOrganizationAction,
  EMDoUpdateDepartmentInOrganizationAction,
} from "../../../../redux/actions/EMNetworkAdminActions";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import EMTemplateTypeDropdown from "../../UserManagement/commons/EMTemplateTypeDropdown";
import EMTextArea from "../../../../components/commans/EMTextArea";
import { isEmpty } from "lodash-es";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    height: "auto",
    minWidth: "50%",
    margin: "auto",
    padding: "0px",
    // maxWidth: '100vw',
    maxHeight: "100%",
    position: "fixed",
    top: "50%",
    transform: "translate(0, -50%)",
    width: "60%",
    overflowY: "auto",
    overflowX: "hidden",
  },
  title: {
    margin: 10,
    flexGrow: 1,
    fontWeight: "bold",
    color: "white",
    fontSize: "large",
},
  form: {
    padding: "8px 36px",
    width: "100%",
  },
  buttonHolder: {
    padding: theme.spacing(3),
  },
}));

export default function EMAddEditDepartment(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  //reset the form after modal is closed
  /* Validation And Formik Values And Handlers */
  const validationSchema = yup.object({
    departmentName: yup.string().required("Department Name is required"),
  });


  const formik = useFormik({
    // enableReinitialize :props.isEdit,

    enableReinitialize: true,
    initialValues: {
      departmentName:
        (props.departmentData && props.departmentData["department"]) || "",
    },
    validationSchema: validationSchema,

    onSubmit: (values) => {
      if (props.departmentData && isEmpty(props.departmentData)) {
        let data = {
          organizationId: props.entity && props.entity.context,
          department: values.departmentName,
        };
        dispatch(EMDoCreateNewDepartmentInOrganizationAction({ data: data, key: "add_department" }));
      }else{
        let data = {
            organizationId: props.entity && props.entity.context,
            department: values.departmentName,
            departmentId:props.departmentData && props.departmentData.id
          };
          dispatch(EMDoUpdateDepartmentInOrganizationAction({data:data, key: "add_department"}))
      }

    },
  });

  useEffect(() => {
    return () => {
      formik.resetForm();
    };
  }, [props.open]);

  return (
    <Modal
      open={props.open}
      onClose={() => {
        formik.resetForm();
        props.setOpenFalse(false);
      }}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box className={classes.root} style={{ outline: "none" }}>
        <form onSubmit={formik.handleSubmit}>
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            style={{
              padding: "0px 4px 0px 18px",
              backgroundColor: "#2F92D6",
              height: "auto",
            }}
          >
            <Typography className={classes.title}>
              {props.titleLable}
            </Typography>
            <IconButton
              onClick={() => {
                formik.resetForm();
                props.setOpenFalse(false);
              }}
            >
              <CloseRounded style={{color:"white"}} />
            </IconButton>
          </Box>
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.form}
            style={{paddingTop:"16px"}}
          >
            <EMSimpleTextFieldView
              label={"Department Name"}
              id="departmentName"
              name="departmentName"
              fullwidth
              value={formik.values.departmentName}
              onChange={formik.handleChange}
              // error={
              //   formik.touched.departmentName &&
              //   Boolean(formik.errors.departmentName)
              // }
              helperText={
                formik.touched.departmentName && formik.errors.departmentName
              }
            />
          </Grid>

          <Grid
            container
            justify="center"
            alignItems="center"
            direction="row"
            className={classes.buttonHolder}
            spacing={2}
            // onClick={() => {
            //     //checkValidation()
            // }}
          >
            <Grid item>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                type="submit"
                disabled={props.saveClicked}
              >
                <Typography variant="h7">{props.buttonLabel}</Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.button}
                variant="outlined"
                color="secondary"
                onClick={() => {
                  formik.resetForm();
                  props.setOpenFalse(false);
                }}
              >
                <Typography variant="h7">Discard</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
}
