import {
  Paper,
  Box,
  Button,
  Modal,
  CircularProgress,
  Typography,
  Hidden,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import EMPage from "../../../components/commans/EMPage";
import EMDatatableSearchView from "../../../components/datatables/EMDatatableSearchView";
import EMPartnershipTable from "./EMPartnershipTable";
import EMAddEditPartnership from "./EMAddEditPartnership";
import { useDispatch, useSelector } from "react-redux";
import { find, findLast } from "lodash";
import { PLATFORM_ADMIN_UPDATE_PARTNERSHIP_LIST } from "../../../redux/constants";
import {
  EMDoGetOrganizationAdminsAction,
  EMDoGetPartnershipAdminsAction,
} from "../../../redux/actions/EMAdminActions";

export default function EMPlatformPartnershipanagement(props) {
  const [filter, setFilter] = useState("");
  const [open, setOpen] = React.useState(false);
  const [editData, setEditData] = React.useState({});
  const [showLabels, setShowLabels] = React.useState({
    label: "",
    buttonLabel: "",
  });
  const [saveClicked, setDisabled] = React.useState(false);
  const dispatch = useDispatch();
  /* open and close modal functionality */
  const handleOpen = () => {
    dispatch({ type: PLATFORM_ADMIN_UPDATE_PARTNERSHIP_LIST, payload: false });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditData({});
  };

  const uiData = useSelector((state) => state.ui);

  useEffect(() => {
    if (uiData["messages"] && uiData.loading !== true) {
      let refObj = findLast(uiData["messages"], {
        key: "partnership_add_edit",
      });
      if (refObj && refObj.type === "success") {
        setOpen(false);
        setDisabled(false);
        refObj.key = "partnership_add_edit_success";
        dispatch({
          type: PLATFORM_ADMIN_UPDATE_PARTNERSHIP_LIST,
          payload: true,
        });
      } else if (refObj && refObj.type === "error") {
        setDisabled(false);
        refObj.type = "failed";
      }
    }
  }, [uiData]);


  return (
    <EMPage title="Partnership Management" style={{ padding: "2%" }}>
      <Box>
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={uiData.loading.state}
        >
          <Box
            style={{ display: "flex" }}
            alignItems="center"
            justifyContent="center"
          >
            <Box textAlign="center">
              <CircularProgress />
              <Typography style={{ fontWeight: "medium", fontSize: "large" }}>
                {uiData.loading.message}
              </Typography>
            </Box>
          </Box>
        </Modal>
        <Paper elevation={2}>
          <Hidden mdDown>
            <Box height="100%" display="flex" flexDirection="column">
              <Box
                width="100%"
                display="flex"
                flexDirection="row"
                p={3}
                justifyItems="center"
                alignItems="center"
              >
                <Box flexGrow={1}>
                  <EMDatatableSearchView
                    hint={"Search by Partnership Name"}
                    onChangeListener={(data) => {
                      setFilter(data);
                    }}
                  />
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      handleOpen();
                      setShowLabels({
                        label: "Add A Partner Network",
                        buttonLabel: "Save",
                      });
                      setEditData({});
                      setDisabled(false);
                    }}
                  >
                    {"Add new Partnership"}
                  </Button>
                </Box>
                <Box>
                  <EMAddEditPartnership
                    open={open}
                    close={handleClose}
                    partnershipData={editData}
                    pagelabel={showLabels.label}
                    buttonLabel={showLabels.buttonLabel}
                    saveClicked={saveClicked}
                    setDisabled={setDisabled}
                  />
                </Box>
              </Box>
              <EMPartnershipTable
                filter={filter}
                onEdit={async (partnershipData) => {
                  setEditData(partnershipData);
                  setOpen(true);
                  setShowLabels({
                    label: "Edit Partner Network Detail",
                    buttonLabel: "Save",
                  });
                  dispatch({
                    type: PLATFORM_ADMIN_UPDATE_PARTNERSHIP_LIST,
                    payload: false,
                  });
                  dispatch(EMDoGetPartnershipAdminsAction(partnershipData.id));
                  let selectedOrgIds = partnershipData.organization?.map(
                    (d) => d.id
                  );
                  dispatch(EMDoGetOrganizationAdminsAction(selectedOrgIds));
                }}
              />
            </Box>
          </Hidden>
          <Hidden lgUp>
            <Box height="100%" display="flex" flexDirection="column">
              <Box
                width="100%"
                display="flex"
                flexDirection="row"
                p={3}
                justifyItems="center"
                alignItems="center"
              >
                <Box flexGrow={1}>
                  <EMDatatableSearchView
                    hint={"Search by Partnership Name"}
                    onChangeListener={(data) => {
                      setFilter(data);
                    }}
                  />
                </Box>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: "20px" }}
                  onClick={() => {
                    handleOpen();
                    setShowLabels({
                      label: "Add A Partner Network",
                      buttonLabel: "Save",
                    });
                    setEditData({});
                    setDisabled(false);
                  }}
                >
                  {"Add new Partnership"}
                </Button>
              </Box>
              <Box>
                <EMAddEditPartnership
                  open={open}
                  close={handleClose}
                  partnershipData={editData}
                  pagelabel={showLabels.label}
                  buttonLabel={showLabels.buttonLabel}
                  saveClicked={saveClicked}
                  setDisabled={setDisabled}
                />
              </Box>
              <EMPartnershipTable
                filter={filter}
                onEdit={(partnershipData) => {
                  setEditData(partnershipData);
                  setOpen(true);
                  setShowLabels({
                    label: "Edit Partner Network Detail",
                    buttonLabel: "Save",
                  });
                  dispatch({
                    type: PLATFORM_ADMIN_UPDATE_PARTNERSHIP_LIST,
                    payload: false,
                  });
                  dispatch(EMDoGetPartnershipAdminsAction(partnershipData.id));
                  let selectedOrgIds = partnershipData.organization?.map(
                    (d) => d.id
                  );
                  dispatch(EMDoGetOrganizationAdminsAction(selectedOrgIds));
                }}
              />
            </Box>
          </Hidden>
        </Paper>
      </Box>
    </EMPage>
  );
}
