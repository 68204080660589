import React, { useState, useEffect, useRef } from "react";
import EMPage from "../../../components/commans/EMPage";
import { useLocation } from "react-router";
import Parse from "html-react-parser";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { useTheme } from "@material-ui/core/styles";
import organizationLogo from "../../../../src/assets/organization_logo.jpg";
import {
  Card,
  Typography,
  Grid,
  Paper,
  GridList,
  Box,
  GridListTile,
  CardContent,
  Avatar,
  IconButton,
  useMediaQuery,
  Tooltip,
} from "@material-ui/core";
import { useNavigate } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { CHANGE_ELEMENT_FOR_SUBTABS } from "../../../redux/constants/UserPlatform/EMNavigationConstant";
import { isEmpty, find } from "lodash";
import EMGroupView from "../../../components/user/EMGroupView";
import { EMDoGetActionsListForDropdownAction, EMDoGetOrganizationdetailsAction } from "../../../redux/actions/UserPlatform/Network/EMMyNetworkAction";
import EMSideBar from "../../../components/navigation/EMSideBar";
import EMPointsTable from "./EMPointsTable";

const useStyles = makeStyles((theme) => ({
  rootForPaper: {
    paddingBottom: "2%",
    width: "100%",
    marginTop: "0.8%",
  },
  rootForGroups: {
    // marginTop: "2%",
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridListforGroups: {
    width: "100%",
    height: "100%",
    padding: 10,
    maxHeight: window.screen.height,
    "&::-webkit-scrollbar": {
      width: "0em",
      height: "0em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#4A4A4A",
      outline: "1px solid slategrey",
    },
  },
  root: {
    width: "100%",
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-around",
    overflow: "hidden",
    marginRight: "2%",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    overflowY: "hidden",
    width: "100%",
    // height: window.screen.height/7,
    padding: 10,
    maxWidth: window.screen.width,
    "&::-webkit-scrollbar": {
      width: "0em",
      height: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#4A4A4A",
      outline: "1px solid slategrey",
    },
  },
  button: {
    marginTop: "15px",
    marginRight: "15px",
    fontWeight: "bolder",
    // width: "190px",
    float: "right",
  },
  gridListTile1: {
    marginTop: "2%",
  },
  rootForOrganizations: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridListForOrganizations: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    overflowY: "hidden",
    "&::-webkit-scrollbar": {
      width: "0em",
      height: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#4A4A4A",
      outline: "1px solid slategrey",
    },
  },

  rootForGrid: {
    display: "flex",
    paddingLeft: "3%",
    paddingTop: "2%",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    marginTop: "1%",
  },
  gridListTile: {
    marginTop: "4px",
    marginBottom: "10px",
    borderRadius: "10px",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);",
  },
  rootForAttachments: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
  },
  gridListForAttachments: {
    // padding:"10px",
    width: "100%",
    height: "100%",
    maxHeight: window.screen.height / 2.5,
    "&::-webkit-scrollbar": {
      width: "0.1em",
    },
  },
  typo:{
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 1,
    wordBreak:"break-word",
    overflow: "hidden",
  },
  typo1:{
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 2,
    wordBreak:"break-word",
    overflow: "hidden",
  },
}));

function EMOrganizationDetails(props) {
  const myRef = useRef(null);
  const channelRef = useRef(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const data = useSelector((state) => state.myNetwork || {});
  const userData = useSelector((state) => state.auth || {});

  const theme = useTheme();

  const matchesMediumScreen = useMediaQuery(theme.breakpoints.up("md"));

  const matchesLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const matchesSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    dispatch(EMDoGetOrganizationdetailsAction());
    dispatch(EMDoGetActionsListForDropdownAction())
  }, []);

  function getCols(screenWidth) {
    if (isWidthUp("xl", screenWidth)) {
      return 3;
    }

    if (isWidthUp("lg", screenWidth)) {
      return 3;
    }
    if (isWidthUp("md", screenWidth)) {
      return 2;
    }
    if (isWidthUp("sm", screenWidth)) {
      return 2;
    }
    if (isWidthUp("xs", screenWidth)) {
      return 1;
    }
    return 4;
  }
  const cols = getCols(props.width);

  function getColsForOrg(screenWidth) {
    if (isWidthUp("xl", screenWidth)) {
      return 8;
    } else if (isWidthUp("lg", screenWidth)) {
      return 7;
    } else if (isWidthUp("md", screenWidth)) {
      return 6;
    } else if (isWidthUp("sm", screenWidth)) {
      return 5;
    } else if (isWidthUp("xs", screenWidth)) {
      return 4;
    }
    return 4;
  }

  const colsForOrg = getColsForOrg(props.width);

  function getColsForAdm(screenWidth) {
    if (isWidthUp("xl", screenWidth)) {
      return 8;
    } else if (isWidthUp("lg", screenWidth)) {
      return 7;
    } else if (isWidthUp("md", screenWidth)) {
      return 6;
    } else if (isWidthUp("sm", screenWidth)) {
      return 5;
    } else if (isWidthUp("xs", screenWidth)) {
      return 3;
    }
    return 4;
  }

  const colsForAdm = getColsForAdm(props.width);

  return (
    <EMPage
      title="Organization details"
      style={{
        width: "100%",
        paddingLeft: matchesLargeScreen ? "8%" : "4%",
        paddingTop: matchesLargeScreen
          ? "4%"
          : matchesMediumScreen
          ? "6%"
          : "8%",
        paddingRight: matchesLargeScreen ? "8%" : "4%",
        paddingBottom: "2%",
      }}
    >
      <Grid container spacing={3}>
        {matchesMediumScreen ? (
          <Grid item xs={matchesLargeScreen ? 2 : 3}>
            <EMSideBar />
          </Grid>
        ) : null}
        <Grid item xs={matchesLargeScreen ? 10 : matchesMediumScreen ? 9 : 12}>
          <Paper elevation={2} className={classes.rootForPaper}>
            <Grid
              container
              spacing={2}
              style={{
                paddingLeft: "2%",
                paddingRight: "2%",
                paddingTop: "2%",
              }}
            >
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                item
                lg={2}
                md={3}
                sm={4}
                xs={5}
              >
                <Card
                  elevation={2}
                  // style={{
                  //   height: "180px",
                  // }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      overflow: "hidden",
                      height: "100%",
                      backgroundColor: "#d3d3d3",
                    }}
                  >
                    <img
                      alt=""
                      style={{
                        flexShrink: 0,
                        minWidth: "100%",
                        minHeight: "100%",
                      }}
                      src={
                        data.organizationDetails.logo_file
                          ? process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(
                              0,
                              -1
                            ) + data.organizationDetails.logo_file
                          : organizationLogo
                      }
                    />
                  </div>
                  {/* </CardContent> */}
                </Card>
              </Grid>

              <Grid item lg={10} md={9} sm={8} xs={7}>
                <Card>
                  <Typography variant="h6" style={{ fontWeight: "bold" }} className={classes.typo}>
                    {data.organizationDetails.organization_name}
                  </Typography>
                  <Typography>{data.organizationDetails.tagline}</Typography>
                  <Typography color="textSecondary">
                    {data.organizationDetails.number_of_org_users} members
                  </Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    Working With Partners
                  </Typography>
                  <div
                    className={classes.rootForOrganizations}
                    style={{ marginTop: "1%" }}
                  >
                    <GridList
                      className={classes.gridListForOrganizations}
                      cols={colsForOrg}
                    >
                      {data.organizationDetails.partnerships &&
                        data.organizationDetails.partnerships.map(
                          (group, index) => (
                            <GridListTile
                              key={index}
                              style={{
                                overflow: "hidden",
                                width: "100px",
                                height: "100px",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Paper
                                elevation={2}
                                style={{
                                  width: "70px",
                                  height: "70px",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  display: "flex",
                                }}
                              >
                                {group.logo_file ? (
                                  <div
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      whiteSpace: "nowrap",
                                      textAlign: "center",
                                    }}
                                  >
                                    <span
                                      style={{
                                        display: "inline-block",
                                        height: "100%",
                                        verticalAlign: "middle",
                                      }}
                                    ></span>
                                    <img
                                      alt=""
                                      style={{
                                        maxWidth: "100%",
                                        maxHeight: "100%",
                                        verticalAlign: "middle",
                                      }}
                                      src={
                                        process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(
                                          0,
                                          -1
                                        ) + group.logo_file
                                      }
                                    />
                                  </div>
                                ) : (
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                  >
                                    <Avatar
                                      style={{
                                        margin: "auto",
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      {group.partnership_name
                                        .charAt(0)
                                        .toUpperCase()}
                                    </Avatar>
                                  </Box>
                                )}
                              </Paper>
                            </GridListTile>
                          )
                        )}
                    </GridList>
                  </div>
                </Card>
              </Grid>
            </Grid>

            <div style={{ width: "100%", padding: "1%" }}>
              {data.organizationDetails.vision_stmt ||
              data.organizationDetails.organization_info ||
              data.organizationDetails.mission_stmt ||
              data.organizationDetails.organization_info ? (
                <Card style={{}}>
                  <CardContent className={classes.information}>
                    <div
                      style={{
                        display: matchesSmallScreen ? "" : "flex",
                        marginBottom: "1%",
                      }}
                    >
                      <Paper style={{ width: matchesSmallScreen ? "" : "50%" }}>
                        <Typography paragraph={true}>
                          {data.organizationDetails.vision_stmt &&
                            Parse(data.organizationDetails.vision_stmt)}
                        </Typography>
                      </Paper>
                      <Paper
                        style={{
                          width: matchesSmallScreen ? "" : "50%",
                          marginLeft: matchesSmallScreen ? "0%" : "2%",
                          marginBottom: matchesSmallScreen ? "1%" : "0%",
                        }}
                      >
                        <Typography paragraph={true}>
                          {data.organizationDetails.mission_stmt &&
                            Parse(data.organizationDetails.mission_stmt)}
                        </Typography>
                      </Paper>
                    </div>
                    <div
                      style={{
                        display: matchesSmallScreen ? "" : "flex",
                        marginBottom: "1%",
                      }}
                    >
                      <Paper style={{ width: matchesSmallScreen ? "" : "50%" }}>
                        <Typography paragraph={true}>
                          {data.organizationDetails.organization_info &&
                            Parse(data.organizationDetails.organization_info)}
                        </Typography>
                      </Paper>
                      <Paper
                        style={{
                          width: matchesSmallScreen ? "" : "50%",
                          marginLeft: matchesSmallScreen ? "0%" : "2%",
                          marginBottom: matchesSmallScreen ? "1%" : "0%",
                        }}
                      >
                        {data.organizationDetails.attachments &&
                        data.organizationDetails.attachments.length > 0 ? (
                          <div className={classes.rootForAttachments}>
                            <GridList
                              cellHeight="auto"
                              className={classes.gridListForAttachments}
                              cols={1}
                            >
                              {data.organizationDetails.attachments.map(
                                (doc, id) => (
                                  <Box display="flex">
                                    <Typography>
                                      <a
                                        href={
                                          process.env
                                            .REACT_APP_BACKEND_FILE_PREVIEW +
                                          doc.file
                                        }
                                        style={{ color: "#2F92D6" }}
                                        target="_blank"
                                      >
                                        {doc &&
                                          doc.file.substr(
                                            doc &&
                                              doc.file.lastIndexOf("/") + 1,
                                            doc && doc.length
                                          )}
                                      </a>
                                    </Typography>
                                  </Box>
                                )
                              )}
                            </GridList>
                          </div>
                        ) : null}
                      </Paper>
                    </div>
                  </CardContent>
                </Card>
              ) : null}
              <div style={{ paddingLeft: 30, display: "flex" }}>
                <Typography variant="h6" style={{ fontWeight: "bolder" }}>
                  {" "}
                  My Network Groups
                </Typography>
              </div>
              <div style={{ display: "flex" }}>
                {data.organizationDetails.groups &&
                data.organizationDetails.groups.length > 3 ? (
                  <IconButton
                    style={{ marginLeft: "10px" }}
                    onClick={() => {
                      if (
                        myRef.current.scrollLeft !== myRef.current.scrollWidth
                      ) {
                        myRef.current.scrollLeft -=
                          myRef.current.scrollWidth / 10;
                      }
                    }}
                  >
                    <NavigateBeforeIcon
                      fontSize="small"
                      style={{ color: "black" }}
                    />
                  </IconButton>
                ) : null}

                <div
                  className={
                    data.organizationDetails.groups &&
                    data.organizationDetails.groups.length > 3
                      ? classes.root
                      : classes.rootForGroups
                  }
                >
                  <GridList
                    className={
                      data.organizationDetails.groups &&
                      data.organizationDetails.groups.length > 3
                        ? classes.gridList
                        : classes.gridListforGroups
                    }
                    cols={cols}
                    ref={myRef}
                    style={{ overflowY: "hidden" }}
                  >
                    {data.organizationDetails.groups &&
                      data.organizationDetails.groups.map((network, key) => (
                        <GridListTile
                          key={network.id}
                          style={{
                            // width: "23.7%",
                            marginLeft: "1%",
                            marginTop: "2%",
                            height:"150px"
                          }}
                          className={classes.gridListTile}
                        >
                          <EMGroupView
                            route="browse_groups"
                            onStarClick={() => {}}
                            id={network.id}
                            group_name={network.group_name}
                            active={network.is_active}
                            logo_file={network.logo_file}
                            privacy_type_name={network.privacy_type_name}
                            is_fav={network.is_fav}
                            users_count={network.number_of_group_users}
                            onCardClick={() => {
                                navigate("/users/groups/activity", {
                                  state: { GroupId: network.id },
                                });
                                window.sessionStorage.setItem(
                                  "GroupId",
                                  network.id
                                );
                              }
                            }
                          />
                        </GridListTile>
                      ))}
                  </GridList>
                </div>

                {data.organizationDetails.groups &&
                data.organizationDetails.groups.length > 3 ? (
                  <IconButton
                    style={{ marginLeft: "-20px" }}
                    onClick={() => {
                      if (
                        myRef.current.scrollLeft !== myRef.current.scrollWidth
                      ) {
                        myRef.current.scrollLeft +=
                          myRef.current.scrollWidth / 10;
                      }
                    }}
                  >
                    <NavigateNextIcon
                      fontSize="small"
                      style={{ color: "black" }}
                    />
                  </IconButton>
                ) : null}
              </div>

              <div style={{ paddingLeft: 30, display: "flex" }}>
                <Typography variant="h6" style={{ fontWeight: "bolder" }}>
                  {" "}
                  My Network Channels
                </Typography>
              </div>
              <div style={{ display: "flex" }}>
                {data.organizationDetails.channels &&
                data.organizationDetails.channels.length > 3 ? (
                  <IconButton
                    onClick={() => {
                      if (
                        channelRef.current.scrollLeft !==
                        channelRef.current.scrollWidth
                      ) {
                        channelRef.current.scrollLeft -=
                          channelRef.current.scrollWidth / 10;
                      }
                    }}
                  >
                    <NavigateBeforeIcon
                      fontSize="small"
                      style={{ color: "black" }}
                    />
                  </IconButton>
                ) : null}
                <div
                  className={
                    data.organizationDetails.channels &&
                    data.organizationDetails.channels.length > 3
                      ? classes.root
                      : classes.rootForGroups
                  }
                >
                  <GridList
                    className={
                      data.organizationDetails.channels &&
                      data.organizationDetails.channels.length > 3
                        ? classes.gridList
                        : classes.gridListforGroups
                    }
                    cols={cols}
                    ref={channelRef}
                    style={{ overflowY: "hidden" }}
                  >
                    {data.organizationDetails.channels &&
                      data.organizationDetails.channels.map((network, key) => (
                        <GridListTile
                          key={network.id}
                          style={{
                            // width: "23.7%",
                            marginLeft: "1%",
                            marginTop: "2%",
                            height:"150px"
                          }}
                          className={classes.gridListTile}
                        >
                          <EMGroupView
                            route="browse_groups"
                            onStarClick={() => {}}
                            id={network.id}
                            group_name={network.channel_name || ""}
                            logo_file={network.logo_file}
                            privacy_type_name={network.privacy_type_name}
                            isFav={network.is_fav}
                            users_count={network.number_of_channel_users}
                            onCardClick={() => {
                              
                                navigate("/users/channels/activity", {
                                  state: { ChannelId: network.id },
                                });
                                window.sessionStorage.setItem(
                                  "ChannelId",
                                  network.id
                                );
                              }
                            }
                          />
                        </GridListTile>
                      ))}
                  </GridList>
                </div>
                {data.organizationDetails.channels &&
                data.organizationDetails.channels.length > 3 ? (
                  <IconButton
                    // style={{ marginLeft: "-20px" }}
                    onClick={() => {
                      if (
                        channelRef.current.scrollLeft !==
                        channelRef.current.scrollWidth
                      ) {
                        channelRef.current.scrollLeft +=
                          channelRef.current.scrollWidth / 10;
                      }
                    }}
                  >
                    <NavigateNextIcon
                      fontSize="small"
                      style={{ color: "black" }}
                    />
                  </IconButton>
                ) : null}
              </div>
            </div>
          </Paper>
          <Paper elevation={2} style={{ marginTop: "2%" }}>
            <Grid
              container
              direction="row"
              style={{ padding: 10 }}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item lg={2} md={2} sm={3} xs={5}>
                <Typography
                  component="h6"
                  variant="h6"
                  style={{ fontWeight: "bolder" }}
                >
                  Network Admins
                </Typography>
              </Grid>
              <Grid item lg={10} md={10} sm={9} xs={7}>
                <div className={classes.root}>
                  <GridList
                    className={classes.gridList}
                    // style={{ alignItems: "center" }}
                    cellHeight="auto"
                    cols={colsForAdm}
                  >
                    {data.organizationDetails.admins &&
                      data.organizationDetails.admins.map((admin, index) => (
                        <GridListTile className={classes.gridListTile1}>
                          <Avatar
                            onClick={
                              () =>
                                navigate(
                                  `/users/people/${parseInt(admin.user_id)}`,
                                  {
                                    state: {
                                      name: admin.first_name,
                                      id: parseInt(admin.user_id),
                                    },
                                  }
                                )
                              // dispatch(EMDoGetProfileDetailsAction(loginUserData.profile_id))
                            }
                            style={{
                              margin: "auto",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "48px",
                              width: "48px",
                            }}
                            src={
                              process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(
                                0,
                                -1
                              ) + admin.profile_pic_file
                            }
                          />
                          <Tooltip
                            placement="top"
                            title={
                              <h2 style={{ color: "white" }}>
                                {admin.first_name + " " + admin.last_name}
                              </h2>
                            }
                          >
                            <Typography
                            className={classes.typo1}
                              style={{ textAlign: "center", marginTop: "5%" }}
                            >
                              {admin.first_name} {admin.last_name}
                            </Typography>
                          </Tooltip>
                        </GridListTile>
                      ))}
                  </GridList>
                </div>
              </Grid>
            </Grid>
            
          </Paper>
          <EMPointsTable orgName={data.organizationDetails.organization_name}/>
        </Grid>
      </Grid>
      
    </EMPage>
  );
}

export default withWidth()(EMOrganizationDetails);
