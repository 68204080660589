import axios from "axios";
//Group listing api
const EMDoGetListOfMyChannels = () => {
  let url = "";
  url = `user/organization-channels/`;
  return axios.get(url);
};

const EMDoGetListOfMyJointChannels = () => {
  let url = "";
  url = `user/partnerships-with-channels/`;
  return axios.get(url);
};

const EMDoGetListOfAllChannels = (action) => {
  let url = "";
  url = `user/channels/browse/?limit=10&offset=${action.limit}`;
  return axios.get(url);
};

const EMDoGetListOfMyChannelsBySearch = (searchText) => {
  let url = "";
  url = `user/organization-channels/?search=${searchText}`;
  return axios.get(url);
};

const EMDoGetListOfMyJointChannelsBySearch = (searchText) => {
  let url = "";
  url = `user/partnerships-with-channels/?search=${searchText}`;
  return axios.get(url);
};

// const EMDoGetListOfChannelMembersBySearch = (id,searchText) => {
//   let url = "";
//   url =  `user/channels/${id}/users/?search=${searchText}&limit=100`;
//   return axios.get(url);
// };

const EMDoGetChannelDetails = (id) => {
  
  let url = "";
  url = `user/channels/${id}/details/`;
  return axios.get(url);
};

const EMDoGetChannelList = () => {
  let url = "";
  url = `user/channels-brief/`;
  return axios.get(url);
};

const EMDoGetMembersOfChannel = (id, limit) => {
  let url = "";
  url = `/user/channels/${id}/users/?limit=20&offset=${limit}`;
  return axios.get(url);
};

const EMDoGetListOfAllChannelsBySearch = (searchText) => {
  let url = "";
  url = `user/channels/browse/?search=${searchText}`;
  return axios.get(url);
};

// Member leaves channel
const EMDoLeaveChannel= (id) => {
  let url = `channel/${id}/leave-channel/`;
  return axios.delete(url);
};

const EMDoGetChannelPosts = (id, page) => {
  
  let url = "";
  url = `user/posts/channel/${id}/?page=${page}`;
  return axios.get(url);
};
const EMDoGetListOfChannelMembersBySearch = (id, searchText,limit) => {
  let url = "";
  url = `user/channels/${searchText}/users/?search=${id}&limit=20&offset=${limit}`;
  return axios.get(url);
};
// GET request - category filtered post list
const EMDoGetCategoryFilteredPostForChannelList = (id, selectedOption, page) => {
  let url;
  if (selectedOption) {
    url = `user/posts/channel/${id}/?category=${selectedOption}&page=${page}`
  } else {
    url = `/posts/?`;
  }

  return axios.get(url);
};

const EMDoActionForChannel = (myaction, id) => {
  let url;
  url =`channel/${myaction}/action:${id}/`
  return axios.patch(url);
}

const EMDoRequestToJoinChannel = (id) => {
  let url;
  url = `channel/${id}/action:join/`
  return axios.patch(url);
}

const EMDoGetUserRequestsForChannel = (id) => {
  let url;
  url = `channel/${id}/join-requests/`
  return axios.get(url)
}

const EMDoAcceptRejectUserRequestForChannel = (requestId, myaction) => {
  let url;
  url = `channel/join-requests/${requestId}/action:${myaction}/`;
  return axios.patch(url);
}

//Get groups for filter
const EMDoGetGroupsForFilterInChannel = () => {
  let url = `user/groups-brief/`
  return axios.get(url);
}

//Get Events by group Filter
const EMDoGetChannelsByGroupFilter = (action) => {
  let url;
   url = `user/channels/browse/?${action.myaction}&limit=10&offset=${action.limit}`
  return axios.get(url);
}

const EMDoGetMembersOfChannelByGroupFilter = (id, limit, action) => {
  let url = "";
  url = `/user/channels/${id}/users/?${action}&limit=20&offset=${limit}`;
  return axios.get(url);
};

const EMDoGetEmailNotificationChannelsApi = (action,id) => {
  let url = "";
  url = `channel/email-notifications/${id}/`;
  return axios.put(url,action);
};


export default {
  EMDoGetListOfMyChannels,
  EMDoGetListOfMyJointChannels,
  EMDoGetListOfAllChannels,
  // EMDoGetNetworkFilters,
  EMDoGetChannelDetails,
  EMDoGetChannelList,
  EMDoGetMembersOfChannel,
  EMDoGetChannelPosts,
  EMDoGetCategoryFilteredPostForChannelList,
  EMDoActionForChannel,
  EMDoGetListOfMyChannelsBySearch,
  EMDoGetListOfMyJointChannelsBySearch,
  EMDoRequestToJoinChannel,
  EMDoGetUserRequestsForChannel,
  EMDoAcceptRejectUserRequestForChannel,
  EMDoGetChannelsByGroupFilter,
  EMDoGetGroupsForFilterInChannel,
  EMDoGetMembersOfChannelByGroupFilter ,
  EMDoGetListOfChannelMembersBySearch,
  EMDoGetListOfAllChannelsBySearch,
  EMDoLeaveChannel,
  EMDoGetEmailNotificationChannelsApi
};
