import {
  Grid,
  Paper,
  Typography,
  Button,
  GridList,
  GridListTile,
  useMediaQuery,
} from "@material-ui/core";
import React, { useState, useRef, useEffect, useCallback } from "react";
import useFetch from "../../../../../components/user/EMUseInfiniteScrolls";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import EMPage from "../../../../../components/commans/EMPage";
import EMUser from "../../../../../components/user/EMUser";
import { useSelector, useDispatch } from "react-redux";
import EMFilter from "../../../../../components/user/EMFilter";
import EMSearchView from "../../../../../components/navigation/EMSearchView";
import {
  EMDoGetFilteredGroupMembersWithOrganizationAndPartnershipsAction,
  EMDoGetGroupDetailsAction,
  EMDoGetListOfGroupMembersSearchAction,
  EMDoGetMembersOfGroupAction,
  EMDoGetNetworkFiltersAction,
  EMDoGetNetworksByGroupIdAction,
} from "../../../../../redux/actions/UserPlatform/Groups/EMGroupsAction";
import {
  DISABLE_ENABLE_FILTER,
  DISABLE_ENABLE_SEARCH_GROUP_MEMBERS,
  GET_MEMBERS_OF_GROUP,
  GROUP_APPLY_NETWORK_FILTER,
  SHOW_NEXT_MEMBERS,
} from "../../../../../redux/constants/UserPlatform/EMGroupConstant";
import { EMDoGetProfileDetailsAction } from "../../../../../redux/actions/UserPlatform/People/EMPeopleActions";
import { useNavigate } from "react-router-dom";
import profile_image from "../../../../../assets/user_image.png";
import InfinitScroll from "react-infinite-scroll-component";
import EMSideBar from "../../../../../components/navigation/EMSideBar";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { EMGetRolesAndPermissionsBasedOnEntityAction } from "../../../../../redux/actions/EMAuthActions";
import EMSearch from "../../../../../components/navigation/EMSearch";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "2%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    fontWeight: "bold",
    //  marginLeft:"1%",
    //  marginBottom:"2%"
  },
  gridList: {
    width: "100%",
    height: "100%",
    marginLeft: "2%",
    // maxHeight: window.screen.height,
    // "&::-webkit-scrollbar": {
    //   width: "0.4em",
    // },
    // "&::-webkit-scrollbar-track": {
    //   boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    //   webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    // },
    // "&::-webkit-scrollbar-thumb": {
    //   backgroundColor: "#4A4A4A",
    //   outline: "1px solid slategrey",
    // },
  },
}));

function GroupMembersPage(props) {
  const data = useSelector((state) => state.groups || {});
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigation = useNavigate();
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(0);
  const [networkValue, setNetworkValue] = useState([]);
  const [limitWhileSearching, setLimitWhileSearching] = useState(0);
  const [limitWhileFiltering, setLimitWhileFiltering] = useState(0);
  const ref = useRef(null);
  const theme = useTheme();
  const matchesMediumScreen = useMediaQuery(theme.breakpoints.up("md"));
  const matchesLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  useEffect(() => {
    dispatch(
      EMDoGetNetworksByGroupIdAction({
        id: window.sessionStorage.getItem("GroupId"),
        route: false,
      })
    );
    dispatch({
      type: SHOW_NEXT_MEMBERS,
      payload: {
        nextMembers: undefined,
      },
    });
    dispatch({
      type: DISABLE_ENABLE_SEARCH_GROUP_MEMBERS,
      payload: true,
    });
    dispatch({
      type: DISABLE_ENABLE_FILTER,
      payload: true,
    });
  }, []);

  useEffect(() => {
    // dispatch(
    //   EMDoGetMembersOfGroupAction({
    //     id: data.singleGroup.id
    //       ? data.singleGroup.id
    //       : window.sessionStorage.getItem("GroupId"),
    //     limit: limit,
    //   })
    // );
    dispatch(
      EMDoGetGroupDetailsAction({
        id: window.sessionStorage.getItem("GroupId"),
      })
    );
    dispatch(
      EMGetRolesAndPermissionsBasedOnEntityAction({
        entity: "group",
        entityId: window.sessionStorage.getItem("GroupId"),
      })
    );
  }, []);

  const sendQuery = useCallback(async () => {
    if (search.length === 0 && networkValue.length === 0) {
      dispatch(
        EMDoGetMembersOfGroupAction({
          id: data.singleGroup.id
            ? data.singleGroup.id
            : window.sessionStorage.getItem("GroupId"),
          limit: limit,
        })
      );
    } else if (search.length > 0 && networkValue.length == 0) {
      dispatch(
        EMDoGetListOfGroupMembersSearchAction({
          id: window.sessionStorage.getItem("GroupId"),
          limit: limitWhileSearching,
          searchText: search,
        })
      );
    } else if (networkValue.length > 0 && search.length == 0) {
      if (networkValue.length == 1) {
        let selectedNetwork = networkValue[0];
        let action =
          selectedNetwork.context_type_name + "=" + selectedNetwork.context;

        dispatch(
          EMDoGetFilteredGroupMembersWithOrganizationAndPartnershipsAction({
            myaction: action,
            id: data.singleGroup.id
              ? data.singleGroup.id
              : window.sessionStorage.getItem("GroupId"),
            limit: limitWhileFiltering,
          })
        );
      } else if (networkValue.length > 1) {
        let organizationAction;
        let orgIds = [];
        let action;
        networkValue.map((selectedNetwork, key) => {
          if (selectedNetwork.context_type_name === "organization") {
            orgIds.push(selectedNetwork.context);
            organizationAction = `${
              selectedNetwork.context_type_name
            }=${orgIds.toString()}`;
          }
          if (organizationAction != undefined) {
            action = organizationAction;
          }
        });
        dispatch(
          EMDoGetFilteredGroupMembersWithOrganizationAndPartnershipsAction({
            myaction: action,
            id: data.singleGroup.id
              ? data.singleGroup.id
              : window.sessionStorage.getItem("GroupId"),
            limit: limitWhileFiltering,
          })
        );
      }
    } else if (networkValue.length > 0 && search.length > 0) {
      if (networkValue.length == 1) {
        let selectedNetwork = networkValue[0];
        let action =
          selectedNetwork.context_type_name + "=" + selectedNetwork.context;
        if (search.length > 0) {
          action = action + "&" + "search=" + search;
        }
        dispatch(
          EMDoGetFilteredGroupMembersWithOrganizationAndPartnershipsAction({
            myaction: action,
            id: data.singleGroup.id
              ? data.singleGroup.id
              : window.sessionStorage.getItem("GroupId"),
            limit: limitWhileFiltering,
          })
        );
      } else if (networkValue.length > 1) {
        let organizationAction;
        let orgIds = [];
        let action;
        networkValue.map((selectedNetwork, key) => {
          if (selectedNetwork.context_type_name === "organization") {
            orgIds.push(selectedNetwork.context);
            organizationAction = `${
              selectedNetwork.context_type_name
            }=${orgIds.toString()}`;
          }
          if (organizationAction != undefined) {
            action = organizationAction;
          }
        });
        if (search.length > 0) {
          action = action + "&" + "search=" + search;
        }
        dispatch(
          EMDoGetFilteredGroupMembersWithOrganizationAndPartnershipsAction({
            myaction: action,
            id: data.singleGroup.id
              ? data.singleGroup.id
              : window.sessionStorage.getItem("GroupId"),
            limit: limitWhileFiltering,
          })
        );
      }
    }
  }, [limit, limitWhileSearching, limitWhileFiltering]);

  useEffect(() => {
    if (search.length === 0 && networkValue.length === 0) {
      sendQuery(limit);
    } else if (search.length > 0 && networkValue.length == 0) {
      sendQuery(limitWhileSearching);
    } else if (networkValue.length > 0 || search.length > 0) {
      sendQuery(limitWhileFiltering);
    }
  }, [sendQuery, limit, limitWhileSearching, limitWhileFiltering]);

  const handleObserver = useCallback(() => {
    if (search.length === 0 && networkValue.length === 0) {
      setLimit((prev) => prev + 20);
      setLimitWhileFiltering(0);
      setLimitWhileSearching(0);
    } else if (search.length > 0 && networkValue.length == 0) {
      setLimitWhileSearching((prev) => prev + 20);
      setLimit(0);
      setLimitWhileFiltering(0);
    } else if (networkValue.length > 0 || search.length > 0) {
      setLimitWhileFiltering((prev) => prev + 20);
      setLimit(0);
      setLimitWhileSearching(0);
    }
  }, [search, networkValue]);

  useEffect(() => {
    setNetworkValue(data.networks.filter((network) => network.value === true));
  }, [data.networks]);

  function getCols(screenWidth) {
    if (isWidthUp("xl", screenWidth)) {
      return 4;
    } else if (isWidthUp("lg", screenWidth)) {
      return 4;
    } else if (isWidthUp("md", screenWidth)) {
      return 4;
    } else if (isWidthUp("sm", screenWidth)) {
      return 3;
    } else if (isWidthUp("xs", screenWidth)) {
      return 2;
    }
    return 4;
  }

  const cols = getCols(props.width);

  useEffect(() => {
    let appliedFilters = networkValue;
    if (appliedFilters.length > 0) {
      setLimitWhileFiltering(0);
      if (appliedFilters.length == 1) {
        let selectedNetwork = appliedFilters[0];
        let action =
          selectedNetwork.context_type_name + "=" + selectedNetwork.context;
        if (selectedNetwork.context_type_name === "partnership") {
          action = `${selectedNetwork.context_type_name}s=${selectedNetwork.context}`;
        }
        if (search.length > 0) {
          action = action + "&" + "search=" + search;
        }
        dispatch(
          EMDoGetFilteredGroupMembersWithOrganizationAndPartnershipsAction({
            myaction: action,
            id: data.singleGroup.id
              ? data.singleGroup.id
              : window.sessionStorage.getItem("GroupId"),
            limit: limitWhileSearching,
          })
        );
      } else if (appliedFilters.length > 1) {
        let action;
        let partnershipAction;
        let organizationAction;
        let partnershipIds = [];
        let orgIds = [];
        appliedFilters.map((selectedNetwork, key) => {
          if (selectedNetwork.context_type_name === "partnership") {
            partnershipIds.push(selectedNetwork.context);
            partnershipAction = `${
              selectedNetwork.context_type_name
            }s=${partnershipIds.toString()}`;
          }
          if (selectedNetwork.context_type_name === "organization") {
            orgIds.push(selectedNetwork.context);
            organizationAction = `${
              selectedNetwork.context_type_name
            }=${orgIds.toString()}`;
          }
          if (organizationAction != undefined) {
            action = organizationAction;
          }
          if (organizationAction != undefined) {
            action = organizationAction;
          }
          if (partnershipAction != undefined) {
            action = partnershipAction;
          }
          if (
            organizationAction != undefined &&
            partnershipAction != undefined
          ) {
            action = organizationAction + "&" + partnershipAction;
          }
          if (search.length > 0) {
            action = action + "&" + "search=" + search;
          }
        });
        dispatch(
          EMDoGetFilteredGroupMembersWithOrganizationAndPartnershipsAction({
            myaction: action,
            id: data.singleGroup.id
              ? data.singleGroup.id
              : window.sessionStorage.getItem("GroupId"),
            limit: limitWhileFiltering,
          })
        );
      } else if (appliedFilters.length == 0 && search.length == 0) {
        setLimit(0);
        dispatch(
          EMDoGetMembersOfGroupAction({
            id: data.singleGroup.id
              ? data.singleGroup.id
              : window.sessionStorage.getItem("GroupId"),
            limit: limit,
          })
        );
      }
    } else {
      setLimitWhileSearching(0);
      let action = "search=" + search;
      dispatch(
        EMDoGetFilteredGroupMembersWithOrganizationAndPartnershipsAction({
          myaction: action,
          id: data.singleGroup.id
            ? data.singleGroup.id
            : window.sessionStorage.getItem("GroupId"),
          limit: limitWhileSearching,
        })
      );
    }
  }, [search]);

  return (
    <InfinitScroll
      dataLength={data.members.length}
      next={handleObserver}
      hasMore={true}
    >
      <EMPage
        title="Group Members"
        style={{
          width: "100%",
          paddingLeft: matchesLargeScreen ? "4%" : "2%",
          paddingTop: matchesLargeScreen
            ? "4%"
            : matchesMediumScreen
            ? "6%"
            : "8%",
          paddingRight: matchesLargeScreen ? "4%" : "2%",
          paddingBottom: "2%",
        }}
      >
        <div id="page" ref={ref}>
          <Grid container direction="row" alignItems="flexStart" spacing={3}>
            {matchesMediumScreen ? (
              <Grid item xs={matchesLargeScreen ? 2 : 3}>
                <EMSideBar />
              </Grid>
            ) : null}
            {data.singleGroup ? (
              <Grid
                item
                xs={matchesLargeScreen ? 7 : matchesMediumScreen ? 9 : 12}
              >
                <Paper elevation={2}>
                  <Grid container alignItems="center">
                    <Grid item md={9} sm={8} xs={7}>
                      <Typography
                        className={classes.title}
                        variant={"h5"}
                        style={{ padding: "2%" }}
                      >
                        {"Group Members"}
                      </Typography>
                    </Grid>
                    <Grid item md={3} sm={4} xs={5}>
                      <EMSearch
                        disabled={
                          data.isSearchDisabledGroupMembers ||
                          data.isFilterDisabled
                        }
                        data={search}
                        usedIn="browse_groups"
                        route={true}
                        hint={"Search"}
                        onChangeListener={(serachData) => {
                          setSearch(serachData);
                          dispatch({
                            type: DISABLE_ENABLE_SEARCH_GROUP_MEMBERS,
                            payload: true,
                          });
                        }}
                      />
                    </Grid>
                  </Grid>
                  <div className={classes.root}>
                    <GridList
                      cellHeight={160}
                      className={classes.gridList}
                      cols={cols}
                    >
                      {data.members.map((user) => (
                        <GridListTile
                          style={{
                            // minWidth: "18%",
                            height: "100%",
                            padding: "1%",
                          }}
                        >
                          <EMUser
                            user={user}
                            profileImage={
                              user.profile_pic_url && user.profile_pic_url
                                ? process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(
                                    0,
                                    -1
                                  ) + user.profile_pic_url
                                : profile_image
                            }
                            userFirstName={user.first_name}
                            userLastName={user.last_name}
                            designation={user.professional_title}
                            organisationName={user.organisation_name}
                            email={user.email}
                            linkedinLink={
                              user.linkedin_profile_link &&
                              user.linkedin_profile_link
                            }
                            profileClickHandler={() =>
                              navigation(`/users/people/${user.id}`, {
                                state: { name: user.first_name, id: user.id },
                              })
                            }
                          />
                        </GridListTile>
                      ))}
                    </GridList>
                  </div>
                </Paper>
              </Grid>
            ) : null}
            {matchesLargeScreen ? (
              <Grid item xs={3}>
                <Paper elevation={2}>
                  <EMFilter
                    disabled={
                      data.isSearchDisabledGroupMembers || data.isFilterDisabled
                    }
                    filter_name="NETWORK FILTER"
                    network={data.networks}
                    network_filter={GROUP_APPLY_NETWORK_FILTER}
                    route={"group_members"}
                    handleLimitInGroups={(limit) => setLimit(limit)}
                    handleLimitWhileFiltering={(limit) =>
                      setLimitWhileFiltering(limit)
                    }
                    handleLimitWhileSearching={(limit) =>
                      setLimitWhileSearching(limit)
                    }
                    search={search}
                  />
                </Paper>
              </Grid>
            ) : null}
          </Grid>
        </div>
      </EMPage>
    </InfinitScroll>
  );
}
export default withWidth()(GroupMembersPage);
