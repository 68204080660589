import React from "react";
import {
  Paper,
  Box,
  Button,
  Modal,
  Typography,
  IconButton,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import {
  EMSimpleTextFieldView,
  EMChipTextFieldView,
} from "../../../components/EMTextFieldView";
import { CloseRounded } from "@material-ui/icons";
import { useFormik, Field } from "formik";
import * as yup from "yup";
import ChipInput from "material-ui-chip-input";
import { useEffect } from "react";
import {
  EMDoCreatePlatformAdminAction,
  EMDoUpdatePlatformAdminAction,
} from "../../../redux/actions/EMAdminActions";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("xs")]: {
      backgroundColor: theme.palette.common.white,
      minWidth: "50%",
      margin: "auto",
      padding: "0px",
      maxHeight: "70%",
      marginTop: "25rem",
      transform: "translate(0, -50%)",
      // marginLeft: "5rem",
      overflowY: "auto",
      overflowX:"hidden"
    },
    [theme.breakpoints.up("sm")]: {
      backgroundColor: theme.palette.common.white,
      height: "auto",
      minWidth: "50%",
      margin: "auto",
      padding: "0px",
      maxHeight: "100%",
      position: "fixed",
      top: "50%",
      transform: "translate(0, -50%)",
      width: "50%",
      overflowY: "auto",
      overflowX:"hidden"
    },
    [theme.breakpoints.up("md")]: {
      backgroundColor: theme.palette.common.white,
      height: "auto",
      minWidth: "50%",
      margin: "auto",
      padding: "0px",
      maxHeight: "100%",
      position: "fixed",
      top: "50%",
      transform: "translate(0, -50%)",
      width: "50%",
      overflowY: "auto",
      overflowX:"hidden"
    },
    [theme.breakpoints.up("lg")]: {
      backgroundColor: theme.palette.common.white,
      height: "auto",
      minWidth: "50%",
      margin: "auto",
      padding: "0px",
      maxHeight: "100%",
      position: "fixed",
      top: "50%",
      transform: "translate(0, -50%)",
      width: "50%",
      overflowY: "auto",
      overflowX:"hidden"
    },
  },
  title: {
    margin: 10,
    flexGrow: 1,
    fontWeight: "bold",
    color:"white",
    fontSize:"large"
  },
  form: {
    padding: "0px 36px",
    width: "100%",
  },
  buttonHolder: {
    padding: theme.spacing(3),
  },
}));

export default function EMAddEditAdmin(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  let incorrectInput = "Only alphabets are allowed";
  const validationSchema = yup.object({
    first_name: yup
      .string("Enter first name")
      .trim()
      .max(150, "Maximum 150 characters are allowed")
      
      .required("First name is required"),
    last_name: yup
      .string("Enter last name")
      .max(150, "Maximum 150 characters are allowed")
      .trim()
      .required("Last name is required")
      ,
    email: yup.array()
      .of(yup.string().email(({ value }) => `${value} is not a valid email`))
      .of(yup.string().max(150, "Maximum 150 characters are allowed"))
      .min(1, "Email Address field must have atleast one email address")
      .required("Email is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: props.userData["first_name"] || "",
      last_name: props.userData["last_name"] || "",
      email: props.userData["email"] ? [props.userData["email"]] : [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      props.setShouldButtonDisabled(true);
      if (props.userData && props.userData.id > 0) {
        let newUserData = {
          ...props.userData,
          ...values,
          email: values.email[0].toLowerCase(),
        };
        dispatch(
          EMDoUpdatePlatformAdminAction({
            ...newUserData,
            key: "user_add_edit",
          })
        );
      } else {
        let newUserData = { ...values, email: values.email[0].toLowerCase() };
        dispatch(
          EMDoCreatePlatformAdminAction({
            ...newUserData,
            key: "user_add_edit",
          })
        );
      }
    },
  });

  //reset the form after modal is closed
  useEffect(() => {
    return () => {
      formik.resetForm();
    };
  }, [props.open]);


  return (
    <Modal
      open={props.open}
      onClose={props.close}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box className={classes.root} style={{ outline: "none" }}>
        <form onSubmit={formik.handleSubmit}>
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            style={{    padding: "0px 4px 0px 18px",
            backgroundColor:"#2F92D6"}}
            
          >
            <Typography className={classes.title}>{props.pagelabel}</Typography>
            <IconButton onClick={props.close}>
              <CloseRounded style={{color:"white"}} />
            </IconButton>
          </Box>
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="row"
            spacing={2}
            className={classes.form}
            style={{paddingTop:"16px"}}
          >
            <Grid item xs={12} sm={12} md={6}>
              <EMSimpleTextFieldView
                label={"First Name"}
                id="first_name"
                name="first_name"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                // error={
                //   formik.touched.first_name && Boolean(formik.errors.first_name)
                // }
                helperText={
                  formik.touched.first_name && formik.errors.first_name
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <EMSimpleTextFieldView
                label={"Last Name"}
                id="last_name"
                name="last_name"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                // error={
                //   formik.touched.last_name && Boolean(formik.errors.last_name)
                // }
                helperText={formik.touched.last_name && formik.errors.last_name}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="row"
            spacing={2}
            className={classes.form}
          >
            <Grid item xs>
              <ChipInput
                    InputLabelProps={{style:{color:"#4d4d4d"}}}
                    FormHelperTextProps={{style:{color:"red"}}}
                name="email"
                type="email"
                label="Email Address"
                disabled={props.userData["email"]}
                helperText={
                  formik.errors.email && formik.touched.email
                    ? formik.errors.email
                    : ""
                }
                value={formik.values.email}
                //InputProps={{ style: {fontFamily: "Arial" } }}
                //FilledInputProps={{ style: {fontFamily: "Arial" } }}
                // error={formik.touched.email &&
                //   Boolean(formik.errors.email) 
                // }
                margin="none"
                onAdd={(chip) => {
                  if (chip) {
                    formik.setFieldValue("email", [chip.toLowerCase()]);
                  }
                }}
                onDelete={() => {
                  formik.setFieldValue("email", []);
                }}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="row"
            className={classes.buttonHolder}
            spacing={4}
          >
            <Grid item>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                type="submit"
                // disabled={props.shouldButtonDisabled}
              >
                <Typography variant="h7">{props.buttonLabel}</Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.button}
                variant="outlined"
                color="secondary"
                onClick={props.close}
              >
                <Typography variant="h7">Discard</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
}
