import {
    SHOW_MESSAGE
  } from "../../../constants";
import {
    LIST_USER_PINNED_POSTS,
    LIST_USER_PINNED_POSTS_CATEGORY_SELECTED,
    LIKE_DISLIKE_PIN_UNPIN_PINNED_POSTS,
    LIKE_DISLIKE_PINNED_POSTS,
    CHANGE_LIKE_BUTTON_STATE_OF_PINNED_POST
} from "../../../constants/UserPlatform/index"
import API from "../../../../api";
import { handleError } from "../../../../utils/EMCommonFunctions";
//action for listing pinned posts
export const EMDoGetPinnedPostsListAction = action => {
    return dispatch => {
        API.EMDoGetPinnedPostsList(action.nextPost)
            .then(data => data.data)
            .then(response => {
                if (response) {
                    dispatch({
                        type: LIST_USER_PINNED_POSTS,
                        payload: {response :response ,userData :action.userData, page:action.nextPost}
                    });
                } else {
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: {
                            type: "error",
                            message: "Error occured while fetching channels list"
                        }
                    });
                }
            })
            .catch(err => {
                handleError(dispatch, err)
            });
    };
};

export const EMDoGetCategoryFilterPinnedPostListAction = action => {
    return dispatch => {
        API.EMDoGetCategoryFilteredPinnedPostList(action.selectedDataOption, action.page)
            .then(data => data.data)
            .then(response => {
                if (response) {
                    dispatch({
                        type: LIST_USER_PINNED_POSTS,
                        payload: {response :response ,userData :action.userData, page:action.page}
                    });
                } else {
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: {
                            type: "error",
                            message: "Error occured while fetching post"
                        }
                    });
                }
            })
            .catch(err => {
                handleError(dispatch, err)
            });
    };
};

//action for Post pin unpin like dislike
export const EMDoLikeDislikePinUnpinPinnedPostAction = action => {
    return dispatch => {
        
        API.EMDoLikeDislikePinUnpinPost(action)
            .then(data => data.data)
            .then(response => {
                if (response) {
                    dispatch({
                        type: LIKE_DISLIKE_PIN_UNPIN_PINNED_POSTS,
                        payload: response
                    });
                } else {
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: {
                            type: "error",
                            message: "Action Failed, Try Again" 
                        }
                    });
                }
            })
            .catch(err => {
                handleError(dispatch, err)
            });
    };
};

export const EMDoLikeDislikePinnedPostAction = action => {
    return dispatch => {
        
        API.EMDoLikeDislikePinUnpinPost(action)
            .then(data => data.data)
            .then(response => {
                if (response) {
                    dispatch({
                        type: LIKE_DISLIKE_PINNED_POSTS,
                        payload: response
                    });
                    dispatch({
                        type:CHANGE_LIKE_BUTTON_STATE_OF_PINNED_POST,
                        payload:{
                          id:action.queryData.id,
                          isDisabled:false
                        }
                      })
                } else {
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: {
                            type: "error",
                            message: "Action Failed, Try Again" 
                        }
                    });
                }
            })
            .catch(err => {
                handleError(dispatch, err)
            });
    };
};