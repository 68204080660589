import axios from "axios";

/* User Management Functionality Api */
const EMDoGetGroupAdminGroupListDropDown = () => {
    let url = `/group/group_admin/user/group_list/`;
    return axios.get(url);
};

const EMDoGetGroupAdminGroupListDropDownForChannel = () => {
  let url = `/group/list_groups_for_dropdown/`;
  return axios.get(url);
};

const EMDOGetGroupAdminGroupUsersList = queryData => {
  let url = "";
  if (queryData) {
    url = `/group/group_user/${queryData}`;
  } 
  return axios.get(url);
};

const EMDoGetGroupUserRolesList = (id) => {
    let url = `/group/roles/?group=${id}`;
    return axios.get(url);
  };

const EMDoUpdateGroudUserDetails = ({ data,id }) => {
  let url = `group/group_user/${id}/`;
  return axios.put(url, { ...data })
}  

const EMDoExportGroupAdminUsers = ({contextData}) => {
  
  let url = `/group/group_user/export/?context=${contextData.context}`;
  return axios.get(url);
};

/* Network Management Functionality Api */

/* get Group detail by id */

const EMDoGetGroupDetailsById = (id) => {
    let url = `group/group_profile_update/${id}`;
    return axios.get(url);
};
// remove group member

const EMAdminRemoveGroupUser= (data) => {
  
  let url = `group/${data.groupId}/remove-user-from-group/`;
  return axios.delete(url, {data:{
    user_id: data.user_id
  }});
  
};

//download attachments for groups
const EMDoDownloadAttachmentsForGroup = (groupId) => {
  let url = `group/${groupId}/download-group-attachments/`
  return axios.get(url,  
    {
    responseType: 'arraybuffer'
}
)
}

/* Put - update Group Details by id */
const EMDoUpdateGroupDetailsById = ({ jsonData }) => {
    let url = `group/group_profile_update/${jsonData.id}`;
    
    const fileData = new FormData()
    if (jsonData.files.hasOwnProperty("icon")) { fileData.append("logo_file", jsonData.files.icon) }
    if (jsonData.files.hasOwnProperty("banner")) { fileData.append("banner_file", jsonData.files.banner) }
    delete (jsonData.files)
    fileData.append("id", jsonData.id)
    fileData.append("group_name", jsonData.group_name)
    fileData.append("tagline", jsonData.tagline)
    fileData.append("vision_stmt", jsonData.vision_stmt)
    fileData.append("mission_stmt", jsonData.mission_stmt)
    fileData.append("additional_info", jsonData.additional_info)
    fileData.append("privacy_type", jsonData.privacy_type)
    fileData.append("is_active", jsonData.is_active)
    fileData.append("deleted_attachments", jsonData.deleted_attachments)
    jsonData.new_attachments.map(img => {
      fileData.append("new_attachments", img)
    })

    return axios.put(url, fileData, {
        body: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

/* Put - update Group Status */
const EMDoUpdateGroudStatusById = ({ data,id }) => {
    let url = `group/group_status/${id}`;
    const fileData = new FormData()
  fileData.append("is_active", data.is_active)

    return axios.put(url, fileData, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

/* Put - update Group Details */
const EMDoUpdateEditedGroupDetailsById =({data})=>{
    let url = `/group/group_settings_update/${data.id}`;
    return axios.put(url, data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

/* Get - GroupTable Listing Api  */
const EMDoGetAllGroupListDetails = (queryData) => {
  
    let url = "";
  if (queryData) {
    url = `group/list_all_groups/${queryData}`;
  } else {
    url = `group/list_all_groups/`;
  }
    return axios.get(url);
};

/* Get - ChannelTable Listing Api  */
const EMDoGetAllChannelListDetails = (queryData) => {
  let url = "";
if (queryData) {
  url = `group/list_all_channels/${queryData}`;
} else {
  url = `group/list_all_channels/`;
}
  return axios.get(url);
};

// POST - Add new Channel
const EMDoCreateChannelInGroupAdmin = userData => {
  let url = `group/add_new_channel/`;
  return axios.post(url, { ...userData });
};

//Put - Edit channel details
const EMDoUpdateChannelInGroupAdmin =({data})=>{
  
  let url = `/group/update_channel_settings/${data.id}`;
  return axios.put(url, data, {
      headers: {
          'Content-Type': 'application/json'
      }
  })
}

/* get Channel detail by id */

const EMDoGetChannelDetailsById = (id) => {
  let url = `group/channel_details_update/${id}`;
  return axios.get(url);
};

/* Put - update Channel Details by id */
const EMDoUpdateChannelDetailsById = ({ jsonData }) => {
  let url = `group/channel_details_update/${jsonData.id}`;
  const fileData = new FormData()
  if (jsonData.files.hasOwnProperty("icon")) { fileData.append("logo_file", jsonData.files.icon) }
  if (jsonData.files.hasOwnProperty("banner")) { fileData.append("banner_file", jsonData.files.banner) }
  delete (jsonData.files)
  fileData.append("id", jsonData.id)
  fileData.append("channel_name", jsonData.channel_name)
  fileData.append("tagline", jsonData.tagline)
  fileData.append("vision_stmt", jsonData.vision_stmt)
  fileData.append("mission_stmt", jsonData.mission_stmt)
  fileData.append("additional_info", jsonData.additional_info)
  fileData.append("privacy_type", jsonData.privacy_type)
  fileData.append("is_active", jsonData.is_active)
  fileData.append("deleted_attachments", jsonData.deleted_attachments)
  jsonData.new_attachments.map(img => {
    fileData.append("new_attachments", img)
  })

  return axios.put(url, fileData, {
      body: {
          'Content-Type': 'multipart/form-data'
      }
  })
}

const EMDoGetGroupAdminImportUsersList = queryData =>{
  
  let url;
  if (queryData) {
    url = `/group/import_users/${queryData}`;
  }
  if(queryData){
    return axios.get(url);
  }  
}

const EMDoImportGroupAdminSelectedUsersList = ({ data, contextData }) => {
  
  let url = `/group/import_users/?group=${contextData.groupId}`;
  return axios.post(url, { ...data });
};

const EMDoGetResendInviteUsersGroupAdminList = queryData =>{
  let url ="";
  if (queryData) {
    url = `/invitations/resend-invitation/${queryData}`;
  } else {
    url = `/invitations/resend-invitation/`
  }
  return axios.get(url);
}

const EMDoGroupAdminResendInviteToUsers = ({ data, contextData }) => {
  let url ="";
  if (contextData) {
    url = `/invitations/resend-invitation/?group=${contextData.groupId}&email_template=${contextData.emailTemplate}`;
  } else {
    url = `/invitations/resend-invitation/`
  }

  return axios.post(url, { ...data });
};

const EMDOGetGroupAdminEmailTemplateTypeList = (groupId) => {
  let url ="";
  if (groupId) {
    url = `/commans/listemailtemplates/?group=${groupId}`;
  } else {
    url = `/commans/listemailtemplates/`
  }
  return axios.get(url);
  };
/* Manage Roles And Permissions */
/* Get - List Roles */
const EMDoListRolesForSelectedGroup = ({data,query})=>{
  let url 
  
  if(data != undefined){
    if(query){

      url = `${data.entity}/${data.id}/roles${query}`
    }else{
      url = `${data.entity}/${data.id}/roles`
    }
  }
  return axios.get(url)
}
const EMDoCreateNewRoleForGroup =({ roleData, contextData }) =>{
  let url=`${contextData.entity}/${contextData.id}/roles`
  return axios.post(url, { ...roleData });
}
const EMDoUpdateRoleForGroup =({ roleData, contextData }) =>{
  let url=`${contextData.entity}/${contextData.id}/roles/${contextData.roleId}`
  return axios.put(url, { ...roleData });
}
const EMDoRetrievePermissionsForGroup = ({contextData}) =>{
  let url= `${contextData.entity}/${contextData.id}/roles/${contextData.roleId}`
  return axios.get(url)
}
const EMDoUpdateStatusOfRole = ({contextData})=>{
  let url = `${contextData.entity}/${contextData.entityId}/roles/${contextData.roleId}/action:${contextData.action}/`
  return axios.patch(url)
}
// API For Dashboard
const EMDoGetGroupDashboardInfo=(requiredData)=>{
let url=`user/group-admin-group-dashboard/?start_date=${requiredData.start_date}&end_date=${requiredData.end_date}&group=${requiredData.groupId}`
return axios.get(url)
}
const EMDoGetEntityListForGroup = () => {
  let url = `/user/group-admin-entity-list/`;
  return axios.get(url);
};

const EMDoGetGroupBasedOnOrgOrPartnership = (requiredData) => {
  
  let url
  if(requiredData.isPartnership === true){
    url = `/user/partnership/${requiredData.id}/list-user-groups/?limit=100`
  } else{
    url = `/user/organization/${requiredData.id}/list-user-groups/?limit=100`
  }
  return axios.get(url);
}
// reports
const EMDoGetUserEngagementReportForGroup=(requiredData, paginationData)=>{

  let url
  if(requiredData.groupOrChannel == 1){
    if(requiredData && requiredData.isPartnership){
      url = `/user/group-admin-user-engagement-report/group/?${paginationData}&start_date=${requiredData.start_date}&end_date=${requiredData.end_date}&partnership=${requiredData.organizationOrPartnershipId}`
     }else {
       url = `/user/group-admin-user-engagement-report/group/?${paginationData}&start_date=${requiredData.start_date}&end_date=${requiredData.end_date}&organization=${requiredData.organizationOrPartnershipId}`
     }
  }else {
    if(requiredData && requiredData.isPartnership){
      url = `/user/group-admin-user-engagement-report/channel/?${paginationData}&start_date=${requiredData.start_date}&end_date=${requiredData.end_date}&partnership=${requiredData.organizationOrPartnershipId}`
     }else {
       url = `/user/group-admin-user-engagement-report/channel/?${paginationData}&start_date=${requiredData.start_date}&end_date=${requiredData.end_date}&organization=${requiredData.organizationOrPartnershipId}`
     }
  }
  return axios.get(url);
}

// export reports
const EMDoExportUserEngagementReportForGroup=(requiredData,groupOrChannel)=>{

  let url
  if(groupOrChannel == 1){
    url = `/user/group-admin-user-engagement-report/group/`
  }else{
    url = `/user/group-admin-user-engagement-report/channel/`
  }
  return axios.post(url, requiredData);
};

//get list of groups for dropdown

const EMDoGetListOfGroupsForDropdownInUserManagement = () => {
  let url = `/auth/get-entity-for-dropdown/group/none/none/`
  return axios.get(url)
}

//get permission for user, specific to group
const EMDoGetPermissionSpecificToGroup = (id) => {
  let url;
  if(id){
    url = `auth/get-roles/group/${id}/`
    return axios.get(url)
  }
}

export default {
    EMDoGetGroupDetailsById,
    EMDoUpdateGroupDetailsById,
    EMDoUpdateGroudStatusById,
    EMDoGetGroupAdminGroupListDropDown,
    EMDoGetGroupAdminGroupListDropDownForChannel,
    EMDOGetGroupAdminGroupUsersList,
    EMDoUpdateEditedGroupDetailsById,
    EMDoGetAllGroupListDetails,
    EMDoGetGroupUserRolesList,
    EMDoUpdateGroudUserDetails,
    EMDoExportGroupAdminUsers,
    EMDoGetAllChannelListDetails,
    EMDoCreateChannelInGroupAdmin,
    EMDoUpdateChannelInGroupAdmin,
    EMDoGetChannelDetailsById,
    EMDoUpdateChannelDetailsById,
    EMDoGetGroupAdminImportUsersList,
    EMDoImportGroupAdminSelectedUsersList,
    EMDoGroupAdminResendInviteToUsers,
    EMDoGetResendInviteUsersGroupAdminList,
    EMDOGetGroupAdminEmailTemplateTypeList,
    /* Manage Roles and Permissions */
    EMDoListRolesForSelectedGroup,
    EMDoCreateNewRoleForGroup,
    EMDoUpdateRoleForGroup,
    EMDoRetrievePermissionsForGroup,
    EMDoUpdateStatusOfRole,
    EMAdminRemoveGroupUser,
    EMDoGetGroupDashboardInfo,
    EMDoGetEntityListForGroup,
    EMDoGetGroupBasedOnOrgOrPartnership,
    EMDoGetUserEngagementReportForGroup,
    EMDoExportUserEngagementReportForGroup,
    EMDoDownloadAttachmentsForGroup,
    EMDoGetListOfGroupsForDropdownInUserManagement,
    EMDoGetPermissionSpecificToGroup
}
