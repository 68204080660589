import { Box, IconButton, TableCell, Typography,Tooltip } from "@material-ui/core";
import EMSimpleDatatable from "../../../../components/datatables/EMSimpleDatatable";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Edit } from "@material-ui/icons";
import {
  EMDOGetChannelAdminChannelUsersListAction,
  EMDoUpdateChannelUserDetailsAction,EMAdminRemoveChannelUserAction
} from '../../../../redux/actions/EMChannelAdminActions'
import EMSwitch from "../../../../components/commans/EMSwitch";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { isEmpty,findLast } from "lodash";
import moment from 'moment';
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({
  typo: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 1,
    wordBreak: "break-word",
    overflow: "hidden",
  },
}));

export default function EMChannelManageUsers({ filter, onEdit, channelId,permissionData }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(15);
  const [offset, setOffset] = useState(0);
  const [order, setOrder] = useState({ id: -1, direction: "asc" });
  const [sortAscending, setSortAscending] = useState(false);
  const userData = useSelector((state) => state.auth);
  let loginUser = userData.email;

  const data = useSelector(
    state => state.channelAdmin["channelUsersList"] || []
  );
  const shouldUpdate = useSelector(
    state => state.channelAdmin["updateUsersList"] || false
  );
  const classes = useStyles()

  useEffect(() => {
    if (filter && filter.length > 0) {
      setOffset(0);
    } else if (filter && filter == "") {
      setOffset(offset);
    }
  }, [filter]);

  const columns = [
    {
      title: "First Name ",
      field: "user__first_name",
      align:"left",
      render: data=> {
        return (
          <>
          <Tooltip title={<h2 style={{ color: "white" }}>{data["first_name"]}</h2>}  placement='top'>
          <Typography className={classes.typo}>{ data["first_name"] && data["first_name"] }</Typography>
          </Tooltip>
          </>
        )
      }
    },

    {
      title: "Last Name ",
      field: "user__last_name",
      align:"left",
      render: data=> {
        return (
          <>
          <Tooltip title={<h2 style={{ color: "white" }}>{data["last_name"]}</h2>} placement='top'>
          <Typography className={classes.typo}>{ data["last_name"] && data["last_name"] }</Typography>
          </Tooltip>
          </>
        )
      }
    },
    {
      title: "Organization Name",
      field: "organization",
      align:"left",
      render: data=> {
        return (
          <>
          <Tooltip title={<h2 style={{ color: "white" }}>{data["organization"]}</h2>} placement='top'>
          <Typography className={classes.typo}>{ data["organization"] && data["organization"] }</Typography>
          </Tooltip>
          </>
        )
      }
    },

    {
      title: "E-mail Address ",
      field: "user__email",
      align:"left",
      render: data=> {
        return (
          <>
          <Tooltip title={<h2 style={{ color: "white" }}>{data["email"]}</h2>} placement='top'>
          <Typography className={classes.typo}>{ data["email"] && data["email"] }</Typography>
          </Tooltip>
          </>
        )
      }
    },
    // {
    //   title: "Status",
    //   align:"left",
    //   field: "user__is_active",
    //   render: (newData) => {
    //     return (
    //       <EMSwitch
    //         status={newData.is_active}
    //         isDisabled={newData.email == loginUser || !(permissionData && permissionData["edit_flag"])}
    //         // isDisabled={true}
    //         dialogText= {"Are you sure that you want to activate/deactivate the user?"}
    //         onChange={status => {
              
    //           let data = { ...newData };
    //           let id = data.id;
    //           data.is_active = status;
    //           delete data.tableData;
    //           dispatch(EMDoUpdateChannelUserDetailsAction({data,id}));
    //         }}
    //       />
    //     );
    //   },
    // },
    {
      title: "Role",
      field: "role_name",
      align:"left",
      sorting: false,
      render: data=> {
        return (
          <>
          <Tooltip title={<h2 style={{ color: "white" }}>{data["role_name"]}</h2>} placement='top'>
          <Typography className={classes.typo}>{ data["role_name"] && data["role_name"] }</Typography>
          </Tooltip>
          </>
        )
      }
    },
    {
      title: "Z-Points",
      field: "points",
      align:"left",
      sorting: false,
      render: data=> {
        return (
          <>
          <Tooltip title={<h2 style={{ color: "white" }}>{data["points"]}</h2>}  placement='top'>
          <Typography className={classes.typo}>{ data["points"] && data["points"] }</Typography>
          </Tooltip>
          </>
        )
      }
    },
    {
      title: "SignUp Date",
      field: "signup_date",
      align:"left",
      sorting: true,
      render: data=> {
        let date = data["signup_date"];
        date = new Date(date)
        date =  moment(date).format('MM/DD/YYYY');
        return(
          <Typography>{data["signup_date"]? date : ""}</Typography>
        )
      }
    },
    {
      title: "Last Active",
      field: "last_active",
      align:"left",
      sorting: true,
      render: data=> {
        let date = data["last_active"];
        date = new Date(date)
        date =  moment(date).format('MM/DD/YYYY');
        return(
          <Typography>{data["last_active"]? date : ""}</Typography>
        )
      }
    },
    {
      title: "DeActivation Date",
      field: "deactivation_date",
      align:"left",
      sorting: true,
      render: data=> {
        let date = data["deactivation_date"];
        date = new Date(date)
        date =  moment(date).format('MM/DD/YYYY');
        return(
          <Typography>{data["deactivation_date"]? date : ""}</Typography>
        )
      }
    },
    {
      title: "Action",
      align:"left",
      sorting: false,
      render: data => {
        return (
          <>
          <IconButton onClick={() => onEdit(data)} disabled={!(permissionData && permissionData["edit_flag"])}>
            <Edit />
          </IconButton>
          
                  {data.role_name !== "Admin" ?
                  <IconButton disabled={!(permissionData && permissionData["edit_flag"])}>
                  <DeleteIcon 
                  onClick={() => {
                  dispatch(
                    EMAdminRemoveChannelUserAction({data:{
                    channelId:data.channel,
                    user_id:data.id
                }})
                );
          
            }}
           />
           </IconButton>
           : null}  
          </>
        );
      }
    }
   
  ];

  const updateData = query => {
    if(query.indexOf("limit")>=0 &&query.indexOf("channel")>=0){dispatch(EMDOGetChannelAdminChannelUsersListAction(query));}
    
  };

  useEffect(() => {
    updateData("?");
  }, []);
  useEffect(() => {
    
    const query = [];
    if (limit !== 0) {
      query.push(`limit=${limit}`);
    }
    if (filter) {
      query.push(`search=${filter}`);
    }
    if (offset !== 0) {
      query.push(`offset=${offset}`);
    }
    if (order.id !== -1) {
      if(sortAscending === false){
        // order.direction = "asc"
        query.push(
          `ordering=${order.direction === "desc" ? "-" : ""}${
            columns[order.id-1].field
          }`
        );
        setSortAscending(true)
      }else{
        // order.direction = "desc"
        query.push(
          `ordering=${order.direction === "desc" ? "-" : ""}${
            columns[order.id-1].field
          }`
        );
        setSortAscending(false)
      }
    }
    
    if (channelId) {
      query.push(
        `channel=${channelId}`
      );
    }
    updateData("?" + query.join("&"));
  }, [limit, filter, offset, order, channelId, shouldUpdate]);

  
  return (
    <Box p={2}>

      <EMSimpleDatatable
      filter={filter}
        columns={columns}
        data={data["results"] || []}
        count={data["count"] || 0}
        updatePageAndPageSizeAndOrder={(page, pageSize, order) => {
            setOffset(page * limit);
            setLimit(pageSize);
            setOrder(order)
          }}
        options={{
          headerStyle: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.common.white,
            fontWeight: "bold",
          },
        }}
      />

    </Box> 
  );
}
