import {
  GROUP_ADMIN_GROUP_LIST_DROPDOWN_SUCCESS,
  GROUP_ADMIN_GROUP__USERS_LIST_DROPDOWN,
  SHOW_MESSAGE,
  GROUP_ADMIN_DETAILS_BY_ID,
  GROUP_ADMIN_UPDATE_GROUP_STATUS,
  GROUP_ADMIN_GROUP_LIST_SUCCESS,
  GROUP_ADMIN_GROUP_UPDATE_SUCCESS,
  GROUP_ADMIN_GROUP_USER_ROLE_DROPDOWN_SUCCESS,
  GROUP_ADMIN_USER_DETAILS_UPDATE_SUCCESS,
  GROUP_ADMIN_USERS_EXPORT_SUCCESS,
  GROUP_ADMIN_CHANNEL_LIST_SUCCESS,
  GROUP_ADMIN_CHANNEL_CREATE_SUCCESS,
  GROUP_ADMIN_CHANNEL_UPDATE_SUCCESS,
  UI_LOADING,
  GROUP_ADMIN_GROUP_LIST_DROPDOWN_FOR_CHANNEL_SUCCESS,
  GROUP_ADMIN_CHANNEL_DETAILS_BY_ID,
  GROUP_ADMIN_GROUP_SETTINGS_UPDATE_SUCCESS,
  GROUP_ADMIN_CHANNEL_SETTINGS_UPDATE_SUCCESS,
  GROUP_ADMIN_IMPORT_USERS_LIST_SUCCESS,
  GROUP_ADMIN_IMPORT_USERS_SUCCESS,
  GROUP_ADMIN_RESEND_USERS_LIST_SUCCESS,
  GROUP_ADMIN_RESEND_INVITE_SUCCESS,
  GROUP_ADMIN_EMAIL_TEMPLATE_TYPE_LIST_SUCCESS,
  GROUP_ADMIN_LIST_ROLES_SUCCESS,
  GROUP_ADMIN_ROLE_CREATE_SUCCESS,
  ENTITY_ROLE_RETRIEVE_SUCCESS,
  GROUP_ADMIN_GROUP_SETTINGS_UPDATE_SUCCESS_FOR_USER_PLATFORM,
  GROUP_ADMIN_MEMBER_LEAVE_GROUP,
  GROUP_ADMIN_REMOVE_USER,
  GROUP_ADMIN_GET_DASHBOARD_DATA,
  GROUP_ADMIN_ENTITY_LIST,
  GROUP_ADMIN_GET_GROUP_LIST,
  GROUP_ADMIN_GET_USER_ENGAGEMENT_DATA,
  GROUP_ADMIN_GET_DATA_FOR_DROPDOWN_IN_GAMIFICATION,
  GROUP_ADMIN_GET_LEVEL_REPORT,
  GROUP_ADMIN_GET_BADGE_REPORT,
  GROUP_ADMIN_GET_LIST_OF_GROUPS_IN_USER_MANAGEMENT,
  GROUP_ADMIN_GET_PERMISSION_BASED_ON_GROUP,
} from "../constants/index"
import API from "../../api"
import { handleError } from "../../utils/EMCommonFunctions";

/* Action for select group dropdown */

export const EMDoGetGroupAdminGroupListDropDownAction = action => {
  return dispatch => {
    API.EMDoGetGroupAdminGroupListDropDown(action)
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: GROUP_ADMIN_GROUP_LIST_DROPDOWN_SUCCESS,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching group list"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetGroupAdminGroupListDropDownForChannelAction = action => {
  return dispatch => {
    API.EMDoGetGroupAdminGroupListDropDownForChannel(action)
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: GROUP_ADMIN_GROUP_LIST_DROPDOWN_FOR_CHANNEL_SUCCESS,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching group list"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetGroupUserRolesListAction = action => {
  return dispatch => {
    API.EMDoGetGroupUserRolesList(action)
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: GROUP_ADMIN_GROUP_USER_ROLE_DROPDOWN_SUCCESS,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching role list"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoUpdateGroudUserDetailsAction = action => {
  return dispatch => {
    
    dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
    API.EMDoUpdateGroudUserDetails(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: GROUP_ADMIN_USER_DETAILS_UPDATE_SUCCESS,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "User details updated successfully",
              key: action.key
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to update the user details"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        handleError(dispatch, err)
      });
  };
};

export const EMDOGetGroupAdminGroupUsersListAction = action => {
  
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
    API.EMDOGetGroupAdminGroupUsersList(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: GROUP_ADMIN_GROUP__USERS_LIST_DROPDOWN,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching group users list"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        handleError(dispatch, err)
      });
  };
};

//Export Users Action

export const EMDoExportGroupAdminUsersAction = action => {
  
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
    let userData = { ...action };
    delete userData.key;
    API.EMDoExportGroupAdminUsers(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'ExportedUserDetails.csv');
          document.body.appendChild(link);
          link.click();
          link.remove();
          dispatch({
            type: GROUP_ADMIN_USERS_EXPORT_SUCCESS,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Users exported successfully",
              key: action.key
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to export users"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        handleError(dispatch, err)
      });
  };
};


/* Action for select group dropdown */
/*  ----------------- Group Management ------------------*/

/* Action for Get Group Details By Id */
export const EMDoGetGroupDetailsByIdAction = action => {
  
  let key = action.key
  delete (action.key)
  
  return dispatch => {
    API.EMDoGetGroupDetailsById(action.id)
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: GROUP_ADMIN_DETAILS_BY_ID,
            payload: data
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });
          if(action.usedIn && action.usedIn !== "Activity Feed"){
            dispatch({
              type: SHOW_MESSAGE,
              payload: {
                type: "success",
                message: "Group Data is ready to update",
                key: key
              }
            });
          }
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching entity list",
              //key: key
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

//  action for removing group member
export const EMAdminRemoveGroupUserAction = action => {
  return dispatch => {
    API.EMAdminRemoveGroupUser(action.data)
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "User is Removed",
            }
          });
        
         dispatch({
          type: GROUP_ADMIN_REMOVE_USER,
          payload:action.data
          
         })
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching entity list",
              //key: key
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};
/* Action for Put - Update Group Details By Id */

export const EMDoUpdateGroupDetailsByIdAction = action => {
  return dispatch => {
    
    dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
    API.EMDoUpdateGroupDetailsById(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          if(action.route) {
            dispatch({type: GROUP_ADMIN_GROUP_SETTINGS_UPDATE_SUCCESS_FOR_USER_PLATFORM,
              payload: response})
          } else {dispatch({
            type: GROUP_ADMIN_GROUP_SETTINGS_UPDATE_SUCCESS,
            payload: response
          }); }
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Group Updated Successfully",
              key: action.key
            }
          });
          //dispatch(EMDoGetAllGroupListDetailsAction());
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to Update Group"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        if (err.response && err.response.data) {
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
          handleError(dispatch, err)
        }
      });
  };
};

/* Action for Put - update Group Status */

export const EMDoUpdateGroudStatusByIdAction = action => {
  
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
    API.EMDoUpdateGroudStatusById(action)
      .then(data => data.data)
      .then(response => {

        if (response) {
          dispatch({
            type: GROUP_ADMIN_UPDATE_GROUP_STATUS,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Group Updated Successfully",
              key: action.key
            }
          });
          //dispatch(EMDoGetAllGroupListDetailsAction());
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to Update Group"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        if (err.response.data) {
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
          handleError(dispatch, err)
        }
      });
  };
};

/* Action for Get - Group Listing Api */
export const EMDoGetAllGroupListDetailsAction = action => {
  return dispatch => {
    API.EMDoGetAllGroupListDetails(action)
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: GROUP_ADMIN_GROUP_LIST_SUCCESS,
            payload: data
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching entity list",
              key: action.key
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

/* Action For Put : Update Edited Group details by id  */
export const EMDoUpdateEditedGroupDetailsByIdAction = action => {
  
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
    API.EMDoUpdateEditedGroupDetailsById(action)
      .then(data => data.data)
      .then(response => {

        if (response) {
          dispatch({
            type: GROUP_ADMIN_GROUP_UPDATE_SUCCESS,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Group Updated Successfully",
              key: action.key
            }
          });
          // dispatch(EMDoGetAllGroupListDetailsAction());
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to Update Group"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        if (err.response.data) {
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
          handleError(dispatch, err)
        }
      });
  };
};

/* Action for Get - Group Listing Api */
export const EMDoGetAllChannelListDetailsAction = action => {
  return dispatch => {
    API.EMDoGetAllChannelListDetails(action)
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: GROUP_ADMIN_CHANNEL_LIST_SUCCESS,
            payload: data
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching channel list",
              key: action.key
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

/* Action for Post - Channel Creating Api */
export const EMDoCreateChannelInGroupAdminAction = action => {
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
    let userData = { ...action };
    delete userData.key;
    API.EMDoCreateChannelInGroupAdmin(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: GROUP_ADMIN_CHANNEL_CREATE_SUCCESS,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Channel added successfully",
              key: action.key
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to create the user"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        handleError(dispatch, err)
      });
  };
};

//Action for put - Channel Updatong API
export const EMDoUpdateChannelInGroupAdminAction = action => {
  
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
    API.EMDoUpdateChannelInGroupAdmin(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: GROUP_ADMIN_CHANNEL_UPDATE_SUCCESS,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Channel details updated successfully",
              key: action.key
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to update the user details"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        handleError(dispatch, err)
      });
  };
};

/* Action for Get Channel Details By Id */
export const EMDoGetChannelDetailsByIdAction = action => {
  
  let key = action.key
  delete (action.key)
 
  return dispatch => {
    API.EMDoGetChannelDetailsById(action.id)
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: GROUP_ADMIN_CHANNEL_DETAILS_BY_ID,
            payload: data
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Channel Data is ready to update",
              key: key
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching channel data",
              //key: key
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

/* Action for Put - Update Channel Details By Id */

export const EMDoUpdateChannelDetailsByIdAction = action => {
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
    API.EMDoUpdateChannelDetailsById(action)
      .then(data => data.data)
      .then(response => {

        if (response) {
          dispatch({
            type: GROUP_ADMIN_CHANNEL_SETTINGS_UPDATE_SUCCESS,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Channel Updated Successfully",
              key: action.key
            }
          });
          //dispatch(EMDoGetAllChannelListDetailsAction());
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to Update Channel"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        if (err.response.data) {
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
          handleError(dispatch, err)
        }
      });
  };
};

export const EMDoGetGroupAdminImportUsersListAction = action => {
  return dispatch => {
    API.EMDoGetGroupAdminImportUsersList(action)
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: GROUP_ADMIN_IMPORT_USERS_LIST_SUCCESS,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching import list"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoImportGroupAdminSelectedUsersList = action => {
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
    let userData = { ...action };
    API.EMDoImportGroupAdminSelectedUsersList(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: GROUP_ADMIN_IMPORT_USERS_SUCCESS,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Invitations sent successfully",
              key: action.key
            }
          });

        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to send invite"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetResendInviteUsersGroupAdminListAction = action => {
  return dispatch => {
    API.EMDoGetResendInviteUsersGroupAdminList(action)
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: GROUP_ADMIN_RESEND_USERS_LIST_SUCCESS,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching users list"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoResendInviteToGroupAdminUsersAction = action => {
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
    let userData = { ...action };
    delete userData.key;
    API.EMDoGroupAdminResendInviteToUsers(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: GROUP_ADMIN_RESEND_INVITE_SUCCESS,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Invitations sent successfully",
              key: action.key
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to resend invite"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        handleError(dispatch, err)
      });
  };
};

export const EMDOGetGroupAdminEmailTemplateTypeListAction = action => {
  return dispatch => {
    API.EMDOGetGroupAdminEmailTemplateTypeList(action)
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: GROUP_ADMIN_EMAIL_TEMPLATE_TYPE_LIST_SUCCESS,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching template list"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

/* Manage Roles And Permissions */

//GET - for listing roles in table

export const EMDoListRolesForSelectedGroupAction = action => {
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
    API.EMDoListRolesForSelectedGroup(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: GROUP_ADMIN_LIST_ROLES_SUCCESS,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching role list"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        handleError(dispatch, err)
      });
  };
};

//Post - Create new Role
export const EMDoCreateNewRoleForGroupAction = action => {
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
    let userData = { ...action };
    delete userData.key;
    
    API.EMDoCreateNewRoleForGroup(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: GROUP_ADMIN_ROLE_CREATE_SUCCESS,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Role added successfully",
              key: action.key
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to create the role"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        handleError(dispatch, err)
      });
  };
};

//Put - Update Role
export const EMDoUpdateRoleForGroupAction = action => {
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
    let userData = { ...action };
    delete userData.key;
    
    API.EMDoUpdateRoleForGroup(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          // dispatch({
          //   type: ENTITY_ROLE_CREATE_SUCCESS,
          //   payload: response
          // });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Role updated successfully",
              key: action.key
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to create the role"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        handleError(dispatch, err)
      });
  };
};

//GET - for Role Specific Permissions
export const EMDoRetrievePermissionsForGroupAction = action => {
  
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
    API.EMDoRetrievePermissionsForGroup(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          
          dispatch({
            type: ENTITY_ROLE_RETRIEVE_SUCCESS,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching role details"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        handleError(dispatch, err)
      });
  };
};

//Patch - active inactive 
export const EMDoUpdateStatusOfRoleAction = action => {
  
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state:true, message:"Please wait"} });;
    API.EMDoUpdateStatusOfRole(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Role updated Successfully"
            }
          })
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching role list"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state:false, message:""} });;
        handleError(dispatch, err)
      });
  };
}
// GET DASHBOARD DATA ACTION
export const EMDoGetGroupDashboardInfo = action => {
  
  return dispatch => {
    API.EMDoGetGroupDashboardInfo(action.requiredData)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: GROUP_ADMIN_GET_DASHBOARD_DATA,
            payload: response
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching dashboard data"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

// DASHBOARD ENTITY DATA
export const EMDoGetEntityListForGroupAction = action => {
  return dispatch => {
    API.EMDoGetEntityListForGroup(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: GROUP_ADMIN_ENTITY_LIST,
            payload: response
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching dashboard data"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

//Get Group List For Dashboard
export const EMDoGetGroupBasedOnOrgOrPartnershipAction = action => {
  return dispatch => {
    API.EMDoGetGroupBasedOnOrgOrPartnership(action.requiredData)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: GROUP_ADMIN_GET_GROUP_LIST,
            payload: response
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching group list"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

// Reports user engagement
export const EMDoGetUserEngagementReportForGroupAction = action => {
  return dispatch => {
    API.EMDoGetUserEngagementReportForGroup(action.requiredData, action.paginationData)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: GROUP_ADMIN_GET_USER_ENGAGEMENT_DATA,
            payload: response
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching dashboard data"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};
// Export report
export const EMDoExportUserEngagementReportForGroupAction = action => {
  return dispatch => {
    API.EMDoExportUserEngagementReportForGroup(action.requiredData, action.groupOrChannel)
      .then(data => data.data)
      .then(response => {
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'UserEngagementReport.csv');
          document.body.appendChild(link);
          link.click();
          link.remove();
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "User engagement report exported successfully",
              key: action.key
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to export User engagement report"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err) 
      });
  };
};

//download attachments for groups
export const EMDoDownloadAttachmentsForGroupAction = action => {
  return dispatch => {
    API.EMDoDownloadAttachmentsForGroup(action.groupId)
    .then(data => data.data)
      .then(response => {
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Group Attachments.zip');
          document.body.appendChild(link);
          link.click();
          link.remove();
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Group attachments downloaded successfully",
              key: action.key
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to download attachments"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetDataForDropdownInGamificationForGroupAction = action => {
  return dispatch => {
    API.EMDoGetDataForDropdownInGamification("group")
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: GROUP_ADMIN_GET_DATA_FOR_DROPDOWN_IN_GAMIFICATION,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching network list",
              key: action.key
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetLevelReportForGroupAction = action => {
  return dispatch => {
    API.EMDoGetLevelAndBadgeReport(action.entityType, action.entityId, "level")
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: GROUP_ADMIN_GET_LEVEL_REPORT,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching network list",
              key: action.key
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetBadgeReportForGroupAction = action => {
  return dispatch => {
    API.EMDoGetLevelAndBadgeReport(action.entityType, action.entityId, "badge")
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: GROUP_ADMIN_GET_BADGE_REPORT,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching network list",
              key: action.key
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};


export const EMDoGetListOfGroupsForDropdownInUserManagementAction = action => {
  return dispatch => {
    API.EMDoGetListOfGroupsForDropdownInUserManagement()
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: GROUP_ADMIN_GET_LIST_OF_GROUPS_IN_USER_MANAGEMENT,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching group list",
              key: "get_groups_error"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetPermissionSpecificToGroupAction = action => {
  return dispatch => {
    API.EMDoGetPermissionSpecificToGroup(action.id)
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: GROUP_ADMIN_GET_PERMISSION_BASED_ON_GROUP,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching user's permission",
              key: "get_permissions_error"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};