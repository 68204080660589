import axios from "axios";

//Get Polls
const EMDoGetMyQuiz = (action)=>{
    let url = `posts/my-quizzes/`
    return axios.get(url);
}

const EMDoGetQuizReport = (quizId, queryData) => {
    let url;
    if (queryData) {
        url = `posts/my-quizzes/user-quiz-details/${quizId}/${queryData}`;
      } else {
        url = `posts/my-quizzes/user-quiz-details/${quizId}/`;
      }
    return axios.get(url);
}

//Download Quiz reports
const EMDownloadQuizReports = (quizId)=>{
    let url = `posts/my-quizzes/user-quiz-details/export/${quizId}/`
    return axios.get(url);
}


export default{
    EMDoGetMyQuiz,
    EMDoGetQuizReport,
    EMDownloadQuizReports
}