import React, {useState, useEffect} from "react";
import { Box, FormGroup, FormControlLabel, Switch } from "@material-ui/core";
import EMDialogBox from './EMDialogBox'


export default function EMSwitch({ status, onChange, dialogText,isDisabled}) {
  const [localStatus,setLocalStatus] = useState(false)
  const [open,setOpen] = useState(false)

  const handleChange = (event) => {
    setLocalStatus(event.target.checked)
    setOpen(true)
  };

  useEffect(() => {
    setLocalStatus(status)
  },[status])

  return (
    <Box display="flex" justifyContent="flex-start" alignItems="center">
      <FormControlLabel
        control={
          <Switch
            checked={localStatus}
            disabled = {isDisabled}
            onChange={handleChange}
            name={localStatus ? "Active" : " Inactive"}
            color="primary"
          />
        }
        label={localStatus ? "Active" : " Inactive"}
      />
      <EMDialogBox 
        shouldOpen={open}
        onSelect={(shouldProcess) => {
            if(shouldProcess){
              onChange(localStatus)
            }else{
              setLocalStatus(!localStatus)
            }
            setOpen(false)
        }}
        dialogText={dialogText}/>
    </Box>
  );
}
