import {
  Typography,
  Box,
  Modal,
  makeStyles,
  Button,
  Paper,
  IconButton,
  Grid,
  Link,
  GridList,
  Tooltip,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { CloseRounded } from "@material-ui/icons";
import { EMSimpleTextFieldView } from "../../../../components/EMTextFieldView";
import EMFileUploader from "../../../../components/commans/EMFileUploader";
import EMTextArea from "../../../../components/commans/EMTextArea";
import EMPrivacyDropdown from "../../../NetworkAdmin/UserManagement/commons/EMPrivacyDropdown";
import { useFormik } from "formik";
import { isEmpty, find } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import * as yup from "yup";
import { EMDoUpdateChannelDetailsByIdAction } from "../../../../redux/actions/EMGroupAdminActions";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { findLast } from "lodash";
import EMFileSelect from "../../../../components/commans/EMFileSelect";
import EMImageCropper from "../../../../components/commans/EMImageCropper";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "80%",
    overflowX: "hidden",

    width: "70%",
  },
  rootForGrid: {
    marginTop: "2%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    // padding:"10px",
    width: "100%",
    height: "100%",
    maxHeight: window.screen.height / 2.5,
    "&::-webkit-scrollbar": {
      width: "0.1em",
    },
    // "&::-webkit-scrollbar-track": {
    //   boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    //   webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    // },
    // "&::-webkit-scrollbar-thumb": {
    //   backgroundColor: "#4A4A4A",
    //   outline: "1px solid slategrey",
    // },
  },
  paper: {
    paddingLeft: "3%",
    paddingRight: "3%",
    borderRadius: 0,
  },
  // sideBox: {
  //   overflow: "auto",
  //   width: "99%",
  //   maxHeight: window.screen.height / 1.5,
  //   "&::-webkit-scrollbar": {
  //     width: "0.3em",
  //   },
  //   "&::-webkit-scrollbar-track": {
  //     boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
  //     webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
  //   },
  //   "&::-webkit-scrollbar-thumb": {
  //     backgroundColor: "#4A4A4A",
  //     outline: "1px solid slategrey",
  //   },
  // },
  orgDetails: {
    marginBottom: "10%",
  },
  container: {},
  title: {
    margin: 10,
    flexGrow: 1,
    fontWeight: "bold",
    color: "white",
    fontSize: "large",
  },
  spacing: {
    marginBottom: "5%",
    marginRight: "5%",
  },
  header: {
    fontWeight: "bold",
    padding: theme.spacing(0.5),
  },
  dropdown: {
    // maxWidth: "50%",
  },
  buttonHolder: {},
  attachmentBtnStyle: {
    [theme.breakpoints.up("xs")]: {
      marginLeft: "3rem",
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: "2rem",
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "2rem",
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: "-0.5rem",
    },
  },
}));

export default function EMChannelSettings(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const channelInfo = useSelector(
    (state) => state.groupAdmin["channelDetails"]
  );
  const [vision, setVision] = React.useState();
  const [additionalInfo, setAdditionalInfo] = React.useState();
  const [mission, setMission] = React.useState();
  const [channelIconFile, setChannelIconFile] = React.useState({});
  const [channelBannerFile, setChannelBannerFile] = React.useState({});
  const [privacyId, setPrivacyId] = React.useState();
  const [attachments, setAttachments] = React.useState([]);
  const [prevAttachments, setPrevAttachments] = React.useState([]);
  const [deletedDocs, setDeletedDocs] = React.useState([]);
  /* File Upload Handlers || state mgmt for file upload */
  const channelIconFileUploadHandler = (file) => {
    setChannelIconFile({
      file: file,
    });
  };
  const channelBannerFileUploadHandler = (file) => {
    setChannelBannerFile({
      file: file,
    });
  };
  /* Vision Mission change Handlers */
  const additionalInfoChangeHandler = (value) => {
    setAdditionalInfo(value);
  };
  const visionChangeHandler = (value) => {
    setVision(value);
  };
  const missionChangeHandler = (value) => {
    setMission(value);
  };

  useEffect(() => {
    if (channelInfo) {
      setPrevAttachments(channelInfo.attachments);
    }
  }, [channelInfo]);

  /* Conditional Rendering for prev File's selected */
  let channelIcon;
  if (channelInfo && channelInfo["logo_file"]) {
    let logoFile = channelInfo["logo_file"];
    let logoFileName = logoFile.substring(
      logoFile.lastIndexOf("/") + 1,
      logoFile.length
    );
    channelIcon = (
      <>
        <p style={{ marginBottom: "2%" }}>
          {" "}
          {"Previous Icon File is : "}{" "}
          <Link
            variant="body2"
            href={logoFile}
            target="_blank"
            rel="noopener"
            download={logoFileName}
          >
            {logoFileName}
          </Link>
        </p>
      </>
    );
  }
  let channelBanner;
  if (channelInfo && channelInfo["banner_file"]) {
    let logoFile = channelInfo["banner_file"];
    let logoFileName = logoFile.substring(
      logoFile.lastIndexOf("/") + 1,
      logoFile.length
    );
    channelBanner = (
      <>
        <p style={{ marginBottom: "2%" }}>
          {" "}
          {"Previous Banner File is : "}{" "}
          <Link
            variant="body2"
            href={logoFile}
            target="_blank"
            rel="noopener"
            download={logoFileName}
          >
            {logoFileName}
          </Link>
        </p>
      </>
    );
  }
  /* Formik Validation Object */
  const validationSchema = yup.object({
    name: yup.string().required(" Channel Name is required "),
    tagline: yup.string(),
    vision: yup.string(""),
    mission: yup.string(),
    // .max(255),
    additionalInfo: yup.string(),
    // privacy: yup
    //     .string()
    //     .max(1, "Please select Privacy Type"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: (channelInfo && channelInfo["channel_name"]) || "",
      tagline: (channelInfo && channelInfo["tagline"]) || "",
      vision: (channelInfo && channelInfo["vision_stmt"]) || "",
      mission: (channelInfo && channelInfo["mission_stmt"]) || "",
      additionalInfo: (channelInfo && channelInfo["additional_info"]) || "",
      privacy: (channelInfo && channelInfo["privacy_type"]) || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      //
      if (isEmpty(vision)) {
        setVision(values.vision);
      }
      if (isEmpty(mission)) {
        setMission(values.mission);
      }
      let additionalInfoEntered;
      if (
        channelInfo.additional_info == null ||
        (channelInfo.additional_info == "" && additionalInfo == undefined)
      ) {
        additionalInfoEntered = "";
      } else {
        additionalInfoEntered = additionalInfo;
      }
      let data = {
        id: channelInfo && channelInfo["id"],
        channel_name: values.name.trim(),
        tagline: values.tagline.trim(),
        vision_stmt: vision || "",
        mission_stmt: mission || "",
        additional_info: additionalInfoEntered,
        privacy_type: privacyId || values.privacy,
        is_active: channelInfo && channelInfo["is_active"],
        new_attachments: attachments,
        deleted_attachments: deletedDocs.toString(),
      };
      let files = {};
      if (!isEmpty(channelIconFile)) {
        files["icon"] = channelIconFile && channelIconFile.file;
      }
      if (!isEmpty(channelBannerFile)) {
        files["banner"] = channelBannerFile && channelBannerFile.file;
      }
      let jsonData = {
        ...data,
        files,
      };

      dispatch(
        EMDoUpdateChannelDetailsByIdAction({
          jsonData,
          key: "update_channel_settings",
        })
      );
      formik.resetForm(formik.initialValues);
      setVision("");
      setAttachments([]);
      setMission("");
      setAdditionalInfo();
      setChannelIconFile({});
      setChannelBannerFile({});
    },
  });

  return (
    <Modal
      open={props.open}
      onClose={props.close}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box className={classes.root} style={{ outline: "none" }}>
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          style={{
            padding: "0px 4px 0px 18px",
            backgroundColor: "#2F92D6",
            height: "auto",
          }}
        >
          <Typography variant={"h5"} className={classes.title}>
            {"Channel Settings"}
          </Typography>
          <IconButton
            onClick={() => {
              setAttachments([]);
              setPrevAttachments([]);
              props.close();
            }}
          >
            <CloseRounded style={{ color: "white" }} />
          </IconButton>
        </Box>
        <Paper className={classes.paper}>
          <form onSubmit={formik.handleSubmit}>
            <Box className={classes.paper} style={{ paddingTop: "16px" }}>
              <Grid container className={classes.container} spacing={6}>
                <Grid item xs={12} sm={6} md={6} className={classes.sideBox}>
                  <Box className={classes.paper}>
                    <EMSimpleTextFieldView
                      className={classes.textField}
                      label={"Name"}
                      id={"name"}
                      name={"name"}
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      // error={
                      //   formik.touched.name && Boolean(formik.errors.name)
                      // }
                      helperText={formik.touched.name && formik.errors.name}
                    />
                    <EMSimpleTextFieldView
                      label={"Tagline"}
                      name={"tagline"}
                      id={"tagline"}
                      value={formik.values.tagline}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.tagline && Boolean(formik.errors.tagline)
                      }
                      helperText={
                        formik.touched.tagline && formik.errors.tagline
                      }
                    />
                    <Grid
                      className={classes.dropdown}
                      item
                      xs={12}
                      sm={6}
                      md={6}
                    >
                      <Typography className={classes.header}>
                        {"Channel Privacy"}
                      </Typography>
                      <EMPrivacyDropdown
                        name={"privacy"}
                        initialValue={formik.values.privacy}
                        // errorText={showNetworkError}
                        onSelect={(id) => {
                          setPrivacyId(id);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.header}>
                        {"Channel Logo"}
                      </Typography>
                      <EMImageCropper
                        id={"iconId"}
                        changeHandler={channelIconFileUploadHandler}
                        accept={[".png", ".jpg", ".jpeg"]}
                        fileSize={11534336}
                        supportedFormats={[
                          "image/png",
                          "image/jpg",
                          "image/jpeg",
                        ]}
                        maxFileSize={"10MB"}
                        aspectRatio={150 / 150}
                        minfilesize={71680}
                        minFileSize={"70KB"}
                      />
                      {channelIcon}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.header}>
                        {"Channel Banner"}
                      </Typography>
                      <EMImageCropper
                        id={"bannerId"}
                        changeHandler={channelBannerFileUploadHandler}
                        accept={[".png", ".jpg", ".jpeg"]}
                        fileSize={11534336}
                        supportedFormats={[
                          "image/png",
                          "image/jpg",
                          "image/jpeg",
                        ]}
                        maxFileSize={"10MB"}
                        aspectRatio={789 / 300}
                        minfilesize={102400}
                        minFileSize={"100KB"}
                      />
                      {channelBanner}
                    </Grid>
                  </Box>
                  <Box>
                    <Box alignItems="flex-end" justifyContent="center" p={2}>
                      <Typography>Previous Files:</Typography>
                      <div className={classes.rootForGrid}>
                        <GridList
                          cellHeight={20}
                          className={classes.gridList}
                          cols={1}
                        >
                          {prevAttachments &&
                            prevAttachments.map((doc, id) => (
                              <Box display="flex">
                                <Typography>
                                  <a
                                    href={
                                      process.env
                                        .REACT_APP_BACKEND_FILE_PREVIEW +
                                      doc.file
                                    }
                                    style={{ color: "#2F92D6" }}
                                    target="_blank"
                                  >
                                    {doc &&
                                      doc.file.substr(
                                        doc && doc.file.lastIndexOf("/") + 1,
                                        doc && doc.length
                                      )}
                                  </a>
                                </Typography>
                                <IconButton
                                  onClick={() => {
                                    if (deletedDocs.length > 0) {
                                      setDeletedDocs([...deletedDocs, doc.id]);
                                    } else {
                                      setDeletedDocs([doc.id]);
                                    }
                                    let docArray = prevAttachments;
                                    let index = id;
                                    docArray.splice(index, 1);
                                    setPrevAttachments(docArray);
                                  }}
                                >
                                  <CloseRounded color="secondary" />
                                </IconButton>
                              </Box>
                            ))}
                        </GridList>
                      </div>
                    </Box>

                    <Box alignItems="flex-end" justifyContent="center" p={2}>
                      <Typography>Selected Files:</Typography>
                      <div className={classes.rootForGrid}>
                        <GridList
                          cellHeight={20}
                          className={classes.gridList}
                          cols={2}
                        >
                          {attachments &&
                            attachments.map((doc, id) => (
                              <Box key={id}>
                                <Tooltip
                                  title={
                                    <h2 style={{ color: "white" }}>
                                      {doc.name}
                                    </h2>
                                  }
                                  placement="top"
                                >
                                  <Typography>
                                    <a
                                      href={doc.url}
                                      style={{ color: "#2F92D6" }}
                                      target="_blank"
                                    >
                                      {doc.name.slice(0, 20)}
                                    </a>
                                  </Typography>
                                </Tooltip>
                              </Box>
                            ))}
                        </GridList>
                      </div>
                    </Box>
                    <Box display="flex" className={classes.attachmentBtnStyle}>
                      <EMFileSelect
                        changeHandler={(file) => {
                          if (file != null) {
                            let data = file;
                            if (data != null) {
                              let newDocuments = [];
                              for (let i = 0; i < data.length; i++) {
                                let file = data[i];
                                let fileObj = file && file;
                                let url = URL.createObjectURL(data[i]);
                                fileObj["url"] = url;
                                newDocuments.push(fileObj);
                              }

                              let documentArray;
                              if (attachments && attachments.length > 0) {
                                let arr = attachments.concat(newDocuments);
                                setAttachments(arr);
                              } else {
                                documentArray = newDocuments;
                                setAttachments(documentArray);
                              }
                            } else {
                              setAttachments([]);
                            }
                          }
                        }}
                        id={"doc"}
                        accept={[
                          ".pdf",
                          ".txt",
                          ".doc",
                          ".xls",
                          ".pptx",
                          ".ppt",
                          ".csv",
                          ".docx",
                          ".xlsx",
                          ".png",
                          ".jpg",
                          ".jpeg",
                        ]}
                        fileSize={11534336}
                        supportedFormats={[
                          "text/plain",
                          "application/pdf",
                          "application/doc",
                          "application/ms-doc",
                          "application/msword",
                          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                          "application/mspowerpoint",
                          "application/powerpoint",
                          "application/vnd.ms-powerpoint",
                          "application/x-mspowerpoint",
                          "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                          "application/excel",
                          "application/vnd.ms-excel",
                          "application/x-excel",
                          "application/x-msexcel",
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                          "image/png",
                          "image/jpg",
                          "image/jpeg",
                        ]}
                        maxFileSize={"10MB"}
                        icon={<CloudUploadIcon />}
                        label="ATTACHMENTS"
                        // disabled={
                        //   data.documents && data.documents.length >= 10
                        //     ? true
                        //     : false
                        // }
                        multipleFileSelect={true}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} className={classes.sideBox}>
                  <Box className={classes.spacing}>
                    <EMTextArea
                      placeHolder="Vision"
                      toolbarId={1}
                      changeHandler={(value) => {
                        visionChangeHandler(value);
                      }}
                      value={formik.values.vision}
                    />
                    {/* {vision} */}
                  </Box>
                  <Box className={classes.spacing} toolbarId={2}>
                    <EMTextArea
                      placeHolder="Mission"
                      changeHandler={(value) => {
                        missionChangeHandler(value);
                      }}
                      value={formik.values.mission}
                    />
                    {/* {mission} */}
                  </Box>
                  {/* <Box className={classes.spacing}>
                                        <EMSimpleTextFieldView
                                            label={"Additional Information"}
                                            id={"additionalInfo"}
                                            name={"additionalInfo"}
                                            onChange={formik.handleChange}
                                            value={formik.values.additionalInfo}
                                        error={formik.touched.additionalInfo && Boolean(formik.errors.additionalInfo)}
                                        helperText={formik.touched.additionalInfo && formik.errors.additionalInfo}
                                        />
                                    </Box> */}
                  <Box className={classes.spacing}>
                    <EMTextArea
                      placeHolder="Additional Information"
                      toolbarId={1}
                      changeHandler={(value) => {
                        additionalInfoChangeHandler(value);
                      }}
                      value={formik.values.additionalInfo}
                    />
                    {/* {additionalInfo} */}
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid
                container
                justify="center"
                alignItems="center"
                direction="row"
                className={classes.buttonHolder}
                spacing={4}
              >
                <Grid item>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    <Typography variant="h7">Save</Typography>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    className={classes.button}
                    variant="outlined"
                    color="secondary"
                    type="reset"
                    onClick={() => {
                      setAttachments([]);
                      setPrevAttachments([]);
                      props.close();
                    }}
                  >
                    <Typography variant="h7">Discard</Typography>
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Paper>
        {/* </Box> */}
      </Box>
    </Modal>
  );
}
