import { createMuiTheme, createTheme } from "@material-ui/core";
import overrides from "./overrides";
import palette from "./palette";
import typography from './typography'
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

const BREAKPOINTS = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
  apple:1930
};

const breakpointsFull = createBreakpoints({
    values: {...BREAKPOINTS},
    keys: Object.keys(BREAKPOINTS),
  });


const theme =  createTheme({
  palette,
  overrides,
  typography: {
    "fontFamily": `Montserrat`,
    "fontSize": 11,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
   },
   breakpoints: breakpointsFull
});

export default theme
