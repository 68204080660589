import { cloneDeep, findIndex } from "lodash";
import { AUTH_LOGOUT } from "../../../constants";
import {
  ALL_MY_NETWORKS,
  DETAIL_FOR_NETWORK,
  ORGANIZATION_DETAILS,
  NETWORK_BY_ORGANIZATION_FILTER,
  ALL_CHANNELS_FOR_NETWORK,
  ALL_GROUPS_FOR_NETWORK,
  FILTER_FOR_NETWORK,
  GET_ACTIONS_LIST_FOR_DROPDOWN,
  GET_ACTIONS_OF_ORGANIZATION,
  DISABLE_ENABLE_FILTER_FOR_NETWORKS,
} from "../../../constants/UserPlatform/EMNetworkConstant";

const initialState = {
  showProfileEdit: false,
  myNetworks: [],
  networkDetails: {},
  allChannelsfornetwork: [],
  allgroupsfornetwork: [],
  networkFilter: [],
  organizationDetails: {},
  actionsList: [],
  organizationActions: [],
  isFilterDisabled: true
};

export default function EMMyNetworkReducer(state = initialState, action) {
  let newState = cloneDeep(state);
  switch (action.type) {
    case ALL_MY_NETWORKS: {
      newState["myNetworks"] = action.payload;
      return newState;
    }
    case FILTER_FOR_NETWORK: {
      newState["networkFilter"] = action.payload;
      newState["networkFilter"].map((network) => {
        network["value"] = false;
      });
      return newState;
    }

    case DISABLE_ENABLE_FILTER_FOR_NETWORKS:{
      newState["isFilterDisabled"] = action.payload
      return newState
    }

    case NETWORK_BY_ORGANIZATION_FILTER: {
      let postIndex = action.payload.postIndex;
      if (postIndex >= 0) {
        newState["networkFilter"][postIndex].value =
          action.payload.checked_value;
      }
      return newState;
    }

    case DETAIL_FOR_NETWORK: {
      newState["networkDetails"] = action.payload;
      return newState;
    }
    case ORGANIZATION_DETAILS: {
      newState["organizationDetails"] = action.payload[0];
      return newState;
    }

    case ALL_CHANNELS_FOR_NETWORK: {
      newState["allChannelsfornetwork"] = action.payload;
      return newState;
    }
    case ALL_GROUPS_FOR_NETWORK: {
      newState["allgroupsfornetwork"] = action.payload;
      return newState;
    }

    case GET_ACTIONS_LIST_FOR_DROPDOWN: {
      newState["actionsList"] = action.payload;
      return newState;
    }

    case GET_ACTIONS_OF_ORGANIZATION: {
      newState["organizationActions"] = action.payload;
      return newState;
    }

    case AUTH_LOGOUT: {
      return { ...initialState };
    }

    default: {
      return newState;
    }
  }
}
