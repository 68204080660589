const { default: axios } = require("axios");

const EMDoLoginAPI = userData => {
  return axios.post("auth/token/", userData);
};

const EMDoGetLoggedInUserRoles = ()=>{
  let url = `auth/roles/`
  return axios.get(url)
}
/* Select Entity Dropdown */
const EMDoGetEntityInformationBasedOnPermission = (data)=>{
  let url = `auth/roles/${data.entity}/${data.permissionData}/`
  return axios.get(url)
}
// auth/link-email/
/* Link Email Api */
const EMDoLinkEmailToPrimaryAccount = (data)=>{
  let url = `auth/link-email/`
  return axios.post(url,data)
}
/* UnLink Email Api */
const EMDoUnLinkEmailToPrimaryAccount = (data)=>{
  let url = `auth/unlink-email/`
  return axios.put(url,data)
}
const EMDoGetMultipleProfileDetails = (data)=>{
  let url = `auth/profiles/`
  return axios.get(url)
}

/* Switch Profile Api */
const EMDoSwitchProfile = (data)=>{
  let url = `auth/switch-profile/${data}/`
  return axios.post(url,data)
}

// auth/verify-email-link
const EMDoVerifyLinkEmail = (data)=>{
  
  let url = `auth/verify-email-link/${data}`
  return axios.put(url,data)
}

const EMGetRolesAndPermissionsBasedOnEntity = (entity, entityId) => {
  let url = `auth/get-entity-permissions/${entity}/${entityId}/`
  return axios.get(url)
}

const EMDoGetCommonAdminPanelPermissions = () => {
  let url = `auth/admin-panel-permissions/`
  return axios.get(url)
}

const EMDoGetCommonGlobalPermissions = () => {
  let url = `auth/global-permissions/`
  return axios.get(url)
}

export default {
  EMDoLoginAPI,
  EMDoGetLoggedInUserRoles,
  EMDoGetEntityInformationBasedOnPermission,
  EMDoLinkEmailToPrimaryAccount,
  EMDoUnLinkEmailToPrimaryAccount,
  EMDoGetMultipleProfileDetails,
  EMDoSwitchProfile,
  EMDoVerifyLinkEmail,
  EMGetRolesAndPermissionsBasedOnEntity,
  EMDoGetCommonAdminPanelPermissions,
  EMDoGetCommonGlobalPermissions
};
