import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
      // '&::-webkit-scrollbar': {
      //   width: '0.6em',
      // },
      // '&::-webkit-scrollbar-track': {
      //   boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      //   webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
      // },
      // '&::-webkit-scrollbar-thumb': {
      //   backgroundColor: '#4A4A4A',
      //   outline: '1px solid slategrey',
      // },
    },
    
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%'
    },
    body: {
      backgroundColor: '#f4f6f8',
      height: '100%',
      width: '100%'                                                 
    },
    a: {
      textDecoration: 'none'
    },
    '#root': {
      height: '100%',
      width: '100%'
    }
  }
}));

const EMGlobalStyles = () => {
  useStyles();

  return null;
};

export default EMGlobalStyles;
