import {
  Grid,
  Paper,
  Typography,
  Button,
  GridList,
  GridListTile,
  CardHeader,
  IconButton,
  useMediaQuery
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CloseIcon from "@material-ui/icons/Close";
import EMPage from "../../../../../components/commans/EMPage"; 
import Card from "@material-ui/core/Card";
import { useSelector, useDispatch } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import {
  EMDoAcceptRejectUserRequestForChannelAction,
  EMDoGetUserRequestsForChannelAction,
} from "../../../../../redux/actions/UserPlatform/Channels/EMChannelsAction";
import EMSideBar from "../../../../../components/navigation/EMSideBar";
import { EMGetRolesAndPermissionsBasedOnEntityAction } from "../../../../../redux/actions/EMAuthActions";

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    marginTop: "4%",
    marginLeft: "3%",
    marginBottom: "2%",
  },
  user: {
    paddingLeft: "1%",
    textAlign: "center",
    fontWeight: "bolder",
    color: theme.palette.text.secondary,
    marginLeft: "3%",
  },
  username: {
    color: theme.palette.text.secondary,
  },
  designation: {
    fontWeight: "bolder",
    color: theme.palette.text.primary,
  },
  first: {
    overflow: "auto",
    // width: "90%",
    maxHeight: window.screen.height,
    "&::-webkit-scrollbar": {
      width: "0.3em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#4A4A4A",
      outline: "1px solid slategrey",
    },
  },
}));

export default function EMChannelSettingsPage(props) {
  const classes = useStyles();
  const data = useSelector((state) => state.channels || {});
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      EMDoGetUserRequestsForChannelAction({
        id: data.singleChannel.id
          ? data.singleChannel.id
          : window.sessionStorage.getItem("ChannelId"),
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      EMGetRolesAndPermissionsBasedOnEntityAction({
        entity: "channel",
        entityId: window.sessionStorage.getItem("ChannelId"),
      })
    );
  },[])

  const theme = useTheme()

  const matchesMediumScreen = useMediaQuery(theme.breakpoints.up('md'));

  const matchesLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <EMPage
      title="Channel Settings"
      style={{
        width: "100%",
        paddingLeft: matchesLargeScreen ? "4%" : "2%",
        paddingTop: matchesLargeScreen
          ? "4%"
          : matchesMediumScreen
          ? "6%"
          : "8%",
        paddingRight: matchesLargeScreen ? "4%" : "2%",
        backgroundColor: "#e7dadc",
        height: window.screen.height,
      }}
    >
      <Grid container spacing={3}>
      {matchesMediumScreen ?  <Grid item xs={matchesLargeScreen ? 2 : 3}>
            <EMSideBar />
          </Grid> : null}
        <Grid item xs={matchesLargeScreen ? 10 : matchesMediumScreen ? 9 : 12}>
        <Paper elevation={2}>
        <Grid
          container
          direction="row"
          alignItems="flexStart"
          style={{ padding: 20 }}
        >
          <Grid item lg={4} md={5} sm={7} xs={12}>
            <div className={classes.main}>
              <PersonAddIcon fontSize="large" style={{ color: "#2F92D6" }} />
              <Typography variant="h5" component="h5" className={classes.user}>
                User Requests
              </Typography>
            </div>
            <div className={classes.first}>
              {data.channelRequests.map((user) => (
                <Card>
                  <CardHeader
                    avatar={
                      <Avatar
                        aria-label="recipe"
                        className={classes.avatar}
                        src={
                          user.user.profile_pic_file &&
                          user.user.profile_pic_file.includes("localhost")
                            ? user.user.profile_pic_file
                            : process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(
                                0,
                                -1
                              ) + user.user.profile_pic_file
                        }
                      ></Avatar>
                    }
                    action={
                      <>
                        <IconButton
                          aria-label="settings"
                          onClick={() => {
                            dispatch(
                              EMDoAcceptRejectUserRequestForChannelAction({
                                myaction: "accept",
                                requestId: user.id,
                              })
                            );
                          }}
                        >
                          <CheckBoxIcon
                            fontSize="large"
                            style={{ color: "#2F92D6" }}
                          />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            dispatch(
                              EMDoAcceptRejectUserRequestForChannelAction({
                                myaction: "reject",
                                requestId: user.id,
                              })
                            );
                          }}
                        >
                          <CloseIcon fontSize="large" />
                        </IconButton>
                      </>
                    }
                    title={
                      <Typography className={classes.username}>
                        {user.user.first_name} {user.user.last_name}
                      </Typography>
                    }
                    subheader={
                      <div style={{ display: "flex" }}>
                        <Typography className={classes.designation}>
                          {user.user.professional_title},
                        </Typography>{" "}
                        <Typography className={classes.designation}>
                          {user.user.organization_name}
                        </Typography>
                      </div>
                    }
                  />
                </Card>
              ))}
            </div>
          </Grid>
          {/* <Grid item xs={7}></Grid> */}
        </Grid>
      </Paper>

        </Grid>
      </Grid>
         </EMPage>
  );
}
