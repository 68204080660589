import React from "react";
import {
  Paper,
  Box,
  Button,
  Modal,
  Typography,
  IconButton,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import {
  EMSimpleTextFieldView,
  EMChipTextFieldView,
} from "../../../../components/EMTextFieldView";
import { CloseRounded } from "@material-ui/icons";
import { useFormik, Field } from "formik";
import * as yup from "yup";
import ChipInput from "material-ui-chip-input";
import { useEffect } from "react";
import EMGroupUserRoleDropdown from "../../commons/EMGroupUserRoleDropdown"
import EMSwitch from "../../../../components/commans/EMSwitch";
import {
  EMDoUpdateGroudUserDetailsAction,
} from "../../../../redux/actions/EMGroupAdminActions";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
        

    [theme.breakpoints.up("xs")]: {
        backgroundColor: theme.palette.common.white,
    height: "auto",
    minWidth: "50%",
    margin: "auto",
    padding: "0px",
    // maxWidth: '100vw',
    maxHeight: '100%', 
    // position: 'fixed',
    marginTop: '22rem',
    transform: 'translate(0, -50%)',
    width:"70%", 
    marginLeft:"5rem"
      },
      [theme.breakpoints.up("sm")]: {
        backgroundColor: theme.palette.common.white,
        height: "auto",
        minWidth: "50%",
        margin: "auto",
        padding: "0px",
        // maxWidth: '100vw',
        maxHeight: '100%',
        position: 'fixed',
        top: '50%',
        transform: 'translate(0, -50%)',
        width:"50%",
      },
      [theme.breakpoints.up("md")]: {
        backgroundColor: theme.palette.common.white,
        height: "auto",
        minWidth: "50%",
        margin: "auto",
        padding: "0px",
        // maxWidth: '100vw',
        maxHeight: '100%',
        position: 'fixed',
        top: '50%',
        transform: 'translate(0, -50%)',
        width:"50%",
      },
      [theme.breakpoints.up("lg")]: {
        backgroundColor: theme.palette.common.white,
    height: "auto",
    minWidth: "50%",
    margin: "auto",
    padding: "0px",
    // maxWidth: '100vw',
    maxHeight: '100%',
    position: 'fixed',
    top: '50%',
    transform: 'translate(0, -50%)',
    width:"50%",
      },
},
  title: {
    margin: 10,
    flexGrow: 1,
    fontWeight: "bold",
    color:"white",
    fontSize:"large"
  },
  form: {
    justify:"center",
    alignItems:"center",
    direction:"row",
    padding: "5px 36px",
    width: "100%",
  },
  buttonHolder: {
    padding: theme.spacing(3),
  },
  switch: {
    padding: "20px 10px",
  }
}));

export default function EMEditUserDetails(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  let incorrectInput = 'Only alphabets are allowed'
  const [roleId, setRoleId] = React.useState("");
  const userData = useSelector((state) => state.auth);
  let loginUser = userData.email;
  let isRoleDisabled = props.userData["email"]?props.userData["email"]=== userData.email :false
  let isDisabled = props.permissionData && props.permissionData["edit_flag"]
  const validationSchema = yup.object({
    first_name: yup
      .string("Enter first name").trim()
      .required("First name is required"),
    last_name: yup.string("Enter last name").trim()
      .required("Last name is required"),
    email: yup
      .array()
      .transform(function (value, originalValue) {
        if (this.isType(value) && value !== null) {
          return value;
        }
        return originalValue ? originalValue.split(/[\s,]+/) : [];
      })
    .of(yup.string().email(({ value }) => `${value} is not a valid email`.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,"Invalid Email Address Entered")))
      .min(1,"Email Address field must have atleast one email address")
      .required("Email is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: props.userData["first_name"] || "",
      last_name: props.userData["last_name"] || "",
      email: props.userData["email"] ? [props.userData["email"]] : [],
      role:  props.userData["role"] || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (props.userData && props.userData.id > 0) {
        let role = roleId ? roleId.id :  props.userData["role"]
        let newUserData = { ...props.userData, ...values,role, email: values.email[0] };
        let id = newUserData.id;
        
        dispatch(
          EMDoUpdateGroudUserDetailsAction({
            
            data : {...newUserData},
            id,
            key: "user_edit",
          })
        );
      }
    },
  });

  //reset the form after modal is closed
  useEffect(() => {
    return () => {
      formik.resetForm();
    };
  }, [props.open]);

  return (
    <Modal open={props.open} onClose={props.close} style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
      <Box className={classes.root} style={{ outline: 'none'}}>
        <form onSubmit={formik.handleSubmit}>
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            style={{    padding: "0px 4px 0px 18px",
            backgroundColor:"#2F92D6"}}
          >
            <Typography className={classes.title}>
            {"Edit User Details"}
            </Typography>
            <IconButton onClick={props.close}>
              <CloseRounded style={{color:"white"}} />
            </IconButton>
          </Box>
          <Grid
            container
            spacing={3}
            className={classes.form}
          >
            <Grid item xs={12} sm={12} md={6}>
              <EMSimpleTextFieldView
                label={"First Name"}
                id="first_name"
                name="first_name"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                error={
                  formik.touched.first_name && Boolean(formik.errors.first_name)
                }
                helperText={
                  formik.touched.first_name && formik.errors.first_name
                }
                disabled = {isDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <EMSimpleTextFieldView
                label={"Last Name"}
                id="last_name"
                name="last_name"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                disabled={isDisabled}
                error={
                  formik.touched.last_name && Boolean(formik.errors.last_name)
                }
                helperText={formik.touched.last_name && formik.errors.last_name}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            className={classes.form}
          >
            <Grid item xs>
              <ChipInput
                name="email"
                type="email"
                label="Email Address"
                disabled={props.userData["email"] || isDisabled}
                helperText={
                  formik.errors.email && formik.touched.email
                    ? formik.errors.email
                    : ""
                }
                value={formik.values.email}
                //InputProps={{ style: {fontFamily: "Arial" } }}
                //FilledInputProps={{ style: {fontFamily: "Arial" } }}
                error={
                  formik.errors.email && formik.touched.email ? true : false
                }
                margin="none"
                onAdd={(chip) => {
                  if (chip) {
                    formik.setFieldValue("email", [chip]);
                  }
                }}
                onDelete={() => {
                  formik.setFieldValue("email", []);
                }}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.form}
          >
            <Grid item xs={12} sm={12} md={5}>
              <EMGroupUserRoleDropdown
                name="role"
                id="role"
                onSelect={id => {
                  setRoleId(id);
                }}
                initialValue={formik.values.role}
                error={formik.touched.role && Boolean(formik.errors.role)}
                helperText={formik.touched.role && formik.errors.role}
                disabled={isRoleDisabled}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="row"
            className={classes.buttonHolder}
            spacing={4}
          >
            <Grid item>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                type="submit"
              >
                <Typography variant="h7">{"Edit"}</Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.button}
                variant="outlined"
                color="secondary"
                onClick={props.close}
              >
                <Typography variant="h7">Discard</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
}
