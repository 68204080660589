import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Box,
  Button,
  Modal,
  CircularProgress,
  Typography,
  Hidden,
} from "@material-ui/core";
import EMPage from "../../../../components/commans/EMPage";
import EMRolesPermissionsTable from "./EMRolesPermissionsTable";
import EMAddEditRole from "./EMAddEditRole";
import EMGroupDropdown from "../../commons/groupDropdown";
import { useSelector, useDispatch } from "react-redux";
import { find, isEmpty } from "lodash";
import {
  EMDoGetListOfGroupsForDropdownInUserManagementAction,
  EMDoGetPermissionSpecificToGroupAction,
  EMDoRetrievePermissionsForGroupAction,
} from "../../../../redux/actions/EMGroupAdminActions";
import { EMDoGetPermissionForEntityAction } from "../../../../redux/actions/EMCommonActions";

export default function EMManageGroupUsers(props) {
  const [filter, setFilter] = useState("");
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [groupId, setGroupId] = React.useState();
  const [editData, setEditData] = React.useState({});
  const [labels, setLabels] = React.useState({ title: "", buttonLabel: "" });
  const [showGroupError, setShowGroupError] = React.useState();
  const [isError, setIsError] = React.useState(true);
  const userData = useSelector((state) => state.auth);
  const groupSpecificPermissions = useSelector(
    (state) => state.groupAdmin.groupSpecificRolesAndPermissions || {}
  );
  const [permissionData, setPermissionData] = React.useState();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const uiData = useSelector((state) => state.ui);
  //call dropdown Api
  useEffect(() => {
    dispatch(EMDoGetListOfGroupsForDropdownInUserManagementAction());
  }, []);

  useEffect(() => {
    if (groupSpecificPermissions) {
      let permission = find(groupSpecificPermissions, { context: groupId });
      let specificPermission = find(permission && permission.rolePermissions, {
        permission_name: "manage_roles_and_permissions",
      });
      setPermissionData(specificPermission);
    }
  }, [groupSpecificPermissions]);

  useEffect(() => {
    if (uiData["messages"]) {
      let refObj = find(uiData["messages"], {
        key: "role_permission_add_edit_success",
      });
      if (refObj && refObj.type === "success") {
        refObj.key = "role_permission_add_edit_success_done";
        setOpen(false);
      }
    }
  }, [uiData]);

  useEffect(() => {
    if (groupId) {
      groupSelectionHandler();
      let entityInfo = "group";
      dispatch(EMDoGetPermissionForEntityAction(entityInfo));
      dispatch(EMDoGetPermissionSpecificToGroupAction({ id: groupId }));
    }
  }, [groupId]);

  const groupSelectionHandler = () => {
    if (groupId == undefined) {
      setShowGroupError("Please Select Group Here");
      setIsError(false);
    } else {
      setShowGroupError("");
      setIsError(true);
    }
  };

  let disabledValue = (permissionData && permissionData["add_flag"]) || true;

  return (
    <EMPage title="Manage Roles And Permissions">
      <Box>
        <Modal
          style={{ marginTop: window.screen.height / 2 }}
          open={uiData.loading.state}
        >
          <Box
            style={{ display: "flex" }}
            alignItems="center"
            justifyContent="center"
          >
            <Box textAlign="center">
              <CircularProgress />
              <Typography style={{ fontWeight: "medium", fontSize: "large" }}>
                {uiData.loading.message}
              </Typography>
            </Box>
          </Box>
        </Modal>
        <Paper elevation={2}>
          <Box height="100%" display="flex" flexDirection="column">
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              p={3}
              justifyItems="center"
              alignItems="center"
            >
              <Grid container style={{ maxWidth: "100%" }} justify="flex-start">
                <Grid
                  width="100%"
                  justify="flex-start"
                  item
                  xs={12}
                  sm={12}
                  md={10}
                  lg={10}
                >
                  <EMGroupDropdown
                    error={showGroupError}
                    onSelect={(id) => {
                      setGroupId(id);
                    }}
                  />
                </Grid>
                <Hidden smDown>
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <Button
                      style={{ marginLeft: "2rem", marginTop: "1rem" }}
                      disabled = {!disabledValue}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        groupSelectionHandler();
                        if (groupId) {
                          setOpen(true);
                          setEditData({});
                          setEdit(false);
                          setLabels({
                            title: "Add A New Role",
                            buttonLabel: "Save",
                          });
                          let entityInfo = "group";
                          dispatch(
                            EMDoGetPermissionForEntityAction(entityInfo)
                          );
                        }
                      }}
                    >
                      {"Add New Role"}
                    </Button>
                  </Grid>
                </Hidden>
                <Hidden lgUp mdUp>
                  <Grid item xs={12} sm={12} md={2} lg={1}>
                    {" "}
                    <Button
                      style={{ marginTop: "2rem" }}
                      disabled = {!disabledValue}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        groupSelectionHandler();
                        if (groupId) {
                          setOpen(true);
                          setEditData({});
                          setEdit(false);
                          setLabels({
                            title: "Add A New Role",
                            buttonLabel: "Save",
                          });
                          let entityInfo = "group";
                          dispatch(
                            EMDoGetPermissionForEntityAction(entityInfo)
                          );
                        }
                      }}
                    >
                      {"Add New Role"}
                    </Button>
                  </Grid>
                </Hidden>
              </Grid>
              <Box>
                <EMAddEditRole
                  open={open}
                  close={handleClose}
                  entity={groupId}
                  roleData={editData}
                  labels={labels}
                  closeSetter={setOpen}
                  isEdit={edit}
                />
              </Box>
            </Box>
            <EMRolesPermissionsTable
            permissionData={permissionData}
              entity={groupId}
              onEdit={(roleData) => {
                let contextData = {
                  entity: "group",
                  id: groupId,
                  roleId: roleData.id,
                };

                dispatch(
                  EMDoRetrievePermissionsForGroupAction({ contextData })
                );
                setEdit(true);
                setEditData(roleData);
                setOpen(true);
                setLabels({ title: "Edit A Role", buttonLabel: "Update" });
              }}
            />
          </Box>
        </Paper>
      </Box>
    </EMPage>
  );
}
