import React, { useState, useEffect } from "react";
import EMPage from "../../../components/commans/EMPage";
import {
  Grid,
  Button,
  Paper,
  Fab,
  IconButton,
  useMediaQuery,
} from "@material-ui/core/";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useLocation } from "react-router";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useNavigate } from "react-router";
import {
  EMDoGetNotificationsAction,
  EMDoSeenNotificationAction,
} from "../../../redux/actions/EMCommonActions";
import { EMDoVerifyLinkEmailAction } from "../../../redux/actions/EMAuthActions";
import Parse from "html-react-parser";
import EMSideBar from "../../../components/navigation/EMSideBar";
import { CLEAR_GROUP_DATA } from "../../../redux/constants/UserPlatform/EMGroupConstant";
import { CLEAR_CHANNEL_DATA } from "../../../redux/constants/UserPlatform/EMChannelConstant";
import InfiniteScroll from "react-infinite-scroll-component";
import { useCallback } from "react";

const useStyles = makeStyles((theme) => ({
  top: {
    marginTop: "5%",
    marginLeft: "15%",
    marginRight: "15%",
  },
  text: {
    marginLeft: 5,
    marginTop: 5,
    fontWeight: "bold",
  },
  button: {
    marginLeft: "auto",
    marginTop: "10",
    variant: "h4",
    fontWeight: "bold",
  },
  root: {
    display: "flex",
    marginLeft: "3",
    borderBottom: "1px solid",
    // marginBottom: '20px',
    padding: "20px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  groupInfo: {
    display: "flex",
    marginLeft: "3",
    borderBottom: "1px solid",
    // marginBottom: '20px',
    padding: "20px",
  },
}));

export default function Notifications(props) {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notificationData = useSelector((state) => state.common.notifications);
  const notificationCount = useSelector(
    (state) => state.common.notificationCount
  );
  const notificationNext = useSelector(
    (state) => state.common.nextDataForNotifications
  );
  const [limit, setLimit] = useState(0);

  const sendQuery = useCallback(async () => {
    dispatch(EMDoGetNotificationsAction({ limit: 15, offset: limit }));
  }, [limit]);

  useEffect(() => {
    sendQuery(limit);
  }, [sendQuery, limit]);

  const handleObserver = useCallback(() => {
    ;
    if (notificationData && notificationNext) {
      setLimit((prev) => prev + 15);
    }
  }, [limit, notificationNext]);

  const theme = useTheme();

  const matchesMediumScreen = useMediaQuery(theme.breakpoints.up("md"));

  const matchesLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  function ChangeDate(date) {
    let newDate = new Date(date);
    var hours = newDate.getHours();
    var minutes = newDate.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    let dateToDisplay =
      newDate.getDate() +
      " " +
      monthNames[newDate.getMonth()] +
      " " +
      newDate.getFullYear() +
      " " +
      strTime;

    return dateToDisplay;
  }

  return (
    <InfiniteScroll
      dataLength={notificationData.length}
      next={handleObserver}
      hasMore={true}
    >
      <EMPage
        title="Notifications"
        style={{
          width: "100%",
          paddingLeft: matchesLargeScreen ? "8%" : "4%",
          paddingTop: matchesLargeScreen
            ? "4%"
            : matchesMediumScreen
            ? "6%"
            : "8%",
          paddingRight: matchesLargeScreen ? "8%" : "4%",
          paddingBottom: "2%",
        }}
      >
        <Grid container spacing={3}>
          {matchesMediumScreen ? (
            <Grid item xs={matchesLargeScreen ? 2 : 3}>
              <EMSideBar />
            </Grid>
          ) : null}
          <Grid
            item
            xs={matchesLargeScreen ? 10 : matchesMediumScreen ? 9 : 12}
          >
            <Paper style={{ padding: "1%" }} elevation={2}>
              {/* <Grid
          item
          style={{
            marginBottom: "15px",
            paddingLeft: "15px",
            marginTop: "18px",
          }}
        > */}
              <Grid
                container
                style={{
                  padding: "0px 4px 0px 4px",
                  backgroundColor: "#2F92D6",
                  height: "50px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid item md={10} sm={10} xs={9}>
                  <Typography
                    style={{
                      fontWeight: "bolder",
                      fontSize: "large",
                      color: "white",
                    }}
                  >
                    Notifications
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={2}
                  sm={2}
                  xs={3}
                  container
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    onClick={() => {
                      navigate(
                        location.state.path ||
                          window.sessionStorage.getItem("previousPath")
                      );
                    }}
                    style={{ color: "white" }}
                    variant="outlined"
                    startIcon={<ArrowBackIcon style={{ color: "white" }} />}
                  >
                    Back
                  </Button>
                </Grid>
              </Grid>
              <div>
                {notificationData &&
                  notificationData.map((notification) =>
                    notification.notification_type ===
                    "RequestedToLinkProfile" ? notification.isSeen ? (
                      <div className={classes.root} style={{backgroundColor:"#0000008a"}}>
                      <Grid item xs={12}>
                          <Typography
                            color="textprimary"
                            style={{ padding: "10px" }}
                          >
                            {Parse(notification.notification_description)}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            color="textSecondary"
                            style={{ padding: "10px" }}
                          >
                            {notification.updated_on &&
                              ChangeDate(notification.updated_on)}
                          </Typography>
                        </Grid>
                      </div>
                    ) : (
                      <div className={classes.root}>
                        <Grid item xs={9}>
                          <Typography
                            color="textprimary"
                            style={{ padding: "10px" }}
                          >
                            {Parse(notification.notification_description)}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            color="textSecondary"
                            style={{ padding: "10px" }}
                          >
                            {notification.updated_on &&
                              ChangeDate(notification.updated_on)}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Fab
                            onClick={() => {
                              dispatch(
                                EMDoVerifyLinkEmailAction({
                                  data: notification.key,
                                })
                              );
                              dispatch(
                                EMDoSeenNotificationAction({
                                  id: notification.id,
                                  from: "notication",
                                })
                              );
                            }}
                            size="small"
                            color="primary"
                            style={{
                              borderRadius: "2px",
                              marginLeft: "20px",
                              marginRight: "20px",
                            }}
                          >
                            <CheckIcon />
                          </Fab>
                          <Fab
                            onClick={() => {
                              dispatch(
                                EMDoSeenNotificationAction({
                                  id: notification.id,
                                  key: "cancel_linking_request",
                                  from: "notication",
                                })
                              );
                            }}
                            size="small"
                            style={{ borderRadius: "2px" }}
                          >
                            <CloseIcon />
                          </Fab>
                          <Typography>
                            {notification.update_on &&
                              ChangeDate(notification.update_on)}
                          </Typography>
                        </Grid>
                      </div>
                    ) : notification.isSeen ? (
                      <div className={classes.root} style={{backgroundColor:"#c4c4c4", }}>
                      <Grid item xs={12}>
                          <Typography
                            color="textprimary"
                            style={{ padding: "10px" }}
                          >
                            {Parse(notification.notification_description)}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            color="textSecondary"
                            style={{ padding: "10px" }}
                          >
                            {notification.updated_on &&
                              ChangeDate(notification.updated_on)}
                          </Typography>
                        </Grid>
                      </div>
                    ) : (
                      <div className={classes.root}>
                        <Grid item xs={11}>
                          <Typography
                            onClick={() => {
                              if (
                                notification.notification_type ==
                                  "AddedToGroup" ||
                                notification.notification_type ==
                                  "Group Created"
                              ) {
                                dispatch({ type: CLEAR_GROUP_DATA });
                                navigate(
                                  "/users/groups/browsegroups/activity",
                                  {
                                    state: { GroupId: notification.entity_id },
                                  }
                                );
                                window.sessionStorage.setItem(
                                  "GroupId",
                                  notification.entity_id
                                );
                                dispatch(
                                  EMDoSeenNotificationAction({
                                    id: notification.id,
                                    from: "notication",
                                  })
                                );
                              } else if (
                                notification.notification_type ==
                                  "AddedToChannel" ||
                                notification.notification_type ==
                                  "Channel Created"
                              ) {
                                dispatch({ type: CLEAR_CHANNEL_DATA });
                                navigate(
                                  "/users/channels/browsechannels/activity",
                                  {
                                    state: {
                                      ChannelId: notification.entity_id,
                                    },
                                  }
                                );
                                window.sessionStorage.setItem(
                                  "ChannelId",
                                  notification.entity_id
                                );
                                dispatch(
                                  EMDoSeenNotificationAction({
                                    id: notification.id,
                                    from: "notication",
                                  })
                                );
                              } else if (
                                notification.notification_type ==
                                "AddedToPartnership"
                              ) {
                                navigate("/users/networks/details", {
                                  state: { NetworkId: notification.entity_id },
                                });
                                window.sessionStorage.setItem(
                                  "NetworkId",
                                  notification.entity_id
                                );
                                dispatch(
                                  EMDoSeenNotificationAction({
                                    id: notification.id,
                                    from: "notication",
                                  })
                                );
                              } else if (
                                notification.notification_type == "Commented" ||
                                notification.notification_type == "Posted" ||
                                notification.notification_type == "Tagged"
                              ) {
                                navigate(
                                  `/users/taggedpost/${notification.entity_id}`
                                );
                                dispatch(
                                  EMDoSeenNotificationAction({
                                    id: notification.id,
                                    from: "notication",
                                  })
                                );
                              }
                            }}
                            color="textprimary"
                            style={{ padding: "10px" }}
                          >
                            {Parse(notification.notification_description)}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            color="textSecondary"
                            style={{ padding: "10px" }}
                          >
                            {notification.updated_on &&
                              ChangeDate(notification.updated_on)}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            onClick={() => {
                              dispatch(
                                EMDoSeenNotificationAction({
                                  id: notification.id,
                                  from: "notication",
                                })
                              );
                            }}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </Grid>
                      </div>
                    )
                  )}
              </div>
              {/* </Grid> */}
            </Paper>
          </Grid>
        </Grid>
      </EMPage>
    </InfiniteScroll>
  );
}
