import React, { useState, useEffect, useCallback } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import EMPage from "../../../components/commans/EMPage";
import {
  Grid,
  Paper,
  Typography,
  GridList,
  GridListTile,
  useMediaQuery,
  withWidth,
  isWidthUp,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  EMDoGetChannelsBySearchKeyAction,
  EMDoGetGroupsBySearchKeyAction,
} from "../../../redux/actions/EMCommonActions";
import { useNavigate } from "react-router";
import EMGroupView from "../../../components/user/EMGroupView";
import {
  ADD_SUBTABS_TO_GROUP,
  GET_CHANNELS_BY_SEARCH,
  SAVE_GLOBAL_SEARCH,
} from "../../../redux/constants/UserPlatform/EMNavigationConstant";
import EMSideBar from "../../../components/navigation/EMSideBar";
import InfiniteScroll from "react-infinite-scroll-component";

const useStyles = makeStyles((theme) => ({
  topBar: {
    float: "right",
    display: "flex",
    marginLeft: "59%",
  },
  paper: {
    padding: "2%",
  },
  header: {
    fontWeight: "bolder",
    fontSize: "1.5rem",
  },
  gridListTile: {
    // margin:"auto",

    marginTop: "4px",
    marginBottom: "2%",
    // marginLeft:"1%",
    // marginBottom:"10px",
    borderRadius: "10px",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);",
  },
  root: {
    marginTop: "2%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    // padding:"10px",
    width: "100%",
    height: "100%",
    "&::-webkit-scrollbar": {
      width: "0.2em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#4A4A4A",
      outline: "1px solid slategrey",
    },
  },
}));

function EMSearchChannel(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.navigation || {});
  const navigation = useNavigate();
  const [limit, setLimit] = useState(0);

  const theme = useTheme();

  const matchesMediumScreen = useMediaQuery(theme.breakpoints.up("md"));

  const matchesLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    if (data.filter && data.filter.length > 0) {
    } else {
      dispatch({
        type: GET_CHANNELS_BY_SEARCH,
        payload: {
          response: { results: [], next: null },
          page: 1,
        },
      });
    }
    dispatch({ type: ADD_SUBTABS_TO_GROUP });
  }, [data.filter]);

  const sendQuery = useCallback(async () => {
    if (data.filter && data.filter.length > 0) {
      dispatch(
        EMDoGetChannelsBySearchKeyAction({
          searchText: data.filter,
          limit: limit,
        })
      );
    }
  }, [limit]);

  useEffect(() => {
    sendQuery(limit);
  }, [sendQuery, limit]);

  const handleObserver = useCallback(() => {
    if (data.nextChannels !== null && data.filter && data.filter.length > 0) {
      setLimit((prev) => prev + 10);
    }
  }, [limit, data.nextChannels, data.filter]);

  function getCols(screenWidth) {
    if (isWidthUp("xl", screenWidth)) {
      return 3;
    }

    if (isWidthUp("lg", screenWidth)) {
      return 3;
    }
    if (isWidthUp("md", screenWidth)) {
      return 3;
    }
    if (isWidthUp("sm", screenWidth)) {
      return 2;
    }
    if (isWidthUp("xs", screenWidth)) {
      return 1;
    }
    return 4;
  }
  const cols = getCols(props.width);

  return (
    <InfiniteScroll
      dataLength={data.searchedChannels.length}
      next={handleObserver}
      hasMore={true}
    >
      <EMPage
        title="Channels"
        style={{
          width: "100%",
          paddingLeft: matchesLargeScreen ? "8%" : "4%",
          paddingTop: matchesLargeScreen
            ? "4%"
            : matchesMediumScreen
            ? "6%"
            : "8%",
          paddingRight: matchesLargeScreen ? "8%" : "4%",
          paddingBottom: "2%",
        }}
      >
        <Grid container spacing={3}>
          {matchesMediumScreen ? (
            <Grid item xs={matchesLargeScreen ? 2 : 3}>
              <EMSideBar />
            </Grid>
          ) : null}
          <Grid
            item
            xs={matchesLargeScreen ? 10 : matchesMediumScreen ? 9 : 12}
          >
            <Paper elevation={2} className={classes.paper}>
              <Typography className={classes.header}>
                Search Results For "{data.filter}"
              </Typography>
              <Grid container>
                <Grid item xs={12}>
                  <div className={classes.root}>
                    <GridList
                      cellHeight={180}
                      className={classes.gridList}
                      cols={cols}
                      style={{
                        alignItems: "flex-start",
                      }}
                    >
                      {data.searchedChannels &&
                        data.searchedChannels.map((channel, key) => (
                          <GridListTile
                            key={channel.id}
                            className={classes.gridListTile}
                            style={{ height: "150px" }}
                            // style={{  marginLeft: "1%", width: "23.7%", }}
                          >
                            <EMGroupView
                              route="browse_groups"
                              onStarClick={() => {}}
                              id={channel.id}
                              type={""}
                              number_of_group_users={
                                channel.number_of_channel_users
                              }
                              group_name={channel.channel_name}
                              logo_file={channel.logo_file}
                              isFav={channel.is_fav}
                              created_by={channel.created_by}
                              organization_name={channel.organization_name}
                              users_count={channel.users_count}
                              privacy_type_name={channel.privacy_type_name}
                              group={channel}
                              onCardClick={() => {
                                navigation(
                                  "/users/channels/browsechannels/activity",
                                  { state: { ChannelId: channel.id } }
                                );
                                window.sessionStorage.setItem(
                                  "ChannelId",
                                  channel.id
                                );
                                dispatch({
                                  type: SAVE_GLOBAL_SEARCH,
                                  payload: "",
                                });
                              }}
                            />
                          </GridListTile>
                        ))}
                    </GridList>
                  </div>
                </Grid>
                {/* <Grid item xs={3}></Grid> */}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </EMPage>
    </InfiniteScroll>
  );
}

export default withWidth()(EMSearchChannel);
