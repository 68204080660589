import React from "react";
import {
  Box,
  Button,
  Modal,
  Typography,
  IconButton,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { EMSimpleTextFieldView } from "../../../../components/EMTextFieldView";
import { CloseRounded } from "@material-ui/icons";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  EMDoCreateEmailTemplateAction,
  EMDoUpdateEmailTemplateAction,
  EMDOGetEmailTemplateTypeListAction,
  EMDoGetDefaultEmailTemplateAction,
} from "../../../../redux/actions/EMNetworkAdminActions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import EMTemplateTypeDropdown from "../../UserManagement/commons/EMTemplateTypeDropdown";
import EMTextArea from "../../../../components/commans/EMTextArea";
import { isEmpty } from "lodash-es";
import EMDialogBox from "../../../../components/commans/EMDialogBox";
import { NETWORK_ADMIN_GET_EMAIL_TEMPLATE_BODY } from "../../../../redux/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    height: "auto",
    minWidth: "50%",
    margin: "auto",
    padding: "0px",
    // maxWidth: '100vw',
    maxHeight: "100%",
    position: "fixed",
    top: "50%",
    transform: "translate(0, -50%)",
    width: "60%",
    overflowY: "auto",
    overflowX: "hidden",
  },
  title: {
    margin: 10,
    flexGrow: 1,
    fontWeight: "bold",
    color: "white",
    fontSize: "large",
  },
  form: {
    padding: "8px 36px",
    width: "100%",
  },
  buttonHolder: {
    padding: theme.spacing(3),
  },
}));

export default function EMAddEditEmailTemplates(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [localEntity, setLocalEntity] = React.useState(-1);
  const [emailTemplate, setEmailTemplate] = React.useState();
  const [template, setTemplate] = React.useState("");
  const [error, setError] = React.useState();
  const [emailbodyError, setEmailBodyError] = React.useState();
  const [showDialog, setShowDialog] = React.useState(false);
  const defaultEmailTemplateData = useSelector(
    (state) => state.networkAdmin?.defaultEmailBody
  );
  /* template state update function */
  const templateChangeHandler = (value) => {
    setTemplate(value);
  };

  useEffect(() => {
    if (defaultEmailTemplateData) {
      setTemplate(defaultEmailTemplateData.template_body);
    }
  }, [defaultEmailTemplateData]);

  useEffect(() => {
    if (!isEmpty(emailTemplate)) {
      setError();
    }
  }, [emailTemplate]);

  useEffect(() => {
    if (!isEmpty(template)) {
      setEmailBodyError();
    }
  }, [template]);

  useEffect(
    () => {
      if (props.entity && props.entity.context_type != undefined) {
        let contextData = {
          contextType: props.entity.context_type,
          context: props.entity.context,
        };
        setLocalEntity(props.entity);
        dispatch(EMDOGetEmailTemplateTypeListAction({ contextData }));
      }
    },
    [props.entity],
    [props.open]
  );
  //reset the form after modal is closed
  /* Validation And Formik Values And Handlers */
  const validationSchema = yup.object({
    templateName: yup.string().required("Template Name is required"),
    emailSubject: yup.string().required("Email Subject is required"),
  });

  useEffect(() => {
    if (props.emailTemplateData) {
      if (
        props.isEdit &&
        //emailTemplate == undefined &&
        props.emailTemplateData &&
        props.emailTemplateData["email_template_type"] &&
        props.emailTemplateData["email_template_type"]["id"]
      ) {
        setEmailTemplate(
          props.emailTemplateData &&
            props.emailTemplateData["email_template_type"] &&
            props.emailTemplateData["email_template_type"]["id"]
        );
      }
      if (
        props.isEdit &&
        //template == undefined &&
        props.emailTemplateData["body"]
      ) {
        setTemplate(props.emailTemplateData["body"]);
      }
    }
  }, [props?.emailTemplateData]);

  console.log(props.open);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      templateName:
        (props.emailTemplateData && props.emailTemplateData["template_name"]) ||
        "",
      emailSubject:
        (props.emailTemplateData && props.emailTemplateData["subject"]) || "",
      // is_active : props.emailTemplateData["email_template_type"]["is_active"]
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      
      if (isEmpty(emailTemplate)) {
        setError("Please select template");
      }
      if (isEmpty(template)) {
        setEmailBodyError("Please enter email body ");
      }

      if (template && emailTemplate) {
        props.setDisabled(true);
        setError();
        setEmailBodyError();
        if (props.emailTemplateData && props.emailTemplateData.id > 0) {
          let emailTemplateData = {
            template_name: formik.values.templateName,
            email_templ_type_id:
              (emailTemplate && emailTemplate.id) ||
              props.emailTemplateData.email_template_type.id,
            template_subject: formik.values.emailSubject,
            template_body: template || props.emailTemplateData.body,
            context: props.emailTemplateData.context,
            id: props.emailTemplateData.id,
            contextType: props.entity.context_type,
            context: props.entity.context,
            key: "user_add_edit",
          };
          dispatch(EMDoUpdateEmailTemplateAction(emailTemplateData));
          formik.resetForm();
          setTemplate();
          setEmailTemplate();
          setError();
          setEmailBodyError();
          dispatch({
            type: NETWORK_ADMIN_GET_EMAIL_TEMPLATE_BODY,
            payload: undefined
          });
        } else {
          let emailTemplateData = {
            template_name: formik.values.templateName,
            email_template_type_id: emailTemplate.id,
            subject: formik.values.emailSubject,
            body: template,
          };
          let contextData = {
            contextType: props.entity.context_type,
            context: props.entity.context,
          };
          dispatch(
            EMDoCreateEmailTemplateAction({
              emailTemplateData,
              contextData,
              key: "user_add_edit",
            })
          );
          formik.resetForm();
          setTemplate();
          setEmailTemplate();
          setError();
          setEmailBodyError();
          dispatch({
            type: NETWORK_ADMIN_GET_EMAIL_TEMPLATE_BODY,
            payload: undefined
          });
        }
      } else {
        if (isEmpty(emailTemplate)) {
          setError("Please select template");
        } else {
          setError();
        }
        if (isEmpty(template)) {
          setEmailBodyError("Please enter email body ");
        }else{
          setEmailBodyError("");
        }
        
      }
    },
  });

  return (
    <Modal
      open={props.open}
      onClose={() => {
        formik.resetForm();
        props.setOpenFalse(false);
        setTemplate();
        setEmailTemplate();
        setError();
        setEmailBodyError();
        dispatch({
          type: NETWORK_ADMIN_GET_EMAIL_TEMPLATE_BODY,
          payload: undefined
        });
      }}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box className={classes.root} style={{ outline: "none" }}>
        <form onSubmit={formik.handleSubmit}>
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            style={{
              padding: "0px 4px 0px 18px",
              backgroundColor: "#2F92D6",
              height: "auto",
            }}
          >
            {showDialog ? (
              <EMDialogBox
                shouldOpen={showDialog}
                onSelect={(shouldProcess) => {
                  if (shouldProcess) {
                    let data = {
                      templateId: emailTemplate.id,
                      contextType: props.entity.context_type,
                      contextId: props.entity.context,
                    };
                    dispatch(EMDoGetDefaultEmailTemplateAction(data));
                    setShowDialog(false);
                    props.setOpenFalse(true);
                    // setEmailTemplate();
                  } else {
                    setEmailTemplate();
                    setShowDialog(false);
                  }
                }}
                dialogText={
                  "It looks like you have been editing something. If you change the template type, your changes will be lost."
                }
              />
            ) : null}
            <Typography className={classes.title}>
              {props.titleLable}
            </Typography>
            <IconButton
              onClick={() => {
                formik.resetForm();
                props.setOpenFalse(false);
                setTemplate();
                setEmailTemplate();
                setError();
                setEmailBodyError();
                dispatch({
                  type: NETWORK_ADMIN_GET_EMAIL_TEMPLATE_BODY,
                  payload: undefined
                });
              }}
            >
              <CloseRounded style={{ color: "white" }} />
            </IconButton>
          </Box>
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.form}
            style={{ paddingTop: "16px" }}
          >
            <EMSimpleTextFieldView
              label={"Template Name"}
              id="templateName"
              name="templateName"
              fullwidth
              value={formik.values.templateName}
              onChange={formik.handleChange}
              //   error={
              //     formik.touched.templateName &&
              //     Boolean(formik.errors.templateName)
              //   }
              helperText={
                formik.touched.templateName && formik.errors.templateName
              }
            />
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.form}
          >
            <EMSimpleTextFieldView
              label={"Email Subject"}
              id="emailSubject"
              name="emailSubject"
              fullwidth
              value={formik.values.emailSubject}
              onChange={formik.handleChange}
              //   error={
              //     formik.touched.emailSubject &&
              //     Boolean(formik.errors.emailSubject)
              //   }
              helperText={
                formik.touched.emailSubject && formik.errors.emailSubject
              }
            />
          </Grid>
          <Grid className={classes.form}>
            <EMTemplateTypeDropdown
              onSelect={(id) => {
                if (id && props.entity.context_type && props.entity.context) {
                  if (props.isEdit) {
                    setEmailTemplate(id);
                    setShowDialog(true);
                  }else {
                    setEmailTemplate(id);
                    let data = {
                      templateId: id.id,
                      contextType: props.entity.context_type,
                      contextId: props.entity.context,
                    };
                    dispatch(EMDoGetDefaultEmailTemplateAction(data));
                    
                  }
                } 
              }}
              title={"Email Template"}
              style={{ width: "50%" }}
              initialValue={props.isEdit ? emailTemplate : ""}
              error={error}
            />
          </Grid>

          <Grid className={classes.form}>
            <Typography
              color="#4d4d4d"
              style={{ fontSize: "small", marginBottom: "1%" }}
            >
              {" "}
              Email Body{" "}
            </Typography>
            <EMTextArea
              // value={formik.values.body}
              // changeHandler={templateChangeHandler}
              placeHolder="Email Body"
              toolbarId={1}
              changeHandler={templateChangeHandler}
              value={template}
            />
            <Typography style={{ color: "red", fontSize: 11 }}>
              {emailbodyError}
            </Typography>
          </Grid>

          <Grid
            container
            justify="center"
            alignItems="center"
            direction="row"
            className={classes.buttonHolder}
            spacing={2}
            // onClick={() => {
            //     //checkValidation()
            // }}
          >
            <Grid item>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                type="submit"
                disabled={props.saveClicked}
              >
                <Typography variant="h7">{props.buttonLabel}</Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.button}
                variant="outlined"
                color="secondary"
                onClick={() => {
                  formik.resetForm();
                  props.setOpenFalse(false);
                  setTemplate();
                  setEmailTemplate();
                  setError();
                  setEmailBodyError();
                  dispatch({
                    type: NETWORK_ADMIN_GET_EMAIL_TEMPLATE_BODY,
                    payload: undefined
                  });
                }}
              >
                <Typography variant="h7">Discard</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
}
