import axios from "axios";

//Get Events created by me
const EMDoGetEventsByMe = () => {
    let url = `posts/events/created/`
    return axios.get(url);
}
//Get All my events(including groups events)
const EMDoGetMyAllEvents = () => {
    let url = `posts/events/other/`
    return axios.get(url);
}
//Get upcoming events
const EMDoGetUpcomingEvents = () => {
    let url = `posts/events/upcoming/`
    return axios.get(url);
}
//Get past events
const EMDoGetPastEvents = () => {
    let url = `posts/events/past/`
    return axios.get(url);
}

//Get upcoming events by group filter
const EMDoGetUpcomingEventsByGroupFilter = (groupId) => {
    let url = `posts/events/upcoming/?groups=${groupId}`
    return axios.get(url);
}

//Get past events by group
const EMDoGetPastEventsByGroupFilter = (groupId) => {
    let url = `posts/events/past/?groups=${groupId}`
    return axios.get(url);
}

//Get events for specific month
const EMDoGetMonthEvents = (calendarView) => {
    // 
    let url;
    if (calendarView) {
        url = `posts/events/calendar:month/${calendarView.getFullYear()}/${calendarView.getMonth() + 1}/`
    }
    else { url = `posts/events/upcoming/` }
    return axios.get(url);
}

//Get events for specific month
const EMDoGetWeekEvents = () => {
    let url = `posts/events/calendar:week/2021/7/15`
    return axios.get(url);
}

//Get events for specific month
const EMDoGetDayEvents = () => {
    let url = `posts/events/calendar:day/2021/7/15`
    return axios.get(url);
}

//Get groups for filter
const EMDoGetGroupsForFilter = () => {
    let url = `user/groups-brief/`
    return axios.get(url);
}

//Get Events by group Filter
const EMDoGetEventsByGroupFilter = (calendarView, id) => {
    let url;
    if (calendarView) {
        url = `posts/events/calendar:month/${calendarView.getFullYear()}/${calendarView.getMonth() + 1}/?groups=${id}`
    }
    else { url = `posts/events/upcoming/` }
    return axios.get(url);
}

export default {
    EMDoGetEventsByMe,
    EMDoGetMyAllEvents,
    EMDoGetPastEvents,
    EMDoGetUpcomingEvents,
    EMDoGetMonthEvents,
    EMDoGetWeekEvents,
    EMDoGetDayEvents,
    EMDoGetGroupsForFilter,
    EMDoGetEventsByGroupFilter,
    EMDoGetUpcomingEventsByGroupFilter,
    EMDoGetPastEventsByGroupFilter
}