import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  Box,
  Paper,
  Grid,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { Poll, QuestionAnswer, Help  } from "@material-ui/icons";
import { ReactComponent as VideoIcon } from "../../../../assets/Icon-awesome-video.svg";
import { ReactComponent as ImageIcon } from "../../../../assets/Icon-metro-image.svg";
import { ReactComponent as EventIcon } from "../../../../assets/Icon-material-event-note.svg";
import { CloseRounded } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";

import {
  SHOW_CREATE_POST,
  TO_GET_THE_POLL_FORM,
} from "../../../../redux/constants/UserPlatform/index";
import {
  EMDoGetEventSharedWithGroupsListAction,
  EMDoGetPollSharedWithGroupsListAction,
  EMDoGetPostSharedWithGroupsListAction,
  EMDoGetQuizSharedWithGroupsListAction,
} from "../../../../redux/actions/UserPlatform/Home/EMLatestPostPageAction";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 30,
    paddingBottom: 5,
  },
  typing: {
    padding: "1%",
    height: "50px",
    cursor:"pointer"
  },
  button: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    backgroundColor: "white",
  },
  buttonGrid: {
    marginTop: theme.spacing(1),
    // marginLeft:"-7%",
    // maxWidth: "95%",
  },
}));

export default function EMStartTyping() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectOption, setSelectOption] = useState(false);
  const data = useSelector((state) => state.auth || {});
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const onOpen = () => {
    setSelectOption(true);
  };

  const onClose = () => {
    setSelectOption(false);
  };

  const matchesSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  function StartTypingRenderer(){
    if(data.boolean_group_create_posts_in_group){
      return(
        <Paper onClick={() => {
          dispatch(EMDoGetPostSharedWithGroupsListAction());
          dispatch({
            type: SHOW_CREATE_POST,
            payload: {
              value: true,
              route: "create_post",
            },
          });
        }} elevation={2} className={classes.typing}>
        <Typography
          color="textSecondary"
          style={{ fontSize: "small", fontWeight: "bolder", padding: 10 }}
        >
          Start Typing
        </Typography>
      </Paper>
      )
  }else {
    return null
  }
  }

  return data.boolean_group_create_posts_in_group ||
    data.boolean_group_create_polls_in_group ||
    data.boolean_group_create_events_in_group ||
    data.boolean_group_create_quiz_in_group ? (
    <Paper elevation={2} className={classes.root}>
      <StartTypingRenderer/>
      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={2}
        className={classes.buttonGrid}
      >
        {/* {matchesSmallScreen ? (
          <Grid item align="center">
            <Button
            variant="contained"
             className={classes.button}
            disabled={!data.boolean_group_create_posts_in_group}
            onClick={() => {
              dispatch(EMDoGetPostSharedWithGroupsListAction());
              dispatch({
                type: SHOW_CREATE_POST,
                payload: {
                  value: true,
                  route: "create_post",
                },
              });
            }}
              startIcon={<ImageIcon />}
            >
              post
            </Button>
          </Grid>
        ) : null} */}
        {!matchesSmallScreen ? (
          <>
            <Grid item align="center">
              <Button
                variant="contained"
                className={classes.button}
                disabled={!data.boolean_group_create_posts_in_group}
                onClick={() => {
                  dispatch(EMDoGetPostSharedWithGroupsListAction());
                  dispatch({
                    type: SHOW_CREATE_POST,
                    payload: {
                      value: true,
                      route: "create_post",
                    },
                  });
                }}
                startIcon={<ImageIcon />}
              >
                Image
              </Button>
            </Grid>
            <Grid item align="center">
              <Button
                variant="contained"
                disabled={!data.boolean_group_create_posts_in_group}
                className={classes.button}
                onClick={() => {
                  dispatch(EMDoGetPostSharedWithGroupsListAction());
                  dispatch({
                    type: SHOW_CREATE_POST,
                    payload: {
                      value: true,
                      route: "create_post",
                    },
                  });
                }}
                startIcon={<VideoIcon />}
              >
                video
              </Button>
            </Grid>
            <Grid item align="center">
              <Button
                disabled={!data.boolean_group_create_posts_in_group}
                variant="contained"
                className={classes.button}
                onClick={() => {
                  dispatch(EMDoGetPostSharedWithGroupsListAction());
                  dispatch({
                    type: SHOW_CREATE_POST,
                    payload: {
                      value: true,
                      route: "create_post",
                    },
                  });
                }}
                startIcon={<CloudUploadIcon />}
              >
                Document
              </Button>
            </Grid>{" "}
          </>
        ) : null}

        <Grid item align="center">
          <Button
            variant="contained"
            className={classes.button}
            disabled={!data.boolean_group_create_events_in_group}
            onClick={() => {
              dispatch(EMDoGetEventSharedWithGroupsListAction());
              dispatch({
                type: SHOW_CREATE_POST,
                payload: {
                  value: true,
                  route: "create_event",
                },
              });
            }}
            startIcon={<EventIcon />}
          >
            event
          </Button>
        </Grid>
        <Grid item align="center">
          <Button
            disabled={!data.boolean_group_create_polls_in_group}
            variant="contained"
            className={classes.button}
            onClick={() => {
              dispatch(EMDoGetPollSharedWithGroupsListAction());
              onOpen();
            }}
            startIcon={<Poll />}
          >
            poll
          </Button>

          <Dialog open={selectOption} onClose={onClose} fullWidth={true}>
            <DialogTitle>
              {"Select the Poll Type"}
              <IconButton
                aria-label="close"
                onClick={onClose}
                style={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseRounded />
              </IconButton>
            </DialogTitle>
            <DialogActions>
              <Grid item xs={6} style={{ marginBottom: 15, marginLeft: "12%" }}>
                <Button
                  variant="contained"
                  className={classes.button}
                  style={{ width: window.screen.width / 10.5 }}
                  onClick={() => {
                    setOpen(false);
                    dispatch({
                      type: SHOW_CREATE_POST,
                      payload: {
                        value: true,
                        route: "create_poll",
                      },
                    });
                    dispatch({
                      type: TO_GET_THE_POLL_FORM,
                      payload: true,
                    });
                  }}
                >
                  Poll
                </Button>
              </Grid>
              <Grid item xs={6} style={{ marginBottom: 15 }}>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={() => {
                    setOpen(false);
                    dispatch({
                      type: SHOW_CREATE_POST,
                      payload: {
                        value: true,
                        route: "create_poll",
                      },
                    });
                    dispatch({
                      type: TO_GET_THE_POLL_FORM,
                      payload: false,
                    });
                  }}
                  autoFocus
                >
                  Open Ended
                </Button>
              </Grid>
            </DialogActions>
          </Dialog>
        </Grid>
        <Grid item align="center">
          <Button
            variant="contained"
            className={classes.button}
            disabled={!data.boolean_group_create_quiz_in_group}
            onClick={() => {
              dispatch(EMDoGetQuizSharedWithGroupsListAction());
              dispatch({
                type: SHOW_CREATE_POST,
                payload: {
                  value: true,
                  route: "create_quiz",
                },
              });
            }}
            startIcon={<Help style={{color:"purple"}} />}
          >
            quiz
          </Button>
        </Grid>
      </Grid>
    </Paper>
  ) : null;
}
