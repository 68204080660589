import { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import MaterialTable from "material-table";
import EMIcons from "./EMIcons";
import { useSelector } from "react-redux";
import { findIndex } from 'lodash'
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme=> ({
  colHeader: {
    color: "white",
    "&:hover": {
      color: "white"
    }
  },
 tableWidth: { [theme.breakpoints.up("xs")]: {
    width: "20rem",
  },
  [theme.breakpoints.up("sm")]: {
    width: "40rem",
  },
  [theme.breakpoints.up("md")]: {
    width: "50rem",
  },
  [theme.breakpoints.up("lg")]: {
    width: "100%",
  },}
}));


export default function EMSimpleDatatable({
  columns,
  data,
  options,
  count
}) {
  const uiData = useSelector((state) => state.ui);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [defaultpageSize, setdefaultpageSize] = useState(20);
  const classes = useStyles();

  return (
    <div className={classes.tableWidth} style = {{maxHeight : window.screen.height /3, overflow : "auto"}}>
      <MaterialTable
        icons={EMIcons}
        // isLoading={uiData.loading}
        totalCount={count}
        options={{
          ...options,
          pageSize: pageSize,
          pageSizeOptions: [defaultpageSize*1,defaultpageSize*2,defaultpageSize*3],
          search: false,
          showTitle: false,
          toolbar: null,
          emptyRowsWhenPaging: false,
          showEmptyDataSourceMessage: !uiData.loading,
          draggable : false,
          headerStyle: {
            backgroundColor: "#43455A",
            // color: "white",
            padding : '7px 14px',
            width:100
          },
        }}
        localization={{
          pagination: {
            labelDisplayedRows:`${page+1}-${Math.floor(count/pageSize+1) }`,

            labelRowsSelect: "",
          },
        }}
        components={{
          Container: "div",
        }}
        columns={[
          {
            title: "Sr. No.",
            align: "center",
            render: (rowData) => {
              return (rowData.tableData.id + 1)
            }
          },
          ...columns.map((col) => ({ ...col, 
            title : <Typography className={classes.colHeader}>
              {col.title}
            </Typography> }))]}
        data={data}
      />
    </div>
  );
}
