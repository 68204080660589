import React, {useEffect} from "react";
import { makeStyles } from "@material-ui/core";
import EMTopBar from "../../components/navigation/EMTopBar";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {EMDoGetPrivacyList, EMDoGetNetworkListDetailsAction} from "../../redux/actions/EMNetworkAdminActions"
import {EMDoGetAllGroupListDetailsAction} from "../../redux/actions/EMGroupAdminActions"
import { EMDoGetCommonAdminPanelPermissionsAction, EMDoGetCommonGlobalPermissionsAction, EMDoGetLoggedInUserRolesAction } from "../../redux/actions/EMAuthActions";
import {LOGIN_USER_PERMISSIONS, RESET} from "../../redux/constants"
import { CHANGE_USER_TO_ADMIN } from "../../redux/constants/UserPlatform/EMNavigationConstant";
import { useLocation } from "react-router";
import withNavigate from "../../components/navigation/EMWithNavigate";
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
}));

const DashboardLayout = props => {
  const cssClasses = useStyles();
  const userData = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const location = useLocation()

  let topBar =  <EMTopBar />
  if(userData.is_network_admin||userData.permission_for_partnership||userData.permission_for_organization||userData.is_group_admin||userData.permission_for_group||userData.is_channel_admin||userData.permission_for_channel){
    topBar =   <EMTopBar items = {true}/>
  }

  useEffect(() => {
    // dispatch(EMGetEntityList());
    dispatch(EMDoGetPrivacyList());
    dispatch(EMDoGetCommonAdminPanelPermissionsAction())
    dispatch(EMDoGetCommonGlobalPermissionsAction())
    // dispatch(EMDoGetLoggedInUserRolesAction());
    if(window.location.href.includes("networkAdmin") ||
    window.location.href.includes("groupAdmin") ||
    window.location.href.includes("channelAdmin")){ 
      dispatch({
        type:CHANGE_USER_TO_ADMIN,
        payload:true
      })
    }
  }, [location]);
 
  return (
    <div className={cssClasses.root} >

     {topBar}
    </div>
  );
};

export default DashboardLayout;
