import {
  FormHelperText,
  Radio,
  FormControl,
  Typography,
  FormControlLabel,
  RadioGroup
} from "@material-ui/core";

export default function EMRadioGroup({
  label,
  value,
  onChange,
  data,
  labelKey,
  formik
}) {
  return (
    <FormControl
      component="fieldset"
      error={formik.errors && formik.touched ? true : false}
    >
      <Typography>{label}</Typography>
      <RadioGroup
        row
        value={value}
        aria-label="organizationType"
        name="customized-radios"
        onChange={onChange}
      >
        {data &&
          data.map(type => {
            return (
              <FormControlLabel
                key={type[labelKey]}
                value={type.id}
                control={<Radio />}
                label={type[labelKey]}
              />
            );
          })}
      </RadioGroup>
      <FormHelperText  style = {{color :'red'}}>
        {formik.errors && formik.touched ? formik.errors : ""}
      </FormHelperText>
    </FormControl>
  );
}
