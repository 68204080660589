import axios from "axios";
//Group listing api
const EMDoGetListOfMyGroups = (action) => {
  let url = "";
  url = `user/organization-groups/?limit=4&offset=${action.limit}`;
  return axios.get(url);
};

const EMDoGetListOfMyGroupsBySearch = (action) => {
  let url = "";
  url = `user/organization-groups/?search=${action.searchText}&limit=4&offset=${action.limit}`;
  return axios.get(url);
};

const EMDoGetListOfMyJointGroups = () => {
  let url = "";
  url = `user/partnerships-with-groups/`;
  return axios.get(url);
};

// Member leaves group
const EMDoGetMemberLeaveGroup= (id) => {
  let url = `group/${id}/leave-group/`;
  return axios.delete(url);
};

const EMDoGetListOfMyJointGroupsBySearch = (searchText) => {
  let url = "";
  url = `user/partnerships-with-groups/?search=${searchText}`;
  return axios.get(url);
};

const EMDoGetListOfAllGroups = (action) => {
  
  let url = "";
  url = `user/groups/?limit=10&offset=${action.limit}`;
  return axios.get(url);
};

const EMDoGetListOfAllGroupsBySearch = (searchText) => {
  let url = "";
  url = `user/groups/?search=${searchText}`;
  return axios.get(url);
};

const EMDoGetNetworkFilters = () => {
  let url = "";
  url = `user/network-filter/`;
  return axios.get(url);
};

const EMDoGetNetworksByGroupId = (id) => {
  let url = `/group/${id}/organizations/`
  return axios.get(url)
}


const EMDoGetGroupDetails = (id) => {
  let url = "";
  url = `user/groups/${id}/details/`;
  return axios.get(url);
};

const EMDoGetChannelList = () => {
  let url = "";
  url = `user/channels-brief/`;
  return axios.get(url);
};

const EMDoGetChannelListByGroupId = (groupId) => {
  let url = '';
  url = `user/group/${groupId}/channels-brief/`;
  return axios.get(url)
}

const EMDoGetMembersOfGroup = (id, limit) => {
  let url = "";
  url = `user/groups/${id}/users/?limit=20&offset=${limit}`;
  return axios.get(url);
};

const EMDoGetGroupPosts = (id, page) => {
  let url = "";
  url = `user/posts/group/${id}/?page=${page}`;
  return axios.get(url);
};

// GET request - category filtered post list
const EMDoGetCategoryFilteredPostForGroupList = (id, selectedOption, page) => {
  let url;
  if (selectedOption) {
    url = `user/posts/group/${id}/?category=${selectedOption}&page=${page}`
  } else {
    url = `/posts/?`;
  }

  return axios.get(url);
};

const EMDoGetFilteredGroupsWithPartenershipsAndOrganizations = (action) => {
  
  let url;
      url=`user/groups/?${action.myaction}&limit=10&offset=${action.limit}`
  return axios.get(url);
};

const EMDoGetFilteredGroupMembersWithOrganizationAndPartnerships = (myaction, id, limit) => {
  let url;
  url = `user/groups/${id}/users/?${myaction}&limit=20&offset=${limit}`;
  return axios.get(url);
};

const EMDoAcceptRejectUserRequest = (requestId, myaction) => {
  let url;
  url = `group/join-requests/${requestId}/action:${myaction}/`;
  return axios.patch(url);
}

const EMDoActionForGroup = (myaction, id) => {
  let url;
  url =`group/${myaction}/action:${id}/`
  return axios.patch(url);
}

const EMDoGetUserRequests = (id) => {
  let url;
  url = `group/${id}/join-requests/`
  return axios.get(url)
}

const EMDoRequestToJoinGroup = (id) => {
  let url;
  url = `group/${id}/action:join/`
  return axios.patch(url);
}

const EMDoGetListOfGroupMembersBySearch = (id, searchText, limit) => {
  let url = "";
  url = `user/groups/${id}/users/?search=${searchText}&limit=20&offset=${limit}`;
  return axios.get(url);
};

const EMDoGetEmailNotificationGroupsApi = (action,id) => {
  let url = "";
  url = `group/email-notifications/${id}/`;
  return axios.put(url,action);
};
 
export default {
  EMDoGetListOfMyGroupsBySearch,
  EMDoGetListOfMyJointGroupsBySearch,
  EMDoGetListOfAllGroupsBySearch,
  EMDoGetListOfMyGroups,
  EMDoGetUserRequests,
  EMDoRequestToJoinGroup,
  EMDoGetListOfMyJointGroups,
  EMDoGetListOfAllGroups,
  EMDoGetNetworkFilters,
  EMDoGetGroupDetails,
  EMDoAcceptRejectUserRequest,
  EMDoGetChannelList,
  EMDoGetMembersOfGroup,
  EMDoGetGroupPosts,
  EMDoGetCategoryFilteredPostForGroupList,
  EMDoGetFilteredGroupsWithPartenershipsAndOrganizations,
  EMDoGetFilteredGroupMembersWithOrganizationAndPartnerships,
  EMDoActionForGroup,
  EMDoGetChannelListByGroupId,
  EMDoGetListOfGroupMembersBySearch ,
  EMDoGetMemberLeaveGroup,
  EMDoGetNetworksByGroupId,
  EMDoGetEmailNotificationGroupsApi
};
