export const PROFILE_EDIT_TOGGLE = "profile_edit_toggle"
export const LOGIN_USER_DETAILS = "login_user_details"
export const LOGIN_USER_GROUPS = "login_user_groups"
export const LOGIN_USER_CHANNELS = "login_user_channels"
export const LINK_PROFILE = "link_profile"
export const ORGANISATION_MEMBERS ="organisation_members"
export const OTHER_NETWORKS_MEMBERS = "other_network_members"
export const OTHER_NETWORKS_MEMBERS_FILTERED = "other_network_filtered"
export const SHOW_NEXT_MEMBERS_SEARCHOTHERS = "show_next_members_searchothers"
export const GET_USER_LEVEL_AND_BADGES = "get_user_level_and_badges"
export const DISABLE_ENABLE_SEARCH_MY_COMPANY = "disable_enable_search_my_company"
export const DISABLE_ENABLE_SEARCH_OTHERS = "disable_enable_search_others"