import { Box, IconButton, Typography ,Tooltip} from "@material-ui/core";
import EMSimpleDatatable from "../../../components/datatables/EMSimpleDatatable";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import {
    EMDoUpdateGroudStatusByIdAction,
    EMDoGetAllGroupListDetailsAction
} from "../../../redux/actions/EMGroupAdminActions";
import { useEffect, useState } from "react";
import EMSwitch from "../../../components/commans/EMSwitch";
import { Edit, Settings } from "@material-ui/icons";
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    typo: {
      display: "-webkit-box",
      boxOrient: "vertical",
      lineClamp: 1,
      wordBreak: "break-word",
      overflow: "hidden",
    },
  }));

export default function EMGroupTable({ filter, onEdit, onSettings }) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const data = useSelector(
        state => state.groupAdmin.allGroupListingDetails || {}
    );
    const [limit, setLimit] = useState(15);
    const [offset, setOffset] = useState(0);
    const [order, setOrder] = useState({ id: -1, direction: "asc" });
    const [sortAscending, setSortAscending] = useState(false);
    const userData = useSelector(state=>state.auth)
    const classes = useStyles()
    //const uiData = useSelector((state) => state.ui);

    useEffect(() => {
        if (filter && filter.length > 0) {
          setOffset(0);
        } else if (filter && filter == "") {
          setOffset(offset);
        }
      }, [filter]);

    const columns = [
        {
            title: "Network Name",
            field: "network",
            align:"left",
            render: data=>{
                return (
                    <>
                    <Tooltip title={<h2 style={{ color: "white" }}>{data["network"]}</h2>} placement='top'>
                    <Typography className={classes.typo}>{data["network"] && data["network"]}</Typography>
                    </Tooltip>
                    </>
                  )
                }
        },
        {
            title: "Group Name",
            field: "group_name",
            align:"left",
            render: data=>{
                return (
                    <>
                    <Tooltip title={<h2 style={{ color: "white" }}>{data["group_name"]}</h2>} placement='top'>
                    <Typography className={classes.typo}>{data["group_name"] && data["group_name"]}</Typography>
                    </Tooltip>
                    </>
                  )
                }
        },
        {
            title: "Admin Emails ",
            field: "groupuser_group_id__user__email",
            align:"left",
            sorting: false,
            render: data => {
                let emails = data["users"]
                    .map(user => user["email"]).join(", ");
                return (
                    <>
                    <Tooltip title={<h2 style={{ color: "white" }}>{emails}</h2>} placement='top'>
                    <Typography className={classes.typo}>{emails && emails}</Typography>
                    </Tooltip>
                    </>
                  )
            }
        },

        {
            title: "Status",
            align:"left",
            field: "is_active",
            customSort: (a, b) => a.is_active - b.is_active,
            render: eventData => {
                return (
                    <EMSwitch
                        status={eventData.is_active}
                        dialogText={"Are you sure that you want to activate/deactivate the group?"}
                        isDisabled = {eventData.group_name.indexOf("HQ_HUB") >= 0 ||(!userData.boolean_group_manage_profile_page)}
                        onChange={status => {
                            let newUserData = { ...eventData };
                            let data = {
                                is_active: status
                            }
                            let id = newUserData.id
                            dispatch(EMDoUpdateGroudStatusByIdAction({ data, id }))
                        }}
                    />
                );
            }
        },
        {
            title: "Created By",
            align:"left",
            field: "created_by",
            sorting: true,
            render: data=>{
                return <Typography className={classes.typo}> {data["created_by"]}</Typography>
                }
        },
        {
            title: "Privacy",
            align:"left",
            field: "privacy_type",
            render: data => {
                return <Typography>{data["privacy_type_name"]}</Typography>
            }
        },
        {
            title: "Created on",
            field: "created_on",
            align:"left",
            sorting: true,
            render: data => {
                let date = data["created_on"];
                date = new Date(date)
                date =  moment(date).format('MM/DD/YYYY');
                return (
                    <Typography>{data["created_on"] ? date : ""}</Typography>
                )
            }
        },
        {
            title: "Last Active",
            field: "last_active",
            sorting: true,
            align:"left",
            render: data => {
                let date = data["last_active"];
                date = new Date(date)
                date =  moment(date).format('MM/DD/YYYY');
                return (
                    <Typography>{data["last_active"] ? date : ""}</Typography>
                )
            }
        },
        {
            title: "Action",
            align:"left",
            sorting: false,
            render: data => {
                return (
                    <>
                        <IconButton onClick={() => onSettings(data)} disabled = {!(userData && userData.boolean_group_manage_profile_page)}>
                            <Settings />
                        </IconButton>
                        {/* <IconButton onClick={() => onEdit(data)} disabled = {data.group_name.indexOf("HQ_HUB") >= 0||!(userData && userData.group_manage_users && userData.group_manage_users["edit_flag"])} >
                            <Edit />
                        </IconButton> */}
                    </>
                );
            }
        }
    ];


    const updateData = query => {
        if(query){
        
            dispatch(EMDoGetAllGroupListDetailsAction(query));
        }

    };

    useEffect(() => {
        updateData("?");
    }, []);

    useEffect(() => {
        const query = [];
        if (limit !== 0) {
            query.push(`limit=${limit}`);
        }
        if (filter) {
            query.push(`search=${filter}`);
        }
        if (offset !== 0) {
            query.push(`offset=${offset}`);
        }
        if (order.id !== -1) {
            if (sortAscending === false) {
                query.push(
                    `ordering=${order.direction === "desc" ? "-" : ""}${columns[order.id - 1].field
                    }`
                );
                setSortAscending(true)
            } else {
                // order.direction = "desc"
                query.push(
                    `ordering=${order.direction === "desc" ? "-" : ""}${columns[order.id - 1].field
                    }`
                );
                setSortAscending(false)
            }
        }
        updateData("?" + query.join("&"));
    }, [limit, filter, offset, order]);

    return (
        <Box p={2}>
            {
                <EMSimpleDatatable
                filter={filter}
                    columns={columns}
                    data={data["results"] || []}
                    count={data["count"] || 0}
                    updatePageAndPageSizeAndOrder={(page, pageSize, order) => {
                        setOffset(page * limit);
                        setLimit(pageSize);
                        setOrder(order)
                    }}
                    options={{
                        headerStyle: {
                            backgroundColor: theme.palette.secondary.main,
                            color: theme.palette.common.white,
                            fontWeight: "bold"
                        }
                    }}
                />
            }
        </Box>
      );
}
