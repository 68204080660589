import React, { useState, useEffect, useRef } from "react";
import EMPage from "../../../components/commans/EMPage";
import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Box,
  Button,
  makeStyles,
  Grid,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Menu,
  Tooltip,
  Hidden,
} from "@material-ui/core";
import {
  Chart,
  BarSeries,
  Title,
  ArgumentAxis,
  ValueAxis,
  Legend,
} from "@devexpress/dx-react-chart-material-ui";
import {
  Animation,
  Stack,
  EventTracker,
  ValueScale,
} from "@devexpress/dx-react-chart";
import { Typography } from "@material-ui/core";
import EMSelect from "../../../components/commans/EMDropdown";
import { find, findIndex } from "lodash";
import EMDatePicker from "../../../components/commans/EMDatePicker";
import { useFormik, Field, Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { EMDoGetNetworkListForDashboardAndReportsAction } from "../../../redux/actions/EMAdminActions";
import {
  EMDoGetNetworkDashboardInfo,
  EMDoGetNetworkOrgGrpChnForNetworkAction,
} from "../../../redux/actions/EMNetworkAdminActions";
import moment from "moment";
import { NETWORK_ADMIN_GET_DASHBOARD_DATA } from "../../../redux/constants";

const useStyles = makeStyles((theme) => ({
  Title: {
    textAlign: "center",
    fontWeight: "bolder",
    fontSize: "large",
  },

  circle: {
    [theme.breakpoints.up("xs")]: {
      lineHeight: "100px",
      fontWeight: "bolder",
      color: "#fff",
      position: "relative",
      width: "3rem",
      paddingBottom: "3rem",
      background: "#000080",
      borderRadius: "50%",
    },
    [theme.breakpoints.up("sm")]: {
      lineHeight: "100px",
      fontWeight: "bolder",
      color: "#fff",
      position: "relative",
      width: "70%",
      paddingBottom: "70%",
      background: "#000080",
      borderRadius: "50%",
    },
    [theme.breakpoints.up("md")]: {
      lineHeight: "100px",
      fontWeight: "bolder",
      color: "#fff",
      position: "relative",
      width: "70%",
      paddingBottom: "70%",
      background: "#000080",
      borderRadius: "50%",
    },
    [theme.breakpoints.up("lg")]: {
      lineHeight: "100px",
      fontWeight: "bolder",
      color: "#fff",
      position: "relative",
      width: "70%",
      paddingBottom: "70%",
      background: "#000080",
      borderRadius: "50%",
    },
  },
  circleText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    margin: "0",
    fontSize: "100%",
    // marginTop:"2.5rem"
    [theme.breakpoints.up("xs")]: {
      //width: "12ch",
      fontSize: "15px",
    },
    [theme.breakpoints.up("sm")]: {
      //width: "12ch",
      fontSize: "25px",
    },
    [theme.breakpoints.up("md")]: {
      //width: "12ch",
      fontSize: "25px",
    },
    [theme.breakpoints.up("lg")]: {
      //width: "12ch",
      fontSize: "35px",
    },
  },
  typography: {
    fontWeight: "bold",
    fontSize: "100%",
    // marginTop:"2.5rem"
    [theme.breakpoints.up("xs")]: {
      //width: "12ch",
      fontSize: "7px",
    },
    [theme.breakpoints.up("sm")]: {
      //width: "12ch",
      fontSize: "10px",
    },
    [theme.breakpoints.up("md")]: {
      //width: "12ch",
      fontSize: "13px",
    },
    [theme.breakpoints.up("lg")]: {
      //width: "12ch",
      fontSize: "16px",
    },
  },
  flex: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    [theme.breakpoints.up("lg")]: {
      display: "flex",
    },
  },
  grid: {
    // borderBlock: "5px solid #2F92D6",
    // borderLeft: "5px solid #2F92D6",
    // borderRight: "5px solid #2F92D6",
    // borderRadius: "5px",
  },
}));

export default function EMPlatformDashboard(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [networkList, setNetworkList] = useState([]);
  const networkadminData = useSelector((state) => state.networkAdmin || {});
  const [invitationData, setInvitationData] = useState([]);
  const [groupStats, setgroupStats] = useState([]);
  const [targetItem, setTargetItem] = useState(undefined);
  const [usageData, setUsageData] = useState();
  const myRef = useRef();
  const [anchorRef, setElRefs] = React.useState([]);
  const [open, setOpen] = useState(false);
  const [widthDropdown, setWidth] = useState("80%");
  const [domainForInvitationPerformance, setDomainForInvitationPerformance] =
    useState(() => () => [0, 10]);
  const [domainForPlatformUsage, setDomainForPlatformUsage] = useState(
    () => () => [0, 10]
  );
  const [domainForGroupStats, setDomainForGroupStats] = useState(() => () => [
    0, 10,
  ]);

  useEffect(() => {
    dispatch(EMDoGetNetworkOrgGrpChnForNetworkAction());
    dispatch({
      type: NETWORK_ADMIN_GET_DASHBOARD_DATA,
      payload: undefined,
    });
  }, []);

  useEffect(() => {
    setInvitationData([]);
    setgroupStats([]);
    setUsageData();
    if (networkadminData.dashboardDataNetwork) {
      setInvitationData(
        networkadminData.dashboardDataNetwork[0].invitation_performance_data
      );
      setgroupStats(networkadminData.dashboardDataNetwork[0].group_stats);

      setUsageData(
        networkadminData.dashboardDataNetwork[0].platform_usage_data
      );
      const maxInvitationPerformance =
        networkadminData.dashboardDataNetwork[0].invitation_performance_data.reduce(
          (p, c) => (p.users > c.users ? p : c)
        );
      const maxActiveUser =
        networkadminData.dashboardDataNetwork[0].platform_usage_data.reduce(
          (p, c) => (p.active_users > c.active_users ? p : c)
        );
      const maxInvitedUser =
        networkadminData.dashboardDataNetwork[0].platform_usage_data.reduce(
          (p, c) => (p.invited_users > c.invited_users ? p : c)
        );
      if (maxInvitationPerformance) {
        let value = maxInvitationPerformance.users;
        value < 10
          ? setDomainForInvitationPerformance(() => () => [0, 10])
          : setDomainForInvitationPerformance(() => () => [0, value + 2]);
      }
      if (maxActiveUser && maxInvitedUser) {
        let value =
          maxActiveUser.active_users > maxInvitedUser.invited_users
            ? maxActiveUser.active_users
            : maxInvitedUser.invited_users;
        value < 10
          ? setDomainForPlatformUsage(() => () => [0, 10])
          : setDomainForPlatformUsage(() => () => [0, value + 2]);
      }
      if(networkadminData.dashboardDataNetwork[0].group_stats.length > 0){
        const maxGroup =
        networkadminData.dashboardDataNetwork[0].group_stats.reduce(
          (p, c) => (p.users > c.users ? p : c)
        );
        if (maxGroup) {
          let value = maxGroup.users;
          value < 10
            ? setDomainForGroupStats(() => () => [0, 10])
            : setDomainForGroupStats(() => () => [0, value + 2]);
        }
      }
    }
  }, [networkadminData]);

  useEffect(() => {
    let organizationsArray = [];
    let partnershipsArray = [];
    if (networkadminData && networkadminData.dashboardEntityList) {
      if (
        networkadminData.dashboardEntityList &&
        networkadminData.dashboardEntityList[0] &&
        networkadminData.dashboardEntityList[0].partnerships.length > 0
      ) {
        partnershipsArray =
          networkadminData.dashboardEntityList[0].partnerships.map(
            (part, index) => ({
              ...part,
              isPartnership: true,
              newId: index + 1,
              networkName: part.partnership_name,
            })
          );
      }

      let newIndex = partnershipsArray.length;
      organizationsArray =
        networkadminData.dashboardEntityList &&
        networkadminData.dashboardEntityList[0] &&
        networkadminData.dashboardEntityList[0].organizations.length > 0 &&
        networkadminData.dashboardEntityList[0].organizations.map(
          (org, index) => ({
            ...org,
            isPartnership: false,
            newId: newIndex + index + 1,
            networkName: org.organization_name,
          })
        );
      var combinedArray = [...partnershipsArray, ...organizationsArray];
      function SortArray(x, y) {
        if (x.networkName < y.networkName) {
          return -1;
        }
        if (x.networkName > y.networkName) {
          return 1;
        }
        return 0;
      }
      var sortedArray = combinedArray.sort(SortArray);
      setNetworkList([...sortedArray]);
    }
  }, [networkadminData]);

  const validationSchema = yup.object({
    startDate: yup.date("Enter valid date").required("start date is required"),
    endDate: yup
      .date("Enter valid date")
      .required("end date is required")
      .min(yup.ref("startDate"), "End Date Must be After Start Date")
      .test("", "end date should not be greater than 1 year", (val, props) => {
        const expiryDate = moment(val);
        const enteredDate = moment(props.parent.startDate);
        const tmpExpiryDate = moment(enteredDate).add(365, "days");

        if (!tmpExpiryDate.isBefore(expiryDate)) {
          return true;
        }
      }),
    selectedNetwork: yup
      .string("Please select the network")
      .required("Please select the network"),
  });

  let date = new Date();
  date.setMonth(date.getMonth() + 1);

  const formik = useFormik({
    initialValues: {
      startDate: new Date(),
      endDate: date,
      selectedNetwork: "",
    },
    validationSchema: validationSchema,
    onSubmit: (e) => {
      function formatDate(date) {
        var d = new Date(date),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
      }

      let result = networkList.find((obj) => {
        return obj.newId === formik.values.selectedNetwork;
      });

      let requiredData = {
        start_date: formatDate(formik.values.startDate),
        end_date: formatDate(formik.values.endDate),
        organizationOrPartnershipId: result && result.id,
        isPartnership: result && result.isPartnership,
      };
      dispatch(EMDoGetNetworkDashboardInfo({ requiredData: requiredData }));
    },
  });

  const ArgumentLabel = (props, index) => {
    const { text } = props;

    props = {
      ...props,
      text: text.slice(0, 3),
    };
    return (
      <>
        <Tooltip title={<h2 style={{ color: "white" }}>{text}</h2>}>
          <ArgumentAxis.Label {...props} />
        </Tooltip>
      </>
    );
  };

  window.addEventListener("resize", () => {
    let width = document.body.clientWidth;

    if (width <= 600) {
      //Close Drawer when screen size medium
      setWidth("100%");
    } else {
      setWidth("80%");
    }
  });
  return (
    <EMPage title="Dashboard" style={{ padding: "2%" }}>
      <div className={classes.grid}>
        <Paper elevation={2} className={classes.flex}>
          <form
            onSubmit={formik.handleSubmit}
            style={{ width: "100%", padding: "1%", display: "inline-block" }}
          >
            <Grid
              container
              spacing={3}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={6} sm={6} md={3} style={{}}>
                {/* <Typography style={{ fontWeight: "bolder", fontSize: "large" }}>
                  Choose time period
                </Typography> */}
                {/* &nbsp; */}
                <EMDatePicker
                  label={"Start Date"}
                  id={"Start Date"}
                  value={formik.values.startDate}
                  onSelectDate={(date) => {
                    formik.setFieldValue("startDate", date);
                  }}
                  minDate={new Date("01/01/1960")}
                  error={
                    formik.touched.startDate && Boolean(formik.errors.startDate)
                  }
                  helperText={
                    formik.touched.startDate && formik.errors.startDate
                  }
                />
              </Grid>
              <Grid item xs={6} sm={6} md={3} style={{}}>
                {/* <div ></div>&nbsp; */}
                <EMDatePicker
                  label={"End Date"}
                  id={"End Date"}
                  value={formik.values.endDate}
                  onSelectDate={(date) => {
                    formik.setFieldValue("endDate", date);
                  }}
                  minDate={formik.values.startDate}
                  error={
                    formik.touched.endDate && Boolean(formik.errors.endDate)
                  }
                  helperText={formik.touched.endDate && formik.errors.endDate}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} style={{}}>
                <FormControl style={{ width: widthDropdown }}>
                  <InputLabel id="select-network" style={{ color: "#4d4d4d" }}>
                    Network
                  </InputLabel>
                  <Select
                    labelId="select-network"
                    id="select-network"
                    value={formik.values.selectedNetwork}
                    label="Select Network"
                    // error={
                    //   formik.errors.selectedNetwork &&
                    //     formik.touched.selectedNetwork
                    //     ? true
                    //     : false
                    // }
                    InputLabelProps={{ style: { color: "#4d4d4d" } }}
                    FormHelperTextProps={{ style: { color: "red" } }}
                    helperText={
                      formik.touched.selectedNetwork &&
                      formik.errors.selectedNetwork
                    }
                    // helperText={formik.touched.selectedNetwork && formik.errors.selectedNetwork}
                    onChange={(selected) => {
                      formik.setFieldValue(
                        "selectedNetwork",
                        selected.target.value
                      );
                    }}
                  >
                    {networkList &&
                      networkList.length > 0 &&
                      networkList.map((network) => (
                        <MenuItem value={network.newId}>
                          {network.networkName}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText style={{ color: "red" }}>
                    {formik.errors.selectedNetwork &&
                    formik.touched.selectedNetwork
                      ? formik.errors.selectedNetwork
                      : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3} style={{ textAlign: "center" }}>
                <Button type="submit" variant="contained" color="primary">
                  Apply
                </Button>
              </Grid>
            </Grid>
          </form>
          <Hidden smDown>
            {networkadminData.dashboardDataNetwork ? (
              <Grid
                container
                spacing={3}
                justifyContent="center"
                alignItems="center"
                style={{ width: "40%", padding: "1%" }}
              >
                <Grid
                  container
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item xs={6} sm={6} md={6}>
                    <div className={classes.circle}>
                      <div className={classes.circleText}>
                        {networkadminData.dashboardDataNetwork[0].org_groups}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <Typography className={classes.typography}>
                      Network Groups
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item xs={6} sm={6} md={6}>
                    <div className={classes.circle}>
                      <div className={classes.circleText}>
                        {networkadminData.dashboardDataNetwork[0].org_channels}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <Typography className={classes.typography}>
                      Network Channels
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
          </Hidden>
          <Hidden lgUp mdUp>
            {networkadminData.dashboardDataNetwork ? (
              <Grid
                container
                spacing={3}
                justifyContent="flex-start"
                alignItems="center"
                style={{ width: "100%", padding: "1%" }}
              >
                <Grid
                  container
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item xs={6} sm={6} md={6}>
                    <div className={classes.circle}>
                      <div className={classes.circleText}>
                        {networkadminData.dashboardDataNetwork[0].org_groups}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <Typography className={classes.typography}>
                      Network Groups
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item xs={6} sm={6} md={6}>
                    <div className={classes.circle}>
                      <div className={classes.circleText}>
                        {networkadminData.dashboardDataNetwork[0].org_channels}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <Typography className={classes.typography}>
                      Network Channels
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
          </Hidden>
        </Paper>
        {invitationData.length > 0 ? (
          <Grid container spacing={1} style={{ paddingTop: "1%" }}>
            <Grid item xs={12} sm={12} md={6}>
              <Paper elevation={2}>
                <Typography
                  style={{
                    textAlign: "center",
                    fontWeight: "bolder",
                    fontSize: "large",
                  }}
                >
                  Invitation Performance
                </Typography>
                <Grid
                  alignItems="center"
                  justifyContent="center"
                  container
                  direction="row"
                  style={{ height: "100%" }}
                >
                  <Grid item xs={2}>
                    <Typography
                      style={{
                        transform: "rotate(-90deg)",
                        fontWeight: "bolder",
                        fontSize: "large",
                        // height:"10rem",
                        // width:"10rem"
                      }}
                    >
                      No. of Users
                    </Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Chart data={invitationData}>
                      <ValueScale modifyDomain={domainForInvitationPerformance} />
                      <ArgumentAxis labelComponent={ArgumentLabel} />
                      <ValueAxis />

                      <BarSeries
                        valueField="users"
                        argumentField="invitation_count"
                      />

                      <Animation />
                    </Chart>
                  </Grid>
                </Grid>
                <Typography
                  style={{
                    textAlign: "center",
                    fontWeight: "bolder",
                    fontSize: "large",
                  }}
                >
                  No. of Invitations Required To Join
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Paper elevation={2}>
                <Typography
                  style={{
                    textAlign: "center",
                    fontWeight: "bolder",
                    fontSize: "large",
                  }}
                >
                  Platform Usage
                </Typography>
                <Grid
                  alignItems="center"
                  justifyContent="center"
                  container
                  direction="row"
                >
                  <Grid item xs={2}>
                    <Typography
                      style={{
                        transform: "rotate(-90deg)",

                        fontWeight: "bolder",
                        fontSize: "large",
                      }}
                    >
                      Users
                    </Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Chart data={usageData}>
                    <ValueScale modifyDomain={domainForPlatformUsage} />
                      <ArgumentAxis labelComponent={ArgumentLabel} />
                      <ValueAxis min={1} />

                      <BarSeries
                        name="Invited Users"
                        valueField="invited_users"
                        argumentField="date"
                        color="#2F92D6"
                      />
                      <BarSeries
                        name="Active Users"
                        valueField="active_users"
                        argumentField="date"
                        color="#000080"
                      />
                      <Animation />
                      <Legend position="right" />

                      <Stack />
                    </Chart>
                  </Grid>
                </Grid>
                <Typography
                  style={{
                    textAlign: "center",
                    fontWeight: "bolder",
                    fontSize: "large",
                  }}
                >
                  Month
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Paper elevation={2}>
                <Typography
                  style={{
                    textAlign: "center",
                    fontWeight: "bolder",
                    fontSize: "large",
                  }}
                >
                  Group Stats
                </Typography>
                <Grid
                  alignItems="center"
                  justifyContent="center"
                  container
                  direction="row"
                >
                  <Grid item xs={2}>
                    <Typography
                      style={{
                        transform: "rotate(-90deg)",

                        fontWeight: "bolder",
                        fontSize: "large",
                      }}
                    >
                      Users
                    </Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Chart data={groupStats}>
                    <ValueScale modifyDomain={domainForGroupStats} />
                      <ArgumentAxis labelComponent={ArgumentLabel} />
                      <ValueAxis />

                      <BarSeries
                        valueField="users"
                        argumentField="group_name"
                      />

                      <Animation />
                    </Chart>
                  </Grid>
                </Grid>
                <Typography
                  style={{
                    textAlign: "center",
                    fontWeight: "bolder",
                    fontSize: "large",
                  }}
                >
                  Group
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        ) : null}
      </div>
    </EMPage>
  );
}
