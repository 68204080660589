import { AUTH_LOGOUT, RESET } from "../../../constants";
import {
  TO_GET_THE_POLL_FORM,
  SHOW_CREATE_POST,
  USER_IMAGES_UPLOAD,
  USER_IMAGES_DELETE,
  USER_VIDEOS_UPLOAD,
  USER_VIDEOS_DELETE,
  USER_DOCUMENT_UPLOAD,
  USER_DOCUMENT_DELETE,
  SHOW_USER_POSTS,
  CHANNEL_COMPONENT_POP_UP,
  LIST_USER_POSTS,
  USER_EXPAND_TEXT,
  USER_EXPAND_COMMENT,
  USER_SHOW_COMMENT,
  POST_CATEGORY_SELECTED_DATA,
  POST_SHOW_HASHTAG,
  POST_NEW_COMMENT,
  POST_HASHTAG_SELECTED,
  SUBMIT_NEW_COMMENT,
  POST_SHOW_TAGPEOPLE,
  POST_TAGPEOPLE_SELECTED,
  POST_EMOJI_SELECTED,
  POST_SHOW_EMOJI,
  POST_SHOW_MORE,
  SHOW_POST_EDIT,
  POST_SHOW_MORE_SELECTED,
  CHANNEL_PREVIEW_LIST_SUCCESS,
  LIKE_DISLIKE_PIN_UNPIN_POSTS,
  SHOW_POST_MEDIA_PREVIEW,
  LIST_SHARE_WITH_GROUP,
  SHOW_NEXT_POSTS,
  LIST_USER_POSTS_CATEGORY_SELECTED,
  LIST_HASHTAG,
  LIST_MENTIONS,
  POST_CATEGORY_DATA,
  DELETE_POST,
  ADD_NEW_POST,
  CLEAR_POST_FROM_STATE,
  OPEN_DELETE_CONFIRMATION_DIALOG,
  LIKE_DISLIKE_POSTS,
  POST_IMAGES,
  POST_VIDEOS,
  POST_DOCUMENT,
  POST_IMAGES_DELETE,
  POST_VIDEOS_DELETE,
  POST_DOCUMENT_DELETE,
  DELETE_POST_COMMENT,
  USER_EXTERNAL_VIDEO_UPLOAD,
  USER_EXTERNAL_VIDEO_DELETE,
  POST_EXTERNAL_VIDEO_UPLOAD,
  POST_EXTERNAL_VIDEO_DELETE,
  GET_MENTIONS_FOR_POST,
  SUBMIT_POLL,
  SUBMIT_OPEN_POLL,
  GET_USER_POINTS,
  GET_LEADERBOARD,
  SUBMIT_QUIZ,
  PLAY_VIDEO_BY_ID,
  CHANGE_LIKE_STATUS_OF_POST,
  CHANGE_LIKE_BUTTON_STATE,
} from "../../../constants/UserPlatform/index";

import { cloneDeep, findIndex, find } from "lodash";
import { ReactComponent as BlueIcon } from "../../../../assets/blue.svg";
import { ReactComponent as GreenIcon } from "../../../../assets/green.svg";
import { ReactComponent as YellowIcon } from "../../../../assets/yellow.svg";
import { ReactComponent as OrangeIcon } from "../../../../assets/orange.svg";
import { ReactComponent as PurpleIcon } from "../../../../assets/purple.svg";
import { ReactComponent as PinkIcon } from "../../../../assets/pink.svg";
import { ReactComponent as EditIcon } from "../../../../assets/Icon-material-edit.svg";
import ShareIcon from "@material-ui/icons/Share";
import DeleteIcon from "@material-ui/icons/Delete";

const initialState = {
  showCommentsGlobal: false,
  channelList: [],
  numberOfGroups: 0,
  numberOfChannels: 0,
  showCreatePost: {},
  images: [],
  documents: [],
  pageofPosts: undefined,
  videos: [],
  externalVideos: [],
  comments: "",
  posts: [],
  pollWithOptions: undefined,
  channelComponent: {},
  categories: [
    { id: 1, categoryName: "Text" },
    { id: 2, categoryName: "Images & Videos" },
    { id: 3, categoryName: "Documents" },
    { id: 4, categoryName: "Events" },
    { id: 5, categoryName: "Polls" },
    { id: 5, categoryName: "Quiz" },
  ],
  categorySelected: "",
  postHashTags: [
    { id: "reg", display: "region" },
    { id: "endreg", display: "endregion" },
  ],
  postAtMentions: [],
  postMoreOptions: [
    { name: "Edit Post", icon: <EditIcon /> },
    { name: "Share with Groups", icon: <ShareIcon /> },
    { name: "Delete Post", icon: <DeleteIcon /> },
  ],
  postMoreOptionsForQuiz: [
    { name: "Edit Quiz", icon: <EditIcon /> },
    { name: "Share with Groups", icon: <ShareIcon /> },
    { name: "Delete Quiz", icon: <DeleteIcon /> },
  ],
  postMoreOptionsForPoll: [
    { name: "Edit Poll", icon: <EditIcon /> },
    { name: "Share with Groups", icon: <ShareIcon /> },
    { name: "Delete Poll", icon: <DeleteIcon /> },
  ],
  postMoreOptionsForEvent: [
    { name: "Edit Event", icon: <EditIcon /> },
    { name: "Share with Groups", icon: <ShareIcon /> },
    { name: "Delete Event", icon: <DeleteIcon /> },
  ],
  postSharedWithGroups: [],
  next: null,
  singleEventDetails: {},
  nextLeaderBoard: undefined,
  leaderBoard: [],
};

export default function EMLatestPageReducer(state = initialState, action) {
  let newState = cloneDeep(state);
  switch (action.type) {
    /* Latest Post -index page reducers */
    // List Posts - from server
    case LIST_USER_POSTS: {
      newState["next"] = action.payload.response.next;
      if (action.payload.page == 1) {
        newState["posts"] = action.payload.response.results;
      } else {
        newState["posts"] = newState["posts"].concat(
          action.payload.response.results
        );
      }

      newState["posts"].map((post) => {
        post["userName"] = post["firstName"] + " " + post["lastName"];
        post["expandText"] = false;
        post["expandPostComment"] = false;
        post["showComments"] = false;
        post["showHashTag"] = false;
        post["showTagPeople"] = false;
        post["showEmoji"] = false;
        post["mediaView"] = false;
        post["likeButtonDisabled"] = false
        let start = new Date(post["event_start_date"]);
        let end = new Date(post["event_end_date"]);
        function ChangeDate(date) {
          var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

          if (month.length < 2) month = "0" + month;
          if (day.length < 2) day = "0" + day;

          return [year, month, day].join("-");
        }
        function ChangeTime(date) {
          return (
            ("00" + date.getHours()).slice(-2) +
            ":" +
            ("00" + date.getMinutes()).slice(-2) +
            ":" +
            ("00" + date.getSeconds()).slice(-2)
          );
        }

        function showTimeZone(date) {
          const dateAsString = date.toString();
          const timezone = dateAsString.match(/\(([^\)]+)\)$/)[1];
          return timezone;
        }
        post["timeZone"] = showTimeZone(start);
        post["event_start"] = ChangeDate(start);
        post["event_end"] = ChangeDate(end);
        post["event_start_time"] = ChangeTime(start);
        post["event_end_time"] = ChangeTime(end);
        post["showMore"] = false;

        post["channel_details"].map((channel) => {
          if (channel.channel_name.includes("MAIN_CHANNEL")) {
            post["postFrom"] = "home";
          } else {
            post["postFrom"] = "channel";
          }
        });

        post["shouldOpen"] = false;
        post["images"].map((img) => {
          img["type"] = "images";
          img["isPlaying"] = false;
        });
        post["videos"].map((video) => {
          video["type"] = "videos";
          video["isPlaying"] = false;
        });
        post["video_links"].map((video) => {
          video["type"] = "video_link";
          video["isPlaying"] = false;
        });
        post["documents"].map((doc) => {
          doc["type"] = "documents";
        });
        post["totalMedia"] = post["images"].concat(post["videos"]);
        post["totalCombinedMedia"] = post["totalMedia"].concat(
          post["video_links"]
        );
        // if(post["totalMedia"].length > 10) {
        //   post["totalCombinedMedia"] = post["totalMedia"].slice(0,10)
        // } else if (post["totalMedia"].length < 10) {
        //   post["totalCombinedMedia"] = post["totalMedia"]
        // }
        post["postAtMentions"] = [];
        post["editPost"] = {
          showEditPost: false,
          labels: { title: "", button: "" },
        };
        post["newComment"] = "";
        post["owner"] =
          post.userName == action.payload.userData.name ? true : false;
        let postCategory = post["category"];
        post["type"] = post["category"];

        newState["categories"].map((category) => {
          if (
            category.category_name &&
            category.category_name.includes(postCategory)
          ) {
            post["category"] = category.icon;
          }
        });
      });
      return newState;
    }

    case PLAY_VIDEO_BY_ID: {
      let postId = action.payload.postId;
      let postIndex = findIndex(newState["posts"], { id: postId });
      let videoId = action.payload.videoId;
      let media = action.payload.media;
      let previewOpen = action.payload.fromPreview;
      ;
      if (previewOpen == false) {
        if (postIndex >= 0) {
          let videoIndex = findIndex(
            newState["posts"][postIndex].totalCombinedMedia,
            { id: videoId }
          );
          if (videoIndex >= 0) {
            newState["posts"][postIndex].totalCombinedMedia = newState["posts"][
              postIndex
            ].totalCombinedMedia.map((item) => ({ ...item, isPlaying: false }));
            let upcomingMedia = media;
            newState["posts"][postIndex].totalCombinedMedia[videoIndex] =
              upcomingMedia;
          }
        }
      } else {
        if (postIndex >= 0) {
          if (media.type == "videos") {
            let videoIndex = findIndex(newState["posts"][postIndex].videos, {
              id: videoId,
            });
            if (videoIndex >= 0) {
              newState["posts"][postIndex].videos = newState["posts"][
                postIndex
              ].videos.map((item) => ({ ...item, isPlaying: false }));
              let upcomingMedia = media;
              newState["posts"][postIndex].videos[videoIndex] = upcomingMedia;
              newState["posts"][postIndex].totalCombinedMedia = newState["posts"][
                postIndex
              ].totalCombinedMedia.map((item) => ({ ...item, isPlaying: false }));
            }
          } else {
            let videoIndex = findIndex(
              newState["posts"][postIndex].video_links,
              { id: videoId }
            );
            if (videoIndex >= 0) {
              newState["posts"][postIndex].video_links = newState["posts"][
                postIndex
              ].video_links.map((item) => ({ ...item, isPlaying: false }));
              let upcomingMedia = media;
              newState["posts"][postIndex].video_links[videoIndex] =
                upcomingMedia;
                newState["posts"][postIndex].totalCombinedMedia = newState["posts"][
                  postIndex
                ].totalCombinedMedia.map((item) => ({ ...item, isPlaying: false }));
            }
          }
        }
      }

      return newState;
    }

    case SUBMIT_POLL: {
      let response = action.payload.poll_details;
      response.map((x) => {
        let index = newState["posts"].findIndex((d) => d.id === x.id);
        if (index >= 0) {
          newState["posts"][index].poll_details = x;
        }
      });
      return newState;
    }

    case SUBMIT_QUIZ: {
      let response = action.payload.quiz_details;
      response.map((x) => {
        let index = newState["posts"].findIndex((d) => d.id === x.id);
        if (index >= 0) {
          newState["posts"][index].quiz_details = x;
        }
      });
      return newState;
    }

    case SUBMIT_OPEN_POLL: {
      let response = action.payload.poll_details;
      response.map((x) => {
        let index = newState["posts"].findIndex((d) => d.id === x.id);
        if (index >= 0) {
          newState["posts"][index].open_ended_question_details.poll_details = x;
        }
      });
      return newState;
    }

    case GET_MENTIONS_FOR_POST: {
      newState["postAtMentions"] = action.payload;
      return newState;
    }

    case CHANNEL_COMPONENT_POP_UP: {
      newState["channelComponent"]["value"] = action.payload.value;
      newState["channelComponent"]["id"] = action.payload.id;
      return newState;
    }
    //category
    case POST_CATEGORY_DATA: {
      newState["categories"] = action.payload;
      newState["categories"].map((category) => {
        switch (category.category_name) {
          case "Text": {
            category["icon"] = <YellowIcon />;
            break;
          }
          case "Media": {
            category["icon"] = <OrangeIcon />;
            break;
          }
          case "Documents": {
            category["icon"] = <BlueIcon />;
            break;
          }
          case "Events": {
            category["icon"] = <GreenIcon />;
            break;
          }
          case "Poll": {
            category["icon"] = <PurpleIcon />;
            break;
          }
          case "Quiz": {
            category["icon"] = <PinkIcon />;
            break;
          }
        }
      });
      return newState;
    }
    case LIST_USER_POSTS_CATEGORY_SELECTED: {
      newState["next"] = action.payload.response.next;
      if (action.payload.response.results) {
        let newArray = newState["posts"].concat(
          action.payload.response.results
        );
        newState["posts"] = newArray;
      } else {
        newState["posts"] = action.payload.response.results;
      }
      if (action.payload.page == 1) {
        newState["posts"] = action.payload.response.results;
      }
      newState["posts"].map((post) => {
        post["userName"] = post["firstName"] + " " + post["lastName"];
        post["expandText"] = false;
        post["expandPostComment"] = false;
        post["showComments"] = false;
        post["showHashTag"] = false;
        post["showTagPeople"] = false;
        post["showEmoji"] = false;
        post["mediaView"] = false;
        post["likeButtonDisabled"] = false
        let start = new Date(post["event_start_date"]);
        let end = new Date(post["event_end_date"]);
        function ChangeDate(date) {
          var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

          if (month.length < 2) month = "0" + month;
          if (day.length < 2) day = "0" + day;

          return [year, month, day].join("-");
        }
        function ChangeTime(date) {
          return (
            ("00" + date.getHours()).slice(-2) +
            ":" +
            ("00" + date.getMinutes()).slice(-2) +
            ":" +
            ("00" + date.getSeconds()).slice(-2)
          );
        }
        function showTimeZone(date) {
          const dateAsString = date.toString();
          const timezone = dateAsString.match(/\(([^\)]+)\)$/)[1];
          return timezone;
        }
        post["event_start"] = ChangeDate(start);
        post["event_end"] = ChangeDate(end);
        post["event_start_time"] = ChangeTime(start);
        post["event_end_time"] = ChangeTime(end);
        post["timeZone"] = showTimeZone(start);
        post["showMore"] = false;
        post["shouldOpen"] = false;

        post["channel_details"].map((channel) => {
          if (channel.channel_name.includes("MAIN_CHANNEL")) {
            post["postFrom"] = "home";
          } else {
            post["postFrom"] = "channel";
          }
        });

        post["images"].map((img) => {
          img["type"] = "images";
        });
        post["videos"].map((video) => {
          video["type"] = "videos";
        });
        post["video_links"].map((video) => {
          video["type"] = "video_link";
        });
        post["documents"].map((doc) => {
          doc["type"] = "documents";
        });
        post["totalMedia"] = post["images"].concat(post["videos"]);
        post["totalCombinedMedia"] = post["totalMedia"].concat(
          post["video_links"]
        );
        post["postAtMentions"] = [];
        post["editPost"] = {
          showEditPost: false,
          labels: { title: "", button: "" },
        };
        post["newComment"] = "";
        post["owner"] =
          post.userName == action.payload.userData.name ? true : false;
        let postCategory = post["category"];

        newState["categories"].map((category) => {
          if (
            category.category_name &&
            category.category_name.includes(postCategory)
          ) {
            post["category"] = category.icon;
          }
        });
      });
      return newState;
    }
    //Like Dislike Pin Unpin posts
    case LIKE_DISLIKE_PIN_UNPIN_POSTS: {
      let postId = action.payload.id;
      let postIndex = findIndex(newState["posts"], { id: postId });
      if (postIndex >= 0) {
        newState["posts"][postIndex].isPinnedPost = action.payload.is_pinned;
      }
      return newState;
    }

    case LIKE_DISLIKE_POSTS: {

      let postId = action.payload.id;
      let postIndex = findIndex(newState["posts"], { id: postId });

      if (postIndex >= 0) {
        newState["posts"][postIndex].liked = action.payload.is_liked;
        newState["posts"][postIndex].likes = action.payload.like_count
      }
      return newState;
    }

    case CHANGE_LIKE_STATUS_OF_POST:{
      let postId = action.payload.id
      let postIndex = findIndex(newState["posts"], {id: postId});
      if(postIndex >= 0){
        newState["posts"][postIndex].liked = action.payload.isLiked
      }
      return newState
    }

    case CHANGE_LIKE_BUTTON_STATE:{
      let postId = action.payload.id
      let postIndex = findIndex(newState["posts"], {id: postId});
      if(postIndex >= 0){
        newState["posts"][postIndex].likeButtonDisabled = action.payload.isDisabled
      }
      return newState
    }

    //delete post Comment
    case DELETE_POST_COMMENT: {
      let data = action.payload;
      newState["posts"].map((post) => {
        if (post.id == data.postId) {
          let commentIndex = findIndex(post.comments, {
            comment_id: data.commentId,
          });
          post.comments.splice(commentIndex, 1);
        }
      });
      return newState;
    }

    //to toggle create post component
    case SHOW_CREATE_POST: {
      newState["showCreatePost"]["value"] = action.payload.value;
      newState["showCreatePost"]["route"] = action.payload.route;
      return newState;
    }

    case CLEAR_POST_FROM_STATE: {
      newState["posts"] = action.payload;
      return newState;
    }

    case OPEN_DELETE_CONFIRMATION_DIALOG: {
      newState["posts"].map((post) => {
        if (post.id == action.payload.id) {
          post["shouldOpen"] = action.payload.shouldOpen;
        }
      });
      return newState;
    }
    /* Create a Post Component */
    /* File Preview */
    //Images
    case USER_IMAGES_UPLOAD: {
      let data = action.payload;
      if (data != null) {
        let newImages = [];
        for (let i = 0; i < data.length; i++) {
          let file = data[i];
          let fileObj = file && file;

          let url = URL.createObjectURL(data[i]);
          fileObj["url"] = url;
          newImages.push(fileObj);
        }
        let imgArray;
        if (newState["images"]) {
          let arr = newState["images"].concat(newImages);
          newState["images"] = arr;
        } else {
          imgArray = newImages;
          newState["images"] = imgArray;
        }
      } else {
        newState["images"] = [];
      }
      return newState;
    }
    case USER_IMAGES_DELETE: {
      let imagesArray = newState["images"];
      let index = action.payload;
      imagesArray.splice(index, 1);
      newState["images"] = imagesArray;
      return newState;
    }
    //Images Already uploaded - post Specific
    case POST_IMAGES: {
      newState["post_images"] = action.payload;
      return newState;
    }
    case POST_IMAGES_DELETE: {
      let imagesArray = newState["post_images"];
      let index = action.payload;
      imagesArray.splice(index, 1);
      newState["post_images"] = imagesArray;
      return newState;
    }
    //videos
    case USER_VIDEOS_UPLOAD: {
      let data = action.payload;
      if (data != null) {
        let newVideos = [];
        for (let i = 0; i < data.length; i++) {
          let file = data[i];
          let fileObj = file && file;
          let url = URL.createObjectURL(data[i]);
          fileObj["url"] = url;
          newVideos.push(fileObj);
        }
        let videoArray;
        if (newState["videos"]) {
          let arr = newState["videos"].concat(newVideos);
          newState["videos"] = arr;
        } else {
          videoArray = newVideos;
          newState["videos"] = videoArray;
        }
      } else {
        newState["videos"] = [];
      }
      return newState;
    }
    case USER_VIDEOS_DELETE: {
      let imagesArray = newState["videos"];
      let index = action.payload;
      imagesArray.splice(index, 1);
      newState["videos"] = imagesArray;
      return newState;
    }

    //Videos Already uploaded - post Specific
    case POST_VIDEOS: {
      newState["post_videos"] = action.payload;
      return newState;
    }
    case POST_VIDEOS_DELETE: {
      let imagesArray = newState["post_videos"];
      let index = action.payload;
      imagesArray.splice(index, 1);
      newState["post_videos"] = imagesArray;
      return newState;
    }

    case USER_EXTERNAL_VIDEO_UPLOAD: {
      let data = action.payload;
      if (data != null) {
        let newVideos = [];
        newVideos.push(action.payload);
        let videoArray;
        if (newState["externalVideos"]) {
          let arr = newState["externalVideos"].concat(newVideos);
          newState["externalVideos"] = arr;
        } else {
          videoArray = newVideos;
          newState["externalVideos"] = videoArray;
        }
      } else {
        newState["externalVideos"] = [];
      }
      return newState;
    }
    case USER_EXTERNAL_VIDEO_DELETE: {
      let imagesArray = newState["externalVideos"];
      let index = action.payload;
      imagesArray.splice(index, 1);
      newState["externalVideos"] = imagesArray;
      return newState;
    }

    //Videos Already uploaded - post Specific
    case POST_EXTERNAL_VIDEO_UPLOAD: {
      newState["post_external_video_links"] = action.payload;
      return newState;
    }
    case POST_EXTERNAL_VIDEO_DELETE: {
      let imagesArray = newState["post_external_video_links"];
      let index = action.payload;
      imagesArray.splice(index, 1);
      newState["post_external_video_links"] = imagesArray;
      return newState;
    }

    //document
    case USER_DOCUMENT_UPLOAD: {
      let data = action.payload;
      if (data != null) {
        let newDocuments = [];
        for (let i = 0; i < data.length; i++) {
          let file = data[i];
          let fileObj = file && file;
          let url = URL.createObjectURL(data[i]);
          fileObj["url"] = url;
          newDocuments.push(fileObj);
        }
        let documentArray;
        if (newState["documents"]) {
          let arr = newState["documents"].concat(newDocuments);
          newState["documents"] = arr;
        } else {
          documentArray = newDocuments;
          newState["documents"] = documentArray;
        }
      } else {
        newState["documents"] = [];
      }
      return newState;
    }
    case USER_DOCUMENT_DELETE: {
      let imagesArray = newState["documents"];
      let index = action.payload;
      imagesArray.splice(index, 1);
      newState["documents"] = imagesArray;
      return newState;
    }
    //Videos Already uploaded - post Specific
    case POST_DOCUMENT: {
      newState["post_documents"] = action.payload;
      return newState;
    }

    case POST_DOCUMENT_DELETE: {
      let imagesArray = newState["post_documents"];
      let index = action.payload;
      imagesArray.splice(index, 1);
      newState["post_documents"] = imagesArray;
      return newState;
    }
    /* Post Component */
    case USER_EXPAND_TEXT: {
      let id = action.payload.id;
      let posts = newState["posts"].map((post) => {
        if (post.id == id) {
          return { ...post, expandText: action.payload.content };
        } else {
          return { ...post };
        }
      });
      newState["posts"] = posts;
      return newState;
    }
    case USER_EXPAND_COMMENT: {
      let id = action.payload.id;
      let posts = newState["posts"].map((post) => {
        if (post.id == id) {
          return { ...post, expandPostComment: action.payload.content };
        } else {
          return { ...post };
        }
      });
      newState["posts"] = posts;
      return newState;
    }
    case USER_SHOW_COMMENT: {
      let id = action.payload.id;
      let posts = newState["posts"].map((post) => {
        if (post.id == id) {
          return { ...post, showComments: action.payload.content };
        } else {
          return { ...post };
        }
      });
      newState["showCommentsGlobal"] = action.payload.content;
      newState["posts"] = posts;
      return newState;
    }
    /* Category Selected */
    case POST_CATEGORY_SELECTED_DATA: {
      let data = action.payload;
      if (data != null) {
        newState["categories"].map((category) => {
          if (
            category.category_name &&
            category.category_name.indexOf(data) >= 0
          ) {
            newState["categorySelected"] = category;
          }
        });
      } else {
        newState["categorySelected"] = "";
      }

      return newState;
    }

    /* Hashtag added in comment */
    case POST_SHOW_HASHTAG: {
      newState["posts"].map((post) => {
        if (post.id == action.payload.id) {
          post["showHashTag"] = action.payload.showHashtag;
        }
      });
      return newState;
    }
    case POST_EMOJI_SELECTED: {
      newState["posts"].map((post) => {
        if (post.id == action.payload.id) {
          post["newComment"] = post["newComment"].substr(
            0,
            post["newComment"].length
          );
          post["newComment"] =
            post["newComment"] + action.payload.selectedEmoji;
        }
      });
      return newState;
    }
    case POST_HASHTAG_SELECTED: {
      newState["posts"].map((post) => {
        if (post.id == action.payload.id) {
          post["newComment"] = post["newComment"].substr(
            0,
            post["newComment"].length - 1
          );
          post["newComment"] =
            post["newComment"] + action.payload.selectedHashTag;
        }
      });
      return newState;
    }
    /* comment update */
    case POST_NEW_COMMENT: {
      newState["posts"].map((post) => {
        if (post.id == action.payload.id) {
          post["newComment"] = action.payload.comment;
        }
      });
      return newState;
    }
    case SUBMIT_NEW_COMMENT: {
      //submit content from newComment for that Id
      let data = action.payload.response;
      let userData = action.payload.userData;
      newState["posts"].map((post) => {
        if (post.id == data.post_id) {
          let content = data.content;
          let commentedOn = data.created_on;
          let newComment = {
            first_name: data.first_name,
            last_name: data.last_name,
            // userName: userData.name,
            organisationName: userData.organization_name,
            // designation: userData.designation == null ? "" : userData.designation,
            designation: data.user_designation,
            comment: content,
            // profileIcon: userData.user_profile,
            profileIcon: data.user_profile_pic,
            commentedOn: commentedOn,
            comment_id: data.id,
            user_id: userData.profile_id,
          };
          if (post.comments == null) {
            post.comments = [];
          }
          post.comments.push(newComment);
          post.newComment = "";
          // post.comments++
        }
      });
      return newState;
    }
    /* TagPeople in Comment */
    case POST_SHOW_TAGPEOPLE: {
      newState["posts"].map((post) => {
        if (post.id == action.payload.id) {
          post["showTagPeople"] = action.payload.showTagPeople;
        }
      });
      return newState;
    }
    case POST_SHOW_EMOJI: {
      newState["posts"].map((post) => {
        if (post.id == action.payload.id) {
          post["showEmoji"] = action.payload.showEmoji;
        }
      });
      return newState;
    }
    case POST_TAGPEOPLE_SELECTED: {
      newState["posts"].map((post) => {
        if (post.id == action.payload.id) {
          post["newComment"] = post["newComment"].substr(
            0,
            post["newComment"].length
          );
          post["newComment"] =
            post["newComment"] + action.payload.selectedAtPeople.mentions.name;
        }
      });
      return newState;
    }
    /* Post - Show More */
    case POST_SHOW_MORE: {
      newState["posts"].map((post) => {
        if (post.id == action.payload.id) {
          post["showMore"] = action.payload.showMore;
        }
      });
      return newState;
    }
    /* Show Edit Post */
    case SHOW_POST_EDIT: {
      newState["posts"].map((post) => {
        if (post.id == action.payload.id) {
          post["editPost"] = {
            showEditPost: action.payload.editPost,
            labels: {
              title: action.payload.title,
              button: action.payload.button,
            },
          };
        }
      });
      return newState;
    }
    case POST_SHOW_MORE_SELECTED: {
      newState["posts"].map((post) => {
        if (post.id == action.payload.id) {
          switch (action.payload.option) {
            case "Edit Post": {
              post["editPost"] = {
                showEditPost: action.payload.args.editPost,
                labels: {
                  title: action.payload.args.title,
                  button: action.payload.args.button,
                },
              };
              break;
            }
            case "Share with Groups": {
              post["editPost"] = {
                showEditPost: action.payload.args.editPost,
                labels: {
                  title: action.payload.args.title,
                  button: action.payload.args.button,
                },
              };
              break;
            }
          }
        }
      });
      return newState;
    }
    /* Show - Media View for Post */
    case SHOW_POST_MEDIA_PREVIEW: {
      newState["posts"].map((post) => {
        if (post.id == action.payload.id) {
          post["mediaView"] = action.payload.showPreview;
        }
      });
      return newState;
    }

    //Infinite scrolling
    case SHOW_NEXT_POSTS: {
      newState["pageofPosts"] = action.payload.nextPosts;
      return newState;
    }

    case CHANNEL_PREVIEW_LIST_SUCCESS: {
      newState["numberOfGroups"] = 0;
      newState["numberOfChannels"] = 0;
      newState["channelList"] = action.payload;
      newState["channelList"].map((channel) => {
        newState["numberOfChannels"] += channel.channels.length;
      });
      newState["numberOfGroups"] += newState["channelList"].length;
      return newState;
    }
    //hashtags from server
    case LIST_HASHTAG: {
      newState["postHashTags"] = action.payload;
      return newState;
    }
    //mentions for post
    case LIST_MENTIONS: {
      newState["posts"].map((post) => {
        if (post.id == action.payload.id) {
          post["postAtMentions"] = action.payload.response;
        }
      });
      return newState;
    }
    //Share with groups listing
    case LIST_SHARE_WITH_GROUP: {
      newState["postSharedWithGroups"] = action.payload;
      return newState;
    }

    case DELETE_POST: {
      let dataId = action.payload;
      let postIndex = findIndex(newState["posts"], { id: dataId });
      if (postIndex >= 0) {
        newState["posts"].splice(postIndex, 1);
      }
      return newState;
    }

    case TO_GET_THE_POLL_FORM: {
      newState["pollWithOptions"] = action.payload;
      return newState;
    }
    case ADD_NEW_POST: {
      let data = action.payload.response;
      data["userName"] = data["firstName"] + " " + data["lastName"];
      data["expandText"] = false;
      data["expandPostComment"] = false;
      data["showComments"] = false;
      data["showHashTag"] = false;
      data["showTagPeople"] = false;
      data["showEmoji"] = false;
      data["mediaView"] = false;
      data["showMore"] = false;
      data["postAtMentions"] = [];
      data["editPost"] = {
        showEditPost: false,
        labels: { title: "", button: "" },
      };
      data["newComment"] = "";
      data["owner"] =
        data.userName == action.payload.userData.name ? true : false;
      let postCategory = data["category"];
      newState["categories"].map((category) => {
        if (
          category.categoryName &&
          category.categoryName.includes(postCategory)
        ) {
          data["category"] = category.icon;
        }
      });
      //add data to posts
      newState["posts"].unshift(data);
      return newState;
    }

    case GET_USER_POINTS: {
      newState["userPoints"] = action.payload;
      return newState;
    }

    case GET_LEADERBOARD: {
      let page = action.payload.page;
      let data = action.payload.response;
      newState["nextLeaderBoard"] = data.next;
      if (page == 1) {
        newState["leaderBoard"] = data.results;
      } else {
        newState["leaderBoard"] = newState["leaderBoard"].concat(data.results);
      }
      return newState;
    }

    case AUTH_LOGOUT: {
      return { ...initialState };
    }

    default: {
      // initialise state with additional keys
      if (newState["posts"]) {
        newState["posts"].map((post) => {
          post["expandText"] = false;
          post["expandPostComment"] = false;
          post["showComments"] = false;
          post["showHashTag"] = false;
          post["showTagPeople"] = false;
          post["showEmoji"] = false;
          post["showMore"] = false;
          post["mediaView"] = false;
          post["postAtMentions"] = [];
          post["editPost"] = {
            showEditPost: false,
            labels: { title: "", button: "" },
          };
          post["newComment"] = "";
          let postCategory = post["category"];
          newState["categories"].map((category) => {
            if (
              category.category_name &&
              category.category_name.includes(postCategory)
            ) {
              post["category"] = category.icon;
            }
          });
        });
      }
      return newState;
    }
  }
}
