import EMDropdown from "../../../../components/commans/EMDropdown";
import { useSelector } from "react-redux";
import { find } from "lodash";
import FormHelperText from "@material-ui/core/FormHelperText";

export default function EMGroupDropdown({ onSelect, name, error, helperText, initialValue,isDisabled}) {
  
  const data = useSelector(state => state.networkAdmin["groupDropdownList"]);
  let preValue = initialValue ? find(data,{"id" : initialValue}) : ''
  if(preValue){
    preValue = preValue !== '' ? preValue["group_name"] : ''
  }
  
  return (
    <>
    <EMDropdown
      label="Group Name"
      data={data || []}
      objKey={"group_name"}
      onChange={selected => {
        onSelect(find(data, { group_name: selected }).id);
      }}
      prevData={preValue}
      name={name}
      error={error}
      helperText={helperText}
      isDisabled = {isDisabled}
    />
    <FormHelperText style = {{color:'red'}}>
          {error}
          {helperText}
        </FormHelperText>
    </>
  );
}

