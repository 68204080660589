import axios from "axios";
import authApi from "./auth";
import platformApi from "./platformAdmin";
import commonApi from "./common";
import networkApi from "./networkAdmin";
import groupApi from "./groupAdmin";
import channelApi from "./channelAdmin"
import EMStorage from "../utils/EMStorage";
import userHomeApi from "./userPlatform/Home";
import UserGroupApi from "./userPlatform/Groups";
import EMPeople from "./userPlatform/People";
import UserChannelApi from "./userPlatform/Channels"
import UserEventsApi from "./userPlatform/Events"
import UserNetworkApi from "./userPlatform/Network"
import UserPollsApi from "./userPlatform/Polls/index"
import UserQuizApi from "./userPlatform/Quiz/index"
axios.defaults.baseURL = process.env.REACT_APP_BACKEND;
//axios.defaults.headers.post["Content-type"] = "application/json";

axios.interceptors.request.use(function(config) {
  
  const userData = EMStorage.getUserData();
  if (userData) {
    if(userData["access"]){
    config.headers.Authorization = `Bearer ${userData["access"]}`;
  }
  }
  return config;
});

const API = {
  ...authApi,
  ...platformApi,
  ...commonApi,
  ...networkApi,
  ...groupApi,
  ...channelApi,
  ...userHomeApi,
  ...UserGroupApi,
  ...EMPeople,
  ...UserChannelApi,
  ...UserEventsApi,
  ...UserNetworkApi,
  ...UserPollsApi,
  ...UserQuizApi
};

export default API;
