import React, { useState, useEffect, useCallback } from "react";
import EMPage from "../../../../components/commans/EMPage";
import {
  Grid,
  Paper,
  Typography,
  Button,
  GridList,
  GridListTile,
  SvgIcon,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import EMSearchView from "../../../../components/navigation/EMSearchView";
import { useSelector, useDispatch } from "react-redux";
import EMGroupView from "../../../../components/user/EMGroupView";
import EMFilter from "../../../../components/user/EMFilter";
import Dialog from "@material-ui/core/Dialog";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import DialogContent from "@material-ui/core/DialogContent";
import {
  EMDoGetListOfAllGroupsAction,
  EMDoGetNetworkFiltersAction,
  EMDoGetFilteredGroupsWithPartenershipsAndOrganizationsAction,
  EMDoActionForGroupAction,
  EMDoGetListOfAllGroupsBySearchAction,
} from "../../../../redux/actions/UserPlatform/Groups/EMGroupsAction";
import {
  DISABLE_ENABLE_FILTER,
  DISABLE_ENABLE_SEARCH_BROWSE_GROUPS,
  GROUP_APPLY_NETWORK_FILTER,
  GROUP_INFO_MODAL,
} from "../../../../redux/constants/UserPlatform/EMGroupConstant";
import {
  EMDoGetLoggedInUserRolesAction,
  EMGetRolesAndPermissionsBasedOnEntityAction,
} from "../../../../redux/actions/EMAuthActions";
import { useNavigate } from "react-router-dom";
import EMSideBar from "../../../../components/navigation/EMSideBar";
import {
  ADD_SUBTABS_TO_GROUP,
  CHANGE_ELEMENT_FOR_SUBTABS,
} from "../../../../redux/constants/UserPlatform/EMNavigationConstant";
import EMSearch from "../../../../components/navigation/EMSearch";
import InfiniteScroll from "react-infinite-scroll-component";

const useStyles = makeStyles((theme) => ({
  topBar: {
    float: "right",
    display: "flex",
    marginLeft: "59%",
  },

  paper: {
    // paddingLeft: "1%",
    textAlign: "center",
    fontWeight: "bolder",
    color: theme.palette.text.primary,
  },
  dialog: {
    width: "400px",
  },
  gridListTile: {
    // margin:"auto",

    marginTop: "4px",
    marginBottom: "2%",
    // marginLeft:"1%",
    // marginBottom:"10px",
    borderRadius: "10px",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);",
  },

  root: {
    marginTop: "2%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    // padding:"10px",
    width: "100%",
    height: "100%",
    //maxHeight: window.screen.height,
    "&::-webkit-scrollbar": {
      width: "0.1em",
    },
    // "&::-webkit-scrollbar-track": {
    //   boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    //   webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    // },
    // "&::-webkit-scrollbar-thumb": {
    //   backgroundColor: "#4A4A4A",
    //   outline: "1px solid slategrey",
    // },
  },
}));
function DeleteIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M11.436,3.375A8.061,8.061,0,1,0,19.5,11.436,8.06,8.06,0,0,0,11.436,3.375Zm2.042,10.979-2.042-2.042L9.394,14.354a.619.619,0,1,1-.876-.876l2.042-2.042L8.518,9.394a.619.619,0,0,1,.876-.876l2.042,2.042,2.042-2.042a.619.619,0,1,1,.876.876l-2.042,2.042,2.042,2.042a.622.622,0,0,1,0,.876A.615.615,0,0,1,13.478,14.354Z" />
    </SvgIcon>
  );
}
function LatestPost(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [search, setSearch] = useState("");
  // const userData = useSelector((state)=> state.auth || {})
  const data = useSelector((state) => state.groups || {});
  const [limit, setLimit] = useState(0);
  const [networkValue, setNetworkValue] = useState([]);
  const [limitWhileSearchingInGroups, setLimitWhileSearchingInGroups] =
    useState(0);
  const [limitWhileFilteringInGroups, setLimitWhileFilteringInGroups] =
    useState(0);

  useEffect(() => {
    dispatch(EMDoGetNetworkFiltersAction({ route: false }));
    dispatch({ type: ADD_SUBTABS_TO_GROUP });
    dispatch({
      type: DISABLE_ENABLE_SEARCH_BROWSE_GROUPS,
      payload: true,
    });
    dispatch({
      type: DISABLE_ENABLE_FILTER,
      payload: true,
    });
  }, []);

  const theme = useTheme();

  const matchesMediumScreen = useMediaQuery(theme.breakpoints.up("md"));

  const matchesLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  function getCols(screenWidth) {
    if (isWidthUp("xl", screenWidth)) {
      return 3;
    }

    if (isWidthUp("lg", screenWidth)) {
      return 3;
    }
    if (isWidthUp("md", screenWidth)) {
      return 2.2;
    }
    if (isWidthUp("sm", screenWidth)) {
      return 2;
    }
    if (isWidthUp("xs", screenWidth)) {
      return 1;
    }
    return 3;
  }
  const cols = getCols(props.width);

  const sendQuery = useCallback(async () => {
    
    if (search.length === 0 && networkValue.length === 0) {
      dispatch(
        EMDoGetListOfAllGroupsAction({
          limit: limit,
        }))
    } else if (search.length > 0 && networkValue.length == 0) {
      dispatch(
        EMDoGetFilteredGroupsWithPartenershipsAndOrganizationsAction({
          limit: limitWhileSearchingInGroups,
          myaction: "search=" + search,
        })
      );
    }else if (networkValue.length > 0 && search.length == 0) {
      if (networkValue.length == 1) {
        let selectedNetwork = networkValue[0];
        let action =
          selectedNetwork.context_type_name + "=" + selectedNetwork.context;
        if (selectedNetwork.context_type_name === "partnership") {
          action = `${selectedNetwork.context_type_name}s=${selectedNetwork.context}`;
        }

        dispatch(
          EMDoGetFilteredGroupsWithPartenershipsAndOrganizationsAction({
            myaction: action,
            limit: limitWhileFilteringInGroups,
          })
        );
      } else if (networkValue.length > 1) {
        let action;
        let partnershipAction;
        let organizationAction;
        let partnershipIds = [];
        networkValue.map((selectedNetwork, key) => {
          if (selectedNetwork.context_type_name === "partnership") {
            partnershipIds.push(selectedNetwork.context);
            partnershipAction = `${
              selectedNetwork.context_type_name
            }s=${partnershipIds.toString()}`;
          }
          if (selectedNetwork.context_type_name === "organization") {
            organizationAction =
              selectedNetwork.context_type_name + "=" + selectedNetwork.context;
          }
          if (organizationAction != undefined) {
            action = organizationAction;
          }
          if (partnershipAction != undefined) {
            action = partnershipAction;
          }
          if (
            organizationAction != undefined &&
            partnershipAction != undefined
          ) {
            action = organizationAction + "&" + partnershipAction;
          }
        });
        dispatch(
          EMDoGetFilteredGroupsWithPartenershipsAndOrganizationsAction({
            myaction: action,
            limit: limitWhileFilteringInGroups,
          })
        );
      }
    } else if(networkValue.length > 0 && search.length > 0){
      if (networkValue.length == 1) {
        let selectedNetwork = networkValue[0];
        let action =
          selectedNetwork.context_type_name + "=" + selectedNetwork.context;
        if (selectedNetwork.context_type_name === "partnership") {
          action = `${selectedNetwork.context_type_name}s=${selectedNetwork.context}`;
        }
        if(search.length > 0){
          action = action + "&" + "search=" + search
       }

       dispatch(
        EMDoGetFilteredGroupsWithPartenershipsAndOrganizationsAction({
          myaction: action,
          limit: limitWhileFilteringInGroups,
        })
      );
      } else if (networkValue.length > 1) {
        let action;
        let partnershipAction;
        let organizationAction;
        let partnershipIds = [];
        networkValue.map((selectedNetwork, key) => {
          if (selectedNetwork.context_type_name === "partnership") {
            partnershipIds.push(selectedNetwork.context);
            partnershipAction = `${
              selectedNetwork.context_type_name
            }s=${partnershipIds.toString()}`;
          }
          if (selectedNetwork.context_type_name === "organization") {
            organizationAction =
              selectedNetwork.context_type_name + "=" + selectedNetwork.context;
          }
          if (organizationAction != undefined) {
            action = organizationAction;
          }
          if (partnershipAction != undefined) {
            action = partnershipAction;
          }
          if (
            organizationAction != undefined &&
            partnershipAction != undefined
          ) {
            action = organizationAction + "&" + partnershipAction;
          }
        });
        if(search.length > 0){
          action = action + "&" + "search=" + search
       }
       dispatch(
        EMDoGetFilteredGroupsWithPartenershipsAndOrganizationsAction({
          myaction: action,
          limit: limitWhileFilteringInGroups,
        })
      );
      }
    }
  }, [limit, limitWhileSearchingInGroups, limitWhileFilteringInGroups,]);

  useEffect(() => {
      if (search.length === 0 && networkValue.length === 0) {
        sendQuery(limit);
      } else if (search.length > 0 && networkValue.length == 0) {
        sendQuery(limitWhileSearchingInGroups);
      } else if (networkValue.length > 0 || search.length > 0) {
        sendQuery(limitWhileFilteringInGroups);
      }
  }, [sendQuery, limit, limitWhileSearchingInGroups, limitWhileFilteringInGroups]);

  const handleObserver = useCallback(() => {
    if (search.length === 0 && networkValue.length === 0) {
      setLimit((prev) => prev + 10);
      setLimitWhileFilteringInGroups(0)
      setLimitWhileSearchingInGroups(0)
    } else if (search.length > 0 && networkValue.length == 0) {
      setLimitWhileSearchingInGroups((prev) => prev + 10);
      setLimitWhileFilteringInGroups(0)
      setLimit(0)
    } else if (networkValue.length > 0 || search.length > 0) {
      setLimitWhileFilteringInGroups((prev) => prev + 10);
      setLimit(0)
      setLimitWhileSearchingInGroups(0)
    }
  }, [search, networkValue]);

  useEffect(() => {
    setNetworkValue(data.networks.filter((network) => network.value === true));
  }, [data.networks]);

  useEffect(() => {
    let appliedFilters = networkValue
    if(appliedFilters.length > 0){
      setLimitWhileFilteringInGroups(0)
      if (appliedFilters.length == 1) {
        let selectedNetwork = appliedFilters[0];
        let action =
          selectedNetwork.context_type_name +
          "=" +
          selectedNetwork.context;
        if (
          selectedNetwork.context_type_name === "partnership"
        ) {
          action = `${selectedNetwork.context_type_name}s=${selectedNetwork.context}`;
        }
        if(search.length > 0){
           action = action + "&" + "search=" + search
        } 
        dispatch(
          EMDoGetFilteredGroupsWithPartenershipsAndOrganizationsAction({
            myaction: action,
            limit: limitWhileFilteringInGroups,
          })
        );
      }else if (appliedFilters.length > 1) {
       
          let action
          let partnershipAction 
          let organizationAction
          let partnershipIds = []
          let orgIds = []
          appliedFilters.map((selectedNetwork, key) => {
            if (
              selectedNetwork.context_type_name === "partnership"
            ) {
              partnershipIds.push(selectedNetwork.context);
              partnershipAction = `${
                selectedNetwork.context_type_name
              }s=${partnershipIds.toString()}`;
            }
            if (selectedNetwork.context_type_name === "organization") {
              orgIds.push(selectedNetwork.context)
              organizationAction=  `${selectedNetwork.context_type_name}=${orgIds.toString()}`
            } 
            if(organizationAction != undefined){
              action=organizationAction  
            }
            if (organizationAction != undefined) {
              action = organizationAction;
            }
            if (partnershipAction != undefined) {
              action = partnershipAction;
            }
            if (
              organizationAction != undefined &&
              partnershipAction != undefined
            ) {
              action =
                organizationAction + "&" + partnershipAction;
            }
            if(search.length > 0){
              action = action + "&" + "search=" + search
           }
          });
          dispatch(
            EMDoGetFilteredGroupsWithPartenershipsAndOrganizationsAction({
              myaction: action,
              limit: limitWhileFilteringInGroups,
            })
          );
        }else if (appliedFilters.length == 0 && search.length == 0) {
          
          setLimit(0)
          dispatch(EMDoGetListOfAllGroupsAction({ limit: limit }));
        }
    } else{
      setLimitWhileSearchingInGroups(0)
      let action = "search=" + search
      dispatch(
        EMDoGetFilteredGroupsWithPartenershipsAndOrganizationsAction({
          myaction: action,
          limit: limitWhileSearchingInGroups,
        })
      );
    }   
  },[search])

  return (
    <InfiniteScroll
      dataLength={data.browseallgroups.length}
      next={handleObserver}
      hasMore={true}
    >
      <EMPage
        title="Browse Groups"
        style={{
          width: "100%",
          paddingLeft: matchesLargeScreen ? "8%" : "4%",
          paddingTop: matchesLargeScreen
            ? "4%"
            : matchesMediumScreen
            ? "6%"
            : "8%",
          paddingRight: matchesLargeScreen ? "8%" : "4%",
          paddingBottom: "2%",
        }}
      >
        <Grid container direction="row" alignItems="flexStart" spacing={3}>
          {matchesMediumScreen ? (
            <Grid item xs={matchesLargeScreen ? 2 : 3}>
              <EMSideBar />
            </Grid>
          ) : null}
          <Grid item xs={matchesLargeScreen ? 7 : matchesMediumScreen ? 9 : 12}>
            <Paper elevation={2}>
              <Grid container alignItems="center" style={{ padding: 10 }}>
                {/* <div style={{ display: "flex", padding: 20 }}> */}
                <Grid
                  item
                  xs={9}
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <Typography
                    style={{ fontSize: "medium", fontWeight: "bolder" }}
                  >
                    Search for Groups available
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <EMSearch
                    disabled={
                      data.isSearchDisabledBrowseGroups || data.isFilterDisabled
                    }
                    data={search}
                    usedIn="browse_groups"
                    route={true}
                    hint={"Search"}
                    onChangeListener={(serachData) => {
                      setSearch(serachData);
                      dispatch({
                        type: DISABLE_ENABLE_SEARCH_BROWSE_GROUPS,
                        payload: true,
                      });
                    }}
                  />
                </Grid>
                {/* </div> */}
              </Grid>
              {/* <Grid xs={12}> */}
              <div className={classes.root}>
                <GridList
                  // cellHeight={180}
                  className={classes.gridList}
                  cols={cols}
                  style={{
                    alignItems: "flex-start",
                  }}
                >
                  {data.browseallgroups.map((group, key) => (
                    <GridListTile
                      key={key}
                      className={classes.gridListTile}
                      style={{
                        height: "150px",
                      }}
                    >
                      <EMGroupView
                        route="browse_groups"
                        onStarClick={() => {
                          let actions;
                          group.is_fav
                            ? (actions = `remove-favorite`)
                            : (actions = `favorite`);
                          dispatch(
                            EMDoActionForGroupAction({
                              id: group.id,
                              myaction: actions,
                              postIndex: key,
                              route: "browse_groups",
                            })
                          );
                        }}
                        id={group.id}
                        type={GROUP_INFO_MODAL}
                        value={data.groupInfoPopUp.value}
                        number_of_group_users={group.number_of_group_users}
                        group_name={group.group_name}
                        isMember={group.is_member}
                        logo_file={group.logo_file}
                        isFav={group.is_fav}
                        isHighlighted={false}
                        created_by={group.created_by}
                        organization_name={group.organization_name}
                        users_count={group.users_count}
                        privacy_type_name={group.privacy_type_name}
                        group={group}
                        onCardClick={() => {
                          dispatch(
                            EMGetRolesAndPermissionsBasedOnEntityAction({
                              entity: "group",
                              entityId: group.id,
                            })
                          );
                          navigation("activity", {
                            state: { GroupId: group.id },
                          });
                          window.sessionStorage.setItem("GroupId", group.id);
                        }}
                      />
                    </GridListTile>
                  ))}
                </GridList>
                {data.groupInfoPopUp.network_name ||
                data.groupInfoPopUp.created_by ? (
                  <Dialog
                    fullWidth={true}
                    maxWidth="sm"
                    open={data.groupInfoPopUp.value}
                    onClose={() => {
                      dispatch({
                        type: GROUP_INFO_MODAL,
                        payload: { value: false },
                      });
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    // maxWidth="md"
                    className={classes.dialog}
                  >
                    <DialogContent>
                      <div style={{ marginBottom: "5%" }}>
                        <div style={{ display: "flex" }}>
                          {data.groupInfoPopUp &&
                          data.groupInfoPopUp.network_name ? (
                            <Typography color="textSecondary">
                              Network Name
                            </Typography>
                          ) : null}

                          <DeleteIcon
                            style={{ marginLeft: "20%" }}
                            onClick={() => {
                              dispatch({
                                type: GROUP_INFO_MODAL,
                                payload: { value: false },
                              });
                            }}
                          />
                        </div>
                        {data.groupInfoPopUp &&
                        data.groupInfoPopUp.network_name ? (
                          <Typography
                            style={{
                              fontWeight: "bolder",
                              fontSize: "medium",
                              marginTop: "3%",
                            }}
                          >
                            {data.groupInfoPopUp &&
                              data.groupInfoPopUp.network_name}
                          </Typography>
                        ) : null}
                      </div>
                      {data.groupInfoPopUp.groupInfo &&
                      data.groupInfoPopUp.groupInfo.created_by ? (
                        <div>
                          <Typography color="textSecondary">
                            Created By
                          </Typography>
                          <Typography
                            style={{
                              fontWeight: "bolder",
                              fontSize: "medium",
                              marginTop: "3%",
                            }}
                          >
                            {data.groupInfoPopUp.groupInfo &&
                              data.groupInfoPopUp.groupInfo.created_by}
                          </Typography>
                        </div>
                      ) : null}
                    </DialogContent>
                  </Dialog>
                ) : null}
              </div>
              {/* </Grid> */}
            </Paper>
          </Grid>
          {matchesLargeScreen ? (
            <Grid item lg={3}>
              <Paper elevation={2}>
                <EMFilter
                  disabled={
                    data.isFilterDisabled || data.isSearchDisabledBrowseGroups
                  }
                  filter_name="NETWORK FILTER"
                  network={data.networks}
                  network_filter={GROUP_APPLY_NETWORK_FILTER}
                  route={"browse_groups"}
                  handleLimitInGroups={(limit) => setLimit(limit)}
                  handleLimitWhileFilteringInGroups={(limit) =>
                    setLimitWhileFilteringInGroups(limit)
                  }
                  handleLimitWhileSearchingInGroups={(limit) =>
                    setLimitWhileSearchingInGroups(limit)
                  }
                  search={search}
                />
              </Paper>
            </Grid>
          ) : null}
        </Grid>
      </EMPage>
    </InfiniteScroll>
  );
}
export default withWidth()(LatestPost);
