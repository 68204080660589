import { combineReducers } from "redux";
import EMAuthReducer from "./EMAuthReducer";
import EMUIreducer from "./EMUIReducer";
import EMAdminReducer from "./EMAdminReducer";
import EMNetworkAdminReducer from "./EMNetworkAdminReducer";
import EMCommonReducer from "./EMCommonReducer";
import EMGroupAdminReducer from "./EMGroupAdminReducer";
import EMChannelAdminReducer from "./EMChannelAdminReducer";
import EMNavigationReducer from "./UserPlatform/EMNavigationReducer";
//user
import EMLatestPostReducer from "./UserPlatform/Home/EMLatestPostPageReducer";
import EMPinnedPostPageReducer from "./UserPlatform/Home/EMPinnedPostPageReducer";
import EMEventsReducer from "./UserPlatform/Calendar/EMEventsReducer";
import EMGroupsReducer from "./UserPlatform/Groups/EMGroupsReducer";
import EMChannelsReducer from "./UserPlatform/Channels/EMChannelsReducer";
import EMMyPeopleReducer from "./UserPlatform/People/EMMyPeopleReducer";
import EMMyNetworkReducer from "./UserPlatform/Network/EMMyNetworkReducer";
import EMMyPollReducer from "./UserPlatform/Poll/EMMyPollReducer";
import EMMyQuizReducer from "./UserPlatform/Quiz/EMMyQuizReducer";
import { RESET } from "../constants";

export default combineReducers({
  auth: EMAuthReducer,
  ui: EMUIreducer,
  admin: EMAdminReducer,
  networkAdmin: EMNetworkAdminReducer,
  common: EMCommonReducer,
  groupAdmin: EMGroupAdminReducer,
  channelAdmin: EMChannelAdminReducer,
  home: EMLatestPostReducer,
  pinnedPost: EMPinnedPostPageReducer,
  navigation: EMNavigationReducer,
  events: EMEventsReducer,
  groups: EMGroupsReducer,
  channels: EMChannelsReducer,
  myPeople: EMMyPeopleReducer,
  myNetwork: EMMyNetworkReducer,
  myPoll: EMMyPollReducer,
  myQuizzes: EMMyQuizReducer,
});

