import EMDropdown from "../../../../components/commans/EMDropdown";
import { useSelector,useDispatch } from "react-redux";
import { find } from "lodash";
import {useEffect} from "react"
import FormHelperText from "@material-ui/core/FormHelperText";

export default function EMEntityTemplateDropdown({ initialValue,onSelect,name,entity,error,
  helperText, },props) {
  const data = useSelector(state => state.networkAdmin["emailTemplates"]);
  let preValue = initialValue ? find(data,{"id" : initialValue}) : ''
  preValue = preValue !== ''&& preValue !== undefined ? preValue["template_name"] : ''
 
  return (
    <>
    <EMDropdown
    name = {name}
      label="Select Template"
      data={data || []}
      objKey={"template_name"}
      onChange={selected => {
        onSelect(find(data, { template_name: selected }));
      }}
      disabled = {props.disabled}
      error={error}
      helperText={helperText}
      prevData={preValue}
      {...props}
    />
      <FormHelperText style = {{color:'red'}}>
        {error}
        {helperText}
      </FormHelperText>
    </>
  );
}
