import React, { useState, useEffect } from "react";
import EMPage from "../../components/commans/EMPage";
import {
  Box,
  Grid,
  Button,
  Paper,
  Avatar,
  Fab,
  Card,
  Icon,
  IconButton,
} from "@material-ui/core/";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useSelector, useDispatch } from "react-redux";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import {
  ACCEPT_OR_REJECT_USER_REQUEST,
  CLEAR_GROUP_DATA,
} from "../../redux/constants/UserPlatform/EMGroupConstant";
import { EMDoSeenNotificationAction } from "../../redux/actions/EMCommonActions";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { EMDoVerifyLinkEmailAction } from "../../redux/actions/EMAuthActions";
import Parse from "html-react-parser";
import { CLEAR_CHANNEL_DATA } from "../../redux/constants/UserPlatform/EMChannelConstant";

const useStyles = makeStyles((theme) => ({
  // top: {
  //     marginTop: '5%',
  //     marginLeft: '15%',
  //     marginRight: '15%'
  // },
  // text: {

  //     marginLeft: 5,
  //     marginTop: 5,
  //     fontWeight: 'bold',
  // },
  // button: {

  //     marginLeft: "auto",
  //     marginTop: '10',
  //     variant: 'h4',
  //     fontWeight: 'bold'
  // },
  root: {
    display: "flex",
    marginRight: "5%",
    borderBottom: "1px solid",
    // marginBottom: '20px',
    padding: "20px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  groupInfo: {
    display: "flex",
    marginRight: "5%",
    // borderBottom: "1px solid",
    // marginBottom: '20px',
    padding: "20px",
  },
  avtartStyle: {
    marginLeft: "-1rem",
    marginTop: "2%",
  },
  fabStyle: {
    borderRadius: "2px",
    marginLeft: "10px",
    marginRight: "20px",
  },
  notification: {
    paddingBottom: "1rem",
    fontWeight: "bolder",
  },
  spanNotification: {
    marginLeft: "10%",
    width: "auto",
    height: "auto",
    color: "white",
    // textAlign: "center",
    fontWeight: "bold",
    color: "white",
    fontSize: "large",
  },
}));
export default function Notifications({
  notificationData,
  noOfNotifications,
  handleclose,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  function ChangeDate(date) {
    let newDate = new Date(date);
    var hours = newDate.getHours();
    var minutes = newDate.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    let dateToDisplay =
      newDate.getDate() +
      " " +
      monthNames[newDate.getMonth()] +
      " " +
      newDate.getFullYear() +
      " " +
      strTime;

    return dateToDisplay;
  }
  return (
    <Grid
      item
      style={{
        marginBottom: "15px",
        paddingLeft: "15px",
        paddingRight: "15px",
        marginTop: "18px",
        width: "100%",
      }}
    >
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        style={{
          padding: "0px 4px 0px 4px",
          backgroundColor: "#2F92D6",
          height: "auto",
        }}
      >
        <Typography
          style={{
            margin: 10,
            fontWeight: "bold",
            color: "white",
            fontSize: "large",
          }}
        >
          Notifications
        </Typography>
        <Box>

          <span
            className={classes.spanNotification}
            style={{ borderRadius: "2px" }}
          >
            <icon style={{ padding: "2%" }}>{"("+ noOfNotifications + ")"}</icon>
          </span>
        </Box>
      </Box>

      <div>
        {notificationData &&
          notificationData.map((notification) =>
            notification.notification_type === "RequestedToLinkProfile" ? (
              <div style={{
              marginRight: "5%",
              borderBottom: "1px solid",
              // marginBottom: '20px',
              padding: "20px",
              "&:hover": {
                cursor: "pointer",
              },}}>
                <Grid item xs={12}>
                  <Typography color="textprimary" style={{ padding: "10px" }}>
                    {Parse(notification.notification_description)}
                  </Typography>
                </Grid>
                <Grid container direction="row" >
                  <Grid item xs={6} container direction="row" alignItems="center" justifyContent="center">
                    <Fab
                      onClick={() => {
                        dispatch(
                          EMDoVerifyLinkEmailAction({ data: notification.key })
                        );
                        dispatch(
                          EMDoSeenNotificationAction({ id: notification.id, from:"popup" })
                        );
                      }}
                      size="small"
                      color="primary"
                      style={{
                        borderRadius: "2px",
                        marginLeft: "20px",
                        marginRight: "20px",
                      }}
                    >
                      <CheckIcon />
                    </Fab>
                  </Grid>
                  <Grid item xs={6} container direction="row" alignItems="center" justifyContent="center">
                    <Fab
                      onClick={() => {
                        dispatch(
                          EMDoSeenNotificationAction({ id: notification.id, key:"cancel_linking_request", from:"popup" })
                        );
                      }}
                      size="small"
                      style={{ borderRadius: "2px" }}
                    >
                      <CloseIcon />
                    </Fab>
                  </Grid>
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    style={{ padding: "10px" }}
                  >
                    {notification.updated_on &&
                      ChangeDate(notification.updated_on)}
                  </Typography>
                </Grid>
              </div>
            ) : (
              <div className={classes.root}>
                <Grid item xs={11}>
                  <Typography
                    onClick={() => {
                      if (
                        notification.notification_type == "AddedToGroup" ||
                        notification.notification_type == "Group Created"
                      ) {
                        dispatch({ type: CLEAR_GROUP_DATA });
                        navigate("/users/groups/browsegroups/activity", {
                          state: { GroupId: notification.entity_id },
                        });
                        window.sessionStorage.setItem(
                          "GroupId",
                          notification.entity_id
                        );
                        dispatch(
                          EMDoSeenNotificationAction({ id: notification.id, from:"popup" })
                        );
                      } else if (
                        notification.notification_type == "AddedToChannel" ||
                        notification.notification_type == "Channel Created"
                      ) {
                        dispatch({ type: CLEAR_CHANNEL_DATA });
                        navigate("/users/channels/browsechannels/activity", {
                          state: { ChannelId: notification.entity_id },
                        });
                        window.sessionStorage.setItem(
                          "ChannelId",
                          notification.entity_id
                        );
                        dispatch(
                          EMDoSeenNotificationAction({ id: notification.id, from:"popup" })
                        );
                      } else if (
                        notification.notification_type == "AddedToPartnership"
                      ) {
                        navigate("/users/networks/details", {
                          state: { NetworkId: notification.entity_id },
                        });
                        window.sessionStorage.setItem(
                          "NetworkId",
                          notification.entity_id
                        );
                        dispatch(
                          EMDoSeenNotificationAction({ id: notification.id, from:"popup" })
                        );
                      } else if (
                        notification.notification_type == "Commented" ||
                        notification.notification_type == "Posted" ||
                        notification.notification_type == "Tagged"
                      ) {
                        navigate(`/users/taggedpost/${notification.entity_id}`);
                        dispatch(
                          EMDoSeenNotificationAction({ id: notification.id, from:"popup" })
                        );
                      }
                    }}
                    color="textprimary"
                    style={{ padding: "10px" }}
                  >
                    {Parse(notification.notification_description)}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    style={{ padding: "10px" }}
                  >
                    {notification.updated_on &&
                      ChangeDate(notification.updated_on)}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    onClick={() => {
                      dispatch(
                        EMDoSeenNotificationAction({ id: notification.id, from:"popup" })
                      );
                    }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Grid>
              </div>
            )
          )}
      </div>
      <Button
        style={{ marginLeft: "25%", marginTop: "5%", width: "50%" }}
        variant="contained"
        color="primary"
        onClick={() => {
          handleclose();
          window.sessionStorage.setItem("previousPath", location.pathname);
          navigate("/users/notifications", {
            state: { path: location.pathname },
          });
        }}
      >
        View All
      </Button>
    </Grid>
  );
}
