import {SHOW_MESSAGE} from "../../../constants/index";

import API from "../../../../api";
import { MY_OPEN_ENDED_QUESTIONS, MY_OPEN_POLL_DETAILS, MY_POLLS, MY_POLL_DETAILS } from "../../../constants/UserPlatform/EMPollConstant";
import { handleError } from "../../../../utils/EMCommonFunctions";
export const EMDoGetPollsListAction = (action) => {
    return (dispatch) => {
      API.EMDoGetMyPolls(action)
        .then((data) => data.data)
        .then((response) => {
          if (response) {
            dispatch({
              type: MY_POLLS,
              payload: {
                response: response,
                userData: action.userData,
              },
            });
          } else {
            dispatch({
              type: SHOW_MESSAGE,
              payload: {
                type: "error",
                message: "Error occured while fetching poll list",
              },
            });
          }
        })
        .catch((err) => {
          handleError(dispatch, err)
        });
    };
  };

  export const EMDoGetMYOpenEndedQuestionAction = (action) => {
    return (dispatch) => {
      API.EMDoGetMYOpenEndedQuestion(action)
        .then((data) => data.data)
        .then((response) => {
          if (response) {
            dispatch({
              type: MY_OPEN_ENDED_QUESTIONS,
              payload: {
                response: response,
                userData: action.userData,
              },
            });
          } else {
            dispatch({
              type: SHOW_MESSAGE,
              payload: {
                type: "error",
                message: "Error occured while fetching poll list",
              },
            });
          }
        })
        .catch((err) => {
          handleError(dispatch, err)
        });
    };
  };

  export const EMDoGetPollDetailsForOwnerAction = (action) => {
    return (dispatch) => {
      API.EMDoGetPollDetailsForOwner(action.pollId, action.query)
        .then((data) => data.data)
        .then((response) => {
          if (response) {
            
            dispatch({
              type: MY_POLL_DETAILS,
              payload: 
                response
            });
          }
        })
        .catch((err) => {
          handleError(dispatch, err)
        });
    };
  };

  export const EMDoExportPollDetailsForOwnerAction = (action) => {
    return (dispatch) => {
      API.EMDoExportPollDetailsForOwner(action.pollId)
      .then(data => data.data)
      .then(response => {
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'PollReport.csv');
          document.body.appendChild(link);
          link.click();
          link.remove();
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Poll report exported successfully",
              key: action.key
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to export poll report"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
    };
  };

  export const EMDoGetOpenPollDetailsForOwnerAction = (action) => {
    return (dispatch) => {
      API.EMDoGetOpenPollDetailsForOwner(action.pollId, action.query)
        .then((data) => data.data)
        .then((response) => {
          if (response) {
            
            dispatch({
              type: MY_OPEN_POLL_DETAILS,
              payload: 
                response
            });
          }
        })
        .catch((err) => {
          handleError(dispatch, err)
        });
    };
  };

  export const EMDoExportOpenPollDetailsForOwnerAction = (action) => {
    return (dispatch) => {
      API.EMDoExportOpenPollDetailsForOwner(action.pollId)
      .then(data => data.data)
      .then(response => {
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'PollReport.csv');
          document.body.appendChild(link);
          link.click();
          link.remove();
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Poll report exported successfully",
              key: action.key
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to export poll report"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
    };
  };