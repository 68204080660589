import palette from '../palette'
export default {

//    root: {
//     '@global': {
//         '*::-webkit-scrollbar': {
//           width: '0.4em'
//         },
//         '*::-webkit-scrollbar-track': {
//           '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
//         },
//         '*::-webkit-scrollbar-thumb': {
//           backgroundColor: 'rgba(0,0,0,.1)',
//         }
//       }
//    }, 

   MuiToolbar: {
        regular: {
            //height : 101
        }
    },
    // MuiSvgIcon: {
    //     root: {
    //         width: "1em",
    //         height: "1.2em"
    //     }
    // },

    MuiDrawer: {
        paper: {
            background: palette.secondary.main
        }
    },
    MuiTableHead: {
        root: {
            backgroundColor: palette.secondary.main,
            borderRadius: 5,
            '&:hover': {
                backgroundColor: '#ffffff',
            },
        }
    },

    MuiTableRow : {
        footer : {
            display : 'flex',
            justifyContent : 'center'
        },
        root: {
            '&:nth-of-type(even)': {
              backgroundColor: "#E5E5E5",
            },
          },

    },


    MuiTableCell : {
        root : {
            padding: 0,
        },
        head : {
            padding: 0,
        }
    },

    MuiTabs : {
        root: {
            minHeight : window.screen.height/20,
        }
    },

    MuiTab : {
        root: {
            minHeight : 0,
        }
    },

    MuiSelect: {
        icon: {
            top: 0
        }
    },

    MuiTablePagination: {
        root: {
            marginTop: 5,
            borderBottom: "none",
        },
        spacer: {
            flex: 'none'
        },
        toolbar: {
            paddingRight: 0,
            justifyContent: 'center'
        }
    },

    MuiPaper:{
        elevation1: {
            boxShadow : "none",
        }
    },

    // MuiTypography: { 
    //     root: { 
    //       wordWrap: "break-word"
    //     },}

    
}