//Main Page
export const GROUP_LIST_MY_GROUPS = "group_list_my_group";
export const GROUP_LIST_MY_JOINT_GROUPS = "group_list_my_joint_groups";
export const GROUP_LIST_MY_JOINT_GROUPS_TOTAL_NUMBER = "group_list_my_joint_groups_total_number"
export const GROUP_EDIT = "group_edit";
export const LEAVE_GROUP="leave_group";
export const SHOW_CREATE_POST = "show_create_post";
export const TO_GET_THE_POLL_FORM = "to_get_the_poll_form";
export const SHOW_ALL_GROUPS = "show_all_groups";
export const GROUP_BROWSE_ALL_GROUPS = "group_browse_all_groups";
export const GROUP_NETWORK_FILTER = "group_network_filter";
export const GROUP_APPLY_NETWORK_FILTER = "group_apply_network_filter";
export const GROUP_INFO_MODAL = "group_info_modal";
export const FILTERED_GROUPS = "filtered_groups";
export const ACTION_FOR_GROUPS = "action_for_groups";
export const SEND_REQUEST_FOR_JOINING_THE_GROUP = "send_request_for_joining_the_group";
export const ACCEPT_OR_REJECT_USER_REQUEST = "accept_or_reject_user_request";

//Activity
export const GET_DETAILS_FOR_GROUP = "get_details_for_group";
export const CHANNEL_INFO_POP_UP = "channel_info_pop_up";
export const GET_CHANNEL_LIST = "get_channel_list";
export const LIST_GROUP_POSTS = "list_group_posts";
export const POST_CATEGORY_DATA = "group_post_category_data";
export const POST_CATEGORY_SELECTED_DATA = "post_category_selected_data";
export const GET_PERMISSION_DATA = "get_permission_data";
export const SHOW_INFO_OF_GROUP = "show_info_of_group";
export const SHOW_NEXT_POSTS = "show_next_posts";

// export const DETAILS_FOR_GROUP_EDIT = "details_for_group_edit"
export const GET_DETAILS_OF_GROUP_FOR_NONMEMBERS = "get_details_of_group_for_nonmembers";
export const LIST_USER_GROUP_POSTS_CATEGORY_SELECTED = "list_user_group_posts_category_selected";

//Members
export const GET_MEMBERS_OF_GROUP = "get_members_of_group";
export const SHOW_NEXT_MEMBERS = "show_next_members";
export const GET_FILTERED_MEMBERS_OF_GROUP = "get_filtered_members_of_group";
export const REMOVE_MEMBERS_FROM_FILTERED_GROUP = "remove_members_from_filtered_group";

//settings
export const SHOW_USER_REQUESTS = "show_user_requests";

//clearing the data
export const CLEAR_GROUP_DATA = "clear_group_data"

//disable enable filter checkbox
export const DISABLE_ENABLE_FILTER = "disable_enable_filter"
export const DISABLE_ENABLE_SEARCH_INTERNAL_GROUPS = "disable_enable_search_internal_groups"
export const DISABLE_ENABLE_SEARCH_JOINT_GROUPS = "disable_enable_search_joint_groups"
export const DISABLE_ENABLE_SEARCH_BROWSE_GROUPS = "disable_enable_search_browse_groups"
export const DISABLE_ENABLE_SEARCH_GROUP_MEMBERS = "disable_enable_search_group_members"