import React, { useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { SvgIcon, Typography, Paper, Tooltip } from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { useSelector, useDispatch } from "react-redux";
import { find } from "lodash";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    backgroundColor: "white",
    borderRadius: "5px",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);",
    // backgroundColor :theme.palette.primary,
  },
  flexcontainer: {
    display: "flex",
    alignItems: "center",
    // justifyContent: "flex-start",
    overflow: "none",
  },
  menu: {
    justifyContent: "flex-start",
  },
  label: {
    "&:focus": {
      color: theme.palette.common.black,
    },
  },
  select: {
    height: "40px",
    borderRadius: "5px",
  },
}));

export default function EMProfileSwitchDropdown({
  name,
  onSelect,
  error,
  helperText,
  isDisabled,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.auth["multipleProfiles"]);
  const [preValue, setPrevalue] = React.useState();
  const [org_name, setOrg_Name] = React.useState("");
  const selectedData = useSelector((state) => state.auth["profile_id"]);

  React.useEffect(() => {
    let preValue = selectedData
      ? data && data.find((x) => x.profile_id == selectedData)
      : "";
    setPrevalue(preValue);
  }, [selectedData, preValue, data]);

  React.useEffect(() => {
    if (preValue) {
      setOrg_Name(preValue.organization_name);
    }
  }, [preValue]);

  const ITEM_HEIGHT = 50;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <FormControl
      className={classes.formControl}
      // disabled={isDisabled}
      variant="outlined"
    >
      <Select
        className={classes.select}
        // MenuProps={MenuProps}
        name={org_name}
        placeholder={name}
        labelId="org_network_select"
        value={org_name}
        onChange={(selected) => {
          let selectedOption = selected.target.value;
          let newObject = data.find(
            (x) => x.organization_name == selectedOption
          );
          let id = newObject.profile_id;
          onSelect(id);
        }}
        error={error}
        helperText={helperText}
      >
        {data.map((d) => {
          let dataToShow = d["organization_name"];
          return (
            <MenuItem key={dataToShow} value={dataToShow}>
              <div className={classes.flexcontainer}>
                <ListItemIcon>
                  <div
                    style={{
                      height: "37px",
                      width: "100%",
                      whiteSpace: "nowrap",
                      textAlign: "center",
                    }}
                  >
                    <span
                      style={{
                        display: "inline-block",
                        height: "100%",
                        verticalAlign: "middle",
                      }}
                    ></span>
                    <img
                      style={{ maxHeight: "100%", maxWidth: "100%" }}
                      alt=""
                      src={
                        process.env.REACT_APP_BACKEND_FILE_PREVIEW +
                        d["organization_logo_file"]
                      }
                    />
                  </div>
                </ListItemIcon>
                <Tooltip placement="top" title={<h2 style={{ color: "white" }}>{dataToShow}</h2>}  >
                <Typography style={{ fontSize: "small", fontWeight:600 }}>
                  {dataToShow.length > 15
                    ? dataToShow.slice(0, 15) + "..."
                    : dataToShow}
                </Typography>
                </Tooltip>
              </div>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
