import React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import EMPage from "../commans/EMPage";
import { Link } from "react-router-dom";
import EMUserNavigation from "./EMUserNavigation";
import { useMediaQuery } from "@material-ui/core";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: 25,
    // overflowX: "auto",
    position: "fixed",
    opacity: 5,
    backgroundColor: "#f2f2f2",
    zIndex: 2,
    width: "100%",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);",
  },
  tabLabel: {
    [theme.breakpoints.up("md")]: {
      marginTop: "4.8%",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "4.8%",
    },
  },
}));

export default function EMTabs(props) {
  const classes = useStyles();
  const theme = useTheme();
  
  let tabs =
    props.tabs &&
    props.tabs.map((tabName, index) => {
      return (
        <Tab
          label={<span className={classes.tabLabel}>{tabName.label}</span>}
          component={Link}
          to={tabName.path}
          {...a11yProps(index)}
        />
      );
    });

  let activeTab;
  props.tabs &&
    props.tabs.map((tab) => {
      if (window.location.href.includes(tab.path)) {
        activeTab = tab.id;
      }
    });

  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      <AppBar color="default">
        <Tabs
          value={activeTab == undefined ? props.value : activeTab}
          onChange={props.changeHandler}
          indicatorColor="primary"
          textColor="primary"
          style={{
            marginTop: matches ? 64 : 113,
          }}
          className={classes.root}
          variant="scrollable"
          scrollButtons={true}
          aria-label="full width tabs example"
        >
          {tabs}
        </Tabs>
      </AppBar>
    </>
  );
}
