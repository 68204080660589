import { Box, IconButton, TableCell,Tooltip,Typography } from "@material-ui/core";
import EMSimpleDatatable from "../../../../../components/datatables/EMSimpleDatatable";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Checkbox from '@material-ui/core/Checkbox';
import {EMDoGetResendInviteUsersGroupAdminListAction} from '../../../../../redux/actions/EMGroupAdminActions'
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { filter as lodash_filter } from "lodash";
import moment from "moment";
import { DATATABLE_DATE_FORMAT } from "../../../../../utils/EMConstants";

const useStyles = makeStyles((theme) => ({
  typo: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 1,
    wordBreak: "break-word",
    overflow: "hidden",
  },
}));

export default function EMNetworkAdminManageUsers({ filter,data, onChangePermission, close, groupId }) {
  
  const theme = useTheme();
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(15);
  const [offset, setOffset] = useState(0);
  const [order, setOrder] = useState({ id: -1, direction: "asc" });
  const [checked, setChecked] = useState(false);
  const [sortAscending, setSortAscending] = useState(false);
  const classes = useStyles()
  const columns = [
    {
      title: "Name",
      field: "first_name",
      align: "left",
      render: data=> {
        return (
          <>
          <Tooltip title={<h2 style={{ color: "white" }}>{data["first_name"]}</h2>} placement='top'>
          <Typography className={classes.typo}>{data["first_name"] && data["first_name"] }</Typography>
          </Tooltip>
          </>
        )
      }
    },
    {
      title: "E-mail Address ",
      field: "email",
      align: "left",
      render: data=> {
        return (
          <>
          <Tooltip title={<h2 style={{ color: "white" }}>{data["email"]}</h2>} placement='top'>
          <Typography className={classes.typo}>{data["email"] && data["email"] }</Typography>
          </Tooltip>
          </>
        )
      }
    },
    {
      title: "Last Invited On ",
      // field: "sent",
      align: "left",
      render: data => {
        let date = data["sent"];
        date = moment(date).format(DATATABLE_DATE_FORMAT);
        return <Typography>{date}</Typography>;
      }
    },
    {
      title: "Invite",
      align: "left",
      render: data => {
        
        return (
       <Checkbox
    
       checked = {data["add_flag"]  || false} 
       onChange={event => {
        
        let state = event.target.checked;
        data["add_flag"] = state;
        
         
        onChangePermission(data)
      }}/>
        );
      }
    }
     
  ];

  const updateData = query => {
    dispatch(EMDoGetResendInviteUsersGroupAdminListAction(query));
  };

  useEffect(() => {
    updateData("?");
  }, []);

  useEffect(() => {
    const query = [];
    if (limit !== 0) {
      query.push(`limit=${limit}`);
    }
    if (filter) {
      query.push(`search=${filter}`);
    }
    if (offset !== 0 && !filter) {
      query.push(`offset=${offset}`);
    }
    if (order.id !== -1) {
      if(sortAscending === false){
        query.push(
          `ordering=${order.direction === "desc" ? "-" : ""}${
            columns[order.id-1].field
          }`
        );
        setSortAscending(true)
      }else{
        // order.direction = "desc"
        query.push(
          `ordering=${order.direction === "desc" ? "-" : ""}${
            columns[order.id-1].field
          }`
        );
        setSortAscending(false)
      }
    }
    if (groupId) {
      query.push(
        `group=${groupId}`,
      );
    }
    updateData("?" + query.join("&"));
  }, [limit, filter, offset, order, groupId]);

  return (
    <Box p={2} onClose={close}>
      <EMSimpleDatatable
      filter={filter}
        columns={columns}
        data={data["results"]}
        enableCustomPagination={false}
        count={data["count"] || 0}
        updatePageAndPageSizeAndOrder={(page, pageSize, order) => {
          setOffset(page * limit);
          setLimit(pageSize);
          setOrder(order)
        }} 
        options={{
          headerStyle: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.common.white,
            fontWeight: "bold",
          },
        }}
      />
    </Box>
  );
}
