import React, { useState, useEffect } from "react";
import {
  Paper,
  Box,
  Button,
  Grid,
  makeStyles,
  Modal,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import EMPage from "../../../../components/commans/EMPage";
import EMUsersTable from "./EMUserTable";
import EMChannelDropdown from "../../commons/channelDropdown";
import EMDatatableSearchView from "../../../../components/datatables/EMDatatableSearchView";
import EMImportUsers from "./ImportUsers/";
import EMResendInvites from "./ResendInvites/";
import {
  EMDoGetChannelAdminChannelListDropDownAction,
  EMDoGetChannelUserRolesListAction,
  EMDoExportChannelAdminUsersAction,
  EMDoGetListOfChannelsForDropdownInUserManagementAction,
  EMDoGetPermissionSpecificToChannelAction,
} from "../../../../redux/actions/EMChannelAdminActions";
import { useSelector, useDispatch } from "react-redux";
import { find, findLast, isEmpty } from "lodash";
import EMEditUserDetails from "./EMEditUserDetails";
import { CHANNEL_ADMIN_ADD_REMOVE_IMPORT_USERS_FROM_SECONDARY_ARRAY, CHANNEL_ADMIN_IMPORT_USERS_SELECT_ALL_FLAG, CHANNEL_ADMIN_UPDATE_USERS_LIST } from "../../../../redux/constants";

const useStyles = makeStyles((theme) => ({
  button: {
    // margin: 10
    margin: 10,
    width: 170,
    height: 40,
  },
  justifyEnd: {
    [theme.breakpoints.up("md")]: {
      justifyContent: "end",
    },
    [theme.breakpoints.up("lg")]: {
      justifyContent: "end",
    },
  },
  widthForSearchBar: {
    [theme.breakpoints.up("xs")]: {
      //width: "12ch",
      width: "100%",
      height: "fit-content",
    },
    [theme.breakpoints.up("sm")]: {
      //width: "12ch",
      width: "100%",
      height: "fit-content",
    },
    [theme.breakpoints.up("md")]: {
      //width: "12ch",
      width: "100%",
      height: "fit-content",
    },
    [theme.breakpoints.up("lg")]: {
      //width: "12ch",
      width: "100%",
      height: "fit-content",
    },
  },
}));

export default function EMManageChannelUsers(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState("");
  // const data = useSelector(state => state.networkAdmin["networkList"]);
  const data = useSelector((state) => state.auth["entityDetails"]);
  const [open, setOpen] = React.useState(false);
  const [editData, setEditData] = React.useState({});
  const [importUserActive, setImportUserActive] = React.useState(false);
  const [resendInviteActive, setResendInviteActive] = React.useState(false);
  const [channelId, setChannelId] = React.useState();
  const [showChannelError, setShowChannelError] = React.useState();
  const [isError, setIsError] = React.useState(true);
  const userData = useSelector((state) => state.auth);
  const tableData = useSelector(
    (state) => state.channelAdmin.channelUsersList || {}
  );
  const [permissionData, setPermissionData] = React.useState();
  const channelSpecificPermissions = useSelector(
    (state) => state.channelAdmin.channelSpecificRolesAndPermissions || {}
  );

  //rolepermissions based on group selection
  useEffect(() => {
    
    if (channelSpecificPermissions) {
      let permission = find(channelSpecificPermissions, { context: channelId });
      let specificPermission = find(permission && permission.rolePermissions, {
        permission_name: "manage_users",
      });
      setPermissionData(specificPermission);
    }
  }, [channelSpecificPermissions]);

  //call dropdown Api
  useEffect(() => {
    dispatch(EMDoGetListOfChannelsForDropdownInUserManagementAction());
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditData({});
  };

  const uiData = useSelector((state) => state.ui);

  useEffect(() => {
    if (uiData["messages"]) {
      let refObj = find(uiData["messages"], { key: "user_edit" });
      if (refObj && refObj.type === "success") {
        setOpen(false);
      }
    }
  }, [uiData]);

  useEffect(() => {
    dispatch(EMDoGetChannelAdminChannelListDropDownAction());
  }, []);

  useEffect(() => {
    if (channelId) {
      setShowChannelError("");
      let id = channelId;
      dispatch(EMDoGetChannelUserRolesListAction(id));
      dispatch(EMDoGetPermissionSpecificToChannelAction({ id: id }));
    }
  }, [channelId]);

  useEffect(() => {
    if (uiData["messages"]) {
      let refObj1 = findLast(uiData["messages"], { key: "users_import" });
      if (refObj1 && refObj1.type === "success") {
        
        setImportUserActive(false);
        refObj1.key = "users_import_successfully";
        dispatch({
          type: CHANNEL_ADMIN_IMPORT_USERS_SELECT_ALL_FLAG,
          payload: { from: "selectAll", state: false },
        });
        dispatch({
          type: CHANNEL_ADMIN_ADD_REMOVE_IMPORT_USERS_FROM_SECONDARY_ARRAY,
          payload: { data: [], from: "selectAll", state: false },
        });
        dispatch({
          type:CHANNEL_ADMIN_UPDATE_USERS_LIST,
          payload:true
        })
      }
      let refObj2 = findLast(uiData["messages"], {
        key: "resend_list_success_ca",
      });
      if (refObj2 && refObj2.type === "success") {
        setResendInviteActive(false);
        refObj2.type = "Modal closed";
      }
    }
  }, [uiData]);

  const channelSelectionHandler = () => {
    if (channelId == undefined) {
      setShowChannelError("Please Select Channel Here");
      setIsError(false);
    } else {
      setShowChannelError("");
      setIsError(true);
    }
  };

  return (
    <EMPage title="Channel - Manage Users">
      <Box>
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={uiData.loading.state}
        >
          <Box
            style={{ display: "flex" }}
            alignItems="center"
            justifyContent="center"
          >
            <Box textAlign="center">
              <CircularProgress />
              <Typography style={{ fontWeight: "medium", fontSize: "large" }}>
                {uiData.loading.message}
              </Typography>
            </Box>
          </Box>
        </Modal>
        <Paper elevation={2}>
          {/* <Box height="100%" display="flex" flexDirection="column"> */}
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            p={3}
            justifyItems="center"
            alignItems="center"
          >
            <Grid container>
              <Grid container spacing={40} style={{ maxWidth: "100%" }}>
                <Grid justify="flex-start" item xs={12} sm={4} md={4} lg={4}>
                  <EMChannelDropdown
                    name="channel"
                    error={showChannelError}
                    onSelect={(id) => {
                      setChannelId(id);
                    }}
                  />
                </Grid>
                <Grid
                  container
                  className={classes.justifyEnd}
                  item
                  xs={12}
                  sm={4}
                  md={8}
                  lg={8}
                >
                  <Button
                    className={classes.button}
                    disabled={
                      !((permissionData && permissionData["add_flag"]) || true)
                    }
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      channelSelectionHandler();
                      if (channelId !== undefined) {
                        setImportUserActive(true);
                        dispatch({
                          type:CHANNEL_ADMIN_UPDATE_USERS_LIST,
                          payload:false
                        })
                      }
                    }}
                  >
                    {"Import Users"}
                  </Button>
                </Grid>
              </Grid>
              <Box>
                <EMImportUsers
                  open={importUserActive}
                  close={() => {
                    channelSelectionHandler();
                    setImportUserActive(false);
                  }}
                  userData={editData}
                  channelId={channelId}
                  closeHandler={setImportUserActive}
                />
              </Box>

              <Grid
                container
                style={{ maxWidth: "100%" }}
                justify="flex-start"
                alignItems="center"
                item
                xs={12}
                sm={4}
                md={6}
                lg={8}
              >
                <Box
                  width="100%"
                  justify="flex-start"
                  className={classes.widthForSearchBar}
                  xs={12}
                  sm={4}
                  md={6}
                  lg={8}
                >
                  <EMDatatableSearchView
                    hint={"Search by User Name, E-mail Address"}
                    onChangeListener={(data) => {
                      setFilter(data);
                    }}
                  />
                </Box>
              </Grid>
              <Grid
                container
                className={classes.justifyEnd}
                item
                xs={12}
                sm={4}
                md={4}
                lg={4}
              >
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    channelSelectionHandler();
                    if (channelId) {
                      if (tableData) {
                        let contextData = {
                          context: channelId,
                        };
                        let data = {
                          ...tableData,
                          key: "export_user_success",
                        };
                        dispatch(
                          EMDoExportChannelAdminUsersAction({
                            data,
                            contextData,
                            key: "user_export",
                          })
                        );
                      }
                    }
                  }}
                >
                  {"Export Users"}
                </Button>
              </Grid>
            </Grid>
          </Box>
          <EMUsersTable
            filter={filter}
            channelId={channelId}
            permissionData={permissionData}
            onEdit={(roleData) => {
              setEditData(roleData);
              setOpen(true);
            }}
          />
          <Box>
            <EMEditUserDetails
              open={open}
              close={handleClose}
              userData={editData}
              permissionData={permissionData}
            />
          </Box>
          {/* </Box> */}
        </Paper>
      </Box>
    </EMPage>
  );
}
