import React, { useEffect, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import * as Emoji from "quill-emoji";
import "react-quill/dist/quill.snow.css";
import "quill-emoji/dist/quill-emoji.css";
import "quill-mention/dist/quill.mention.css";
import "quill-mention";

//Add https to link if https is not present
const Link = Quill.import("formats/link");
Link.sanitize = function (url) {
  // quill by default creates relative links if scheme is missing.
  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    return `http://${url}`;
  }
  return url;
};
Quill.register(Link, true);
Quill.register("modules/emoji", Emoji);

// Add sizes to whitelist and register them
const Size = Quill.import("formats/size");
Size.whitelist = ["extra-small", "small", "medium", "large"];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import("formats/font");
Font.whitelist = [
  "arial",
  "comic-sans",
  "courier-new",
  "georgia",
  "helvetica",
  "lucida",
];
Quill.register(Font, true);

let atValues = [];
let hashValues = [];

// const editor = new Quill(document.getElementById(this.id), options)

const mention = {
  allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
  mentionDenotationChars: ["@", "#"],
  linkTarget: "_self",
  defaultMenuOrientation: "bottom",
  source: function (searchTerm, renderList, mentionChar) {
    let values;

    if (mentionChar === "@") {
      values = atValues;
    } else {
      values = hashValues;
    }

    if (searchTerm.length === 0) {
      renderList(values, searchTerm);
    } else {
      const matches = [];
      for (let i = 0; i < values.length; i++)
        if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()))
          matches.push(values[i]);
      renderList(matches, searchTerm);
    }
  },
};

function Editor(props) {
  const [editorHtml, setEditorHtml] = useState("");

  const handleChange = (html) => {
    setEditorHtml(html);
    props.changeHandler(html);
  };

  useEffect(() => {
    if (props.value) {
      setEditorHtml(props.value);
    } else {
      setEditorHtml("");
    }
    if (props.values) {
      let hash = props.values;
      hash.map((v) => {
        v["value"] = v["display"];
        v["link"] = `/#/users/hashtags/${v.id}`;
      });
      hashValues = hash;
    }
    if (props.people) {
      let peoples = props.people;
      peoples.map((v) => {
        v["value"] = v["display"];
        v["link"] = `/#/users/people/${v.id}`;
      });
      atValues = peoples;
    }
  }, [props.value, props.people]);

  return (
    <div>
      <ReactQuill
        onChange={handleChange}
        value={editorHtml}
        modules={modules}
        formats={formats}
        bounds={".app"}
        placeholder={props.placeholder}
      />
    </div>
  );
}

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ list: "ordered" }, { list: "bullet" }],
    ["bold", "italic", "underline"],
    [
      {
        color: [],
      },
      { background: [] },
    ],
    // [{ script: 'sub' }, { script: 'super' }],
    [{ align: [] }],
    ["link", "blockquote", "emoji"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
  mention,
  "emoji-toolbar": true,
  "emoji-textarea": false,
  "emoji-shortname": true,
};

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "align",
  "link",
  "mention",
  "emoji",
  'background', 'color',
];

export default function EMTextArea({
  placeHolder,
  name,
  value,
  changeHandler,
  hash,
  peopleMention,
}) {
  return (
    <div className="custom-toolbar-example">
      <Editor
        placeholder={placeHolder}
        name={name}
        value={value}
        changeHandler={changeHandler}
        values={hash}
        people={peopleMention}
      />
    </div>
  );
}
