import {
  GROUP_ADMIN_GROUP_LIST_DROPDOWN_SUCCESS,
  GROUP_ADMIN_GROUP__USERS_LIST_DROPDOWN,
  GROUP_ADMIN_DETAILS_BY_ID,
  GROUP_ADMIN_CHANNEL_DETAILS_BY_ID,
  GROUP_ADMIN_GROUP_LIST_SUCCESS,
  GROUP_ADMIN_CHANNEL_LIST_SUCCESS,
  GROUP_ADMIN_CHANNEL_CREATE_SUCCESS,
  GROUP_ADMIN_GROUP_UPDATE_SUCCESS,
  GROUP_ADMIN_GROUP_USER_ROLE_DROPDOWN_SUCCESS,
  GROUP_ADMIN_USER_DETAILS_UPDATE_SUCCESS,
  GROUP_ADMIN_CHANNEL_UPDATE_SUCCESS,
  GROUP_ADMIN_GROUP_LIST_DROPDOWN_FOR_CHANNEL_SUCCESS,
  GROUP_ADMIN_GROUP_SETTINGS_UPDATE_SUCCESS,
  GROUP_ADMIN_CHANNEL_SETTINGS_UPDATE_SUCCESS,
  GROUP_ADMIN_IMPORT_USERS_LIST_SUCCESS,
  GROUP_ADMIN_RESEND_USERS_LIST_SUCCESS,
  GROUP_ADMIN_RESEND_INVITE_SUCCESS,
  GROUP_ADMIN_EMAIL_TEMPLATE_TYPE_LIST_SUCCESS,
  GROUP_ADMIN_LIST_ROLES_SUCCESS,
  GROUP_ADMIN_ROLE_CREATE_SUCCESS,
  GROUP_ADMIN_GROUP_SETTINGS_UPDATE_SUCCESS_FOR_USER_PLATFORM,
  GROUP_ADMIN_SAVE_IMPORT_USER_DATA,
  GROUP_ADMIN_REMOVE_USER,
  GROUP_ADMIN_GET_DASHBOARD_DATA,
  GROUP_ADMIN_ENTITY_LIST,
  RESET,
  GROUP_ADMIN_GET_GROUP_LIST,
  GROUP_ADMIN_GET_USER_ENGAGEMENT_DATA,
  GROUP_ADMIN_GET_DATA_FOR_DROPDOWN_IN_GAMIFICATION,
  GROUP_ADMIN_GET_LEVEL_REPORT,
  GROUP_ADMIN_GET_BADGE_REPORT,
  GROUP_ADMIN_GET_LIST_OF_GROUPS_IN_USER_MANAGEMENT,
  GROUP_ADMIN_GET_PERMISSION_BASED_ON_GROUP,
  GROUP_ADMIN_IMPORT_USERS_SELECT_ALL_FLAG,
  GROUP_ADMIN_SELECT_UNSELECT_USER_IN_IMPORT_USERS,
  GROUP_ADMIN_ADD_REMOVE_IMPORT_USERS_FROM_SECONDARY_ARRAY,
  AUTH_LOGOUT,
  GROUP_ADMIN_IMPORT_USERS_CLEAR_UNCLEAR_SELECT_ALL_WRT_SEARCH,
  GROUP_ADMIN_UPDATE_USERS_LIST,
  GROUP_ADMIN_UPDATE_GROUPS_LIST,
} from "../constants";
import { cloneDeep, find, findIndex } from "lodash";
import { Dashboard } from "@material-ui/icons";
import { isEmpty } from "lodash";

const initialState = {
  importUsersList: {},
  selectAll: false,
  checkedUsers: [],
  updateUsersList:false,
  updateGroupsList: false
};

export default function EMCommonReducer(state = initialState, action) {
  const newState = cloneDeep(state);

  switch (action.type) {
    case GROUP_ADMIN_LIST_ROLES_SUCCESS: {
      newState["roles"] = action.payload;
      return newState;
    }
    case GROUP_ADMIN_ROLE_CREATE_SUCCESS: {
      newState["roles"]["results"].unshift(action.payload);
      newState["roles"]["count"] = newState["roles"]["count"]++;
      return newState;
    }
    case GROUP_ADMIN_GROUP_LIST_DROPDOWN_SUCCESS: {
      newState["groupDropdownList"] = action.payload;
      return newState;
    }

    case GROUP_ADMIN_GET_DATA_FOR_DROPDOWN_IN_GAMIFICATION: {
      newState["gamificationDropdownData"] = action.payload;
      return newState;
    }

    case GROUP_ADMIN_UPDATE_USERS_LIST: {
      newState["updateUsersList"] = action.payload
      return newState
    }

    case GROUP_ADMIN_UPDATE_GROUPS_LIST: {
      newState["updateGroupsList"] = action.payload
      return newState
    }

    case GROUP_ADMIN_GET_LEVEL_REPORT: {
      newState["levelReport"] = action.payload;
      return newState;
    }

    case GROUP_ADMIN_GET_BADGE_REPORT: {
      if (action.payload) {
        newState["badgeReport"] = action.payload;
        let result = Object.keys(action.payload.report).map((key) => ({
          badgeName: key,
          userCount: action.payload.report[key],
        }));
        newState["badgeReport"] = {
          ...newState["badgeReport"],
          newBadges: result,
        };
      }
      return newState;
    }

    case GROUP_ADMIN_GROUP_LIST_DROPDOWN_FOR_CHANNEL_SUCCESS: {
      newState["groupDropdownListForChannel"] = action.payload;
      return newState;
    }

    case GROUP_ADMIN_GROUP__USERS_LIST_DROPDOWN: {
      newState["groupUsersList"] = action.payload;
      return newState;
    }

    case GROUP_ADMIN_DETAILS_BY_ID: {
      newState["groupDetails"] = action.payload;
      return newState;
    }

    case GROUP_ADMIN_GROUP_SETTINGS_UPDATE_SUCCESS_FOR_USER_PLATFORM: {
      newState["groupDetails"] = action.payload;
      return newState;
    }

    case GROUP_ADMIN_GROUP_SETTINGS_UPDATE_SUCCESS: {
      let userId = action.payload.id;
      let userIndex = findIndex(newState["allGroupListingDetails"]["results"], {
        id: userId,
      });
      if (userIndex > -1) {
        let nextState =
          newState["allGroupListingDetails"]["results"][userIndex];
        nextState.group_name = action.payload.group_name;
        nextState.privacy_type_name = action.payload.privacy_type_name;
        nextState.privacy_type = action.payload.privacy_type;
      }
      return newState;
    }

    case GROUP_ADMIN_GROUP_LIST_SUCCESS: {
      newState["allGroupListingDetails"] = action.payload;
      return newState;
    }
    case GROUP_ADMIN_CHANNEL_LIST_SUCCESS: {
      newState["allChannelListingDetails"] = action.payload;
      return newState;
    }

    case GROUP_ADMIN_CHANNEL_CREATE_SUCCESS: {
      newState["allChannelListingDetails"]["results"].unshift(action.payload);
      newState["allChannelListingDetails"]["count"] = newState[
        "allChannelListingDetails"
      ]["count"]++;
      return newState;
    }

    case GROUP_ADMIN_CHANNEL_DETAILS_BY_ID: {
      newState["channelDetails"] = action.payload;
      return newState;
    }

    //Get group list for dashboard
    case GROUP_ADMIN_GET_GROUP_LIST: {
      newState["groupListForDashboard"] = action.payload;
      return newState;
    }

    case GROUP_ADMIN_CHANNEL_SETTINGS_UPDATE_SUCCESS: {
      let userId = action.payload.id;
      let userIndex = findIndex(
        newState["allChannelListingDetails"]["results"],
        { id: userId }
      );
      if (userIndex > -1) {
        let nextState =
          newState["allChannelListingDetails"]["results"][userIndex];
        nextState.channel_name = action.payload.channel_name;
        nextState.privacy_name = action.payload.privacy_type_name;
        nextState.privacy_type = action.payload.privacy_type;
      }
      return newState;
    }

    case GROUP_ADMIN_CHANNEL_UPDATE_SUCCESS: {
      let userId = action.payload.id;
      let userIndex = findIndex(
        newState["allChannelListingDetails"]["results"],
        {
          id: userId,
        }
      );
      if (userIndex > -1) {
        newState["allChannelListingDetails"]["results"][userIndex] = {
          ...action.payload,
        };
      }
      return newState;
    }

    case GROUP_ADMIN_GROUP_UPDATE_SUCCESS: {
      let userId = action.payload.id;
      let userIndex = findIndex(newState["allGroupListingDetails"]["results"], {
        id: userId,
      });
      if (userIndex > -1) {
        newState["allGroupListingDetails"]["results"][userIndex] = {
          ...action.payload,
        };
      }
      return newState;
    }

    case GROUP_ADMIN_GROUP_USER_ROLE_DROPDOWN_SUCCESS: {
      newState["roles"] = action.payload;
      return newState;
    }

    case GROUP_ADMIN_USER_DETAILS_UPDATE_SUCCESS: {
      let userId = action.payload.id;
      userId = userId.toString();
      let userIndex = findIndex(newState["groupUsersList"]["results"], {
        id: userId,
      });
      if (userIndex > -1) {
        // newState["groupUsersList"]["results"][userIndex] = {
        //   ...action.payload,
        // };

        let nextState = newState["groupUsersList"]["results"][userIndex];
        nextState.first_name = action.payload.first_name;
        nextState.last_name = action.payload.last_name;
        nextState.role_name = action.payload.role_name;
        nextState.role = action.payload.role;
        nextState.email = action.payload.email;
        nextState.deactivation_date = action.payload.deactivation_date;
      }
      return newState;
    }

    case GROUP_ADMIN_IMPORT_USERS_LIST_SUCCESS: {
      newState["nextUsersImport"] = action.payload.next;
      newState["importUsersList"] = action.payload;
      newState["selectAll"] = false;
      newState["importUsersList"] = {
        ...newState["importUsersList"],
        results: newState["importUsersList"].results.map((user) => ({
          ...user,
          isChecked: false,
        })),
      }
      return newState;
    }

    case GROUP_ADMIN_SELECT_UNSELECT_USER_IN_IMPORT_USERS: {
      let userId = action.payload.userId;

      var foundIndex = newState["importUsersList"].results.findIndex(
        (x) => x.id == userId
      );
      if (foundIndex >= 0) {
        newState["importUsersList"].results[foundIndex].isChecked =
          action.payload.state;
      }

      let selectAllState = newState["importUsersList"].results.every(
        (user) => user.isChecked == true
      );
      if (selectAllState) {
        newState["selectAll"] = true;
      } else {
        newState["selectAll"] = false;
      }
      return newState;
    }

    case GROUP_ADMIN_IMPORT_USERS_SELECT_ALL_FLAG: {
      newState["selectAll"] = action.payload.state;
        newState["importUsersList"] = {
          ...newState["importUsersList"],
          results: newState["importUsersList"].results.map((user) => ({
            ...user,
            isChecked: action.payload.state,
          })),
        };
      return newState;
    }

    case GROUP_ADMIN_RESEND_INVITE_SUCCESS: {
      newState["invites"] = action.payload;
      return newState;
    }

    case GROUP_ADMIN_RESEND_USERS_LIST_SUCCESS: {
      newState["resendUsersList"] = action.payload;
      return newState;
    }

    case GROUP_ADMIN_EMAIL_TEMPLATE_TYPE_LIST_SUCCESS: {
      newState["emailTemplateType"] = action.payload;
      return newState;
    }

    case GROUP_ADMIN_REMOVE_USER: {
      let data = action.payload;
      newState["groupUsersList"].results.map((groupUsersList) => {
        if (groupUsersList.id == data.user_id) {
          let removeUser = findIndex(newState["groupUsersList"].results, {
            id: data.user_id,
          });
          newState["groupUsersList"].results.splice(removeUser, 1);
        }
      });
      return newState;
    }
    // For Dashboard_data
    case GROUP_ADMIN_GET_DASHBOARD_DATA: {
      newState["dashboardDataGroup"] = action.payload;
      return newState;
    }
    //  FOR ENTITY  List Dashboard
    case GROUP_ADMIN_ENTITY_LIST: {
      newState["dashboardEntityList"] = action.payload;
      return newState;
    }
    // report for user engagement
    case GROUP_ADMIN_GET_USER_ENGAGEMENT_DATA: {
      newState["userEngagementGroup"] = action.payload;
      return newState;
    }

    case GROUP_ADMIN_SAVE_IMPORT_USER_DATA: {
      newState["importUserData"] = action.payload;
      return newState;
    }

    case GROUP_ADMIN_GET_LIST_OF_GROUPS_IN_USER_MANAGEMENT: {
      newState["groupListForUserManagement"] = action.payload;
      return newState;
    }

    case GROUP_ADMIN_GET_PERMISSION_BASED_ON_GROUP: {
      newState["groupSpecificRolesAndPermissions"] = action.payload;
      return newState;
    }

    case RESET: {
      return {};
    }

    case AUTH_LOGOUT: {
      return { ...initialState };
    }

    default: {
      return newState;
    }
  }
}
