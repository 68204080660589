import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  Box,
  Paper,
  Grid,
  Button,
  Menu,
  MenuItem,
  TextField,
  Typography,
  SvgIcon,
  Avatar,
  ListItemIcon,
  Tooltip,
  Badge,
} from "@material-ui/core";
import { find, isEmpty } from "lodash";
import { CHANGE_ELEMENT_FOR_SUBTABS } from "../../redux/constants/UserPlatform/EMNavigationConstant";
// import  ThemeProvider from "@material-ui/core";
import { red, blue } from "@material-ui/core/colors";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { Forum } from "@material-ui/icons";
import { ReactComponent as ChannelIcon } from "../../assets/Icon_material-forum.svg";
import { ReactComponent as ViewMoreIcon } from "../../assets/Icon_metro-unfold-more.svg";
import { ReactComponent as UserSecretIcon } from "../../assets/Icon_awesome-user-secret.svg";
import GroupIcon from "@material-ui/icons/Group";
// import { ReactComponent as GroupIcon } from "../../assets/Icon_material-group.svg";
import { ReactComponent as PinIcon } from "../../assets/Icon_metro-pin.svg";
import { ReactComponent as InfoIcon } from "../../assets/info-icon.svg";
import IconButton from "@material-ui/core/IconButton";
import StarRateIcon from "@material-ui/icons/StarRate";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import PublicIcon from "@material-ui/icons/Public";
import { EMDoActionForGroupAction } from "../../redux/actions/UserPlatform/Groups/EMGroupsAction";
import { EMDoActionForChannelAction } from "../../redux/actions/UserPlatform/Channels/EMChannelsAction";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { CHANNEL_COMPONENT_POP_UP } from "../../redux/constants/UserPlatform";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { CHANNEL_INFO_POP_UP, CLEAR_GROUP_DATA } from "../../redux/constants/UserPlatform/EMGroupConstant";
import { CHANNEL_POP_UP, CLEAR_CHANNEL_DATA } from "../../redux/constants/UserPlatform/EMChannelConstant";
import { EMDoMarkHighlightedChannelAsSeenAction } from "../../redux/actions/UserPlatform/Home/EMLatestPostPageAction";

const MaterialTheme = createTheme({
  overrides: {
    MuiAvatar: {
      img: {
        // objectFit: "fill",
        maxHeight: "100%",
        border: 0,
        maxWidth: "100%",
        backgroundColor: "rgba(0,0,0,0)",
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 30,
    paddingBottom: 15,
    position:"sticky"
  },
  avatarGroup: {
    [theme.breakpoints.up("lg")]:{
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    [theme.breakpoints.down("lg")]:{
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
  channelList: {
    "&:hover": {
      cursor: "pointer",
    },
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 2,
    wordBreak:"break-word",
    overflow: "hidden",
  },
  grouplist: {
    "&:hover": {
      cursor: "pointer",
    },
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 2,
    wordBreak:"break-word",
    overflow: "hidden",
  },
  avatarChannel: {
    [theme.breakpoints.up("lg")]:{
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    [theme.breakpoints.down("lg")]:{
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    "&:hover": {
      cursor: "pointer",
    },

  },
  pinIcon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  scrollForChannels: {
    marginTop: "2%",
    overflow: "auto",
    overflowX: "hidden",
    width: "100%",
    // maxHeight : window.screen.height,
    "&::-webkit-scrollbar": {
      width: "0.3em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#4A4A4A",
      outline: "1px solid slategrey",
    },
  },
  scroll: {
    marginTop: "2%",
    overflow: "auto",
    width: "100%",
    overflowX: "hidden",
    maxHeight: window.screen.height / 3,
    "&::-webkit-scrollbar": {
      width: "0.3em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#4A4A4A",
      outline: "1px solid slategrey",
    },
  },
}));

function SecretIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M12.227,9.819l.761-1.994a.509.509,0,0,0-.478-.691H10.647A4.038,4.038,0,0,0,11.214,5.1v-.01c1.248-.248,2.038-.608,2.038-1.01,0-.424-.869-.8-2.233-1.051A8.262,8.262,0,0,0,9.726.389,1.022,1.022,0,0,0,8.468.109l-.879.44a1.019,1.019,0,0,1-.911,0L5.8.109a1.022,1.022,0,0,0-1.258.28A8.329,8.329,0,0,0,3.249,3.026c-1.36.252-2.229.627-2.229,1.051,0,.4.79.761,2.038,1.01V5.1a4.038,4.038,0,0,0,.567,2.038H1.793a.509.509,0,0,0-.468.71l.822,1.917A4.266,4.266,0,0,0,0,13.453V14.88a1.429,1.429,0,0,0,1.427,1.427H12.841a1.429,1.429,0,0,0,1.427-1.427V13.453a4.268,4.268,0,0,0-2.041-3.634ZM5.605,15.288,4.28,9.173l1.58,1.019.764,1.274Zm3.057,0L7.644,11.466l.764-1.274,1.58-1.019ZM9.991,5.781a2.342,2.342,0,0,1-.525,1.064c-.322.3-1.529.713-2.038-.8a.312.312,0,0,0-.583,0c-.541,1.6-1.784,1.032-2.038.8A2.311,2.311,0,0,1,4.28,5.781c-.025-.08-.2-.182-.2-.185V5.252a24.526,24.526,0,0,0,3.057.185,23.929,23.929,0,0,0,3.057-.185V5.6S10.016,5.7,9.991,5.781Z" />
    </SvgIcon>
  );
}

export default function EMChannelsPreview({ data, route, usedIn }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  let dataArray;
  const groupData = useSelector((state) => state.groups || {});
  const userData = useSelector((state) => state.auth || {});
  const homeData = useSelector((state) => state.home || {});
  const channelData = useSelector((state) => state.channels || {});
  const navigation = useNavigate();
  const [anchorRef, setElRefs] = React.useState([]);
  const [channelRef, setChannelRef] = React.useState([]);
  const [channelRefForGroupsOrChannels, setchannelRefForGroupsOrChannels] =
    React.useState([]);
  const [channelRefForChannel, setChannelRefForChannel] = React.useState([]);
  const groupArray = homeData.numberOfGroups;
  const channelArray = homeData.numberOfChannels;
  const [addFlag_channel_forOrg, setAddFlag_channel_forOrg] = useState(false);
  const [addFlag_channel_forPart, setAddFlag_channel_forPart] = useState(false);

  React.useEffect(() => {
    if (!isEmpty(userData)) {
      if (
        userData.boolean_group_manage_channels === true
      ) {
        setAddFlag_channel_forOrg(true);
      }
      if (
        userData.boolean_group_manage_channels === true
      ) {
        setAddFlag_channel_forPart(true);
      }
    }
  }, [userData]);

  React.useEffect(() => {
    setChannelRefForChannel((elRefs) =>
      Array(100)
        .fill()
        .map((_, i) => elRefs[i] || React.createRef())
    );
  }, [100]);

  React.useEffect(() => {
    setChannelRef((elRefs) =>
      Array(100)
        .fill()
        .map((_, i) => elRefs[i] || React.createRef())
    );
  }, [100]);

  React.useEffect(() => {
    setchannelRefForGroupsOrChannels((elRefs) =>
      Array(100)
        .fill()
        .map((_, i) => elRefs[i] || React.createRef())
    );
  }, [100]);

  React.useEffect(() => {
    setElRefs((elRefs) =>
      Array(100)
        .fill()
        .map((_, i) => elRefs[i] || React.createRef())
    );
  }, [100]);



  dataArray = data != undefined ? data : [];
  return route === "home" ? (
    <Paper elevation={2} className={classes.root}>
      <Grid container direction="column" alignItems="center" spacing={3}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={2}>
            <Forum
              fontSize="large"
              style={{ marginTop: "15%", color: "#2F92D6" }}
            />
          </Grid>
          <Grid item xs={9} align="flex-start">
            <Typography
              style={{
                fontSize: "medium",
                color: "#2F92D6",
                fontWeight: "bold",
                marginTop: "3%",
              }}
            >
              CHANNELS
            </Typography>
          </Grid>
          {/* <Grid item xs={5}>
            <Button variant="outlined" startIcon={<ViewMoreIcon />}>
              View More
            </Button>
          </Grid> */}
        </Grid>

        {dataArray &&
          dataArray.map((group, index) => (
            <>
              <Box
                boxShadow={4}
                style={{
                  // backgroundColor:index%2 == 0 ? "#ade8f4" : "#c99ff9",
                  marginTop: "2.5%",
                  padding: "2%",
                  marginRight: "2%",

                  // marginLeft:"1%",
                  paddingBottom: "2%",
                  borderRadius: "10px",
                  width: "100%",
                }}
              >
                <Grid
                  style={{ backgroundColor: "#2F92D6", borderRadius: "10px" }}
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={2}
                >
                  <Grid item xs={2}>
                    <ThemeProvider theme={MaterialTheme}>
                      {group.group_logo_file !== null ? (
                        <Avatar
                          onClick={() => {
                            dispatch({
                              type: CHANNEL_COMPONENT_POP_UP,
                              payload: {
                                value: true,
                                id: group.group_id,
                              },
                            });
                          }}
                          ref={anchorRef[index]}
                          aria-label="recipe"
                          className={classes.avatarGroup}
                          src={ group.group_logo_file &&
                            group.group_logo_file.includes("localhost")
                              ? group.group_logo_file
                              : process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(
                                  0,
                                  -1
                                ) + group.group_logo_file
                            }
                        ></Avatar>
                      ) : (
                        <Avatar
                          onClick={() => {
                            dispatch({
                              type: CHANNEL_COMPONENT_POP_UP,
                              payload: {
                                value: true,
                                id: group.group_id,
                              },
                            });
                          }}
                          ref={anchorRef[index]}
                          aria-label="recipe"
                          className={classes.avatarGroup}
                        >
                          G
                        </Avatar>
                      )}
                    </ThemeProvider>
                  </Grid>
                  <Grid item xs={7}>
                    {/* <Grid container direction="column"> */}
                    <Grid
                      container
                      direction="row"
                      style={{ marginBottom: "2%" }}
                    >
                      <Grid
                        container
                        item
                        xs={10}
                        align="flexStart"
                        className={classes.grouplist}
                        onClick={() => {
                          dispatch({type:CLEAR_GROUP_DATA})
                          if (group.is_highlighted === true) {
                            dispatch(
                              EMDoMarkHighlightedChannelAsSeenAction(
                                group.main_channel_id
                              )
                            );
                          }
                          navigation("/users/groups/activity", {
                            state: { GroupId: group.group_id },
                          });
                          window.sessionStorage.setItem(
                            "GroupId",
                            group.group_id
                          );
                        }}
                      >
                        <Grid item xs={12}>
                          <Tooltip title={<h2 style={{ color: "white" }}>{group.group_name}</h2>} placement="top">
                            <Typography
                              style={{ fontWeight: "bolder", color: "white" }}
                            >
                              { group.group_name}
                            </Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                      {/* <Grid item xs={1}>
                        {group.privacy_type === 1 ? <PublicIcon /> : null}
                        {group.privacy_type === 3 ? <LockIcon /> : null}
                        {group.privacy_type === 2 ? <LockOpenIcon /> : null}
                        {group.privacy_type === 4 ? <SecretIcon /> : null}
                      </Grid> */}
                    </Grid>
                    {/* </Grid> */}
                  </Grid>
                  <Grid item container direction="column" xs={3} style={{paddingRight:8, paddingTop:0, paddingBottom:0}}>
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      
                    >
                      <Box>
                        {group.is_fav ? (
                          <IconButton
                            aria-label="pin"
                            onClick={() => {
                              dispatch(
                                EMDoActionForGroupAction({
                                  id: group.group_id,
                                  myaction: `remove-favorite`,
                                  route: "home",
                                })
                              );
                            }}
                          >
                            <StarRateIcon   onClick={() => {
                              dispatch(
                                EMDoActionForGroupAction({
                                  id: group.group_id,
                                  myaction: `remove-favorite`,
                                  route: "home",
                                })
                              );
                            }} style={{ color: "white" }} />
                          </IconButton>
                        ) : (
                          <IconButton
                            aria-label="pin"
                            onClick={() => {
                              let actions;

                              dispatch(
                                EMDoActionForGroupAction({
                                  id: group.group_id,
                                  myaction: `favorite`,
                                  route: "home",
                                })
                              );
                            }}
                          >
                            <StarRateIcon onClick={() => {
                              let actions;

                              dispatch(
                                EMDoActionForGroupAction({
                                  id: group.group_id,
                                  myaction: `favorite`,
                                  route: "home",
                                })
                              );
                            }} style={{ color: "#000080" }} />
                          </IconButton>
                        )}
                      </Box>
                      <Box>
                        <Badge
                        style={{top:"29%"}}
                          variant="dot"
                          invisible={!group.is_highlighted}
                          color="error"
                        ></Badge>
                      </Box>
                    </Box>
                    <Box display="flex" justifyContent="flex-end" >
                      <Box pr={1}>
                        <GroupIcon style={{ color: "white",  }} />
                      </Box>
                      <Box >
                        <Typography
                          style={{ color: "white", fontWeight: "bold" }}
                        >
                          {group.number_of_group_users}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Box className={classes.scroll}>
                  {group.channels.map((channel, id) => (
                    <div
                      style={{
                        padding: "4%",
                        borderBottom: "2px solid grey",
                      }}
                    >
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                        spacing={1}
                        style={{
                          backgroundColor: channel.is_highlited
                            ? "#d3e1eb"
                            : "white",
                        }}
                      >
                        <Grid item xs={2}>
                          <ThemeProvider theme={MaterialTheme}>
                            <Avatar
                              
                              onClick={() => {
                                dispatch({
                                  type: CHANNEL_COMPONENT_POP_UP,
                                  payload: {
                                    value: true,
                                    id: channel.id,
                                  },
                                });
                              }}
                              ref={channelRef[id]}
                              aria-label="recipe"
                              className={classes.avatarChannel}
                              src={
                                channel.logo_file &&
                                channel.logo_file.includes("localhost")
                                  ? channel.logo_file
                                  : process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(
                                      0,
                                      -1
                                    ) + channel.logo_file
                              }
                            ></Avatar>
                          </ThemeProvider>
                        </Grid>
                        <Grid
                          item
                          xs={7}
                          className={classes.channelList}
                          onClick={() => {
                            dispatch({type:CLEAR_CHANNEL_DATA})
                            if (channel.is_highlited === true) {
                              dispatch(
                                EMDoMarkHighlightedChannelAsSeenAction(
                                  channel.id
                                )
                              );
                            }
                            navigation("/users/channels/activity", {
                              state: { ChannelId: channel.id },
                            });
                            window.sessionStorage.setItem(
                              "ChannelId",
                              channel.id
                            );
                          }}
                        >
                          <Tooltip title={<h2 style={{ color: "white" }}>{channel.channel_name}</h2>}  placement="top">
                            <Typography>
                              { channel.channel_name}
                            </Typography>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={2}>
                          {channel.is_fav ? (
                            <IconButton
                              aria-label="pin"
                              onClick={() => {
                                dispatch(
                                  EMDoActionForChannelAction({
                                    id: channel.id,
                                    myaction: `remove-favorite`,
                                    route: "home",
                                  })
                                );
                              }}
                            >
                              <StarRateIcon color="secondary" />
                            </IconButton>
                          ) : (
                            <IconButton
                              aria-label="pin"
                              onClick={() => {
                                dispatch(
                                  EMDoActionForChannelAction({
                                    id: channel.id,
                                    myaction: `favorite`,
                                    route: "home",
                                  })
                                );
                              }}
                            >
                              <StarRateIcon style={{ color: "#C8CDD0" }} />
                            </IconButton>
                          )}
                        </Grid>
                      </Grid>
                      {channelRef.length > 0 ? (
                        <Menu
                          aria-controls="customized-menu"
                          getContentAnchorEl={null}
                          id={id}
                          anchorEl={channelRef && channelRef[id] && channelRef[id].current}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          open={
                            homeData.channelComponent.value &&
                            homeData.channelComponent.id == channel.id
                          }
                          onClose={() => {
                            dispatch({
                              type: CHANNEL_COMPONENT_POP_UP,
                              payload: {
                                value: false,
                                id: channel.id,
                              },
                            });
                          }}
                        >
                          <MenuItem>
                            <Typography style={{ fontWeight: "bolder" }}>
                              Network Name
                            </Typography>
                          </MenuItem>
                          <MenuItem>
                            <Typography variant="inherit">
                              {group.network_name}
                            </Typography>
                          </MenuItem>
                          <MenuItem>
                            <Typography style={{ fontWeight: "bolder" }}>
                              Group Name
                            </Typography>
                          </MenuItem>
                          <MenuItem>
                            <ListItemIcon>
                              <ThemeProvider theme={MaterialTheme}>
                                <Avatar
                                  src={
                                    process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(
                                      0,
                                      -1
                                    ) + group.group_logo_file
                                  }
                                ></Avatar>
                              </ThemeProvider>
                            </ListItemIcon>
                            <Typography>{group.group_name}</Typography>
                          </MenuItem>
                          {/* <MenuItem>
                      <ListItemIcon>
                        <Avatar src={ process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(
                                  0,
                                  -1
                                ) +channel.created_by.profile_pic_file}>

                        </Avatar>
                      </ListItemIcon>
                      <Typography>{channel.created_by.created_by}</Typography>
                      </MenuItem> */}
                        </Menu>
                      ) : null}
                    </div>
                  ))}
                </Box>
              </Box>
              {anchorRef.length > 0 ? (
                <Menu
                  aria-controls="customized-menu"
                  getContentAnchorEl={null}
                  id={index}
                  anchorEl={anchorRef[index].current}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  open={
                    homeData.channelComponent.value &&
                    homeData.channelComponent.id == group.group_id
                  }
                  onClose={() => {
                    dispatch({
                      type: CHANNEL_COMPONENT_POP_UP,
                      payload: {
                        value: false,
                        id: group.group_id,
                      },
                    });
                  }}
                >
                  <MenuItem>
                    <Typography style={{ fontWeight: "bolder" }}>
                      Network Name
                    </Typography>
                  </MenuItem>
                  <MenuItem>
                    <Typography variant="inherit">
                      {group.network_name}
                    </Typography>
                  </MenuItem>
                  <MenuItem>
                    <Typography style={{ fontWeight: "bolder" }}>
                      Created By
                    </Typography>
                  </MenuItem>
                  {group.created_by ? (
                    <MenuItem>
                      <ListItemIcon>
                        <ThemeProvider theme={MaterialTheme}>
                          <Avatar
                            src={
                              process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(
                                0,
                                -1
                              ) + group.created_by.profile_pic_file
                            }
                          ></Avatar>
                        </ThemeProvider>
                      </ListItemIcon>
                      <Typography>{group.created_by.created_by}</Typography>
                    </MenuItem>
                  ) : null}
                </Menu>
              ) : null}
            </>
          ))}

        <Grid item  style={{ marginTop: "2%" }}>
          {addFlag_channel_forOrg || addFlag_channel_forPart ? (
            <Button
              size="medium"
              variant="contained"
              style={{
                backgroundColor: "#2F92D6",
                color: "#ffffff",
                fontWeight: "bold",
              }}
              onClick={() => {
                navigation("/groupAdmin/channelmanagement");
              }}
            >
              Create New Channel
            </Button>
          ) : null}
        </Grid>
      </Grid>
    </Paper>
  ) : usedIn !== "channels" ? (
    <Paper elevation={2} className={classes.root}>
      {" "}
      <Grid container direction="column" alignItems="center" spacing={3}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={2}>
            <ChannelIcon style={{ marginTop: "15%" }} />
          </Grid>
          <Grid item xs={5} align="flex-start">
            <Typography
              style={{
                fontSize: "18px",
                color: "#630F6E",
                fontWeight: "bold",
                marginTop: "3%",
              }}
            >
              CHANNELS
            </Typography>
          </Grid>
          {/* <Grid item xs={5}>
            <Button variant="outlined" startIcon={<ViewMoreIcon />}>
              View More
            </Button>
          </Grid> */}
        </Grid>

        <Box className={classes.scrollForChannels}>
          {data &&
            data.map((channel, idx) => (
              <>
                <Paper
                  elevation={2}
                  style={{
                    padding: "2%",
                    margin: "4%",
                    borderRadius: "10px",
                    backgroundColor: channel.is_highlighted
                      ? "#d3e1eb"
                      : "white",
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    spacing={1}
                  >
                    <Grid item xs={2}>
                      {channel.logo_file === null ? (
                        <ThemeProvider theme={MaterialTheme}>
                          <Avatar
                            variant="circular"
                            onClick={() => {
                              dispatch({
                                type: CHANNEL_INFO_POP_UP,
                                payload: {
                                  value: true,
                                  id: channel.id,
                                },
                              });
                            }}
                            ref={channelRefForGroupsOrChannels[idx]}
                            aria-label="recipe"
                            className={classes.avatarChannel}
                          >
                            C
                          </Avatar>
                        </ThemeProvider>
                      ) : (
                        <ThemeProvider theme={MaterialTheme}>
                          <Avatar
                            variant="circular"
                            onClick={() => {
                              dispatch({
                                type: CHANNEL_INFO_POP_UP,
                                payload: {
                                  value: true,
                                  id: channel.id,
                                },
                              });
                            }}
                            ref={channelRefForGroupsOrChannels[idx]}
                            aria-label="recipe"
                            className={classes.avatarChannel}
                            src={
                              channel.logo_file &&
                              channel.logo_file.includes("localhost")
                                ? channel.logo_file
                                : process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(
                                    0,
                                    -1
                                  ) + channel.logo_file
                            }
                          ></Avatar>
                        </ThemeProvider>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={8}
                      className={classes.channelList}
                      onClick={() => {
                        dispatch({type:CLEAR_CHANNEL_DATA})
                        navigation("/users/channels/activity", {
                          state: { ChannelId: channel.id },
                        });
                        window.sessionStorage.setItem(
                          "ChannelId",
                          channel.id
                        );
                        if (channel.is_highlighted === true) {
                          dispatch(
                            EMDoMarkHighlightedChannelAsSeenAction(channel.id)
                          );
                        }
                      }}
                    >
                      <Tooltip title={<h2 style={{ color: "white" }}>{channel.channel_name}</h2>}  placement="bottom">
                        <Typography>
                          { channel.channel_name}
                        </Typography>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={2}>
                      {channel.is_fav ? (
                        <IconButton
                          aria-label="pin"
                          onClick={() => {
                            dispatch(
                              EMDoActionForChannelAction({
                                id: channel.id,
                                myaction: `remove-favorite`,
                                usedIn: usedIn,
                              })
                            );
                          }}
                        >
                          <StarRateIcon color="secondary" />
                        </IconButton>
                      ) : (
                        <IconButton
                          aria-label="pin"
                          onClick={() => {
                            dispatch(
                              EMDoActionForChannelAction({
                                id: channel.id,
                                myaction: `favorite`,
                                usedIn: usedIn,
                              })
                            );
                          }}
                        >
                          <StarRateIcon style={{ color: "#C8CDD0" }} />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                  {channelRefForGroupsOrChannels.length > 0 ? (
                    <Menu
                      aria-controls="customized-menu"
                      getContentAnchorEl={null}
                      id={idx}
                      anchorEl={channelRefForGroupsOrChannels[idx].current}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      open={
                        groupData.channelInfoPopUp.value &&
                        groupData.channelInfoPopUp.id == channel.id
                      }
                      onClose={() => {
                        dispatch({
                          type: CHANNEL_INFO_POP_UP,
                          payload: {
                            value: false,
                            id: channel.id,
                          },
                        });
                      }}
                    >
                      <MenuItem>
                        <Typography style={{ fontWeight: "bolder" }}>
                          Network Name
                        </Typography>
                      </MenuItem>
                      <MenuItem>
                        <Typography variant="inherit">
                          {channel.network_name && channel.network_name}
                        </Typography>
                      </MenuItem>
                      <MenuItem>
                        <Typography style={{ fontWeight: "bolder" }}>
                          Group Name
                        </Typography>
                      </MenuItem>
                      <MenuItem>
                        <ListItemIcon>
                          <ThemeProvider theme={MaterialTheme}>
                            <Avatar
                              src={
                                channel.group_logo &&
                                process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(
                                  0,
                                  -1
                                ) + channel.group_logo
                              }
                            ></Avatar>
                          </ThemeProvider>
                        </ListItemIcon>
                        <Typography>
                          {channel.group_name && channel.group_name}
                        </Typography>
                      </MenuItem>
                      <Typography style={{ fontWeight: "bolder" }}>
                        Created By
                      </Typography>
                      {channel.created_by !== null ? (
                        <MenuItem>
                          <ListItemIcon>
                            <Avatar
                              src={
                                channel.created_by &&
                                process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(
                                  0,
                                  -1
                                ) + channel.created_by.profile_pic_file
                              }
                            ></Avatar>
                          </ListItemIcon>
                          <Typography>
                            {channel.created_by &&
                              channel.created_by.created_by}
                          </Typography>
                        </MenuItem>
                      ) : null}
                    </Menu>
                  ) : null}
                </Paper>
              </>
            ))}
        </Box>

        <Grid item  style={{ marginTop: "2%" }}>
          {addFlag_channel_forOrg || addFlag_channel_forPart ? (
            <Button
              size="medium"
              variant="outlined"
              style={{ backgroundColor: "#630F6E", color: "#ffffff" }}
              onClick={() => {
                navigation("/groupAdmin/channelmanagement");
              }}
            >
              Create New Channel
            </Button>
          ) : null}
        </Grid>
      </Grid>
    </Paper>
  ) : (
    <Paper elevation={2} className={classes.root}>
      {" "}
      <Grid container direction="column" alignItems="center" spacing={3}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={2}>
            <ChannelIcon style={{ marginTop: "15%" }} />
          </Grid>
          <Grid item xs={5} align="flex-start">
            <Typography
              style={{
                fontSize: "18px",
                color: "#630F6E",
                fontWeight: "bold",
                marginTop: "3%",
              }}
            >
              CHANNELS
            </Typography>
          </Grid>
          {/* <Grid item xs={5}>
           <Button variant="outlined" startIcon={<ViewMoreIcon />}>
             View More
           </Button>
         </Grid> */}
        </Grid>

        <Box className={classes.scrollForChannels}>
          {data &&
            data.map((channel, idex) => (
              <>
                <Paper
                  elevation={2}
                  style={{
                    padding: "2%",
                    margin: "4%",
                    borderRadius: "10px",
                    backgroundColor: channel.is_highlited ? "#d3e1eb" : "white",
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={1}
                  >
                    <Grid item xs={2}>
                      {channel.logo_file === null ? (
                        <ThemeProvider theme={MaterialTheme}>
                          <Avatar
                            aria-label="recipe"
                            className={classes.avatarChannel}
                            onClick={() => {
                              dispatch({
                                type: CHANNEL_POP_UP,
                                payload: {
                                  value: true,
                                  id: channel.id,
                                },
                              });
                            }}
                            ref={channelRefForChannel[idex]}
                          >
                            C
                          </Avatar>
                        </ThemeProvider>
                      ) : (
                        <ThemeProvider theme={MaterialTheme}>
                          <Avatar
                            aria-label="recipe"
                            className={classes.avatarChannel}
                            src={
                              channel.logo_file &&
                              channel.logo_file.includes("localhost")
                                ? channel.logo_file
                                : process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(
                                    0,
                                    -1
                                  ) + channel.logo_file
                            }
                            onClick={() => {
                              dispatch({
                                type: CHANNEL_POP_UP,
                                payload: {
                                  value: true,
                                  id: channel.id,
                                },
                              });
                            }}
                            ref={channelRefForChannel[idex]}
                          ></Avatar>
                        </ThemeProvider>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={8}
                      className={classes.channelList}
                      onClick={() => {
                        dispatch({type:CLEAR_CHANNEL_DATA})
                        navigation("/users/channels/activity", {
                          state: { ChannelId: channel.id },
                        });
                        window.sessionStorage.setItem(
                          "ChannelId",
                          channel.id
                        );
                        if (channel.is_highlited === true) {
                          dispatch(
                            EMDoMarkHighlightedChannelAsSeenAction(channel.id)
                          );
                        }
                      }}
                    >
                      <Tooltip title={<h2 style={{ color: "white" }}>{channel.channel_name}</h2>} placement="top">
                        <Typography>
                          { channel.channel_name}
                        </Typography>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={2}>
                      {channel.is_fav ? (
                        <IconButton
                          aria-label="pin"
                          onClick={() => {
                            dispatch(
                              EMDoActionForChannelAction({
                                id: channel.id,
                                myaction: `remove-favorite`,
                                usedIn: usedIn,
                              })
                            );
                          }}
                        >
                          <StarRateIcon color="secondary" />
                        </IconButton>
                      ) : (
                        <IconButton
                          aria-label="pin"
                          onClick={() => {
                            dispatch(
                              EMDoActionForChannelAction({
                                id: channel.id,
                                myaction: `favorite`,
                                usedIn: usedIn,
                              })
                            );
                          }}
                        >
                          <StarRateIcon style={{ color: "#C8CDD0" }} />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                  {channelRefForChannel.length !== 0 ? (
                    <Menu
                      aria-controls="customized-menu"
                      getContentAnchorEl={null}
                      id={idex}
                      anchorEl={channelRefForChannel[idex].current}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      open={
                        channelData.channelPopUp.value &&
                        channelData.channelPopUp.id == channel.id
                      }
                      onClose={() => {
                        dispatch({
                          type: CHANNEL_POP_UP,
                          payload: {
                            value: false,
                            id: channel.id,
                          },
                        });
                      }}
                    >
                      <MenuItem>
                        <Typography style={{ fontWeight: "bolder" }}>
                          Network Name
                        </Typography>
                      </MenuItem>
                      <MenuItem>
                        <Typography variant="inherit">
                          {channel.network_name && channel.network_name}
                        </Typography>
                      </MenuItem>
                      <MenuItem>
                        <Typography style={{ fontWeight: "bolder" }}>
                          Group Name
                        </Typography>
                      </MenuItem>
                      <MenuItem>
                        <ListItemIcon>
                          <ThemeProvider theme={MaterialTheme}>
                            <Avatar
                              src={
                                channel.group_logo &&
                                process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(
                                  0,
                                  -1
                                ) + channel.group_logo
                              }
                            ></Avatar>
                          </ThemeProvider>
                        </ListItemIcon>
                        <Typography>
                          {channel.group_name && channel.group_name}
                        </Typography>
                      </MenuItem>
                      <MenuItem>
                        <Typography style={{ fontWeight: "bolder" }}>
                          Created By
                        </Typography>
                      </MenuItem>
                      {channel.created_by !== null ? (
                        <MenuItem>
                          <ListItemIcon>
                            <Avatar
                              src={
                                channel.created_by &&
                                process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(
                                  0,
                                  -1
                                ) + channel.created_by.profile_pic_file
                              }
                            ></Avatar>
                          </ListItemIcon>
                          <Typography>
                            {channel.created_by &&
                              channel.created_by.created_by}
                          </Typography>
                        </MenuItem>
                      ) : null}
                    </Menu>
                  ) : null}
                </Paper>
              </>
            ))}
        </Box>

        <Grid item  style={{ marginTop: "2%" }}>
          {addFlag_channel_forOrg || addFlag_channel_forPart ? (
            <Button
              size="medium"
              variant="outlined"
              style={{ backgroundColor: "#630F6E", color: "#ffffff" }}
              onClick={() => {
                navigation("/groupAdmin/channelmanagement");
              }}
            >
              Create New Channel
            </Button>
          ) : null}
        </Grid>
      </Grid>
    </Paper>
  );
}
