import {
  CHANNEL_ADMIN_CHANNEL_LIST_DROPDOWN_SUCCESS,
  CHANNEL_ADMIN_CHANNEL_USERS_LIST_DROPDOWN,
  CHANNEL_ADMIN_CHANNEL_USER_ROLE_DROPDOWN_SUCCESS,
  CHANNEL_ADMIN_USER_DETAILS_UPDATE_SUCCESS,
  CHANNEL_ADMIN_CHANNEL_LIST_SUCCESS,
  CHANNEL_ADMIN_CHANNEL_UPDATE_SUCCESS,
  CHANNEL_ADMIN_GROUP_LIST_DROPDOWN_SUCCESS,
  CHANNEL_ADMIN_CHANNEL_DETAILS_BY_ID,
  CHANNEL_ADMIN_CHANNEL_SETTINGS_UPDATE_SUCCESS,
  CHANNEL_ADMIN_IMPORT_USERS_LIST_SUCCESS,
  CHANNEL_ADMIN_RESEND_INVITE_SUCCESS,
  CHANNEL_ADMIN_RESEND_USERS_LIST_SUCCESS,
  CHANNEL_ADMIN_EMAIL_TEMPLATE_TYPE_LIST_SUCCESS,
  CHANNEL_ADMIN_LIST_ROLE_SUCCESS,
  CHANNEL_ADMIN_ROLE_CREATE_SUCCESS,
  CHANNEL_ADMIN_CHANNEL_SETTINGS_UPDATE_SUCCESS_FOR_USER_PLATFORM,
  CHANNEL_ADMIN_REMOVE_CHANNEL_USER,
  CHANNEL_ADMIN_GET_GROUP_LIST,
  CHANNEL_ADMIN_ENTITY_LIST,
  CHANNEL_ADMIN_GET_DASHBOARD_DATA,
  CHANNEL_ADMIN_GET_CHANNEL_LIST,
  CHANNEL_ADMIN_GET_USER_ENGAGEMENT_DATA,
  RESET,
  CHANNEL_ADMIN_GET_DATA_FOR_DROPDOWN_IN_GAMIFICATION,
  CHANNEL_ADMIN_GET_LEVEL_REPORT,
  CHANNEL_ADMIN_GET_BADGE_REPORT,
  CHANNEL_ADMIN_GET_LIST_OF_CHANNELS_IN_USER_MANAGEMENT,
  CHANNEL_ADMIN_GET_PERMISSION_BASED_ON_CHANNEL,
  CHANNEL_ADMIN_ADD_REMOVE_IMPORT_USERS_FROM_SECONDARY_ARRAY,
  CHANNEL_ADMIN_IMPORT_USERS_SELECT_ALL_FLAG,
  CHANNEL_ADMIN_SELECT_UNSELECT_USER_IN_IMPORT_USERS,
  AUTH_LOGOUT,
  CHANNEL_ADMIN_IMPORT_USERS_CLEAR_UNCLEAR_SELECT_ALL_WRT_SEARCH,
  CHANNEL_ADMIN_UPDATE_USERS_LIST,
} from "../constants";
import { cloneDeep, find, findIndex } from "lodash";
const initialState = {
  importUsersList: {},
  selectAll: false,
  checkedUsers: [],
  updateUsersList: false
};
export default function EMChannelAdminReducer(state = initialState, action) {
  const newState = cloneDeep(state);

  switch (action.type) {
    case CHANNEL_ADMIN_CHANNEL_LIST_DROPDOWN_SUCCESS: {
      newState["channelDropdownList"] = action.payload;
      return newState;
    }

    case CHANNEL_ADMIN_CHANNEL_USERS_LIST_DROPDOWN: {
      newState["channelUsersList"] = action.payload;
      return newState;
    }
    case CHANNEL_ADMIN_LIST_ROLE_SUCCESS: {
      newState["listRoles"] = action.payload;
      return newState;
    }
    case CHANNEL_ADMIN_ROLE_CREATE_SUCCESS: {
      newState["listRoles"]["results"].unshift(action.payload);
      newState["listRoles"]["count"] = newState["listRoles"]["count"]++;
      return newState;
    }
    case CHANNEL_ADMIN_CHANNEL_USER_ROLE_DROPDOWN_SUCCESS: {
      newState["roles"] = action.payload;
      return newState;
    }

    case CHANNEL_ADMIN_GET_DATA_FOR_DROPDOWN_IN_GAMIFICATION: {
      newState["gamificationDropdownData"] = action.payload;
      return newState;
    }

    case CHANNEL_ADMIN_GET_LEVEL_REPORT: {
      newState["levelReport"] = action.payload;
      return newState;
    }

    case CHANNEL_ADMIN_GET_BADGE_REPORT: {
      if (action.payload) {
        newState["badgeReport"] = action.payload;
        let result = Object.keys(action.payload.report).map((key) => ({
          badgeName: key,
          userCount: action.payload.report[key],
        }));
        newState["badgeReport"] = {
          ...newState["badgeReport"],
          newBadges: result,
        };
      }
      return newState;
    }

    case CHANNEL_ADMIN_USER_DETAILS_UPDATE_SUCCESS: {
      let userId = action.payload.id;
      userId = userId.toString();
      let userIndex = findIndex(newState["channelUsersList"]["results"], {
        id: userId,
      });
      if (userIndex > -1) {
        // newState["channelUsersList"]["results"][userIndex] = {
        //   ...action.payload,
        // };

        let nextState = newState["channelUsersList"]["results"][userIndex];
        nextState.first_name = action.payload.first_name;
        nextState.last_name = action.payload.last_name;
        nextState.role_name = action.payload.role_name;
        nextState.role = action.payload.role;
        nextState.email = action.payload.email;
        nextState.deactivation_date = action.payload.deactivation_date;
      }
      return newState;
    }

    case CHANNEL_ADMIN_CHANNEL_LIST_SUCCESS: {
      newState["channelList"] = action.payload;
      return newState;
    }

    case CHANNEL_ADMIN_CHANNEL_UPDATE_SUCCESS: {
      let userId = action.payload.id;
      let userIndex = findIndex(newState["channelList"]["results"], {
        id: userId,
      });
      if (userIndex > -1) {
        newState["channelList"]["results"][userIndex] = {
          ...action.payload,
        };
      }
      return newState;
    }

    case CHANNEL_ADMIN_GROUP_LIST_DROPDOWN_SUCCESS: {
      newState["groupDropdownList"] = action.payload;
      return newState;
    }

    case CHANNEL_ADMIN_CHANNEL_DETAILS_BY_ID: {
      newState["channelDetails"] = action.payload;
      return newState;
    }

    case CHANNEL_ADMIN_CHANNEL_SETTINGS_UPDATE_SUCCESS_FOR_USER_PLATFORM: {
      newState["channelDetails"] = action.payload;
      return newState;
    }

    case CHANNEL_ADMIN_CHANNEL_SETTINGS_UPDATE_SUCCESS: {
      let userId = action.payload.id;
      let userIndex = findIndex(newState["channelList"]["results"], {
        id: userId,
      });
      if (userIndex > -1) {
        let nextState = newState["channelList"]["results"][userIndex];
        nextState.channel_name = action.payload.channel_name;
        nextState.privacy_type_name = action.payload.privacy_type_name;
        nextState.privacy_type = action.payload.privacy_type;
      }
      return newState;
    }

    case CHANNEL_ADMIN_IMPORT_USERS_LIST_SUCCESS: {
      newState["nextUsersImport"] = action.payload.next;
      newState["importUsersList"] = action.payload;
      newState["selectAll"] = false;
      newState["importUsersList"] = {
        ...newState["importUsersList"],
        results: newState["importUsersList"].results.map((user) => ({
          ...user,
          isChecked: false,
        })),
      }
      return newState;
    }

    case CHANNEL_ADMIN_SELECT_UNSELECT_USER_IN_IMPORT_USERS: {
      let userId = action.payload.userId;

      var foundIndex = newState["importUsersList"].results.findIndex(
        (x) => x.id == userId
      );
      if (foundIndex >= 0) {
        newState["importUsersList"].results[foundIndex].isChecked =
          action.payload.state;
      }

      let selectAllState = newState["importUsersList"].results.every(
        (user) => user.isChecked == true
      );
      if (selectAllState) {
        newState["selectAll"] = true;
      } else {
        newState["selectAll"] = false;
      }
      return newState;
    }

    case CHANNEL_ADMIN_IMPORT_USERS_SELECT_ALL_FLAG: {
      newState["selectAll"] = action.payload.state;
      newState["importUsersList"] = {
        ...newState["importUsersList"],
        results: newState["importUsersList"].results.map((user) => ({
          ...user,
          isChecked: action.payload.state,
        })),
      };
    return newState;

    }

    case CHANNEL_ADMIN_UPDATE_USERS_LIST: {
      
      newState["updateUsersList"] = action.payload
      return newState
    }

    case CHANNEL_ADMIN_RESEND_INVITE_SUCCESS: {
      newState["invites"] = action.payload;
      return newState;
    }

    case CHANNEL_ADMIN_RESEND_USERS_LIST_SUCCESS: {
      newState["resendUsersList"] = action.payload;
      return newState;
    }

    case CHANNEL_ADMIN_EMAIL_TEMPLATE_TYPE_LIST_SUCCESS: {
      newState["emailTemplateType"] = action.payload;
      return newState;
    }

    case CHANNEL_ADMIN_REMOVE_CHANNEL_USER: {
      let data = action.payload;
      newState["channelUsersList"].results.map((channelUsersList) => {
        if (channelUsersList.id == data.user_id) {
          let removeChannelUser = findIndex(
            newState["channelUsersList"].results,
            { id: data.user_id }
          );
          newState["channelUsersList"].results.splice(removeChannelUser, 1);
        }
      });
      return newState;
    }
    //Get group list for dashboard
    case CHANNEL_ADMIN_GET_GROUP_LIST: {
      newState["channelListForDashboard"] = action.payload;
      return newState;
    }
    // For Dashboard_data
    case CHANNEL_ADMIN_GET_DASHBOARD_DATA: {
      newState["dashboardDataChannel"] = action.payload;
      return newState;
    }
    //  FOR ENTITY  List Dashboard
    case CHANNEL_ADMIN_ENTITY_LIST: {
      newState["dashboardEntityListChannel"] = action.payload;
      return newState;
    }

    case CHANNEL_ADMIN_GET_CHANNEL_LIST: {
      newState["channelList"] = action.payload;
      return newState;
    }
    // report for user engagement
    case CHANNEL_ADMIN_GET_USER_ENGAGEMENT_DATA: {
      newState["userEngagementChannel"] = action.payload;
      return newState;
    }

    case CHANNEL_ADMIN_GET_LIST_OF_CHANNELS_IN_USER_MANAGEMENT: {
      newState["channelListForUserManagement"] = action.payload;
      return newState;
    }

    case CHANNEL_ADMIN_GET_PERMISSION_BASED_ON_CHANNEL: {
      newState["channelSpecificRolesAndPermissions"] = action.payload;
      return newState;
    }

    case AUTH_LOGOUT: {
      return { ...initialState };
    }

    case RESET: {
      return {};
    }

    default: {
      return newState;
    }
  }
}
