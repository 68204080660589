import EMDropdown from "../../../../components/commans/EMDropdown";
import { useSelector } from "react-redux";
import { find, findIndex } from "lodash";
import { useEffect, useState } from "react";
// import { useLocation } from "react-router";
import {useLocation} from "react-router-dom"

export default function EMEntityDropdown({ onSelect, error, helperText, errorText, onlyOrganizationsNeeded }) {
  const data = useSelector(state => state.networkAdmin["networkListForUserDropdown"]);
  
  return (
    <>
      <EMDropdown
        label="Select Network"
        data={data || []}
        objKey={"entity_name"}
        onChange={selected => {
          onSelect(find(data, { entity_name: selected }));
        }}
        prevData={[]}
        error={error}
        helperText={helperText}
      />
      <p style={{ color: "red" }} >{errorText}</p>
    </>
  );
}
