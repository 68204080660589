import React from "react";
import {
  Paper,
  Box,
  Button,
  Modal,
  Typography,
  IconButton,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import {
  EMSimpleTextFieldView,
  EMChipTextFieldView,
} from "../../../components/EMTextFieldView";
import {
  EMDoCreateNetworkAdminGroupAction,
  EMDoUpdateNetworkAdminGroupAction,
} from "../../../redux/actions/EMNetworkAdminActions";
import { CloseRounded } from "@material-ui/icons";
import { useFormik, Field, Formik } from "formik";
import * as yup from "yup";
import ChipInput from "material-ui-chip-input";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { findIndex, filter, difference, isEmpty } from "lodash";
import PrivacyDropdown from "../UserManagement/commons/EMPrivacyDropdown";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("xs")]: {
      backgroundColor: theme.palette.common.white,
      height: "auto",
      minWidth: "50%",
      margin: "auto",
      padding: "0px",
      // maxWidth: '100vw',
      maxHeight: "100%",
      // position: 'fixed',
      marginTop: "22rem",
      transform: "translate(0, -50%)",
      width: "70%",
      marginLeft: "5rem",
    },
    [theme.breakpoints.up("sm")]: {
      backgroundColor: theme.palette.common.white,
      height: "auto",
      minWidth: "50%",
      margin: "auto",
      padding: "0px",
      // maxWidth: '100vw',
      maxHeight: "100%",
      position: "fixed",
      top: "50%",
      transform: "translate(0, -50%)",
      width: "50%",
    },
    [theme.breakpoints.up("md")]: {
      backgroundColor: theme.palette.common.white,
      height: "auto",
      minWidth: "50%",
      margin: "auto",
      padding: "0px",
      // maxWidth: '100vw',
      maxHeight: "100%",
      position: "fixed",
      top: "50%",
      transform: "translate(0, -50%)",
      width: "50%",
    },
    [theme.breakpoints.up("lg")]: {
      backgroundColor: theme.palette.common.white,
      height: "auto",
      minWidth: "50%",
      margin: "auto",
      padding: "0px",
      // maxWidth: '100vw',
      maxHeight: "100%",
      position: "fixed",
      top: "50%",
      transform: "translate(0, -50%)",
      width: "50%",
    },
  },
  title: {
    margin: 10,
    flexGrow: 1,
    fontWeight: "bold",
    color: "white",
    fontSize: "large",
  },
  form: {
    padding: "5px 36px",
    width: "100%",
  },
  buttonHolder: {
    padding: theme.spacing(3),
  },
}));

export default function EMAddEditGroup(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [localEntity, setLocalEntity] = React.useState(-1);

  useEffect(
    () => {
      setLocalEntity(props.entity);
      return () => {
        formik.resetForm();
      };
    },
    [props.entity],
    [props.open]
  );
  const validationSchema = yup.object({
    group_name: yup
      .string("Enter Group Name")
      .trim()
      .required("Group name is required"),
    admin_emails: yup
      .array()
      .transform(function (value, originalValue) {
        if (this.isType(value) && value !== null) {
          return value;
        }
        return originalValue ? originalValue.split(/[\s,]+/) : [];
      })
      .of(yup.string().email(({ value }) => `${value} is not a valid email`))
      .min(1, "Atleast 1 admin email is required")
      .required("Admin email is requreid"),
    privacy_type: yup
      .string("Select Privacy Type")
      .required("Select Privacy Type"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      group_name: props.groupData["group_name"] || "",
      admin_emails: props.groupData["users"]
        ? props.groupData["users"].map((x) => x["email"])
        : [],
      privacy_type: props.groupData["privacy_type"] || "",
      // is_active:Boolean(1)
    },

    validationSchema: validationSchema,
    onSubmit: (values) => {
      props.setDisabled(true);
      if (props.groupData && props.groupData.id > 0) {
      
        let deleted_admin_emails = difference(
          filter(props.groupData["users"], {
            role: { role_name: "Admin" },
          }).map((x) => x["email"]),
          formik.values.admin_emails
        );
        let new_admin_emails = difference(
          formik.values.admin_emails,
          filter(props.groupData["users"], {
            role: { role_name: "Admin" },
          }).map((x) => x["email"])
        );
        let newGroupData = {
          ...props.groupData,
          ...values,
          deleted_admin_emails,
          new_admin_emails,
        };
        let query = [];
        if (!isEmpty(props.entity)) {
          query.push(
            `context_type=${props.entity.context_type}`,
            `context=${props.entity.context}`
          );
        }
        dispatch(
          EMDoUpdateNetworkAdminGroupAction({
            groupData: { ...newGroupData },
            queryData: `?${query.join("&")}`,
            key: "group_add_edit",
          })
        );
      } else {
        let newGroupData = {
          ...values,
        };
        let query = [];
        if (!isEmpty(props.entity)) {
          query.push(
            `context_type=${props.entity.context_type}`,
            `context=${props.entity.context}`
          );
        }
        dispatch(
          EMDoCreateNetworkAdminGroupAction({
            groupData: { ...newGroupData },
            queryData: `?${query.join("&")}`,
            key: "group_add_edit",
          })
        );
      }
    },
  });

  //reset the form after modal is closed
  useEffect(() => {
    return () => {
      formik.resetForm();
    };
  }, [props.open]);

  return (
    <Modal
      open={props.open}
      onClose={props.close}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box className={classes.root} style={{ outline: "none" }}>
        <form onSubmit={formik.handleSubmit}>
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            style={{
                padding: "0px 4px 0px 18px",
                backgroundColor: "#2F92D6",
                height: "auto",
              }}
          >
            <Typography className={classes.title}>{props.pagelabel}</Typography>
            <IconButton onClick={props.close}>
              <CloseRounded style={{color:"white"}} />
            </IconButton>
          </Box>
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="row"
            className={classes.form}
            style={{paddingTop:"16px"}}
          >
            <EMSimpleTextFieldView
              label={"Group Name"}
              id="group_name"
              name="group_name"
              value={formik.values.group_name}
              onChange={formik.handleChange}
              fullwidth
              // error={
              //   formik.touched.group_name && Boolean(formik.errors.group_name)
              // }
              helperText={formik.touched.group_name && formik.errors.group_name}
            />
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="row"
            spacing={2}
            className={classes.form}
          >
            <Grid item xs={12} sm={12} md={12}>
              <ChipInput
                name="admin_emails"
                id="admin_emails"
                type="email"
                label="Admin E-mail Address"
                helperText={
                  formik.errors.admin_emails && formik.touched.admin_emails
                    ? formik.errors.admin_emails
                    : ""
                }
                value={formik.values.admin_emails}
                //FilledInputProps={{style: {fontFamily: "Arial" }}}
                //InputProps={{ style: {fontFamily: "Arial" } }}
                InputLabelProps={{ style: { color: "#4d4d4d" } }}
                FormHelperTextProps={{ style: { color: "red" } }}
                // error={
                //   formik.errors.admin_emails && formik.touched.admin_emails
                //     ? true
                //     : false
                // }
                margin="none"
                onAdd={(chip) => {
                  if (chip) {
                    let oldvalues = [...formik.values.admin_emails];
                    let index = findIndex(oldvalues, chip);
                    if (index === -1) {
                      oldvalues.push(chip.toLowerCase());
                      formik.setFieldValue("admin_emails", oldvalues);
                    }
                  }
                }}
                onDelete={(chip) => {
                  let oldValues = [...formik.values.admin_emails];
                  let index = oldValues.indexOf(chip);
                  if (index > -1) {
                    oldValues.splice(index, 1);
                    formik.setFieldValue("admin_emails", oldValues);
                  }
                }}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid className={classes.form}>
            <PrivacyDropdown
              name="privacy_type"
              initialValue={formik.values.privacy_type}
              onSelect={(id) => {
                formik.setFieldValue("privacy_type", id);
              }}
            //   error={
            //     formik.touched.privacy_type &&
            //     Boolean(formik.errors.privacy_type)
            //   }
              helperText={
                formik.touched.privacy_type && formik.errors.privacy_type
              }
            />
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="row"
            className={classes.buttonHolder}
            spacing={2}
          >
            <Grid item>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                type="submit"
                disabled={props.saveClicked}
              >
                <Typography variant="h7">{props.buttonLabel}</Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.button}
                variant="outlined"
                color="secondary"
                onClick={props.close}
              >
                <Typography variant="h7">Discard</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
}
