import { Box, IconButton,Typography,Tooltip, makeStyles } from "@material-ui/core";
import EMSimpleDatatable from "../../../components/datatables/EMSimpleDatatable";
import { useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import {EMDOGetPlatformAdminAction,EMDoUpdatePlatformAdminAction} from "../../../redux/actions/EMAdminActions";
import { useEffect, useState } from "react";
import EMSwitch from "../../../components/commans/EMSwitch";
import EditIcon from "../../../assets/Icon-material-edit.svg"

const useStyles = makeStyles((theme) => ({
  typo: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 1,
    wordBreak: "break-word",
    overflow: "hidden",
  },
}));

export default function EMUserTable({ filter, onEdit }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const paginationData = useSelector(
    state => state.admin.platformAdminsList || {}
  );
  const [limit, setLimit] = useState(15);
  const [offset, setOffset] = useState(0);
  const [order, setOrder] = useState({ id: -1, direction: "asc" });
  const [sortAscending, setSortAscending] = useState(false);
  const userData = useSelector((state) => state.auth);
  let loginUser = userData.email;
  const classes = useStyles()

  useEffect(() => {
    if (filter && filter.length > 0) {
      setOffset(0);
    } else if (filter && filter == "") {
      setOffset(offset);
    }
  }, [filter]);

  const columns = [
    {
      title: "First Name",
      field: "first_name",
      align: "left",
      render: data => {
        let firstName = data["first_name"]
        return (
          <>
          <Tooltip title={<h2 style={{ color: "white" }}>{firstName}</h2>}  placement='top'>
          <Typography className={classes.typo}>{firstName}</Typography>
          </Tooltip>
          </>
        )
      }
    },
    {
      title: "Last Name",
      field: "last_name",
      align: "left",
      render: data => {
        let lastName = data["last_name"]
        return (
          <>
          <Tooltip title={<h2 style={{ color: "white" }}>{lastName}</h2>}  placement='top'>
          <Typography className={classes.typo}>{lastName}</Typography>
          </Tooltip>
          </>
        )
      }
    },
    {
      title: "E-mail Address",
      field: "email",
      align: "left",
      render: data => {
        let email = data["email"]
        return (
          <>
          <Tooltip title={<h2 style={{ color: "white" }}>{email}</h2>}  placement='top'>
          <Typography className={classes.typo}>{email}</Typography>
          </Tooltip>
          </>
        )
      }
    },
    {
      title: "Status",
      align: "left",
      field: "is_active",
      render: data => {
        return (
          <EMSwitch
            status={data.is_active}
            dialogText= {"Are you sure that you want to activate/deactivate the user?"}
            isDisabled={data.email == loginUser}
            onChange={status => {
              let newData = { ...data };
              newData.is_active = status;
              delete newData.tableData;
              dispatch(EMDoUpdatePlatformAdminAction(newData));
            }}
          />
        );
      }
    },
    {
      title: "Action",
      align: "left",
      sorting : false,
      render: data => {
        return (
          <IconButton onClick={() => onEdit(data)}>
            <img
              alt="Edit"
              src={EditIcon}
            />
          </IconButton>
        );
      }
    }
  ];

  const updateData = query => {
    
    dispatch(EMDOGetPlatformAdminAction(query));
  };
  /* forming url wrt functionality */
  useEffect(() => {
    const query = [];
    if (limit !== 0) {
      query.push(`limit=${limit}`);
    }
    if (filter) {
      query.push(`search=${filter}`);
    }
    if (offset !== 0) {
      query.push(`offset=${offset}`);
    }
    if (order.id !== -1) {
      /* Logic for including sorting */
      if(sortAscending === false){
        query.push(
          `ordering=${order.direction === "desc" ? "-" : ""}${
            columns[order.id-1].field
          }`
        );
        setSortAscending(true)
      }else{
        // order.direction = "desc"
        query.push(
          `ordering=${order.direction === "desc" ? "-" : ""}${
            columns[order.id-1].field
          }`
        );
        setSortAscending(false)
      }
    }
    updateData("?" + query.join("&"));


  }, [limit, filter, offset, order]);



  return (
    <Box p={2}>
      {
        <EMSimpleDatatable
        filter={filter}
          columns={columns}
          data={paginationData["results"] || []}
          count={paginationData["count"] || 0}
          updatePageAndPageSizeAndOrder={(page, pageSize, order) => {
            setOffset(page * limit);
            setLimit(pageSize);
            setOrder(order)
          }}
          options={{
            headerStyle: {
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.common.white,
              fontWeight: "bold"
            },
          }}
        />
      }
    </Box>
  );
}
