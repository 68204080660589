import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import thunk from 'redux-thunk'
import logger from "redux-logger";
import EMRootReducer from '../reducers'
import EMRootSaga from '../sagas'

//const sagaMiddleware = createSagaMiddleware()

// const store = createStore(EMRootReducer, applyMiddleware(logger, thunk));

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(EMRootReducer, /* preloadedState, */ composeEnhancers(
    applyMiddleware(logger, thunk)
  ));
//sagaMiddleware.run(EMRootSaga)


export default store