import {
    CHANNEL_ADMIN_CHANNEL_LIST_DROPDOWN_SUCCESS,
    CHANNEL_ADMIN_CHANNEL_USERS_LIST_DROPDOWN,
    SHOW_MESSAGE,
    CHANNEL_ADMIN_CHANNEL_USER_ROLE_DROPDOWN_SUCCESS,
    CHANNEL_ADMIN_USER_DETAILS_UPDATE_SUCCESS,
    UI_LOADING,
    CHANNEL_ADMIN_USERS_EXPORT_SUCCESS,
    CHANNEL_ADMIN_CHANNEL_LIST_SUCCESS,
    CHANNEL_ADMIN_CHANNEL_UPDATE_SUCCESS,
    CHANNEL_ADMIN_GROUP_LIST_DROPDOWN_SUCCESS,
    CHANNEL_ADMIN_CHANNEL_DETAILS_BY_ID,
    CHANNEL_ADMIN_CHANNEL_SETTINGS_UPDATE_SUCCESS,
    CHANNEL_ADMIN_IMPORT_USERS_LIST_SUCCESS,
    CHANNEL_ADMIN_IMPORT_USERS_SUCCESS,
    CHANNEL_ADMIN_RESEND_INVITE_SUCCESS,
    CHANNEL_ADMIN_RESEND_USERS_LIST_SUCCESS,
    CHANNEL_ADMIN_EMAIL_TEMPLATE_TYPE_LIST_SUCCESS,
    CHANNEL_ADMIN_LIST_ROLE_SUCCESS,
    CHANNEL_ADMIN_ROLE_CREATE_SUCCESS,
    ENTITY_ROLE_RETRIEVE_SUCCESS,
    CHANNEL_ADMIN_CHANNEL_SETTINGS_UPDATE_SUCCESS_FOR_USER_PLATFORM,
    CHANNEL_ADMIN_REMOVE_CHANNEL_USER,
    CHANNEL_ADMIN_GET_GROUP_LIST,
    CHANNEL_ADMIN_ENTITY_LIST,
    CHANNEL_ADMIN_GET_DASHBOARD_DATA,
    CHANNEL_ADMIN_GET_CHANNEL_LIST,
    CHANNEL_ADMIN_GET_USER_ENGAGEMENT_DATA,
    CHANNEL_ADMIN_GET_DATA_FOR_DROPDOWN_IN_GAMIFICATION,
    CHANNEL_ADMIN_GET_LEVEL_REPORT,
    CHANNEL_ADMIN_GET_BADGE_REPORT,
    CHANNEL_ADMIN_GET_LIST_OF_CHANNELS_IN_USER_MANAGEMENT,
    CHANNEL_ADMIN_GET_PERMISSION_BASED_ON_CHANNEL,
  } from "../constants/index"
  import API from "../../api"
  import { handleError } from "../../utils/EMCommonFunctions";
  /* Action for select channel dropdown */
  
  export const EMDoGetChannelAdminChannelListDropDownAction = action => {
    return dispatch => {
      API.EMDoGetChannelAdminChannelListDropDown(action)
        .then(data => data.data)
        .then(data => {
          if (data) {
            dispatch({
              type: CHANNEL_ADMIN_CHANNEL_LIST_DROPDOWN_SUCCESS,
              payload: data
            });
          } else {
            dispatch({
              type: SHOW_MESSAGE,
              payload: {
                type: "error",
                message: "Error occured while fetching channel list"
              }
            });
          }
        })
        .catch(err => {
          handleError(dispatch, err)
        });
    };
  };
  
  export const EMDoGetChannelUserRolesListAction = action => {
    return dispatch => {
      API.EMDoGetChannelUserRolesList(action)
        .then(data => data.data)
        .then(data => {
          if (data) {
            dispatch({
              type: CHANNEL_ADMIN_CHANNEL_USER_ROLE_DROPDOWN_SUCCESS,
              payload: data
            });
          } else {
            dispatch({
              type: SHOW_MESSAGE,
              payload: {
                type: "error",
                message: "Error occured while fetching role list"
              }
            });
          }
        })
        .catch(err => {
          handleError(dispatch, err)
        });
    };
  };
  
  export const EMDoUpdateChannelUserDetailsAction = action => {
    return dispatch => {
      
      dispatch({ type: UI_LOADING, payload: {state: true, message:"Please wait"}  });
      API.EMDoUpdateChannelUserDetails(action)
        .then(data => data.data)
        .then(response => {
          if (response) {
            dispatch({
              type: CHANNEL_ADMIN_USER_DETAILS_UPDATE_SUCCESS,
              payload: response
            });
            dispatch({ type: UI_LOADING, payload: {state: false, message:""}  });
            dispatch({
              type: SHOW_MESSAGE,
              payload: {
                type: "success",
                message: "User details updated successfully",
                key: action.key
              }
            });
          } else {
            dispatch({
              type: SHOW_MESSAGE,
              payload: {
                type: "error",
                message: "Unable to update the user details"
              }
            });
            dispatch({ type: UI_LOADING, payload: {state: false, message:""}  });
          }
        })
        .catch(err => {
          dispatch({ type: UI_LOADING, payload: {state: false, message:""}  });
          handleError(dispatch, err)
        });
    };
  };
  
  export const EMDOGetChannelAdminChannelUsersListAction = action => {
    
    return dispatch => {
      dispatch({ type: UI_LOADING, payload: {state: true, message:"Please wait"}  });
      API.EMDOGetChannelAdminChannelUsersList(action)
        .then(data => data.data)
        .then(response => {
          if (response) {
            dispatch({
              type: CHANNEL_ADMIN_CHANNEL_USERS_LIST_DROPDOWN,
              payload: response
            });
            dispatch({ type: UI_LOADING, payload: {state: false, message:""}  });
          } else {
            dispatch({
              type: SHOW_MESSAGE,
              payload: {
                type: "error",
                message: "Error occured while fetching channel users list"
              }
            });
            dispatch({ type: UI_LOADING, payload: {state: false, message:""}  });
          }
        })
        .catch(err => {
          dispatch({ type: UI_LOADING, payload: {state: false, message:""}  });
          handleError(dispatch, err)
        });
    };
  };

  export const EMDoExportChannelAdminUsersAction = action => {
    
    return dispatch => {
      dispatch({ type: UI_LOADING, payload: {state: true, message:"Please wait"}  });
      let userData = { ...action };
      delete userData.key;
      API.EMDoExportChannelAdminUsers(action)
        .then(data => data.data)
        .then(response => {
          if (response) {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'ExportedUserDetails.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
            dispatch({
              type: CHANNEL_ADMIN_USERS_EXPORT_SUCCESS,
              payload: response
            });
            dispatch({ type: UI_LOADING, payload: {state: false, message:""}  });
            dispatch({
              type: SHOW_MESSAGE,
              payload: {
                type: "success",
                message: "Users exported successfully",
                key: action.key
              }
            });
          } else {
            dispatch({
              type: SHOW_MESSAGE,
              payload: {
                type: "error",
                message: "Unable to export users"
              }
            });
            dispatch({ type: UI_LOADING, payload: {state: false, message:""}  });
          }
        })
        .catch(err => {
          dispatch({ type: UI_LOADING, payload: {state: false, message:""}  });
          handleError(dispatch, err)
        });
    };
  };

//Channel Management Actions
export const EMDoGetChannelAdminChannelListAction = action => {
  return dispatch => {
    API.EMDoGetChannelAdminChannelList(action)
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: CHANNEL_ADMIN_CHANNEL_LIST_SUCCESS,
            payload: data
          });
          dispatch({ type: UI_LOADING, payload: {state: false, message:""}  });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching channel list",
              key: action.key
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoUpdateChannelAdminChannelAction = action => {
  return dispatch => {
    API.EMDoUpdateChannelAdminChannel(action)
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: CHANNEL_ADMIN_CHANNEL_UPDATE_SUCCESS,
            payload: data
          });
          dispatch({ type: UI_LOADING, payload: {state: false, message:""}  });
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Channel details updated successfully",
              key: action.key
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching channel list",
              key: action.key
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetChannelAdminGroupListDropDownAction = action => {
  return dispatch => {
    API.EMDoGetChannelAdminGroupListDropDown(action)
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: CHANNEL_ADMIN_GROUP_LIST_DROPDOWN_SUCCESS,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching group list"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

/* Action for Get Channel Details By Id */
export const EMDoGetChannelAdminChannelDetailsByIdAction = action => {
  
  let key = action.key
  delete (action.key)
 
  return dispatch => {
    API.EMDoGetChannelAdminChannelDetailsById(action.id)
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: CHANNEL_ADMIN_CHANNEL_DETAILS_BY_ID,
            payload: data
          });
          dispatch({ type: UI_LOADING, payload: {state: false, message:""}  });
          if(action.usedIn && action.usedIn !== "Activity Feed"){
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Channel Data is ready to update",
              key: key
            }
          })}
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching channel data",
              //key: key
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

/* Action for Put - Update Channel Details By Id */

export const EMDoUpdateChannelAdminChannelDetailsByIdAction = action => {
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state: false, message:""}  });

    API.EMDoUpdateChannelAdminChannelDetailsById(action)
    
      .then(data => data.data)
      .then(response => {

        if (response) {
          if(action.route == "user_platform") {
            dispatch({type:CHANNEL_ADMIN_CHANNEL_SETTINGS_UPDATE_SUCCESS_FOR_USER_PLATFORM,
              payload: response
            })
          } else
          dispatch({
            type: CHANNEL_ADMIN_CHANNEL_SETTINGS_UPDATE_SUCCESS,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state: false, message:""}  });
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Channel Updated Successfully",
              key: action.key
            }
          });
          //dispatch(EMDoGetChannelAdminChannelListAction());
        } else {
        
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to Update Channel"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state: false, message:""}  });
        }
      })
      .catch(err => {
        if (err.response && err.response.data) {
          dispatch({ type: UI_LOADING, payload: {state: false, message:""}  });
          handleError(dispatch, err)
        }
      });
  };
};

//import users action
export const EMDoGetChannelAdminImportUsersListAction = action => {
  return dispatch => {
    API.EMDoGetChanelAdminImportUsersList(action)
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: CHANNEL_ADMIN_IMPORT_USERS_LIST_SUCCESS,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching import list"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoImportChannelAdminSelectedUsersList = action => {
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state: true, message:"Please wait"}  });
    let userData = { ...action };
    API.EMDoImportChannelAdminSelectedUsersList(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: CHANNEL_ADMIN_IMPORT_USERS_SUCCESS,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state: false, message:""}  });
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Invitations sent successfully",
              key: action.key
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to send invite"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state: false, message:""}  });
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state: false, message:""}  });
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetResendInviteUsersChannelAdminListAction = action => {
  return dispatch => {
    API.EMDoGetResendInviteUsersChannelAdminList(action)
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: CHANNEL_ADMIN_RESEND_USERS_LIST_SUCCESS,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching users list"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoResendInviteToChannelAdminUsersAction = action => {
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state: true, message:"Please wait"}  });
    let userData = { ...action };
    delete userData.key;
    API.EMDoChannelAdminResendInviteToUsers(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: CHANNEL_ADMIN_RESEND_INVITE_SUCCESS,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state: false, message:""}  });
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Invitations sent successfully",
              key: action.key
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to resend invite"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state: false, message:""}  });
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state: false, message:""}  });
        handleError(dispatch, err)
      });
  };
};

export const EMDOGetChannelAdminEmailTemplateTypeListAction = action => {
  return dispatch => {
    API.EMDOGetChannelAdminEmailTemplateTypeList(action)
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: CHANNEL_ADMIN_EMAIL_TEMPLATE_TYPE_LIST_SUCCESS,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching template list"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

/* Manage Role and Permissions */
//List Roles based on channel
export const EMDoListRolesForSelectedChannelAction = action => {
  return dispatch => {
    API.EMDoListRolesForSelectedChannel(action)
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: CHANNEL_ADMIN_LIST_ROLE_SUCCESS,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching role list"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

//Post - Create new Role 

export const EMDoCreateNewRoleForChannelAction = action => {
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state: true, message:"Please wait"}  });
    let userData = { ...action };
    delete userData.key;
    
    API.EMDoCreateNewRoleForChannel(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: CHANNEL_ADMIN_ROLE_CREATE_SUCCESS,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state: false, message:""}  });
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Role added successfully",
              key: action.key
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to create the role"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state: false, message:""}  });
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state: false, message:""}  });
        handleError(dispatch, err)
      });
  };
};

//GET - for Role Specific Permissions
export const EMDoRetrievePermissionsForChannelAction = action => {
  
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state: true, message:"Please wait"}  });
    API.EMDoRetrievePermissionsForChannel(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          
          dispatch({
            type: ENTITY_ROLE_RETRIEVE_SUCCESS,
            payload: response
          });
          dispatch({ type: UI_LOADING, payload: {state: false, message:""}  });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching role details"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state: false, message:""}  });
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state: false, message:""}  });
        handleError(dispatch, err)
      });
  };
};

//Put - Update Role
export const EMDoUpdateRoleForChannelAction = action => {
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state: true, message:"Please wait"}  });
    let userData = { ...action };
    delete userData.key;
    
    API.EMDoUpdateRoleForChannel(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          // dispatch({
          //   type: ENTITY_ROLE_CREATE_SUCCESS,
          //   payload: response
          // });
          dispatch({ type: UI_LOADING, payload: {state: false, message:""}  });
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Role updated successfully",
              key: action.key
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to create the role"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state: false, message:""}  });
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state: false, message:""}  });
        handleError(dispatch, err)
      });
  };
};

//  action for removing channel member
export const EMAdminRemoveChannelUserAction = action => {
  return dispatch => {
    API.EMAdminRemoveChannelUser(action.data)
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "User is Removed",
            }
          });
        
         dispatch({
          type:CHANNEL_ADMIN_REMOVE_CHANNEL_USER,
          payload:action.data
          
         })
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching entity list",
              //key: key
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};
//Patch - active inactive 
export const EMDoUpdateStatusOfRoleforChannelAction = action => {
  
  return dispatch => {
    dispatch({ type: UI_LOADING, payload: {state: true, message:"Please wait"}  });
    API.EMDoUpdateStatusOfRoleforChannel(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Role updated Successfully"
            }
          })
          dispatch({ type: UI_LOADING, payload: {state: false, message:""}  });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching role list"
            }
          });
          dispatch({ type: UI_LOADING, payload: {state: false, message:""}  });
        }
      })
      .catch(err => {
        dispatch({ type: UI_LOADING, payload: {state: false, message:""}  });
        handleError(dispatch, err)
      });
  };
}
// GET DASHBOARD DATA ACTION
export const EMDoGetChannelDashboardInfo = action => {
  
  return dispatch => {
    API.EMDoGetChannelDashboardInfo(action.requiredData)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: CHANNEL_ADMIN_GET_DASHBOARD_DATA,
            payload: response
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching dashboard data"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

// DASHBOARD ENTITY DATA
export const EMDoGetEntityListForChannelAction = action => {
  return dispatch => {
    API.EMDoGetEntityListForChannel(action)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: CHANNEL_ADMIN_ENTITY_LIST,
            payload: response
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching dashboard data"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

//Get group list For  channel Dashboard
export const EMDoGetChannelBasedOnOrgOrPartnershipAction = action => {
  return dispatch => {
    API.EMDoGetChannelBasedOnOrgOrPartnership(action.requiredData)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: CHANNEL_ADMIN_GET_GROUP_LIST,
            payload: response
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching group list"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetListForChannelAction = action => {
  return dispatch => {
    API.EMDoGetListForChannel(action.requiredData)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: CHANNEL_ADMIN_GET_CHANNEL_LIST,
            payload: response
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching channel list"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

// Reports user engagement
export const EMDoGetUserEngagementReportForChannelAction = action => {
  return dispatch => {
    API.EMDoGetUserEngagementReportForChannel(action.requiredData, action.paginationData)
      .then(data => data.data)
      .then(response => {
        if (response) {
          dispatch({
            type: CHANNEL_ADMIN_GET_USER_ENGAGEMENT_DATA,
            payload: response
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching User Engagement data"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};
// Export report
export const EMDoExportUserEngagementReportForChannelAction = action => {
  return dispatch => {
    API.EMDoExportUserEngagementReportForChannel(action.requiredData, action.groupOrChannel)
      .then(data => data.data)
      .then(response => {
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'UserEngagementReport.csv');
          document.body.appendChild(link);
          link.click();
          link.remove();
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "User engagement report exported successfully",
              key: action.key
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to export User engagement report"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

//download attachments for channels
export const EMDoDownloadAttachmentsForChannelAction = action => {
  return dispatch => {
    API.EMDoDownloadAttachmentsForChannel(action.channelId)
      .then(data => data.data)
      .then(response => {
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Channel Attachments.zip');
          document.body.appendChild(link);
          link.click();
          link.remove();
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Channel attachments downloaded successfully",
              key: action.key
            }
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to download attachments"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetDataForDropdownInGamificationForChannelAction = action => {
  return dispatch => {
    API.EMDoGetDataForDropdownInGamification("channel")
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: CHANNEL_ADMIN_GET_DATA_FOR_DROPDOWN_IN_GAMIFICATION,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching network list",
              key: action.key
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetLevelReportForChannelAction = action => {
  return dispatch => {
    API.EMDoGetLevelAndBadgeReport(action.entityType, action.entityId, "level")
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: CHANNEL_ADMIN_GET_LEVEL_REPORT,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching network list",
              key: action.key
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetBadgeReportForChannelAction = action => {
  return dispatch => {
    API.EMDoGetLevelAndBadgeReport(action.entityType, action.entityId, "badge")
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: CHANNEL_ADMIN_GET_BADGE_REPORT,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching network list",
              key: action.key
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetListOfChannelsForDropdownInUserManagementAction = action => {
  return dispatch => {
    API.EMDoGetListOfChannelsForDropdownInUserManagement()
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: CHANNEL_ADMIN_GET_LIST_OF_CHANNELS_IN_USER_MANAGEMENT,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching channel list",
              key: "get_channels_error"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetPermissionSpecificToChannelAction = action => {
  return dispatch => {
    API.EMDoGetPermissionSpecificToChannel(action.id)
      .then(data => data.data)
      .then(data => {
        if (data) {
          dispatch({
            type: CHANNEL_ADMIN_GET_PERMISSION_BASED_ON_CHANNEL,
            payload: data
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching user's permission",
              key: "channel_permissions_error"
            }
          });
        }
      })
      .catch(err => {
        handleError(dispatch, err)
      });
  };
};

