import {
    LIST_EVENTS_BY_ME,
    LIST_ALL_MY_EVENTS,
    LIST_UPCOMING_EVENTS,
    LIST_PAST_EVENTS,
    LIST_MONTH_EVENTS,
    LIST_GROUPS_FOR_FILTER,
    DISABLE_ENABLE_FILTER_FOR_GROUPS,
    DISABLE_ENABLE_FILTER_FOR_GROUPS_FUTURE_EVENTS,
    DISABLE_ENABLE_FILTER_FOR_GROUPS_PAST_EVENTS
} from "../../../constants/UserPlatform/EMEventsConstant"
import { SHOW_MESSAGE } from "../../../constants";
import API from "../../../../api";
import { handleError } from "../../../../utils/EMCommonFunctions";

//action for Channel View
export const EMDoGetEventsByMeAction = action => {
    return dispatch => {
        API.EMDoGetEventsByMe(action)
            .then(data => data.data)
            .then(response => {

                if (response) {

                    dispatch({
                        type: LIST_EVENTS_BY_ME,
                        payload: { response: response, userData: action.userData }
                    });
                } else {
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: {
                            type: "error",
                            message: "Error while fetching events"
                        }
                    });
                }
            })
            .catch(err => {
                handleError(dispatch, err)
            });
    };
};

export const EMDoGetMyAllEventsAction = action => {
    return dispatch => {
        API.EMDoGetMyAllEvents(action)
            .then(data => data.data)
            .then(response => {

                if (response) {

                    dispatch({
                        type: LIST_ALL_MY_EVENTS,
                        payload: { response: response, userData: action.userData }
                    });
                } else {
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: {
                            type: "error",
                            message: "Error while fetching events"
                        }
                    });
                }
            })
            .catch(err => {
                handleError(dispatch, err)
            });
    };
};

export const EMDoGetUpcomingEventsAction = action => {
    return dispatch => {
        API.EMDoGetUpcomingEvents(action)
            .then(data => data.data)
            .then(response => {

                if (response) {

                    dispatch({
                        type: LIST_UPCOMING_EVENTS,
                        payload: { response: response, userData: action.userData }
                    });
                    dispatch({
                        type:DISABLE_ENABLE_FILTER_FOR_GROUPS_FUTURE_EVENTS,
                        payload:false
                      })
                } else {
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: {
                            type: "error",
                            message: "Error while fetching events"
                        }
                    });
                }
            })
            .catch(err => {
                handleError(dispatch, err)
            });
    };
};

export const EMDoGetPastEventsAction = action => {
    return dispatch => {
        API.EMDoGetPastEvents(action)
            .then(data => data.data)
            .then(response => {

                if (response) {

                    dispatch({
                        type: LIST_PAST_EVENTS,
                        payload: { response: response, userData: action.userData }
                    });
                    dispatch({
                        type:DISABLE_ENABLE_FILTER_FOR_GROUPS_PAST_EVENTS,
                        payload:false
                      })
                } else {
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: {
                            type: "error",
                            message: "Error while fetching events"
                        }
                    });
                }
            })
            .catch(err => {
                handleError(dispatch, err)
            });
    };
};

export const EMDoGetMonthEventsAction = action => {

    return dispatch => {
        // 
        if (action.calendarView !== undefined) {
            API.EMDoGetMonthEvents(action.calendarView)
                .then(data => data.data)
                .then(response => {

                    if (response) {


                        dispatch({
                            type: LIST_MONTH_EVENTS,
                            payload: { response: response, userData: action.userData }
                        });
                        dispatch({
                            type:DISABLE_ENABLE_FILTER_FOR_GROUPS,
                            payload:false
                          })
                    } else {
                        dispatch({
                            type: SHOW_MESSAGE,
                            payload: {
                                type: "error",
                                message: "Error while fetching events"
                            }
                        });
                    }
                })
                .catch(err => {
                    handleError(dispatch, err)
                });
        }
    };
};


export const EMDoGetGroupsForFilterAction = action => {
    return dispatch => {
        API.EMDoGetGroupsForFilter(action)
            .then(data => data.data)
            .then(response => {

                if (response) {

                    dispatch({
                        type: LIST_GROUPS_FOR_FILTER,
                        payload: response
                    });
                } else {
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: {
                            type: "error",
                            message: "Error while fetching events"
                        }
                    });
                }
            })
            .catch(err => {
                handleError(dispatch, err)
            });
    }
};

export const EMDoGetEventsByGroupFilterAction = action => {
    return dispatch => {
        if (action.calendarView !== undefined) {
            API.EMDoGetEventsByGroupFilter(action.calendarView, action.id)
                .then(data => data.data)
                .then(response => {

                    if (response) {

                        dispatch({
                            type: LIST_MONTH_EVENTS,
                            payload: { response: response, userData: action.userData }
                        });
                        dispatch({
                            type:DISABLE_ENABLE_FILTER_FOR_GROUPS,
                            payload:false
                          })
                    } else {
                        dispatch({
                            type: SHOW_MESSAGE,
                            payload: {
                                type: "error",
                                message: "Error while fetching events"
                            }
                        });
                    }
                })
                .catch(err => {
                    handleError(dispatch, err)
                });
        }
    }
};

export const EMDoGetUpcomingEventsByGroupFilterAction = action => {
    return dispatch => {
        API.EMDoGetUpcomingEventsByGroupFilter(action.groupId)
            .then(data => data.data)
            .then(response => {

                if (response) {

                    dispatch({
                        type: LIST_UPCOMING_EVENTS,
                        payload: { response: response, userData: action.userData }
                    });
                    dispatch({
                        type:DISABLE_ENABLE_FILTER_FOR_GROUPS_FUTURE_EVENTS,
                        payload:false
                      })
                } else {
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: {
                            type: "error",
                            message: "Error while fetching events"
                        }
                    });
                }
            })
            .catch(err => {
                handleError(dispatch, err)
            });
    };
};

export const EMDoGetPastEventsByGroupFilterAction = action => {
    return dispatch => {
        API.EMDoGetPastEventsByGroupFilter(action.groupId)
            .then(data => data.data)
            .then(response => {

                if (response) {

                    dispatch({
                        type: LIST_PAST_EVENTS,
                        payload: { response: response, userData: action.userData }
                    });
                    dispatch({
                        type:DISABLE_ENABLE_FILTER_FOR_GROUPS_PAST_EVENTS,
                        payload:false
                      })
                } else {
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: {
                            type: "error",
                            message: "Error while fetching events"
                        }
                    });
                }
            })
            .catch(err => {
                handleError(dispatch, err)
            });
    };
};