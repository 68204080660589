import React, { useEffect, useReducer, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import { useDispatch, useSelector } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import { useFormik, Field, Formik } from "formik";
import * as yup from "yup";
import {
  Card,
  Box,
  Paper,
  Grid,
  Button,
  TextField,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
  FormHelperText,
} from "@material-ui/core";
import { mergeClasses } from "@material-ui/styles";
import { EMSimpleTextFieldView } from "../EMTextFieldView";
import {
  SHOW_CREATE_POST,
  SHOW_POST_EDIT,
} from "../../redux/constants/UserPlatform";
import EMMultipleSelect from "../commans/EMMultipleSelect";
import {
  EMDoCreateNewPollAction,
  EMDoEditPollAction,
  EMDoGetPollSharedWithGroupsListAction,
} from "../../redux/actions/UserPlatform/Home/EMLatestPostPageAction";
import { find, findLast } from "lodash";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: 30,
    // paddingBottom: 5,
    maxHeight: window.screen.height / 1.2,
    width: "100%",
    overflow: "auto",
  },
  content: {
    padding: "5px",
    // width: "100%",
    maxHeight: "80%",
  },
  buttonGridforSave: {
    marginTop: theme.spacing(0.5),
    color: "primary",
  },
  options: {
    padding: "5px",
  },
}));

export default function EMCreatePoll(props) {
  const classes = useStyles();
  const data = useSelector((state) => state.home || {});

  const theme = useTheme();

  const matchesSmallScreen = useMediaQuery(theme.breakpoints.between(0, 600));

  
  const uiData = useSelector(state => state.ui || {})

  const postSharedWithGroups = useSelector(
    (state) => state.home.postSharedWithGroups || []
  );

  const dispatch = useDispatch();

  const duration = [
    { id: 1, duration: "1 Day" },
    { id: 2, duration: "3 Day" },
    { id: 3, duration: "7 Day" },
  ];

  const privacy = [
    { id: 1, privacy: "Anonymous" },
    { id: 2, privacy: "Regular" },
  ];

  const FIELDS_SCHEMA = yup.object().shape({
    value: yup.string().required("Option field should not be empty"),
  });

  const validationSchema = yup.object({
    title: yup
      .string("Enter title for your poll")
      .required("Title is required"),
    question: yup
      .string("Enter qusetion for your poll")
      .required("Qusetion is required"),
    fields: yup
      .array()
      .min(2, "At least 2 options are required and should not be empty")
      .of(FIELDS_SCHEMA)
      .ensure()
      .compact((v) => !v.value)
      .required("Option field should not be empty"),
    groups: yup
      .array()
      .transform(function (value, originalValue) {
        if (this.isType(value) && value !== null) {
          return value;
        }
        return originalValue ? originalValue.split(/[\s,]+/) : [];
      })
      .required("Please Select Groups")
      .min(1, "Select Atleast 1 group"),
    duration: yup
      .string("Please select the duration")
      .required("Please select the duration"),
    privacy_type: yup
      .string("Please select the privacy type")
      .required("Please select the privacy type"),
  });

  let previousAnswers;
  if (props.post) {
    previousAnswers = props.post.poll_details.Answers.map((ans, index) => ({
      value: ans.Ans,
      label: `Option ${index + 1}`,
      disabled: true,
    }));
  }

  let prevDataForGroups = [];
  if (props.post && prevDataForGroups.length <= 0) {
    let groupArray = props.post.groupName.split(",");
    if(groupArray){
      groupArray.forEach(group => {
        let index = find(postSharedWithGroups, {
          group_name: group.trim(),
        });
        let showValue = index !== undefined && index.group_name + " |" + index.id
        if (index !== undefined && index !== false) {
          prevDataForGroups.push(showValue);
        }
      })
    }
  } else if (props.forGroup && props.groupName) {
    let index = find(postSharedWithGroups, { group_name: props.groupName });
    let showValue = index !== undefined && index.group_name + " |" + index.id;
    if (index !== undefined && index !== false) {
      prevDataForGroups.push(showValue);
    }
  }

  let selected_channelIds = [];
  if (props.post && props.usedIn !== "channels") {
    selected_channelIds = prevDataForGroups;
  } else if (props.id && props.usedIn == "channels") {
    selected_channelIds = [props.id];
  } else if (props.forGroup && props.groupName) {
    let index = find(postSharedWithGroups, { group_name: props.groupName });
    let showValue = index !== undefined && index.group_name + " |" + index.id;
    if (index !== undefined && index !== false) {
      selected_channelIds = [showValue];
    }
  } else {
    selected_channelIds = [];
  }

  const formik = useFormik({
    initialValues: {
      groups: props?.selectedChannelIds ? props.selectedChannelIds : [],
      title: props.post ? props.post.poll_details.title : "",
      question: props.post ? props.post.poll_details.question : "",
      duration: props.post ? props.post.poll_details.poll_duration : "",
      privacy_type: props.post ? props.post.poll_details.poll_privacy : "",
      fields: props.post
        ? previousAnswers
        : [
            { value: null, label: "Option 1", disabled: false },
            { value: null, label: "Option 2", disabled: false },
          ],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (props.post) {
        let groups = [];
        if (props.postFrom == "home") {
          let sharedWithGroup = formik.values.groups;
          sharedWithGroup.map((grp) => {
            if (typeof grp === "string") {
              if (grp.indexOf(" |") > -1) {
                let grpName = grp.substr(0, grp.indexOf(" |"));
                let index = find(postSharedWithGroups, { group_name: grpName });
                groups.push(index.id);
              }
            } else {
              groups.push(grp);
            }
          });
        } else if (props.postFrom == "channel") {
          props.post.channel_details.map((channel) => {
            let channelId = channel.channel_id;
            groups.push(channelId);
          });
        }

        var newOptionsArray = formik.values.fields.filter(function (objFromA) {
          return !previousAnswers.find(function (objFromB) {
            return objFromA.value === objFromB.value;
          });
        });
        
        let finalObject = {
          title: formik.values.title,
          question: formik.values.question,
          new_channel_ids: groups.toString(),
          poll_privacy: formik.values.privacy_type,
          poll_duration: formik.values.duration,
          entity: "poll",
          options: previousAnswers.map((field) => {
            return field.value;
          }),
          new_options: newOptionsArray.map((field) => {
            return field.value;
          }),
        };
        dispatch(
          EMDoEditPollAction({
            finalObject: finalObject,
            Id: props.post.id,
          })
        );
      } else {
        let groups = [];

        let sharedWithGroup = formik.values.groups;
        sharedWithGroup.map((grp) => {
          if (typeof grp === "string") {
            if (grp.indexOf(" |") > -1) {
              let grpName = grp.substr(0, grp.indexOf(" |"));
              let index = find(postSharedWithGroups, { group_name: grpName });
              groups.push(index.id);
            }
          } else {
            groups.push(grp);
          }
        });

        let finalObject = {
          title: formik.values.title,
          question: formik.values.question,
          channel_ids: groups.toString(),
          poll_privacy: formik.values.privacy_type,
          poll_duration: formik.values.duration,
          entity: "poll",
          options: formik.values.fields.map((field) => {
            return field.value;
          }),
        };
        dispatch(
          EMDoCreateNewPollAction({
            finalObject,
          })
        );
      }
    },
  });

  useEffect(() => {
    
    if (uiData["messages"]) {
      let refObj = findLast(uiData["messages"], { key: "post_error" });
      if (refObj && refObj.type === "error") {
        formik.setSubmitting(false)
        uiData["messages"] = []
      }
    }
  },[uiData])

  return (
    <Paper elevation={2} className={classes.root}>
      <form onSubmit={formik.handleSubmit}>
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          style={{
            padding: "0px 4px 0px 4px",
            backgroundColor: "#2F92D6",
            height: "auto",
          }}
        >
          <Typography
            style={{
              margin: 10,
              flexGrow: 1,
              fontWeight: "bold",
              color: "white",
              fontSize: "large",
            }}
          >
            {props.post ? props.post.editPost.labels.title : "Create a Poll"}
          </Typography>
          <Box>
            <IconButton
              onClick={() => {
                props.post
                  ? dispatch({
                      type: SHOW_POST_EDIT,
                      payload: {
                        id: props.post.id,
                        editPost: false,
                        title: "Edit Poll",
                        button: "Update Poll",
                      },
                    })
                  : dispatch({
                      type: SHOW_CREATE_POST,
                      payload: { value: false, route: "create_poll" },
                    });
              }}
            >
              <CloseIcon style={{color:"white"}}/>
            </IconButton>
          </Box>
        </Box>
        <Box className={classes.content}>
          <EMSimpleTextFieldView
            className={classes.textField}
            label={"Title"}
            id={"title"}
            name={"title"}
            value={formik.values.title}
            onChange={formik.handleChange}
            // error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && formik.errors.title}
          />
        </Box>
        <Box className={classes.content}>
          <EMSimpleTextFieldView
            className={classes.textField}
            label={"Question"}
            id={"question"}
            name={"question"}
            value={formik.values.question}
            onChange={formik.handleChange}
            // error={formik.touched.question && Boolean(formik.errors.question)}
            helperText={formik.touched.question && formik.errors.question}
          />
        </Box>

        <Box className={classes.content}>
          {formik.values.fields.map((field, idx) => {
            return (
              <Grid
                md={6}
                xs={12}
                container
                spacing={2}
                key={`${field}-${idx}`}
              >
                <Grid item xs={11} alignItems="center" justifyContent="center">
                  <EMSimpleTextFieldView
                    disabled={field.disabled}
                    // error={
                    //   formik.touched.fields && Boolean(formik.errors.fields)
                    // }
                    helperText={formik.touched.fields && formik.errors.fields}
                    type="text"
                    placeholder="Enter Option"
                    name={field.label}
                    label={field.label}
                    value={field.value}
                    onChange={(e) => {
                      const values = [...formik.values.fields];
                      values[idx].value = e.target.value;
                      formik.setFieldValue("fields", values);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  container
                  direction="row"
                  alignItems="flex-end"
                  justify="center"
                >
                  <Button
                    disabled={
                      formik.values.fields.length <= 2 || field.disabled
                    }
                    onClick={() => {
                      const values = [...formik.values.fields];
                      values.splice(idx, 1);
                      formik.setFieldValue("fields", values);
                    }}
                  >
                    <CloseIcon />
                  </Button>
                </Grid>
              </Grid>
            );
          })}
        </Box>
        <Grid container spacing={2} className={classes.content}>
          <Grid item xs={12} md={5}>
            <Button
              variant="outlined"
              onClick={() => {
                const values = [...formik.values.fields];
                values.push({
                  value: null,
                  label: `Option ${formik.values.fields.length + 1}`,
                  disabled: false,
                });
                formik.setFieldValue("fields", values);
              }}
              style={{ marginTop: "3%" }}
            >
              <AddCircleOutlineRoundedIcon
                color="primary"
                style={{ width: "30px" }}
              />
              Add Another Option
            </Button>
          </Grid>
        </Grid>

        <Grid container spacing={3} className={classes.content}>
          <Grid
            item
            md={6}
            xs={12}
            container
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item style={{ width: "46%" }}>
              <FormControl fullWidth>
                <InputLabel id="select-network" style={{color:"#4d4d4d"}}>Duration</InputLabel>
                <Select
                  labelId="select-duration"
                  id="select-network"
                  value={formik.values.duration}
                  label="Select Duration"
                  onChange={(selected) => {
                    formik.setFieldValue("duration", selected.target.value);
                  }}
                >
                  {duration &&
                    duration.length > 0 &&
                    duration.map((duration) => (
                      <MenuItem key={duration.id} value={duration.duration}>
                        {duration.duration}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText style={{ color: "red" }}>
                  {formik.errors.duration && formik.touched.duration
                    ? formik.errors.duration
                    : ""}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item style={{ width: "46%" }}>
              <FormControl fullWidth>
                <InputLabel id="select-privacy" style={{color:"#4d4d4d"}}>Privacy</InputLabel>
                <Select
                  labelId="select-privacy"
                  id="select-privacy"
                  value={formik.values.privacy_type}
                  label="Select Privacy"
                  // error={
                  //   formik.errors.privacy_type && formik.touched.privacy_type
                  //     ? true
                  //     : false
                  // }
                  // helperText={formik.touched.selectedNetwork && formik.errors.selectedNetwork}
                  onChange={(selected) => {
                    formik.setFieldValue("privacy_type", selected.target.value);
                  }}
                >
                  {privacy &&
                    privacy.length > 0 &&
                    privacy.map((privacy) => (
                      <MenuItem key={privacy.id} value={privacy.privacy}>
                        {privacy.privacy}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText style={{ color: "red" }}>
                  {formik.errors.privacy_type && formik.touched.privacy_type
                    ? formik.errors.privacy_type
                    : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
            {props.id ? null : (
              <Box>
                <EMMultipleSelect
                  label={"Select Groups"}
                  data={postSharedWithGroups}
                  objKey={"group_name"}
                  objId={"id"}
                  prevData={props?.selectedChannelIds?.length > 0 && props.selectedChannelIds}
                  formik={{
                    errors: formik.errors.groups,
                    touched: formik.touched.groups,
                  }}
                  onChange={(selectedGroups) => {
                    let ids = [];
                    selectedGroups.map((grp) => {
                      let id = String(grp).substr(
                        String(grp).lastIndexOf("|") + 1
                      );
                      ids.push(parseInt(id));
                    });
                    formik.setFieldValue("groups", ids);
                  }}
                />
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          style={{ marginTop: matchesSmallScreen ? "8px" : 0 }}
          // className={classes.buttonGridforSave}
        >
          <Grid
            item
            xs={6}
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button variant="contained" color="primary" type="submit" disabled={formik.isSubmitting}>
              {props.post ? props.post.editPost.labels.title : "Create Poll"}
            </Button>
          </Grid>

          <Grid
            item
            xs={6}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Button
              variant="contained"
              onClick={() => {
                props.post
                  ? dispatch({
                      type: SHOW_POST_EDIT,
                      payload: {
                        id: props.post.id,
                        editPost: false,
                        title: "Edit Poll",
                        button: "Update Poll",
                      },
                    })
                  : dispatch({
                      type: SHOW_CREATE_POST,
                      payload: { value: false, route: "create_poll" },
                    });
              }}
            >
              Discard
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}
