import React from "react";
import {
  Box,
  Button,
  Modal,
  Typography,
  IconButton,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { EMSimpleTextFieldView } from "../../../components/EMTextFieldView";
import { CloseRounded } from "@material-ui/icons";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  EMDoCreateNewDepartmentInOrganizationAction,
  EMDoUpdateDepartmentInOrganizationAction,
} from "../../../redux/actions/EMNetworkAdminActions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash-es";
import EMSingleSelect from "../../../components/commans/EMSingleSelect";
import {
  EMDoCreateActionsForOrganizationAction,
  EMDoUpdateActionsForOrganizationAction,
} from "../../../redux/actions/UserPlatform/Network/EMMyNetworkAction";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    height: "auto",
    minWidth: "50%",
    margin: "auto",
    padding: "0px",
    // maxWidth: '100vw',
    maxHeight: "100%",
    position: "fixed",
    top: "50%",
    transform: "translate(0, -50%)",
    width: "60%",
    overflowY: "auto",
    overflowX: "hidden",
  },
  title: {
    margin: 10,
    flexGrow: 1,
    fontWeight: "bold",
  },
  form: {
    padding: "8px 36px",
    width: "100%",
  },
  buttonHolder: {
    padding: theme.spacing(3),
  },
}));

export default function EMAddEditPoints(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.myNetwork || {});
  const authData = useSelector((state) => state.auth || {})

  const pointsData = [
    {
      id: 1,
      points: 5,
    },
    {
      id: 2,
      points: 10,
    },
    {
      id: 3,
      points: 15,
    },
    {
      id: 4,
      points: 20,
    },
    {
      id: 5,
      points: 25,
    },
    {
      id: 6,
      points: 30,
    },
  ];

  //reset the form after modal is closed
  /* Validation And Formik Values And Handlers */
  const validationSchema = yup.object({
    action_name: yup.string().required("Action is required"),
    points: yup.number().required("Point is required"),
  });



  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      action_name: props.isEdit ? props.actionData.actions.action_name : "",
      points: props.isEdit
        ? props.actionData.custom_points_for_action
        : undefined,
    },
    validationSchema: validationSchema,

    onSubmit: (values) => {
      let organizationId = authData.organization_id;
      let requiredAction = data.actionsList.find(
        (action) => action.action_name == values.action_name
      );
      let points = values.points;

      if (props.isEdit) {
          let data = {
            custom_points_for_action: points,
          };

          dispatch(
            EMDoUpdateActionsForOrganizationAction({
              actionId: props.actionData.id,
              data: data,
            })
          );
      } else {
        if (requiredAction) {
          let data = {
            custom_points_for_action: points,
            actions: requiredAction.id,
            organization_id: organizationId,
          };

          dispatch(
            EMDoCreateActionsForOrganizationAction({
              organizationId: organizationId,
              data: data,
            })
          );
        }
      }

      formik.resetForm();
      props.setOpenFalse(false);
      if (props.isEdit) {
        props.setEdit(false);
      }
    },
  });
  return (
    <Modal
      open={props.open}
      onClose={() => {
        formik.resetForm();
        props.setOpenFalse(false);
        if (props.isEdit) {
          props.setEdit(false);
        }
      }}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box className={classes.root} style={{ outline: "none" }}>
        <form onSubmit={formik.handleSubmit}>
          <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          style={{
            padding: "0px 4px 0px 4px",
            backgroundColor: "#2F92D6",
            height: "auto",
          }}
          >
            <Typography      style={{
              margin: 10,
              flexGrow: 1,
              fontWeight: "bold",
              color: "white",
              fontSize: "large",
            }}>
              {props.titleLable}
            </Typography>
            <IconButton
              onClick={() => {
                formik.resetForm();
                props.setOpenFalse(false);
                if (props.isEdit) {
                  props.setEdit(false);
                }
              }}
            >
              <CloseRounded style={{color:"white"}} />
            </IconButton>
          </Box>
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.form}
            spacing={2}
          >
            <Grid item md={6} xs={12}>
              <EMSingleSelect
              disabled={props.isEdit}
                label={"Select Action"}
                objKey={"action_name"}
                required={true}
                data={data.actionsList}
                value={formik.values.action_name}
                onChange={(selectedAction) => {
                  let action = selectedAction;
                  formik.setFieldValue("action_name", action);
                }}
                error={Boolean(
                  formik.touched.action_name && formik.errors.action_name
                )}
                helperText={
                  formik.touched.action_name && formik.errors.action_name
                }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <EMSingleSelect
                label={"Select Points"}
                objKey={"points"}
                required={true}
                data={pointsData}
                value={formik.values.points}
                onChange={(selectedPoints) => {
                  let points = selectedPoints;
                  formik.setFieldValue("points", points);
                }}
                error={Boolean(formik.touched.points && formik.errors.points)}
                helperText={formik.touched.points && formik.errors.points}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="row"
            className={classes.buttonHolder}
            spacing={2}
            // onClick={() => {
            //     //checkValidation()
            // }}
          >
            <Grid item>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                type="submit"
                disabled={props.saveClicked}
              >
                <Typography variant="h7">{props.buttonLabel}</Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.button}
                variant="outlined"
                color="secondary"
                onClick={() => {
                  formik.resetForm();
                  props.setOpenFalse(false);
                  if (props.isEdit) {
                    props.setEdit(false);
                  }
                }}
              >
                <Typography variant="h7">Discard</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
}
