import React, { useRef } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import DeleteIcon from "@material-ui/icons/Delete";
import ReactPlayer from "react-player";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import { EMDoDeleteCommentFromPostAction } from "../../redux/actions/UserPlatform/Home/EMLatestPostPageAction";
import { CardActions, SvgIcon, Paper, Box } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Grid from "@material-ui/core/Grid";
import {
  USER_SHOW_COMMENT,
  SHOW_POST_MEDIA_PREVIEW,
  PLAY_VIDEO_BY_ID,
} from "../../redux/constants/UserPlatform/index";
import EMAddNewComment from "./EMAddNewComment";
import Parse from "html-react-parser";
import { EMGetRolesAndPermissionsBasedOnEntityAction } from "../../redux/actions/EMAuthActions";
import super_poll_creator from "../../assets/super_poll_creator.png";
import super_quiz_creator from "../../assets/super_quiz_creator.png";
import top_platform_user from "../../assets/top_platform_user.png";
import super_logger from "../../assets/super_logger.png";

const useStyles = makeStyles((theme) => ({
  gridItem: {
    display: "flex",
    overflow: "hidden",
  },
  root: {
    paddingLeft: "1%",
    overflow: "auto",
    width: "99%",
    maxHeight: "600px",
    "&::-webkit-scrollbar": {
      width: "0.3em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#4A4A4A",
      outline: "1px solid slategrey",
    },
  },
  addComment: {
    marginTop: "1%",
    marginBottom: "1%",
    width: "100%",
    display: "flex",
    height: "auto",
    position: "sticky",
    alignItems: "center",
  },
  picker: {
    position: "fixed",
    marginTop: "10%",
    marginLeft: "68%",
  },
  // comments: {
  //   width: "100%",
  // },
  // commentButton: {
  //   marginRight: "3%",
  //   "&:hover": {
  //     color: "auqa",
  //     cursor: "pointer",
  //   },
  // },
  commentButtonBeforeposting: { marginRight: "3%" },
  groupAvtar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginTop: "2%",
  },
  comments: {
    width: "100%",
    paddingLeft: 5,
  },
  commentButton: {
    marginRight: "3%",
    "&:hover": {
      color: "auqa",
      cursor: "pointer",
    },
  },
  avatar: {
    backgroundColor: red[500],
  },
  title: {
    fontWeight: "bolder",
    fontSize: "medium",
    // marginBottom:"1%"
  },
  dialog: {
    position: "relative",
  },
  item: {
    height: "600px",
  },
  like: {
    "&:hover": {
      color: "auqa",
      cursor: "pointer",
    },
  },
  fontSizeLarge: {
    fontSize: "2.875rem",
  },
  masonryItemFirst: {
    maxHeight: "40%",
  },
  closeButton: {
    "&:hover": {
      color: "auqa",
      cursor: "pointer",
    },
  },
  fill: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  comment: {
    "&:hover": {
      color: "auqa",
      cursor: "pointer",
    },
  },
}));

function Deleteicon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M11.436,3.375A8.061,8.061,0,1,0,19.5,11.436,8.06,8.06,0,0,0,11.436,3.375Zm2.042,10.979-2.042-2.042L9.394,14.354a.619.619,0,1,1-.876-.876l2.042-2.042L8.518,9.394a.619.619,0,0,1,.876-.876l2.042,2.042,2.042-2.042a.619.619,0,1,1,.876.876l-2.042,2.042,2.042,2.042a.622.622,0,0,1,0,.876A.615.615,0,0,1,13.478,14.354Z" />
    </SvgIcon>
  );
}

export default function EMPostContent({
  post,
  likehandler,
  id,
  permission,
  searched,
  selectedMediaId,
}) {
  const classes = useStyles();
  const data = useSelector((state) => state.home || {});
  const userData = useSelector((state) => state.auth);
  const [dimension, setDimension] = React.useState(null);
  const dispatch = useDispatch();
  const myRef = React.useRef(null);
  const location = useLocation();
  const navigation = useNavigate();
  const carouselRef = useRef();

  React.useEffect(() => {
    
    if (carouselRef && carouselRef.current && selectedMediaId) {
      carouselRef.current.goToSlide(selectedMediaId);
    }
  }, [selectedMediaId, carouselRef]);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  function ChangeDate(date) {
    let newDate = new Date(date);
    var hours = newDate.getHours();
    var minutes = newDate.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    let dateToDisplay =
      newDate.getDate() +
      " " +
      monthNames[newDate.getMonth()] +
      " " +
      newDate.getFullYear() +
      " " +
      strTime;

    return dateToDisplay;
  }

  React.useEffect(() => {
    setDimension({
      width: myRef.current.offsetWidth,
      height: myRef.current.offsetHeight,
    });
  }, []);

  return (
    <Grid container className={classes.gridItem}>
      <Grid item xs={8} ref={myRef}>
        <div
          style={{ backgroundColor: "black", width: "100%", height: "100%" }}
        >
          <Carousel
            additionalTransfrom={0}
            className="react-multi-carousel-track"
            swipeable={false}
            draggable={false}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            deviceType="desktop"
            dotListClass="custom-dot-list-style"
            itemClass={classes.item}
          >
            {post.images &&
              post.images.map((img, index) => (
                <div
                  style={{
                    width: "99%",
                    height: "100%",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}
                >
                  <span
                    style={{
                      display: "inline-block",
                      height: "100%",
                      verticalAlign: "middle",
                    }}
                  ></span>{" "}
                  <img
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      verticalAlign: "middle",
                    }}
                    src={
                      process.env.REACT_APP_BACKEND_FILE_PREVIEW + img.file_name
                    }
                    alt={index}
                  />
                </div>
              ))}
            {post.videos &&
              post.videos.map((vid, index) => (
                <div className="media-postcontent">
                  <ReactPlayer
                    controls={true}
                    playing={vid.isPlaying}
                    onEnded={() => {
                      dispatch({
                        type: PLAY_VIDEO_BY_ID,
                        payload: {
                          postId: post.id,
                          videoId: vid.id,
                          media: {
                            file_name: vid.file_name,
                            file_path: vid.file_path,
                            id: vid.id,
                            isPlaying: false,
                            type: vid.type,
                          },
                          fromPreview: true,
                          is_event: post.is_event,
                        },
                      });
                    }}
                    onPause={() => {
                      dispatch({
                        type: PLAY_VIDEO_BY_ID,
                        payload: {
                          postId: post.id,
                          videoId: vid.id,
                          media: {
                            file_name: vid.file_name,
                            file_path: vid.file_path,
                            id: vid.id,
                            isPlaying: false,
                            type: vid.type,
                          },
                          fromPreview: true,
                          is_event: post.is_event,
                        },
                      });
                    }}
                    onPlay={() => {
                      dispatch({
                        type: PLAY_VIDEO_BY_ID,
                        payload: {
                          postId: post.id,
                          videoId: vid.id,
                          media: {
                            file_name: vid.file_name,
                            file_path: vid.file_path,
                            id: vid.id,
                            isPlaying: true,
                            type: vid.type,
                          },
                          fromPreview: true,
                          is_event: post.is_event,
                        },
                      });
                    }}
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                    url={
                      process.env.REACT_APP_BACKEND_FILE_PREVIEW + vid.file_name
                    }
                  />
                </div>
              ))}
            {post.video_links &&
              post.video_links.map((link, index) => (
                <div className="media-postcontent">
                  <ReactPlayer
                    config={{
                      youtube: {
                        playerVars: { origin: "https://www.youtube.com" },
                      },
                    }}
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                    url={link.file_path}
                    playing={link.isPlaying}
                    onEnded={() => {
                      dispatch({
                        type: PLAY_VIDEO_BY_ID,
                        payload: {
                          postId: post.id,
                          videoId: link.id,
                          media: {
                            file_name: link.file_name,
                            file_path: link.file_path,
                            id: link.id,
                            isPlaying: false,
                            type: link.type,
                          },
                          fromPreview: true,
                          is_event: post.is_event,
                        },
                      });
                    }}
                    onPause={() => {
                      dispatch({
                        type: PLAY_VIDEO_BY_ID,
                        payload: {
                          postId: post.id,
                          videoId: link.id,
                          media: {
                            file_name: link.file_name,
                            file_path: link.file_path,
                            id: link.id,
                            isPlaying: false,
                            type: link.type,
                          },
                          fromPreview: true,
                          is_event: post.is_event,
                        },
                      });
                    }}
                    onPlay={() => {
                      dispatch({
                        type: PLAY_VIDEO_BY_ID,
                        payload: {
                          postId: post.id,
                          videoId: link.id,
                          media: {
                            file_name: link.file_name,
                            file_path: link.file_path,
                            id: link.id,
                            isPlaying: true,
                            type: link.type,
                          },
                          fromPreview: true,
                          is_event: post.is_event,
                        },
                      });
                    }}
                  />
                </div>
              ))}
          </Carousel>
        </div>
      </Grid>
      <Grid className={classes.root} item xs={4}>
        <Card>
          <CardHeader
            avatar={
              <Avatar
                onClick={() => navigation(`/users/people/${post.user_id}`)}
                style={{ cursor: "pointer" }}
                aria-label="recipe"
                className={classes.avatar}
                alt={post.userName.charAt(0).toUpperCase()}
                src={
                  post.user_profile &&
                  process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(0, -1) +
                    post.user_profile
                }
              ></Avatar>
            }
            title={
              <div>
                <Typography
                  className={classes.title}
                  style={{ cursor: "pointer" }}
                  onClick={() => navigation(`/users/people/${post.user_id}`)}
                >
                  {post.userName}
                </Typography>
                <Typography>{post.organisationName}</Typography>
              </div>
            }
            action={
              <Deleteicon
                className={classes.closeButton}
                onClick={() => {
                  dispatch({
                    type: SHOW_POST_MEDIA_PREVIEW,
                    payload: {
                      id: post.id,
                      showPreview: false,
                      is_event: post.is_event,
                    },
                  });
                }}
              />
            }
            subheader={
              <>
                <Typography>{post.designation}</Typography>
                <Typography>
                  {post.postCreatedOn && ChangeDate(post.postCreatedOn)}
                </Typography>
                {id || post.postFrom == "channel" ? null : (
                  <div
                    style={{ display: "flex", cursor: "pointer" }}
                    onClick={() => {
                      if (post.group_id) {
                        dispatch(
                          EMGetRolesAndPermissionsBasedOnEntityAction({
                            entity: "group",
                            entityId: post.group_id,
                          })
                        );
                        navigation("/users/groups/activity", {
                          state: { GroupId: post.group_id },
                        });
                        window.sessionStorage.setItem("GroupId", post.group_id);
                      }
                    }}
                  >
                    <Avatar
                      className={classes.groupAvtar}
                      src={
                        post.groupLogo &&
                        process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(
                          0,
                          -1
                        ) + post.groupLogo
                      }
                    ></Avatar>
                    <Typography>
                      <Box fontWeight="fontWeightBold" m={1}>
                        {post.groupName}
                      </Box>
                    </Typography>
                  </div>
                )}
              </>
            }
          />
          <CardContent style={{ paddingTop: "0px" }}>
            <Typography>{Parse(post.postText)}</Typography>
          </CardContent>

          <CardActions>
            {post.liked ? (
              <>
                <ThumbUpAltIcon
                  color="primary"
                  className={classes.like}
                  onClick={post.likeButtonDisabled ? () => {} : likehandler}
                />
                <Typography>{post.likes} Likes</Typography>
              </>
            ) : (
              <>
                <ThumbUpAltIcon
                  color="action"
                  className={classes.like}
                  onClick={post.likeButtonDisabled ? () => {} : likehandler}
                />
                <Typography>{post.likes} Likes</Typography>
              </>
            )}
            <>
              {post.comments && post.comments.length > 0 ? (
                <>
                  <ChatBubbleIcon
                    color="primary"
                    className={classes.comment}
                    onClick={() => {
                      dispatch({
                        type: USER_SHOW_COMMENT,
                        payload: {
                          id: post.id,
                          content: !post.showComments,
                          is_event: post.is_event,
                        },
                      });
                    }}
                  />
                  <Typography
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      dispatch({
                        type: USER_SHOW_COMMENT,
                        payload: {
                          id: post.id,
                          content: !post.showComments,
                          is_event: post.is_event,
                        },
                      });
                    }}
                  >
                    {post.comments.length} Comments
                  </Typography>
                </>
              ) : (
                <>
                  <ChatBubbleIcon color="action" className={classes.comment} />
                  <Typography>
                    {post.comments && post.comments.length} Comments
                  </Typography>
                </>
              )}
            </>
          </CardActions>
          {post.showComments
            ? post.comments !== null &&
              post.comments.length > 0 &&
              post.comments.map((comment) => {
                return (
                  <Card
                    elevation={2}
                    style={{ width: "100%", borderBottom: "1px solid grey" }}
                  >
                    <CardHeader
                      avatar={
                        <div>
                          <Avatar
                            aria-label="recipe"
                            className={classes.avatar}
                            src={
                              process.env.REACT_APP_BACKEND_FILE_DOWNLOAD +
                              comment.profileIcon
                            }
                            alt={
                              comment.first_name &&
                              comment.first_name.charAt(0).toUpperCase()
                            }
                          />
                        </div>
                      }
                      title={
                        <div>
                          <Typography className={classes.title}>
                            {comment.first_name} {comment.last_name}
                          </Typography>
                          <Typography
                            style={{ fontSize: "small", marginTop: "0.5%" }}
                          >
                            {comment.organisationName}
                          </Typography>
                        </div>
                      }
                      action={
                        <>
                          <Typography>
                            <Box fontSize={10}>
                              {comment.commentedOn &&
                                ChangeDate(comment.commentedOn)}
                            </Box>
                            {comment.first_name + " " + comment.last_name ==
                              userData.name && !searched ? (
                              <IconButton
                                style={{ marginLeft: "10%" }}
                                onClick={() => {
                                  let data = {
                                    postId: post.id,
                                    commentId: comment.comment_id,
                                  };
                                  dispatch(
                                    EMDoDeleteCommentFromPostAction(data)
                                  );
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            ) : null}
                          </Typography>
                        </>
                      }
                      subheader={
                        <>
                          <Typography>{comment.designation}</Typography>
                        </>
                      }
                    />
                    <CardContent style={{ paddingTop: "0px" }}>
                      <Typography>{Parse(comment.comment)}</Typography>
                    </CardContent>
                  </Card>
                );
              })
            : null}
        </Card>
        {permission ? (
          <Paper elevation={2} className={classes.addComment}>
            <EMAddNewComment post={post} />
          </Paper>
        ) : null}
      </Grid>
    </Grid>
  );
}

function EmojiIcon(props) {
  return (
    <SvgIcon viewBox="0 0 29 29" {...props}>
      <path d="M15.987,3A13,13,0,1,0,29,16,12.994,12.994,0,0,0,15.987,3ZM16,26.4A10.4,10.4,0,1,1,26.4,16,10.4,10.4,0,0,1,16,26.4Zm4.55-11.7a1.95,1.95,0,1,0-1.95-1.95A1.947,1.947,0,0,0,20.55,14.7Zm-9.1,0A1.95,1.95,0,1,0,9.5,12.75,1.947,1.947,0,0,0,11.45,14.7ZM16,23.15a7.145,7.145,0,0,0,6.643-4.55H9.357A7.145,7.145,0,0,0,16,23.15Z" />
    </SvgIcon>
  );
}

function SendIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M3.011,22.008,26.5,13.254,3.011,4.5,3,11.309l16.786,1.945L3,15.2Z" />
    </SvgIcon>
  );
}

function LikeIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M1.5,13.65H3.93V6.36H1.5ZM14.865,6.967A1.219,1.219,0,0,0,13.65,5.752H9.817l.577-2.776.018-.194a.914.914,0,0,0-.267-.644L9.5,1.5l-4,4a1.188,1.188,0,0,0-.358.857v6.075A1.219,1.219,0,0,0,6.36,13.65h5.467a1.207,1.207,0,0,0,1.118-.741L14.78,8.626a1.2,1.2,0,0,0,.085-.443V7.022l-.006-.006Z" />
    </SvgIcon>
  );
}

const arrowStyles = {
  position: "absolute",
  zIndex: 2,
  top: "calc(50% - 15px)",
  width: 30,
  height: 30,
  cursor: "pointer",
  backgroundColor: "transparent",
};

function CommentIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M17,4.4A1.4,1.4,0,0,0,15.609,3H4.4A1.405,1.405,0,0,0,3,4.4v8.406a1.405,1.405,0,0,0,1.4,1.4h9.807l2.8,2.8Z" />
    </SvgIcon>
  );
}
function PinIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M23.541,23.763l-9.431-5.9a2.321,2.321,0,0,0-3.306.958,2.745,2.745,0,0,0,.886,3.581l9.429,5.9a2.32,2.32,0,0,0,3.306-.959,2.739,2.739,0,0,0-.885-3.58Zm-7.778-6.378L23.1,21.973l2.495-7.521-5.065-3.167ZM10.356,36.706l7.017-9.233-3.144-1.966-3.873,11.2ZM28.625,10.292,22.339,6.36a1.74,1.74,0,0,0-2.479.72,2.057,2.057,0,0,0,.664,2.686L26.811,13.7a1.74,1.74,0,0,0,2.479-.72,2.057,2.057,0,0,0-.665-2.686Z"
        transform="translate(-10.356 -6.096)"
      />
    </SvgIcon>
  );
}

function NextIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M15,9l-2.115,2.115L19.755,18l-6.87,6.885L15,27l9-9Z"
        transform="translate(-10.356 -6.096)"
      />
    </SvgIcon>
  );
}

const defaultStyle = {
  control: {
    // backgroundColor: "white",

    fontSize: 12,
    fontWeight: "normal",
    border: "none",
    height: "20px",
    outline: "0",
  },

  highlighter: {
    overflow: "hidden",
  },

  input: {
    margin: 0,
  },

  "&singleLine": {
    control: {
      display: "inline-block",

      width: 130,
    },

    highlighter: {
      padding: 1,
    },

    input: {
      padding: 1,
    },
  },

  "&multiLine": {
    control: {
      fontFamily: "Montserrat",
    },

    highlighter: {
      padding: 9,
    },

    input: {
      padding: 4,
      minHeight: 25,
      outline: 0,
      border: 0,
    },
  },

  suggestions: {
    list: {
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 15,
    },

    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",

      "&focused": {
        backgroundColor: "#cee4e5",
      },
    },
  },
};
