import { Paper, Box, Button, Modal, CircularProgress, Typography, Grid, Hidden ,makeStyles} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import EMPage from "../../../components/commans/EMPage";
import EMDatatableSearchView from "../../../components/datatables/EMDatatableSearchView";
import EMUserTable from "./EMUserTable";
import EMAddEditAdmin from "./EMAddEditAdmin";
import { useSelector } from "react-redux";
import { find } from "lodash";
import EMResendInvite from "./ResendInvites";
const useStyles = makeStyles(theme => ({
  button: {
    margin: 10,
    minWidth: 200,
    height: 40
  }
}));
export default function PlatformAdminManagement(props) {
  const classes = useStyles();
  const [filter, setFilter] = useState("");
  const [open, setOpen] = React.useState(false);
  const [editData, setEditData] = React.useState({});
  const [col, setCol] = React.useState(true);
  const [showLabels ,setShowLabels ]=React.useState({label : "",buttonLabel : "" })
  const [shouldButtonDisabled , setShouldButtonDisabled] = React.useState(false)
  const [resendInviteActive, setResendInviteActive] = React.useState(false);
  const uiData = useSelector((state) => state.ui);
  
  /* open and close modal functionality */
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditData({});
  };
  /* close PopUp after successful user add / edit Operation */
  useEffect(() => {
    if (uiData["messages"]) {
      let refObj = find(uiData["messages"], { key: "user_add_edit" });
      if (refObj && refObj.type === "success") {
        setOpen(false);
        setShouldButtonDisabled(false)
      }else if (refObj && refObj.type === "error") {
        setShouldButtonDisabled(false)
      }
    }
  }, [uiData]);

  window.addEventListener("resize", () => {
    let width = document.body.clientWidth;
    if (width <= 600) { //Close Drawer when screen size medium
      setCol(false)
    } else {
      setCol(true)
    }
  });

  return (
    <EMPage title="Platform Admin Management" style={{padding:"2%"}}>
      <Box >
      <Modal style={{display:"flex", alignItems:"center", justifyContent:"center"}} open={uiData.loading.state}>
          <Box style={{display:"flex"}} alignItems="center" justifyContent="center">
            <Box textAlign="center">
          <CircularProgress />
          <Typography style={{fontWeight:"medium", fontSize:"large"}}>{uiData.loading.message}</Typography>
          </Box>
          </Box>
          </Modal>
          <Box>
            <EMResendInvite 
            open={resendInviteActive}
            close={() => {
              setResendInviteActive(false);
            }}
            closeHandler={setResendInviteActive}/>
          </Box>
        <Paper elevation={2}>
        <Hidden smDown>
          <Grid style={{paddingTop:20, paddingLeft:20, paddingRight:20}} container direction = "row" alignItems="center" justifyContent="center">
            <Grid item md={8}>
            <EMDatatableSearchView
                  hint={"Search by First Name, Last Name, E-mail Address"}
                  onChangeListener={(data) => {
                    
                    setFilter(data);
                  }}
                />
            </Grid>
            <Grid item md={2} >
            <Button 
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setResendInviteActive(true);
                    }}
                  >
                    {"Resend Invites"}
                  </Button>
            </Grid>
            <Grid item md={2}>
            <Button
                  variant="contained"
                  color="primary"
                  onClick={()=>{
                    handleOpen()
                    setShouldButtonDisabled(false)
                    setEditData({})
                    setShowLabels({
                      label : "Add A New Platform Admin",
                      buttonLabel : "Invite"
                    })}}>
                  {"Add new platform Admin"}
                </Button>
            </Grid>
            <Box>
                <EMAddEditAdmin
                  open={open}
                  close={handleClose}
                  userData={editData}
                  pagelabel = {showLabels.label}
                  buttonLabel = {showLabels.buttonLabel}
                  shouldButtonDisabled = {shouldButtonDisabled}
                  setShouldButtonDisabled = {setShouldButtonDisabled}/>
              </Box>
          </Grid>
          <Box height="100%" display="flex" flexDirection="column">
            <EMUserTable
              filter={filter}
              onEdit={(roleData) => {
                setEditData(roleData);
                setOpen(true);
                setShowLabels({
                  label : "Edit Admin Details",
                  buttonLabel : "Save"
                })
              }}/>
          </Box>
           </Hidden>
           <Hidden lgUp mdUp>
          <Box height="100%" display="flex" flexDirection="column">
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              p={3}
              justifyItems="center"
              alignItems="center">
              <Box flexGrow={1}>
                <EMDatatableSearchView
                  hint={"Search by First Name, Last Name, E-mail Address"}
                  onChangeListener={(data) => {
                    
                    setFilter(data);
                  }}
                />
              </Box>
              </Box>
              <Box height="100%" display="flex" flexDirection="column">
              <Box>
                <Button
                                  className={classes.button}

                  variant="contained"
                  style={{marginLeft: "50px",textAlign:"center"}}
                  color="primary"
                  onClick={()=>{
                    handleOpen()
                    setShouldButtonDisabled(false)
                    setEditData({})
                    setShowLabels({
                      label : "Add A New Platform Admin",
                      buttonLabel : "Invite"
                    })}}>
                  {"Add new platform Admin"}
                </Button>
                </Box>
                <Box>
                <Button
                                  className={classes.button}

                style={{marginLeft: "50px"}}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setResendInviteActive(true);
                    }}
                  >
                    {"Resend Invites"}
                  </Button>
              </Box>
              </Box>
              <Box>
                <EMAddEditAdmin
                  open={open}
                  close={handleClose}
                  userData={editData}
                  pagelabel = {showLabels.label}
                  buttonLabel = {showLabels.buttonLabel}
                  shouldButtonDisabled = {shouldButtonDisabled}
                  setShouldButtonDisabled = {setShouldButtonDisabled}/>
              </Box>
           
            <EMUserTable
              filter={filter}
              onEdit={(roleData) => {
                setEditData(roleData);
                setOpen(true);
                setShowLabels({
                  label : "Edit Admin Details",
                  buttonLabel : "Save"
                })
              }}/>
          </Box>
          </Hidden>
        </Paper>
      </Box>
    </EMPage>
  );
}
