import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    //margin: theme.spacing(1),
    width: "100%",
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  label: {
    "&:focus": {
      color: theme.palette.common.black,
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null
};

export default function EMMultipleSelect({
  label,
  data,
  objKey,
  prevData,
  onChange,
  formik,
  objId,
}) {
  const classes = useStyles();
  const [selectedData, setSelectedData] = React.useState([]);

  React.useEffect(() => { 
    if(prevData){
      setSelectedData(prevData);
    }
  }, []);

  const handleChange = (event) => {
    setSelectedData(event.target.value);
    onChange(event.target.value);
  };


  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-mutiple-checkbox-label" className={classes.label} style={{color:"#4d4d4d"}}>
          {label}
        </InputLabel>
        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          value={selectedData || []}
          onChange={handleChange}
          input={<Input />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
          // error={formik.errors && formik.touched ? true : false}
          fullWidth
        >
          {data.map((d, index) => {
            let dataToShow = objId ? d[objKey]+" |"+d[objId] :d[objKey] ;
            return (
              <MenuItem disabled={prevData && prevData.includes(dataToShow)} key={dataToShow} value={dataToShow}>
                <Checkbox
                  checked={
                   selectedData && selectedData.indexOf(dataToShow) > -1
                  }
                />
                <Tooltip
                  title={<h2 style={{ color: "white" }}>{dataToShow}</h2>}
                  placement="top"
                >
                  <ListItemText
                    style={{
                      display: "-webkit-box",
                      boxOrient: "vertical",
                      lineClamp: 1,
                      wordBreak: "break-word",
                      overflow: "hidden",
                    }}
                    primary={
                     dataToShow
                    }
                  />
                </Tooltip>
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText style={{ color: "red" }}>
          {formik.errors && formik.touched ? formik.errors : ""}
        </FormHelperText>
      </FormControl>
    </div>
  );
}
