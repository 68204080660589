import {
  PLATFORM_ADMIN_LIST_SUCCESS,
  PLATFORM_ADMIN_UPDATE_SUCCESS,
  PLATFORM_ADMIN_CREATE_SUCCESS,
  PLATFORM_ADMIN_ORGANIZATION_LIST_SUCCESS,
  PLATFORM_ADMIN_ORGANIZATION_UPDATE_SUCCESS,
  PLATFORM_ADMIN_ORGANIZATION_CREATE_SUCCESS,
  PLATFORM_ADMIN_PARTNERSHIP_LIST_SUCCESS,
  PLATFORM_ADMIN_PARTNERSHIP_UPDATE_SUCCESS,
  PLATFORM_ADMIN_PARTNERSHIP_CREATE_SUCCESS,
  ORGANIZATIONS_EXPORT_SUCCESS,
  UI_LOADING,
  SHOW_MESSAGE,
  PLATFORM_ADMIN_NETWORKS_LIST_DASHBOARD,
  PLATFORM_ADMIN_GET_DASHBOARD_DATA,
  PLATFORM_ADMIN_GET_ONBOARDING_DATA,
  PLATFORM_ADMIN_GET_USAGE_REPORT_DATA,
  PLATFORM_ADMIN_GET_USER_ENGAGEMENT_DATA,
  PLATFORM_ADMIN_GET_NETWORK_STATS_DATA,
  PLATFORM_ADMIN_GET_STATIC_DASHBOARD_DATA,
  PLATFORM_ADMIN_RESEND_USERS_LIST_SUCCESS,
  PLATFORM_ADMIN_RESEND_INVITE_SUCCESS,
  PLATFORM_ADMIN_GET_ORGANIZATION_SUB_TYPES,
  PLATFORM_ADMIN_GET_ADMIN_LIST_BASED_ON_ORGANIZATIONS,
  PLATFORM_ADMIN_GET_ADMIN_LIST_FOR_PARTNERSHIP,
} from "../constants";
import API from "../../api";
import { handleError } from "../../utils/EMCommonFunctions";
export const EMDOGetPlatformAdminAction = (action) => {
  return (dispatch) => {
    dispatch({
      type: UI_LOADING,
      payload: { state: true, message: "Please wait" },
    });
    API.EMDoGetPlatformAdminList(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: PLATFORM_ADMIN_LIST_SUCCESS,
            payload: response,
          });
          dispatch({
            type: UI_LOADING,
            payload: { state: false, message: "" },
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching admin list",
            },
          });
          dispatch({
            type: UI_LOADING,
            payload: { state: false, message: "" },
          });
        }
      })
      .catch((err) => {
        dispatch({ type: UI_LOADING, payload: { state: false, message: "" } });
        handleError(dispatch, err);
      });
  };
};

export const EMDoUpdatePlatformAdminAction = (action) => {
  return (dispatch) => {
    dispatch({
      type: UI_LOADING,
      payload: { state: true, message: "Please wait" },
    });
    API.EMDoUpdatePlatformAdmin(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: PLATFORM_ADMIN_UPDATE_SUCCESS,
            payload: response,
          });
          dispatch({
            type: UI_LOADING,
            payload: { state: false, message: "" },
          });
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "User details updated successfully",
              key: action.key,
            },
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to update the user details",
            },
          });
          dispatch({
            type: UI_LOADING,
            payload: { state: false, message: "" },
          });
        }
      })
      .catch((err) => {
        dispatch({ type: UI_LOADING, payload: { state: false, message: "" } });
        handleError(dispatch, err);
      });
  };
};

export const EMDoCreatePlatformAdminAction = (action) => {
  return (dispatch) => {
    dispatch({
      type: UI_LOADING,
      payload: { state: true, message: "Please wait" },
    });
    let userData = { ...action };
    delete userData.key;
    API.EMDoCreatePlatformAdmin(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: PLATFORM_ADMIN_CREATE_SUCCESS,
            payload: response,
          });
          dispatch({
            type: UI_LOADING,
            payload: { state: false, message: "" },
          });
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "User invited successfully",
              key: action.key,
            },
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to create the user",
            },
          });
          dispatch({
            type: UI_LOADING,
            payload: { state: false, message: "" },
          });
        }
      })
      .catch((err) => {
        dispatch({ type: UI_LOADING, payload: { state: false, message: "" } });
        handleError(dispatch, err);
      });
  };
};

export const EMDoGetOrganizationList = (action) => {
  return (dispatch) => {
    dispatch({
      type: UI_LOADING,
      payload: { state: true, message: "Please wait" },
    });
    API.EMDoGetOrganizationList(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: PLATFORM_ADMIN_ORGANIZATION_LIST_SUCCESS,
            payload: response,
          });
          dispatch({
            type: UI_LOADING,
            payload: { state: false, message: "" },
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching organization list",
            },
          });
          dispatch({
            type: UI_LOADING,
            payload: { state: false, message: "" },
          });
        }
      })
      .catch((err) => {
        dispatch({ type: UI_LOADING, payload: { state: false, message: "" } });
        handleError(dispatch, err);
      });
  };
};

export const EMDoUpdateOrganizationAction = (action) => {
  return (dispatch) => {
    dispatch({
      type: UI_LOADING,
      payload: { state: true, message: "Please wait" },
    });
    API.EMDoUpdateOrganization(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: PLATFORM_ADMIN_ORGANIZATION_UPDATE_SUCCESS,
            payload: response,
          });
          dispatch({
            type: UI_LOADING,
            payload: { state: false, message: "" },
          });
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Organization updated successfully",
              key: action.key,
            },
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to update the organization",
              key: action.key,
            },
          });
          dispatch({
            type: UI_LOADING,
            payload: { state: false, message: "" },
          });
        }
      })
      .catch((err) => {
        dispatch({ type: UI_LOADING, payload: { state: false, message: "" } });
        handleError(dispatch, err, action.key);
      });
  };
};

export const EMDoCreateOrganizationAction = (action) => {
  return (dispatch) => {
    dispatch({
      type: UI_LOADING,
      payload: { state: true, message: "Please wait" },
    });
    let userData = { ...action };
    delete userData.key;
    API.EMDoCreateOrganization(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: PLATFORM_ADMIN_ORGANIZATION_CREATE_SUCCESS,
            payload: response,
          });
          dispatch({
            type: UI_LOADING,
            payload: { state: false, message: "" },
          });
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Organization Created successfully",
              key: action.key,
            },
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to create the organization",
              key: action.key,
            },
          });
          dispatch({
            type: UI_LOADING,
            payload: { state: false, message: "" },
          });
        }
      })
      .catch((err) => {
        dispatch({ type: UI_LOADING, payload: { state: false, message: "" } });
        handleError(dispatch, err, action.key);
      });
  };
};

//Partnership related actions
export const EMDoGetPartnershipList = (action) => {
  return (dispatch) => {
    dispatch({
      type: UI_LOADING,
      payload: { state: true, message: "Please wait" },
    });
    API.EMDoGetPartnershipList(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: PLATFORM_ADMIN_PARTNERSHIP_LIST_SUCCESS,
            payload: response,
          });
          dispatch({
            type: UI_LOADING,
            payload: { state: false, message: "" },
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching partnership list",
            },
          });
          dispatch({
            type: UI_LOADING,
            payload: { state: false, message: "" },
          });
        }
      })
      .catch((err) => {
        dispatch({ type: UI_LOADING, payload: { state: false, message: "" } });
        handleError(dispatch, err);
      });
  };
};

export const EMDoUpdatePartnershipAction = (action) => {
  return (dispatch) => {
    dispatch({
      type: UI_LOADING,
      payload: { state: true, message: "Please wait" },
    });
    API.EMDoUpdatePartnership(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: PLATFORM_ADMIN_PARTNERSHIP_UPDATE_SUCCESS,
            payload: response,
          });
          dispatch({
            type: UI_LOADING,
            payload: { state: false, message: "" },
          });
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Partnership updated successfully",
              key: action.key,
            },
          });
          dispatch({
            type: PLATFORM_ADMIN_GET_ADMIN_LIST_BASED_ON_ORGANIZATIONS,
            payload: [],
          });
          dispatch({
            type: PLATFORM_ADMIN_GET_ADMIN_LIST_FOR_PARTNERSHIP,
            payload: [],
          })
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to update the partnership",
              key: action.key,
            },
          });
          dispatch({
            type: UI_LOADING,
            payload: { state: false, message: "" },
          });
        }
      })
      .catch((err) => {
        dispatch({ type: UI_LOADING, payload: { state: false, message: "" } });
        handleError(dispatch, err, action.key);
      });
  };
};

export const EMDoCreatePartnershipAction = (action) => {
  return (dispatch) => {
    dispatch({
      type: UI_LOADING,
      payload: { state: true, message: "Please wait" },
    });
    let userData = { ...action };
    delete userData.key;
    API.EMDoCreatePartnership(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: PLATFORM_ADMIN_PARTNERSHIP_CREATE_SUCCESS,
            payload: response,
          });
          dispatch({
            type: UI_LOADING,
            payload: { state: false, message: "" },
          });
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Partnership added successfully",
              key: action.key,
            },
          });
          dispatch({
            type: PLATFORM_ADMIN_GET_ADMIN_LIST_BASED_ON_ORGANIZATIONS,
            payload: [],
          });
          dispatch({
            type: PLATFORM_ADMIN_GET_ADMIN_LIST_FOR_PARTNERSHIP,
            payload: [],
          })
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to create the partnership",
              key: action.key,
            },
          });
          dispatch({
            type: UI_LOADING,
            payload: { state: false, message: "" },
          });
        }
      })
      .catch((err) => {
        dispatch({ type: UI_LOADING, payload: { state: false, message: "" } });
        handleError(dispatch, err, action.key);
      });
  };
};

export const EMDoExportOrganizationsAction = (action) => {
  return (dispatch) => {
    dispatch({
      type: UI_LOADING,
      payload: { state: true, message: "Please wait" },
    });
    let userData = { ...action };
    delete userData.key;
    API.EMDoExportOrganizations(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "ExportedOrganizationDetails.csv");
          document.body.appendChild(link);
          link.click();
          link.remove();
          dispatch({
            type: ORGANIZATIONS_EXPORT_SUCCESS,
            payload: response,
          });
          dispatch({
            type: UI_LOADING,
            payload: { state: false, message: "" },
          });
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Organizations exported successfully",
              key: action.key,
            },
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to export organizations",
            },
          });
          dispatch({
            type: UI_LOADING,
            payload: { state: false, message: "" },
          });
        }
      })
      .catch((err) => {
        dispatch({ type: UI_LOADING, payload: { state: false, message: "" } });
        if (err.response.data.detail) {
          handleError(dispatch, err);
        }
      });
  };
};

export const EMDoGetNetworkListForDashboardAndReportsAction = (action) => {
  return (dispatch) => {
    API.EMDoGetNetworkListForDashboardAndReports(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: PLATFORM_ADMIN_NETWORKS_LIST_DASHBOARD,
            payload: response,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching organization list",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err);
      });
  };
};

export const EMDoGetDashboardInfo = (action) => {
  return (dispatch) => {
    API.EMDoGetDashboardInfo(action.requiredData)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: PLATFORM_ADMIN_GET_DASHBOARD_DATA,
            payload: response,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching dashboard data",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err);
      });
  };
};

export const EMDoGetOnboardingReportAction = (action) => {
  return (dispatch) => {
    API.EMDoGetOnboardingReport(action.requiredData)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: PLATFORM_ADMIN_GET_ONBOARDING_DATA,
            payload: response,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching dashboard data",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err);
      });
  };
};

export const EMDoGetUsageReportAction = (action) => {
  return (dispatch) => {
    API.EMDoGetUsageReport(action.requiredData)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: PLATFORM_ADMIN_GET_USAGE_REPORT_DATA,
            payload: response,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching dashboard data",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err);
      });
  };
};

export const EMDoGetUserEngagementReportAction = (action) => {
  return (dispatch) => {
    API.EMDoGetUserEngagementReport(action.requiredData, action.paginationData)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: PLATFORM_ADMIN_GET_USER_ENGAGEMENT_DATA,
            payload: response,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching dashboard data",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err);
      });
  };
};

export const EMDoGetNetworkStatsReportAction = (action) => {
  return (dispatch) => {
    API.EMDoGetNetworkStatsReport(action.requiredData, action.paginationData)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: PLATFORM_ADMIN_GET_NETWORK_STATS_DATA,
            payload: response,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching dashboard data",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err);
      });
  };
};

export const EMDoGetNetworkOrgGrpChnAction = (action) => {
  return (dispatch) => {
    API.EMDoGetNetworkOrgGrpChn(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: PLATFORM_ADMIN_GET_STATIC_DASHBOARD_DATA,
            payload: response,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching dashboard data",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err);
      });
  };
};

export const EMDoExportOnboardingReportAction = (action) => {
  return (dispatch) => {
    API.EMDoExportOnboardingReport(action.requiredData)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "OnboardingReport.csv");
          document.body.appendChild(link);
          link.click();
          link.remove();
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Onboarding report exported successfully",
              key: action.key,
            },
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to export onboarding report",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err);
      });
  };
};

export const EMDoExportNetworkStatsReportAction = (action) => {
  return (dispatch) => {
    API.EMDoExportNetworkStatsReport(action.requiredData)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "NetworkStatsReport.csv");
          document.body.appendChild(link);
          link.click();
          link.remove();
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Network stats report exported successfully",
              key: action.key,
            },
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to export network stats report",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err);
      });
  };
};

export const EMExportUsageReportAction = (action) => {
  return (dispatch) => {
    API.EMExportUsageReport(action.requiredData)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "UsageReport.csv");
          document.body.appendChild(link);
          link.click();
          link.remove();
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Usage report exported successfully",
              key: action.key,
            },
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to export usage report",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err);
      });
  };
};

export const EMDoExportUserEngagementReportAction = (action) => {
  return (dispatch) => {
    API.EMDoExportUserEngagementReport(
      action.requiredData,
      action.groupOrChannel
    )
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "UserEngagementReport.csv");
          document.body.appendChild(link);
          link.click();
          link.remove();
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "User engagement report exported successfully",
              key: action.key,
            },
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to export User engagement report",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err);
      });
  };
};

export const EMDoGetPlatformAdminResendInviteUsersListAction = (action) => {
  return (dispatch) => {
    API.EMDoGetPlatformAdminResendInviteUsersList(action)
      .then((data) => data.data)
      .then((data) => {
        if (data) {
          dispatch({
            type: PLATFORM_ADMIN_RESEND_USERS_LIST_SUCCESS,
            payload: data,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching users list",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err);
      });
  };
};

export const EMDoResendInviteToAdminAction = (action) => {
  return (dispatch) => {
    dispatch({
      type: UI_LOADING,
      payload: { state: true, message: "Please wait" },
    });
    let userData = { ...action };
    delete userData.key;
    API.EMDoResendInviteToAdmins(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: PLATFORM_ADMIN_RESEND_INVITE_SUCCESS,
            payload: response,
          });
          dispatch({
            type: UI_LOADING,
            payload: { state: false, message: "" },
          });
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Invitations sent successfully",
              key: action.key,
            },
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Unable to resend invite",
            },
          });
          dispatch({
            type: UI_LOADING,
            payload: { state: false, message: "" },
          });
        }
      })
      .catch((err) => {
        dispatch({ type: UI_LOADING, payload: { state: false, message: "" } });
        handleError(dispatch, err);
      });
  };
};

export const EMDoGetOrganizationSubTypesAction = (action) => {
  return (dispatch) => {
    API.EMDoGetOrganizationSubTypes(action.orgType)
      .then((data) => data.data)
      .then((data) => {
        if (data) {
          dispatch({
            type: PLATFORM_ADMIN_GET_ORGANIZATION_SUB_TYPES,
            payload: data,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching sub types list",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err);
      });
  };
};

export const EMDoGetOrganizationAdminsAction = (action) => {
  return (dispatch) => {
    API.EMDoGetOrganizationAdmins(action)
      .then((data) => data.data)
      .then((data) => {
        if (data) {
          dispatch({
            type: PLATFORM_ADMIN_GET_ADMIN_LIST_BASED_ON_ORGANIZATIONS,
            payload: data,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching admins",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err);
      });
  };
};

export const EMDoGetPartnershipAdminsAction = (action) => {
  return (dispatch) => {
    API.EMDoGetPartnershipAdmins(action)
      .then((data) => data.data)
      .then((data) => {
        if (data) {
          dispatch({
            type: PLATFORM_ADMIN_GET_ADMIN_LIST_FOR_PARTNERSHIP,
            payload: data,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching admins",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err);
      });
  };
};
