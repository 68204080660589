import React, { useEffect, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  Box,
  Paper,
  Grid,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  IconButton,
  DialogActions,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { ReactComponent as VideoIcon } from "../../assets/Icon-awesome-video.svg";
import { ReactComponent as ImageIcon } from "../../assets/Icon-metro-image.svg";
import { ReactComponent as EventIcon } from "../../assets/Icon-material-event-note.svg";
import { CloseRounded, Help, Poll } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";

import { SHOW_CREATE_POST } from "../../redux/constants/UserPlatform/EMGroupConstant";
import { TO_GET_THE_POLL_FORM } from "../../redux/constants/UserPlatform/index";
import {
  EMDoGetEventSharedWithGroupsListAction,
  EMDoGetPollSharedWithGroupsListAction,
  EMDoGetPostSharedWithGroupsListAction,
  EMDoGetQuizSharedWithGroupsListAction,
} from "../../redux/actions/UserPlatform/Home/EMLatestPostPageAction";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 30,
    paddingBottom: 5,
  },
  typing: {
    padding: "1%",
    height: "50px",
    cursor: "pointer",
  },
  button: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    backgroundColor: "white",
  },
  buttonGrid: {
    marginTop: theme.spacing(1),
    // marginLeft:"-4%",
    // maxWidth: "90%",
  },
}));

export default function EMStartTyping(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.auth || {});
  const groupData = useSelector((state) => state.groups || {});
  const channelData = useSelector((state) => state.channels || {});
  const entityBasedPermissions = useSelector(
    (state) => state.auth.rolesAndPermissionsForSpecificEntity
  );
  const [selectOption, setSelectOption] = useState(false);
  const [createPostInGroup, setCreatePostInGroup] = useState(false);
  const [createPollInGroup, setCreatePollInGroup] = useState(false);
  const [createEventInGroup, setCreateEventInGroup] = useState(false);
  const [createQuizInGroup, setCreateQuizInGroup] = useState(false);
  const [createPostInChannel, setCreatePostInChannel] = useState(false);
  const [createPollInChannel, setCreatePollInChannel] = useState(false);
  const [createQuizInChannel, setCreateQuizInChannel] = useState(false);
  const theme = useTheme();

  const onOpen = () => {
    setSelectOption(true);
  };

  const onClose = () => {
    setSelectOption(false);
  };

  useEffect(() => {
    setCreateEventInGroup(false);
    setCreatePollInChannel(false);
    setCreatePollInGroup(false);
    setCreatePostInChannel(false);
    setCreatePostInGroup(false);
    setCreateQuizInChannel(false);
    setCreateQuizInGroup(false);
    let usedIn = props && props.usedIn;
    if (usedIn === "channels") {
      if (entityBasedPermissions) {
        entityBasedPermissions.length > 0 &&
          entityBasedPermissions.map((perm) => {
            if (perm.permission_name == "create_polls_in_channel") {
              setCreatePollInChannel(perm.add_flag);
            } else if (perm.permission_name == "create_posts_in_channel") {
              setCreatePostInChannel(perm.add_flag);
            } else if (perm.permission_name == "create_quiz_in_channel") {
              setCreateQuizInChannel(perm.add_flag);
            }
          });
      }
    } else if (usedIn == "groups") {
      if (entityBasedPermissions) {
        entityBasedPermissions.length > 0 &&
          entityBasedPermissions.map((perm) => {
            if (perm.permission_name == "create_posts_in_group") {
              setCreatePostInGroup(perm.add_flag);
            } else if (perm.permission_name == "create_events_in_group") {
              setCreateEventInGroup(perm.add_flag);
            } else if (perm.permission_name == "create_polls_in_group") {
              setCreatePollInGroup(perm.add_flag);
            } else if (perm.permission_name == "create_quiz_in_group") {
              setCreateQuizInGroup(perm.add_flag);
            }
          });
      }
    }
  }, [props, data, channelData, groupData, entityBasedPermissions]);

  const matchesSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  function StartTypingRenderer() {
    if (createPostInChannel || createPostInGroup) {
      if (
        window.location.href.includes("channels/activity") &&
        createPostInChannel
      ) {
        return (
          <Paper
            onClick={() => {
              dispatch(EMDoGetPostSharedWithGroupsListAction());
              dispatch({
                type: SHOW_CREATE_POST,
                payload: {
                  value: true,
                  route: "create_post",
                },
              });
            }}
            elevation={2}
            className={classes.typing}
          >
            <Typography
              color="textSecondary"
              style={{ fontSize: "small", fontWeight: "bolder", padding: 10 }}
            >
              Start Typing
            </Typography>
          </Paper>
        );
      } else if (
        window.location.href.includes("groups/activity") &&
        createPostInGroup
      ) {
        return (
          <Paper
            onMouseEnter={() => {
              dispatch(EMDoGetPostSharedWithGroupsListAction());
            }}
            onClick={() => {
              dispatch({
                type: SHOW_CREATE_POST,
                payload: {
                  value: true,
                  route: "create_post",
                },
              });
            }}
            elevation={2}
            className={classes.typing}
          >
            <Typography
              color="textSecondary"
              style={{ fontSize: "small", fontWeight: "bolder", padding: 10 }}
            >
              Start Typing
            </Typography>
          </Paper>
        );
      }
    } else {
      return null;
    }
  }

  return createPostInGroup ||
    createPollInGroup ||
    createEventInGroup ||
    createQuizInGroup ||
    createPostInChannel ||
    createPollInChannel ||
    createQuizInChannel ? (
    <Paper elevation={2} className={classes.root}>
      <StartTypingRenderer />
      {props.usedIn === "channels" ? (
        <Grid
          container
          justify="space-between"
          alignItems="flex-start"
          spacing={2}
          className={classes.buttonGrid}
        >
          {matchesSmallScreen ? (
            <Grid item align="center">
              <Button
                variant="contained"
                className={classes.button}
                disabled={!createPostInChannel}
                onClick={() => {
                  dispatch(EMDoGetPostSharedWithGroupsListAction());
                  dispatch({
                    type: SHOW_CREATE_POST,
                    payload: {
                      value: true,
                      route: "create_post",
                    },
                  });
                }}
                startIcon={<ImageIcon />}
              >
                post
              </Button>
            </Grid>
          ) : null}
          {!matchesSmallScreen ? (
            <>
              <Grid item align="center">
                <Button
                  variant="contained"
                  className={classes.button}
                  disabled={!createPostInChannel}
                  onClick={() => {
                    dispatch(EMDoGetPostSharedWithGroupsListAction());
                    dispatch({
                      type: SHOW_CREATE_POST,
                      payload: {
                        value: true,
                        route: "create_post",
                      },
                    });
                  }}
                  startIcon={<ImageIcon />}
                >
                  Image
                </Button>
              </Grid>
              <Grid item align="center">
                <Button
                  variant="contained"
                  disabled={!createPostInChannel}
                  className={classes.button}
                  onClick={() => {
                    dispatch(EMDoGetPostSharedWithGroupsListAction());
                    dispatch({
                      type: SHOW_CREATE_POST,
                      payload: {
                        value: true,
                        route: "create_post",
                      },
                    });
                  }}
                  startIcon={<VideoIcon />}
                >
                  video
                </Button>
              </Grid>

              <Grid item align="center">
                <Button
                  variant="contained"
                  className={classes.button}
                  disabled={!createPostInChannel}
                  onClick={() => {
                    dispatch(EMDoGetPostSharedWithGroupsListAction());
                    dispatch({
                      type: SHOW_CREATE_POST,
                      payload: {
                        value: true,
                        route: "create_post",
                      },
                    });
                  }}
                  startIcon={<CloudUploadIcon />}
                >
                  Document
                </Button>
              </Grid>
            </>
          ) : null}

          {/* </>
          ) : null} */}
          <Grid item align="center">
            <Dialog open={selectOption} onClose={onClose} fullWidth={true}>
              <DialogTitle>
                {"Select the Question Type"}
                <IconButton
                  aria-label="close"
                  onClick={onClose}
                  style={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                  }}
                >
                  <CloseRounded />
                </IconButton>
              </DialogTitle>
              <DialogActions>
                <Grid
                  item
                  xs={6}
                  style={{ marginBottom: 15, marginLeft: "12%" }}
                >
                  <Button
                    variant="contained"
                    className={classes.button}
                    style={{ width: window.screen.width / 10.5 }}
                    onClick={() => {
                      onClose();
                      dispatch({
                        type: SHOW_CREATE_POST,
                        payload: {
                          value: true,
                          route: "create_poll",
                        },
                      });
                      dispatch({
                        type: TO_GET_THE_POLL_FORM,
                        payload: true,
                      });
                    }}
                  >
                    Poll
                  </Button>
                </Grid>
                <Grid item xs={6} style={{ marginBottom: 15 }}>
                  <Button
                    variant="contained"
                    className={classes.button}
                    onClick={() => {
                      onClose();
                      dispatch({
                        type: SHOW_CREATE_POST,
                        payload: {
                          value: true,
                          route: "create_poll",
                        },
                      });
                      dispatch({
                        type: TO_GET_THE_POLL_FORM,
                        payload: false,
                      });
                    }}
                    autoFocus
                  >
                    Open Ended
                  </Button>
                </Grid>
              </DialogActions>
            </Dialog>

            <Button
              variant="contained"
              disabled={!createPollInChannel}
              className={classes.button}
              onClick={() => {
                dispatch(EMDoGetPollSharedWithGroupsListAction());
                onOpen();
              }}
              startIcon={<Poll />}
            >
              poll
            </Button>
          </Grid>
          <Grid item align="center">
            <Button
              variant="contained"
              className={classes.button}
              disabled={!createQuizInChannel}
              onClick={() => {
                dispatch(EMDoGetQuizSharedWithGroupsListAction());
                dispatch({
                  type: SHOW_CREATE_POST,
                  payload: {
                    value: true,
                    route: "create_quiz",
                  },
                });
              }}
              startIcon={<Help style={{ color: "purple" }} />}
            >
              quiz
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          justify="space-between"
          alignItems="flex-start"
          spacing={2}
          className={classes.buttonGrid}
        >
          {!matchesSmallScreen ? (
            <>
              <Grid item align="center">
                <Button
                  variant="contained"
                  disabled={!createPostInGroup}
                  className={classes.button}
                  onMouseEnter={() => {
                    dispatch(EMDoGetPostSharedWithGroupsListAction());
                  }}
                  onClick={() => {
                    dispatch({
                      type: SHOW_CREATE_POST,
                      payload: {
                        value: true,
                        route: "create_post",
                      },
                    });
                  }}
                  startIcon={<ImageIcon />}
                >
                  Image
                </Button>
              </Grid>
              <Grid item align="center">
                <Button
                  variant="contained"
                  className={classes.button}
                  disabled={!createPostInGroup}
                  onMouseEnter={() => {
                    dispatch(EMDoGetPostSharedWithGroupsListAction());
                  }}
                  onClick={() => {
                    dispatch({
                      type: SHOW_CREATE_POST,
                      payload: {
                        value: true,
                        route: "create_post",
                      },
                    });
                  }}
                  startIcon={<VideoIcon />}
                >
                  video
                </Button>
              </Grid>
              <Grid item align="center">
                <Button
                  variant="contained"
                  className={classes.button}
                  disabled={!createPostInGroup}
                  onMouseEnter={() => {
                    dispatch(EMDoGetPostSharedWithGroupsListAction());
                  }}
                  onClick={() => {
                    dispatch({
                      type: SHOW_CREATE_POST,
                      payload: {
                        value: true,
                        route: "create_post",
                      },
                    });
                  }}
                  startIcon={<CloudUploadIcon />}
                >
                  Document
                </Button>
              </Grid>
            </>
          ) : null}
          <Grid item align="center">
            <Button
              variant="contained"
              className={classes.button}
              disabled={!createEventInGroup}
              onMouseEnter={() => {
                dispatch(EMDoGetEventSharedWithGroupsListAction());
              }}
              onClick={() => {

                dispatch({
                  type: SHOW_CREATE_POST,
                  payload: {
                    value: true,
                    route: "create_event",
                  },
                });
              }}
              startIcon={<EventIcon />}
            >
              event
            </Button>
          </Grid>
          <Grid item align="center">
            <Dialog open={selectOption} onClose={onClose} fullWidth={true}>
              <DialogTitle>
                {"Select the Question Type"}
                <IconButton
                  aria-label="close"
                  onClick={onClose}
                  style={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    // color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseRounded />
                </IconButton>
              </DialogTitle>
              <DialogActions>
                <Grid
                  item
                  xs={6}
                  style={{ marginBottom: 15, marginLeft: "12%" }}
                >
                  <Button
                    variant="contained"
                    className={classes.button}
                    style={{ width: window.screen.width / 10.5 }}
                    onClick={() => {
                      onClose();
                      dispatch({
                        type: SHOW_CREATE_POST,
                        payload: {
                          value: true,
                          route: "create_poll",
                        },
                      });
                      dispatch({
                        type: TO_GET_THE_POLL_FORM,
                        payload: true,
                      });
                    }}
                  >
                    Poll
                  </Button>
                </Grid>
                <Grid item xs={6} style={{ marginBottom: 15 }}>
                  <Button
                    variant="contained"
                    className={classes.button}
                    onClick={() => {
                      onClose();
                      dispatch({
                        type: SHOW_CREATE_POST,
                        payload: {
                          value: true,
                          route: "create_poll",
                        },
                      });
                      dispatch({
                        type: TO_GET_THE_POLL_FORM,
                        payload: false,
                      });
                    }}
                    autoFocus
                  >
                    Open Ended
                  </Button>
                </Grid>
              </DialogActions>
            </Dialog>
            <Button
              disabled={!createPollInGroup}
              variant="contained"
              className={classes.button}
              onMouseEnter={() => {
                dispatch(EMDoGetPollSharedWithGroupsListAction());
              }}
              onClick={() => {
                
                onOpen();
              }}
              startIcon={<Poll />}
            >
              poll
            </Button>
          </Grid>
          <Grid item align="center">
            <Button
              variant="contained"
              className={classes.button}
              disabled={!createQuizInGroup}
              onMouseEnter={() => {
                dispatch(EMDoGetQuizSharedWithGroupsListAction());
              }}
              
              onClick={() => {
                dispatch({
                  type: SHOW_CREATE_POST,
                  payload: {
                    value: true,
                    route: "create_quiz",
                  },
                });
              }}
              startIcon={<Help style={{ color: "purple" }} />}
            >
              quiz
            </Button>
          </Grid>
        </Grid>
      )}
    </Paper>
  ) : null;
}
