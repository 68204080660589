import React from "react";
import EMPage from "../../../components/commans/EMPage";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import {
  EMDoGetBadgeReportAction,
  EMDoGetDataForDropdownInGamificationAction,
  EMDoGetLevelReportAction,
} from "../../../redux/actions/EMNetworkAdminActions";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  ArgumentAxis,
  BarSeries,
  Chart,
  ValueAxis,
} from "@devexpress/dx-react-chart-material-ui";
import { Animation, Stack, EventTracker, ValueScale } from "@devexpress/dx-react-chart";
import { EMDoGetBadgeReportForGroupAction, EMDoGetDataForDropdownInGamificationForGroupAction, EMDoGetLevelReportForGroupAction } from "../../../redux/actions/EMGroupAdminActions";
import { EMDoGetBadgeReportForChannelAction, EMDoGetDataForDropdownInGamificationForChannelAction, EMDoGetLevelReportForChannelAction } from "../../../redux/actions/EMChannelAdminActions";
import { CHANNEL_ADMIN_GET_BADGE_REPORT, CHANNEL_ADMIN_GET_LEVEL_REPORT } from "../../../redux/constants";

const useStyles = makeStyles((theme) => ({
  typo: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 1,
    wordBreak: "break-word",
    overflow: "hidden",
  },
}));

export default function GamificationReports() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const drop_down_data = useSelector(
    (state) => state.channelAdmin.gamificationDropdownData || {}
  );
  const badges_data = useSelector(
    (state) => state.channelAdmin.badgeReport || {}
  );
  const levels_data = useSelector(
    (state) => state.channelAdmin.levelReport || {}
  );
  const [selectedValue, setSelectedValue] = useState("");
  const [badgesData, setBadgesData] = useState([]);
  const [levelData, setLevelData] = useState([]);
  const classes = useStyles();

  const matchesXLScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const matchesLGScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const matchesMDScreen = useMediaQuery(theme.breakpoints.up("md"));
  const matchesSMScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const matchesXSScreen = useMediaQuery(theme.breakpoints.up("xs"));
  const [domain, setDomain] = useState(() => () => [0,10])
  const [domainForLevel, setDomainForLevel] = useState(() => () => [0,10])

  useEffect(() => {
    dispatch(EMDoGetDataForDropdownInGamificationForChannelAction());
    dispatch({
      type: CHANNEL_ADMIN_GET_LEVEL_REPORT,
      payload: undefined
    });
    dispatch({
      type: CHANNEL_ADMIN_GET_BADGE_REPORT,
      payload: undefined
    });
  }, []);

  const ArgumentLabel = (props, index) => {
    const { text } = props;

    props = {
      ...props,
      text: text,
    };
    return (
      <>
        <Tooltip title={<h2 style={{ color: "white" }}>{text}</h2>}>
          <ArgumentAxis.Label {...props} />
        </Tooltip>
      </>
    );
  };

  useEffect(() => {
    if (badges_data && levels_data && badges_data.newBadges && levels_data.report) {
      setBadgesData(badges_data.newBadges);
      setLevelData(levels_data.report);
      const maxBadge = badges_data.newBadges.reduce((p, c) => p.userCount > c.userCount ? p : c);
      const maxLevel = levels_data.report.reduce((p, c) => p.users > c.users ? p : c);
      if(maxBadge){
        let value = maxBadge.userCount
        value < 10 ? setDomain(() => () => [0,10]) : setDomain(() => () => [0,value + 2])
      }
      if(maxLevel){
        let value = maxLevel.users
        value < 10 ? setDomainForLevel(() => () => [0,10]) : setDomainForLevel(() => () => [0,value + 2])
      }
    }else{
      setBadgesData([])
      setLevelData([])
    }
  }, [badges_data, levels_data]);


  return (
    <EMPage title="Gamification Reports" style={{padding:"2%"}}>
      <Paper elevation={2}>
        <Box p={1}>
          <FormControl
            style={{
              width: matchesXLScreen
                ? "20%"
                : matchesLGScreen
                ? "30%"
                : matchesMDScreen
                ? "40%"
                : matchesSMScreen
                ? "50%"
                : matchesXSScreen
                ? "100%"
                : "20%",
            }}
          >
            <InputLabel id="select-channel" style={{color:"#4d4d4d"}}>Select Channel</InputLabel>
            <Select
              labelId="select-channel"
              id="select-channel"
              value={selectedValue}
              label="Select Network"
              // helperText={formik.touched.selectedNetwork && formik.errors.selectedNetwork}
              onChange={(selected) => {
                setSelectedValue(selected.target.value);
                let requiredData = drop_down_data.channels.find(
                  (channel) => channel.group == selected.target.value
                );
                if (requiredData) {
                  dispatch(
                    EMDoGetLevelReportForChannelAction({
                      entityType: requiredData.entity_type,
                      entityId: requiredData.id,
                    })
                  );
                  dispatch(
                    EMDoGetBadgeReportForChannelAction({
                      entityType: requiredData.entity_type,
                      entityId: requiredData.id,
                    })
                  );
                }
              }}
            >
              {drop_down_data &&
                drop_down_data.channels &&
                drop_down_data.channels.length > 0 &&
                drop_down_data.channels.map((channel) => (
                  <MenuItem value={channel.group}>{channel.group}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
      </Paper>
      {badgesData && badgesData.length > 0 ? <Paper style={{marginTop:"2%"}} elevation={2}>
          <Typography
            style={{
              textAlign: "center",
              fontWeight: "bolder",
              fontSize: "large",
              paddingTop:"2%"
            }}
          >
            Badges Earned
          </Typography>
          <Grid
            alignItems="center"
            justifyContent="center"
            container
            direction="row"
          >
            <Grid item xs={2} sm={1}>
              <Typography
                style={{
                  transform: "rotate(-90deg)",
                  fontWeight: "bolder",
                  fontSize: "large",
                  display:"flex",
                  alignItems:"center",
                  justifyContent:"center",
                  height:"10rem",
                  whiteSpace:"nowrap"
                }}
              >
                No. of Users
              </Typography>
            </Grid>
            <Grid item xs={10} sm={11}>
              {badgesData.length > 0 ? (
                <Chart data={badgesData}>
                  <ValueScale modifyDomain={domain} />
                  <ArgumentAxis labelComponent={ArgumentLabel} />
                  <ValueAxis />

                  <BarSeries valueField="userCount" argumentField="badgeName" name="badge_series" />

                  <Animation />
                </Chart>
              ) : null}
            </Grid>
          </Grid>
          <Typography
            style={{
              textAlign: "center",
              fontWeight: "bolder",
              fontSize: "large",
            }}
          >
           Badge
          </Typography>
        </Paper> : null}
      {levelData && levelData.length > 0 ? <Paper style={{marginTop:"2%"}} elevation={2}>
          <Typography
            style={{
              textAlign: "center",
              fontWeight: "bolder",
              fontSize: "large",
              paddingTop:"2%"
            }}
          >
            Level Performance
          </Typography>
          <Grid
            alignItems="center"
            justifyContent="center"
            container
            direction="row"
          >
            <Grid item xs={2} sm={1}>
              <Typography
                style={{
                  transform: "rotate(-90deg)",
                  fontWeight: "bolder",
                  fontSize: "large",
                  display:"flex",
                  alignItems:"center",
                  justifyContent:"center",
                  height:"10rem",
                  whiteSpace:"nowrap"
                }}
              >
                No. of Users
              </Typography>
            </Grid>
            <Grid item xs={10} sm={11}>
              {levelData.length > 0 ? (
                <Chart data={levelData}>
                  <ValueScale modifyDomain={domainForLevel} />
                  <ArgumentAxis labelComponent={ArgumentLabel} />
                  <ValueAxis />

                  <BarSeries valueField="users" argumentField="level" name="level_series" />

                  <Animation />
                </Chart>
              ) : null}
            </Grid>
          </Grid>
          <Typography
            style={{
              textAlign: "center",
              fontWeight: "bolder",
              fontSize: "large",
            }}
          >
           Level
          </Typography>
        </Paper> : null}
    </EMPage>
  );
}
