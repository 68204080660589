import React, { useState, useCallback, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { AddCircle, ShowChart } from "@material-ui/icons";
import { ReactComponent as ZPointsWhite } from "../../../../assets/z_points_white.svg";
import { ReactComponent as ZPointsBlue } from "../../../../assets/z_points_blue.svg";
import { useSelector, useDispatch } from "react-redux";
import {
  EMDoGetLeaderBoardAction,
  EMDoGetUserPointsAction,
} from "../../../../redux/actions/UserPlatform/Home/EMLatestPostPageAction";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxHeight: window.screen.height / 2,
    overflowY: "auto",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "0.2em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#4A4A4A",
      outline: "1px solid slategrey",
    },
    // marginTop:"2%"
  },
  title: {
    fontWeight: "bolder",
    fontSize: "medium",
    color: "rgb(47, 146, 214)",
  },
  leaderBoard: {
    padding: "2%",
    alignItems: "center",
    justifyContent: "center",
  },
  stats: {
    padding: "2%",
    alignItems: "center",
    justifyContent: "center",
    margin: "0%",
    width: "100%",
    borderRadius: "5px",
    marginBottom: "10%",
  },
  centeredGrid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  leftGrid: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  typography: {
    color: "white",
  },
  username: {
    color: theme.palette.text.primary,
    fontSize: "10px",
  },
  typo: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 3,
    wordBreak: "break-word",
    overflow: "hidden",
  },
}));

export default function EMLeaderBoard({ fromProfile }) {
  const classes = useStyles();
  const [limit, setLimit] = useState(1);
  const data = useSelector((state) => state.home || {});
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth || {});
  const navigate = useNavigate();

  const theme = useTheme();

  const matcheslgScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const matchexlScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const matchemdScreen = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    dispatch(EMDoGetUserPointsAction());
  }, []);

  const sendQuery = useCallback(async () => {
    if (authData && authData.organization_id) {
      dispatch(
        EMDoGetLeaderBoardAction({
          organizationId: authData && authData.organization_id,
          page: limit,
        })
      );
    }
  }, [limit, authData.organization_id]);

  useEffect(() => {
    sendQuery(limit);
  }, [sendQuery, limit]);

  const handleObserver = useCallback(() => {
    ;
    if (data.nextLeaderBoard !== null) {
      setLimit((prev) => prev + 1);
    }
  }, [limit, data.nextLeaderBoard]);

  return (
    <>
      {fromProfile ? null : (
        <Grid
          container
          direction="row"
          style={{ backgroundColor: "rgb(47, 146, 214)" }}
          spacing={1}
          className={classes.stats}
        >
          <Grid item xs={3}>
            <Typography className={classes.typography}>My Stats</Typography>
          </Grid>
          <Grid item xs={1} className={classes.centeredGrid}>
            <Divider
              orientation="vertical"
              style={{ height: "40px", width: "2px", backgroundColor: "white" }}
            />
          </Grid>
          <Grid item xs={8} container>
            <Grid item xs={6}>
              <Box>
                <Typography className={classes.typography}>Rank</Typography>
                <Typography className={classes.typography}>
                  {data.userPoints && data.userPoints.rank}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box>
                <Typography className={classes.typography}>Z-Points</Typography>
                <Box
                  flexDirection="row"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography className={classes.typography}>
                    {data.userPoints && data.userPoints.z_points}
                  </Typography>
                  <ZPointsWhite />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )}

      <Paper
        className={classes.root}
        elevation={2}
        style={{
          boxShadow:
            "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
        }}
      >
        <Grid
          container
          direction="row"
          spacing={1}
          className={classes.leaderBoard}
          style={{ borderBottom: "1.5px solid rgb(47, 146, 214)" }}
        >
          <Grid item xl={2} lg={3} md={3} className={classes.centeredGrid}>
            <ShowChart fontSize="large" color="primary" />
          </Grid>
          <Grid item xl={10} lg={9} md={9} className={classes.centeredGrid}>
            <Typography className={classes.title}>LEADERBOARD</Typography>
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            paddingTop: "2%",
            paddingLeft: "2%",
            borderBottom: "1.5px solid rgb(47, 146, 214)",
          }}
        >
          <Grid item xs={4}>
            <Typography
              style={{ fontSize: "12px", fontWeight: "bolder" }}
              className={classes.leftGrid}
            >
              Rank
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              style={{ fontSize: "12px", fontWeight: "bolder" }}
              className={classes.leftGrid}
            >
              Users
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              style={{ fontSize: "12px", fontWeight: "bolder" }}
              className={classes.leftGrid}
            >
              Z-Points
            </Typography>
          </Grid>
        </Grid>
        {data.leaderBoard &&
          data.leaderBoard.map((user, index) => (
            <Grid
              onClick={() => {
                if (window.location.href.includes("home")) {
                  navigate(`/users/people/${user.id}`);
                }
              }}
              style={{ paddingTop: "2%", cursor: "pointer" }}
              className={classes.leaderBoard}
              container
              direction="row"
              spacing={1}
            >
              <Grid item xl={1} md={1} lg={2} xs={2}>
                <Typography style={{ fontWeight: "bolder" }}>
                  {user && index + 1}.
                </Typography>
              </Grid>
              <Grid item container xl={11} md={11} lg={10} xs={10} spacing={1}>
                <Grid item xl={3} sm={2} xs={2} className={classes.centeredGrid}>
                  <Avatar
                    style={{
                      height: matchexlScreen
                        ? "36px"
                        : matcheslgScreen
                        ? "24px"
                        : matchemdScreen
                        ? "36px"
                        : "36px",
                      width: matchexlScreen
                        ? "36px"
                        : matcheslgScreen
                        ? "24px"
                        : matchemdScreen
                        ? "36px"
                        : "36px",
                    }}
                    src={
                      process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(0, -1) +
                      user.profile_pic
                    }
                  />
                </Grid>
                <Grid item xl={5} lg={6} md={5} xs={5}>
                  <Typography
                    className={classes.typo}
                    style={{ fontSize: "10px", fontWeight: "bolder" }}
                  >
                    {user && user.name}
                  </Typography>
                  <Typography
                    style={{ fontSize: "10px" }}
                    className={classes.typo}
                  >
                    {user && user.professional_title}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  alignItems="center"
                  justifyContent="center"
                  container
                  direction="row"
                >
                  <Grid
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    item
                    xs={12}
                  >
                    <Typography style={{ fontWeight: "bolder" }}>
                      {user && user.z_points}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="center"
          style={{ padding: 8 }}
        >
          {data.nextLeaderBoard !== null ? (
            <Button
              variant="outlined"
              onClick={() => {
                handleObserver();
              }}
              startIcon={<AddCircle />}
            >
              Load More
            </Button>
          ) : null}
        </Grid>
      </Paper>
    </>
  );
}
