import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Card } from "@material-ui/core/";
import Typography from "@material-ui/core/Typography";
import {
  SvgIcon,
  CardMedia,
  Grid,
  Box,
  Tooltip,
  Badge,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import StarRateIcon from "@material-ui/icons/StarRate";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import PublicIcon from "@material-ui/icons/Public";
import { useDispatch, useSelector } from "react-redux";
import { CLEAR_GROUP_DATA, GROUP_INFO_MODAL } from "../../redux/constants/UserPlatform/EMGroupConstant";
import groupImage from "../../../src/assets/group_image.jpg";
import { CLEAR_CHANNEL_DATA } from "../../redux/constants/UserPlatform/EMChannelConstant";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  topRight: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  cover: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  groupInfo: {
    width: "auto",
    fontWeight: "bolder",
    color: theme.palette.text.primary,
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 2,
    wordBreak: "break-word",
    overflow: "hidden",
  },
}));

export default function EMGroupView({
  onStarClick,
  id,
  onCardClick,
  number_of_group_users,
  group_name,
  logo_file,
  isFav,
  created_by,
  organization_name,
  users_count,
  privacy_type_name,
  isMember,
  route,
  type,
  value,
  isHighlighted,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.groups || {});

  function hasWhiteSpace(s) {
    return s.indexOf(" ") >= 0;
  }

  return (
    <Card>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <div
            onClick={() => {
              dispatch({type:CLEAR_GROUP_DATA})
              dispatch({type:CLEAR_CHANNEL_DATA})
              onCardClick()}}
            style={{
              width: "",
              height: "150px",
              whiteSpace: "nowrap",
              textAlign: "center",
            }}
          >
            <span
              style={{
                display: "inline-block",
                height: "100%",
                verticalAlign: "middle",
              }}
            ></span>
            <img
              alt=""
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                verticalAlign: "middle",
              }}
              src={
                logo_file
                  ? process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(0, -1) +
                    logo_file
                  : groupImage
              }
            />
          </div>
        </Grid>
        <Grid item xs={6} container>
          <Box display="flex" top="0px" right="0px" position="absolute">
            <>
              {route !== "browse_groups" ? (
                <>
                  {isFav ? (
                    <IconButton onClick={onStarClick} aria-label="pin">
                      <StarRateIcon color="secondary" />
                    </IconButton>
                  ) : (
                    <IconButton onClick={onStarClick} aria-label="pin">
                      <StarRateIcon style={{ color: "#C8CDD0" }} />
                    </IconButton>
                  )}
                  <IconButton
                    onClick={() => {
                      created_by || organization_name
                        ? dispatch({
                            type: type,
                            payload: {
                              value: !value,
                              groupInfo: created_by,
                              network_name: organization_name,
                              groupName: group_name,
                              groupLogo: logo_file,
                            },
                          })
                        : dispatch({
                            type: type,
                            payload: {
                              value: false,
                              groupInfo: created_by,
                              network_name: organization_name,
                              groupName: group_name,
                              groupLogo: logo_file,
                            },
                          });
                    }}
                  >
                    <InfoIcon />
                  </IconButton>
                </>
              ) : null}
              <Badge
                variant="dot"
                style={{ top: 5, right: 5 }}
                invisible={!isHighlighted}
                color="error"
              ></Badge>
            </>
          </Box>
          <Box
            onClick={onCardClick}
            display="flex"
            width="100%"
            justifyContent="center"
            alignItems="center"
          >
            <Box m="auto" >
              <Tooltip
                title={<h2 style={{ color: "white" }}>{group_name}</h2>}
                placement="top"
              >
                <Typography
                  className={classes.groupInfo}
                >
                  {group_name}
                </Typography>
              </Tooltip>
              {isMember && isMember === true ? (
                <Typography color="textSecondary">(member)</Typography>
              ) : null}
            </Box>
          </Box>
          <Box
            onClick={onCardClick}
            display="flex"
            bottom="0px"
            right="0px"
            position="absolute"
            alignItems="center"
            justifyContent="center"
          >
            <Box display="flex" alignItems="center"
            justifyContent="center" paddingRight="8px" paddingBottom="0px">
              {privacy_type_name === "Public" ? <PublicIcon /> : null}
              {privacy_type_name === "Restricted" ? <LockIcon /> : null}
              {privacy_type_name === "Private" ? <LockOpenIcon /> : null}
              {privacy_type_name === "Secret" ? <SecretIcon /> : null}
            </Box>
            <Box paddingRight="8px">
              <PeopleIcon style={{ color: "#2F92D6" }} />{" "}
            </Box>
            <Box paddingRight="8px">
              <Typography style={{ fontWeight: "bolder" }}>
                {number_of_group_users ? number_of_group_users : users_count}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
}

function PinIcon(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        d="M23.541,23.763l-9.431-5.9a2.321,2.321,0,0,0-3.306.958,2.745,2.745,0,0,0,.886,3.581l9.429,5.9a2.32,2.32,0,0,0,3.306-.959,2.739,2.739,0,0,0-.885-3.58Zm-7.778-6.378L23.1,21.973l2.495-7.521-5.065-3.167ZM10.356,36.706l7.017-9.233-3.144-1.966-3.873,11.2ZM28.625,10.292,22.339,6.36a1.74,1.74,0,0,0-2.479.72,2.057,2.057,0,0,0,.664,2.686L26.811,13.7a1.74,1.74,0,0,0,2.479-.72,2.057,2.057,0,0,0-.665-2.686Z"
        transform="translate(-10.356 -6.096)"
      />
    </SvgIcon>
  );
}

function InfoIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M12.042,3a9.042,9.042,0,1,0,9.042,9.042A9.045,9.045,0,0,0,12.042,3Zm.9,13.562H11.137V11.137h1.808Zm0-7.233H11.137V7.521h1.808Z" />
    </SvgIcon>
  );
}

function SecretIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M12.227,9.819l.761-1.994a.509.509,0,0,0-.478-.691H10.647A4.038,4.038,0,0,0,11.214,5.1v-.01c1.248-.248,2.038-.608,2.038-1.01,0-.424-.869-.8-2.233-1.051A8.262,8.262,0,0,0,9.726.389,1.022,1.022,0,0,0,8.468.109l-.879.44a1.019,1.019,0,0,1-.911,0L5.8.109a1.022,1.022,0,0,0-1.258.28A8.329,8.329,0,0,0,3.249,3.026c-1.36.252-2.229.627-2.229,1.051,0,.4.79.761,2.038,1.01V5.1a4.038,4.038,0,0,0,.567,2.038H1.793a.509.509,0,0,0-.468.71l.822,1.917A4.266,4.266,0,0,0,0,13.453V14.88a1.429,1.429,0,0,0,1.427,1.427H12.841a1.429,1.429,0,0,0,1.427-1.427V13.453a4.268,4.268,0,0,0-2.041-3.634ZM5.605,15.288,4.28,9.173l1.58,1.019.764,1.274Zm3.057,0L7.644,11.466l.764-1.274,1.58-1.019ZM9.991,5.781a2.342,2.342,0,0,1-.525,1.064c-.322.3-1.529.713-2.038-.8a.312.312,0,0,0-.583,0c-.541,1.6-1.784,1.032-2.038.8A2.311,2.311,0,0,1,4.28,5.781c-.025-.08-.2-.182-.2-.185V5.252a24.526,24.526,0,0,0,3.057.185,23.929,23.929,0,0,0,3.057-.185V5.6S10.016,5.7,9.991,5.781Z" />
    </SvgIcon>
  );
}

function PeopleIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M17.781,14.013a3.256,3.256,0,1,0-3.256-3.256A3.243,3.243,0,0,0,17.781,14.013Zm-8.683,0a3.256,3.256,0,1,0-3.256-3.256A3.243,3.243,0,0,0,9.1,14.013Zm0,2.171c-2.529,0-7.6,1.27-7.6,3.8V22.7H16.7V19.983C16.7,17.453,11.627,16.184,9.1,16.184Zm8.683,0c-.315,0-.673.022-1.053.054a4.58,4.58,0,0,1,2.138,3.745V22.7h6.513V19.983C25.379,17.453,20.31,16.184,17.781,16.184Z" />
    </SvgIcon>
  );
}


