import { cloneDeep, findIndex, uniqBy } from "lodash";
import {
  MY_POLLS,
  MY_OPEN_ENDED_QUESTIONS,
  MY_POLL_DETAILS,
  MY_OPEN_POLL_DETAILS,
} from "../../../constants/UserPlatform/EMPollConstant";
import {
  TO_GET_THE_POLL_FORM,
  SHOW_CREATE_POST,
  POST_CATEGORY_SELECTED_DATA,
  POST_SHOW_HASHTAG,
  POST_HASHTAG_SELECTED,
  POST_SHOW_TAGPEOPLE,
  POST_TAGPEOPLE_SELECTED,
  POST_EMOJI_SELECTED,
  POST_SHOW_EMOJI,
  POST_SHOW_MORE,
  SHOW_POST_EDIT,
  POST_SHOW_MORE_SELECTED,
  LIST_SHARE_WITH_GROUP,
  LIST_HASHTAG,
  LIST_MENTIONS,
  POST_CATEGORY_DATA,
  DELETE_POST,
  OPEN_DELETE_CONFIRMATION_DIALOG,
  GET_MENTIONS_FOR_POST,
  SUBMIT_POLL,
  SUBMIT_OPEN_POLL,
  LIKE_DISLIKE_PIN_UNPIN_POSTS,
} from "../../../constants/UserPlatform/index";
import { ReactComponent as BlueIcon } from "../../../../assets/blue.svg";
import { ReactComponent as GreenIcon } from "../../../../assets/green.svg";
import { ReactComponent as YellowIcon } from "../../../../assets/yellow.svg";
import { ReactComponent as OrangeIcon } from "../../../../assets/orange.svg";
import { ReactComponent as PurpleIcon } from "../../../../assets/purple.svg";
import { ReactComponent as EditIcon } from "../../../../assets/Icon-material-edit.svg";
import ShareIcon from "@material-ui/icons/Share";
import DeleteIcon from "@material-ui/icons/Delete";
import { AUTH_LOGOUT } from "../../../constants";

const initialState = {
  showCreatePost: {},
  polls: [],
  openEndedQuestions: [],
  pollWithOptions: undefined,
  categories: [
    { id: 1, categoryName: "Text" },
    { id: 2, categoryName: "Images & Videos" },
    { id: 3, categoryName: "Documents" },
    { id: 4, categoryName: "Events" },
    { id: 5, categoryName: "Polls" },
  ],
  categorySelected: "",
  postHashTags: [],
  postAtMentions: [],
  postMoreOptions: [
    { name: "Edit Post", icon: <EditIcon /> },
    { name: "Share with Groups", icon: <ShareIcon /> },
    { name: "Delete Post", icon: <DeleteIcon /> },
  ],
  postSharedWithGroups: [],
  pollDetails: {count: 0, next: null, previous: null, results: []},
  openPollDetails: {count: 0, next: null, previous: null, results: []},
};

export default function EMMyPollReducer(state = initialState, action) {
  let newState = cloneDeep(state);
  switch (action.type) {
    case MY_POLLS: {
      newState["polls"] = action.payload.response;

      newState["polls"].map((post) => {
        post["userName"] = post["firstName"] + " " + post["lastName"];
        post["expandText"] = false;
        post["expandPostComment"] = false;
        post["showComments"] = false;
        post["showHashTag"] = false;
        post["showTagPeople"] = false;
        post["showEmoji"] = false;
        post["mediaView"] = false;
        let start = new Date(post["event_start_date"]);
        let end = new Date(post["event_end_date"]);
        function ChangeDate(date) {
          var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

          if (month.length < 2) month = "0" + month;
          if (day.length < 2) day = "0" + day;

          return [year, month, day].join("-");
        }
        function ChangeTime(date) {
          return (
            ("00" + date.getHours()).slice(-2) +
            ":" +
            ("00" + date.getMinutes()).slice(-2) +
            ":" +
            ("00" + date.getSeconds()).slice(-2)
          );
        }

        function showTimeZone(date) {
          const dateAsString = date.toString();
          const timezone = dateAsString.match(/\(([^\)]+)\)$/)[1];
          return timezone;
        }

        post["event_start"] = ChangeDate(start);
        post["event_end"] = ChangeDate(end);
        post["event_start_time"] = ChangeTime(start);
        post["event_end_time"] = ChangeTime(end);
        post["showMore"] = false;
        post["timeZone"] = showTimeZone(start);

        post["channel_details"].map((channel) => {
          if (channel.channel_name.includes("MAIN_CHANNEL")) {
            post["postFrom"] = "home";
          } else {
            post["postFrom"] = "channel";
          }
        });

        post["shouldOpen"] = false;
        post["images"].map((img) => {
          img["type"] = "images";
        });
        post["videos"].map((video) => {
          video["type"] = "videos";
        });
        post["video_links"].map((video) => {
          video["type"] = "video_link";
        });
        post["documents"].map((doc) => {
          doc["type"] = "documents";
        });
        post["totalMedia"] = post["images"].concat(post["videos"]);
        post["totalCombinedMedia"] = post["totalMedia"].concat(
          post["video_links"]
        );
        // if(post["totalMedia"].length > 10) {
        //   post["totalCombinedMedia"] = post["totalMedia"].slice(0,10)
        // } else if (post["totalMedia"].length < 10) {
        //   post["totalCombinedMedia"] = post["totalMedia"]
        // }
        post["postAtMentions"] = [];
        post["editPost"] = {
          showEditPost: false,
          labels: { title: "", button: "" },
        };
        post["newComment"] = "";
        post["owner"] =
          post.userName == action.payload.userData.name ? true : false;
        let postCategory = post["category"];

        newState["categories"].map((category) => {
          if (
            category.category_name &&
            category.category_name.includes(postCategory)
          ) {
            post["category"] = category.icon;
          }
        });
      });
      return newState;
    }

    case MY_OPEN_ENDED_QUESTIONS: {
      newState["openEndedQuestions"] = action.payload.response;

      newState["openEndedQuestions"].map((post) => {
        post["userName"] = post["firstName"] + " " + post["lastName"];
        post["expandText"] = false;
        post["expandPostComment"] = false;
        post["showComments"] = false;
        post["showHashTag"] = false;
        post["showTagPeople"] = false;
        post["showEmoji"] = false;
        post["mediaView"] = false;
        let start = new Date(post["event_start_date"]);
        let end = new Date(post["event_end_date"]);
        function ChangeDate(date) {
          var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

          if (month.length < 2) month = "0" + month;
          if (day.length < 2) day = "0" + day;

          return [year, month, day].join("-");
        }
        function ChangeTime(date) {
          return (
            ("00" + date.getHours()).slice(-2) +
            ":" +
            ("00" + date.getMinutes()).slice(-2) +
            ":" +
            ("00" + date.getSeconds()).slice(-2)
          );
        }

        function showTimeZone(date) {
          const dateAsString = date.toString();
          const timezone = dateAsString.match(/\(([^\)]+)\)$/)[1];
          return timezone;
        }

        post["event_start"] = ChangeDate(start);
        post["event_end"] = ChangeDate(end);
        post["event_start_time"] = ChangeTime(start);
        post["event_end_time"] = ChangeTime(end);
        post["showMore"] = false;
        post["timeZone"] = showTimeZone(start);

        post["channel_details"].map((channel) => {
          if (channel.channel_name.includes("MAIN_CHANNEL")) {
            post["postFrom"] = "home";
          } else {
            post["postFrom"] = "channel";
          }
        });

        post["shouldOpen"] = false;
        post["images"].map((img) => {
          img["type"] = "images";
        });
        post["videos"].map((video) => {
          video["type"] = "videos";
        });
        post["video_links"].map((video) => {
          video["type"] = "video_link";
        });
        post["documents"].map((doc) => {
          doc["type"] = "documents";
        });
        post["totalMedia"] = post["images"].concat(post["videos"]);
        post["totalCombinedMedia"] = post["totalMedia"].concat(
          post["video_links"]
        );
        // if(post["totalMedia"].length > 10) {
        //   post["totalCombinedMedia"] = post["totalMedia"].slice(0,10)
        // } else if (post["totalMedia"].length < 10) {
        //   post["totalCombinedMedia"] = post["totalMedia"]
        // }
        post["postAtMentions"] = [];
        post["editPost"] = {
          showEditPost: false,
          labels: { title: "", button: "" },
        };
        post["newComment"] = "";
        post["owner"] =
          post.userName == action.payload.userData.name ? true : false;
        let postCategory = post["category"];

        newState["categories"].map((category) => {
          if (
            category.category_name &&
            category.category_name.includes(postCategory)
          ) {
            post["category"] = category.icon;
          }
        });
      });
      return newState;
    }

    case SUBMIT_POLL: {
      let response = action.payload.poll_details;
      response.map((x) => {
        let index = newState["polls"].findIndex((d) => d.id === x.id);
        if (index >= 0) {
          newState["polls"][index].poll_details = x;
        }
      });
      return newState;
    }

    case MY_POLL_DETAILS: {
      let response = action.payload;
      newState["pollDetails"] = response;
      return newState;
    }

    case MY_OPEN_POLL_DETAILS: {
      let response = action.payload;
      newState["openPollDetails"] = response;
      return newState;
    }

    case SUBMIT_OPEN_POLL: {
      let response = action.payload.poll_details;
      response.map((x) => {
        let index = newState["openEndedQuestions"].findIndex(
          (d) => d.id === x.id
        );
        if (index >= 0) {
          newState["openEndedQuestions"][
            index
          ].open_ended_question_details.poll_details = x;
        }
      });
      return newState;
    }

    case POST_CATEGORY_DATA: {
      newState["categories"] = action.payload;
      newState["categories"].map((category) => {
        switch (category.category_name) {
          case "Text": {
            category["icon"] = <YellowIcon />;
            break;
          }
          case "Media": {
            category["icon"] = <OrangeIcon />;
            break;
          }
          case "Documents": {
            category["icon"] = <BlueIcon />;
            break;
          }
          case "Events": {
            category["icon"] = <GreenIcon />;
            break;
          }
          case "Poll": {
            category["icon"] = <PurpleIcon />;
            break;
          }
        }
      });
      return newState;
    }

    //Like Dislike Pin Unpin posts
    case LIKE_DISLIKE_PIN_UNPIN_POSTS: {
      let dataId = action.payload.id;
      let pollIndex = findIndex(newState["polls"], { id: dataId });
      let openEndedQuestionIndex = findIndex(newState["openEndedQuestions"], {
        id: dataId,
      });
      let postId = action.payload.id;
      if (pollIndex >= 0) {
        let postIndex = findIndex(newState["polls"], { id: postId });
        if (postIndex >= 0) {
          newState["polls"][postIndex].isPinnedPost = action.payload.is_pinned;
        }
      } else if (openEndedQuestionIndex >= 0) {
        let postIndex = findIndex(newState["openEndedQuestions"], {
          id: postId,
        });
        if (postIndex >= 0) {
          newState["openEndedQuestions"][postIndex].isPinnedPost =
            action.payload.is_pinned;
        }
      }

      return newState;
    }

    case SHOW_CREATE_POST: {
      newState["showCreatePost"]["value"] = action.payload.value;
      newState["showCreatePost"]["route"] = action.payload.route;
      return newState;
    }

    case OPEN_DELETE_CONFIRMATION_DIALOG: {
      let dataId = action.payload.id;
      let pollIndex = findIndex(newState["polls"], { id: dataId });
      let openEndedQuestionIndex = findIndex(newState["openEndedQuestions"], {
        id: dataId,
      });
      if (pollIndex >= 0) {
        newState["polls"].map((post) => {
          if (post.id == action.payload.id) {
            post["shouldOpen"] = action.payload.shouldOpen;
          }
        });
      } else if (openEndedQuestionIndex >= 0) {
        newState["openEndedQuestions"].map((post) => {
          if (post.id == action.payload.id) {
            post["shouldOpen"] = action.payload.shouldOpen;
          }
        });
      }

      return newState;
    }

    case POST_SHOW_MORE: {
      let dataId = action.payload.id;
      let pollIndex = findIndex(newState["polls"], { id: dataId });
      let openEndedQuestionIndex = findIndex(newState["openEndedQuestions"], {
        id: dataId,
      });
      if (pollIndex >= 0) {
        newState["polls"].map((post) => {
          if (post.id == action.payload.id) {
            post["showMore"] = action.payload.showMore;
          }
        });
      } else if (openEndedQuestionIndex >= 0) {
        newState["openEndedQuestions"].map((post) => {
          if (post.id == action.payload.id) {
            post["showMore"] = action.payload.showMore;
          }
        });
      }

      return newState;
    }

    /* Show Edit Post */
    case SHOW_POST_EDIT: {
      let dataId = action.payload.id;
      let pollIndex = findIndex(newState["polls"], { id: dataId });
      let openEndedQuestionIndex = findIndex(newState["openEndedQuestions"], {
        id: dataId,
      });
      if (pollIndex >= 0) {
        newState["polls"].map((post) => {
          if (post.id == action.payload.id) {
            post["editPost"] = {
              showEditPost: action.payload.editPost,
              labels: {
                title: action.payload.title,
                button: action.payload.button,
              },
            };
          }
        });
      } else if (openEndedQuestionIndex >= 0) {
        newState["openEndedQuestions"].map((post) => {
          if (post.id == action.payload.id) {
            post["editPost"] = {
              showEditPost: action.payload.editPost,
              labels: {
                title: action.payload.title,
                button: action.payload.button,
              },
            };
          }
        });
      }

      return newState;
    }

    case POST_SHOW_MORE_SELECTED: {
      let dataId = action.payload.id;
      let pollIndex = findIndex(newState["polls"], { id: dataId });
      let openEndedQuestionIndex = findIndex(newState["openEndedQuestions"], {
        id: dataId,
      });
      if (pollIndex >= 0) {
        newState["polls"].map((post) => {
          if (post.id == action.payload.id) {
            switch (action.payload.option) {
              case "Edit Post": {
                post["editPost"] = {
                  showEditPost: action.payload.args.editPost,
                  labels: {
                    title: action.payload.args.title,
                    button: action.payload.args.button,
                  },
                };
                break;
              }
              case "Share with Groups": {
                post["editPost"] = {
                  showEditPost: action.payload.args.editPost,
                  labels: {
                    title: action.payload.args.title,
                    button: action.payload.args.button,
                  },
                };
                break;
              }
            }
          }
        });
      } else if (openEndedQuestionIndex >= 0) {
        newState["openEndedQuestions"].map((post) => {
          if (post.id == action.payload.id) {
            switch (action.payload.option) {
              case "Edit Post": {
                post["editPost"] = {
                  showEditPost: action.payload.args.editPost,
                  labels: {
                    title: action.payload.args.title,
                    button: action.payload.args.button,
                  },
                };
                break;
              }
              case "Share with Groups": {
                post["editPost"] = {
                  showEditPost: action.payload.args.editPost,
                  labels: {
                    title: action.payload.args.title,
                    button: action.payload.args.button,
                  },
                };
                break;
              }
            }
          }
        });
      }

      return newState;
    }

    //Share with groups listing
    case LIST_SHARE_WITH_GROUP: {
      newState["postSharedWithGroups"] = action.payload;
      return newState;
    }

    case DELETE_POST: {
      let dataId = action.payload;
      let pollIndex = findIndex(newState["polls"], { id: dataId });
      let openEndedQuestionIndex = findIndex(newState["openEndedQuestions"], {
        id: dataId,
      });
      if (pollIndex >= 0) {
        newState["polls"].splice(pollIndex, 1);
      } else if (openEndedQuestionIndex >= 0) {
        newState["openEndedQuestions"].splice(openEndedQuestionIndex, 1);
      }
      return newState;
    }

    case TO_GET_THE_POLL_FORM: {
      newState["pollWithOptions"] = action.payload;
      return newState;
    }

    case AUTH_LOGOUT: {
      return { ...initialState };
    }

    default: {
      return newState;
    }
  }
}
