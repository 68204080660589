import {SHOW_MESSAGE} from "../../../constants/index";
import {ORGANISATION_MEMBERS,LOGIN_USER_DETAILS,LOGIN_USER_GROUPS,LOGIN_USER_CHANNELS, OTHER_NETWORKS_MEMBERS, OTHER_NETWORKS_MEMBERS_FILTERED, GET_USER_LEVEL_AND_BADGES, DISABLE_ENABLE_SEARCH_MY_COMPANY, DISABLE_ENABLE_SEARCH_OTHERS} from "../../../constants/UserPlatform/EMPeopleConstant"
import API from "../../../../api";
import { DISABLE_ENABLE_FILTER } from "../../../constants/UserPlatform/EMGroupConstant";
import { handleError } from "../../../../utils/EMCommonFunctions";
export const EMDoGetProfileDetailsAction = action => {
    return dispatch => {
        API.EMDoGetProfileDetails(action)
            .then(data => data.data)
            .then(response => {
                if (response) {
                    dispatch({
                        type: LOGIN_USER_DETAILS,
                        payload: response
                    });
                } else {
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: {
                            type: "error",
                            message: "Error while fetching user details"
                        }
                    });
                }
            })
            .catch(err => {
                handleError(dispatch, err)
            });
    };
};

export const EMDoGetGroupsOfUserForProfileAction = action => {
    return dispatch => {
        API.EMDoGetGroupsOfUserForProfile(action)
            .then(data => data.data)
            .then(response => {
                if (response) {
                    dispatch({
                        type: LOGIN_USER_GROUPS,
                        payload: response
                    });
                } else {
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: {
                            type: "error",
                            message: "Error occured while fetching groups list"
                        }
                    });
                }
            })
            .catch(err => {
                handleError(dispatch, err)
            });
    };
};

export const EMDoGetChannelsOfUserForProfileAction = action => {
    return dispatch => {
        API.EMDoGetChannelsOfUserForProfile(action)
            .then(data => data.data)
            .then(response => {
                if (response) {
                    dispatch({
                        type: LOGIN_USER_CHANNELS,
                        payload: response
                    });
                } else {
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: {
                            type: "error",
                            message: "Error occured while fetching channels list"
                        }
                    });
                }
            })
            .catch(err => {
                handleError(dispatch, err)
            });
    };
};

export const EMDoGetListOfMembersOfOrganisationAction =(action)=>{
    return dispatch => {
        API.EMDoGetListOfMembersOfOrganisation(action.limit)
            .then(data => data.data)
            .then(response => {
                if (response) {
                    dispatch({
                        type: ORGANISATION_MEMBERS,
                        payload: {response:response, limit:action.limit}
                    });
                    dispatch({
                        type:DISABLE_ENABLE_SEARCH_MY_COMPANY,
                        payload:false
                    })
                } else {
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: {
                            type: "error",
                            message: "Error occured while fetching channels list"
                        }
                    });
                }
            })
            .catch(err => {
                handleError(dispatch, err)
            });
    };
}

// Searching all members in people
export const EMDoGetListOfMembersOfOrganisationBySearchAction =action=>{
    return dispatch => {
        API.EMDoGetListOfMembersOfOrganisationBySearch(action.searchText, action.limit)
            .then(data => data.data)
            .then(response => {
                if (response) {
                    dispatch({
                        type: ORGANISATION_MEMBERS,
                        payload: {response:response, limit:action.limit} 
                    });
                    dispatch({
                        type:DISABLE_ENABLE_SEARCH_MY_COMPANY,
                        payload:false
                    })
                } else {
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: {
                            type: "error",
                            message: "Error occured while fetching channels list"
                        }
                    });
                }
            })
            .catch(err => {
                handleError(dispatch, err)
            });
    };
}

export const EMDoGetListOfMembersOfOtherOrganizationAction =(action)=>{
    return dispatch => {
        API.EMDoGetListOfMembersOfOtherOrganization(action.limit)
            .then(data => data.data)
            .then(response => {
                if (response) {
                    dispatch({
                        type: OTHER_NETWORKS_MEMBERS,
                        payload: {response:response, limit:action.limit}
                    });
                    dispatch({
                        type:DISABLE_ENABLE_FILTER,
                        payload:false
                      })
                      dispatch({
                        type:DISABLE_ENABLE_SEARCH_OTHERS,
                        payload:false
                      })
                } else {
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: {
                            type: "error",
                            message: "Error occured while fetching members"
                        }
                    });
                }
            })
            .catch(err => {
                handleError(dispatch, err)
            });
    };
}

// get members of other organization by search
export const EMDoGetListOfMembersOfOtherOrganizationBySearchAction =(action)=>{
    return dispatch => {
        API.EMDoGetListOfMembersOfOtherOrganizationBySearch(action.searchText, action.limit)
            .then(data => data.data)
            .then(response => {
                
                if (response) {
                    dispatch({
                        type: OTHER_NETWORKS_MEMBERS,
                        payload: {response:response, limit:action.limit}
                    });
                    dispatch({
                        type:DISABLE_ENABLE_FILTER,
                        payload:false
                      })
                      dispatch({
                        type:DISABLE_ENABLE_SEARCH_OTHERS,
                        payload:false
                      })
                } else {
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: {
                            type: "error",
                            message: "Error occured while fetching members"
                        }
                    });
                }
            })
            .catch(err => {
                handleError(dispatch, err)
            });
    };
}
//get filtered members using organization
export const EMDoGetFilteredMembersWithPartenershipsAndOrganizationsAction = action => {
    return dispatch => {
        API.EMDoGetFilteredMembersWithPartenershipsAndOrganizations(action.action, action.limit)
        .then(data => data.data)
        .then(response => {
            if (response) {
                dispatch({
                    type: OTHER_NETWORKS_MEMBERS,
                    payload: {response:response, limit:action.limit}
                });
                dispatch({
                    type:DISABLE_ENABLE_FILTER,
                    payload:false
                  })
                  dispatch({
                    type:DISABLE_ENABLE_SEARCH_OTHERS,
                    payload:false
                  })
            } else {
                dispatch({
                    type: SHOW_MESSAGE,
                    payload: {
                        type: "error",
                        message: "Error occured while fetching filtered members"
                    }
                });
            }  
        })
        .catch(err => {
            handleError(dispatch, err)
        });
    };
};

export const EMDoGetUserLevelsAndBadgesAction = action => {
    return dispatch => {
        API.EMDoGetUserLevelsAndBadges(action.userId)
            .then(data => data.data)
            .then(response => {
                if (response) {
                    dispatch({
                        type: GET_USER_LEVEL_AND_BADGES,
                        payload: response
                    });
                } else {
                    dispatch({
                        type: SHOW_MESSAGE,
                        payload: {
                            type: "error",
                            message: "Error while fetching user levels"
                        }
                    });
                }
            })
            .catch(err => {
                handleError(dispatch, err)
            });
    };
};