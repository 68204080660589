import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Box,
  Button,
  makeStyles,
  Hidden,
} from "@material-ui/core";
import EMPage from "../../../../../components/commans/EMPage";
import EMRolesPermissionsTable from "./EMRolesPermissionsTable";
import EMAddEditRole from "./EMAddEditRole";
import EMDropDown from "../../../../../components/commans/EMDropdown";
import EMEntityDropdown from "../../commons/EMEntityDropdown";
import { useSelector, useDispatch } from "react-redux";
import { find, isEmpty, findIndex } from "lodash";
import { EMDoGetListOfNetworksForDropdownAction, EMDoGetPermissionSpecificToNetworkAction, EMDoListRolesForSelectedEntityAction, EMDoRetrievePermissionsForRoleAction } from "../../../../../redux/actions/EMNetworkAdminActions";
import { EMDoGetPermissionForEntityAction } from "../../../../../redux/actions/EMCommonActions";
import { EMDoGetEntityInformationBasedOnPermissionAction } from "../../../../../redux/actions/EMAuthActions";
const useStyles = makeStyles((theme) => ({
  justifyEnd: {
    [theme.breakpoints.up("md")]: {
      justifyContent: "end",
    },
    [theme.breakpoints.up("lg")]: {
      justifyContent: "end",
    },
  },
}));
export default function NetworkAdminManagement(props) {
  const classes = useStyles();
  const [filter, setFilter] = useState("");
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [entity, setEntity] = React.useState();
  const [editData, setEditData] = React.useState({});
  const [labels, setLabels] = React.useState({ title: "", buttonLabel: "" });
  const [showNetworkError, setShowNetworkError] = React.useState();
  const [isError, setIsError] = React.useState(true);
  const userData = useSelector((state) => state.auth);
  const networkSpecificPermissions = useSelector(state => state.networkAdmin.networkSpecificRolesAndPermissions)
  const [permissionData, setPermissionData] = React.useState();

  const handleClose = () => {
    setOpen(false);
  };

  const uiData = useSelector((state) => state.ui);

  useEffect(() => {
    
    if (uiData["messages"]) {
      let refObj = find(uiData["messages"], {
        key: "role_permission_add_edit_success",
      });
      if (refObj && refObj.type === "success") {
        refObj.key = "role_permission_add_edit_success_done";
        setOpen(false);
      }
    }
  }, [uiData]);

  useEffect(()=>{
    if(networkSpecificPermissions && entity){
      let permission = find(networkSpecificPermissions,{context:entity.context})
      let specificPermission = find(permission && permission.rolePermissions,{permission_name:"manage_roles_and_permissions"})
      setPermissionData(specificPermission)
    }
  },[networkSpecificPermissions])

  let disabledValue = (permissionData && permissionData["add_flag"]) || true;

  //call dropdown Api
  useEffect(() => {
    dispatch(EMDoGetListOfNetworksForDropdownAction());
  }, []);

  useEffect(() => {
    if(entity){
      dispatch(EMDoGetPermissionSpecificToNetworkAction({id:entity.context, networkType:entity.entity_type}))
    }
  },[entity])

  useEffect(() => {
    if (!isEmpty(entity)) {
      networkSelectionHandler();
      let entityInfo = entity.entity_type;
      dispatch(EMDoGetPermissionForEntityAction(entityInfo));
    }
  }, [entity]);

  const networkSelectionHandler = () => {
    if (isEmpty(entity)) {
      setShowNetworkError("Please Select Network Here");
      setIsError(false);
    } else {
      setShowNetworkError("");
      setIsError(true);
    }
  };

  return (
    <EMPage title="Manage Roles And Permissions">
      <Box>
        <Paper elevation={2}>
          <Box height="100%" display="flex" flexDirection="column">
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              p={3}
              justifyItems="center"
              alignItems="center"
            >
              <Grid container spacing={40} style={{ maxWidth: "100%" }}>
                <Grid justify="flex-start" item xs={12} sm={12} md={4} lg={4}>
                  <EMEntityDropdown
                    // data ={networkInfo}
                    errorText={showNetworkError}
                    onSelect={(data) => {
                      setEntity(data);
                    }}
                  />
                </Grid>
                <Hidden smDown>
                  <Grid
                    container
                    className={classes.justifyEnd}
                    item
                    xs={12}
                    sm={12}
                    md={8}
                    lg={8}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={
                        !( disabledValue)
                      }
                      onClick={() => {
                        networkSelectionHandler();
                        if (!isEmpty(entity)) {
                          setOpen(true);
                          setEditData();
                          setEdit(false);
                          setLabels({
                            title: "Add A New Role",
                            buttonLabel: "Save",
                          });
                          let entityInfo = entity.entity_type;
                          dispatch(
                            EMDoGetPermissionForEntityAction(entityInfo)
                          );
                        }
                      }}
                    >
                      {"Add New Role"}
                    </Button>
                  </Grid>
                </Hidden>
                <Hidden lgUp mdUp>
                  <Grid
                    container
                    className={classes.justifyEnd}
                    item
                    xs={12}
                    sm={12}
                    md={8}
                    lg={8}
                  >
                    <Button
                      style={{ marginTop: "2rem" }}
                      variant="contained"
                      color="primary"
                      disabled={
                        !( disabledValue)
                      }
                      onClick={() => {
                        networkSelectionHandler();
                        if (!isEmpty(entity)) {
                          setOpen(true);
                          setEditData();
                          setEdit(false);
                          setLabels({
                            title: "Add A New Role",
                            buttonLabel: "Save",
                          });
                          let entityInfo = entity.entity_type;
                          dispatch(
                            EMDoGetPermissionForEntityAction(entityInfo)
                          );
                        }
                      }}
                    >
                      {"Add New Role"}
                    </Button>
                  </Grid>
                </Hidden>
              </Grid>
              <Box>
                <EMAddEditRole
                  open={open}
                  close={handleClose}
                  entity={entity}
                  roleData={editData}
                  labels={labels}
                  closeSetter={setOpen}
                  isEdit={edit}
                />
              </Box>
            </Box>
            <EMRolesPermissionsTable
              entity={entity}
              onEdit={(roleData) => {
                let contextData = {
                  entity: entity.entity_type,
                  id: entity.context,
                  roleId: roleData.id,
                };

                dispatch(EMDoRetrievePermissionsForRoleAction({ contextData }));
                setEdit(true);
                setEditData(roleData);
                setOpen(true);
                setLabels({ title: "Edit A Role", buttonLabel: "Update" });
              }}
            />
          </Box>
        </Paper>
      </Box>
    </EMPage>
  );
}
