import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { CircularProgress, ThemeProvider } from "@material-ui/core";
import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import EMGlobalStyle from "../src/components/styles/EMGlobalStyles";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import EMTheme from "./theme";
import EMStore from "./redux/stores";
import "./assets/GothamBook.ttf";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={EMStore}>
      <ThemeProvider theme={EMTheme}>
        <EMGlobalStyle />
        <HashRouter basename="/">
          <App />
        </HashRouter>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
