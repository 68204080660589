import React from "react";
import {
  Paper,
  Box,
  Button,
  Modal,
  Typography,
  IconButton,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import {
  EMSimpleTextFieldView,
  EMChipTextFieldView,
} from "../../../components/EMTextFieldView";
import { CloseRounded } from "@material-ui/icons";
import { useFormik, Field } from "formik";
import * as yup from "yup";
import ChipInput from "material-ui-chip-input";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { findIndex, filter, isEmpty, difference } from "lodash";
import {
  EMDoCreateNetworkAdminChannelAction,
  EMDoUpdateNetworkAdminChannelAction,
  EMDoGetGroupListDropDownAction,
} from "../../../redux/actions/EMNetworkAdminActions";
import PrivacyDropdown from "../UserManagement/commons/EMPrivacyDropdown";
import GroupDropdown from "../UserManagement/commons/EMGroupDropdown";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("xs")]: {
      backgroundColor: theme.palette.common.white,
      height: "auto",
      minWidth: "50%",
      margin: "auto",
      padding: "0px",
      // maxWidth: '100vw',
      maxHeight: "100%",
      // position: 'fixed',
      marginTop: "22rem",
      transform: "translate(0, -50%)",
      width: "70%",
      marginLeft: "5rem",
    },
    [theme.breakpoints.up("sm")]: {
      backgroundColor: theme.palette.common.white,
      height: "auto",
      minWidth: "50%",
      margin: "auto",
      padding: "0px",
      // maxWidth: '100vw',
      maxHeight: "100%",
      position: "fixed",
      top: "50%",
      transform: "translate(0, -50%)",
      width: "50%",
    },
    [theme.breakpoints.up("md")]: {
      backgroundColor: theme.palette.common.white,
      height: "auto",
      minWidth: "50%",
      margin: "auto",
      padding: "0px",
      // maxWidth: '100vw',
      maxHeight: "100%",
      position: "fixed",
      top: "50%",
      transform: "translate(0, -50%)",
      width: "50%",
    },
    [theme.breakpoints.up("lg")]: {
      backgroundColor: theme.palette.common.white,
      height: "auto",
      minWidth: "50%",
      margin: "auto",
      padding: "0px",
      // maxWidth: '100vw',
      maxHeight: "100%",
      position: "fixed",
      top: "50%",
      transform: "translate(0, -50%)",
      width: "50%",
    },
  },
  title: {
    margin: 10,
    flexGrow: 1,
    fontWeight: "bold",
    color: "white",
    fontSize: "large",
  },
  form: {
    padding: "0px 36px",
    width: "100%",
  },
  buttonHolder: {
    padding: theme.spacing(3),
  },
}));

export default function EMAddEditChannel(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [localEntity, setLocalEntity] = React.useState(-1);
  const [privacyType, setPrivacyType] = React.useState(-1);
  useEffect(
    () => {
      let query = [];
      if (props.entity && props.entity.context_type != undefined) {
        query.push(
          `context_type=${props.entity.context_type}`,
          `context=${props.entity.context}`
        );
        dispatch(
          EMDoGetGroupListDropDownAction({ queryData: `?${query.join("&")}` })
        );
      }
      setLocalEntity(props.entity);

      return () => {
        formik.resetForm();
      };
    },
    [props.entity],
    [props.open]
  );

  const validationSchema = yup.object({
    channel_name: yup
      .string("Enter Channel Name")
      .trim()
      .required("Channel name is required"),
    admin_emails: yup
      .array()
      .transform(function (value, originalValue) {
        if (this.isType(value) && value !== null) {
          return value;
        }
        return originalValue ? originalValue.split(/[\s,]+/) : [];
      })
      .of(yup.string().email(({ value }) => `${value} is not a valid email`))
      .min(1, "Atleast 1 admin email is  required")
      .required("Admin email is requreid"),
    privacy_type: yup.string().required("Select Privacy Type"),
    group: yup.string().required("Select Group Name"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      channel_name: props.channelData["channel_name"] || "",
      admin_emails: props.channelData["users"]
        ? props.channelData["users"].map((x) => x["email"])
        : [],
      privacy_type: props.channelData["privacy_type"] || "",
      group: props.channelData["group"] || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      props.setDisabled(true);
      if (props.channelData && props.channelData.id > 0) {
        let deleted_admin_emails = difference(
          filter(props.channelData["users"], {
            role: { role_name: "Admin" },
          }).map((x) => x["email"]),
          formik.values.admin_emails
        );
        let new_admin_emails = difference(
          formik.values.admin_emails,
          filter(props.channelData["users"], {
            role: { role_name: "Admin" },
          }).map((x) => x["email"])
        );
        let id = props.channelData.id;
        let channel_name = props.channelData.channel_name;
        let newChannelData = {
          id,
          channel_name,
          ...props,
          ...values,
          deleted_admin_emails,
          new_admin_emails,
        };
        let data =
          "?limit=15&context_type=" +
          props.entity.context_type +
          "&context=" +
          props.entity.context;
        dispatch(
          EMDoUpdateNetworkAdminChannelAction({
            ...newChannelData,
            data,
            key: "channel_add_edit",
          })
        );
      } else {
        let data =
          "?limit=15&context_type=" +
          props.entity.context_type +
          "&context=" +
          props.entity.context;
        let newChannelData = {
          ...values,
        };
        dispatch(
          EMDoCreateNetworkAdminChannelAction({
            ...newChannelData,
            data,
            key: "channel_add_edit",
          })
        );
      }
    },
  });
  const data = useSelector((state) => state.networkAdmin["groupDropdownList"]);
  //reset the form after modal is closed
  useEffect(() => {
    return () => {
      formik.resetForm();
    };
  }, [props.open]);

  return (
    <Modal
      open={props.open}
      onClose={props.close}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box className={classes.root} style={{ outline: "none" }}>
        <form onSubmit={formik.handleSubmit}>
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            style={{
              padding: "0px 4px 0px 18px",
              backgroundColor: "#2F92D6",
              height: "auto",
            }}
          >
            <Typography className={classes.title}>{props.pagelabel}</Typography>
            <IconButton onClick={props.close}>
              <CloseRounded style={{color:"white"}} />
            </IconButton>
          </Box>
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="row"
            className={classes.form}
            style={{paddingTop:"16px"}}
          >
            <EMSimpleTextFieldView
              label={"Channel Name"}
              id="channel_name"
              name="channel_name"
              value={formik.values.channel_name}
              onChange={formik.handleChange}
              fullwidth
              // error={
              // formik.touched.channel_name && Boolean(formik.errors.channel_name)
              // }
              helperText={
                formik.touched.channel_name && formik.errors.channel_name
              }
            />
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="row"
            spacing={2}
            className={classes.form}
          >
            <Grid item xs>
              <ChipInput
                //style= {{fontFamily: "Arial" }}
                name="admin_emails"
                id="admin_emails"
                type="email"
                label="Admin E-mail Address"
                helperText={
                  formik.errors.admin_emails && formik.touched.admin_emails
                    ? formik.errors.admin_emails
                    : ""
                }
                value={formik.values.admin_emails}
                InputLabelProps={{ style: { color: "#4d4d4d" } }}
                FormHelperTextProps={{ style: { color: "red" } }}
                //InputProps={{ style: {fontFamily: "Arial" } }}
                // error={
                //     formik.errors.admin_emails && formik.touched.admin_emails
                //     ? true
                //     : false
                // }
                margin="none"
                onAdd={(chip) => {
                  if (chip) {
                    let oldvalues = [...formik.values.admin_emails];
                    let index = findIndex(oldvalues, chip);
                    if (index === -1) {
                      oldvalues.push(chip.toLowerCase());
                      formik.setFieldValue("admin_emails", oldvalues);
                    }
                  }
                }}
                onDelete={(chip) => {
                  let oldValues = [...formik.values.admin_emails];
                  let index = oldValues.indexOf(chip);
                  if (index > -1) {
                    oldValues.splice(index, 1);
                    formik.setFieldValue("admin_emails", oldValues);
                  }
                }}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid xs={12}>
            <Grid item xs={12} sm={12} md={6} className={classes.form}>
              <PrivacyDropdown
                name="privacy_type"
                onSelect={(id) => {
                  formik.setFieldValue("privacy_type", id);
                }}
                initialValue={formik.values.privacy_type}
                // error={
                //     formik.touched.privacy_type && Boolean(formik.errors.privacy_type)
                //     }
                helperText={
                  formik.touched.privacy_type && formik.errors.privacy_type
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} className={classes.form}>
              <GroupDropdown
                name="group"
                isDisabled={isEmpty(props.channelData) ? false : true}
                onSelect={(id) => {
                  formik.setFieldValue("group", id);
                }}
                fullWidth
                initialValue={formik.values.group}
                // error={
                //     formik.touched.group && Boolean(formik.errors.group)
                //     }
                helperText={formik.touched.group && formik.errors.group}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="row"
            className={classes.buttonHolder}
            spacing={4}
          >
            <Grid item>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                type="submit"
                disabled={props.saveClicked}
              >
                <Typography variant="h7">{props.buttonLabel}</Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.button}
                variant="outlined"
                color="secondary"
                onClick={props.close}
              >
                <Typography variant="h7">Discard</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
}
