import {
  Box,
  Button,
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import clsx from "clsx";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
import { Forum, Navigation } from "@material-ui/icons";
import {
  NAVIGATE_BETWEEN_DIFFERENT_PAGES,
  SAVE_GLOBAL_SEARCH,
} from "../../redux/constants/UserPlatform/EMNavigationConstant";
import { POST_DOCUMENT, POST_EXTERNAL_VIDEO_UPLOAD, POST_IMAGES, POST_VIDEOS, USER_DOCUMENT_UPLOAD, USER_EXTERNAL_VIDEO_UPLOAD, USER_IMAGES_UPLOAD, USER_VIDEOS_UPLOAD } from "../../redux/constants/UserPlatform";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.common.grey,
  },
  icon: {},
  root: {
    paddingLeft: "2%",
    height: "50px",
    maxHeight: "50px",
    position: "fixed",
    opacity: 5,
    backgroundColor: "#f2f2f2",
    zIndex: 2,
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);",
    marginTop: 64,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    overflowX: "auto",
    width:"100%"
  },
  active: {
    "& $icon": {
      color: theme.palette.common.white,
      borderBottom: "solid",
      borderBottomColor: "rgb(47, 146, 214)",
      borderBottomWidth: 3,
    },
  },
}));

export default function EMSideBar({ items }) {
  const data = useSelector((state) => state.navigation || {});
  const cssClasses = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch({
      type: NAVIGATE_BETWEEN_DIFFERENT_PAGES,
      payload: {
        href: location.pathname,
      },
    });
    dispatch({ type: USER_IMAGES_UPLOAD, payload: null });
    dispatch({ type: USER_VIDEOS_UPLOAD, payload: null });
    dispatch({ type: USER_DOCUMENT_UPLOAD, payload: null });
    dispatch({ type: USER_EXTERNAL_VIDEO_UPLOAD, payload: null });
    dispatch({
      type: POST_IMAGES,
      payload: undefined,
    });
    dispatch({
      type: POST_VIDEOS,
      payload: undefined,
    });
    dispatch({
      type: POST_DOCUMENT,
      payload: undefined,
    });
    dispatch({
      type: POST_EXTERNAL_VIDEO_UPLOAD,
      payload: undefined,
    });
  }, [location]);

  return (
    <Box className={cssClasses.root}>
      {data.items.slice(0, 8).map((item) => (
        <Grid>
          <Tooltip
            placement="top"
            title={<h2 style={{ color: "white" }}>{item.title}</h2>}
          >
            <Button
              activeClassName={
                item.isActive ? cssClasses.active : cssClasses.button
              }
              className={cssClasses.button}
              component={RouterLink}
              to={item.href}
              onClick={() => {
                dispatch({
                  type: SAVE_GLOBAL_SEARCH,
                  payload: "",
                });
                dispatch({
                  type: NAVIGATE_BETWEEN_DIFFERENT_PAGES,
                  payload: {
                    href: location.pathname,
                  },
                });
              }}
            >
              {
                item.icon && (
                  // <ListItemIcon >

                  <item.icon className={cssClasses.icon} />
                )

                // </ListItemIcon>
              }
            </Button>
          </Tooltip>
        </Grid>
      ))}
    </Box>
  );
}
