import { SHOW_MESSAGE } from "../../../constants";
import {
  CHANNEL_PREVIEW_LIST_SUCCESS,
  LIST_USER_POSTS,
  LIKE_DISLIKE_PIN_UNPIN_POSTS,
  LIST_SHARE_WITH_GROUP,
  LIST_USER_POSTS_CATEGORY_SELECTED,
  LIST_HASHTAG,
  LIST_MENTIONS,
  POST_CATEGORY_DATA,
  SUBMIT_NEW_COMMENT,
  DELETE_POST,
  LIKE_DISLIKE_POSTS,
  DELETE_POST_COMMENT,
  SHOW_POST_EDIT,
  GET_MENTIONS_FOR_POST,
  SUBMIT_POLL,
  SUBMIT_OPEN_POLL,
  SHOW_CREATE_POST,
  GET_USER_POINTS,
  GET_LEADERBOARD,
  SUBMIT_QUIZ,
  POST_IMAGES,
  POST_VIDEOS,
  POST_DOCUMENT,
  POST_EXTERNAL_VIDEO_UPLOAD,
  USER_IMAGES_UPLOAD,
  USER_VIDEOS_UPLOAD,
  USER_DOCUMENT_UPLOAD,
  USER_EXTERNAL_VIDEO_UPLOAD,
  CHANGE_LIKE_BUTTON_STATE,
} from "../../../constants/UserPlatform/index";
import { EMDoGetGroupPostsAction } from "../Groups/EMGroupsAction";
import API from "../../../../api";
import {
  EMDoGetEventsByMeAction,
  EMDoGetMyAllEventsAction,
  EMDoGetPastEventsAction,
  EMDoGetUpcomingEventsAction,
} from "../Events";
import { handleError } from "../../../../utils/EMCommonFunctions";



//action for Channel View
export const EMDoGetChannelPreviewListAction = (action) => {
  return (dispatch) => {
    API.EMDoGetChannelPreviewList(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: CHANNEL_PREVIEW_LIST_SUCCESS,
            payload: response,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching channels list",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

//action for listing posts
export const EMDoGetPostsListAction = (action) => {
  return (dispatch) => {
    API.EMDoGetPostsList(action.nextPost)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: LIST_USER_POSTS,
            payload: {
              response: response,
              userData: action.userData,
              page: action.nextPost,
            },
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching channels list",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};
//action for Post pin unpin like dislike
export const EMDoLikeDislikePinUnpinPostAction = (action) => {
  return (dispatch) => {
    API.EMDoLikeDislikePinUnpinPost(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: LIKE_DISLIKE_PIN_UNPIN_POSTS,
            payload: response,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Action Failed, Try Again",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoLikeDislikePostAction = (action) => {
  return (dispatch) => {
    API.EMDoLikeDislikePinUnpinPost(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {

          dispatch({
            type: LIKE_DISLIKE_POSTS,
            payload: response,
          });
          dispatch({
            type:CHANGE_LIKE_BUTTON_STATE,
            payload:{
              id:action.queryData.id,
              isDisabled:false
            }
          })
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Action Failed, Try Again",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

//action for category filtered post list
export const EMDoGetCategoryFilteredPostListAction = (action) => {
  return (dispatch) => {
    API.EMDoGetCategoryFilteredPostList(action.selectedDataOption, action.page)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: LIST_USER_POSTS,
            payload: {
              response: response,
              userData: action.userData,
              page: action.page,
            },
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching channels list",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};
//action for share with group list
export const EMDoGetPostSharedWithGroupsListAction = (action) => {
  return (dispatch) => {
    API.EMDoGetPostSharedWithGroupsList(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: LIST_SHARE_WITH_GROUP,
            payload: response,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching channels list",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

//action for share with group list
export const EMDoGetPollSharedWithGroupsListAction = (action) => {
  return (dispatch) => {
    API.EMDoGetPollSharedWithGroupsList(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: LIST_SHARE_WITH_GROUP,
            payload: response,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching channels list",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

//action for share with group list
export const EMDoGetEventSharedWithGroupsListAction = (action) => {
  return (dispatch) => {
    API.EMDoGetEventSharedWithGroupsList(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: LIST_SHARE_WITH_GROUP,
            payload: response,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching channels list",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

//action for share with group list
export const EMDoGetQuizSharedWithGroupsListAction = (action) => {
  return (dispatch) => {
    API.EMDoGetQuizSharedWithGroupsList(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: LIST_SHARE_WITH_GROUP,
            payload: response,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching channels list",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

//Mention people in post

export const EMDoMentionPeopleInPostAction = (action) => {
  return (dispatch) => {
    API.EMDoMentionPeopleInPost(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          // dispatch({
          //     type: SHOW_MESSAGE,
          //     payload: {
          //         type: "success",
          //         message: "User Tagged"
          //     }
          // });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while tagging the user",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoMentionPeopleInCommentAction = (action) => {
  return (dispatch) => {
    API.EMDoMentionPeopleInComment(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          // dispatch({
          //     type: SHOW_MESSAGE,
          //     payload: {
          //         type: "success",
          //         message: "User Tagged"
          //     }
          // });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while tagging the user",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

//Create new post action
export const EMDoCreateNewEventAction = (action) => {
  return (dispatch) => {
    API.EMDoCreateNewEvent(action.finalObject)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "New Event Created",
              key:"user_create_poll_post_event_quiz"
            },
          });
          dispatch({
            type: SHOW_CREATE_POST,
            payload: { value: false, route: "create_event" },
          })
          if (action && action.taggedUserId.length > 0) {
            let data = {
              user_ids: action.taggedUserId.toString(),
              post_id: response.id,
            };
            dispatch(EMDoMentionPeopleInPostAction(data));
          }
          dispatch(EMDoGetUpcomingEventsAction({ userData: action.userData }));
          dispatch({ type: USER_IMAGES_UPLOAD, payload: null });
          dispatch({ type: USER_VIDEOS_UPLOAD, payload: null });
          dispatch({ type: USER_DOCUMENT_UPLOAD, payload: null });
          dispatch({ type: USER_EXTERNAL_VIDEO_UPLOAD, payload: null });
        
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching channels list",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err, "post_error")
      });
  };
};

//Create new post action
export const EMDoCreateNewPostAction = (action) => {
  return (dispatch) => {
    API.EMDoCreateNewPost(action.finalObject)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "New Post Created",
              key:"user_create_poll_post_event_quiz"
            },
          });
          dispatch({
            type: SHOW_CREATE_POST,
            payload: { value: false, route: "create_post" },
          });
          if (action && action.taggedUserId.length > 0) {
            let data = {
              user_ids: action.taggedUserId.toString(),
              post_id: response.id,
            };
            dispatch(EMDoMentionPeopleInPostAction(data));
          }
          dispatch({ type: USER_IMAGES_UPLOAD, payload: null });
          dispatch({ type: USER_VIDEOS_UPLOAD, payload: null });
          dispatch({ type: USER_DOCUMENT_UPLOAD, payload: null });
          dispatch({ type: USER_EXTERNAL_VIDEO_UPLOAD, payload: null });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching channels list",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err, "post_error")
      });
  };
};

export const EMDoCreateNewPollAction = (action) => {
  return (dispatch) => {
    API.EMDoCreateNewPoll(action.finalObject)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "New Poll Created",
              key:"user_create_poll_post_event_quiz"
            },
          });
          dispatch({
            type: SHOW_CREATE_POST,
            payload: { value: false, route: "create_poll" },
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching posts",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err, "post_error")
      });
  };
};

export const EMDoCreateNewOpenPollAction = (action) => {
  return (dispatch) => {
    API.EMDoCreateNewOpenPoll(action.finalObject)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "New Poll Created",
              key:"user_create_poll_post_event_quiz"
            },
          });
          dispatch({
            type: SHOW_CREATE_POST,
            payload: { value: false, route: "create_poll" },
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching posts",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err, "post_error")

      });
  };
};

export const EMDoCreateQuizAction = (action) => {
  return (dispatch) => {
    API.EMDoCreateQuiz(action.finalObject)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: SHOW_CREATE_POST,
            payload: { value: false, route: "create_quiz" },
          });
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "New Quiz Created",
              key:"user_create_poll_post_event_quiz"
            },
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while creating quiz",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err, "post_error")
      });
  };
};

export const EMDoSubmitPoll = (action) => {
  return (dispatch) => {
    API.EMDoSubmitPoll(action.finalObject)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type:SUBMIT_POLL,
            payload: response
          })
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while submitting poll",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
}

export const EMDoSubmitQuizAction = (action) => {
  return (dispatch) => {
    API.EMDoSubmitQuiz(action.finalObject)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type:SUBMIT_QUIZ,
            payload: response
          })
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while submitting poll",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
}

export const EMDoSubmitOpenPollAction = (action) => {
  return (dispatch) => {
    API.EMDoSubmitOpenPoll(action.finalObject)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type:SUBMIT_OPEN_POLL,
            payload: response
          })
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while submitting poll",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
}

export const EMDoEditEventAction = (action) => {
  return (dispatch) => {
    API.EMDoEditEvent(action.finalObject)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Event Updated",
              key:"user_create_poll_post_event_quiz"
            },
          });
          dispatch(EMDoGetEventsByMeAction({ userData: action.userData }));
          dispatch(EMDoGetMyAllEventsAction({ userData: action.userData }));
          dispatch(EMDoGetUpcomingEventsAction({ userData: action.userData }));
          dispatch(EMDoGetPastEventsAction({ userData: action.userData }));
          // dispatch({
          //     type: ADD_NEW_POST,
          //     payload: {response :response,userData :action.userData}
          // });
        
          if (action && action.taggedUserId.length > 0) {
            let data = {
              user_ids: action.taggedUserId.toString(),
              post_id: response.id,
            };
            dispatch(EMDoMentionPeopleInPostAction(data));
          }
          if (action.post) {
            dispatch({
              type: SHOW_POST_EDIT,
              payload: {
                button: "Update Post",
                editPost: false,
                id: action.post.id,
                route: action.post.route,
                title: "Edit Post",
              },
            });
          }
          dispatch({ type: USER_IMAGES_UPLOAD, payload: null });
          dispatch({ type: USER_VIDEOS_UPLOAD, payload: null });
          dispatch({ type: USER_DOCUMENT_UPLOAD, payload: null });
          dispatch({ type: USER_EXTERNAL_VIDEO_UPLOAD, payload: null });
          dispatch({
            type: POST_IMAGES,
            payload: undefined,
          });
          dispatch({
            type: POST_VIDEOS,
            payload: undefined,
          });
          dispatch({
            type: POST_DOCUMENT,
            payload: undefined,
          });
          dispatch({
            type: POST_EXTERNAL_VIDEO_UPLOAD,
            payload: undefined,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching channels list",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err, "post_error")
      });
  };
};

export const EMDoEditPollAction = (action) => {
  return (dispatch) => {
    API.EMDoEditPoll(action.finalObject, action.Id)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Poll Updated",
              key:"user_create_poll_post_event_quiz"
            },
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while updating poll",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err, "post_error")
      });
  };
};

export const EMDoEditOpenPollAction = (action) => {
  return (dispatch) => {
    API.EMDoEditOpenPoll(action.finalObject, action.Id)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Poll Updated",
              key:"user_create_poll_post_event_quiz"
            },
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while updating poll",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err, "post_error")
      });
  };
};

export const EMDoEditQuizAction = (action) => {
  return (dispatch) => {
    API.EMDoEditQuiz(action.finalObject, action.Id)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Quiz Updated",
              key:"user_create_poll_post_event_quiz"
            },
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while updating poll",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err, "post_error")
      });
  };
};

//Edit post action
export const EMDoEditPostAction = (action) => {
  return (dispatch) => {
    API.EMDoEditPost(action.finalObject)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Post Updated",
              key:"user_create_poll_post_event_quiz"
            },
          });
          if (action && action.taggedUserId.length > 0) {
            let data = {
              user_ids: action.taggedUserId.toString(),
              post_id: response.id,
            };
            dispatch(EMDoMentionPeopleInPostAction(data));
          }
          dispatch({ type: USER_IMAGES_UPLOAD, payload: null });
          dispatch({ type: USER_VIDEOS_UPLOAD, payload: null });
          dispatch({ type: USER_DOCUMENT_UPLOAD, payload: null });
          dispatch({ type: USER_EXTERNAL_VIDEO_UPLOAD, payload: null });
          dispatch({
            type: POST_IMAGES,
            payload: undefined,
          });
          dispatch({
            type: POST_VIDEOS,
            payload: undefined,
          });
          dispatch({
            type: POST_DOCUMENT,
            payload: undefined,
          });
          dispatch({
            type: POST_EXTERNAL_VIDEO_UPLOAD,
            payload: undefined,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching channels list",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err, "post_error")
      });
  };
};
//Get HashTag List Action
export const EMDoGetHashTagListsAction = (action) => {
  return (dispatch) => {
    API.EMDoGetHashTagLists(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: LIST_HASHTAG,
            payload: response,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching channels list",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};
//Get People Mentions for Post
export const EMDoGetPeopleMentionForPostListAction = (action) => {
  return (dispatch) => {
    API.EMDoGetPeopleMentionForPostList(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: LIST_MENTIONS,
            payload: { response: response, id: action.postId },
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching channels list",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};
export const EMDoGetPeopleMentionForCreatePostListAction = (action) => {
  return (dispatch) => {
    API.EMDoGetPeopleMentionForCreatePostList(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: GET_MENTIONS_FOR_POST,
            payload: response,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching mention list",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

//Get Categories List
export const EMDoGetCategoryListAction = (action) => {
  return (dispatch) => {
    API.EMDoGetCategoryList(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: POST_CATEGORY_DATA,
            payload: response,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching channels list",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

//Add Comment to Post
export const EMDoSubmitCommentOnPostAction = (action) => {
  return (dispatch) => {
    ;
    API.EMDoSubmitCommentOnPost(action.finalData)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: SUBMIT_NEW_COMMENT,
            payload: { response: response, userData: action.userData },
          });
          if (action && action.tag.length > 0) {
            let data = {
              user_ids: action.tag.toString(),
              comment_id: response.id,
            };
            dispatch(EMDoMentionPeopleInCommentAction(data));
          }
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while adding comment",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};
//Delete Post Action
export const EMDoDeletePostAction = (action) => {
  return (dispatch) => {
    API.EMDoDeletePost(action)
      .then((data) => data.data)
      .then((response) => {
        if (response == "") {
          dispatch({
            type: DELETE_POST,
            payload: action.queryData.id,
          });
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: action.isEvent ? "Event Deleted" : "Post deleted",
            },
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Action Failed, Try Again",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)

      });
  };
};

export const EMDoDeletePollAction = (action) => {
  return (dispatch) => {
    API.EMDoDeletePoll(action)
      .then((data) => data.data)
      .then((response) => {
          dispatch({
            type: DELETE_POST,
            payload: action.queryData.id,
          });
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Poll deleted",
            },
          });
      })
      .catch((err) => {
        handleError(dispatch, err)

      });
  };
};

export const EMDoDeleteOpenPollAction = (action) => {
  return (dispatch) => {
    API.EMDoDeleteOpenPoll(action)
      .then((data) => data.data)
      .then((response) => {
          dispatch({
            type: DELETE_POST,
            payload: action.queryData.id,
          });
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Poll deleted",
            },
          });
      })
      .catch((err) => {
        handleError(dispatch, err)
 
      });
  };
};

export const EMDoDeleteQuizAction = (action) => {
  return (dispatch) => {
    API.EMDoDeleteQuiz(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: DELETE_POST,
            payload: action.queryData.id,
          });
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "success",
              message: "Quiz deleted",
            },
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Action Failed, Try Again",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      
      });
  };
};

//Delete Post Action
export const EMDoDeleteCommentFromPostAction = (action) => {
  return (dispatch) => {
    API.EMDoDeleteCommentFromPost(action)

      .then((data) => data.data)
      .then((response) => {
        if (response == "") {
          dispatch({
            type: DELETE_POST_COMMENT,
            payload: { postId: action.postId, commentId: action.commentId },
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Action Failed, Try Again",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoCreateNewHashTagAction = (action) => {
  return (dispatch) => {
    API.EMDoCreateNewHashTag(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch(EMDoGetHashTagListsAction());
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Action Failed, Try Again",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoMarkHighlightedChannelAsSeenAction = (action) => {
  return (dispatch) => {
    API.EMDoMarkHighlightedChannelAsSeen(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Action Failed, Try Again",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetUserPointsAction = (action) => {
  return (dispatch) => {
    API.EMDoGetUserPoints(action)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: GET_USER_POINTS,
            payload: response,
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching user points",
            },
          });
        }
      })
      .catch((err) => {
        handleError(dispatch, err)
      });
  };
};

export const EMDoGetLeaderBoardAction = (action) => {
  return (dispatch) => {
    API.EMDoGetLeaderBoard(action.organizationId, action.page)
      .then((data) => data.data)
      .then((response) => {
        if (response) {
          dispatch({
            type: GET_LEADERBOARD,
            payload: {response:response, page:action.page},
          });
        } else {
          dispatch({
            type: SHOW_MESSAGE,
            payload: {
              type: "error",
              message: "Error occured while fetching user points",
            },
          });
        }
      })
      .catch((err) => {
        // dispatch({
        //   type: SHOW_MESSAGE,
        //   payload: {
        //     type: "error",
        //     message:
        //       err.response && err.response.data && err.response.data.detail,
        //   },
        // });
        handleError(dispatch, err)
      });
  };
};


