import React, {useEffect} from 'react';
import EMPage from "../../../components/commans/EMPage";
import { useSelector, useDispatch } from "react-redux";
import { EMDoGetCategoryListAction, EMDoGetHashTagListsAction, EMDoGetPostSharedWithGroupsListAction, EMDoLikeDislikePinUnpinPostAction } from '../../../redux/actions/UserPlatform/Home/EMLatestPostPageAction';
import { EMDoGetPollsListAction } from '../../../redux/actions/UserPlatform/Poll/EMPollActions';
import EMQuiz from '../../../components/user/EMQuiz';
import { findLast } from 'lodash';
import { Grid, useMediaQuery} from "@material-ui/core";
import EMSimpleDatatable from "../../../components/datatables/EMSimpleDatatable";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import EMSideBar from '../../../components/navigation/EMSideBar';
import { EMDoGetMyQuizAction } from '../../../redux/actions/UserPlatform/Quiz/EMQuizActions';

const useStyles=makeStyles(theme=>({

  paper:{
    width:"70%",
    marginTop:"15%",
    marginLeft:"15%",
    marginRight:"2%",
  }
}));

function MyQuiz(props) {
  const userData = useSelector((state) => state.auth || {});
  const data = useSelector(state => state.myQuizzes)
  const dispatch = useDispatch();
  const uiData = useSelector((state) => state.ui || {});

  const classes=useStyles()



  useEffect(() => {
    if (uiData["messages"]) {
      let refObj = findLast(uiData["messages"], {
        key: "user_create_poll_post_event_quiz",
      });
      if (refObj && refObj.type === "success") {
        dispatch(EMDoGetMyQuizAction({ userData }));
        refObj.key = "user_create_poll_post_event_quiz_success"
      }
    }
  }, [uiData]);


  const theme = useTheme();

  const matchesMediumScreen = useMediaQuery(theme.breakpoints.up("md"));

  const matchesLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
 

  useEffect(() => {
    // dispatch(EMDoGetPostSharedWithGroupsListAction());
    dispatch(EMDoGetMyQuizAction({userData}))
    dispatch(EMDoGetPollsListAction({ userData }));
    dispatch(EMDoGetHashTagListsAction());
    dispatch(EMDoGetCategoryListAction());
  }, []);

    return (
        <EMPage
        title="My Quizzes"
        style={{
          width: "100%",
          paddingLeft: matchesLargeScreen  ? "8%" : "4%",
          paddingTop: matchesLargeScreen ? "4%" : matchesMediumScreen ? "6%" : "8%",
          paddingRight: matchesLargeScreen  ? "8%" : "4%",
          paddingBottom: "2%",
        }}
        >            <Grid container spacing={3}>
        {matchesMediumScreen ? (
              <Grid item xs={matchesLargeScreen ? 2 : 3}>
                <EMSideBar />
              </Grid>
            ) : null}
          <Grid item xs={matchesLargeScreen ? 10 : matchesMediumScreen ? 9 : 12}>
        {data.quizzes.map((post, id) =>
                     <EMQuiz
                     quizFromQuiz={true}
                     usedIn={
                      post.channel_details[0].channel_name.includes("MAIN_CHANNEL")
                        ? "polls"
                        : "channels"
                    }
                    id={
                      post.channel_details[0].channel_name.includes("MAIN_CHANNEL")
                        ? undefined
                        : post.channel_details[0].channel_id
                    }
                     permission={true}
                     post={post}
                     pinHandler={() => {
                       let actions = [];
                       post.isPinnedPost
                         ? actions.push(`unpin`)
                         : actions.push(`pin`);
                       let queryData = {
                         id: post.id,
                         action: actions[0],
                       };
                       dispatch(
                         EMDoLikeDislikePinUnpinPostAction({ queryData })
                       );
                     }}
                   />
                )
              }
              
        </Grid>
        </Grid>
              
        </EMPage>
    );
}




export default MyQuiz;