import { Box, IconButton, TableCell,Tooltip,Typography } from "@material-ui/core";
import EMSimpleDatatable from "../../../../components/datatables/EMSimpleDatatable";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import EMSwitch from "../../../../components/commans/EMSwitch";
import { EMDoListRolesForSelectedGroupAction,EMDoUpdateStatusOfRoleAction } from "../../../../redux/actions/EMGroupAdminActions";
import { find, isEmpty } from "lodash";
import { Edit } from "@material-ui/icons";
import { DATATABLE_DATE_FORMAT } from "../../../../utils/EMConstants";
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  typo: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 1,
    wordBreak: "break-word",
    overflow: "hidden",
  },
}));

export default function EMRolesPermissionsTable({ filter, onEdit, entity, permissionData }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const paginationData = useSelector(
    state => state.groupAdmin.roles ||{}
  );
  const [limit, setLimit] = useState(15);
  const [offset, setOffset] = useState(0);
  const [order, setOrder] = useState({ id: -1, direction: "asc" });
  const userData = useSelector(state=>state.auth)
  const uiData = useSelector((state) => state.ui);

  useEffect(() => {
    if (uiData["messages"]) {
      let refObj = find(uiData["messages"], {
        key: "role_permission_add_edit",
      });
      if (refObj && refObj.type === "success") {
        refObj.key = "role_permission_add_edit_success";
        const query = [];
        if (limit !== 0) {
          query.push(`limit=${limit}`);
        }
        if (filter) {
          query.push(`search=${filter}`);
        }
        if (offset !== 0) {
          query.push(`offset=${offset}`);
        }
        if (order.id !== -1) {
          query.push(
            `ordering=${order.direction === "desc" ? "-" : ""}${
              columns[order.id-1].field
            }`
          );
        }
        updateData("?" + query.join("&"));
      }
    }
  }, [uiData]);
  const classes = useStyles()

  useEffect(() => {
    if (filter && filter.length > 0) {
      setOffset(0);
    } else if (filter && filter == "") {
      setOffset(offset);
    }
  }, [filter]);

  const columns = [
    {
      title: "Role",
      field: "role_name",
      align: "left",
      render: data=> {
        return (
          <>
          <Tooltip title={<h2 style={{ color: "white" }}>{data["role_name"]}</h2>} placement='top'>
          <Typography className={classes.typo}>{ data["role_name"] && data["role_name"] }</Typography>
          </Tooltip>
          </>
        )
      }
    },

    {
      title: "Created On ",
      field: "created_on",
      align: "left",
      render: data=> {
        let date = data["created_on"];
        date = moment(date).format(DATATABLE_DATE_FORMAT);
        return(
          <Typography>{data["created_on"]? date : ""}</Typography>
        )
      }
    },
    {
      title: "Last Updated On",
      field: "updated_on",
      align: "left",
      render: data=> {
        let date = data["updated_on"];
        date = moment(date).format(DATATABLE_DATE_FORMAT);
        return(
          <Typography>{data["updated_on"]? date : ""}</Typography>
        )
      }
    },
    {
      title: "Updated By",
      field: "updated_by_profile",
      align: "left",
      render: data=> {
        return (
          <>
          <Tooltip title={<h2 style={{ color: "white" }}>{data["updated_by_profile"]}</h2>} placement='top'>
          <Typography className={classes.typo}>{ data["updated_by_profile"] && data["updated_by_profile"] }</Typography>
          </Tooltip>
          </>
        )
      }
    },
    {
      title: "Status",
      align: "left",
      customSort: (a, b) => a.is_active - b.is_active,
      render: data => {
        return (
          <EMSwitch
            status={data.is_active}
            isDisabled = {!(permissionData && permissionData["edit_flag"])}
            dialogText= {"Are you sure that you want to activate/deactivate the role?"}
            onChange={status=>{
              let contextData={
                entity : "group",
                entityId : entity,
                roleId :data.id,
                action : data.is_active ? "deactivate" :"activate"
              }
              
              dispatch(EMDoUpdateStatusOfRoleAction({contextData}))
            }}
          />
        );
      }
    },
    {
      title: "Action",
      align: "left",
      sorting : false,
      render: data => {
        return (
          <IconButton onClick={() => onEdit(data)} disabled = {!(permissionData && permissionData["edit_flag"])}>
            <Edit />
          </IconButton>
        );
      }
    }
  ];

  const updateData = query => {
    let data
    
    if(entity){
      data = {
        id : entity,
        entity : "group"
      }
      
      dispatch(EMDoListRolesForSelectedGroupAction({data,query}))
    }
  };

  useEffect(() => {
    updateData("?");
  }, []);

  useEffect(() => {
    const query = [];
    if (limit !== 0) {
      query.push(`limit=${limit}`);
    }
    if (filter) {
      query.push(`search=${filter}`);
    }
    if (offset !== 0) {
      query.push(`offset=${offset}`);
    }
    if (order.id !== -1) {
      query.push(
        `ordering=${order.direction === "desc" ? "-" : ""}${
          columns[order.id-1].field
        }`
      );
    }
    updateData("?" + query.join("&"));
  }, [limit, filter, offset, order, entity]);
  return (
    <Box p={2}>
      <EMSimpleDatatable
      filter={filter}
        columns={columns}
        data={paginationData["results"] || []}
        count={paginationData["count"]||0}
        updatePageAndPageSizeAndOrder={(page, pageSize, order) => {
          setOffset(page * limit);
          setLimit(pageSize);
          setOrder(order);
        }}
        options={{
          headerStyle: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.common.white,
            fontWeight: "bold"
          }
        }}
      />
    </Box>
  );
}
