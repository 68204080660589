import { Box, IconButton, Tooltip, Typography } from "@material-ui/core";
import EMSimpleDatatable from "../../../../components/datatables/EMSimpleDatatable";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import EMSwitch from "../../../../components/commans/EMSwitch";
import { Edit } from "@material-ui/icons";
import {
  EMDoGetListOfOrganizationLevelsAction,
  EMDoDeleteLevelInOrganizationAction
} from "../../../../redux/actions/EMNetworkAdminActions";
import { isEmpty } from "lodash";
import moment from "moment";
import { Delete, DeleteOutline } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  typo: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 1,
    wordBreak: "break-word",
    overflow: "hidden",
  },
}));

export default function EMLevelTable({ filter, onEdit, entity }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  let paginationData = useSelector((state) => state.networkAdmin.levels || []);
  const [limit, setLimit] = useState(15);
  const [offset, setOffset] = useState(0);
  const [order, setOrder] = useState({ id: -1, direction: "asc" });
  const [sortAscending, setSortAscending] = useState(false);
  const userData = useSelector((state) => state.auth);
  const uiData = useSelector((state) => state.ui);
  const classes = useStyles()

  const columns = [
    {
      title: "Level Name",
      field: "level",
      align: "left",
      render: (data) => {
        return (
          <>
            <Tooltip
              title={<h2 style={{ color: "white" }}>{data["level"]}</h2>}
              placement="top"
            >
              <Typography className={classes.typo}>
                {data["level"] &&  data["level"]}
              </Typography>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Created By",
      field: "name",
      render: (data) => {
        return (
          <>
            <Tooltip
              title={
                <h2 style={{ color: "white" }}>{data["created_by"]["name"]}</h2>
              }
              placement="top"
            >
              <Typography className={classes.typo}>
                {data["created_by"] && data["created_by"]["name"] && data["created_by"]["name"]}
              </Typography>
            </Tooltip>
          </>
        );
      },
      align: "left",
    },
    {
      title: "Created On",
      field: "created_on",
      render: (data) => {
        let date = data["created_on"];
        date = new Date(date);
        date = moment(date).format("MM/DD/YYYY");
        return <Typography>{data["created_on"] ? date : ""}</Typography>;
      },
      align: "left",
    },
    {
      title: "Action",
      align: "left",
      sorting: false,
      render: (data) => {
        return (
          <>
            <IconButton
              onClick={() => onEdit(data)}
              disabled={!userData.boolean_organization_manage_profile_page}
            >
              <Edit />
            </IconButton>
            <IconButton
              onClick={() => {
                let d = data;
                ;
                dispatch(
                  EMDoDeleteLevelInOrganizationAction({
                    data: {
                      organizationId: d.organization,
                      levelId: d.id,
                    },
                  })
                );
              }}
              disabled={!userData.boolean_organization_manage_profile_page}
            >
              <Delete />
            </IconButton>
          </>
        );
      },
    },
  ];

  const updateData = (query) => {
    if (query.indexOf("limit") >= 0 && !isEmpty(entity)) {
      dispatch(
        EMDoGetListOfOrganizationLevelsAction({
          organizationId: entity.context,
          queryData: query,
        })
      );
    }
  };
  useEffect(() => {
    updateData("?");
  }, []);

  useEffect(() => {
    const query = [];
    if (limit !== 0) {
      query.push(`limit=${limit}`);
    }

    if (offset !== 0 && !filter) {
      query.push(`offset=${offset}`);
    }
    if (order.id !== -1) {
      if (sortAscending === false) {
        query.push(
          `ordering=${order.direction === "desc" ? "-" : ""}${
            columns[order.id - 1].field
          }`
        );
        setSortAscending(true);
      } else {
        // order.direction = "desc"
        query.push(
          `ordering=${order.direction === "desc" ? "-" : ""}${
            columns[order.id - 1].field
          }`
        );
        setSortAscending(false);
      }
    }
    updateData("?" + query.join("&"));
  }, [limit, filter, offset, order, entity]);

  if (isEmpty(entity)) {
    paginationData = [];
  }
  return (
    <Box p={2}>
      {
        <EMSimpleDatatable
          columns={columns}
          data={paginationData["results"] || []}
          count={paginationData["count"] || 0}
          updatePageAndPageSizeAndOrder={(page, pageSize, order) => {
            setOffset(page * limit);
            setLimit(pageSize);
            setOrder(order);
          }}
          options={{
            headerStyle: {
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.common.white,
              fontWeight: "bold",
            },
          }}
        />
      }
    </Box>
  );
}
