import { Box, IconButton, Tooltip, Typography } from "@material-ui/core";
import EMSimpleDatatable from "../../../../components/datatables/EMSimpleDatatable";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import EMSwitch from "../../../../components/commans/EMSwitch";
import { Edit } from "@material-ui/icons";
import {EMDoGetEmailTemplateListAction,
  EMDoUpdateEmailTemplateStatusAction} from "../../../../redux/actions/EMNetworkAdminActions";
import {isEmpty} from 'lodash';

const useStyles = makeStyles((theme) => ({
  typo: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 1,
    wordBreak: "break-word",
    overflow: "hidden",
  },
}));

export default function EMEnailTemplateTable({ filter, onEdit, entity, permissionData }) {
    const theme = useTheme();
    const dispatch = useDispatch();
    let paginationData = useSelector(
        state => state.networkAdmin.emailTemplateList || []
    );
    const [limit, setLimit] = useState(15);
    const [offset, setOffset] = useState(0);
    const [order, setOrder] = useState({ id: -1, direction: "asc" });
    const [sortAscending, setSortAscending] = useState(false);
    const userData = useSelector(state=>state.auth)
    const uiData = useSelector((state) => state.ui);
    const classes = useStyles()

    useEffect(() => {
      if (filter && filter.length > 0) {
        setOffset(0);
      } else if (filter && filter == "") {
        setOffset(offset);
      }
    }, [filter]);

    const columns = [
        {
        title: "Template Name",
        field: "template_name",
        align: "left",
        render: data=> {
          return (
            <>
            <Tooltip title={<h2 style={{ color: "white" }}>{data["template_name"]}</h2>}  placement='top'>
            <Typography className={classes.typo}>{ data["template_name"] && data["template_name"] }</Typography>
            </Tooltip>
            </>
          )
        }
        },
        {
        title: "Email Type",
        field: "subject",
        align: "left",
        render: data=> {
          return (
            <>
            <Tooltip title={<h2 style={{ color: "white" }}>{data["subject"]}</h2>}  placement='top'>
            <Typography className={classes.typo}>{ data["subject"] && data["subject"] }</Typography>
            </Tooltip>
            </>
          )
        }
        },
        {
        title: "Status",
        align: "left",
        field: "is_active",
        render: eventData => {
            return (
            <EMSwitch
            status={eventData.is_active}
            isDisabled={!(permissionData && permissionData["edit_flag"])}
            dialogText= {"Are you sure that you want to activate/deactivate the email template?"}
            onChange={status => {
              
              let newUserData = { ...eventData };
              let data ={
                template_name : newUserData.template_name,
                is_active : status,
                context :newUserData.context,
                id :newUserData.id
              }
              let id = newUserData.id
              
              dispatch(EMDoUpdateEmailTemplateStatusAction({data, id}))
            }}
          />
            );
        }
        },
        {
        title: "Action",
        align: "left",
        sorting: false,
        render: data => {
            return (
                <IconButton onClick={() => onEdit(data)} disabled={!(permissionData && permissionData["edit_flag"])}>
                    <Edit />
                </IconButton>
                );
            }
        }
    ];    
    const updateData = query => {
      if(query.indexOf("limit")>=0 && query.indexOf("context")>=0){dispatch(EMDoGetEmailTemplateListAction(query));}    
      };
      useEffect(() => {
        updateData("?");
      }, []);
    
      useEffect(() => {
        const query = [];
        if (limit !== 0) {
          query.push(`limit=${limit}`);
        }
        if (filter) {
          query.push(`search=${filter}`);
        }
        if (!isEmpty(entity)) {
          if(entity && entity.context_type != undefined){
            query.push(
              `context_type=${entity.context_type}`,
              `context=${entity.context}`
            );
          }
        }
        if (offset !== 0) {
          query.push(`offset=${offset}`);
        }
        if (order.id !== -1) {
          if(sortAscending === false){
            query.push(
              `ordering=${order.direction === "desc" ? "-" : ""}${
                columns[order.id-1].field
              }`
            );
            setSortAscending(true)
          }else{
            // order.direction = "desc"
            query.push(
              `ordering=${order.direction === "desc" ? "-" : ""}${
                columns[order.id-1].field
              }`
            );
            setSortAscending(false)
          }
        }
        updateData("?" + query.join("&"));
      }, [limit, filter, offset, order, entity]);
      if(isEmpty(entity)){
        paginationData = []
      }
    return (
        <Box p={2}>
        {
            <EMSimpleDatatable
            filter={filter}
            columns={columns}
            data={paginationData["results"] || []}
            count={paginationData["count"] || 0}
            updatePageAndPageSizeAndOrder={(page, pageSize, order) => {
                setOffset(page * limit);
                setLimit(pageSize);
                setOrder(order)
            }}
            options={{
                headerStyle: {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.common.white,
                fontWeight: "bold"
                }
            }}
            />
        }
        </Box>
    );
    }
