import {
  AUTH_USER_PROFILE_ID,
  AUTH_LOGIN_SUCCESS,
  AUTH_MULTIPLE_PROFILE,
  AUTH_LOGOUT,
  AUTH_LOGGEDIN_USER_ROLE_PERMISSIONS,
  ENTITY_LIST_SUCCESS,
  CHANGE_PROFILE_PIC,
  GET_ROLES_BASED_ON_ENTITY,
  GET_COMMON_ADMIN_PANEL_PERMISSIONS,
  GET_COMMON_GLOBAL_PERMISSIONS,
} from "../constants";
import { cloneDeep } from "lodash";
import EMStorage from "../../utils/EMStorage";
import { findIndex, uniqBy } from "lodash";

const userData = localStorage.getItem("userData");

const emptyData = {
  access: "",
  refresh: "",
  username: "",
  email: "",
  is_active: false,
  error: false,
  error_message: "",
};

const anotherState = { ...JSON.parse(userData)}

EMStorage.storeUserData(anotherState);

export default function EMAuthReducer(state = anotherState, actions) {
  let newState = cloneDeep(state);
  switch (actions.type) {
    case AUTH_LOGIN_SUCCESS: {
      newState = { ...actions.payload };
      newState["multipleProfiles_selectedProfileId"] =
        actions.payload.profile_id;

      return { ...newState };
    }

    case AUTH_LOGOUT: {
      return { ...emptyData };
    }

    case AUTH_LOGGEDIN_USER_ROLE_PERMISSIONS: {
      newState["permissions_data"] = actions.payload;
      return { ...newState };
    }

    case ENTITY_LIST_SUCCESS: {
      let data = actions.payload;
      data = uniqBy(data, function (e) {
        return e.id;
      });
      let finalData = [];
      data.forEach((data) => {
        let dataOne = {
          context_type: data.context_type_id,
          entity: data.context_type_name,
          id: data.context,
          name: data.context_name,
          rolePermissions: data.rolePermissions,
        };
        finalData.push(dataOne);
      });
      newState["entityDetails"] = finalData;
      return { ...newState };
    }

    case AUTH_MULTIPLE_PROFILE: {
      newState["multipleProfiles"] = actions.payload;
      if (newState["multipleProfiles"]) {
        localStorage.setItem("userData", JSON.stringify(newState));
        EMStorage.storeUserData(newState);
      }
      return { ...newState };
    }

    case AUTH_USER_PROFILE_ID: {
      newState["multipleProfiles_selectedProfileId"] = actions.payload;
      return { ...newState };
    }

    case CHANGE_PROFILE_PIC: {
      newState["user_profile"] = actions.payload.profile_pic_file;
      newState["name"] =
        actions.payload.first_name + " " + actions.payload.last_name;
      return newState;
    }

    case GET_ROLES_BASED_ON_ENTITY: {
      newState["rolesAndPermissionsForSpecificEntity"] = actions.payload;
      return newState;
    }

    case GET_COMMON_ADMIN_PANEL_PERMISSIONS: {
      let data = actions.payload;
      let sourceObject = data;
      // create empty object to hold new properties
      let newObject = {};
      for (var key in sourceObject) {
        if (sourceObject.hasOwnProperty(key)) {
          // assign property to new object with modified key
          newObject["permission_for_" + key] = sourceObject[key];
        }
      }
      newState = {...newState, ...newObject}

      window.localStorage.setItem("userData", JSON.stringify(newState));
      EMStorage.storeUserData(newState);
      return newState;
    }

    case GET_COMMON_GLOBAL_PERMISSIONS: {
      let data = actions.payload;
      let requiredData = {...data, ...data.group, ...data.organization, ...data.partnership, ...data.channel}
      newState = {...newState, ...requiredData}
      window.localStorage.setItem("userData", JSON.stringify(newState));
      EMStorage.storeUserData(newState);
      return newState;
    }

    default: {
      return newState;
    }
  }
}
