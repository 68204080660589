import React, { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from 'clsx';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  makeStyles,
  IconButton,
  Typography
} from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';

import { useTheme } from "@material-ui/core/styles";

import { User as UserIcon, Navigation, Users } from "react-feather";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import EMNavItem from "./EMNavItem";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { ReactComponent as userIcon } from '../../../assets/userIcon.svg';
// import { ReactComponent as ChannelIcon } from '../../assets/ChannelIcon.svg';
import { ReactComponent as OrganisationIcon } from '../../../assets/GroupIcon.svg';
import { ReactComponent as NetworkIcon } from '../../../assets/NetworkIcon.svg';
import { ReactComponent as ChannelIcon } from '../../../assets/ChannelIcon.svg';
import { ReactComponent as GroupIcon } from '../../../assets/GroupIcon.svg';
import {ReactComponent as CollapseIcon} from "../../../assets/open-collapse-right.svg"
const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  mobileDrawer: {
    width: drawerWidth
  },
  desktopDrawer: {
    width: drawerWidth,
    top: 64,
    height: "calc(100% - 64px)"
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64
  },
  navigation: {
    textTransform: "uppercase",
    fontWeight: "bold",
    marginRight: theme.spacing(1)
  },
  navigationIcon: {
    marginRight: theme.spacing(2.3),
  },
  menuButton: {
    marginRight: 40,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    top: 64,
    height: "calc(100% - 64px)"
  },
  drawerOpen: {
    top: 64,
    height: "calc(100% - 64px)",
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    top: 64,
    height: "calc(100% - 64px)",
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(6) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(6) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  collapseButton: {
    display: 'flex',
    alignItems: 'right', 
  
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const NavBar = ({ onMobileClose, items,openMobile,open,onDrawerOpen,onDrawerClose }) => {
  const userData = useSelector(state => state.auth);
 
  const cssClasses = useStyles();
  const location = useLocation();
  const theme = useTheme();
  

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      
      <Box p={1}>
        {
          open ?
            <IconButton onClick={onDrawerClose} style = {{alignItems  : "right",height : 10, width : "100%"}}>
              {theme.direction === 'rtl' ? <CollapseIcon /> :<div className={cssClasses.collapseButton}><CollapseIcon /><Typography style = {{marginLeft:5, color :"#888A9F"}}>Collapse</Typography></div>}
            </IconButton>
            :
            <Hidden mdDown>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={onDrawerOpen}
              edge="start"
              className={clsx(cssClasses.menuButton, {
                [cssClasses.hide]: open,
              })}
            >
              <MenuIcon style = {{fill : "#ffffff"}}/>
            </IconButton>
            </Hidden> 
        }
      </Box>
      <Box alignItems="flex-start" flexDirection="row">
        <IconButton>
          <Navigation
            size={20}
            className={cssClasses.navigationIcon}
            color={theme.palette.primary.main}
          />
        </IconButton>
        <Typography
          variant="p"
          color="primary"
          className={cssClasses.navigation}
        >
          {"Navigation"}
        </Typography>
      </Box>
      <Box >
        <List>
          {items.map(item => (
            <EMNavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
    </Box>
  );

  window.addEventListener("resize", () => {
    let width = document.body.clientWidth;
    if (width <= 1199) { //Close Drawer when screen size medium
      onDrawerClose();
    }
  });

  return (
    <>
      <Hidden xlUp>
        <Drawer
          anchor="left"
          classes={{ paper: cssClasses.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden>
        <Drawer
          variant="permanent"
          className={clsx(cssClasses.drawer, {
            [cssClasses.drawerOpen]: open,
            [cssClasses.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [cssClasses.drawerOpen]: open,
              [cssClasses.drawerClose]: !open,
            }),
          }}
        >
         {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  open : PropTypes.bool,
  onDrawerOpen : PropTypes.func,
  onDrawerClose :PropTypes.func,
};

NavBar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export default NavBar;
