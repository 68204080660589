import {
  Box,
  IconButton,
  TableCell,
  Typography,
  Tooltip,
} from "@material-ui/core";
import EMSimpleDatatable from "../../../../../components/datatables/EMSimpleDatatable";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Edit, IndeterminateCheckBoxOutlined } from "@material-ui/icons";
import EMSwitch from "../../../../../components/commans/EMSwitch";
import {
  EMDOGetNetworkAdminUsersListAction,
  EMDoUpdateNetworkAdminAction,
} from "../../../../../redux/actions/EMNetworkAdminActions";
import { useSelector, useDispatch } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { isEmpty, findLast, set } from "lodash";
import moment from "moment";
import {
  EMDoGetOrganizationDepartmentsAction,
  EMDoGetOrganizationLevelsAction,
} from "../../../../../redux/actions/EMCommonActions";

const useStyles = makeStyles((theme) => ({
  typo: {
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 1,
    wordBreak: "break-word",
    overflow: "hidden",
  },
}));

export default function EMNetworkAdminManageUsers({
  filter,
  onEdit,
  permissionData,
  disableCondition,
  entity,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.networkAdmin.networkUsers || {});
  const userData = useSelector((state) => state.auth);
  const [limit, setLimit] = useState(15);
  const [offset, setOffset] = useState(0);
  const [order, setOrder] = useState({ id: -1, direction: "asc" });
  const [sortAscending, setSortAscending] = useState(false);
  const classes = useStyles();

  const uiData = useSelector((state) => state.ui);
  let loginUser = userData.email;
  const shouldUpdateForImportUsers = useSelector(
    (state) => state["networkAdmin"].updateUsersListForImportUsers || false
  );
  const shouldUpdateForResendInvites = useSelector(
    (state) => state["networkAdmin"].updateUsersListForResendInvites || false
  );

  useEffect(() => {
    if (filter && filter.length > 0) {
      setOffset(0);
    } else if (filter && filter == "") {
      setOffset(offset);
    }
  }, [filter]);

  const columns = [
    {
      title: "First Name",
      field: "first_name",
      align: "left",

      render: (data) => {
        return (
          <>
            <Tooltip
              title={<h2 style={{ color: "white" }}>{data["first_name"]}</h2>}
              placement="top"
            >
              <Typography className={classes.typo}>
                {data["first_name"] && data["first_name"]}
              </Typography>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Last Name",
      field: "last_name",
      align: "left",

      render: (data) => {
        return (
          <>
            <Tooltip
              title={<h2 style={{ color: "white" }}>{data["last_name"]}</h2>}
              placement="top"
            >
              <Typography className={classes.typo}>
                {data["last_name"] && data["last_name"]}
              </Typography>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Organization Name",
      field: "organization",
      align: "left",

      render: (data) => {
        return (
          <>
            <Tooltip
              title={<h2 style={{ color: "white" }}>{data["organization"]}</h2>}
              placement="top"
            >
              <Typography className={classes.typo}>
                {data["organization"] && data["organization"]}
              </Typography>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "E-mail Address ",
      field: "email",
      align: "left",

      render: (data) => {
        return (
          <>
            <Tooltip
              title={<h2 style={{ color: "white" }}>{data["email"]}</h2>}
              placement="top"
            >
              <Typography className={classes.typo}>
                {data["email"] && data["email"]}
              </Typography>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Status",
      align: "left",

      field: "is_active",
      render: (eventData) => {
        return (
          <EMSwitch
            status={eventData.is_active}
            dialogText={
              eventData.is_primary_user
                ? "This is primary account, are you sure that you want to activate/deactivate the user"
                : "Are you sure that you want to activate/deactivate the user?"
            }
            // isDisabled={eventData.email == loginUser || !(permissionData&& permissionData["edit_flag"])}
            isDisabled={
              eventData.email == loginUser ||
              !(permissionData && permissionData["edit_flag"]) ||
              entity.entity_type === "partnership"
                ? true
                : false
            }
            onChange={(status) => {
              let newUserData = { ...eventData };
              let statusData = String(status);
              statusData =
                statusData.charAt(0).toUpperCase() + statusData.slice(1);
              newUserData.is_active = status;
              delete newUserData.tableData;
              let data = {
                user_id: newUserData.id,
                ...newUserData,
              };
              let contextData = {
                contextType: entity.context_type,
                context: entity.context,
                limit: limit,
              };
              dispatch(EMDoUpdateNetworkAdminAction({ data, contextData }));
            }}
          />
        );
      },
    },
    {
      title: "Role",
      field: "role",
      align: "left",

      render: (data) => {
        return (
          <>
            <Tooltip
              title={<h2 style={{ color: "white" }}>{data["role_name"]}</h2>}
              placement="top"
            >
              <Typography className={classes.typo}>
                {data["role_name"] && data["role_name"]}
              </Typography>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Z-Points",
      field: "points",
      align: "left",

      sorting: false,
      render: (data) => {
        return (
          <>
            <Tooltip
              title={<h2 style={{ color: "white" }}>{data["points"]}</h2>}
              placement="top"
            >
              <Typography className={classes.typo}>
                {data["points"] && data["points"]}
              </Typography>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "SignUp Date",
      field: "signup_date",
      align: "left",

      sorting: true,
      render: (data) => {
        let date = data["signup_date"];
        date = new Date(date);
        date = moment(date).format("MM/DD/YYYY");
        return <Typography>{data["signup_date"] ? date : ""}</Typography>;
      },
    },
    {
      title: "Last Active",
      field: "last_active",
      align: "left",

      sorting: true,
      render: (data) => {
        let date = data["last_active"];
        date = new Date(date);
        date = moment(date).format("MM/DD/YYYY");
        return <Typography>{data["last_active"] ? date : ""}</Typography>;
      },
    },
    {
      title: "DeActivation Date",
      field: "deactivation_date",
      align: "left",

      sorting: true,
      render: (data) => {
        let date = data["deactivation_date"];
        date = new Date(date);
        date = moment(date).format("MM/DD/YYYY");
        return <Typography>{data["deactivation_date"] ? date : ""}</Typography>;
      },
    },
    {
      title: "Action",
      align: "left",

      sorting: false,
      render: (data) => {
        return (
          <IconButton
            onClick={() => {
              onEdit(data);
            }}
            disabled={
              !(permissionData && permissionData["edit_flag"]) ||
              entity.entity_type === "partnership"
                ? true
                : false
            }
          >
            <Edit />
          </IconButton>
        );
      },
    },
  ];

  const updateData = (query) => {
    if (query.indexOf("context_type") >= 0) {
      dispatch(EMDOGetNetworkAdminUsersListAction(query));
    }
  };

  useEffect(() => {
    updateData("?");
  }, []);
  
  useEffect(() => {
    
    const query = [];
    if (limit !== 0) {
      query.push(`limit=${limit}`);
    }
    if (filter) {
      query.push(`search=${filter}`);
    }
    if (!isEmpty(entity)) {
      query.push(
        `context_type=${entity.context_type}`,
        `context=${entity.context}`
      );
    }
    if (offset !== 0) {
      query.push(`offset=${offset}`);
    }
    if (order.id !== -1) {
      if (sortAscending === false) {
        // order.direction = "asc"
        query.push(
          `ordering=${order.direction === "desc" ? "-" : ""}${
            columns[order.id - 1].field
          }`
        );
        setSortAscending(true);
      } else {
        // order.direction = "desc"
        query.push(
          `ordering=${order.direction === "desc" ? "-" : ""}${
            columns[order.id - 1].field
          }`
        );
        setSortAscending(false);
      }
    }
    updateData("?" + query.join("&"));
  }, [limit, filter, offset, order, entity, shouldUpdateForImportUsers, shouldUpdateForResendInvites]);

  return (
    <Box p={2}>
      {
        <EMSimpleDatatable
          filter={filter}
          columns={columns}
          data={data["results"] || []}
          count={data["count"] || 0}
          updatePageAndPageSizeAndOrder={(page, pageSize, order) => {
            setOffset(page * limit);
            setLimit(pageSize);
            setOrder(order);
          }}
          options={{
            headerStyle: {
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.common.white,
              fontWeight: "bold",
            },
          }}
        />
      }
    </Box>
  );
}
