import EMDropdown from "../../../components/commans/EMDropdown";
import { useSelector } from "react-redux";
import { find } from "lodash";
import FormHelperText from "@material-ui/core/FormHelperText";

export default function EMChannelDropdown({ onSelect, name, error, helperText, initialValue}) {
  const data = useSelector(state => state.channelAdmin["channelListForUserManagement"]);

  let preValue = initialValue ? find(data,{context : initialValue}) : ''

  if(preValue){
    preValue  = preValue !== '' ? preValue["entity_name"] : ''
  }

  return (
    <>
    <EMDropdown
      label="Channel Name"
      data={data || []}
      objKey={"entity_name"}
      onChange={selected => {
        onSelect(find(data, { entity_name: selected }).context);
      }}
      prevData={preValue}
      name={name}
      error={error}
      helperText={helperText}
    />
    <FormHelperText style = {{color:'red'}}>
          {error}
          {helperText}
        </FormHelperText>
    </>
  );
}

