import React, { useState, useEffect, useCallback, useRef } from "react";
import EMPage from "../../../../components/commans/EMPage";
import { Box, Grid, useMediaQuery } from "@material-ui/core/";
import { useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router";
import EMPost from "../../../../components/user/EMPost";
import EMChannelsPreview from "../../../../components/user/EMChannelsPreview";
import EMDropdown from "../../../../components/user/EMCategoriesDropdown";
import Picker from "emoji-picker-react";
import {
  EMDoGetChannelPreviewListAction,
  EMDoGetPostSharedWithGroupsListAction,
  EMDoGetHashTagListsAction,
  EMDoGetCategoryListAction,
} from "../../../../redux/actions/UserPlatform/Home/EMLatestPostPageAction";
import {
  EMDoGetPinnedPostsListAction,
  EMDoGetCategoryFilterPinnedPostListAction,
  EMDoLikeDislikePinUnpinPinnedPostAction,
  EMDoLikeDislikePinnedPostAction,
} from "../../../../redux/actions/UserPlatform/Home/EMPinnedPostPageActions";
import {
  SHOW_NEXT_PINNED_POSTS,
  PINNED_POST_CATEGORY_SELECTED_DATA,
  CLEAR_POST_FROM_STATE,
  CLEAR_POST_FROM_STATE_FOR_EVERY_LOAD,
  CHANGE_LIKE_STATUS_OF_PINNED_POST,
  CHANGE_LIKE_BUTTON_STATE_OF_PINNED_POST,
} from "../../../../redux/constants/UserPlatform/index";
import EMEvent from "../../../../components/user/EMEvent";
import { debounce, findLast } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import EMSideBar from "../../../../components/navigation/EMSideBar";
import EMPoll from "../../../../components/user/EMPoll";
import EMQuiz from "../../../../components/user/EMQuiz";

export default function PinnedPost(props) {
  const theme = useTheme();

  const matchesMediumScreen = useMediaQuery(theme.breakpoints.up("md"));

  const matchesLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const dispatch = useDispatch();
  const data1 = useSelector((state) => state.pinnedPost || {});
  const data2 = useSelector((state) => state.home || {});
  const userData = useSelector((state) => state.auth || {});
  const [category, setCategory] = useState("");
  const [limit, setLimit] = useState(1);
  const [limitForCategories, setLimitForCategories] = useState(1);
  const categorySelected = useSelector(
    (state) => state.pinnedPost.categorySelected
  );
  const uiData = useSelector((state) => state.ui || {});
  const allCategories = useSelector((state) => state.pinnedPost.categories);
  const location = useLocation();
  useEffect(() => {
    dispatch({
      type: SHOW_NEXT_PINNED_POSTS,
      payload: {
        nextPosts: undefined,
      },
    });
    //dispatch(EMDoGetPinnedPostsListAction({ nextPost: limit, userData }));
    // dispatch(EMDoGetPostSharedWithGroupsListAction())
    dispatch(EMDoGetChannelPreviewListAction());
    dispatch(EMDoGetHashTagListsAction());
    dispatch(EMDoGetCategoryListAction());
    dispatch({ type: CLEAR_POST_FROM_STATE_FOR_EVERY_LOAD, payload: [] });
  }, []);

  useEffect(() => {
    if (uiData["messages"]) {
      let refObj = findLast(uiData["messages"], {
        key: "user_create_poll_post_event_quiz",
      });
      if (refObj && refObj.type === "success") {
        setLimit(1);
        dispatch(EMDoGetPinnedPostsListAction({ nextPost: limit, userData }));
        refObj.key = "user_create_poll_post_event_quiz_success";
      }
    }
  }, [uiData]);

  useEffect(() => {
    setCategory(data1.categorySelected);
  }, [data1.categorySelected]);

  const sendQuery = useCallback(async () => {
    if (typeof data1.categorySelected === "string") {
      dispatch(EMDoGetPinnedPostsListAction({ nextPost: limit, userData }));
    } else if (typeof data1.categorySelected !== "string") {
      dispatch(
        EMDoGetCategoryFilterPinnedPostListAction({
          selectedDataOption: data1.categorySelected.category_name,
          page: limitForCategories,
          userData,
        })
      );
    }
  }, [limit, limitForCategories]);

  useEffect(() => {
    if (typeof data1.categorySelected === "string") {
      sendQuery(limit);
    } else if (typeof data1.categorySelected !== "string") {
      sendQuery(limitForCategories);
    }
  }, [sendQuery, limit, limitForCategories]);

  const handleObserver = useCallback(() => {
    if (typeof data1.categorySelected === "string" && data1.next !== null) {
      setLimit((prev) => prev + 1);
    } else if (
      typeof data1.categorySelected !== "string" &&
      data1.next !== null
    ) {
      setLimitForCategories((prev) => prev + 1);
    }
  }, [limitForCategories, limit, data1.categorySelected, data1.next]);

  function prettyDate2(date) {
    var parts = date.split("-");
    var mydate = new Date(parts[0], parts[1] - 1, parts[2]);
    return mydate;
  }

  return (
    <InfiniteScroll
      dataLength={data1.pinnedPosts.length}
      hasMore={true}
      next={handleObserver}
    >
      <EMPage
        title="Pinned Posts"
        style={{
          width: "100%",
          paddingLeft: matchesLargeScreen ? "8%" : "4%",
          paddingTop: matchesLargeScreen
            ? "4%"
            : matchesMediumScreen
            ? "6%"
            : "8%",
          paddingRight: matchesLargeScreen ? "8%" : "4%",
          paddingBottom: "2%",
        }}
      >
        <Grid container direction="row" alignItems="flexStart" spacing={3}>
          {matchesMediumScreen ? (
            <Grid item xs={matchesLargeScreen ? 2 : 3}>
              <EMSideBar />
            </Grid>
          ) : null}
          <Grid
            item
            xs={matchesLargeScreen ? 7 : matchesMediumScreen ? 9 : 12}
            style={{ zIndex: 1 }}
          >
            <Grid
              container
              direction="row"
              // justify="center"
              alignItems="center"

              // style={{marginTop:"2%",marginLeft:"4%"}}
            >
              <Grid item md={8} sm={6} xs={4}>
                <hr />
              </Grid>
              <Grid item md={4} sm={6} xs={8}>
                <EMDropdown
                  handleChange={(event) => {
                    setLimitForCategories(1);
                    let selectedDataOption =
                      event.target.value == "Images & Videos"
                        ? "Media"
                        : event.target.value;
                    dispatch({
                      type: PINNED_POST_CATEGORY_SELECTED_DATA,
                      payload: selectedDataOption,
                    });

                    dispatch(
                      EMDoGetCategoryFilterPinnedPostListAction({
                        selectedDataOption: selectedDataOption,
                        page: limitForCategories,
                        userData,
                      })
                    );
                  }}
                  deleteHandler={(event) => {
                    let selectedDataOption =
                      event.target.value == "Images & Videos"
                        ? "Media"
                        : event.target.value;
                    dispatch({
                      type: PINNED_POST_CATEGORY_SELECTED_DATA,
                      payload: selectedDataOption,
                    });
                    dispatch({ type: CLEAR_POST_FROM_STATE, payload: [] });
                    setLimit(1);
                    dispatch(
                      EMDoGetPinnedPostsListAction({
                        nextPost: limit,
                        userData,
                      })
                    );
                  }}
                  selectedCategory={categorySelected}
                  selectedDataForDropdown={allCategories}
                />
              </Grid>
            </Grid>
            <Box style={{ marginTop: "2%" }}>
              {data1 &&
                data1.pinnedPosts &&
                data1.pinnedPosts.map((post, id) =>
                  post.is_event ? (
                    <EMEvent
                      permission={true}
                      post={post}
                      pinHandler={() => {
                        let actions = [];
                        post.isPinnedPost
                          ? actions.push(`unpin`)
                          : actions.push(`pin`);
                        let queryData = {
                          id: post.id,
                          action: actions[0],
                        };
                        dispatch(
                          EMDoLikeDislikePinUnpinPinnedPostAction({ queryData })
                        );
                      }}
                      likeHandler={() => {
                        let actions = [];
                        post.liked
                          ? actions.push(`dislike`)
                          : actions.push(`like`);
                        //post.liked = !post.liked;
                        let queryData = {
                          id: post.id,
                          action: actions[0],
                        };

                        dispatch({
                          type: CHANGE_LIKE_STATUS_OF_PINNED_POST,
                          payload: {
                            id: post.id,
                            isLiked: post.liked ? false : true,
                          },
                        });
                        dispatch({
                          type: CHANGE_LIKE_BUTTON_STATE_OF_PINNED_POST,
                          payload: {
                            id: post.id,
                            isDisabled: true,
                          },
                        });

                        dispatch(
                          EMDoLikeDislikePinnedPostAction({
                            queryData: queryData,
                          })
                        );
                      }}
                    />
                  ) : post.is_poll ? (
                    <EMPoll
                      permission={true}
                      post={post}
                      pinHandler={() => {
                        let actions = [];
                        post.isPinnedPost
                          ? actions.push(`unpin`)
                          : actions.push(`pin`);
                        let queryData = {
                          id: post.id,
                          action: actions[0],
                        };
                        dispatch(
                          EMDoLikeDislikePinUnpinPinnedPostAction({ queryData })
                        );
                      }}
                    />
                  ) : post.is_quiz ? (
                    <EMQuiz
                      quizFromQuiz={false}
                      permission={true}
                      post={post}
                      pinHandler={() => {
                        let actions = [];
                        post.isPinnedPost
                          ? actions.push(`unpin`)
                          : actions.push(`pin`);
                        let queryData = {
                          id: post.id,
                          action: actions[0],
                        };
                        dispatch(
                          EMDoLikeDislikePinUnpinPinnedPostAction({ queryData })
                        );
                      }}
                    />
                  ) : (
                    <EMPost
                      permission={true}
                      post={post}
                      pinHandler={() => {
                        let actions = [];
                        post.isPinnedPost
                          ? actions.push(`unpin`)
                          : actions.push(`pin`);
                        let queryData = {
                          id: post.id,
                          action: actions[0],
                        };
                        dispatch(
                          EMDoLikeDislikePinUnpinPinnedPostAction({ queryData })
                        );
                      }}
                      likeHandler={() => {
                        let actions = [];
                        post.liked
                          ? actions.push(`dislike`)
                          : actions.push(`like`);
                        let queryData = {
                          id: post.id,
                          action: actions[0],
                        };

                        dispatch({
                          type: CHANGE_LIKE_STATUS_OF_PINNED_POST,
                          payload: {
                            id: post.id,
                            isLiked: post.liked ? false : true,
                          },
                        });
                        dispatch({
                          type: CHANGE_LIKE_BUTTON_STATE_OF_PINNED_POST,
                          payload: {
                            id: post.id,
                            isDisabled: true,
                          },
                        });

                        dispatch(
                          EMDoLikeDislikePinnedPostAction({
                            queryData: queryData,
                          })
                        );
                      }}
                    />
                  )
                )}
            </Box>
          </Grid>
          {matchesLargeScreen ? (
            <Grid item xs={3} style={{ marginBottom: "2%" }}>
              <EMChannelsPreview data={data2.channelList} route="home" />
            </Grid>
          ) : null}
        </Grid>
      </EMPage>
    </InfiniteScroll>
  );
}
